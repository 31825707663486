import { Menu, Transition } from '@headlessui/react';
import { FilterIcon } from '@heroicons/react/solid';
import { getUrlParam } from 'Helper/getUrlPram';
import { useLocalStorage } from 'Hooks';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLargestTransfersByToken } from 'statemgmt/actions';
import Refresh from 'Utilities/Refresh/buttonRefresh';
import Select from 'Utilities/SelectOption/select';
import Table from 'Utilities/Table/table';
import { token_group, token_vol_limit } from './Helper/constants';
import { column } from './Helper/header';
import { processData } from './Helper/processData';
import { CHAINS } from 'Helper/constants';

export default function LatestTransactions() {
  const [group, setGroup] = useLocalStorage('tk_group', 'all');
  const [volLimit, setVolLimit] = useLocalStorage('tk_vol_limit', 'all');
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const contract_address = getUrlParam('contract_address');
  const chainId = CHAINS[getUrlParam('chain')];
  const { largestTransfersbytoken, loading } = useSelector(
    (s = initialState) => {
      return {
        largestTransfersbytoken: processData(
          s.largestTranfersByTokenData.largestTransfersbytoken
        ),
        loading: s.largestTranfersByTokenData.loading,
      };
    }
  );
  useEffect(() => {
    dispatch(
      getLargestTransfersByToken(group, volLimit, contract_address, chainId)
    );
  }, [group, volLimit, refresh]);
  return (
    <>
      <div className="mx-2 sm:px-6 lg:px-2 dark:bg-dark-themePrimary bg-light-themeSecondary">
        <div className="p-2 rounded-lg h-full">
          <div className="hidden sm:block">
            <div className="flow-root mx-2">
              <div className="flex justify-between">
                <div className="flex flex-wrap mb-2 space-x-1">
                  <div className="p-2 my-1 bg-white border dark:bg-dark-bgMutedDark text-gray-500 dark:border-gray-800 dark:text-dark-textPrimary rounded-md flex justify-center items-center">
                    <FilterIcon className="h-4 w-4" />
                  </div>
                  <div>
                    <Select
                      optionsObj={token_group}
                      state={group}
                      setState={setGroup}
                    />
                  </div>
                  <div>
                    <Select
                      optionsObj={token_vol_limit}
                      state={volLimit}
                      setState={setVolLimit}
                    />
                  </div>
                </div>
                <div className="my-1">
                  <div className="flex justify-between">
                    <Refresh
                      state={refresh}
                      setState={setRefresh}
                      loadingbutton={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:hidden block">
            <div className="flex flex-row-reverse">
              <div>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                      <span className="sr-only">Open options</span>
                      <FilterIcon className="h-4 w-4" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-dark-bgMutedDark ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1 px-2">
                        <div className="flow-root mx-2">
                          <div className="flex justify-between">
                            <div className="flex flex-wrap mb-2">
                              <div className="my-1">
                                <Select
                                  optionsObj={token_group}
                                  state={group}
                                  setState={setGroup}
                                />
                              </div>
                              <div className="my-1">
                                <Select
                                  optionsObj={token_vol_limit}
                                  state={volLimit}
                                  setState={setVolLimit}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="mx-2">
                <Refresh
                  state={refresh}
                  setState={setRefresh}
                  loadingbutton={loading}
                />
              </div>
            </div>
          </div>
          {/* <div className="shadow overflow-hidden  border-b border-gray-200 overflow-y-scroll flex-1 h-screen metric-scroll overflow-x-auto dark:border-dark-secondary mt-2"> */}
          <Table
            data={largestTransfersbytoken}
            col={column}
            isloading={loading}
          />
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
