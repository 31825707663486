type wrapperProps = {
  children: JSX.Element;
};
export default function ContentWrapper({
  children,
}: wrapperProps): JSX.Element {
  return (
    <>
      <div className="dark:bg-dark-themePrimary bg-light-themeSecondary  rounded-tr-md">
        {children}
      </div>
    </>
  );
}
