import useQuery from 'Hooks/useQuery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHomePageData } from 'statemgmt/actions';
import PageNotFound from 'Utilities/ErrorPages/404';
import { LogoMinified } from 'Utilities/images';
import PillTab from 'Utilities/PillTab/pillTab';
import { useLocalStorage } from '../../Hooks/useStorage';
import Dashboard from './Dashboard/dashboard';
import FeaturedProducts from './featuredProducts';
import MacroSide from './Macro/MacroSide/macroSide';
import Matrics from './Matrics/matrics';
import Nav from './nav';
import News from './News/news';

export default function Home() {
  let query = useQuery();

  const tabOptions = [
    { id: '1', component: Dashboard, name: 'Dashboard', tooltip: 'Dashboard' },
    {
      id: '2',
      component: MacroSide,
      name: 'Macro',
      tooltip: 'Macro',
    },
    {
      id: '3',
      component: Matrics,
      name: 'Metrics',
      tooltip: 'Metrics',
    },
    { id: '4', component: News, name: 'News', tooltip: 'News' },
  ];
  const [homeTab, setHomeTab] = useState(String(query.get('t')));
  const props = {
    tabOptions,
    state: homeTab,
    setState: setHomeTab,
    basePath: '/home',
    search: {},
  };
  if (!homeTab || tabOptions.length < homeTab) {
    return <PageNotFound />;
  }

  return (
    <>
      <div className="px4 md:px-6 lg:px-8">
        <div className="flex flex-col items-center sm:items-start">
          <div className="flex flex-row items-center space-x-2">
            <img src={LogoMinified} className="w-7" />
            <h1 className="text-lg dark: dark:text-white text-gray-800 font-semibold">
              Welcome
            </h1>
          </div>
          <div className="my-2">
            <h1 className="text-gray-800 dark:text-dark-textPrimary text-xs font-medium text-center sm:text-left">
              Quick access to featured products across AlphaStack platform
            </h1>
          </div>

          <div className="my-6 sm:my-2">
            <Nav />
          </div>
        </div>
        <div className="px-4 md:px-6 lg:px-8 py-4 md:py-8 lg:py-10">
          <FeaturedProducts />
        </div>
      </div>
    </>
  );
}
