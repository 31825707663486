/* eslint-disable react/prop-types */
import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';
import { Placement } from 'tippy.js';

type toolTipProps = {
  content: string;
  placement: Placement;
  children: JSX.Element;
};

function Tooltip({ content, placement, children }: toolTipProps): JSX.Element {
  const renderContent = () => {
    return <div className="text-xs break-words">{content}</div>;
  };
  return (
    <>
      <Tippy content={renderContent()} placement={placement}>
        {children}
      </Tippy>
    </>
  );
}

export default Tooltip;
