import {
  getAlphaRequest,
  getResponses,
  responseVoteByUser,
} from 'Helper/services';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Grid } from 'Utilities';
import RecentRequests from './recentRequests';
import RequestDetails from './requestDetails';
import ResponseList from './responseList';
import SubmitResponse from './submitResponse';

RequestDetailsWrapper.propTypes = {
  request_id: PropTypes.number,
};

const BE_SERVER =
  process.env.NODE_ENV === 'production'
    ? 'https://alphastack-web-backend-cige4pikta-uc.a.run.app'
    : 'http://localhost:9000';

const socket = io.connect(BE_SERVER);
export default function RequestDetailsWrapper({ request_id }) {
  const [responseList, setResponseList] = useState([]);
  const [userVotes, setUserVotes] = useState([]);
  const [requestDetails, setRequestDeatils] = useState();
  const [loading, setLoading] = useState(false);
  const { user_address } = useGetCurrentUser();
  // const user_address = localStorage.getItem('currentAddress');

  // const userInfo = JSON.parse(sessionStorage.getItem('user_info'));
  const getAlphaRequestDetails = () => {
    getAlphaRequest(request_id)
      .then((res) => {
        setRequestDeatils(res.data.response);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAlphaResponseList = () => {
    setLoading(true);
    getResponses(request_id)
      .then((res) => {
        setResponseList(res.response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const updateResponse = (newResponse) => {
    setResponseList((prevState) => prevState.concat(newResponse));
  };

  const getUserVote = () => {
    responseVoteByUser(user_address, request_id)
      .then((r) => {
        setUserVotes(r?.response);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getAlphaRequestDetails();
  }, []);

  useEffect(() => {
    getAlphaResponseList();
    getUserVote();
  }, [socket]);

  return (
    <>
      <Grid cols="1" gap="8" className="lg:grid-cols-3">
        <div className="col-span-2">
          <RequestDetails
            requestDetails={requestDetails && requestDetails[0]}
          />
          <SubmitResponse
            request_id={request_id}
            socket={socket}
            responseList={responseList}
            setResponseList={setResponseList}
            updateResponse={updateResponse}
          />
          <div className="w-full border-t border-gray-300 dark:border-gray-700" />

          <ResponseList
            socket={socket}
            request_id={request_id}
            userAddress={requestDetails && requestDetails[0]?.user_address}
            responseList={responseList}
            setResponseList={setResponseList}
            hasWinner={requestDetails && requestDetails[0]?.status}
            requestDetails={requestDetails && requestDetails}
            userVotes={userVotes}
            setUserVotes={setUserVotes}
            loading={loading}
          />
        </div>
        {requestDetails && (
          <div className="mt-6">
            <RecentRequests
              category_id={requestDetails && requestDetails[0]?.category_id}
              request_id={request_id}
            />
          </div>
        )}
      </Grid>
    </>
  );
}
