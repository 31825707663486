import React from 'react';
import expandLight from '../../Assets/Images/expand-wht.png';
import expandDark from '../../Assets/Images/expand-blk.png';
import { useSelector } from 'react-redux';
export default function ExpandArrowIcon() {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div>
      <img
        src={theme == 'dark' ? expandLight : expandDark}
        className="w-3 dark:text-gray-100"
      />
    </div>
  );
}
