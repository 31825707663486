import { RefreshIcon, SearchIcon } from '@heroicons/react/solid';
import Tippy from '@tippyjs/react';
import HasCluster from 'Components/Platforms/Molecules/hasCluster';
import {
  getLoggedUser,
  getUserClusterList,
  getUserDataCatalogs,
} from 'Helper/services';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { Table } from 'Utilities';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { column } from './Atoms/header';

function Catalog() {
  const navigate = useNavigate();
  const [userDataCatalogs, setUserDataCatalogs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user_address } = useGetCurrentUser();
  const [hasCluster, setHasCluster] = useState(true);

  const getDataCatalogs = async () => {
    setLoading(true);
    try {
      const loggedUser = await getLoggedUser(user_address);
      if (loggedUser) {
        const userId = loggedUser.message[0].id;
        const userClusters = await getUserClusterList(userId, 'all');
        if (userClusters?.data?.length > 0) {
          console.log('user has cluster');
          const userCatalogs = await getUserDataCatalogs(userId);
          if (userCatalogs) setUserDataCatalogs(userCatalogs.message);
        } else {
          setHasCluster(false);
        }
      }
    } catch (err) {
      console.log('failed to fetch catalog');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataCatalogs();
  }, [refresh]);

  return (
    <div>
      <div>
        {!hasCluster && !loading && <HasCluster />}
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="my-4 pb-2">
            <div className="text-xl dark:text-white text-gray-800 font-medium">
              Connect your data
            </div>
          </div>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <NavLink
                to="new?step=catalog-source"
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
              >
                Configure a catalog
              </NavLink>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <div className="relative mt-1 rounded-md shadow-sm border dark:border-gray-700">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-gray-300 pl-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2 bg-light-themeSecondary dark:bg-dark-themePrimary text-black dark:text-white"
                  placeholder="Search catalogs"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row-reverse p-2">
            <Tippy
              content={<div className="text-xs">refresh data</div>}
              placement="top"
            >
              <button className="bg-gray-100  rounded-md flex items-center p-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                <span className="sr-only">refresh Option</span>
                <RefreshIcon
                  className={
                    loading
                      ? 'animate-spin w-4 h-4 text-gray-400 cursor-pointer mouse-pointer'
                      : 'w-4 h-4 text-gray-400 cursor-pointer mouse-pointer'
                  }
                  onClick={() => setRefresh(!refresh)}
                />
              </button>
            </Tippy>
          </div>

          <Table data={userDataCatalogs} col={column} isloading={loading} />
        </div>
      </div>
    </div>
  );
}

export default Catalog;
