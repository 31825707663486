import { Overview } from 'Utilities';
import OverviewCard from 'Utilities/Cards/overviewCard';
import AlphaLeadsIcon from 'Utilities/Icons/AlphaLeadsIcon';
import AlphaRequestIcon from 'Utilities/Icons/AlphaRequestIcon';

const OVERVIEWMENU = [
  {
    title: 'Alpha Leads',
    url: '/app/social/community-alpha/alpha-leads/lead-list',
    desc: 'Find community insights from top Alpha Leaders. Submit your own insights and get rewarded.',
    icon: AlphaLeadsIcon,
  },
  {
    title: 'Alpha Requests',
    url: '/app/social/community-alpha/alpha-request',
    desc: 'Stackoverflow-style question and answer portal to learn and share​ your ​knowledge and get rewarded for it.',
    icon: AlphaRequestIcon,
  },
];
function CommunityAlpha() {
  return (
    <>
      <Overview
        title="Community Alpha"
        desc='Provide value by submiting Alpha Leads, Wallet Labels, solving
              Alpha Requests - and get rewarded for doing so. Get value from
              "The wisdom of the crowd"'
      />

      <div className="max-w-7xl mt-6 mx-10 sm:mt-10 ">
        <div className="max-w-6xl">
          <div className="not-prose my-12 grid grid-cols-1 gap-10 lg:grid-cols-3 p-1">
            {OVERVIEWMENU.map((itm, key) => (
              <OverviewCard
                key={key}
                title={itm.title}
                url={itm.url}
                desc={itm.desc}
                idx={key}
                icon={itm.icon}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CommunityAlpha;
