/* eslint-disable react/prop-types */
import { chainProvider } from 'Helper/chainProvider';
import { SCAN_ADDRESS, SCAN_TX } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import { getUrlParam } from 'Helper/getUrlPram';
import moment from 'moment';
import { CoinName } from 'Utilities';
import DexTradeActivity from 'Utilities/Badges/dexTradeActivity';
import Etherscan from 'Utilities/Etherscan/etherscan';
import InputFilter from 'Utilities/Table/inputFilter';
import NumberRangeFilter from 'Utilities/Table/numberRangeFilter';
import SelectFilter from 'Utilities/Table/selectFilter';

export const column = [
  {
    Header: 'Tx Hash',
    accessor: 'transaction_hash',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.transaction_hash}
        type={SCAN_TX}
        isSubString={true}
        chain={getUrlParam('chain') == 'ethereum' ? 0 : 1}
      />
    ),
    isSearchClicked: true,
    action: 'defiLatestTrades',
    Filter: '',
  },
  {
    Header: 'Token Name',
    accessor: 'name',
    Cell: (cell) => (
      <CoinName
        isSubtring={true}
        slug={cell.data[cell.row.index].contract_address}
        provider={''}
        name={cell.row.values.name}
        logo_url={''}
        showLogo={false}
      />
    ),
    Filter: '',
  },
  {
    Header: 'Trader Address',
    accessor: 'trader_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.trader_address}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    disableSortBy: true,
    Filter: InputFilter,
  },
  {
    Header: 'Trade Action',
    accessor: 'trade_action',
    Cell: (cell) => (
      <DexTradeActivity activity={cell.row.values.trade_action} />
    ),
    disableSortBy: true,
    Filter: SelectFilter,
  },
  {
    Header: 'DEX',
    accessor: 'dex',
    Filter: '',
  },
  {
    Header: 'Swap Type',
    accessor: 'trade_type',
    Filter: '',
  },
  {
    Header: 'Token Amount',
    accessor: 'token_amount',
    Cell: (cell) => (
      <>
        {cell.row.values.trade_action === 'buy' ||
        cell.row.values.trade_action === 'inflow' ? (
          <p className="text-green-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        ) : (
          <p className="text-red-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        )}
      </>
    ),
    disableSortBy: true,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  {
    Header: 'Est. USD Val (Tx Date)',
    accessor: 'token_value_usd_txdate',
    Cell: (cell) => (
      <>
        <>
          <p
            className={`${
              cell.data[cell.row.index].using_ethvalue_txdate != 'x'
                ? cell.row.values.trade_action === 'buy' ||
                  cell.row.values.trade_action === 'inflow'
                  ? 'text-green-500'
                  : 'text-red-500'
                : 'text-gray-500'
            }`}
            title={
              cell.data[cell.row.index].using_ethvalue_txdate === 'x'
                ? 'token price not found, showing eth value'
                : ''
            }
          >
            {cell.row.values.token_value_usd_txdate
              ? '$' +
                formatValue('format', cell.row.values.token_value_usd_txdate)
              : '-'}
          </p>
        </>
      </>
    ),
    disableSortBy: true,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  {
    Header: 'USD Value (Current)',
    accessor: 'token_value_usd',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.data[cell.row.index].using_ethvalue_current != 'x'
              ? cell.row.values.trade_action === 'buy' ||
                cell.row.values.trade_action === 'inflow'
                ? 'text-green-500'
                : 'text-red-500'
              : 'text-gray-500'
          }`}
          title={
            cell.data[cell.row.index].using_ethvalue_current === 'x'
              ? 'token price not found, showing eth value'
              : ''
          }
        >
          {cell.row.values.token_value_usd
            ? '$' + formatValue('format', cell.row.values.token_value_usd)
            : '-'}
        </p>
      </>
    ),
    disableSortBy: true,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  {
    Header: 'Time',
    accessor: 'timestamp_utc',
    Cell: ({ cell }) => {
      let dateTime = moment.utc(cell.row.values.timestamp_utc * 1000).fromNow();
      return dateTime;
    },
    Filter: '',
  },
];

export const columnPopup = [
  {
    Header: 'TX HASH',
    accessor: 'transaction_hash',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.transaction_hash}
        type={SCAN_TX}
        isSubString={true}
      />
    ),
    Filter: '',
  },
  {
    Header: 'Token Name',
    accessor: 'name',
    Cell: (cell) => (
      <a
        className="mr-1 text-blue-500"
        href={`https://etherscan.io/token/${`${
          cell.data[cell.row.index].contract_address
        }`}`}
        target="_blank"
        rel="noreferrer"
      >
        {cell.row.values.name}
      </a>
    ),
    Filter: '',
  },
  {
    Header: 'Trader Address',
    accessor: 'trader_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.trader_address}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    Filter: '',
  },
  {
    Header: 'Trade Action',
    accessor: 'trade_action',
    Cell: (cell) => (
      <DexTradeActivity activity={cell.row.values.trade_action} />
    ),
    Filter: '',
  },
  {
    Header: 'DEX',
    accessor: 'dex',
    Filter: '',
  },
  {
    Header: 'Swap Type',
    accessor: 'trade_type',
    Filter: '',
  },
  {
    Header: 'Token Amount',
    accessor: 'token_amount',
    Cell: (cell) => (
      <>
        {cell.row.values.trade_action === 'buy' ||
        cell.row.values.trade_action === 'inflow' ? (
          <p className="text-green-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        ) : (
          <p className="text-red-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        )}
      </>
    ),
    Filter: '',
  },
  {
    Header: 'Est. USD Val (Tx Date)',
    accessor: 'token_value_usd_txdate',
    Cell: (cell) => (
      <>
        <>
          <p
            className={`${
              cell.data[cell.row.index].using_ethvalue_txdate != 'x'
                ? cell.row.values.trade_action === 'buy' ||
                  cell.row.values.trade_action === 'inflow'
                  ? 'text-green-500'
                  : 'text-red-500'
                : 'text-gray-500'
            }`}
            title={
              cell.data[cell.row.index].using_ethvalue_txdate === 'x'
                ? 'token price not found, showing eth value'
                : ''
            }
          >
            {cell.row.values.token_value_usd_txdate
              ? '$' +
                formatValue('format', cell.row.values.token_value_usd_txdate)
              : '-'}
          </p>
        </>
      </>
    ),
    Filter: '',
  },
  {
    Header: 'USD Value (Current)',
    accessor: 'token_value_usd',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.data[cell.row.index].using_ethvalue_current != 'x'
              ? cell.row.values.trade_action === 'buy' ||
                cell.row.values.trade_action === 'inflow'
                ? 'text-green-500'
                : 'text-red-500'
              : 'text-gray-500'
          }`}
          title={
            cell.data[cell.row.index].using_ethvalue_current === 'x'
              ? 'token price not found, showing eth value'
              : ''
          }
        >
          {cell.row.values.token_value_usd
            ? '$' + formatValue('format', cell.row.values.token_value_usd)
            : '-'}
        </p>
      </>
    ),
    Filter: '',
  },
  {
    Header: 'Time',
    accessor: 'timestamp_utc',
    Cell: ({ cell }) => {
      let dateTime = moment.utc(cell.row.values.timestamp_utc * 1000).fromNow();
      return dateTime;
    },
    Filter: '',
  },
];
