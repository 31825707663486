import { getUrlParam } from 'Helper/getUrlPram';
import useQuery from 'Hooks/useQuery';
import PageNotFound from 'Utilities/ErrorPages/404';
import PillTab from 'Utilities/PillTab/pillTab';
import { useState } from 'react';
import LatestHotTokens from './LatestHotTokens';
import DexTrades from './LatestTrades/latestTrades';
import LatestTransaction from './LatestTransactions/latestTransactions';
import TopLP from './TopLP/topLP';
import TopLpUnlisted from './TopLPUnlisted/topLPUnlisted';
import TopTrades from './TopTrades/topTrades';
import TopTradesUnlisted from './TopTradesUnlisted/topTradesUnlisted';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Defi() {
  let query = useQuery();

  const tabOptions = [
    {
      id: '1',
      component: TopTrades,
      name: 'Top Dex Trades',
      tooltip:
        'Select Top Dex Trades (upto 1 week) with useful filters such as smart_money, whales etc.',
      filter: {
        limit: 20,
        group: 'whales',
        type: 'bought_sold',
        duration: '24hr',
        sort_key: 'wallet_count',
      },
    },

    {
      id: '2',
      component: TopTradesUnlisted,
      name: 'Top Dex Trades(un-listed)',
      tooltip:
        'Top Dex Trades for tokens not yet listed on Coingecko or Coinmarketcap',
      filter: {
        limit: 20,
        group: 'whales',
        type: 'brought_sold',
        duration: '24hr',
        sort_key: 'wallet_count',
        buySellFilter: 'buy_wallet_count',
      },
    },
    {
      id: '7',
      component: LatestHotTokens,
      name: 'Latest Hot Tokens',
      tooltip:
        'The newest tokens with the most dex trades by whales, smart money etc.',
      filter: null,
    },
    // {
    //   id: '7',
    //   component: LatestDexPairs,
    //   name: 'Latest Dex Pairs',
    //   tooltip: 'Newly created Dex pairs with useful filters',
    // },
    {
      id: '5',
      component: DexTrades,
      name: 'Latest Trades',
      tooltip: 'Latest DEx Trades with useful filters',
      filter: {
        group: 'all',
        limit: '1',
        type: 'dex',
        address: '',
      },
    },
    {
      id: '3',
      component: TopLP,
      name: 'Top LP',
      tooltip:
        'Select Top LP events (upto 1 mon) with useful filters such as smart_money, whales etc.',
      filter: {
        limit: 20,
        group: 'whales',
        type: 'brought_sold',
        duration: '24hr',
        buySellFilter: 'buy_wallet_count',
        hideStandardCoins: true,
      },
    },
    {
      id: '4',
      component: TopLpUnlisted,
      name: 'Top LP(un-listed)',
      tooltip:
        'Top Top LP events for tokens not yet listed on Coingecko or Coinmarketcap',
      filter: {
        limit: 20,
        group: 'whales',
        type: 'brought_sold',
        duration: '24hr',
        buySellFilter: 'buy_wallet_count',
      },
    },
    {
      id: '6',
      component: LatestTransaction,
      name: 'Latest Transactions',
      tooltip:
        'Select Latest transactions (upto 1 mon) with useful filters such as smart_money, whales etc.',
      filter: {
        group: 'all',
        volLimit: '1',
      },
    },
    // {
    //   id: '7',
    //   component: MicroHunter,
    //   name: 'Microcap Hunter',
    //   tooltip: 'Micro cap hunter',
    // },
  ];

  const [defiTab, setdefiTab] = useState(String(query.get('tab')));

  const props = {
    tabOptions,
    state: defiTab,
    setState: setdefiTab,
    basePath: '',
  };
  if (!defiTab || tabOptions.length < defiTab) {
    return <PageNotFound />;
  }

  return (
    <>
      <div className="mx-6">
        <div className="flex flex-col">
          <div className="flex mx-2 flex-row-reverse"></div>
          <div className="mt-2">
            <div className="flex flex-col lg:flex-row justify-between dark:border-dark-tabPillBgSelectedDark">
              <PillTab {...props} />
            </div>
            <div className="lg:border-t dark:border-dark-tabPillBgSelectedDark">
              {tabOptions.map((item, key) =>
                item.name.replace(/\s+/g, '') == defiTab ? (
                  <div key={key} className="">
                    <item.component />
                  </div>
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Defi;
