/* eslint-disable react/prop-types */
import { addressFormat } from 'Components/Login/Helper/formater';
import { chainProvider } from 'Helper/chainProvider';
import { CHAINS, SCAN_TX } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import { getUrlParam } from 'Helper/getUrlPram';
import moment from 'moment';
import DexTradeActivity from 'Utilities/Badges/dexTradeActivity';
import Etherscan from 'Utilities/Etherscan/etherscan';
import InputFilter from 'Utilities/Table/inputFilter';
import NumberRangeFilter from 'Utilities/Table/numberRangeFilter';
import SelectFilter from 'Utilities/Table/selectFilter';
import {
  FilterIcon as FilterIconOutline,
  XIcon,
} from '@heroicons/react/outline';

const RenderTokenName = ({ address, name }) => {
  // const { block_chain } = useSelector((s = initialState) => {
  //   return {
  //     block_chain: s.currentBlockChain.selectedBlockChain,
  //   };
  // });
  const block_chain = CHAINS[getUrlParam('chain')];

  return (
    <div className="flex">
      <a
        className="mr-1 text-blue-500"
        href={`${chainProvider(block_chain)}/token/${`${address}`}`}
        target="_blank"
        rel="noreferrer"
      >
        {name ? name : addressFormat(address)}
      </a>
    </div>
  );
};

export const column = [
  {
    Header: 'Tx Hash',
    accessor: 'transaction_hash',
    Cell: (cell) => {
      return (
        <Etherscan
          address={cell.row.values.transaction_hash}
          type={SCAN_TX}
          isSubString={true}
        />
      );
    },
    isSearchClicked: false,
    action: '',
    disableSortBy: true,
    Filter: InputFilter,
  },
  {
    Header: 'Token Name',
    accessor: 'token_name',
    Cell: ({ value, column: { id, filterValue }, setFilter, cell }) => {
      return (
        <div className="flex">
          <RenderTokenName
            address={cell?.row?.values?.transaction_hash}
            name={value}
          />
          {filterValue ? (
            <XIcon
              className={`w-4 cursor-pointer`}
              onClick={() => {
                setFilter(id, '');
              }}
            />
          ) : (
            <FilterIconOutline
              className="w-4 cursor-pointer"
              onClick={() => {
                setFilter(id, value);
              }}
            />
          )}
        </div>
      );
    },
    disableSortBy: true,
    Filter: InputFilter,
  },
  // {
  //   Header: 'Trader Address',
  //   accessor: 'trader_address',
  //   Cell: (cell) => (
  //     <Etherscan
  //       address={cell.row.values.trader_address}
  //       type={SCAN_ADDRESS}
  //       isSubString={true}
  //     />
  //   ),
  // },
  {
    Header: 'Trade Action',
    accessor: 'trade_action',
    Cell: (cell) => (
      <DexTradeActivity activity={cell.row.values.trade_action} />
    ),
    disableSortBy: true,
    Filter: SelectFilter,
  },
  {
    Header: 'DEX',
    accessor: 'dex',
    Filter: '',
  },
  {
    Header: 'Swap Type',
    accessor: 'trade_type',
    Filter: '',
  },
  {
    Header: 'Token Amount',
    accessor: 'token_amount',
    Cell: (cell) => (
      <>
        {cell.row.values.trade_action === 'buy' ||
        cell.row.values.trade_action === 'inflow' ? (
          <p className="text-green-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        ) : (
          <p className="text-red-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        )}
      </>
    ),
    disableSortBy: true,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  {
    Header: 'Est. USD Val (Tx Date)',
    accessor: 'token_value_usd_txdate',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.data[cell.row.index].using_value_txdate != 'x'
              ? cell.row.values.trade_action === 'buy' ||
                cell.row.values.trade_action === 'inflow'
                ? 'text-green-500'
                : 'text-red-500'
              : 'text-gray-500'
          }`}
          title={
            cell.data[cell.row.index].using_value_txdate === 'x'
              ? 'token price not found, showing eth value'
              : ''
          }
        >
          {cell.row.values.token_value_usd_txdate
            ? '$' +
              formatValue('format', cell.row.values.token_value_usd_txdate)
            : '-'}
        </p>
      </>
    ),
    disableSortBy: true,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  {
    Header: 'USD Value (Current)',
    accessor: 'token_value_usd_current',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.data[cell.row.index].token_value_usd_current != 'x'
              ? cell.row.values.trade_action === 'buy' ||
                cell.row.values.trade_action === 'inflow'
                ? 'text-green-500'
                : 'text-red-500'
              : 'text-gray-500'
          }`}
          title={
            cell.data[cell.row.index].token_value_usd_current === 'x'
              ? 'token price not found, showing eth value'
              : ''
          }
        >
          {cell.row.values.token_value_usd_current
            ? '$' +
              formatValue('format', cell.row.values.token_value_usd_current)
            : '-'}
        </p>
      </>
    ),
    disableSortBy: true,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  {
    Header: 'Time',
    accessor: 'block_timestamp',
    Cell: ({ cell }) => {
      // let dateTime = new Date(cell.row.values.timestamp_utc * 1000).toUTCString(
      //   'en-US'
      // );
      let dateTime = moment
        .utc(cell.row.values.block_timestamp * 1000)
        .fromNow();
      return dateTime;
    },
    Filter: '',
  },
];
