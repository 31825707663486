/* eslint-disable react/prop-types */
import moment from 'moment';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import { Text } from 'Utilities';
import PropTypes from 'prop-types';

export default function SetWinnerPrompt(props) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  console.log(props.requestDetails[0].id);
  const style = {
    cancelButton: `bg-transparent py-2 px-4 border rounded-md shadow-sm text-sm font-semibold ${
      theme == 'dark' ? 'border-gray-700 text-white' : 'border-gray-300'
    }`,
    askButton: `ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
    modalTitle: `${theme == 'dark' ? 'text-white' : 'text-gray-800'}`,
    modalSubtitle: `${theme == 'dark' ? 'text-gray-400' : 'text-gray-600'}`,
  };
  const setResponseWinner = () => {
    const _reqdata = {
      request_id: props?.request_id,
      response_id: props?.response?.id,
    };
    props.socket.emit('setResponseWinner', _reqdata);
    props.setShowPopup(false);
  };

  return (
    <>
      <Text
        size="lg"
        fontWeight="semibold"
        text={`Confirm winner `}
        className="dark:text-white"
      />
      <Text
        size="sm"
        fontWeight="normal"
        // text={`${props.requestDetails[0].reward_amount.slice(0, 4)}`}
        text={`${props.requestDetails[0].reward_amount.slice(0, 4)} ${
          props.requestDetails[0].reward_currency_symbol
        } will be awarded from your wallet to ${props.requestDetails[0].user_address.slice(
          0,
          10
        )}`}
        className={theme == 'dark' ? 'text-gray-400' : ''}
      />

      <div
        className={`my-4 p-2 rounded ${
          theme == 'dark'
            ? 'bg-gray-900 text-gray-400 border-gray-700'
            : 'bg-gray-100'
        }`}
      >
        <div className="flex">
          <Text
            size="sm"
            color="blue-600"
            fontWeight="semibold"
            text={props.requestDetails[0].user_address.slice(0, 10)}
          />
          <span className="w-1.5 h-1.5 rounded-full bg-gray-500 mx-2 my-2" />
          <Text
            size="sm"
            fontWeight="medium"
            text={moment
              .utc(props.requestDetails[0].submission_timestamp_utc)
              .fromNow()}
            className="dark:text-dark-textPrimary mr-1"
          />
        </div>
        {props.response.message}
      </div>

      <div className="">
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className={style.cancelButton}
              onClick={() => props.setShowPopup(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={style.askButton}
              onClick={() => setResponseWinner()}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

SetWinnerPrompt.propTypes = {
  props: PropTypes.object,
};
