/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowSmLeftIcon } from '@heroicons/react/solid';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Urlform({
  setNoToken,
  sourceUrl,
  setSourceUrl,
  validateSourceUrl,
  alphaButton,
  setShowUrlForm,
  showUrlForm,
  setShowForm,
  setProjectName,
  setTicker,
  setWebsite,
  setMarkets,
  setshowSubmit,
}) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div>
      <ArrowSmLeftIcon
        className={`h-5 w-5 cursor-pointer mb-3 ${
          theme == 'dark' ? 'text-dark-textPrimary' : ''
        }`}
        onClick={() => {
          setShowUrlForm(!showUrlForm);
        }}
      />
      <div className="grid grid-cols-6 sm:gap-3 md:gap-3">
        <div className="col-span-6 mb-1">
          <label
            htmlFor="sourceUrl"
            className={`block text-sm text-left font-medium   mb-1 ${
              theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
            }`}
          >
            {alphaButton == 'token'
              ? 'coingecko URL'
              : 'Opensea URL (Collection or single asset)'}
            <span className="text-red-500">*</span>
          </label>
          <textarea
            cols={5}
            value={sourceUrl}
            onChange={(e) => setSourceUrl(e.target.value)}
            className={`rounded py-3 px-3 w-full col-4 shadow border text-sm placeholder-gray-400 focus:outline-none focus:shadow-outline ${
              theme === 'dark'
                ? 'bg-gray-700 text-gray-300 border-gray-700'
                : 'bg-white text-gray-700'
            }`}
            placeholder={
              alphaButton == 'token'
                ? 'Eg: https://www.coingecko.com/en/coins/bitcoin'
                : 'Eg: https://opensea.io/collection/cryptopunks'
            }
          />
        </div>
      </div>
      <div className="flex mb-3 justify-between">
        <button
          type="button"
          className={`items-center px-6 py-2  shadow-sm text-xs font-semibold rounded-md ${
            theme === 'dark'
              ? 'bg-blue-700 text-white'
              : 'bg-blue-700 text-white border border-blue-700'
          } ${
            sourceUrl.length <= 0
              ? 'pointer-events-none cursor-not-allowed'
              : ''
          }`}
          onClick={() => {
            validateSourceUrl(alphaButton);
          }}
        >
          GO
        </button>

        <p
          className={`items-center cursor-pointer ml-3  py-2  shadow-sm text-xs rounded-md ${
            theme === 'dark' ? ' text-white' : ' text-black'
          }`}
          onClick={() => {
            setNoToken(true);
            setShowForm(true);
            setProjectName('');
            setTicker('');
            setWebsite('');
            setMarkets('');
            setshowSubmit(true);
          }}
        >
          Don&#39;t have{' '}
          {alphaButton == 'token' ? 'coingecko URL' : 'Opensea URL'}?
          <span className="text-blue-600"> Click Here</span>
        </p>
      </div>
    </div>
  );
}

export default Urlform;
