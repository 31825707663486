export default function formatValue(type, value) {
  const format = [
    {
      abbr: '',
      limit: 1e3,
    },
    {
      abbr: 'K',
      limit: 1e6,
    },
    {
      abbr: 'M',
      limit: 1e9,
    },
    {
      abbr: 'B',
      limit: 1e12,
    },
    {
      abbr: 'T',
      limit: 1e15,
    },
    {
      abbr: 'Q',
      limit: 1e18,
    },
    {
      abbr: 's',
      limit: 1e21,
    },
    {
      abbr: 'S',
      limit: 1e24,
    },
    {
      abbr: 'O',
      limit: 1e27,
    },
    {
      abbr: 'N',
      limit: 1e30,
    },
    {
      abbr: 'd',
      limit: 1e33,
    },
    {
      abbr: 'U',
      limit: 1e36,
    },
    {
      abbr: 'D',
      limit: 1e39,
    },
    {
      abbr: '!',
      limit: 1e42,
    },
    {
      abbr: '@',
      limit: 1e45,
    },
    {
      abbr: '#',
      limit: 1e48,
    },
    {
      abbr: '$',
      limit: 1e51,
    },
    {
      abbr: '%',
      limit: 1e54,
    },
  ];

  if (type === 'abbr') {
    if (value > 0) {
      const findFormat = format?.find((format) => value < format?.limit);

      value = (1000 * value) / findFormat?.limit;
      value = Math.round(value * 10) / 10;
      return value + findFormat?.abbr;
    } else return '';
  }
  if (type === 'format') {
    return !isNaN(parseFloat(value))
      ? parseFloat(value).toLocaleString('en')
      : '-';
  }
}
