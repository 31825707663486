/* eslint-disable react/prop-types */
import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';

export default function SplitProgressBar({ longRate, shortRate }) {
  return (
    <div className="">
      <Tippy
        content={`LongRate: ${longRate} ShortRate:${
          shortRate ? shortRate : ''
        }`}
        placement="bottom"
      >
        <div className="overflow-hidden h-1 text-xs flex rounded bg-blue-100">
          <div
            style={{
              width: longRate + '%',
            }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500
            `}
          ></div>
          <div
            style={{
              width: shortRate + '%',
            }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500
            `}
          ></div>
        </div>
      </Tippy>
    </div>
  );
}
