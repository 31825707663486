import { ExternalLinkIcon } from '@heroicons/react/outline';
import { nftCategories } from 'Components/NFT/NFTDrops/Helper/constants';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNftDropsData, getNftTopsData } from 'statemgmt/actions';
import { Flex, Text } from 'Utilities';

import discord from '../../../../../Assets/Images/discord.png';
import twitter from '../../../../../Assets/Images/twitter.png';
import website from '../../../../../Assets/Images/website.png';

export default function UpcomingNft() {
  const dispatch = useDispatch();

  const { nftDrops, loading } = useSelector((s = initialState) => {
    return {
      nftDrops: s.nftDropsData.nftDrops,
      loading: s.nftDropsData.loading,
    };
  });

  useEffect(() => {
    const frmtMintDate = format(
      new Date('Tue Feb 01 2022 00:00:00 GMT+0530'),
      'yyyy-MM-dd HH:mm:ss'
    );
    dispatch(getNftDropsData(frmtMintDate));
  }, []);
  return (
    <>
      <Flex justifyContent="between" className="md:mx-5 my-3">
        <>
          <Text
            size="lg"
            fontWeight="semibold"
            text="Upcoming NFT Drops"
            className="dark:text-white mt-6 md:mt-0"
          />
        </>
        <a
          href="/app/prebuilt/on-chain-analytics/nft?chain=ethereum&tab=NFTDrops"
          className="flex space-x-2 mt-6 md:mt-2"
          target="_balnk"
          rel="noopener"
        >
          <ExternalLinkIcon className="h-4 w-4 dark:text-dark-textPrimary" />
          <Text
            size="xs"
            fontWeight="medium"
            className="dark:text-dark-textPrimary"
            text="See all NFT drops "
          />
        </a>
      </Flex>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3  rounded md:px-4 mt-4"
      >
        {nftDrops &&
          nftDrops.slice(0, 3).map((item, key) => (
            <li
              key={key}
              className="col-span-1 flex flex-col text-center  rounded-lg shadow"
            >
              <div className="flex-1 flex flex-col p-8 bg-light-themeSecondary dark:bg-dark-themePrimary border-t border-l border-r rounded-tr-lg rounded-tl-lg dark:border-gray-700">
                <img
                  className="w-20 h-20 flex-shrink-0 mx-auto rounded-full"
                  src={item.logo_url}
                  alt=""
                />
                <h3 className="mt-6 text-gray-900 text-lg font-semibold dark:text-white">
                  {item.name}
                </h3>
                <dl className="mt-1 flex-grow flex flex-col justify-between">
                  <dt className="sr-only">Role</dt>
                  <dd className="mt-1">
                    <span
                      className={`px-2 py-1 text-xs rounded-full font-semibold bg-${
                        nftCategories[0][item.category]
                      }-100  rounded-full text-sm text-${
                        nftCategories[0][item.category]
                      }-400 `}
                    >
                      {item.category}
                    </span>
                  </dd>
                </dl>
              </div>
              <div className="dark:bg-dark-themeCard border-b border-l border-r rounded-br-lg rounded-bl-lg dark:border-gray-700">
                <div className="flex flex-wrap">
                  <div className="p-4 md:w-1/3 w-full">
                    <Text
                      size="sm"
                      fontWeight="semibold"
                      text="Price"
                      className="dark:text-dark-textPrimary"
                    />
                    <Text
                      size="md"
                      fontWeight="semibold"
                      text={item.mint_price}
                      className="dark:text-dark-textPrimary md:mb-4"
                    />
                  </div>
                  <div className="p-4 md:w-1/3 w-full">
                    <Text
                      size="sm"
                      fontWeight="semibold"
                      text="Total"
                      className="dark:text-dark-textPrimary"
                    />
                    <Text
                      size="md"
                      fontWeight="semibold"
                      text={item.total_supply}
                      className="dark:text-dark-textPrimary md:mb-4"
                    />
                  </div>
                  <div className="md:p-4 md:w-1/3 w-full">
                    <Text
                      size="sm"
                      fontWeight="semibold"
                      text="Date"
                      className="dark:text-dark-textPrimary"
                    />
                    <Text
                      size="md"
                      fontWeight="semibold"
                      text={
                        moment.utc(item.mint_date_utc).format('lll') + ' UTC'
                      }
                      className="dark:text-dark-textPrimary mb-4"
                    />
                  </div>
                </div>
                <div className="m-3 md:px-6">
                  <button
                    type="button"
                    className="w-full px-4 py-2 border shadow-sm text-base border-blue-700 font-medium rounded-lg text-blue-700 bg-white dark:bg-dark-themeBgDark hover:bg-gray-50"
                  >
                    Mint
                  </button>
                </div>
                <div className="m-3 md:px-6 flex flex-wrap space-x-3">
                  <div className="">
                    <a href={item.website_url} target="_blank" rel="noreferrer">
                      <img
                        className="inline-block h-6 w-6 rounded-full"
                        src={website}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="">
                    <a href={item.twitter_url} target="_blank" rel="noreferrer">
                      <img
                        className="inline-block h-6 w-6 rounded-full"
                        src={twitter}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="">
                    <a href={item.discord_url} target="_blank" rel="noreferrer">
                      <img
                        className="inline-block h-6 w-6 rounded-full"
                        src={discord}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
}
