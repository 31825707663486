import { FilterButton } from 'Utilities';
import PropTypes from 'prop-types';
import { buyFilterOptions, sellFilterOptions } from './Helper/constants';

function SortOptions({ filter, setFilter }) {
  return (
    <div className="overflow-hidden p-2">
      <h6 className="text-xs text-gray-400">Sort by</h6>
      <div className="p-3">
        <h1 className="text-xs text-gray-400 dark:text-dark-textPrimary">
          Buy
        </h1>
        <div className="my-1">
          {buyFilterOptions.map((item, key) => (
            <div
              key={key}
              className="group cursor-pointer relative inline-block my-1 flex-shrink"
            >
              <FilterButton
                value={item.value}
                label={item.label}
                tooltip={item.tooltip}
                filter={filter}
                setFilter={setFilter}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="p-3">
        <h1 className="text-xs text-gray-400 dark:text-dark-textPrimary">
          Sell
        </h1>
        <div className="my-1">
          {sellFilterOptions.map((item, key) => (
            <div
              key={key}
              className="group cursor-pointer relative inline-block my-1 flex-shrink"
            >
              <FilterButton
                key={key}
                value={item.value}
                label={item.label}
                tooltip={item.tooltip}
                filter={filter}
                setFilter={setFilter}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SortOptions;

SortOptions.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func,
};
