import React from 'react';
import CorrelatedChart from './Atoms/correlatedChart';
import InverseCorrelation from './Atoms/inverseCorrelation';

export default function CorrelationInverse() {
  return (
    <>
      {' '}
      <div className="px-5 bg-white dark:bg-dark-themeSecondary">
        <>
          <div className="font-semibold dark:text-white mt-2">
            Correlated Charts
          </div>
          <div className="text-xs text-gray-400 mb-2">
            Non-crypto markets that we find to be in correlation with Bitcoin.
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 py-2">
            <div className="my-4 sm:my-0">
              <div className="font-semibold text-gray-500 dark:text-dark-textPrimary">
                Positive correlated chart
              </div>
              <div className="text-xs text-gray-400 mb-2">
                In general NASDAQ is statistically in positive correlation with
                Bitcoin. (when NASDAQ goes up then Bitcoin tends to go up, and
                vice-versa)
              </div>
              <InverseCorrelation />
            </div>
            <div>
              <div className="font-semibold text-gray-500 dark:text-dark-textPrimary">
                Inversely correlated chart
              </div>
              <div className="text-xs text-gray-400 mb-2">
                In general US Dollar Index (DXY) is statistically in inverse
                correlation with Bitcoin.(when DXY goes up then Bitcoin tends to
                go down, and vice-versa)
              </div>
              <CorrelatedChart />
            </div>
          </div>
        </>
      </div>
    </>
  );
}
