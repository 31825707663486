import { ClockIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'Utilities';

import { formatMetricData } from './Helper/formatData';

export default function TokenBreaking() {
  const { metrics } = useSelector((s = initialState) => {
    return {
      metrics: formatMetricData(s.homePage.matrics.data),
    };
  });

  return (
    <>
      <div className="flex my-3 flex-col md:flex-row">
        {metrics?.tbatl?.is_render && (
          <div className="md:px-4 md:w-1/2 w-full flex flex-col">
            <div className="inline-flex">
              <Text
                size="lg"
                fontWeight="semibold"
                text="Tokens Breaking All-Time Low"
                className="dark:text-white mb-4"
              />
              <a href="metric" target="_blank" rel="noopener noreferrer">
                <ExternalLinkIcon className="m-1 h-4 w-4 cursor-pointer dark:text-white" />
              </a>
            </div>

            <div className="flex-1 p-2 bg-white border dark:bg-dark-themeCard rounded-xl blur-sm mb-4 dark:border-gray-700 flex flex-col relative overflow-hidden">
              <table className="min-w-full divide-y dark:divide-gray-700">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0"
                    >
                      Coin
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold  text-gray-900 dark:text-dark-textPrimary"
                    >
                      Current Price
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold  text-gray-900 dark:text-dark-textPrimary"
                    >
                      New ATL
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {metrics &&
                    metrics?.tbatl?.rows.slice(0, 3).map((person, key) => (
                      <tr key={key}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-dark-textPrimary sm:pl-6 md:pl-0">
                          <a
                            href={person.url}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-600"
                          >
                            {person.Coin}
                          </a>
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900 dark:text-dark-textPrimary">
                          {person.CurrentPrice}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900 dark:text-dark-textPrimary">
                          {person.New_ATL}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <>
                <div className="flex flex-row-reverse mt-auto">
                  <div className="flex">
                    <ClockIcon className="w-4 dark:text-dark-textPrimary mr-2" />
                    <time
                      className="text-xs dark:text-dark-textPrimary"
                      dateTime={moment
                        .utc(metrics?.tbatl?.last_update)
                        .fromNow()}
                    >
                      {moment.utc(metrics?.tbatl?.last_update).fromNow()}
                    </time>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
        {metrics?.tbath?.is_render && (
          <div className="md:px-4 md:w-1/2 w-full flex flex-col">
            <div className="inline-flex">
              <Text
                size="lg"
                fontWeight="semibold"
                text="Tokens Breaking All-Time High"
                className="dark:text-white mb-4"
              />
              <a href="metric" target="_blank" rel="noopener noreferrer">
                <ExternalLinkIcon className="m-1 h-4 w-4 cursor-pointer dark:text-white" />
              </a>
            </div>
            <div className="flex-1 p-2 bg-white border dark:bg-dark-themeCard rounded-xl blur-sm mb-4 dark:border-gray-700 flex flex-col relative overflow-hidden">
              <table className="min-w-full divide-y dark:divide-gray-700 ">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0"
                    >
                      Coin
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold  text-gray-900 dark:text-dark-textPrimary"
                    >
                      Current Price
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold  text-gray-900 dark:text-dark-textPrimary"
                    >
                      New ATH
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {metrics &&
                    metrics?.tbath?.rows.slice(0, 3).map((person, key) => (
                      <tr key={key}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium  dark:text-dark-textPrimary sm:pl-6 md:pl-0">
                          <a
                            href={person.url}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-600"
                          >
                            {person.Coin}
                          </a>
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900 dark:text-dark-textPrimary">
                          {person.CurrentPrice}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900 dark:text-dark-textPrimary">
                          {person.New_ATL}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <>
                <div className="flex flex-row-reverse mt-auto">
                  <div className="flex">
                    <ClockIcon className="w-4 dark:text-dark-textPrimary mr-2" />
                    <time
                      className="text-xs dark:text-dark-textPrimary"
                      dateTime={moment
                        .utc(metrics?.tbath?.last_update)
                        .fromNow()}
                    >
                      {moment.utc(metrics?.tbath?.last_update).fromNow()}
                    </time>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
