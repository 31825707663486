import React from 'react';

type textProps = {
  size: string;
  color?: string;
  text: string;
  fontWeight?: string;
  className?: string;
};
export default function Text({
  size,
  color,
  text,
  fontWeight,
  className,
}: textProps): JSX.Element {
  const textStyle = `text-${size} text-${color} font-${fontWeight} ${className}`;
  return (
    <>
      <div className={textStyle}>{text}</div>
    </>
  );
}
