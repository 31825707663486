export const processToken = (topTradesChart) => {
  // to hold push result data
  let dataSet = [];
  if (topTradesChart && topTradesChart.length > 0) {
    for (let key of topTradesChart) {
      dataSet.push({
        name: key['f'][0]['v'],
        logo_url: key['f'][1]['v'],
        value: key['f'][2]['v'],
      });
    }
  }
  return dataSet;
};

export const proccessCollection = (_collections, type) => {
  let volumeArray = [];
  let walletArray = [];
  let txArray = [];
  let accumulationArray = [];
  // _collections = _collections?.data?.topNftCollectionsData;
  _collections &&
    _collections.map((item) => {
      if (item.vol_usd != null) volumeArray.push(parseInt(item.vol_usd));
      if (item.wallet_count != null)
        walletArray.push(parseInt(item.wallet_count));
      if (item.tx_count != null) txArray.push(parseInt(item.tx_count));
      if (item.accumulation != null)
        accumulationArray.push(parseFloat(item.accumulation));
    });
  _collections['max_vol'] = Math.max(...volumeArray);
  _collections['max_wallet_count'] = Math.max(...walletArray);
  _collections['max_tx_count'] = Math.max(...txArray);
  _collections['max_accumulation'] = Math.max(...accumulationArray);
  return _collections;
};
