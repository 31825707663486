import React from 'react';
import { NavLink } from 'react-router-dom';
const navObj = [
  // {
  //   id: 1,
  //   label: 'Dashboard',
  //   url: '/app/home/dashboard',
  // },
  {
    id: 2,
    label: 'Macro',
    url: '/app/home/welcome/macro',
  },
  {
    id: 3,
    label: 'Metrics',
    url: '/app/home/welcome/metrics',
  },
  {
    id: 4,
    label: 'News',
    url: '/app/home/welcome/news',
  },
  {
    id: 5,
    label: 'Docs',
    url: '',
  },
];
function Nav() {
  return (
    <div>
      <ul className="flex space-x-3">
        {navObj.map((item, key) => (
          <li key={key}>
            <NavLink
              to={item.url}
              className="text-blue-600 underline p-1 font-medium text-sm"
            >
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Nav;
