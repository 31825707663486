import { FilterButton } from 'Utilities';
import React from 'react';
import { sortOptions } from './Helper/constants';
import PropTypes from 'prop-types';

function SortOptions({ sort, setSort }) {
  return (
    <div>
      <div className="flex flex-wrap">
        {sortOptions.map((item, key) => (
          <div
            key={key}
            className="group cursor-pointer relative inline-block my-1 flex-shrink"
          >
            <FilterButton
              key={key}
              value={item.value}
              label={item.label}
              filter={sort}
              setFilter={setSort}
              tooltip={item.tooltip}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SortOptions;

SortOptions.propTypes = {
  sort: PropTypes.string,
  setSort: PropTypes.func,
};
