/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * api calls
 */
import axios from 'axios';
import { AS_PLATFORM_SERVICE_BASE_URL } from './constants';
import { getAxios } from './requestServer';

const SERVICE_PLATFORM_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `${AS_PLATFORM_SERVICE_BASE_URL}/user-platform`
    : 'http://localhost:8080/user-platform';
// const SERVICE_PLATFORM_BASE_URL =
//   process.env.REACT_APP_ALPHASTACK_WEB_USER_PLATFORM_SERVICE_URL;

export const getTopTradesChartData = async (
  group,
  type,
  duration,
  hideStandardCoins
) => {
  const url = `/toptrades_chart?wallet_group=${group}&token_operation=${type}&token_duration=${duration}&show_standard_coins=${hideStandardCoins}&${Date.now()}`;
  const response = await getAxios().get(url);
  return response;
};

export const getTopTradesUnlistedChartData = async (
  group,
  type,
  duration
  // hideUnlistedStandardCoins
) => {
  const url = `/toptrades_unlisted_chart?wallet_group=${group}&token_operation=${type}&token_duration=${duration}&${Date.now()}`;
  const response = await getAxios().get(url);
  return response;
};

export const getNftTokenData = async (contract_address) => {
  // const response = await getAxios().get(url, options);
  // return response;
  const options = {
    method: 'GET',
    headers: { 'X-API-KEY': 'dd2f50e248254e9799b415b92a122f4a' },
  };
  const url = `https://api.opensea.io/api/v1/asset_contract/${contract_address}`;
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        resolve(data);
      });
  });
};

export const getTopLPChartData = async (
  group,
  type,
  duration,
  hideStandardCoins,
  block_chain
) => {
  const url = `/toplp_chart/${block_chain}?wallet_group=${group}&token_operation=${type}&token_duration=${duration}&show_standard_coins=${hideStandardCoins}&${Date.now()}`;
  const response = await getAxios().get(url);
  return response;
};

export const getTopLpUnlistedChartData = async (group, type, duration) => {
  const url = `/toplp_unlisted_chart?wallet_group=${group}&token_operation=${type}&token_duration=${duration}&${Date.now()}`;
  const response = await getAxios().get(url);
  return response;
};

export const getTopIndicators = async () => {
  const url = `https://colintalkscrypto.com/cbbi/data/latest.json`;
  const response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export const getFngData = async () => {
  const url = `https://api.alternative.me/fng/`;
  const response = await axios
    .get(url)
    .then((res) => {
      return res.data.data[0];
    })
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export const getBtcLsData = async () => {
  const url = `https://api.icodrops.com/portfolio/api/exchange/longShortRates`;
  const response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export const getNewsData = async (kind, filter, page, currency) => {
  const url = `/crypto-panic-post?kind=${kind}&filter=${filter}&page=${page}&currency=${currency}`;
  const response = await getAxios()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export const getTradingSession = async (q) => {
  const url = `https://eodhistoricaldata.com/api/exchange-details/${q}?api_token=627b51ce64ac10.46014243`;
  const response = await getAxios()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
  return response;
};

export const getAlphaRequestFormPreload = async () => {
  const url = `/alpha-request-preload`;
  const response = await getAxios()
    .get(url)
    .then((res) => {
      return res.data.response[0].requestCategories;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const submitAlphaRequest = async (formData) => {
  const url = `/submit-alpha-request`;
  let response = await getAxios()
    .post(url, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const submitAlphaResponse = async (formData) => {
  const url = `/submit-alpha-response`;
  let response = await getAxios()
    .post(url, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const markVote = async (formData) => {
  const url = `/vote-response`;
  let response = await getAxios()
    .post(url, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getRelatedRequests = async (category_id, request_id) => {
  const url = `/related-alpha-request?category_id=${category_id}&request_id=${request_id}&${Date.now()}`;
  let response = await getAxios()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getResponses = async (request_id) => {
  const url = `/get-alpha-response?request_id=${request_id}&${Date.now()}`;
  let response = await getAxios()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const responseVoteByUser = async (user_address, alpha_request_id) => {
  const url = `/response-vote-by-user?user_address=${user_address}&alpha_request_id=${alpha_request_id}&${Date.now()}`;
  let response = await getAxios()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const markView = async (formData) => {
  const url = `/request-views`;
  let response = await getAxios()
    .post(url, formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const alphaResponseWinner = async (id) => {
  const url = `alpha-response-winner?response_id=${id}`;
  let response = await getAxios()
    .put(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAlphaRequests = async (
  search,
  duration,
  status,
  reward,
  commentedStat,
  category,
  isUser,
  user_address
) => {
  const url = `/get-alpha-requests?search=${search}&duration=${duration}&status=${status}&reward=${reward}&commentedStat=${commentedStat}&category=${category}&isUser=${isUser}&userAaddress=${user_address}&${Date.now()}`;
  let response = await getAxios()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const deleteAlphaRequest = async (request_id) => {
  const url = `alpha-request?request_id=${request_id}`;
  let response = await getAxios()
    .delete(url)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getAlphaRequest = async (id) => {
  const url = `/get-alpha-request?request_id=${id}`;
  let response = await getAxios()
    .get(url)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const handleSignIn = async (user_address) => {
  const url = `/user-sign-in`;
  let response = await getAxios()
    .post(url, { user_address })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getLoggedUser = async (user_address) => {
  const url = `/user-details?public_address=${user_address}`;
  let response = await getAxios()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const getTraderActivities = async (
  traderAddress,
  subDays,
  chain,
  txHash,
  volLimit,
  tradeType
) => {
  const url = `trader-activity?trader_address=${traderAddress}&date_sub=${subDays}&chain=${chain}&tx_hash=${txHash}&vol_limit=${volLimit}&trade_type=${tradeType}&${Date.now()}`;
  const response = await getAxios()
    .get(url)
    .then((_r) => {
      return { status: true, message: _r.data.message };
    })
    .catch((err) => {
      console.log('Failed to fetch activities ' + err);
      return {
        status: false,
        message: 'Failed to fetch activities ' + err,
      };
    });
  return response;
};

export const getTraderActivitiesSummary = async (
  traderAddress,
  subDays,
  chain,
  tradeType
) => {
  const url = `trader-activity-summary?trader_address=${traderAddress}&date_sub=${subDays}&chain=${chain}&trade_type=${tradeType}&${Date.now()}`;
  const response = await getAxios()
    .get(url)
    .then((_r) => {
      return { status: true, message: _r.data.message };
    })
    .catch((err) => {
      return {
        status: false,
        message: 'Failed to fetch activities ' + err,
      };
    });
  return response;
};

export const getTokenPrices = async (id, provider) => {
  const url = `token-price?id=${id}&provider=${provider}&${Date.now()}`;
  const response = await getAxios()
    .get(url)
    .then((_r) => {
      if (_r.data.success) return { status: true, message: _r.data.message };
      else return { status: false, message: [] };
    })
    .catch((err) => {
      console.log('Failed to fetch price ' + err);
      return {
        status: false,
        message: 'Failed to fetch price ' + err,
      };
    });
  return response;
};

export const getTokenHistoricalPrice = async (
  id,
  provider,
  token_contract,
  network
) => {
  const url = `token-ohlc-history?token_slug=${id}&provider=${provider}&${Date.now()}&token_contract=${token_contract}&network=${network}`;
  const response = await getAxios()
    .get(url)
    .then((_r) => {
      if (_r.status) return { status: true, message: _r.data.data };
      else return { status: false, message: [] };
    })
    .catch((err) => {
      return {
        status: false,
        message: 'Failed to fetch price ' + err,
      };
    });
  return response;
};

export const getLatestDexPairs = async (chainId, age, liquidity, maxFDV) => {
  const url = `https://alphastack-dex-latest-pairs-cige4pikta-uc.a.run.app/latest_dex_pairs?chain_id=${chainId}&min_liquidity=${liquidity}&max_fdv=${maxFDV}&max_age_hrs=${age}`;
  const response = await axios
    .get(url)
    .then((_r) => {
      return _r.data;
    })
    .catch((err) => {
      console.log('Failed to fetch dex pairs ' + err);
      return {
        status: false,
        message: 'Failed to fetch dex pairs ' + err,
      };
    });
  return response;
};

export const getLatestHotTokens = async (chain) => {
  const url = `latest-hot-tokens?chain=${chain}&${Date.now()}`;
  const response = await getAxios()
    .get(url)
    .then((_r) => {
      if (_r.data.success) return _r.data.message;
      else return { status: false, message: [] };
    })
    .catch((err) => {
      console.log('Failed to fetch price ' + err);
      return {
        status: false,
        message: 'Failed to fetch price ' + err,
      };
    });
  return response;
};

export const getLatestTradeDistribution = async (contract_address, chain) => {
  const url = `latest-trade-distribution?contract_address=${contract_address}&chain=${chain}&${Date.now()}`;
  const response = await getAxios()
    .get(url)
    .then((_r) => {
      if (_r.data.success) return _r.data.message;
      else return [];
    })
    .catch((err) => {
      console.log('Failed to fetch price ' + err);
      return [];
    });
  return response;
};

// ################################## USER PLATFORM APIs #########################################

export const getAllocatableCluster = async (user_id) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/user/subscription/${user_id}`;
  const response = await getAxios()
    .get(url)
    .then((r) => {
      return r.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const assignUserCluster = async (
  user_id,
  line_item_id,
  cluster_id,
  nickname
) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/assign-user-cluster`;

  const dataObj = {
    user_id: user_id,
    subscription_line_item_id: line_item_id,
    cluster_id: cluster_id,
    nickname,
  };

  // assignASCatalog();
  const _d = axios
    .post(url, dataObj)
    .then((_r) => {
      return _r.data;
    })
    .catch((err) => {
      return { status: false, message: err.response };
    });
  return _d;
};

export const triggerGkeClusterCreation = async (plan) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/user-cluster`;
  try {
    const result = await axios.post(url, { plan });
    return result.data.message;
  } catch (error) {
    console.error(error);
  }
};

export const triggerUserClusterConfiguration = async (
  userClusterId,
  startAtStep,
  endAtStep
) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/configure-user-clusters/${userClusterId}`;
  const data = { startAtStep, endAtStep };
  const result = await axios.post(url, data);
  return result.data;
};

export const getUserAssignedClusters = async (public_address) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/user-assigned-cluster?public_address=${public_address}&${Date.now()}`;

  const result = axios
    .get(url)
    .then((r) => {
      return r.data;
    })
    .catch((err) => {
      console.log(err);
      return { status: false, message: null };
    });
  return result;
};

export const getUserCluserStatus = async (public_address) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/user-cluster-last-wf-status?public_address=${public_address}&${Date.now()}`;

  const result = axios
    .get(url)
    .then((r) => {
      return r.data;
    })
    .catch((err) => {
      console.log(err);
      return { status: false, message: null };
    });

  return result;
};

export const getClusterCreateStatus = async (id) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/status/${id}?${Date.now()}`;
  const result = axios
    .get(url)
    .then((r) => {
      return r.data;
    })
    .catch((err) => {
      console.log(err);
      return { status: false, message: null };
    });

  return result;
};

export const getWorkflowGroupStatus = async (
  user_cluster_id,
  public_address,
  kind
) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/cluster-wf-group-status?user_clusters_id=${user_cluster_id}&public_address=${public_address}&kind=${kind}&${Date.now()}`;
  const result = axios
    .get(url)
    .then((r) => {
      console.log(r);
      return r.data;
    })
    .catch((err) => {
      console.log(err);
      return { status: false, message: null };
    });

  return result;
};

export const getWorkFlowStatus = (user_cluster_id) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/user-cluster-wf-status?user_clusters_id=${user_cluster_id}&${Date.now()}`;
  const result = axios
    .get(url)
    .then((r) => {
      return r.data;
    })
    .catch((err) => {
      console.log(err.response.data);
      return { status: false, message: null };
    });

  return result;
};

export const getUserClusterList = async (user_id, type) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/user-clusters?user_id=${user_id}&type=${type}`;

  let response = await axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const configureVault = async (data) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/vault/configure?${Date.now()}`;
  const response = await axios
    .post(url, data)
    .then((_r) => {
      return _r.data;
    })
    .catch((err) => {
      console.log(err.response.data);
      return { status: false, message: null };
    });
  return response;
};

export const getOidcAuthUrl = (data) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/oidc/auth-url`;
  const result = axios
    .post(url, data)
    .then((r) => {
      const auth_url = new URL(r?.data?.message?.data?.auth_url);
      if (auth_url) return { status: true, auth_url };
      else return { status: false, auth_url: null };
    })
    .catch((err) => {
      console.log(err);
      return { status: false, auth_url: null };
    });
  return result;
};

export const getOidcToken = async (data) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/oidc/client-token`;
  const result = axios
    .post(url, data)
    .then(() => {
      return true;
    })
    .catch((err) => {
      // setToken(undefined);
      console.log('FAILED TO FETCH TOKEN', err);
      return false;
    });
  return result;
};

export const getUserClusterByName = async (cluster_name) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/user-cluster-by-name?cluster_name=${cluster_name}`;
  const result = axios
    .get(url)
    .then((r) => {
      return r.data;
    })
    .catch((err) => {
      console.log('FAILED TO FETCH USER CLUSTER', err);
      return {};
    });
  return result;
};

export const getClusterLastConfig = async (last_config) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/last-config?last_config=${last_config}`;
  const result = axios
    .get(url)
    .then((r) => {
      return r?.data?.config_id || null;
    })
    .catch((err) => {
      console.log('FAILED TO FETCH USER CLUSTER', err);
      return null;
    });
  return result;
};

export const getUserDataCatalogs = async (user_id) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/catalog/user-catalog?user_id=${user_id}&${Date.now()}`;

  const response = await axios
    .get(url)
    .then((_r) => {
      return { status: _r.data?.status, message: _r.data.message };
    })
    .catch((err) => {
      console.log('Failed to fetch user data catalog ' + err);
      return {
        status: false,
        message: 'Failed to fetch user data catalog ' + err,
      };
    });
  return response;
};

export const createUserDataCatalog = async (dataObj) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/catalog/user-catalog`;
  const response = await axios
    .post(url, dataObj)
    .then((_r) => {
      console.log('create user data catalog');
      return _r;
    })
    .catch((err) => {
      console.log(err);
      return _r;
    });

  return response;
};

export const getUserClusterByUci = async (uci) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/user-cluster-by-uci?uci=${uci}`;
  try {
    const response = await axios.get(url);
    return response?.data?.data;
  } catch (err) {
    console.log('axios error' + err.message);
    return null;
  }
};

export const getLastIndexed = async (chainId, resource) => {
  const url = `/lastIndexed?chainId=${chainId}&resource=${resource}&${Date.now()}`;
  const response = await getAxios()
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(`Last Indexed Error --------- ${error}`);
      return 'unknown';
    });
  return response;
};
export const getUserClusterConfigStatus = async (uci) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/cluster/user-cluster-config-status?uci=${uci}`;
  try {
    const response = await axios.get(url);
    return response?.data?.data;
  } catch (err) {
    console.log('axios error' + err.message);
    return null;
  }
};

export const checkCatalogAvailability = async (catalog_name) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/catalog/is-name-available?catalog_name=${catalog_name}`;
  try {
    const response = await axios.get(url);
    if (response?.data?.status) return response?.data?.isAvailable;
    else return null;
  } catch (err) {
    console.log('axios error' + err.message);
    return null;
  }
};

export const testCatalogConnection = async (catalog) => {
  const url = `${SERVICE_PLATFORM_BASE_URL}/catalog/is-name-available`;
  try {
    const response = await axios.post(url, catalog);
    if (response?.data?.status) return response?.data?.isAvailable;
    else return null;
  } catch (err) {
    console.log('axios error' + err.message);
    return null;
  }
};

// ################################## USER PLATFORM APIs #########################################
