import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import { getLastIndexed, getLatestHotTokens } from 'Helper/services';
import { ContentWrapper, MenuWrapper, Refresh, Select, Table } from 'Utilities';
import DataLastIndexed from 'Utilities/DataLastIndexed/DataLastIndexed';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { column } from './header';

const DEX_PAIR_AGE = [{ text: 'Pair Created Last 12 Hours', value: 12 }];
const TRADE_ACTIVITY_AGE = [{ text: 'Trade Activity Last 2 Hours', value: 2 }];
function LatestHotTokens() {
  const [dexPairAge, setDexPairAge] = useState(12);
  const [tradeActivityAge, setTradeActivityAge] = useState(2);
  const [latestHotTokens, setLatestHotTokens] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastIndexed, setLastIndexed] = useState('');

  const dexPairAgeProps = Object.freeze({
    optionsObj: DEX_PAIR_AGE,
    state: dexPairAge,
    setState: setDexPairAge,
  });

  const tradeActivityAgeProps = Object.freeze({
    optionsObj: TRADE_ACTIVITY_AGE,
    state: tradeActivityAge,
    setState: setTradeActivityAge,
  });
  // const { block_chain } = useSelector((s = initialState) => {
  //   return {
  //     block_chain: s.currentBlockChain.selectedBlockChain || 0,
  //   };
  // });
  const block_chain = CHAINS[getUrlParam('chain')];

  const processData = (data) => {
    let _d = [];
    let _r = {};
    if (data?.length > 0) {
      for (let itm of data) {
        _d.push({
          latest_token_pair_creation_age: itm['f'][0]['v'],
          recent_top_performing_traders: itm['f'][1]['v'],
          recent_top_performing_traders_exited: itm['f'][2]['v'],
          whales: itm['f'][3]['v'],
          smart_money: itm['f'][4]['v'],
          name: itm['f'][5]['v'],
          symbol: itm['f'][6]['v'],
          token_contract: itm['f'][7]['v'],
          latest_token_traded_age: itm['f'][8]['v'],
          latest_token_pair_creation_timestamp: itm['f'][9]['v'],
          latest_token_traded_timestamp: itm['f'][10]['v'],
        });
      }

      const latestPairTradesTSArray = [];
      _d.map((itm) => {
        if (itm.recent_top_performing_traders !== null)
          latestPairTradesTSArray.push(
            itm.latest_token_traded_timestamp * 1000
          );
      });
      _r = {
        data_last_indexed: moment
          .utc(Math.max(...latestPairTradesTSArray))
          .fromNow(),
        // data_last_indexed: latestPairTradesTSArray,
      };
    }
    _r = { ..._r, top_performers: _d };
    console.log('return data', _r);
    return _r;
  };
  const getLatestHotTokenData = () => {
    setLoading(true);
    getLatestHotTokens(block_chain)
      .then((_r) => {
        console.log(_r);
        const processedData = processData(_r);
        setLatestHotTokens(processedData.top_performers);
      })
      .catch((err) => {
        console.error(err);
        setLatestHotTokens([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(async () => {
    getLatestHotTokenData();
    const res = await getLastIndexed(block_chain, 'latest-hot-tokens');
    setLastIndexed(res?.data?.metadata?.data_last_indexed_timestamp_utc);
  }, [refresh]);

  const renderFilters = () => {
    return (
      <div className="flex justify-between p-4">
        <div className="flex flex-wrap sm:space-x-1 mr-1 gap-2">
          <Select {...dexPairAgeProps} />
          <Select {...tradeActivityAgeProps} />
        </div>
        <div className="hidden sm:block">
          <div className="flex justify-between p-4">
            <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
            <div className="ml-4">
              <Refresh
                state={refresh}
                setState={setRefresh}
                loadingbutton={loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <div className="lg:px-4">
        <Table data={latestHotTokens} col={column} isloading={loading} />
      </div>
    );
  };
  return (
    <ContentWrapper>
      <div className="hidden sm:block">{renderFilters()}</div>
      <div className="sm:hidden flex items-center flex-row-reverse py-3 px-3">
        <div className="flex space-x-1">
          <div className="">
            <Refresh
              state={refresh}
              setState={setRefresh}
              loadingbutton={loading}
            />
          </div>
          <MenuWrapper>
            <div className="">{renderFilters()}</div>
          </MenuWrapper>
        </div>
        <div className="mr-2 my-auto">
          <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
        </div>
      </div>
      {renderTable()}
    </ContentWrapper>
  );
}

export default LatestHotTokens;
