import { SCAN_ADDRESS } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import { CoinName, Etherscan } from 'Utilities';
import PublicLinks from 'Utilities/Coins/publicLinks';

export const column = [
  {
    Header: 'Token Name',
    accessor: 'token_name',
    Cell: (cell) => (
      <div className="flex items-center justify-betwee space-x-6">
        <CoinName
          isSubtring={true}
          slug={cell.row.values.token_contract}
          provider={''}
          name={cell.row.values.token_name}
          logo_url={''}
          showLogo={false}
        />
      </div>
    ),
    disableSortBy: true,
    Filter: '',
  },
  {
    Header: 'Contract Address',
    accessor: 'token_contract',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.token_contract}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    Filter: '',
  },
  {
    Header: 'External Links',
    accessor: 'external_links',
    Cell: (cell) => (
      <div className="">
        <PublicLinks token_address={cell.row.values.token_contract} />
      </div>
    ),
    Filter: '',
  },

  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: (cell) => cell.row.values.symbol,
    Filter: '',
  },
  {
    Header: 'Tx Count',
    accessor: 'tx_count',
    Cell: (cell) => cell.row.values.tx_count,
    Filter: '',
  },
  {
    Header: 'Volume',
    accessor: 'volume',
    Cell: (cell) => (
      <>
        {cell.row.values.volume
          ? formatValue('format', cell.row.values.volume)
          : '-'}
      </>
    ),
    Filter: '',
  },
  {
    Header: 'Volume USD',
    accessor: 'volume_usd',
    Cell: (cell) => (
      <>{cell.row.values.volume_usd ? '$' + cell.row.values.volume_usd : '-'}</>
    ),
    Filter: '',
  },
];

export const traderAddressCols = [
  {
    Header: 'Trader Addresses',
    accessor: 'trader_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.trader_address}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    Filter: '',
  },
];
