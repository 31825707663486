import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function PillTabNotableTraders({ tabOptions, state, setState }) {
  return (
    <div className="p-8">
      <div className="grid grid-cols-2 gap-4">
        {tabOptions?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => setState(item?.tab)}
              className={`${
                state === item?.tab
                  ? 'bg-blue-800'
                  : 'border border-solid border-blue-800'
              } p-4 text-black dark:text-white rounded-2xl text-center cursor-pointer`}
            >
              {item?.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PillTabNotableTraders;

PillTabNotableTraders.propTypes = {
  tabOptions: PropTypes.array,
  state: PropTypes.string,
  setState: PropTypes.func,
};
