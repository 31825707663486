/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react';
import { useAuth } from 'Authentication/authProvider';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThemeToggle } from 'Utilities';
import LogoMarkLight from '../../../Assets/Images/Alpha-Logo.light.png';
import LogoMinified from '../../../Assets/Images/Alpha-Logo.minified.png';
import LogoMarkDark from '../../../Assets/Images/Alpha-Logo.png';

const styles = {
  dark: {
    heroBgGradient:
      'bg-gradient-to-r from-heroBgColorPrimaryDark to-heroBgColorSecondaryDark',
  },
  light: {
    heroTextGradient:
      'bg-gradient-to-r from-heroTextPrimaryLight via-heroTextSecondaryLight to-heroTextTertiaryLight bg-clip-text text-transparent',
  },
};
export default function Navbar() {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const auth = useAuth();
  const navigate = useNavigate();

  const login = async () => {
    auth.isLoggedIn() && auth.isVerified()
      ? navigate('/app/home/welcome/quick-access')
      : await auth.startAuthentication();
  };
  return (
    <>
      <Disclosure as="nav" className={`bg-white  dark:bg-navbarBgDark`}>
        {({ open }) => (
          <>
            <div className="container max-w-8xl mx-auto  lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex-shrink-0 flex items-center">
                  <div className="flex ml-2">
                    <div className="sm:hidden block">
                      <img src={LogoMinified} className="w-10" alt="logo" />
                    </div>
                    <div className="hidden sm:block">
                      <img
                        src={
                          theme === 'dark' || theme === undefined
                            ? LogoMarkDark
                            : LogoMarkLight
                        }
                        className="w-40"
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="">
                    <button
                      className="inline-flex items-center rounded-md border border-transparent bg-theme px-5 py-3 text-sm font-semibold leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={() => login()}
                    >
                      {auth.isLoggedIn() && auth.isVerified()
                        ? 'Go to app'
                        : 'Sign in'}
                    </button>
                  </div>
                  <div className="ml-4">
                    <ThemeToggle />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </>
  );
}
