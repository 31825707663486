export const token_limit = [
  { text: 'Top 10', value: '10' },
  { text: 'Top 20', value: '20' },
  { text: 'Top 50', value: '50' },
];

export const token_group = [
  { text: 'All', value: 'all' },
  { text: 'Smart Money', value: 'smart_money' },
  { text: 'Whales', value: 'whales' },
];

export const token_type = [
  { text: 'Buy', value: 'bought' },
  { text: 'Sell', value: 'sold' },
];

export const token_duration = [
  { text: '1 Hour', value: '1hr' },
  { text: '6 Hours', value: '6hr' },
  { text: '12 Hours', value: '12hr' },
  { text: '24 Hours', value: '24hr' },
  { text: '1 Week', value: 'week' },
  { text: '1 Month', value: 'month' },
];

export const filterOptions = [
  {
    value: 'Vol (USD)',
    key: 'volume',
    tooltip: 'Fetch by most Volume (USD) ',
  },
  {
    value: 'Wallet Ct',
    key: 'wallet_count',
    tooltip: 'Fetch by most Wallets Count',
  },
  {
    value: 'Tx Ct',
    key: 'tx_count',
    tooltip: 'Fetch by most Transactions Count',
  },
  {
    value: 'Tx-Per-Wallet',
    key: 'accumulation_count',
    tooltip: 'Fetch by most Tx-per-Wallet Count',
  },
];
