import React from 'react';
import Summary from './summary';
import TokenChart from './tokenChart';
import PropTypes from 'prop-types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TokenOverview(props) {
  const tokenInfo = props.tokenInfo;
  return (
    <div className="dark:bg-dark-themePrimary bg-light-themeSecondary">
      <div className="grid grid-cols-1 lg:grid-cols-3">
        <div className="lg:col-span-2 rounded-md p-8 ml-8">
          <TokenChart tokenInfo={tokenInfo} />
        </div>
        <div>
          <Summary />
        </div>
      </div>
    </div>
  );
}

TokenOverview.propTypes = {
  tokenInfo: PropTypes.any,
};

export default TokenOverview;
