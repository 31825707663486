/* eslint-disable react/prop-types */
import { Menu, Transition } from '@headlessui/react';
import {
  CheckIcon,
  FilterIcon as FilterIconSolid,
} from '@heroicons/react/solid';
import { FilterIcon as FilterIconOutline } from '@heroicons/react/outline';
import { Fragment, useMemo } from 'react';

function SelectFilter({ column }) {
  const { filterValue, setFilter, preFilteredRows, id } = column;
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full">
            <span className="sr-only">Open options</span>
            {filterValue ? (
              <FilterIconSolid
                className="h-4 w-4 mx-2 text-gray-600 dark:text-gray-300 mt-1"
                aria-hidden="true"
              />
            ) : (
              <FilterIconOutline
                className="h-4 w-4 mx-2 text-gray-600 dark:text-gray-300 mt-1"
                aria-hidden="true"
              />
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-40 origin-top-right absolute right-4 mt-2 w-auto rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item className="relative py-3 pl-8 pr-4 space-x-2 px-4 text-xs capitalize cursor-pointer">
                {({ active }) => (
                  <div onClick={() => setFilter('')} className="">
                    {filterValue === undefined && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
                        <CheckIcon className="w-4" aria-hidden="true" />
                      </span>
                    )}
                    <span>All</span>
                  </div>
                )}
              </Menu.Item>
              {options.map((itm, key) => (
                <Menu.Item
                  key={key}
                  className="relative py-3 pl-8 pr-4 space-x-2 px-4 text-xs capitalize cursor-pointer"
                >
                  {({ active }) => (
                    <div onClick={() => setFilter(itm)} className="">
                      {itm === filterValue ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
                          <CheckIcon className="w-4" aria-hidden="true" />
                        </span>
                      ) : null}
                      <span>{itm}</span>
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default SelectFilter;
