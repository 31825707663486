import { CurrencyDollarIcon } from '@heroicons/react/outline';
import React from 'react';
import Logo from '../../Assets/Images/Alpha-Logo.minified.png';

function Spinner() {
  return (
    <div className="">
      <div>
        <img src={Logo} className="w-6 animate-ping" alt="Loading" />
      </div>
    </div>
  );
}

export default Spinner;
