/* eslint-disable react/prop-types */
import { Popover, Transition } from '@headlessui/react';
import {
  getTraderActivities,
  getTraderActivitiesSummary,
} from 'Helper/services';
import { useLocalStorage } from 'Hooks';
import { Refresh, Spinner, Table } from 'Utilities';
import PageTitle from 'Utilities/PageTitle';
import SelectOption from 'Utilities/SelectOption/select';
import { Arbitrum, Binance, EthereumLogo, PolygonLogo } from 'Utilities/images';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedChain } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { token_vol_limit } from '../../Defi/LatestTrades/Helper/constants';
import { column } from './header';
import { summaryColumn } from './summaryHeader';
import TraderActivityToggle from '../traderActivityToggle';
import { InformationCircleIcon } from '@heroicons/react/outline';

const BLOCKCHAINS = [
  {
    label: 'Ethereum',
    value: 0,
    icon: EthereumLogo,
  },
  {
    label: 'Polygon',
    value: 1,
    icon: PolygonLogo,
  },
  {
    label: 'Arbitrum',
    value: 2,
    icon: Arbitrum,
  },
  {
    label: 'Binance',
    value: 3,
    icon: Binance,
  },
];
const OPTIONS_OBJ = [
  { text: '1 day', value: 1 },
  { text: '3 days', value: 3 },
  { text: '7 days', value: 7 },
  { text: '15 days', value: 15 },
  { text: '30 days', value: 30 },
  { text: '60 days', value: 60 },
  { text: '90 days', value: 90 },
  { text: '180 days', value: 180 },
];

const TRADETYPES = [
  { text: 'Dex', value: 'dex' },
  { text: 'Other', value: 'others' },
];

const TRADER_ADDRESS = `0x201b5Abfd44A8F9b75F0fE1BaE74CDaC7675E54B`;

function TraderExplorer() {
  const node_env = process.env.NODE_ENV;
  const [traderAddress, setTraderAddress] = useState(
    node_env === 'development' ? TRADER_ADDRESS : ''
  );
  const [chain, setChain] = useState(0);
  const [txHash, setTxHash] = useState('');
  const [subDays, setSubDays] = useState('30');
  const [loading, setLoading] = useState(false);
  const [traderActivities, setTraderActivities] = useState([]);
  const [traderActivitiesSummary, setTraderActivitiesSummary] = useState([]);
  const [currentChain, setCurrentChain] = useLocalStorage(
    'trader_ac-chain',
    BLOCKCHAINS[0]
  );
  const [error, setError] = useState(null);
  const [noDataMessage, setNoDataMessage] = useState(
    'Please enter a trader address and hit go'
  );
  const [refresh, setRefresh] = useState(false);
  // const [group, setGroup] = useLocalStorage('td_group', 'all');
  const [volLimit, setVolLimit] = useLocalStorage('td_vol_limit', '1');
  const [tradeType, setTradeType] = useLocalStorage('ta_tt', 'dex');
  const selectInputRef = useRef();

  const dispatch = useDispatch();

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const groupProps = {
    optionsObj: OPTIONS_OBJ,
    state: subDays,
    setState: setSubDays,
  };

  const [toggleState, setToggleState] = useState(1);

  const processData = (data) => {
    let dataSet = [];
    if (data && data.length > 0) {
      for (let key of data) {
        dataSet.push({
          transaction_hash: key['f'][0]['v'],
          trade_type: key['f'][1]['v'],
          trade_action: key['f'][2]['v'],
          trade_contract: key['f'][3]['v'],
          token_name: key['f'][4]['v'],
          token_symbol: key['f'][5]['v'],
          token_amount: key['f'][6]['v'],
          token_value_usd_txdate: key['f'][7]['v'],
          using_ethvalue_txdate: key['f'][8]['v'],
          token_value_usd_current: key['f'][9]['v'],
          using_ethvalue_current: key['f'][10]['v'],
          dex: key['f'][11]['v'],
          eth_value: key['f'][12]['v'],
          block_timestamp: key['f'][13]['v'],
        });
      }
    }
    return dataSet;
  };

  const processSummaryData = (data) => {
    let dataSet = [];
    if (data && data.length > 0) {
      for (let key of data) {
        dataSet.push({
          transaction_hash: key['f'][0]['v'],
          token_name: key['f'][1]['v'],
          token_symbol: key['f'][2]['v'],
          buy_token_amount: key['f'][3]['v'],
          sell_token_amount: key['f'][4]['v'],
          buy_token_value_usd_txdate: key['f'][5]['v'],
          sell_token_value_usd_txdate: key['f'][6]['v'],
          buy_token_value_usd_current: key['f'][7]['v'],
          sell_token_value_usd_current: key['f'][8]['v'],
        });
      }
    }
    return dataSet;
  };

  const handleSelectChain = (item, close) => {
    setChain(item.value);
    setCurrentChain(item);
    close();
  };
  const handleInputChange = (e) => {
    if (e.target.value.length > 3) {
      setTraderAddress(e.target.value);
      setError('');
    }
  };

  const handleGo = () => {
    if (!traderAddress) {
      setError('Please enter a trader address ');
      return;
    }
    setLoading(true);
    getTraderActivities(
      traderAddress,
      subDays,
      currentChain.value,
      txHash,
      volLimit,
      tradeType
    )
      .then((_r) => {
        setTraderActivities(processData(_r.message));
      })
      .catch((err) => {
        console.error(err);
        setNoDataMessage('No activities found');
      })
      .finally(() => {
        setLoading(false);
      });
    getTraderActivitiesSummary(
      traderAddress,
      subDays,
      currentChain.value,
      tradeType
    )
      .then((_r) => {
        setTraderActivitiesSummary(processSummaryData(_r.message));
      })
      .catch((err) => {
        setNoDataMessage('No Acitivities Found');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setSelectedChain(chain));
  }, [chain]);

  useEffect(() => {
    if (traderAddress) handleGo();
  }, [refresh, volLimit, tradeType]);

  const renderInput = () => {
    return (
      <div className="relative">
        <input
          type="input"
          className="block border dark:border-gray-700 p-2 h-10 rounded dark:text-dark-textPrimary text-xs w-80 sm:w-96  dark:bg-dark-themeBgDark focus:outline-none"
          placeholder="Enter wallet address and hit go"
          value={traderAddress}
          onChange={(e) => handleInputChange(e)}
          //   onBlur={() => setTokenSearch(false)}
        />
        <span className="text-red-500 absolute text-xs mt-2 opacity-60">
          {error && error}
        </span>
      </div>
    );
  };

  const renderBlockChainSelector = () => {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              onClick={() => (open ? close() : open())}
              className="flex group space-x-3 border dark:border-gray-700 px-4 py-2 h-10 rounded"
            >
              <img src={currentChain.icon} className="w-5 h-5" />
              <span className="text-gray-500 dark:text-white text-sm capitalize">
                {currentChain.label}
              </span>
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute  z-10 mt-2 w-full max-w-lg sm:px-0 bg-white dark:bg-dark-themeBgDark border dark:border-gray-700 rounded p-2 dark:text-white ">
                {({ close }) => (
                  <>
                    {BLOCKCHAINS.map((item, key) => (
                      <div
                        key={key}
                        className="flex items-center p-2 cursor-pointer space-x-2"
                        onClick={() => handleSelectChain(item, close)}
                      >
                        <img src={item.icon} className="w-4" />
                        <span className="text-sm">{item.label}</span>
                      </div>
                    ))}
                  </>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderButton = () => {
    return (
      <button
        className="flex items-center rounded-md border border-transparent bg-blue-600 px-4 h-10 text-sm font-normal text-white cursor-pointer shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        onClick={handleGo}
      >
        Go
      </button>
    );
  };

  const renderSubDays = () => {
    return (
      <div className="">
        <SelectOption {...groupProps} />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex flex-col lg:flex-row py-2 space-x-2 items-center gap-2 lg:gap-0">
          {renderInput()}
          <div className="flex space-x-2">
            {renderSubDays()}
            {renderBlockChainSelector()}
            {renderButton()}
          </div>
        </div>
      </>
    );
  };

  const renderFilter = () => {
    return (
      <div>
        <div className="p-2 flex justify-between items-center">
          <div className="flex items-center flex-wrap space-x-1 mb-2 lg:mt-2">
            {/* <div className="bg-gray-100  rounded-md flex items-center p-2 my-1 dark:bg-gray-700 border-gray-700 text-dark-textPrimary">
              <FilterIcon className="h-4 w-4" />
            </div> */}
            {/* <div>
              <SelectOption
                optionsObj={token_group}
                state={group}
                setState={setGroup}
              />
            </div> */}
            <div>
              <SelectOption
                optionsObj={token_vol_limit}
                state={volLimit}
                setState={setVolLimit}
              />
            </div>
            <div>
              <SelectOption
                optionsObj={TRADETYPES}
                state={tradeType}
                setState={setTradeType}
              />
            </div>

            {/* <Select
              closeMenuOnSelect={false}
              isMulti
              // value={tradeType}
              defaultValue={TRADETYPES[0]}
              options={TRADETYPES}
              styles={colourStyles(theme === 'dark')}
              onChange={handleChange}
              ref={selectInputRef}
              placeholder={<div className="text-xs">Trade Type...</div>}
              className="text-xs dark:bg-dark-themeBgDark"
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator,
                DropdownIndicator,
                MultiValueRemove,
              }}
            /> */}

            {/* <div className="flex">
              <Search {...props} />
            </div> */}
          </div>
          <div className="mx-2 flex row-auto">
            <div className="mr-4">
              <TraderActivityToggle
                state={toggleState}
                setState={setToggleState}
              />
            </div>
            <div className="mt-2">
              <Refresh
                state={refresh}
                setState={setRefresh}
                loadingbutton={loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="dark:bg-dark-themePrimary mt-2">
        {renderFilter()}
        {toggleState === 1 ? (
          <>
            <p className="bg-gray-300 text-gray-500 dark:bg-dark-bgMutedDark dark:text-white capitalize p-2 mb-4 w-5/12 text-xs">
              <InformationCircleIcon className="lg:h-4 w-4 ml-0.5 inline-flex" />
              {
                " You're viewing the Summary tab. Switch to the Details tab for specific trade information"
              }
            </p>
            <Table
              data={traderActivitiesSummary}
              col={summaryColumn}
              isloading={loading}
            />
          </>
        ) : (
          <Table data={traderActivities} col={column} isloading={loading} />
        )}
      </div>
    );
  };

  const renderNoData = () => {
    if (traderActivities.length === 0) {
      return (
        <div className="flex items-center justify-center h-102">
          {loading ? (
            <Spinner />
          ) : (
            <div className="mt-2 text-base text-gray-200 dark:text-gray-600 italic opacity-50">
              {noDataMessage}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <PageTitle title="Trader Activity" subtitle="Search by trader address" />
      <div className="px-4 md:px-6 lg:px-8">
        {renderHeader()}
        {/* {renderNoData()} */}
        {renderContent()}
      </div>
    </>
  );
}

export default TraderExplorer;
