export function isRequired(value) {
  if (value) return true;
  if (!value) return false;
}

export function isValidUrl(_string) {
  const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
  return matchPattern.test(_string);
}

export function sourceValidUrl(_string, tokenUrlType) {
  const isValidUrl = false;
  const coingeckoMatchPattern =
    /^https:\/\/www\.coingecko\.com\/en\/coins\/[a-zA-Z]/i;
  // const openSeaMatchPattern = /^https:\/\/opensea\.io\/assets\/[a-zA-Z]\/[0-9]/;
  // const openSeaColMatchPattern =
  //   /^https:\/\/opensea\.io\/collection\/[a-zA-z0-9]\/[0-9]/;

  const openSeaMatchPattern = /^https:\/\/opensea\.io\/assets\/[0-9a-zA-Z]/;

  const openSeaColMatchPattern =
    /^https:\/\/opensea\.io\/collection\/[a-zA-Z-]/i;

  if (tokenUrlType == 'token') {
    if (coingeckoMatchPattern.test(_string)) {
      return true;
    } else {
      return false;
    }
  } else if (tokenUrlType == 'nft') {
    if (
      openSeaMatchPattern.test(_string) ||
      openSeaColMatchPattern.test(_string)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const isAlpha = (ch) => {
  return /^[A-Z]$/i.test(ch);
};

export const catalogFormValidator = (catalogConf, dbConfig) => {
  const catalogName = catalogConf?.catalog_name.replaceAll(/[_ \s]/g, '');
  let errors = {};
  if (!dbConfig.hasOwnProperty(catalogName + 'host'))
    errors['host'] = 'Host address is required';
  // else errors['host'] = '';

  if (!dbConfig.hasOwnProperty(catalogName + 'port'))
    errors['port'] = 'Port is required';
  // else errors['port'] = '';

  if (!dbConfig.hasOwnProperty(catalogName + 'username'))
    errors['username'] = 'Username is required';
  // else errors['username'] = '';

  if (!dbConfig.hasOwnProperty(catalogName + 'password'))
    errors['password'] = 'Password is required';
  // else errors['password'] = '';

  if (!catalogConf.hasOwnProperty('catalog_name'))
    errors['catalog_name'] = 'Please enter a catalog name';
  // else errors['catalog_name'] = '';

  if (!catalogConf.hasOwnProperty('description'))
    errors['description'] = 'Please enter a description';
  // else errors['description'] = '';

  return errors;
};
