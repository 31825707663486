import {
  LightningBoltIcon,
  StarIcon,
  StatusOnlineIcon,
  StopIcon,
} from '@heroicons/react/outline';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';

const stats = [
  {
    name: 'Total Points',
    stat: '50000',
    previousStat: '70,946',
    change: '12%',
    changeType: 'increase',
    icon: StatusOnlineIcon,
    badgeColor: 'red',
  },
  {
    name: 'Alpha Score',
    stat: '30000',
    previousStat: '56.14%',
    change: '2.02%',
    changeType: 'increase',
    icon: StopIcon,
    badgeColor: 'blue',
  },
  {
    name: 'Wallet Score',
    stat: '20000',
    previousStat: '28.62%',
    change: '4.05%',
    changeType: 'decrease',
    icon: StarIcon,
    badgeColor: 'green',
  },
  {
    name: 'Redeemable Tokens',
    stat: '50',
    previousStat: '28.62%',
    change: '4.05%',
    changeType: 'decrease',
    icon: LightningBoltIcon,
    badgeColor: 'purple',
  },
];

export default function Rewards() {
  return (
    <div className="px-2">
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="sm:hidden flex flex-row-reverse">
          <div className="float-right">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent text-xs md:text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
            >
              Redeem Tokens
            </button>
          </div>
        </div>
        <div className="">
          <dl className="mt-5 grid grid-cols-2 sm:grid-cols-5 gap-2 overflow-hidden sm:divide-y sm:divide-gray-200 dark:divide-gray-700 md:grid-cols-5 md:divide-y-0">
            {stats.map((item) => (
              <div
                key={item.name}
                className={`p-3 sm:p-6 bg-gradient-to-r bg-gray-100 dark:bg-dark-bgMutedDark rounded-xl flex flex-col sm:flex-row  space-y-2 sm:space-y-0 sm:space-x-2 blur-sm`}
              >
                <div
                  className={`w-10 h-10 flex justify-center items-center p-2 sm:p-3 rounded-md bg-${item.badgeColor}-100`}
                >
                  <item.icon className="w-6 h-6 dark:text-gray-800" />
                </div>

                <div>
                  <dt className="text-sm sm:text-xs font-semibold text-gray-900 dark:text-dark-textPrimary">
                    {item.name}
                  </dt>
                  <dd className="mt-1 flex justify-between md:block lg:flex">
                    <div className="flex items-baseline sm:text-md font-semibold text-blue-600 dark:text-dark-textPrimary">
                      {item.stat}
                    </div>
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
