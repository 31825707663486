import { getUserAssignedClusters } from 'Helper/services';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { Spinner } from 'Utilities';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClusterList from './clusterList';
import CreateCluster from './createCluster';

const CONFIG_STATUS = ['user_action_needed', 'ready'];
function Cluster() {
  const { user_address } = useGetCurrentUser();

  const [clusters, setClusters] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getClusters = async () => {
    getUserAssignedClusters(user_address)
      .then((r) => {
        if (r.status) {
          setClusters(r.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderLoading = () => {
    return (
      <>
        {loading && (
          <div className="flex h-screen justify-center items-center py-auto">
            <Spinner />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    getClusters();
  }, []);

  if (clusters && clusters.length === 1) {
    if (clusters[0]?.user_cluster_config_status === 'ready') {
      return (
        <div className="p-2">
          <ClusterList />
        </div>
      );
    }
    if (!CONFIG_STATUS.includes(clusters[0]?.user_cluster_config_status)) {
      navigate(
        `/app/platforms/configuration/user-cluster/configure?id=${clusters[0]?.user_clusters_id}&cid=${clusters[0]?.cluster_id}&step=initial-setup`
      );
    }
    if (clusters[0]?.user_cluster_config_status === 'user_action_needed') {
      navigate(
        `/app/platforms/configuration/user-cluster/configure?id=${clusters[0]?.user_clusters_id}&cid=${clusters[0]?.cluster_id}&step=vault-operations`
      );
    }
  }

  return (
    <>
      {renderLoading()}
      <div>{!loading && clusters?.length === 0 && <CreateCluster />}</div>
      <div>{!loading && clusters?.length > 1 && <ClusterList />}</div>
    </>
  );
}

export default Cluster;
