import { getUrlParam } from 'Helper/getUrlPram';
import useQuery from 'Hooks/useQuery';
import { useState } from 'react';
import PageNotFound from 'Utilities/ErrorPages/404';
import PillTab from 'Utilities/PillTab/pillTab';
import { useLocalStorage } from '../../Hooks/useStorage';
import LatestTrades from './LatestTrades/latestTrades';
import NFTCollections from './NFTCollections/nftCollections';
import NftDrops from './NFTDrops/nftDrops';
import NftTrades from './NFTTrades/nftTrades';
import { InformationCircleIcon } from '@heroicons/react/outline';

function Nft() {
  let query = useQuery();

  const tabOptions = [
    {
      id: '1',
      tab: 'NFTDrops',
      component: NftDrops,
      name: 'NFT Drops',
      tooltip: 'nft drops',
    },
    // {
    //   id: '2',
    //   component: Sniper,
    //   name: 'Top NFT Collections',
    //   tooltip: 'Top NFT Collections',
    // },
    {
      id: '2',
      tab: 'TopNFTCollections',
      component: NFTCollections,
      name: 'Top NFT Collections',
      tooltip: 'Top NFT Collections',
    },
    {
      id: '3',
      tab: 'TopNFTTrades',
      component: NftTrades,
      name: 'Top NFT Trades',
      tooltip: 'top nft trades',
    },
    {
      id: '4',
      tab: 'LatestTrades',
      component: LatestTrades,
      name: 'Latest Trades',
      tooltip: 'latest trades',
    },
    // {
    //   id: '6',
    //   component: LatestTransactions,
    //   name: 'Latest Transactions',
    //   tooltip: 'latest transactions',
    // },
  ];
  const [nftTab, setNftTab] = useState(String(query.get('tab')));
  const props = {
    tabOptions,
    state: nftTab,
    setState: setNftTab,
    basePath: '',
    search: { chain: getUrlParam('chain') },
  };
  if (!nftTab || tabOptions.length < 0) {
    return <PageNotFound />;
  }

  const renderWaring = () => {
    return (
      <div className="flex mx-2 mt-3">
        <div
          className={`flex space-x-2 items-center px-2 py-1 bg-red-50 dark:bg-red-200 text-xs rounded-md`}
        >
          <div className="">
            <InformationCircleIcon
              className="h-4 w-4 text-red-500"
              aria-hidden="true"
            />
          </div>

          <p className="text-xs text-red-700 dark:text-red-800">
            NFT data processing jobs are currently turned off to save costs (we
            are still in beta) and will be resumed in due time. Sorry for the
            inconvenience.
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="m-6">
      {renderWaring()}
      <div className="flex flex-col ">
        <div className="py-2">
          <div className="flex flex-col lg:flex-row justify-between border-b dark:border-dark-tabPillBgSelectedDark">
            <PillTab {...props} />
          </div>
          <div className="">
            {tabOptions.map((item, key) =>
              item.tab == nftTab ? (
                <div key={key} className="">
                  <item.component />
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nft;
