import { getBtcLsData } from 'Helper/services';
import React, { useEffect, useState } from 'react';
import SplitProgressBar from 'Utilities/ProgressBar/splitProgressBar';
import bitcoin from 'Assets/Images/bitcoin.png';

const tabs = [
  { name: 'Binance', href: '#', current: false },
  { name: 'BitMEX', href: '#', current: false },
  { name: 'FTX', href: '#', current: true },
  { name: 'Bitfinex', href: '#', current: false },
  { name: 'Kraken', href: '#', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function SentimentBTCLs() {
  const [btcLsList, setBtcLsList] = useState([]);
  const [btcLongrate, setBtcLongRates] = useState('');
  const [btcShortrate, setBtcShortRates] = useState('');
  const [exchange, setExchange] = useState(1);
  const [exchangeLongRate, setExchangeLongRate] = useState('');
  const [exchangeShortRate, setExchangeShortRate] = useState('');
  const [exchnageLogoUrl, setExchangeLogoUrl] = useState('');
  const [exchangeName, setExchangeName] = useState('');
  const getBtcLs = () => {
    getBtcLsData()
      .then((res) => {
        setBtcLsList(res.list);
        setExchangeLongRate(res.list[0].longRate);
        setExchangeShortRate(res.list[0].shortRate);
        setExchangeLogoUrl(res.list[0].exchangeLogoLink);
        setExchangeName(res.list[0].exchangeName);
        /***
         * longrates and shorrate are coming in a long decimal format,
         * rounding it to two point decimal precision
         */
        let longRates =
          Math.round((res.longRate * 100 + Number.EPSILON) * 100) / 100;
        setBtcLongRates(longRates);
        let shortRates =
          Math.round((res.shortRate * 100 + Number.EPSILON) * 100) / 100;
        setBtcShortRates(shortRates);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const toggleExchange = (
    orderNumber,
    longRate,
    shortRate,
    logoUrl,
    exchangeName
  ) => {
    setExchange(orderNumber);
    // let longRates = Math.round((longRate * 100 + Number.EPSILON) * 100) / 100;
    setExchangeLongRate(longRate);
    // let shortRates = Math.round((shortRate * 100 + Number.EPSILON) * 100) / 100;
    setExchangeShortRate(shortRate);
    setExchangeLogoUrl(logoUrl);
    setExchangeName(exchangeName);
  };
  useEffect(() => {
    getBtcLs();
  }, []);

  return (
    <>
      <div
        className={`p-2 bg-gradient-to-r bg-white border dark:bg-dark-themeCard rounded-xl blur-sm mb-4 dark:border-gray-700`}
      >
        <div className="text-xs text-gray-400">BTC Long v/s Shorts</div>
        <div className="text-sm text-gray-800 font-semibold">
          <div className="">
            <div className="text-xs flex justify-between mb-1">
              <h1 className="dark:text-white">
                <span>
                  {' '}
                  <img
                    className="inline-block h-6 w-6 rounded-full mr-0.5"
                    src={bitcoin}
                    alt=""
                  />
                </span>
                Total
                <span className="text-green-500"> Longs:</span> {btcLongrate}%
              </h1>
              <h1 className="dark:text-white">
                <span className="text-red-500">Short:</span> {btcShortrate}%
              </h1>
            </div>
            <SplitProgressBar longRate={btcLongrate} shortRate={btcShortrate} />
          </div>
          <div className="mt-3">
            <div className="text-xs flex justify-between mb-1">
              <h1 className="dark:text-white">
                <span>
                  {' '}
                  <img
                    className="inline-block h-6 w-6 rounded-full mr-1"
                    src={exchnageLogoUrl}
                    alt=""
                  />
                </span>
                {exchangeName}
                <span className="text-green-500"> Longs:</span>{' '}
                {exchangeLongRate}%
              </h1>
              <h1 className="dark:text-white">
                <span className="text-red-500">Short:</span> {exchangeShortRate}
                %
              </h1>
            </div>
            <SplitProgressBar
              longRate={exchangeLongRate}
              shortRate={exchangeShortRate}
            />
          </div>
          <div className="p-1 mt-2 bg-gray-100 dark:bg-gray-800 rounded-lg">
            <div className="block">
              <div className="flex space-x-1" aria-label="Tabs">
                {btcLsList.map((lst) => (
                  <p
                    onClick={() =>
                      toggleExchange(
                        lst.orderNumber,
                        lst.longRate,
                        lst.shortRate,
                        lst.exchangeLogoLink,
                        lst.exchangeName
                      )
                    }
                    key={lst.exchangeName}
                    className={classNames(
                      lst.orderNumber == exchange
                        ? 'bg-gray-300 text-gray-700 dark:bg-dark-bgMutedDark dark:text-white'
                        : 'text-gray-500 hover:text-gray-700',
                      'px-2 py-1 font-medium text-xs rounded-md cursor-pointer overflow-hidden'
                    )}
                  >
                    {lst.exchangeName}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
