import { ExternalLinkIcon } from '@heroicons/react/solid';
import { useOnClickOutside } from 'Hooks';
import { Spinner } from 'Utilities';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenSearch } from 'statemgmt/actions';
import { tokenSearchResultFormat } from './helper/processData';

function SearchToken({ queryToken, setQueryToken, getAnalytics, block_chain }) {
  const [showSearchResult, setShowSearchResult] = useState(false);

  const dispatch = useDispatch();
  const ref = useRef();
  useOnClickOutside(ref, () => setShowSearchResult(false));

  const { tokenSearchInfo, searchLoading } = useSelector((s = initialState) => {
    const searchInfo = s.tokenSearchDetails.searchInfo;
    return {
      tokenSearchInfo: tokenSearchResultFormat(searchInfo),
      searchLoading: s.tokenSearchDetails.loading,
    };
  });

  const searchToken = (search_input) => {
    console.log(search_input);
    setQueryToken(search_input);
    setShowSearchResult(false);
    let search_type;
    search_input = search_input.toLowerCase();
    if (search_input.length > 3) {
      if (search_input.substring(0, 2).toLowerCase() == '0x') {
        search_type = 'contract_address';
      } else {
        search_type = 'symbol';
      }
      dispatch(getTokenSearch(search_input, search_type, 0)); //TODO: replace '0' with actual chain id
      setShowSearchResult(true);
    }
  };

  const selectToken = (contractAddress) => {
    setShowSearchResult(false);
    setQueryToken(contractAddress);
    getAnalytics(contractAddress);
  };

  return (
    <div className="relative m-3">
      <div className="flex space-x-2">
        <input
          type="text"
          placeholder="Search by Token Contract Address/ Symbol/ Name"
          value={queryToken}
          className="border border-primary w-96 transition px-4 h-11 rounded-md placeholder-text-gray-400 text-xs focus:outline-none dark:text-dark-textPrimary dark:bg-dark-themeBgDark dark:border-gray-600"
          onChange={(e) => searchToken(e.target.value)}
        />
        <button
          className="items-center px-3 py-2 border border-blue-700 shadow-sm text-sm font-semibold rounded-md text-blue-700 bg-white hover:bg-gray-50 dark:text-dark-textPrimary dark:bg-dark-themeBgDark dark:border-gray-700"
          onClick={() => {
            getAnalytics(null);
          }}
        >
          Go
        </button>
      </div>
      {showSearchResult ? (
        <div className="z-40 absolute mt-1 sm:w-96 w-full" ref={ref}>
          <div className="bg-white  dark:bg-dark-bgMutedDark rounded-lg  h-36 overflow-y-auto metric-scroll">
            {searchLoading ? (
              <div className="flex justify-center items-center h-full dark:text-dark-textPrimary">
                <Spinner />
              </div>
            ) : (
              tokenSearchInfo.map((item, key) => (
                <div
                  key={key}
                  onClick={() => selectToken(item.contract_address)}
                  className="flex justify-between p-3 border-b dark:border-gray-600 cursor-pointer"
                >
                  <div className="flex flex-col">
                    <div className="flex">
                      <p className="mr-2 dark:text-dark-textPrimary text-xs">
                        {item.name}
                      </p>
                      <p className="mr-2 dark:text-dark-textPrimary text-xs">
                        ${item.price_usd}
                      </p>
                    </div>
                    <div className="text-gray-300 text-xs">
                      <p>{item.contract_address}</p>
                    </div>
                  </div>
                  <div>
                    <ExternalLinkIcon className="w-4 dark:text-dark-textPrimary" />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SearchToken;

SearchToken.propTypes = {
  queryToken: PropTypes.string,
  setQueryToken: PropTypes.func,
  getAnalytics: PropTypes.func,
  block_chain: PropTypes.string,
};
