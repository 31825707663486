import { ExternalLinkIcon } from '@heroicons/react/solid';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'Utilities';
import { ChainAnalytics, NftIcon } from 'Utilities/images';
import LocalRunSetUp from './Atoms/localRunSetUp';

function Orchestration() {
  const [showModal, setShowModal] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const toolTipRef = useRef();
  const showLocalSetUp = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (showToolTip) {
      toolTipRef.current = setTimeout(() => setShowToolTip(false), 2500);
    }
    return () => {
      clearTimeout(toolTipRef.current);
    };
  }, [showToolTip]);

  if (showModal) {
    return (
      <>
        <Modal
          disableBackdropClick={true}
          showPopup={showModal}
          setShowPopup={setShowModal}
          width={'5/6'}
        >
          <LocalRunSetUp setShowModal={setShowModal} />
        </Modal>
      </>
    );
  }
  return (
    <div>
      <div className="px-4 md:px-6 lg:px-8">
        <div className="flex flex-col items-center sm:items-start">
          <div className="flex flex-row items-center">
            <h1 className="text-md dark:text-white text-gray-800 font-semibold">
              Orchestration
            </h1>
          </div>
          <div className="my-2">
            <h1 className="text-gray-800 dark:text-dark-textPrimary text-xs md:font-md lg:font-lg font-medium  sm:text-left text-center">
              Automate and streamline your data pipelines. Schedule jobs,
              execute workflows, and coordinate dependencies among tasks. Coming
              in 2023!
            </h1>
          </div>
        </div>
      </div>
      <div className="mx-10 mt-10">
        <div className="flex sm:flex-row flex-col sm:space-x-4 space-y-2 sm:space-y-0">
          <div
            className="flex px-4 py-2 items-center dark:bg-dark-tabPillBgSelectedDark shadow-md border-b-1 border-gray-100 dark:border-gray-200 rounded-md cursor-pointer"
            onClick={showLocalSetUp}
          >
            <div>
              <div className="flex space-x-4 items-center">
                <ExternalLinkIcon className="w-4 text-blue-700" />
                <h3 className="text-blue-700 dark:text-blue-600 font-semibold text-xs">
                  Run Locally
                </h3>
              </div>
            </div>
          </div>

          <div className="relative">
            {showToolTip && (
              <div className="absolute -top-12 right-0 dark:text-white p-2 bg-gray-100 dark:bg-gray-700 rounded-md text-xs">
                Coming in 2023
              </div>
            )}

            <div
              className="flex px-4 py-2 items-center dark:bg-dark-tabPillBgSelectedDark shadow-md border-b-1 border-gray-100 dark:border-gray-200 rounded-md cursor-pointer"
              onClick={() => setShowToolTip(true)}
            >
              <div>
                <div className="flex space-x-4 items-center">
                  <ExternalLinkIcon className="w-4 text-blue-700" />
                  <h3 className="dark:text-dark-textSecondary text-blue-700 dark:text-blue-600 font-semibold text-xs">
                    Launch Alphastack Provisioned
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orchestration;
