/* eslint-disable react/prop-types */
import React from 'react';

function Labels({ label }) {
  return (
    <div>
      {typeof label === 'object' ? (
        <div>
          {label.map((item, key) => (
            <span
              key={key}
              className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-400"
            >
              {item}
            </span>
          ))}
        </div>
      ) : (
        <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-400">
          {label}
        </span>
      )}
    </div>
  );
}

export default Labels;
