/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Tippy from '@tippyjs/react';
import formatValue from 'Helper/formatValue';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import BasicProgressBar from 'Utilities/ProgressBar/basicProgressBar';
import { ChevronDownIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid';
import ComparisonProgressbar from 'Utilities/ProgressBar/comparisonBar';

export default function useHeaders() {
  const [showValue, setShowValue] = useState(true);
  const [column, setColumn] = useState(getCol(showValue));

  const cols = getCol(showValue);
  const withbuysellcols = getColBuySell(showValue);
  useEffect(() => {
    setColumn(cols);
  }, [showValue]);
  return [column, withbuysellcols];
}

function getCol(showValue) {
  const column = [
    {
      Header: '#',
      id: 'row',
      accessor: 'ranking',
      Cell: (cell) => {
        let currentRank = Number(cell.row.id) + Number(1);
        let prev_rank = cell.data[cell.row.index]?.ranking?.prev_rank;
        let difference = prev_rank - currentRank;
        if (difference < 0)
          return (
            <div className="flex space-x-1">
              <Tippy
                content={`previous rank ${prev_rank ? prev_rank : ''}`}
                placement="top"
              >
                <p className="font-semibold">#{currentRank}</p>
              </Tippy>
              {difference == -1 ? (
                <ChevronDownIcon className="text-red-500 w-4 font-bold mt-.5" />
              ) : (
                <ChevronDoubleDownIcon className="text-red-500 w-4 font-bold mt-.5" />
              )}
            </div>
          );
        if (difference > 0)
          return (
            <div className="flex space-x-1">
              <Tippy
                content={`previous rank ${prev_rank ? prev_rank : ''}`}
                placement="top"
              >
                <p className="font-semibold">#{currentRank}</p>
              </Tippy>
              {difference == 1 ? (
                <ChevronUpIcon className="text-green-500 w-4 font-bold mt-.5" />
              ) : (
                <ChevronDoubleUpIcon className="text-green-500 w-4 font-bold mt-.5" />
              )}
            </div>
          );
        // if (!prev_rank)
        //   return (
        //     <div className="flex space-x-1">
        //       <Tippy content={`New`} placement="top">
        //         <p className="font-semibold">#{currentRank}</p>
        //       </Tippy>
        //       <PlusSmIcon className="text-yellow-500 w-4 font-bold mt-.5" />
        //     </div>
        //   );
        return `#${currentRank}`;
      },
      disableSortBy: true,
      disableFilters: true,
      Filter: '',
    },
    {
      Header: 'NFT',
      accessor: 'name',
      Cell: (cell) => (
        <div className="flex text-justify mt-3">
          <div className="flex-shrink-0 h-10 w-10">
            <Link
              to={{
                pathname: `https://opensea.io/collection/${`${
                  cell.data[cell.row.index]?.collection?.slug
                }`}`,
              }}
              target="_blank"
            >
              {cell.data[cell.row.index].image_url ? (
                <img
                  className="h-5 w-5 rounded-full"
                  src={`${cell.data[cell.row.index].image_url}`}
                  alt="logo"
                />
              ) : null}
            </Link>
          </div>
          <div className="">
            <div className="whitespace-nowrap text-sm text-blue-400 font-semibold">
              <Link
                to={{
                  pathname: `https://opensea.io/collection/${`${
                    cell.data[cell.row.index].collection?.slug
                  }`}`,
                }}
                target="_blank"
              >
                {cell.row.values.collection_name?.length > 18 ? (
                  <Tippy content={cell.row.values.name} placement="top">
                    <div>{cell.row.values.name}</div>
                  </Tippy>
                ) : (
                  cell.row.values.name
                )}
              </Link>
            </div>
          </div>
          <div>
            <Tippy
              content={'Open Collection details in new tab'}
              placement="top"
            >
              <Link
                to={`/nft/collection?contract_address=${
                  cell.data[cell.row.index].address
                }&t=1`}
                target="_blank"
                className="inline-flex items-center px-4 py-1 cursor-pointer"
              >
                <ExternalLinkIcon className="ml-1 h-3 w-3" />
              </Link>
            </Tippy>
          </div>
        </div>
      ),
      Filter: '',
    },
    {
      Header: `Volume USD`,
      accessor: 'vol_usd',
      Cell: (cell) =>
        showValue ? (
          <div>
            <div className="text-sm">{`$ ${formatValue(
              'format',
              cell.row.values.vol_usd
            )}`}</div>
            <BasicProgressBar
              value={cell.row.values.vol_usd}
              max={cell.data.max_vol}
              score={`${cell.data[cell.row.index].vol_score}`}
            />
          </div>
        ) : (
          <p>
            {cell.row.values.vol_usd
              ? `$ ${formatValue('format', cell.row.values.vol_usd)}`
              : ''}
          </p>
        ),
      Filter: '',
    },
    {
      Header: `Wallet Count`,
      accessor: 'wallet_count',
      Cell: (cell) =>
        showValue ? (
          <>
            <div className="text-sm">{cell.row.values.wallet_count}</div>
            <BasicProgressBar
              value={cell.row.values.wallet_count}
              max={`${cell.data.max_wallet_count}`}
              score={`${cell.data[cell.row.index].wallet_count_score}`}
            />
          </>
        ) : (
          <p>{cell.row.values.wallet_count}</p>
        ),
      Filter: '',
    },
    {
      Header: `Transactions`,
      accessor: 'tx_count',
      Cell: (cell) =>
        showValue ? (
          <>
            <div className="text-sm">{cell.row.values.tx_count}</div>
            <BasicProgressBar
              value={cell.row.values.tx_count}
              max={`${cell.data.max_tx_count}`}
              score={`${cell.data[cell.row.index].tx_count_score}`}
            />
          </>
        ) : (
          <p>{cell.row.values.tx_count}</p>
        ),
      Filter: '',
    },
    {
      Header: `Transactions per Wallet`,
      accessor: 'accumulation',
      Cell: (cell) =>
        showValue ? (
          <>
            <div className="text-sm">{cell.row.values.accumulation}</div>
            <BasicProgressBar
              value={cell.row.values.accumulation}
              max={`${cell.data.max_accumulation}`}
              score={`${cell.data[cell.row.index].accumulation_score}`}
            />
          </>
        ) : (
          <p>{cell.row.values.accumulation}</p>
        ),
      Filter: '',
    },
    {
      Header: 'Floor Price(24H)',
      accessor: 'floor_price',
      Cell: ({ cell }) => {
        let price = cell.row.values.price
          ? '$' + ' ' + cell.row.values.price
          : '-';
        return price;
      },
      Filter: '',
    },
  ];
  return column;
}
function getColBuySell(showValue) {
  const column = [
    {
      Header: '#',
      id: 'row',
      accessor: 'ranking',
      Cell: (cell) => {
        let currentRank = Number(cell.row.id) + Number(1);
        let prev_rank = cell.data[cell.row.index]?.ranking?.prev_rank;
        let difference = prev_rank - currentRank;
        if (difference < 0)
          return (
            <div className="flex space-x-1">
              <Tippy
                content={`previous rank ${prev_rank ? prev_rank : ''}`}
                placement="top"
              >
                <p className="font-semibold">#{currentRank}</p>
              </Tippy>
              {difference == -1 ? (
                <ChevronDownIcon className="text-red-500 w-4 font-bold mt-.5" />
              ) : (
                <ChevronDoubleDownIcon className="text-red-500 w-4 font-bold mt-.5" />
              )}
            </div>
          );
        if (difference > 0)
          return (
            <div className="flex space-x-1">
              <Tippy
                content={`previous rank ${prev_rank ? prev_rank : ''}`}
                placement="top"
              >
                <p className="font-semibold">#{currentRank}</p>
              </Tippy>
              {difference == 1 ? (
                <ChevronUpIcon className="text-green-500 w-4 font-bold mt-.5" />
              ) : (
                <ChevronDoubleUpIcon className="text-green-500 w-4 font-bold mt-.5" />
              )}
            </div>
          );
        // if (!prev_rank)
        //   return (
        //     <div className="flex space-x-1">
        //       <Tippy content={`New`} placement="top">
        //         <p className="font-semibold">#{currentRank}</p>
        //       </Tippy>
        //       <PlusSmIcon className="text-yellow-500 w-4 font-bold mt-.5" />
        //     </div>
        //   );
        return `#${currentRank}`;
      },
      disableSortBy: true,
      disableFilters: true,
      Filter: '',
    },
    {
      Header: 'NFT',
      accessor: 'name',
      Cell: (cell) => (
        <div className="flex text-justify mt-3">
          <div className="flex-shrink-0 h-10 w-10">
            <Link
              to={{
                pathname: `https://opensea.io/collection/${`${
                  cell.data[cell.row.index].collection?.slug
                }`}`,
              }}
              target="_blank"
            >
              {cell.data[cell.row.index].image_url ? (
                <img
                  className="h-5 w-5 rounded-full"
                  src={`${cell.data[cell.row.index].image_url}`}
                  alt="logo"
                />
              ) : null}
            </Link>
          </div>
          <div className="">
            <div className="whitespace-nowrap text-sm text-blue-400 font-semibold">
              <Link
                to={{
                  pathname: `https://opensea.io/collection/${`${
                    cell.data[cell.row.index].collection?.slug
                  }`}`,
                }}
                target="_blank"
              >
                {cell.row.values.collection_name?.length > 18 ? (
                  <Tippy content={cell.row.values.name} placement="top">
                    <div>{cell.row.values.name}</div>
                  </Tippy>
                ) : (
                  cell.row.values.name
                )}
              </Link>
            </div>
          </div>
          <div>
            <Tippy
              content={'Open Collection details in new tab'}
              placement="top"
            >
              <Link
                to={`/nft/collection?contract_address=${
                  cell.data[cell.row.index].address
                }&t=1`}
                target="_blank"
                query={'one'}
                className="inline-flex items-center px-4 py-1 cursor-pointer"
              >
                <ExternalLinkIcon className="ml-1 h-3 w-3" />
              </Link>
            </Tippy>
          </div>
        </div>
      ),
    },
    {
      Header: `Volume USD`,
      accessor: 'buy_volume',
      Cell: (cell) =>
        showValue ? (
          <div>
            <div className="text-sm">{`$ ${formatValue(
              'format',
              cell.row.values.buy_volume
            )}`}</div>
            <ComparisonProgressbar
              buy_vol={cell.data[cell.row.index].buy_volume}
              sell_vol={cell.data[cell.row.index].sell_volume}
            />
            <div className="text-sm">
              {cell.data[cell.row.index].sell_volume
                ? `$ ${formatValue(
                    'format',
                    cell.data[cell.row.index].sell_volume
                  )}`
                : ''}
            </div>
          </div>
        ) : (
          <p>
            {cell.row.values.volume
              ? `$ ${formatValue('format', cell.row.values.buy_volume)}`
              : ''}
          </p>
        ),
      Filter: '',
    },
    {
      Header: `Wallet Count`,
      accessor: 'buy_wallet_count',
      Cell: (cell) =>
        showValue ? (
          <>
            <div className="text-sm">{cell.row.values.buy_wallet_count}</div>
            <ComparisonProgressbar
              buy_vol={cell.data[cell.row.index].buy_wallet_count}
              sell_vol={cell.data[cell.row.index].sell_wallet_count}
            />
            <div className="text-sm">
              {cell.data[cell.row.index].sell_wallet_count}
            </div>
          </>
        ) : (
          <p>{cell.row.values.wallet_count}</p>
        ),
      Filter: '',
    },
    {
      Header: `Transactions`,
      accessor: 'buy_tx_count',
      Cell: (cell) =>
        showValue ? (
          <>
            <div className="text-sm">{cell.row.values.buy_tx_count}</div>
            <ComparisonProgressbar
              buy_vol={cell.data[cell.row.index].buy_tx_count}
              sell_vol={cell.data[cell.row.index].sell_tx_count}
            />
            <div className="text-sm">
              {cell.data[cell.row.index].sell_tx_count}
            </div>
          </>
        ) : (
          <p>{cell.row.values.tx_count}</p>
        ),
      Filter: '',
    },
    {
      Header: `Transactions per Wallet`,
      accessor: 'buy_accumulation',
      Cell: (cell) =>
        showValue ? (
          <>
            <div className="text-sm">{cell.row.values.buy_accumulation}</div>
            <ComparisonProgressbar
              buy_vol={cell.data[cell.row.index].buy_accumulation}
              sell_vol={cell.data[cell.row.index].sell_accumulation}
            />
            <div className="text-sm">
              {cell.data[cell.row.index].sell_accumulation}
            </div>
          </>
        ) : (
          <p>{cell.row.values.accumulation}</p>
        ),
      Filter: '',
    },
    {
      Header: 'Floor Price(24H)',
      accessor: 'floor_price',
      Cell: ({ cell }) => {
        let price = cell.row.values.price
          ? '$' + ' ' + cell.row.values.price
          : '-';
        return price;
      },
      Filter: '',
    },
  ];
  return column;
}
