/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */

import { AlphaSentiment } from 'Utilities';

export const column = [
  {
    Header: 'Project Name',
    accessor: 'project_name',
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Ticker',
    accessor: 'ticker',
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'alphaleaks',
  },
  {
    Header: 'Predicted Sentiment',
    accessor: 'alpha_sentiment',
    Cell: ({ cell }) => {
      return cell.row.values.alpha_sentiment ? (
        <AlphaSentiment sentiment={cell.row.values.alpha_sentiment} />
      ) : (
        '-'
      );
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Submitted On',
    accessor: 'submitted_on_utc',
    Cell: ({ cell }) => {
      let dateTime = new Date(cell.row.values.submitted_on_utc).toDateString();

      return dateTime;
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'alphaleaks',
  },
  {
    Header: 'Price at Submission Time',
    accessor: 'actual_price_at_start',
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'alphaleaks',
  },
  {
    Header: 'Effect Period',
    accessor: 'effect_period',
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'alphaleaks',
  },
  {
    Header: 'Effect Status',
    accessor: 'effect_status',
    Cell: (cell) => (
      <p className="font-bold">{cell.row.values.effect_status}</p>
    ),
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'alphaleaks',
  },
  {
    Header: 'Expiration Date',
    accessor: 'expiration_utc',
    Cell: ({ cell }) => {
      let dateTime = new Date(cell.row.values.expiration_utc).toUTCString(
        'en-US'
      );

      return dateTime;
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'alphaleaks',
  },
  {
    width: 300,
    Header: ' ',
    accessor: 'id',
    Cell: ({ cell }) => (
      <>
        <button
          type="button"
          className="items-center px-3 py-2 border border-blue-700 shadow-sm text-sm leading-4 font-semibold rounded-md text-blue-700 bg-white hover:bg-gray-50 dark:text-dark-textPrimary dark:bg-dark-themeBgDark dark:border-gray-700"
        >
          Details
        </button>
      </>
    ),
    disableSortBy: true,
    isDrawer: true,
    drawerComponent: 'userleaks',
    Filter: '',
  },
];
