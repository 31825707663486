/* eslint-disable react/prop-types */

import * as Highcharts from 'highcharts/highstock';

//comment below line to check with highcharts-pattern-fill
import HighchartsReact from 'highcharts-react-official';
import HighchartsPatternFill from 'highcharts/modules/pattern-fill';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

HighchartsPatternFill(Highcharts);

// uncomment below line to check with highcharts-pattern-fill
// import HighchartsPatternFill from "highcharts-pattern-fill";

function ColumnChart({ buy, sell, trade_date, title, type }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  const options = {
    chart: {
      type: type,
      backgroundColor: theme === 'dark' ? '#18242e' : '#ffffff',
    },
    legend: {
      itemStyle: {
        color: theme === 'dark' ? '#ffff' : '#2a3340',
      },
    },
    title: {
      text: title,
      style: {
        color: theme === 'dark' ? '#ffff' : '#2a3340',
        fontWeight: 'bold',
        fontSize: '12px',
      },
    },
    xAxis: {
      categories: trade_date,
      crosshair: true,
      type: 'datetime',
      tickInterval: 2,
      labels: {
        formatter: function () {
          return moment.utc(this.value).format('LL');
        },
      },
      // visible: false,
    },
    yAxis: [
      {
        gridLineColor: theme === 'dark' ? '#96a3b0' : '#dddddd',
        gridLineWidth: 0.5,
      },
    ],
    tooltip: {
      headerFormat:
        '<span style="font-size:8px">{moment.utc(point.key).format("LL")}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: 'buy',
        data: buy,
        color: '#1f77b4',
      },
      {
        name: 'sell',
        data: sell,
        color: '#ff7f0e',
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default ColumnChart;
