/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

export default function StackedChart({ tradeData, chartId, chartLoading }) {
  const chartRef = useRef(null);

  const items = tradeData;
  console.log({ items });
  useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create(chartId, am4charts.XYChart);
      chartRef.current.hiddenState.properties.opacity = 0; // this creates initial fade-in
      chartRef.current.data = items;
      chartRef.current.colors.step = 2;
      chartRef.current.padding(30, 30, 10, 30);
      // chartRef.current.legend = new am4charts.Legend();

      // Modify chart's colors
      chartRef.current.colors.list = [
        am4core.color('#37FF87'),
        am4core.color('#666666'),
      ];

      const categoryAxis = chartRef.current.xAxes.push(
        new am4charts.CategoryAxis()
      );
      categoryAxis.dataFields.category = 'name';
      categoryAxis.renderer.grid.template.location = 0;

      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.fontSize = 11;
      categoryAxis.renderer.labels.template.dy = 5;
      categoryAxis.renderer.labels.template.rotation = -45;
      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.fill = am4core.color('#9CA3AF');
      categoryAxis.renderer.grid.template.strokeWidth = 0;

      var image = new am4core.Image();
      image.horizontalCenter = 'middle';
      image.width = 20;
      image.height = 20;
      image.verticalCenter = 'middle';
      image.adapter.add('href', (href, target) => {
        let dataContext = target.dataItem.dataContext;
        if (dataContext) {
          return target.dataItem.dataContext['logo_url'];
        }
        return href;
      });
      categoryAxis.dataItems.template.bullet = image;

      const valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      valueAxis.calculateTotals = true;
      valueAxis.renderer.minWidth = 50;

      const buySeries = chartRef.current.series.push(
        new am4charts.ColumnSeries()
      );
      buySeries.columns.template.width = am4core.percent(80);
      buySeries.columns.template.tooltipText =
        "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      buySeries.name = 'Buy';
      buySeries.dataFields.categoryX = 'name';
      buySeries.dataFields.valueY = 'buy';
      buySeries.dataFields.valueYShow = 'totalPercent';
      buySeries.dataItems.template.locations.categoryX = 0.5;
      buySeries.stacked = true;
      buySeries.tooltip.pointerOrientation = 'vertical';

      const bullet1 = buySeries.bullets.push(new am4charts.LabelBullet());
      // bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
      bullet1.label.fill = am4core.color('#ffffff');
      bullet1.locationY = 0.5;

      const sellSeries = chartRef.current.series.push(
        new am4charts.ColumnSeries()
      );
      sellSeries.columns.template.width = am4core.percent(80);
      sellSeries.columns.template.tooltipText =
        "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      sellSeries.name = 'Sell';
      sellSeries.dataFields.categoryX = 'name';
      sellSeries.dataFields.valueY = 'sell';
      sellSeries.dataFields.valueYShow = 'totalPercent';
      sellSeries.dataItems.template.locations.categoryX = 0.5;
      sellSeries.stacked = true;
      sellSeries.tooltip.pointerOrientation = 'vertical';

      const bullet2 = sellSeries.bullets.push(new am4charts.LabelBullet());
      // bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
      bullet2.locationY = 0.5;
      bullet2.label.fill = am4core.color('#ffffff');
    }

    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  // Load data into chart
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = items;
    }
  }, [items]);
  //  Handle component unmounting, dispose chart
  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  if (chartLoading) {
    return <>Loading</>;
  }
  return (
    <div className="">
      <div
        id={chartId}
        style={{
          width: '100%',
          height: '55vh',
          overflow: 'hidden',
        }}
      ></div>
    </div>
  );
}
