import { submitAlphaRequest } from 'Helper/services';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { Text } from 'Utilities';
import { Field } from 'Utilities/FormField/field';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const MODAL_TITLE = 'Submit Alpha Request';
const MODAL_SUBTITLE =
  'Your request will be visible to everyone and will be associated with your username.';

AlphaRequestForm.propTypes = {
  showPopup: PropTypes.bool,
  setShowPopup: PropTypes.func.isRequired,
};

export default function AlphaRequestForm({ showPopup, setShowPopup }) {
  // const [categories, setCategories] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { user_address } = useGetCurrentUser();

  const { theme, categories } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
      categories: s.alphaReqPreloadData.response,
    };
  });

  const style = {
    cancelButton: `bg-transparent py-2 px-4 border rounded-md shadow-sm text-sm font-semibold ${
      theme == 'dark' ? 'border-gray-700 text-white' : 'border-gray-300'
    }`,
    askButton: `ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
    modalTitle: `${theme == 'dark' ? 'text-white' : 'text-gray-800'}`,
    modalSubtitle: `${theme == 'dark' ? 'text-gray-400' : 'text-gray-600'}`,
  };

  const onSubmit = (formData) => {
    formData['user_address'] = user_address;
    submitAlphaRequest(formData).then((res) => {
      if (res.status == 1003) {
        setShowPopup(false);
        location.reload();
      }
    });
  };

  return (
    <div
      className={`${
        theme == 'dark' ? 'dark:bg-dark-secondaryTheme' : 'bg-white'
      }`}
    >
      <div className="mx-4">
        <Text
          size="xl"
          fontWeight="semibold"
          className={style.modalTitle}
          text={MODAL_TITLE}
        />
        <Text
          size="sm"
          fontWeight="medium"
          className={style.modalSubtitle}
          text={MODAL_SUBTITLE}
        />
      </div>
      <div className="mt-6 mx-4 ">
        <div className="">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <div className="md:col-span-2">
                <Field
                  {...register('title', { required: 'Title is required' })}
                  dot={true}
                  error={errors?.title?.message}
                  label="What do you want to ask?"
                  name="title"
                  placeholder="Title"
                  type="default"
                  theme={theme}
                />
              </div>
            </div>
            <div className="">
              <Field
                {...register('description', {
                  required: 'description is required',
                })}
                error={errors?.description?.message}
                label="Anything else you’d like to add?"
                name="description"
                type="textarea"
                theme={theme}
              />
            </div>
            <div>
              <div className="grid md:grid-cols-3 gap-4">
                <div className="md:col-span-2">
                  <Field
                    {...register('category', {
                      required: 'Category is required',
                    })}
                    dot={true}
                    error={errors?.category?.message}
                    label="Pick category"
                    name="category"
                    type="select"
                    theme={theme}
                  >
                    {categories &&
                      categories.map((item, key) => (
                        <>
                          <option value={item.value}>{item.text}</option>
                        </>
                      ))}
                  </Field>
                </div>
                <div className="mt-3">
                  <label
                    htmlFor="price"
                    className={`text-sm font-medium  ${
                      theme == 'dark' && 'text-gray-200'
                    } `}
                  >
                    Reward
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      {...register('reward', {
                        required: 'Reward is required',
                        validate: {
                          positiveNumber: (value) => parseFloat(value) > 0,
                        },
                      })}
                      type="text"
                      name="reward"
                      id="reward"
                      className={`text-sm relative flex flex-1 w-full mt-1 rounded-md py-2 px-4 bg-white border ${
                        theme == 'dark' &&
                        'bg-gray-900 border-gray-700 text-gray-100'
                      } ${
                        errors.reward && 'ring-red-500 ring-1'
                      } placeholder-gray-400 focus:outline-none focus:ring-1 focus:border-transparent`}
                      placeholder="0.00"
                      autoComplete="off"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center">
                      <label htmlFor="currency" className="sr-only">
                        Currency
                      </label>
                      <select
                        {...register('currency', {
                          required: 'Currency is required',
                        })}
                        id="currency"
                        name="currency"
                        className="h-full py-0 pl-2 pr-1 bg-transparent text-gray-500 text-xs rounded-md"
                      >
                        <option value="0">ETH</option>
                        <option value="1" selected>
                          ALPHAS
                        </option>
                      </select>
                    </div>
                  </div>
                  {errors.reward && errors.reward.type == 'required' && (
                    <span role="alert" className="text-sm text-red-500">
                      {'Reward is required'}
                    </span>
                  )}
                  {errors.reward && errors.reward.type == 'positiveNumber' && (
                    <span role="alert" className="text-sm text-red-500">
                      {'Invalid reward value'}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="text-sm">
                The expiration date for your Alpha Request is 9th August 2022 at
                00:00 GMT. Kindly ensure you reward a winner prior to this,
                failing which the answer with the highest upvotes will be
                automatically picked and rewarded.
              </div>
              <div className="flex mt-3">
                <div className="flex items-center">
                  <input
                    {...register('agreement', {
                      required: 'Please accept agreement to continue',
                    })}
                    id="agreement"
                    aria-describedby="agreement-description"
                    name="agreement"
                    type="checkbox"
                    className=" text-blue-600 border-gray-300 rounded-lg"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="agreement"
                    className={`font-medium ${
                      theme === 'dark' ? 'text-white' : 'text-black'
                    } `}
                  >
                    I Agree
                  </label>
                  <span
                    id="agreement-description"
                    className="text-red-500 text-lg ml-1"
                  >
                    *
                  </span>
                </div>
              </div>
              {errors.agreement && (
                <span role="alert" className="text-sm text-red-500">
                  {'Please accept agreement to continue'}
                </span>
              )}
            </div>
            <div className="">
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className={style.cancelButton}
                    onClick={() => setShowPopup(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={style.askButton}>
                    Ask
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
