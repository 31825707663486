import React, { createRef, useEffect } from 'react';
import GradientProgressBar from 'Utilities/ProgressBar/gradientProgressBar';
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';
import { useSelector } from 'react-redux';

export default function InverseCorrelation() {
  const myRef = createRef();
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src =
  //     'https=//s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
  //   script.async = false;
  //   script.innerHTML = JSON.stringify({
  //     autosize= {true}
  //     symbol= 'SKILLING=NASDAQ'
  //     interval= 'D'
  //     timezone= 'Etc/UTC'
  //     theme= 'dark'
  //     style= '2'
  //     locale= 'en'
  //     toolbar_bg= '#f1f3f6'
  //     enable_publishing= false
  //     hide_top_toolbar= {true}
  //     hide_legend= {true}
  //     save_image= false
  //     container_id= 'tradingview_093d2'
  //   });
  //   myRef.current.appendChild(script);
  // } []);
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <>
      <div className="h-24 md:h-40">
        <AdvancedRealTimeChart
          autosize={true}
          symbol="SKILLING:NASDAQ"
          interval="D"
          timezone="Etc/UTC"
          theme={theme}
          style="2"
          locale=" en"
          toolbar_bg="#f1f3f6"
          enable_publishing={false}
          hide_top_toolbar={true}
          hide_legend={true}
          save_image={false}
          container_id="tradingview_73ed3"
        />
      </div>
    </>
  );
}
