import { Select } from 'Utilities';
import React from 'react';
import PropTypes from 'prop-types';
import { filterOptions, rankOptions } from './Helper/constants';

function Filters({ rank, setRank, filter, setFilter }) {
  const rankProps = Object.freeze({
    optionsObj: rankOptions,
    state: rank,
    setState: setRank,
  });

  const filterProps = Object.freeze({
    optionsObj: filterOptions,
    state: filter,
    setState: setFilter,
  });

  return (
    <div className="flex flex-wrap space-x-1">
      <Select {...rankProps} />
      <Select {...filterProps} />
    </div>
  );
}

export default Filters;

Filters.propTypes = {
  rank: PropTypes.string,
  setRank: PropTypes.func,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
};
