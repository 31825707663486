/* eslint-disable react/prop-types */
import { Table } from 'Utilities';
import React from 'react';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

const columns = [
  {
    Header: 'Workflow Group',
    accessor: 'workflow_grouping',
    Filter: '',
  },
  {
    Header: 'Process Step',
    accessor: 'process_step',
    Filter: '',
  },
  {
    Header: 'Last Config',
    accessor: 'last_config',
    Filter: '',
  },
  {
    Header: 'Last Config Action',
    accessor: 'last_config_action',
    Filter: '',
  },
  {
    Header: 'Last Status',
    accessor: 'last_status',
    Filter: '',
  },
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    Cell: (cell) => (
      <div>
        {cell.row.values.timestamp
          .replace('T', ' ')
          .replace('Z', '')
          .slice(0, -4)}
      </div>
    ),
    Filter: '',
  },
];
function UserClusterConfigStatus({ data, loading }) {
  console.log(data);
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  return (
    <div className="mt-2">
      <Table data={data} col={columns} isloading={loading} />
    </div>
  );

  //   <div className="mt-2 flow-root w-full">
  //     <div
  //       className={`overflow-scroll  max-h-96 ${
  //         theme == 'dark'
  //           ? 'metric-scroll-light bg-dark-themePrimary'
  //           : 'metric-scroll-dark bg-white'
  //       }`}
  //     >
  //       <table className="w-full text-left">
  //         <thead className="dark:bg-dark-themePrimary sticky top-0">
  //           <tr>
  //             <th
  //               scope="col"
  //               className="relative isolate py-3.5 pr-3 text-left text-xs font-semibold dark:text-white"
  //             >
  //               Workflow Group
  //               <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
  //               <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
  //             </th>
  //             <th
  //               scope="col"
  //               className="hidden px-3 py-3.5 text-left text-xs font-semibold dark:text-white sm:table-cell"
  //             >
  //               Process Step
  //             </th>
  //             <th
  //               scope="col"
  //               className="hidden px-3 py-3.5 text-left text-xs font-semibold dark:text-white md:table-cell"
  //             >
  //               Last Config
  //             </th>
  //             <th
  //               scope="col"
  //               className="px-3 py-3.5 text-left text-xs font-semibold dark:text-white"
  //             >
  //               Last Config Action
  //             </th>
  //             <th
  //               scope="col"
  //               className="px-3 py-3.5 text-left text-xs font-semibold dark:text-white"
  //             >
  //               Last Status
  //             </th>
  //             <th
  //               scope="col"
  //               className="px-3 py-3.5 text-left text-xs font-semibold dark:text-white"
  //             >
  //               Timestamp
  //             </th>
  //             <th scope="col" className="relative py-3.5 pl-3">
  //               <span className="sr-only">Edit</span>
  //             </th>
  //           </tr>
  //         </thead>
  //         <tbody className="h-96 overflow-y-auto">
  //           {data &&
  //             data.map((itm, key) => (
  //               <tr key={key}>
  //                 <td className="relative py-4 pr-3 text-xs font-medium dark:text-white">
  //                   {itm?.workflow_grouping}
  //                 </td>
  //                 <td className="px-3 py-4 text-xs dark:text-white sm:table-cell">
  //                   {itm?.process_step}
  //                 </td>
  //                 <td className="px-3 py-4 text-xs dark:text-white md:table-cell">
  //                   {itm?.last_config}
  //                 </td>
  //                 <td className="px-3 py-4 text-xs dark:text-white md:table-cell">
  //                   {itm?.last_config_action}
  //                 </td>
  //                 <td className="px-3 py-4 text-xs dark:text-white md:table-cell">
  //                   {itm?.last_status}
  //                 </td>
  //                 <td className="px-3 py-4 text-xs dark:text-white md:table-cell">
  //                   {itm?.timestamp?.replace('T', ' ').replace('Z', ' ')}
  //                 </td>
  //               </tr>
  //             ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   </div>
  // );
}

export default UserClusterConfigStatus;
