import { AdjustmentsIcon } from '@heroicons/react/solid';
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import { getLastIndexed } from 'Helper/services';
import { useLocalStorage } from 'Hooks';
import {
  ContentWrapper,
  MenuWrapper,
  PopoverWrapper,
  Refresh,
} from 'Utilities';
import DataLastIndexed from 'Utilities/DataLastIndexed/DataLastIndexed';
import TableTokenDashboard from 'Utilities/Table/tableTokenDashboard';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToptrades } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import SortToggle from '../Atoms/sortToggle';
import useHeaders from './Helper/header';
import { processTokens } from './Helper/processToken';
import ChartView from './chartView';
import { useNavigate } from 'react-router-dom';
import { setgroups } from 'process';
import Filters from './filters';
import SortOptions from './sortOptions';
import {
  token_duration,
  token_group,
  token_limit,
  token_type,
} from './Helper/constants';

function TopTrades() {
  const [limit, setLimit] = useLocalStorage('limit', 20);
  const [group, setGroup] = useLocalStorage('group', 'whales');
  const [type, setType] = useLocalStorage('type', 'bought_sold');
  const [duration, setDuration] = useLocalStorage('duration', '24hr');
  const [hideStandardCoins, sethideStandardCoins] = useLocalStorage(
    'hideStandardCoins',
    true
  );
  const [refresh, setRefresh] = useState(false);
  const [buysellFilter, setBuySellFilter] = useLocalStorage(
    'buysellfilter',
    'buy_wallet_count'
  );

  const [lastIndexed, setLastIndexed] = useState('');
  const [column] = useHeaders();
  const dispatch = useDispatch();

  const block_chain = CHAINS[getUrlParam('chain')];
  const navigate = useNavigate();
  const basePath = '';
  const chain = getUrlParam('chain');
  const groupFromParam = getUrlParam('group');
  const limitFromParam = getUrlParam('limit');
  const typeFromParam = getUrlParam('type');
  const durationFromParam = getUrlParam('duration');

  const { token, loading } = useSelector((s = initialState) => {
    return {
      token: processTokens(s?.dashboard?.token?.data, type),
      loading: s.dashboard.loading,
    };
  });

  const chartViewProps = { group, type, duration, hideStandardCoins };
  const filterProps = Object.freeze({
    limit,
    setLimit,
    group,
    setGroup,
    duration,
    setDuration,
    type,
    setType,
  });

  const tableProps = {
    data: token,
    col: column,
    isloading: loading,
  };

  const validateURLParams = () => {
    const urlLimitBoolean = token_limit?.some(
      (item) => item.value === limitFromParam
    );
    if (urlLimitBoolean) setLimit(limitFromParam);

    const urlGroupBoolean = token_group?.some(
      (item) => item.value === groupFromParam
    );
    if (urlGroupBoolean) setGroup(groupFromParam);

    const urlDurationBoolean = token_duration?.some(
      (item) => item.value === durationFromParam
    );
    if (urlDurationBoolean) setDuration(durationFromParam);

    const urlTypeBoolean = token_type?.some(
      (item) => item.value === typeFromParam
    );
    if (urlTypeBoolean) setType(typeFromParam);
  };

  useEffect(() => {
    validateURLParams();
  }, []);

  useEffect(async () => {
    dispatch(
      getToptrades(
        block_chain,
        limit,
        group,
        type,
        duration,
        buysellFilter,
        hideStandardCoins
      )
    );

    const res = await getLastIndexed(block_chain, 'top-dex-trades');
    setLastIndexed(res?.data?.metadata?.data_last_indexed_timestamp_utc);
    navigate(
      `${basePath}?chain=${chain}&tab=TopDexTrades&limit=${limit}&group=${group}&type=${type}&duration=${duration}&sort_key=${buysellFilter}`
    );
  }, [
    block_chain,
    limit,
    group,
    type,
    duration,
    hideStandardCoins,
    refresh,
    buysellFilter,
  ]);

  return (
    <ContentWrapper>
      <div className="hidden sm:flex justify-between">
        <div className="flex flex-wrap space-x-1 mr-1 m-4 ">
          <Filters {...filterProps} />
          <div className="mx-2">
            <PopoverWrapper
              Icon={AdjustmentsIcon}
              label="More..."
              kind="primary"
            >
              <SortOptions
                filter={buysellFilter}
                setFilter={setBuySellFilter}
              />
            </PopoverWrapper>
          </div>
          <div className="mx-2">
            <SortToggle
              state={hideStandardCoins}
              setState={sethideStandardCoins}
            />
          </div>
        </div>
        <div className="flex items-center space-x-2 m-3">
          <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
          <Refresh
            state={refresh}
            setState={setRefresh}
            loadingbutton={loading}
          />
        </div>
      </div>

      {/* filter, sort  options mobile view */}
      <div className="block sm:hidden py-4 px-2 lg:py-0 lg:px-0">
        <div className="flex justify-end items-center space-x-1">
          <Refresh
            state={refresh}
            setState={setRefresh}
            loadingbutton={loading}
          />
          <div>
            <MenuWrapper>
              <div className="p-2">
                <Filters {...filterProps} />
                <SortOptions
                  filter={buysellFilter}
                  setFilter={setBuySellFilter}
                />
                <div className="mx-2">
                  <div className="flex">
                    <SortToggle
                      state={hideStandardCoins}
                      setState={sethideStandardCoins}
                    />
                  </div>
                </div>
              </div>
            </MenuWrapper>
          </div>
        </div>
        <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
      </div>
      <div className="lg:px-4">
        <TableTokenDashboard {...tableProps} />
      </div>
    </ContentWrapper>
  );
}
export default TopTrades;
