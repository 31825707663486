/**
 * toggle between thestable coins and wrapped coins
 */

/* eslint-disable react/prop-types */
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
// //import Tooltip from 'Utilities/Tooltip/tooltip';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SortToggle({ state, setState }) {
  return (
    <>
      <div className="m-2">
        <Tippy content={'Exclude stable and wrapped coins'} placement="top">
          <div className="flex items-center">
            <div
              onClick={() => {
                setState(!state);
              }}
              className={classNames(
                state
                  ? 'bg-blue-600 dark:bg-gray-400'
                  : 'bg-gray-200 dark:bg-dark-toggleBgDark',
                'inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'
              )}
            >
              {/* <Tooltip l1={'pegged coins'} /> */}
              <span
                className={classNames(
                  state ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </div>
            <div className="group relative ml-3">
              <span className="text-xs font-medium text-gray-900 dark:text-dark-textPrimary">
                Exclude Pegged Coins
              </span>
            </div>
          </div>
        </Tippy>
      </div>
    </>
  );
}
