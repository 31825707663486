import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

import {
  ConnectDark,
  ConnectLight,
  DataFederationDark,
  DataFederationLight,
  DatabaseDark,
  DatabaseLight,
  LApiDarkcon,
  LApiLightIcon,
  LCADarkIcon,
  LCALightIcon,
  LDPDarkcon,
  LDPLightIcon,
  LMLDarkcon,
  LMLLightIcon,
  LOCADarkIcon,
  LOCALightIcon,
  LSEDarkIcon,
  LSELightIcon,
  QueryDark,
  QueryLight,
  VerifiedDark,
  VerifiedLight,
  VizDark,
  VizLight,
} from 'Utilities/icons';

FeaturesIcon.propTypes = {
  featureId: PropTypes.string,
  className: PropTypes.string,
};

const ICONS = {
  dark: {
    community_alpha: LCADarkIcon,
    on_chain_analytics: LOCADarkIcon,
    api: LApiDarkcon,
    data_platform: LDPDarkcon,
    ml_platform: LMLDarkcon,
    security_enclave: LSEDarkIcon,
    connect: ConnectDark,
    query: QueryDark,
    viz: VizDark,
    self_sovereign_identity: DatabaseDark,
    data_federation: DataFederationDark,
    peace_of_mind: VerifiedDark,
  },
  light: {
    community_alpha: LCALightIcon,
    on_chain_analytics: LOCALightIcon,
    api: LApiLightIcon,
    data_platform: LDPLightIcon,
    ml_platform: LMLLightIcon,
    security_enclave: LSELightIcon,
    connect: ConnectLight,
    query: QueryLight,
    viz: VizLight,
    self_sovereign_identity: DatabaseLight,
    data_federation: DataFederationLight,
    peace_of_mind: VerifiedLight,
  },
};

function FeaturesIcon({ featureId, className = 'h-9 w-9 lg:h-12 lg:w-12' }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  const icon = ICONS[theme][featureId];
  if (theme) {
    // return <h1>placeholder</h1>;
    return <img src={icon} className={className} />;
  }
}

export default FeaturesIcon;
