/* eslint-disable react/prop-types */
import moment from 'moment';
import React, { useEffect, useState } from 'react';

function CountDown({ timeTillDate }) {
  const [days, setDays] = useState('00');
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');
  const [isExpired, setExpired] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const then = moment.utc(timeTillDate).format('YYYY-MM-DD HH:mm:ss');
      const now = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      // const dump = '2022-02-17 07:09:01';
      const countDown = moment.duration(moment(then).diff(now));
      const milliseconds = countDown._milliseconds;
      const day = countDown.days();
      if (milliseconds < 0) {
        setExpired(true);
      } else {
        setExpired(false);
        // no need for setting state as zero if values less than zero
        // here did just as a part of sanity check
        countDown.days() < 0 ? setDays(0) : setDays(countDown.days());
        countDown.hours() < 0 ? setHours(0) : setHours(countDown.hours());
        countDown.minutes() < 0
          ? setMinutes(0)
          : setMinutes(countDown.minutes());
        countDown.seconds() < 0
          ? setSeconds(0)
          : setSeconds(countDown.seconds());
      }
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <div className="border rounded-md dark:border-gray-700 my-4">
      {isExpired ? (
        <div className="my-2 text-md text-center font-semibold text-red-500">
          Expired
        </div>
      ) : (
        <>
          <div className="px-4 ml-4 grid grid-cols-4 gap-2 justify-center">
            <div className="">
              <dt className="font-semibold text-blue-600">{days}</dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-sm dark:text-dark-textPrimary">
                  Day
                </div>
              </dd>
            </div>
            <div className="">
              <dt className="font-semibold text-blue-600">{hours}</dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-sm dark:text-dark-textPrimary">
                  Hrs
                </div>
              </dd>
            </div>
            <div className="">
              <dt className="font-semibold text-blue-600">{minutes}</dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-sm dark:text-dark-textPrimary">
                  Min
                </div>
              </dd>
            </div>
            <div className="">
              <dt className="font-semibold text-blue-600">{seconds}</dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-sm dark:text-dark-textPrimary">
                  Sec
                </div>
              </dd>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CountDown;
