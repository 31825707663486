import React from 'react';

type gridProps = {
  cols: string;
  gap: string;
  className: string;
  children: JSX.Element;
};
export default function Grid({
  cols,
  gap,
  className,
  children,
}: gridProps): JSX.Element {
  const gridStyle = `grid grid-cols-${cols} gap-${gap} ${className}`;
  return (
    <>
      <div className={gridStyle}>{children}</div>
    </>
  );
}
