/* eslint-disable react/prop-types */
import { Tooltip } from 'Utilities';
import React from 'react';

function TokenSymbol({ symbol }) {
  if (symbol) {
    if (symbol.length > 20) {
      return (
        <Tooltip content={symbol} placement="top">
          <div>
            <span className="break-words">
              {symbol.substring(0, 20) + '..'}
            </span>
          </div>
        </Tooltip>
      );
    } else {
      return <span className="">{symbol}</span>;
    }
  } else {
    return '';
  }
}

export default TokenSymbol;
