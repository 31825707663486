import TokenActivity from 'Components/Defi/Atoms/tokenActivity';
import TokenName from 'Components/Defi/Atoms/tokenName';
import TokenRanking from 'Components/Defi/Atoms/tokenRanking';
import TokenSymbol from 'Components/Defi/Atoms/tokenSymbol';
import formatValue from 'Helper/formatValue';

export default function useHeaders() {
  const column = getColumns();

  return [column];
}

function getColumns() {
  const columns = [
    {
      Header: '#',
      id: 'row',
      accessor: 'ranking',
      Cell: (cell) => <TokenRanking cell={cell} />,
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
      Cell: (cell) => <TokenSymbol symbol={cell.row.values.symbol} />,
      Filter: '',
    },
    {
      Header: 'Token Name',
      accessor: 'name',
      Cell: (cell) => <TokenName cell={cell} showLogo={false} />,
    },
    {
      Header: 'Volume',
      accessor: 'buy_volume',
      Cell: (cell) => {
        if (
          cell.row.values.buy_volume === null ||
          cell.row.values.buy_volume === undefined
        )
          return '-';
        else {
          let buy_vol = formatValue('abbr', cell.row.values.buy_volume);
          let sell_vol = formatValue(
            'abbr',
            cell.data[cell.row.index].sell_volume
          );
          return (
            <>
              <div className="text-tradeBuy dark:text-tradeBuyDark text-xs">
                Buy vol : {buy_vol}
              </div>
              <div className="text-tradeSell dark:text-tradeSellDark text-xs">
                Sell Vol : {sell_vol}
              </div>
            </>
          );
        }
      },
      Filter: '',
    },
    {
      Header: 'Wallet Count',
      Cell: (cell) => (
        <div className="">
          <TokenActivity
            cell={cell}
            kind="wallet_count"
            action_group="top_trades"
          />
        </div>
      ),
    },
    {
      Header: 'Tx Count',
      Cell: (cell) => (
        <div className="">
          <TokenActivity
            cell={cell}
            kind="tx_count"
            action_group="top_trades"
          />
        </div>
      ),
    },
    {
      Header: 'Tx Per Wallet',
      Cell: (cell) => (
        <div className="">
          <TokenActivity
            cell={cell}
            kind="tx_per_wallet"
            action_group="top_trades"
          />
        </div>
      ),
    },
  ];

  return columns;
}
