import { Select } from 'Utilities';
import PropTypes from 'prop-types';
import React from 'react';
import {
  countOptions,
  durationOptions,
  earliestWalletOptions,
  operationOptions,
} from '../constants';

function Filters({
  walletOption,
  setWalletOption,
  durationOption,
  setDurationOption,
  countOption,
  setCountOption,
  operationOption,
  setOperationOption,
}) {
  const walletOptionProps = Object.freeze({
    optionsObj: earliestWalletOptions,
    state: walletOption,
    setState: setWalletOption,
  });
  const durationOptionProps = Object.freeze({
    optionsObj: durationOptions,
    state: durationOption,
    setState: setDurationOption,
  });
  const countOptionProps = Object.freeze({
    optionsObj: countOptions,
    state: countOption,
    setState: setCountOption,
  });
  const operationOptionProps = Object.freeze({
    optionsObj: operationOptions,
    state: operationOption,
    setState: setOperationOption,
  });
  return (
    <div className="flex flex-wrap gap-1">
      <Select {...walletOptionProps} />
      <Select {...durationOptionProps} />
      <Select {...countOptionProps} />
      <Select {...operationOptionProps} />
    </div>
  );
}

export default Filters;

Filters.propTypes = {
  walletOption: PropTypes.string,
  setWalletOption: PropTypes.func,
  durationOption: PropTypes.string,
  setDurationOption: PropTypes.func,
  countOption: PropTypes.string,
  setCountOption: PropTypes.func,
  operationOption: PropTypes.string,
  setOperationOption: PropTypes.func,
};
