/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import { connectToWallet } from 'Authentication/authentication';
import { getAxios } from 'Helper/requestServer';
import {
  AUTH_SUCCESS,
  DASHBOARD_DATA_FAILURE,
  DASHBOARD_DATA_LOADING,
  DASHBOARD_DATA_SUCCESS,
  GET_ALL_WALLET_SUBMISSIONS_FAILURE,
  GET_ALL_WALLET_SUBMISSIONS_LOADING,
  GET_ALL_WALLET_SUBMISSIONS_SUCCESS,
  GET_ALPHALEAKS_FAILURE,
  GET_ALPHALEAKS_LOADING,
  GET_ALPHALEAKS_SUCCESS,
  GET_ALPHA_REQUEST_FAILED,
  GET_ALPHA_REQUEST_LOADING,
  GET_ALPHA_REQUEST_SUCCESS,
  GET_ALPHA_REQ_PRELOAD_FAILED,
  GET_ALPHA_REQ_PRELOAD_LOADING,
  GET_ALPHA_REQ_PRELOAD_SUCCESS,
  GET_ALPHA_RESPONSE_FAILED,
  GET_ALPHA_RESPONSE_LOADING,
  GET_ALPHA_RESPONSE_SUCCESS,
  GET_ANALYTICS_DATA_FAILURE,
  GET_ANALYTICS_DATA_LOADING,
  GET_ANALYTICS_DATA_SUCCESS,
  GET_BLOCKCHAIN_FAILURE,
  GET_BLOCKCHAIN_SUCCESS,
  GET_COLLECTION_TRADE_DISTRIBUTION_FAILURE,
  GET_COLLECTION_TRADE_DISTRIBUTION_LOADING,
  GET_COLLECTION_TRADE_DISTRIBUTION_SUCCESS,
  GET_GAMING_DATA_FAILURE,
  GET_GAMING_DATA_LOADING,
  GET_GAMING_DATA_SUCCESS,
  GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_FAILURE,
  GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_LOADING,
  GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_SUCCESS,
  GET_LARGEST_TRANSFERS_DATA_FAILURE,
  GET_LARGEST_TRANSFERS_DATA_LOADING,
  GET_LARGEST_TRANSFERS_DATA_SUCCESS,
  GET_LATEST_DEX_TRADES_FAILURE,
  GET_LATEST_DEX_TRADES_LOADING,
  GET_LATEST_DEX_TRADES_POPUP_FAILURE,
  GET_LATEST_DEX_TRADES_POPUP_LOADING,
  GET_LATEST_DEX_TRADES_POPUP_SUCCESS,
  GET_LATEST_DEX_TRADES_SUCCESS,
  GET_LATEST_NFT_TRADES_BYTX_FAILURE,
  GET_LATEST_NFT_TRADES_BYTX_LOADING,
  GET_LATEST_NFT_TRADES_BYTX_SUCCESS,
  GET_LATEST_NFT_TRADES_FAILURE,
  GET_LATEST_NFT_TRADES_LOADING,
  GET_LATEST_NFT_TRADES_SUCCESS,
  GET_LATEST_TRADE_DISTRIBUTION_FAILURE,
  GET_LATEST_TRADE_DISTRIBUTION_LOADING,
  GET_LATEST_TRADE_DISTRIBUTION_SUCCESS,
  GET_LEADERBOARD_FAILURE,
  GET_LEADERBOARD_LOADING,
  GET_LEADERBOARD_SUCCESS,
  GET_LEAKS_DETAILS_FAILURE,
  GET_LEAKS_DETAILS_SUCCESS,
  GET_LEAKS_SUBMISSION_FAILURE,
  GET_LEAKS_SUBMISSION_SUCCESS,
  GET_NFT_DROPS_FAILURE,
  GET_NFT_DROPS_LOADING,
  GET_NFT_DROPS_SUCCESS,
  GET_NFT_LATEST_TRADES_FAILURE,
  GET_NFT_LATEST_TRADES_LOADING,
  GET_NFT_LATEST_TRADES_SUCCESS,
  GET_NOTABLETRADERS_DATA_FAILURE,
  GET_NOTABLETRADERS_DATA_LOADING,
  GET_NOTABLETRADERS_DATA_SUCCESS,
  GET_TOKEN_DEX_FAILURE,
  GET_TOKEN_DEX_LOADING,
  GET_TOKEN_DEX_POPUP_FAILURE,
  GET_TOKEN_DEX_POPUP_LOADING,
  GET_TOKEN_DEX_POPUP_SUCCESS,
  GET_TOKEN_DEX_SUCCESS,
  GET_TOKEN_INFO_FAILURE,
  GET_TOKEN_INFO_LOADING,
  GET_TOKEN_INFO_SUCCESS,
  GET_TOKEN_SUMMARY_FAILURE,
  GET_TOKEN_SUMMARY_SUCCESS,
  GET_TOPTRADES_CHART_DATA_FAILURE,
  GET_TOPTRADES_CHART_DATA_SUCCESS,
  GET_TOP_BALANCES_FAILURE,
  GET_TOP_BALANCES_LOADING,
  GET_TOP_BALANCES_SUCCESS,
  GET_TOP_LP_CHART_FAILURE,
  GET_TOP_LP_CHART_LOADING,
  GET_TOP_LP_CHART_SUCCESS,
  GET_TOP_LP_FAILURE,
  GET_TOP_LP_LOADING,
  GET_TOP_LP_SUCCESS,
  GET_TOP_LP_UNLISTED_CHART_FAILURE,
  GET_TOP_LP_UNLISTED_CHART_SUCCESS,
  GET_TOP_LP_UNLISTED_FAILURE,
  GET_TOP_LP_UNLISTED_LOADING,
  GET_TOP_LP_UNLISTED_SUCCESS,
  GET_TOP_NFT_BUY_FAILURE,
  GET_TOP_NFT_BUY_LOADING,
  GET_TOP_NFT_BUY_SUCCESS,
  GET_TOP_NFT_CONTRACT_FAILURE,
  GET_TOP_NFT_CONTRACT_LOADING,
  GET_TOP_NFT_CONTRACT_SUCCESS,
  GET_TOP_NFT_TRADES_FAILURE,
  GET_TOP_NFT_TRADES_LOADING,
  GET_TOP_NFT_TRADES_SUCCESS,
  GET_TOP_NFT_TRANSACTION_FAILURE,
  GET_TOP_NFT_TRANSACTION_LOADING,
  GET_TOP_NFT_TRANSACTION_SUCCESS,
  GET_TOP_TRADES_UNLISTED_CHART_FAILURE,
  GET_TOP_TRADES_UNLISTED_CHART_SUCCESS,
  GET_TOP_TRADES_UNLISTED_FAILURE,
  GET_TOP_TRADES_UNLISTED_LOADING,
  GET_TOP_TRADES_UNLISTED_SUCCES,
  GET_TOP_TRANSACTIONS_FAILURE,
  GET_TOP_TRANSACTIONS_LOADING,
  GET_TOP_TRANSACTIONS_SUCCESS,
  GET_USERLEAKS_FAILURE,
  GET_USERLEAKS_LOADING,
  GET_USERLEAKS_SUCCESS,
  GET_VIEWALPHALEAKS_FAILURE,
  GET_VIEWALPHALEAKS_LOADING,
  GET_VIEWALPHALEAKS_SUCCESS,
  GET_WALLET_SUBMISSION_FAILURE,
  GET_WALLET_SUBMISSION_SUCCESS,
  GET_WALLET_SUMISSION_DETAILS_FAILURE,
  GET_WALLET_SUMISSION_DETAILS_SUCCESS,
  HOMEPAGE_DATA_FAILURE,
  HOMEPAGE_DATA_SUCCESS,
  HOMEPAGE_LOADING,
  LEAKS_FORM_PRELOAD_FAILURE,
  LEAKS_FORM_PRELOAD_SUCCESS,
  SET_BLOCKCHAIN,
  SUBMIT_LEAKS,
  SUBMIT_LEAKS_FAILURE,
  SUBMIT_WALLET,
  SUBMIT_WALLET_FAILURE,
  TOKEN_SEARCH_FAILURE,
  TOKEN_SEARCH_LOADING,
  TOKEN_SEARCH_SUCCESS,
} from './action_type';
import { getOSContracts, getOSData } from './helper';

//top dex trades section
export const getToptrades = (
  chainId,
  limit,
  group,
  type,
  duration,
  filter,
  hideStandardCoins
) => {
  const url = `/dashboard/${chainId}?token_limit=${limit}&wallet_group=${group}&token_operation=${type}&token_duration=${duration}&sort_id=${filter}&show_standard_coins=${hideStandardCoins}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: DASHBOARD_DATA_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: DASHBOARD_DATA_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: DASHBOARD_DATA_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// home page action
export const getHomePageData = () => {
  const url = `/latest-metric?${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: HOMEPAGE_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: HOMEPAGE_DATA_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: HOMEPAGE_DATA_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// leask submissions
// get recent submissions
export const getLeaksSubmissions = (selectedAddress) => {
  const url = `/leaks-submissions?user_address=${selectedAddress}&${Date.now()}`;

  return (dispatch) => {
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LEAKS_SUBMISSION_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LEAKS_SUBMISSION_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// post leaks form
export const postLeaksForm = (formData) => {
  const url = '/submit-alphaleads';

  return (dispatch) => {
    getAxios()
      .post(url, formData)
      .then((response) => {
        dispatch({
          type: SUBMIT_LEAKS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: SUBMIT_LEAKS_FAILURE,
          data: { response: null, error },
        });
      });
  };
};

// leaks form dropdown preload
export const getLeaksFormPreload = () => {
  const url = '/leadform-preload';

  return (dispatch) => {
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: LEAKS_FORM_PRELOAD_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: LEAKS_FORM_PRELOAD_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// block chain dropdown
export const getBlockChain = () => {
  const url = '/blockchains';

  return (dispatch) => {
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_BLOCKCHAIN_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_BLOCKCHAIN_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// post wallet form
export const postWalletForm = (formData) => {
  const url = '/submit-wallet';

  return (dispatch) => {
    getAxios()
      .post(url, formData)
      .then((response) => {
        dispatch({
          type: SUBMIT_WALLET,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: SUBMIT_WALLET_FAILURE,
          data: { response: null, error },
        });
      });
  };
};

// get recent wallet submissions
export const getRecenetWalletSubmissions = (user_address) => {
  const url = `/recent-submissions?user_address=${user_address}&${Date.now()}`;

  return (dispatch) => {
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_WALLET_SUBMISSION_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_WALLET_SUBMISSION_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get latest dex trades
export const getLatestDexTrades = (
  block_chain,
  group,
  volLimit,
  traderAddress,
  tradeType,
  addressType
) => {
  const url = `/latest_dex_trades/${block_chain}?wallet_grp=${group}&wallet_limit=${volLimit}&trader_address=${traderAddress}&trade_type=${tradeType}&address_type=${addressType}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_LATEST_DEX_TRADES_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LATEST_DEX_TRADES_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LATEST_DEX_TRADES_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

export const getLatestDexTradesPopup = (txHash) => {
  const url = `/latest_dex_trades_token_popup?tx_hash=${txHash}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_LATEST_DEX_TRADES_POPUP_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LATEST_DEX_TRADES_POPUP_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LATEST_DEX_TRADES_POPUP_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get token summary data
export const getTokenSummary = (contract_address, chainId, network) => {
  const url = `/token_market_summary?contract_address=${contract_address}&chain=${chainId}&network=${network}&${Date.now()}`;

  return (dispatch) => {
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOKEN_SUMMARY_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_TOKEN_SUMMARY_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get top transactions
export const getTopTransactions = (contract_address) => {
  const url = `/toptransactions?contract_address=${contract_address}`;

  return (dispatch) => {
    dispatch({ type: GET_TOP_TRANSACTIONS_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_TRANSACTIONS_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_TRANSACTIONS_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get top balances
export const getTopBalances = (contract_address, chainId) => {
  const url = `/top_balance?token=${contract_address}&chain=${chainId}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_TOP_BALANCES_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_BALANCES_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_BALANCES_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get dex trades of selected token
export const getTokenDexTrades = (
  wallet_grp,
  volLimit,
  contract_address,
  traderAddress,
  tradeType,
  addressType,
  chain
) => {
  const url = `/latest_dex_trades_by_token?wallet_grp=${wallet_grp}&wallet_limit=${volLimit}&contract_address=${contract_address}&trader_address=${traderAddress}&trade_type=${tradeType}&address_type=${addressType}&chain=${chain}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_TOKEN_DEX_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOKEN_DEX_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOKEN_DEX_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
// Get data for latest dex trade popup
export const getTokenDexTradesPopup = (txHash, block_chain) => {
  const url = `/latest_dex_trades_token_popup/${block_chain}?tx_hash=${txHash}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_TOKEN_DEX_POPUP_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOKEN_DEX_POPUP_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOKEN_DEX_POPUP_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get gaming tab data
export const getGamingData = () => {
  const url = `/gaming`;

  return (dispatch) => {
    dispatch({ type: GET_GAMING_DATA_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_GAMING_DATA_SUCCESS,
          data: { response, error: null },
          loading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GAMING_DATA_FAILURE,
          data: { response: [], error },
          loading: false,
        });
      });
  };
};

// TODO: THIS FUNCTION NOT USED ANYWHERE, PLEASE REMOVE
// get view leaks page data
export const getViewLeaksData = (limit, end) => {
  const url = `/viewleaks?limit=${limit}&end=${end}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_VIEWALPHALEAKS_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_VIEWALPHALEAKS_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_VIEWALPHALEAKS_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get leaderboard page data
export const getLeaderBoardData = () => {
  const url = `/leaderboard?${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_LEADERBOARD_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LEADERBOARD_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LEADERBOARD_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get user leaks data
export const getUserLeaksData = (selectedAddress, filter) => {
  const url = `/user-leads?user_address=${selectedAddress}&filter=${filter}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_USERLEAKS_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_USERLEAKS_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_USERLEAKS_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get leaks details page data
export const getLeaksDetailsData = (submission_id) => {
  const url = `/alphaleads-details?submission_id=${submission_id}&${Date.now()}`;

  return (dispatch) => {
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LEAKS_DETAILS_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LEAKS_DETAILS_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get top u buys data in nft tab
export const getTopNftBuysData = () => {
  const url = `/nft?${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_TOP_NFT_BUY_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_NFT_BUY_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_NFT_BUY_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get top transactions in nft tab
export const getTopNftTransactionData = () => {
  const url = `/nft?${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_TOP_NFT_TRANSACTION_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_NFT_TRANSACTION_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_NFT_TRANSACTION_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get all wallet submissions
export const getAllWalletSubmissions = (user_address, filter) => {
  const url = `/recent-submissions?user_address=${user_address}&filter=${filter}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_ALL_WALLET_SUBMISSIONS_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_ALL_WALLET_SUBMISSIONS_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_WALLET_SUBMISSIONS_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getWalletSubmissionDetails = (submission_id) => {
  const url = `/submission-details?submission_id=${submission_id}&${Date.now()}`;

  return (dispatch) => {
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_WALLET_SUMISSION_DETAILS_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_WALLET_SUMISSION_DETAILS_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

export const getAnalyticsData = (
  query_token,
  token_operation,
  token_duration,
  sort_id,
  token_limit,
  block_chain
) => {
  const url = `/early-holders?query_token=${query_token}&token_operation=${token_operation}&token_duration=${token_duration}&sort_id=${sort_id}&token_limit=${token_limit}&chain=${block_chain}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_ANALYTICS_DATA_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_ANALYTICS_DATA_SUCCESS,
          data: { response, error: null },
          loading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ANALYTICS_DATA_FAILURE,
          data: { response: [], error },
          loading: false,
        });
      });
  };
};

export const getNotableTraders = (notableTrader) => {
  const url = `/notable-traders?option=${notableTrader}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_NOTABLETRADERS_DATA_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_NOTABLETRADERS_DATA_SUCCESS,
          data: { response, error: null },
          loading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_NOTABLETRADERS_DATA_FAILURE,
          data: { response: [], error },
          loading: false,
        });
      });
  };
};

export const getTopTradesChartData = (
  group,
  type,
  duration,
  hideStandardCoins
) => {
  const url = `/toptrades_chart?wallet_group=${group}&token_operation=${type}&token_duration=${duration}&show_standard_coins=${hideStandardCoins}&${Date.now()}`;
  return (dispatch) => {
    dispatch({ type: GET_TOP_TRADES_UNLISTED_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOPTRADES_CHART_DATA_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOPTRADES_CHART_DATA_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
// export const connectToMetaMaskWallet = () => {
//   return (dispatch) => {
//     connectToWallet()
//       .then((res) => {
//         dispatch({
//           type: META_MASK_AUTH_SUCCESS,
//           data: { res, error: null },
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: META_MASK_AUTH_FAIL,
//           data: { accountAddress: '', error },
//         });
//       });
//   };
// };

export const setAppTheme = (theme) => {
  const THEME_UPDATE = 'THEME_UPDATE';
  return (dispatch) => {
    dispatch({
      type: THEME_UPDATE,
      data: theme,
    });
  };
};

export const getToptTradesUnlisted = (
  block_chain,
  limit,
  group,
  type,
  duration,
  filter
  // hideUnlistedStandardCoins
) => {
  const url = `/toptrades_unlisted/${block_chain}?token_limit=${limit}&wallet_group=${group}&token_operation=${type}&token_duration=${duration}&sort_id=${filter}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_TOP_TRADES_UNLISTED_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_TRADES_UNLISTED_SUCCES,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_TRADES_UNLISTED_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getToptTradesUnlistedChartData = (
  group,
  type,
  duration,
  sort_key
  // hideUnlistedStandardCoins
) => {
  const url = `/toptrades_unlisted_chart?wallet_group=${group}&token_operation=${type}&token_duration=${duration}&sort_id=${sort_key}&${Date.now()}`;
  return (dispatch) => {
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_TRADES_UNLISTED_CHART_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_TRADES_UNLISTED_CHART_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getLargestTransfers = (block_chain, wallet_grp, wallet_limit) => {
  const url = `/largest-transfers/${block_chain}?wallet_grp=${wallet_grp}&wallet_limit=${wallet_limit}&${Date.now()}`;
  return (dispatch) => {
    dispatch({ type: GET_LARGEST_TRANSFERS_DATA_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LARGEST_TRANSFERS_DATA_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LARGEST_TRANSFERS_DATA_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getLargestTransfersByToken = (
  wallet_grp,
  wallet_limit,
  contract_address,
  chainId
) => {
  const url = `/largest-transfers-by-token?wallet_grp=${wallet_grp}&wallet_limit=${wallet_limit}&contract_address=${contract_address}&chain=${chainId}&${Date.now()}`;
  return (dispatch) => {
    dispatch({
      type: GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_LOADING,
      loading: true,
    });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getTokenInfo = (contract_address, chainId) => {
  const url = `/token_info?contract_address=${contract_address}&${Date.now()}&chain=${chainId}`;
  return (dispatch) => {
    dispatch({
      type: GET_TOKEN_INFO_LOADING,
      loading: true,
    });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOKEN_INFO_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOKEN_INFO_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getALphaleaksData = (rank, status, sort_key) => {
  const url = `/alphaleads?rank=${rank}&status=${status}&sort_key=${sort_key}&${Date.now()}`;
  return (dispatch) => {
    dispatch({
      type: GET_ALPHALEAKS_LOADING,
      loading: true,
    });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_ALPHALEAKS_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALPHALEAKS_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const walletAuthentication = (status, address) => {
  const authdata = { status: status, address: address };
  return (dispatch) => {
    dispatch({
      type: AUTH_SUCCESS,
      data: authdata,
    });
  };
};
export const getTokenSearch = (search_input, search_type, chain) => {
  const url = `/token-details-search?search_input=${search_input}&search_type=${search_type}&chain=${chain}&${Date.now()}`;
  return (dispatch) => {
    dispatch({
      type: TOKEN_SEARCH_LOADING,
      loading: true,
    });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: TOKEN_SEARCH_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: TOKEN_SEARCH_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getNftDropsData = (mintDate) => {
  const url = `/nft-drops?mint_date=${mintDate}&${Date.now()}`;
  return (dispatch) => {
    dispatch({
      type: GET_NFT_DROPS_LOADING,
      loading: true,
    });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_NFT_DROPS_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_NFT_DROPS_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// GET TOP LP
export const getTopLpData = (
  block_chain,
  limit,
  group,
  type,
  duration,
  filter,
  hideStandardCoins
) => {
  const url = `/toplp/${block_chain}?token_limit=${limit}&wallet_group=${group}&token_operation=${type}&token_duration=${duration}&sort_id=${filter}&show_standard_coins=${hideStandardCoins}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_TOP_LP_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_LP_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_LP_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
// GET TOP LP CHART DATA
export const getTopLpChartData = (
  group,
  type,
  duration,
  hideStandardCoins,
  block_chain
) => {
  const url = `/toplp_chart/${block_chain}?wallet_group=${group}&token_operation=${type}&token_duration=${duration}&show_standard_coins=${hideStandardCoins}&${Date.now()}`;
  return (dispatch) => {
    dispatch({ type: GET_TOP_LP_CHART_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_LP_CHART_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_LP_CHART_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// GET TOP LP UNLISTED
export const getToptLpUnlisted = (
  block_chain,
  limit,
  group,
  type,
  duration,
  filter
  // hideUnlistedStandardCoins
) => {
  const url = `/toplp_unlisted/${block_chain}?token_limit=${limit}&wallet_group=${group}&token_operation=${type}&token_duration=${duration}&sort_id=${filter}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_TOP_LP_UNLISTED_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_LP_UNLISTED_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_LP_UNLISTED_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// GET TOP LP ULISTED CHART
export const getTopLPUnlistedChartData = (
  group,
  type,
  duration,
  sort_key
  // hideUnlistedStandardCoins
) => {
  const url = `/toplp_unlisted_chart?wallet_group=${group}&token_operation=${type}&token_duration=${duration}&sort_id=${sort_key}&${Date.now()}`;
  return (dispatch) => {
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_TOP_LP_UNLISTED_CHART_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_LP_UNLISTED_CHART_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

// get latest dex trades
export const getNftLatestDexTrades = (
  chain_id,
  group,
  volLimit,
  traderAddress,
  addressType
) => {
  const url = `/latest-nft-trades/${chain_id}?wallet_grp=${group}&wallet_limit=${volLimit}&trader_address=${traderAddress}&address_type=${addressType}&${Date.now()}`;

  return (dispatch) => {
    dispatch({ type: GET_LATEST_NFT_TRADES_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LATEST_NFT_TRADES_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LATEST_NFT_TRADES_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

export const getLatestNftDexTradesByTx = (txHash) => {
  const url = `/latest-nft-trades-bytx?tx_hash=${txHash}&${Date.now()}`;
  console.log('called');
  return (dispatch) => {
    dispatch({ type: GET_LATEST_NFT_TRADES_BYTX_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LATEST_NFT_TRADES_BYTX_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LATEST_NFT_TRADES_BYTX_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

export const getTopNftTrades = (block_chain, limit, group, type, duration) => {
  const url = `/top-nft-trades/${block_chain}?token_limit=${limit}&wallet_group=${group}&token_operation=${type}&token_duration=${duration}&${Date.now()}`;
  return (dispatch) => {
    dispatch({ type: GET_TOP_NFT_TRADES_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        // calling opensea api
        const _r = getOSData(response.data);
        _r.then((ele) => {
          console.log(ele);
          setTimeout(() => {
            dispatch(
              {
                type: GET_TOP_NFT_TRADES_SUCCESS,
                data: { ele, error: null },
              },
              3000
            );
          });
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_NFT_TRADES_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getTopNftContracts = (
  block_chain,
  limit,
  group,
  type,
  duration,
  filter
) => {
  const url = `/top-nft-collections/${block_chain}?token_limit=${limit}&wallet_group=${group}&token_operation=${type}&token_duration=${duration}&sort_id=${filter}&${Date.now()}`;
  let bindData = [];
  return (dispatch) => {
    dispatch({ type: GET_TOP_NFT_CONTRACT_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        // calling opensea api
        const _r = getOSContracts(response.data);
        _r.then((ele) => {
          // binding response from alphastack api and opensea api
          bindData = [
            ...response.data.map((item, i) => Object.assign({}, item, ele[i])),
          ];
          // dispatching response after a delay - giving time to resolve Promise
          // immediately calling dispatch may result dispatching empty data
          console.log(`bind data in actions.js ln 1208->`, bindData);
          setTimeout(() => {
            dispatch(
              {
                type: GET_TOP_NFT_CONTRACT_SUCCESS,
                data: { bindData, error: null },
              },
              3000
            );
          });
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_TOP_NFT_CONTRACT_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getLatestTradesDistribution = (contract_address) => {
  const url = `/latest-trade-distribution?contract_address=${contract_address}`;
  return (dispatch) => {
    dispatch({ type: GET_LATEST_TRADE_DISTRIBUTION_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LATEST_TRADE_DISTRIBUTION_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LATEST_TRADE_DISTRIBUTION_FAILURE,
          data: { response: [], error },
        });
      });
  };
};
export const getLatestTradesCollection = (
  contract_address,
  group,
  volLimit,
  addressType,
  address
) => {
  const url = `/nft-latest-trade?contract_address=${contract_address}&wallet_grp=${group}&wallet_limit=${volLimit}&address_type=${addressType}&trader_address=${address}&${Date.now()}`;
  return (dispatch) => {
    dispatch({ type: GET_NFT_LATEST_TRADES_LOADING, loading: true });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_NFT_LATEST_TRADES_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_NFT_LATEST_TRADES_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

export const getCollectionTradesDistribution = (contract_address) => {
  const url = `/collection-trade-distribution?contract_address=${contract_address}`;
  return (dispatch) => {
    dispatch({
      type: GET_COLLECTION_TRADE_DISTRIBUTION_LOADING,
      loading: true,
    });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_COLLECTION_TRADE_DISTRIBUTION_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_COLLECTION_TRADE_DISTRIBUTION_FAILURE,
          data: { response: [], error },
        });
      });
  };
};

export const getAlphaRequests = (
  search,
  duration,
  status,
  reward,
  commentedStat,
  category
) => {
  const url = `/get-alpha-request?search=${search}&duration=${duration}&status=${status}&reward=${reward}&commentedStat=${commentedStat}&category=${category}&${Date.now()}`;

  return (dispatch) => {
    dispatch({
      type: GET_ALPHA_REQUEST_LOADING,
      loading: true,
    });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_ALPHA_REQUEST_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALPHA_REQUEST_FAILED,
          data: { response: [], error },
        });
      });
  };
};

export const getAlphaResponse = (request_id) => {
  const url = `/get-alpha-response?request_id=${request_id}&${Date.now()}`;
  return (dispatch) => {
    dispatch({
      type: GET_ALPHA_RESPONSE_LOADING,
      loading: true,
    });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_ALPHA_RESPONSE_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALPHA_RESPONSE_FAILED,
          data: { response: [], error },
        });
      });
  };
};

export const getAlphaReqPreload = () => {
  const url = `/alpha-request-preload?${Date.now()}`;
  return (dispatch) => {
    dispatch({
      type: GET_ALPHA_REQ_PRELOAD_LOADING,
      loading: true,
    });
    getAxios()
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_ALPHA_REQ_PRELOAD_SUCCESS,
          data: { response, error: null },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALPHA_REQ_PRELOAD_FAILED,
          data: { response: [], error },
        });
      });
  };
};

export const setSelectedChain = (chainId) => {
  return (dispatch) => {
    dispatch({
      type: SET_BLOCKCHAIN,
      chain_id: chainId,
    });
  };
};

export const sliderActions = (
  isLeaksSliderOver,
  isWalletSliderOver,
  openSlideOver
) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_SLIDER ',
      isLeaksSliderOver: isLeaksSliderOver,
      isWalletSliderOver: isWalletSliderOver,
      openSlideOver: openSlideOver,
    });
  };
};
