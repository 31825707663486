/* eslint-disable react/prop-types */
import { log } from 'Helper/log';
import { deleteAlphaRequest } from 'Helper/services';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import { Text } from 'Utilities';

export default function DeletRequest({
  showPopup,
  setShowPopup,
  alphaRequest,
  alphaRequests,
  setAlphaRequests,
  setShowNotify,
}) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  const style = {
    cancelButton: `bg-transparent py-2 px-4 border rounded-md shadow-sm text-sm font-semibold ${
      theme == 'dark' ? 'border-gray-700 text-white' : 'border-gray-300'
    }`,
    askButton: `ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
    modalTitle: `${theme == 'dark' ? 'text-white' : 'text-gray-800'}`,
    modalSubtitle: `${theme == 'dark' ? 'text-gray-400' : 'text-gray-600'}`,
  };

  const removeObject = (id) => {
    return alphaRequests.filter(function (item) {
      if (item.id == id) {
        return false;
      }
      return true;
    });
  };

  // const { socket } = useSocket();
  const deleteRequest = () => {
    deleteAlphaRequest(alphaRequest.id)
      .then((res) => {
        setShowPopup(false);
        if (res.data.status == 409) {
          setShowNotify(true);
        }
        setAlphaRequests(removeObject(alphaRequest.id));
      })
      .catch((err) => {
        log(err);
      });
  };
  return (
    <>
      <Text
        size="lg"
        fontWeight="semibold"
        text={`Are sure you want to delete?`}
        className="dark:text-white"
      />
      <Text
        size="sm"
        fontWeight="normal"
        text={`Once you confirmed it can't be undone`}
        className={theme == 'dark' ? 'text-gray-400' : ''}
      />
      <div
        className={`my-4 p-2 rounded ${
          theme == 'dark'
            ? 'bg-gray-900 text-gray-400 border-gray-700'
            : 'bg-gray-100'
        }`}
      >
        <h3
          className={`text-lg mb-4 ${
            theme == 'dark' ? 'text-white' : 'text-gray-800'
          }`}
        >
          {alphaRequest.title}
        </h3>
        <p>{alphaRequest.description}</p>
      </div>

      <div className="">
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className={style.cancelButton}
              onClick={() => setShowPopup(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={style.askButton}
              onClick={() => deleteRequest()}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
