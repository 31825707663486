/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';

function LeaksTypeSelector({ alphaButton, setAlphaButton, setShowUrlForm }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div className="cursor-pointer relative inline-block mr-2 flex-shrink">
      <label
        htmlFor="sourceUrl"
        className={`text-sm text-left font-medium mb-3 ${
          theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
        }`}
      >
        Choose Type:
      </label>
      <span
        className={`inline-flex items-center ml-2 px-4 py-2 cursor-pointer rounded-full text-xs bg-blue-50 dark:bg-dark-filterBgDark dark:text-dark-textPrimary font-medium text-blue-800${
          alphaButton === 'token'
            ? 'bg-blue-700 text-blue-600 border dark:text-dark-textPrimary border-blue-600 font-semibold'
            : ''
        }`}
        onClick={() => {
          setAlphaButton('token');
          setShowUrlForm(true);
        }}
      >
        Token
      </span>
      <span
        className={`inline-flex items-center ml-3 px-4 py-2 cursor-pointer rounded-full text-xs bg-blue-50 dark:bg-dark-filterBgDark dark:text-dark-textPrimary font-medium text-blue-800${
          alphaButton === 'nft'
            ? 'bg-blue-700 text-blue-600 border dark:text-dark-textPrimary border-blue-600 font-semibold'
            : ''
        }`}
        onClick={() => {
          setAlphaButton('nft');
          setShowUrlForm(true);
        }}
      >
        NFT
      </span>
    </div>
  );
}

export default LeaksTypeSelector;
