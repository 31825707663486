/* eslint-disable react/prop-types */
import { LinkIcon } from '@heroicons/react/solid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaksDetailsData } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { AlphaSentiment, EffectStatus, Etherscan } from 'Utilities';

import { SCAN_ADDRESS } from '../../../Helper/constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function LeaksDetails({ value, component }) {
  const submission_id = value ? value : null;

  // useDispatch() instance
  const dispatch = useDispatch();

  // listeing to redux store changes
  const { leaksDetails, theme } = useSelector((s = initialState) => {
    return {
      leaksDetails: s.leaksDetailsData.leaksDetails.data,
      theme: s.appTheme.theme,
    };
  });
  // useeffect hook start
  useEffect(() => {
    dispatch(getLeaksDetailsData(submission_id));
  }, []);

  // useeffect hook end
  return (
    <div className="m-4 flex flex-col h-full">
      <div className="flex-1">
        {component === 'userleaks' && leaksDetails ? (
          leaksDetails[0]?.effect_time_ended === 'x' ? (
            <>
              <h1
                className={`mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                }`}
              >
                Result
              </h1>
              <div
                className={`bg-gray-100 border rounded-sm ${
                  theme === 'dark' ? 'bg-gray-800 border border-gray-500' : null
                }`}
              >
                <div className="flex flex-row justify-between">
                  <div className="m-3">
                    <dl className="">
                      <dt
                        className={`text-sm font-medium  truncate mb-1 ${
                          theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                        }`}
                      >
                        Status
                      </dt>
                      <dd className="text-white rounded-md text-sm">
                        {leaksDetails ? (
                          <EffectStatus status={leaksDetails[0].status} />
                        ) : (
                          'No status Available'
                        )}
                      </dd>
                    </dl>
                  </div>
                  <div className="m-3">
                    <dl className="">
                      <dt
                        className={`text-sm font-medium  truncate mb-1 ${
                          theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                        }`}
                      >
                        Earned Score
                      </dt>
                      <dd
                        className={`text-sm px-4 py-2 text-center font-semibold ${
                          theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                        }`}
                      >
                        {leaksDetails ? leaksDetails[0].score : '-'}
                      </dd>
                    </dl>
                  </div>
                  {leaksDetails ? (
                    leaksDetails[0].review_timestamp_utc ? (
                      <div className="m-3">
                        <dl className="">
                          <dt
                            className={`text-sm font-medium  truncate mb-1 ${
                              theme === 'dark'
                                ? 'text-gray-300'
                                : 'text-gray-500'
                            }`}
                          >
                            Reviewed on
                          </dt>
                          <dd
                            className={`text-sm py-2 font-semibold ${
                              theme === 'dark'
                                ? 'text-gray-200'
                                : 'text-gray-700'
                            }`}
                          >
                            {leaksDetails
                              ? new Date(
                                  leaksDetails[0].review_timestamp_utc
                                ).toUTCString('en-US')
                              : '-'}
                          </dd>
                        </dl>
                      </div>
                    ) : null
                  ) : null}
                </div>
                <div className="flex">
                  <div className="m-3">
                    <dl className="">
                      <dt
                        className={`text-sm font-medium  truncate mb-1 ${
                          theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                        }`}
                      >
                        Score Comments
                      </dt>
                      <dd className="text-white rounded-md text-sm">
                        {leaksDetails
                          ? leaksDetails[0].score_comments
                          : 'No status Available'}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </>
          ) : null
        ) : null}

        <div className="grid grid-cols-2 gap-4 my-6">
          <div>
            <dl className="">
              <dt
                className={`text-sm font-medium  truncate mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                }`}
              >
                Project Name
              </dt>
              <dd
                className={`text-sm py-2 font-semibold ${
                  theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                }`}
              >
                {leaksDetails ? leaksDetails[0].project_name : '-'}
              </dd>
            </dl>
          </div>
          <div>
            <dl className="">
              <dt
                className={`text-sm font-medium  truncate mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                }`}
              >
                Ticker
              </dt>
              <dd
                className={`text-sm py-2 font-semibold ${
                  theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                }`}
              >
                {leaksDetails ? leaksDetails[0].ticker : '-'}
              </dd>
            </dl>
          </div>
          <div>
            <dl className="">
              <div className="flex">
                <dt
                  className={`text-sm font-medium  truncate mb-1 ${
                    theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                  }`}
                >
                  Website
                </dt>
                <span>
                  <LinkIcon
                    className={`h-4 w-4 ml-2 ${
                      theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                    }`}
                  />
                </span>
              </div>

              <dd
                className={`text-sm py-2 ${
                  theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                }`}
              >
                {leaksDetails ? (
                  <a
                    href={leaksDetails[0].website}
                    className="text-blue-700 font-semibold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {leaksDetails[0].website}
                  </a>
                ) : (
                  '-'
                )}
              </dd>
            </dl>
          </div>
          <div>
            <dl className="">
              <dt
                className={`text-sm font-medium  truncate mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                }`}
              >
                Markets
              </dt>
              <dd
                className={`text-sm py-2 font-semibold break-words ${
                  theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                }`}
              >
                <a
                  href={leaksDetails ? leaksDetails[0].markets : '-'}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500"
                >
                  {leaksDetails ? leaksDetails[0].markets : '-'}
                </a>
              </dd>
            </dl>
          </div>
          <div>
            <dl className="">
              <dt
                className={`text-sm font-medium  truncate mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                }`}
              >
                Alpha
              </dt>
              <dd
                className={`text-sm py-2 font-semibold ${
                  theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                }`}
              >
                {leaksDetails ? leaksDetails[0].alpha : '-'}
              </dd>
            </dl>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 my-6">
          <div className="">
            <dl className="">
              <dt
                className={`text-sm font-medium  truncate mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                }`}
              >
                Sentiment
              </dt>
              <dd className="text-sm py-2 rounded-md">
                {leaksDetails ? (
                  <AlphaSentiment sentiment={leaksDetails[0].sentiment} />
                ) : (
                  'no sentiment'
                )}
              </dd>
            </dl>
          </div>
          <div className="">
            <dl className="">
              <dt
                className={`text-sm font-medium  truncate mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                }`}
              >
                Effect Period
              </dt>
              <dd
                className={`text-sm py-2 font-semibold ${
                  theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                }`}
              >
                {leaksDetails ? leaksDetails[0].effect_seen_in : '-'}
              </dd>
            </dl>
          </div>
          {component === 'alphaleaks' ? (
            <div className="">
              <dl className="">
                <dt
                  className={`text-sm font-medium  truncate mb-1 ${
                    theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                  }`}
                >
                  Effect Status
                </dt>
                <dd
                  className={`text-sm py-2 font-semibold ${
                    theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                  }`}
                >
                  {leaksDetails ? (
                    <EffectStatus status={leaksDetails[0].status} />
                  ) : (
                    '-'
                  )}
                </dd>
              </dl>
              <dl className="mt-4">
                <dt
                  className={`text-sm font-medium  truncate mb-1 ${
                    theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                  }`}
                >
                  Market Segment
                </dt>
                <dd
                  className={`text-sm py-2 font-semibold ${
                    theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                  }`}
                >
                  {leaksDetails ? leaksDetails[0].market_segment : '-'}
                </dd>
              </dl>
            </div>
          ) : null}

          {component === 'userleaks' ? (
            <div className="">
              <dl className="">
                <dt
                  className={`text-sm font-medium  truncate mb-1 ${
                    theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                  }`}
                >
                  Submitted on (UTC)
                </dt>
                <dd
                  className={`text-sm py-2 font-semibold ${
                    theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                  }`}
                >
                  {leaksDetails
                    ? new Date(
                        leaksDetails[0].submission_timestamp_utc
                      ).toUTCString('en-US')
                    : '-'}
                </dd>
              </dl>
            </div>
          ) : null}
          <div className="">
            <dl className="">
              <dt
                className={`text-sm font-medium  truncate mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                }`}
              >
                Expiration (UTC)
              </dt>
              <dd
                className={`text-sm py-2 font-semibold ${
                  theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                }`}
              >
                {leaksDetails
                  ? new Date(
                      leaksDetails[0].effect_end_timestamp_utc
                    ).toUTCString('en-US')
                  : '-'}
              </dd>
            </dl>
          </div>
        </div>
        <div className="">
          <dl className="">
            <dt
              className={`text-sm font-medium  truncate mb-1 ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              Comments
            </dt>
            <dd
              className={`text-sm font-semibold break-words overflow-hidden ${
                theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
              }`}
            >
              {leaksDetails ? leaksDetails[0].comments : ' ugh Oh no comments!'}
            </dd>
          </dl>
        </div>
      </div>
      <div className="justify-between">
        {component === 'alphaleaks' ? (
          <div className="relative">
            <div
              className={`border rounded-sm ${
                theme === 'dark'
                  ? 'bg-gray-800 border border-gray-500'
                  : 'bg-light-bgAccent'
              }`}
            >
              <div className="flex flex-row justify-between">
                <div className="m-3">
                  <dl className="">
                    <dt
                      className={`text-sm font-medium  truncate mb-1 ${
                        theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                      }`}
                    >
                      Submitted by
                    </dt>
                    <dd className="rounded-md text-sm py-2 truncate">
                      {leaksDetails ? (
                        <Etherscan
                          address={leaksDetails[0].user_address}
                          type={SCAN_ADDRESS}
                          isSubString={true}
                          chainId={''}
                        />
                      ) : (
                        'No status Available'
                      )}
                    </dd>
                  </dl>
                </div>
                <div className="m-3">
                  <dl className="">
                    <dt
                      className={`text-sm font-medium  truncate mb-1 ${
                        theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                      }`}
                    >
                      Submitted on
                    </dt>
                    <dd
                      className={`text-sm py-2 font-semibold ${
                        theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                      }`}
                    >
                      {leaksDetails
                        ? new Date(
                            leaksDetails[0].submission_timestamp_utc
                          ).toUTCString('en-US')
                        : '-'}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default LeaksDetails;
