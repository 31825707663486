/* eslint-disable react/prop-types */
import { addressFormat } from 'Components/Login/Helper/formater';
import { chainProvider } from 'Helper/chainProvider';
import { CHAINS, SCAN_TX } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import { getUrlParam } from 'Helper/getUrlPram';
import Etherscan from 'Utilities/Etherscan/etherscan';
import InputFilter from 'Utilities/Table/inputFilter';
import { SCAN_ADDRESS } from 'Helper/constants';

const RenderTokenName = ({ address, name }) => {
  const block_chain = CHAINS[getUrlParam('chain')];

  return (
    <a
      className="mr-1 text-blue-500"
      href={`${chainProvider(block_chain)}/token/${`${address}`}`}
      target="_blank"
      rel="noreferrer"
    >
      {name ? name : addressFormat(address)}
    </a>
  );
};

export const summaryColumn = [
  {
    Header: 'Tx Hash',
    accessor: 'transaction_hash',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.transaction_hash}
        type={SCAN_TX}
        isSubString={true}
      />
    ),
    isSearchClicked: false,
    action: '',
    disableSortBy: true,
    Filter: InputFilter,
  },
  {
    Header: 'Token Name',
    accessor: 'token_name',
    Cell: (cell) => (
      <RenderTokenName
        address={cell.data[cell.row.index].transaction_hash}
        name={cell.row.values.token_name}
      />
    ),
    disableSortBy: true,
    Filter: InputFilter,
  },
  {
    Header: 'Token Symbol',
    accessor: 'token_symbol',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.token_symbol}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    disableSortBy: true,
    Filter: '',
  },
  {
    Header: 'Buy Token Amount',
    accessor: 'buy_token_amount',
    Cell: (cell) => (
      <p className="text-green-500">
        {formatValue('format', cell.row.values.buy_token_amount)}
      </p>
    ),
    disableSortBy: true,
    Filter: '',
  },
  {
    Header: 'Sell Token Amount',
    accessor: 'sell_token_amount',
    Cell: (cell) => (
      <p className="text-red-500">
        {formatValue('format', cell.row.values.sell_token_amount)}
      </p>
    ),
    disableSortBy: true,
    Filter: '',
  },
  {
    Header: 'Buy Token Val USD (Tx Date)',
    accessor: 'buy_token_value_usd_txdate',
    Cell: (cell) => (
      <p className="text-green-500">
        {formatValue('format', cell.row.values.buy_token_value_usd_txdate)}
      </p>
    ),
    disableSortBy: true,
    Filter: '',
  },
  {
    Header: 'Sell Token Val USD (Tx Date)',
    accessor: 'sell_token_value_usd_txdate',
    Cell: (cell) => (
      <p className="text-red-500">
        {formatValue('format', cell.row.values.sell_token_value_usd_txdate)}
      </p>
    ),
    disableSortBy: true,
    Filter: '',
  },
  {
    Header: 'Buy Token Val USD Current',
    accessor: 'buy_token_value_usd_current',
    Cell: (cell) => (
      <p className="text-green-500">
        {formatValue('format', cell.row.values.buy_token_value_usd_current)}
      </p>
    ),
    disableSortBy: true,
    Filter: '',
  },
  {
    Header: 'Sell Token Val USD Current',
    accessor: 'sell_token_value_usd_current',
    Cell: (cell) => (
      <p className="text-red-500">
        {formatValue('format', cell.row.values.sell_token_value_usd_current)}
      </p>
    ),
    disableSortBy: true,
    Filter: '',
  },
];
