export const formatMetricData = (data) => {
  let dataSet = {};
  let tokenBreakingLow = {};
  let tokenBreakingHigh = {};
  let airDrop = {};

  if (data && data.length > 0) {
    tokenBreakingLow['rows'] = data[5]['data_json']?.rows;
    tokenBreakingLow['last_update'] =
      data[5]['metrics_data_last_updated_timestamp_utc'];
    tokenBreakingLow['is_render'] =
      Object.keys(tokenBreakingLow.rows[0]).length > 1;

    tokenBreakingHigh['rows'] = data[8]['data_json']?.rows;
    tokenBreakingHigh['last_update'] =
      data[8]['metrics_data_last_updated_timestamp_utc'];
    tokenBreakingHigh['is_render'] =
      Object.keys(tokenBreakingHigh.rows[0]).length > 1;

    airDrop['rows'] = data[4]['data_json']?.rows;
    airDrop['last_update'] = data[4]['metrics_data_last_updated_timestamp_utc'];

    dataSet['tbatl'] = tokenBreakingLow;
    dataSet['tbath'] = tokenBreakingHigh;
    dataSet['airdrop'] = airDrop;
  }
  return dataSet;
};
