import { RefreshIcon } from '@heroicons/react/outline';
import { VAULT_REDIRECT_URI } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import {
  getLoggedUser,
  getOidcAuthUrl,
  getUserClusterList,
} from 'Helper/services';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { useEffect, useState } from 'react';

function VaultUserAuth() {
  const [selectedCluster, setSelectedCluster] = useState(getUrlParam('name'));
  const [userClusterList, setUserClusterList] = useState();
  const [isAuthRunning, setIsAuthRunning] = useState(false);

  const { user_address } = useGetCurrentUser();
  // const cluster_name = getUrlParam('name');
  // sessionStorage.setItem('catalog_cluster', getUrlParam('name'));

  const getUserClusters = (user_id) => {
    getUserClusterList(user_id, 'ready')
      .then((_r) => {
        if (_r.status) setUserClusterList(_r.data);
      })
      .catch((err) => {
        console.log('Get user cluster list error' + err.message);
      });
  };

  const handleOidcLogin = async () => {
    sessionStorage.removeItem('catalog_cluster');
    sessionStorage.setItem('catalog_cluster', selectedCluster);
    setIsAuthRunning(true);
    const user = await getLoggedUser(user_address);
    if (user) {
      const data = {
        cluster: {
          clusterId: selectedCluster,
          zone: 'us-central1-a',
          namespace: user.message[0].username_internal,
        },
        metadata: {
          role: 'oidc-role',
          redirect_uri: VAULT_REDIRECT_URI,
        },
      };
      const auth = await getOidcAuthUrl(data);
      if (auth.status) {
        window.location.href = auth.auth_url;
      } else {
        setIsAuthRunning(false);
      }
    }
  };

  const handleChange = (val) => {
    console.log(val);
    sessionStorage.setItem('catalog_cluster', val);
    setSelectedCluster(val);
  };
  useEffect(() => {
    getLoggedUser(user_address).then((_r) => {
      const user_id = _r?.message[0].id;
      getUserClusters(user_id);
    });
  }, []);
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
          <div className="dark:text-white my-4 text-lg font-semibold">
            Sign in With Ethereum to store your data catalog information
            securely
          </div>
          {/* <p className="dark:text-dark-textPrimary py-2 text-sm">
            Configure your catalog to query a MySQL database,
          </p> */}
          <div className="dark:text-white">
            <ul className="text-sm list-disc m-4">
              <li className="my-2">
                Your secrets for the catalog connection info such as username,
                password etc (that you will fill out in the next page) will be
                stored in your cluster&apos;s vault.
              </li>
              <li>
                The vault has been configured to use your Ethereum address as
                your identifier.
              </li>
            </ul>
          </div>
          <div className="sm:mt-6">
            <label
              htmlFor="cluster"
              className="text-xs dark:text-dark-textPrimary"
            >
              Select cluster
            </label>
            <select
              id="cluster"
              name="cluster"
              className="block w-full sm:w-1/2 rounded-md p-2  text-xs  dark:bg-transparent dark:text-dark-textPrimary bg-white  focus:outline-none border border-gray-600 dark:border-gray-500"
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            >
              <option
                value={getUrlParam('name')}
                selected
                className="dark:text-gray-300"
              >
                {getUrlParam('name') ? getUrlParam('name') : 'Select cluster'}
              </option>
              {/* <option
                value={selectedCluster}
                selected
                className="dark:text-gray-300"
              >
                {selectedCluster}
              </option> */}
              {userClusterList &&
                userClusterList.map((c, key) => (
                  <option key={key} className="dark:bg-black">
                    {c.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex space-x-3 my-3">
            <button
              type="button"
              className={`inline-flex  items-center rounded-md border border-transparent  bg-blue-600 px-4 py-1.5 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                !selectedCluster && 'cursor-not-allowed'
              }`}
              onClick={handleOidcLogin}
              disabled={!selectedCluster}
            >
              {isAuthRunning ? (
                <RefreshIcon className="w-4 animate-spin" />
              ) : (
                'Sign in with ethereum'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default VaultUserAuth;
