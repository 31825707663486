import { getUrlParam } from 'Helper/getUrlPram';

export interface window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
}
export interface InitialStateProps {
  dashboard: {
    token: [],
    max: 0,
    response: [],
    loading: false,
    error: null,
  };
  homePage: {
    matrics: [],
    response: [],
    loading: false,
    error: null,
  };
  recentLeaks: {
    leaks: [],
    response: [],
    error: null,
  };
  submitLeaksForm: {
    leaksdata: {
      project: string,
      ticker: string,
      markets: string,
      alpha: string,
      sentiment: string,
      effect: string,
      comment: string,
      user_address: string,
    },
    response: null,
    error: null,
  };
  leakFormPreload: {
    preload: [],
    response: [],
    error: null,
  };
  blockChainData: {
    blockChain: [],
    response: [],
    error: null,
  };
  submitWalletForm: {
    walletData: {
      blockchain: [],
      label: [],
      walletaddress: string,
      research: string,
    },
    response: null,
    error: null,
  };
  recentWalletSubmissions: {
    recentSubmissions: [],
    response: [],
    error: null,
  };
  latestDexTrades: {
    dexTrades: [],
    response: [],
    error: null,
    loading: false,
  };
  latestDexTradesPopup: {
    dexTrades: [],
    response: [],
    error: null,
    loading: false,
  };
  tokenSummaryData: {
    tokenSummary: [],
    response: [],
    error: null,
  };
  topTransactionData: {
    topTransaction: [],
    resposne: [],
    error: null,
    loading: false,
  };
  topBalanceData: {
    topBalance: [],
    response: [],
    error: null,
    loading: false,
  };
  tokenDexTrades: {
    dexTrades: [],
    response: [],
    error: null,
    loading: false,
  };
  tokenDexTradesPopup: {
    dexTrades: [],
    response: [],
    error: null,
    loading: false,
  };
  gamingData: {
    gaming: [],
    response: [],
    loading: false,
    error: null,
  };
  viewLeaksData: {
    viewLeaks: [],
    response: [],
    error: null,
  };
  leaderBoardData: {
    leaderBoard: [],
    response: [],
    loading: false,
    error: null,
  };
  userLeaksData: {
    userLeaks: [],
    response: [],
    error: null,
    loading: false,
  };
  leaksDetailsData: {
    leaksDetails: [],
    response: [],
    error: null,
  };
  nftTopBuysData: {
    nftTopBuys: [],
    response: [],
    loading: false,
    error: null,
  };
  nftTopTransactionData: {
    nftTopTransaction: [],
    loading: false,
    response: [],
    error: null,
  };
  allWalletSubmissionsData: {
    allWalletSubmissions: [],
    response: [],
    error: null,
    loading: false,
  };
  walletSubmissiondetailsData: {
    submissionDetails: [],
    response: [],
    error: null,
  };
  analyticsData: {
    analytics: [],
    response: [],
    error: null,
    loading: false,
  };
  notableTradersData: {
    notableTraders: [],
    response: [],
    error: null,
    loading: false,
  };
  topTradesChartData: {
    topTradesChart: [],
    response: [],
    error: null,
    loading: false,
  };
  appTheme: {
    theme: 'dark',
  };
  topTradesUnlistedData: {
    topTradesUnlisted: [],
    response: [],
    error: null,
    loading: false,
  };
  topTradesUnlistedChartData: {
    topTradesUnlistedChart: [],
    response: [],
    error: null,
    loading: false,
  };
  largestTranfersData: {
    largestTransfers: [],
    response: [],
    error: null,
    loading: false,
  };
  largestTranfersByTokenData: {
    largestTransfersbytoken: [],
    response: [],
    error: null,
    loading: false,
  };
  tokenInfoData: {
    tokenInfo: [],
    response: [],
    error: null,
    loading: false,
  };
  alphaleaksData: {
    alphaleaks: [],
    response: [],
    error: null,
    loading: false,
  };
  walletAuthentication: {
    userAddress: null,
    authStatus: null,
  };
  tokenSearchDetails: {
    searchInfo: [],
    error: null,
    loading: false,
  };
  nftDropsData: {
    nftDrops: [],
    error: null,
    loading: false,
  };
  topLPData: {
    topLp: [],
    max: 0,
    response: [],
    loading: false,
    error: null,
  };
  topLpChartData: {
    toplpchart: [],
    response: [],
    error: null,
    loading: false,
  };
  topLpUnlistedData: {
    topLpUnlisted: [],
    response: [],
    error: null,
    loading: false,
  };
  topLpUnlistedChartData: {
    topLpUnlistedChart: [],
    response: [],
    error: null,
    loading: false,
  };
  nftLatestTradesData: {
    nftLatestTrades: [],
    response: [],
    error: null,
    loading: false,
  };
  nftLatestTradesByTXData: {
    nftLatestTradesByTX: [],
    response: [],
    error: null,
    loading: false,
  };
  nftTopTradesData: {
    nftTopTrades: [],
    response: [],
    error: null,
    loading: false,
  };
  nftTopContractsData: {
    nftTopContracts: [],
    response: [],
    error: null,
    loading: false,
  };
  latestTradeDistributionData: {
    latestTradeDistribution: [],
    response: [],
    error: null,
    loading: false,
  };
  collectionLatestTradesData: {
    collectionLatestTrades: [],
    response: [],
    error: null,
    loading: false,
  };
  collectionTradeDistributionData: {
    collectionTradeDistribution: [],
    response: [],
    error: null,
    loading: false,
  };
  alphaRequestData: {
    response: [],
    error: null,
    loading: false,
  };
  alphaResponseData: {
    response: [],
    error: null,
    loading: false,
  };
  alphaReqPreloadData: {
    response: [],
    error: null,
    loading: false,
  };
  currentBlockChain: {
    selectedBlockChain: 0,
  };
  earnTokenSlider: {
    isLeaksSliderOver: false,
    isWalletSliderOver: false,
    openSliderOver: '',
  };
}

export const initialState: InitialStateProps = {
  dashboard: {
    token: [],
    response: [],
    loading: false,
    error: null,
  },
  homePage: {
    matrics: [],
    response: [],
    loading: false,
    error: null,
  },
  recentLeaks: {
    leaks: [],
    response: [],
    error: null,
  },
  submitLeaksForm: {
    leaksdata: {
      project: '',
      ticker: '',
      markets: '',
      alpha: '',
      sentiment: '',
      effect: '',
      comment: '',
      user_address: '',
    },
    response: null,
    error: null,
  },
  leakFormPreload: {
    preload: [],
    response: [],
    error: null,
  },
  blockChainData: {
    blockchain: [],
    response: [],
    error: null,
  },
  submitWalletForm: {
    walletData: {
      blockchain: [],
      label: [],
      walletaddress: '',
      research: '',
    },
    response: null,
    error: null,
  },
  recentWalletSubmissions: {
    recentSubmissions: [],
    response: [],
    error: null,
  },
  latestDexTrades: {
    dexTrades: [],
    response: [],
    error: null,
    loading: false,
  },
  latestDexTradesPopup: {
    dexTrades: [],
    response: [],
    error: null,
    loading: false,
  },
  tokenSummaryData: {
    tokenSummary: [],
    response: [],
    error: null,
  },
  topTransactionData: {
    topTransaction: [],
    resposne: [],
    error: null,
    loading: false,
  },
  topBalanceData: {
    topBalance: [],
    response: [],
    error: null,
    loading: false,
  },
  tokenDexTrades: {
    dexTrades: [],
    response: [],
    error: null,
    loading: false,
  },
  tokenDexTradesPopup: {
    dexTrades: [],
    response: [],
    error: null,
    loading: false,
  },
  gamingData: {
    gaming: [],
    response: [],
    loading: false,
    error: null,
  },
  viewLeaksData: {
    viewLeaks: [],
    response: [],
    error: null,
  },
  leaderBoardData: {
    leaderBoard: [],
    response: [],
    loading: false,
    error: null,
  },
  userLeaksData: {
    userLeaks: [],
    response: [],
    error: null,
    loading: false,
  },
  leaksDetailsData: {
    leaksDetails: [],
    response: [],
    error: null,
  },
  nftTopBuysData: {
    nftTopBuys: [],
    response: [],
    loading: false,
    error: null,
  },
  nftTopTransactionData: {
    nftTopTransaction: [],
    response: [],
    loading: false,
    error: null,
  },
  allWalletSubmissionsData: {
    allWalletSubmissions: [],
    response: [],
    error: null,
    loading: false,
  },
  walletSubmissiondetailsData: {
    submissionDetails: [],
    response: [],
    error: null,
  },
  analyticsData: {
    analytics: [],
    response: [],
    error: null,
    loading: false,
  },
  notableTradersData: {
    notableTraders: [],
    response: [],
    error: null,
    loading: false,
  },
  topTradesChartData: {
    topTradesChart: [],
    response: [],
    error: null,
    loading: false,
  },
  appTheme: {
    theme: 'dark',
  },
  topTradesUnlistedData: {
    topTradesUnlisted: [],
    response: [],
    error: null,
    loading: false,
  },
  topTradesUnlistedChartData: {
    topTradesUnlistedChart: [],
    response: [],
    error: null,
    loading: false,
  },
  largestTranfersData: {
    largestTransfers: [],
    response: [],
    error: null,
    loading: false,
  },
  largestTranfersByTokenData: {
    largestTransfersbytoken: [],
    response: [],
    error: null,
    loading: false,
  },
  tokenInfoData: {
    tokenInfo: [],
    response: [],
    error: null,
    loading: false,
  },
  alphaleaksData: {
    alphaleaks: [],
    response: [],
    error: null,
    loading: false,
  },
  walletAuthentication: {
    userAddress: null,
    authStatus: null,
  },
  tokenSearchDetails: {
    searchInfo: [],
    error: null,
    loading: false,
  },
  nftDropsData: {
    nftDrops: [],
    error: null,
    loading: false,
  },
  topLPData: {
    topLp: [],
    max: 0,
    response: [],
    loading: false,
    error: null,
  },
  topLpChartData: {
    toplpchart: [],
    response: [],
    error: null,
    loading: false,
  },
  topLpUnlistedData: {
    topLpUnlisted: [],
    response: [],
    error: null,
    loading: false,
  },
  topLpUnlistedChartData: {
    topLpUnlistedChart: [],
    response: [],
    error: null,
    loading: false,
  },
  nftLatestTradesData: {
    nftLatestTrades: [],
    response: [],
    error: null,
    loading: false,
  },
  nftLatestTradesByTXData: {
    nftLatestTradesByTX: [],
    response: [],
    error: null,
    loading: false,
  },
  nftTopTradesData: {
    nftTopTrades: [],
    response: [],
    error: null,
    loading: false,
  },
  nftTopContractsData: {
    nftTopContracts: [],
    response: [],
    error: null,
    loading: false,
  },
  latestTradeDistributionData: {
    latestTradeDistribution: [],
    response: [],
    error: null,
    loading: false,
  },
  collectionLatestTradesData: {
    collectionLatestTrades: [],
    response: [],
    error: null,
    loading: false,
  },
  collectionTradeDistributionData: {
    collectionTradeDistribution: [],
    response: [],
    error: null,
    loading: false,
  },
  alphaRequestData: {
    response: [],
    error: null,
    loading: false,
  },
  alphaResponseData: {
    response: [],
    error: null,
    loading: false,
  },
  alphaReqPreloadData: {
    response: [],
    error: null,
    loading: false,
  },
  currentBlockChain: {
    selectedBlockChain: 0,
  },
  earnTokenSlider: {
    isLeaksSliderOver: false,
    isWalletSliderOver: false,
    openSliderOver: '',
  },
};
