import { EyeIcon, EyeOffIcon, LockClosedIcon } from '@heroicons/react/outline';
import { USER_SECRET_KEY } from 'Helper/constants';
import { useThemeToggle } from 'Hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { handleSignIn } from 'Helper/services';
import { getMaxAge } from 'Helper/maxAge';

function Verify() {
  const [sKey, setSkey] = useState('');
  const [sKeyError, setSKeyError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { user_address } = useGetCurrentUser();
  const verifyKey = () => {
    if (sKey === USER_SECRET_KEY) {
      handleSignIn(user_address).then((_r) => {
        sessionStorage.setItem(
          'user_info',
          JSON.stringify({
            user_id: _r.message.user_id,
            username_internal: _r.message.username_internal,
            public_address: _r.message.public_address,
          })
        );
      });
      const maxAge = getMaxAge(1);
      const userObj = { key: USER_SECRET_KEY, expires_at: maxAge };
      sessionStorage.setItem('skValidated', true);
      localStorage.setItem('verified_user', JSON.stringify(userObj));
      navigate('/app/home/welcome/quick-access');
    } else {
      setSKeyError('Invalid key');
    }
  };

  const listener = (e) => {
    if (e.code === 'Enter' || e.code === 'NumPadEnter') {
      verifyKey();
    }
  };

  const [colorTheme] = useThemeToggle();

  return (
    <div
      className={`flex justify-center items-center h-screen ${
        colorTheme == 'dark' || colorTheme == undefined || colorTheme == ''
          ? 'bg-light-themePrimary'
          : 'bg-dark-themeSecondary'
      }`}
    >
      <div>
        <div className="mx-2">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <LockClosedIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <div
                className={`text-xl font-semibold leading-6  
                  dark:text-gray-100 text-gray-700
                `}
              >
                Access Code
              </div>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  The app is currently in Beta and access is by invitation only
                </p>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-6">
            <div className="flex items-center rounded-md border dark:border-gray-700 border-gray-500 px-2">
              <input
                type={showPassword ? 'text' : 'password'}
                value={sKey}
                placeholder="Please enter your invitation code"
                onChange={(e) => setSkey(e.target.value)}
                onKeyDown={listener}
                className={`w-full p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none `}
              />
              {sKey.length > 0 &&
                (showPassword ? (
                  <EyeOffIcon
                    className="w-5 dark:text-gray-500"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <EyeIcon
                    className="w-5 dark:text-gray-500"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ))}
            </div>
            {sKeyError.length > 0 && (
              <span className="text-xs text-red-500 font-mono">
                {sKeyError}
              </span>
            )}
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 mt-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
              onClick={verifyKey}
            >
              Go to app
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verify;
