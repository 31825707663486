/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import TokenActivity from 'Components/Defi/Atoms/tokenActivity';
import TokenMarket from 'Components/Defi/Atoms/tokenMarket';
import TokenName from 'Components/Defi/Atoms/tokenName';
import TokenRanking from 'Components/Defi/Atoms/tokenRanking';

export default function useHeaders() {
  const column = getColumns();
  return [column];
}

function getColumns() {
  const column = [
    {
      Header: '#',
      id: 'row',
      accessor: 'ranking',
      Cell: (cell) => <TokenRanking cell={cell} />,
    },
    {
      Header: 'Token Name',
      accessor: 'name',
      Cell: (cell) => <TokenName cell={cell} showLogo={true} />,
    },
    {
      Header: 'Volume USD',
      Cell: (cell) => (
        <div className="">
          <TokenActivity
            cell={cell}
            kind="volume_usd"
            action_group="top_trades"
          />
        </div>
      ),
    },
    {
      Header: 'Wallet Count',
      Cell: (cell) => (
        <div className="">
          <TokenActivity
            cell={cell}
            kind="wallet_count"
            action_group="top_trades"
          />
        </div>
      ),
    },
    {
      Header: 'Tx Count',
      Cell: (cell) => (
        <div className="">
          <TokenActivity
            cell={cell}
            kind="tx_count"
            action_group="top_trades"
          />
        </div>
      ),
    },
    {
      Header: 'Tx Per Wallet',
      Cell: (cell) => (
        <div className="">
          <TokenActivity
            cell={cell}
            kind="tx_per_wallet"
            action_group="top_trades"
          />
        </div>
      ),
    },
    {
      Header: 'Market',
      accessor: 'cg_id',
      Cell: (cell) => <TokenMarket cell={cell} />,
    },
  ];
  return column;
}
