/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import TokenExplorerDM from './appIcons/token_explorer-dm';
import TokenExplorerLM from './appIcons/token_explorer-lm';
import { TokenExplorerIconDark, TokenExplorerIconLight } from 'Utilities/icons';

function TokenExplorerIcon() {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div className="-ml-2">
      {theme === 'dark' ? (
        <img src={TokenExplorerIconDark} className="h-10 w-10" />
      ) : (
        <img src={TokenExplorerIconLight} className="h-10 w-10" />
      )}
    </div>
  );
}

export default TokenExplorerIcon;
