/* eslint-disable react/prop-types */
import { Popover, Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import thermometer from 'Assets/Images/thermometer.png';
import React, { Fragment, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import ExpandArrowIcon from 'Utilities/Icons/expandArrow';
import CorrelationInverse from './Molecules/correlationInverse';
import Sentiment from './Molecules/sentiment';
import TopIndicators from './Molecules/topIndicators';
import TradingSession from './Molecules/tradingSession';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MacroTop({ fng }) {
  const location = useLocation();
  let heatStyle = '';

  if (fng) {
    if (fng.value >= 0 && fng.value <= 25) {
      heatStyle = 'bg-gradient-to-r from-red-700 to-yellow-500';
    }
    if (fng.value > 25 && fng.value <= 50) {
      heatStyle = 'bg-gradient-to-r from-yellow-400 to-yellow-600';
    }
    if (fng.value > 50 && fng.value <= 75) {
      heatStyle = 'bg-gradient-to-r from-yellow-600 to-green-500';
    }
    if (fng.value > 75 && fng.value <= 100) {
      heatStyle = 'bg-gradient-to-r from-green-500 to-green-600';
    }
  }

  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                open ? 'text-gray-900' : 'text-gray-500',
                'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900'
              )}
              onClick={() => (open ? close() : { open })}
            >
              <img
                src={thermometer}
                className={`mt-2 ${heatStyle} rounded-full py-1 mx-1`}
                width="20"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-10 z-10 transform -translate-x-3/4 mt-4 px-2 w-screen max-w-3xl sm:px-0 bg-white dark:bg-dark-themeBgDark border dark:border-gray-700 rounded">
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden sm:overflow-y-scroll sm:overflow-x-auto h-102 metric-scroll">
                  <div className="flex justify-between m-3">
                    <div className="px-2 text-2xl dark:text-white font-semibold">
                      Macro
                    </div>
                    <Popover.Panel>
                      {({ close }) => (
                        <>
                          {location.pathname != '/app/home/welcome/macro' && (
                            <div>
                              <Tippy
                                content={<div className="text-xs">Expand</div>}
                                placement="left"
                              >
                                <a
                                  href="/app/home/welcome/macro"
                                  className=""
                                  onClick={() => close()}
                                >
                                  <ExpandArrowIcon />
                                </a>
                              </Tippy>
                            </div>
                          )}
                        </>
                      )}
                    </Popover.Panel>
                  </div>

                  <TopIndicators />
                  <Sentiment fng={fng} />
                  <CorrelationInverse />
                  <TradingSession />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
