/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import { getGamingData } from 'statemgmt/actions';
import { column } from './header';
import { RefreshIcon } from '@heroicons/react/outline';
import { Refresh, Table } from 'Utilities';

function GamingTable() {
  const [refresh, setRefresh] = useState(false);
  // useDispatch() instance
  const dispatch = useDispatch();
  const processgamingData = (gaming) => {
    let gamingDataSet = [];
    if (gaming.data && gaming.data.length > 0) {
      for (let key of gaming.data) {
        gamingDataSet.push({
          name: key['f'][0]['v'],
          logo_url: key['f'][1]['v'],
          block_chain: key['f'][2]['v'],
          balance: key['f'][3]['v'],
          users: key['f'][4]['v'],
          users_change_percentage: key['f'][5]['v'],
        });
      }
    }
    return gamingDataSet;
  };
  // listening to store changes
  const { gaming, loading } = useSelector((s = initialState) => {
    return {
      gaming: processgamingData(s.gamingData.gaming),
      loading: s.gamingData.loading,
    };
  });
  // useeffect hook start
  useEffect(() => {
    dispatch(getGamingData());
  }, [refresh]);
  // useeffect hook end

  return (
    <>
      <div className="dark:bg-dark-tabPillBgSelectedDark bg-light-tabPillBgSelectedLight p-4 rounded-tr-md">
        <div className="hidden sm:block">
          <div className="flow-root ">
            <div className="flex justify-between">
              <div className="flex flex-wrap space-x-1 mb-2 lg:mt-2">
                <div className="">
                  <h3 className="text-2xl leading-6 font-semibold text-gray-900 dark:text-dark-textPrimary">
                    Gaming
                  </h3>
                </div>
              </div>
              <div className="mx-2">
                <div className="flex justify-between ">
                  <Refresh
                    state={refresh}
                    setState={setRefresh}
                    loadingbutton={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="min-w-full leading-normal divide-y divide-gray-200 ">
        <Table data={gaming} col={column} isloading={loading} />
      </div>
    </>
  );
}

export default GamingTable;
