import React from 'react';

// eslint-disable-next-line react/prop-types
function Search({ address, setAddress, getData, placeholder }) {
  return (
    <div>
      <div className="flex rounded-md">
        <div className="flex items-stretch  flex-grow lg:flex-grow-0 w-96 focus-within:z-10">
          <input
            type="text"
            value={address}
            className="border border-primary text-xs sm:text-md w-full transition px-4 h-10 rounded-l-md placeholder:italic placeholder:text-gray-400 focus:outline-none dark:text-dark-textPrimary dark:bg-dark-themeBgDark dark:border-gray-600"
            placeholder={placeholder}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </div>
        <button
          type="button"
          className="items-center px-3 py-2 border  shadow-sm text-sm leading-4 font-semibold rounded-r-md text-blue-700 bg-white hover:bg-gray-50 dark:text-dark-textPrimary dark:bg-dark-themeBgDark dark:border-gray-600"
          onClick={getData}
        >
          <span>Go</span>
        </button>
      </div>
    </div>
  );
}

export default Search;
