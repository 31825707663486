/* eslint-disable react/prop-types */
import { AlphaRequestIconDark, AlphaRequestIconLight } from 'Utilities/icons';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

function AlphaRequestIcon({ className }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return theme === 'dark' ? (
    <img src={AlphaRequestIconDark} className="w-8 h-8" />
  ) : (
    <img src={AlphaRequestIconLight} className="w-8 h-8" />
  );
}

export default AlphaRequestIcon;
