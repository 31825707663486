// import { PlayIcon } from '@heroicons/react/outline';
import { PlayIcon } from '@heroicons/react/solid';
import VideoPlayer from 'Utilities/VideoJs';
import {
  ClusterConfigThump,
  DataPlatformFlowDark,
  DataPlatformFlowLight,
} from 'Utilities/images';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { initialState } from 'statemgmt/initialState';
function Configuration() {
  const [showVideo, setShowVideo] = useState(false);
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <>
      <div className="mx-auto lg:px-12">
        <div className="flex flex-col lg:flex-row lg:space-x-2 px-2">
          <div className="lg:w-3/5">
            <h1 className="dark:text-white lg:text-xl py-3 font-semibold">
              Alphastack Platforms - User Cluster
            </h1>
            <p className="dark:text-dark-textPrimary text-xs lg:text-sm text text-justify lg:w-4/5">
              Create and configure your secure cluster. With a cluster you will
              be able to use advanced features like cross-platform exploration
              of data where you can combine alphastack-provisioned blockchain
              data and all your data sources (on prem and in the cloud) as if
              they all live in a single database instance.
            </p>
            <NavLink
              to="/app/platforms/configuration/user-clusters"
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-blue-600 mt-4 px-4 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Go to clusters page
            </NavLink>
            <div className="sm:my-10">
              <h1 className="dark:text-white lg:text-lg py-6 lg:py-3 text-center lg:text-left">
                Platform High-level Architecture
              </h1>
              <div className="mx-auto">
                <img
                  className="w-9/12 h-1/2"
                  src={
                    theme === 'dark'
                      ? DataPlatformFlowDark
                      : DataPlatformFlowLight
                  }
                />
              </div>
            </div>
          </div>
          <div className="mt-10 lg:w-2/5">
            <h2 className="dark:text-white py-3 text-center lg:text-left">
              How to - Video
            </h2>
            <div className="w-full">
              {/* {showVideo ? (
                <div>
                  <iframe
                    height="500"
                    className="aspect-video w-full"
                    src="https://www.youtube.com/embed/KpNhqx2pEZI?controls=0&amp;start=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <div
                  className="relative h-full cursor-pointer group"
                  onClick={() => setShowVideo(true)}
                >
                  <div className="text-center absolute top-1/2  transform -translate-y-1/2 -translate-x-1/2 m-auto left-1/2  rounded-full">
                    <div className="flex justify-center items-center relative bg-white rounded-full w-12 h-12 lg:w-16 lg:h-16 delay-300">
                      <div className="absolute transform ease-in delay-300">
                        <svg
                          // width="30"
                          // height="32"
                          className="w-4 h-6 lg:w-6 lg:h-8"
                          viewBox="0 0 14 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0)">
                            <path
                              d="M13.1362 6.54164L2.24095 0.201094C1.35571 -0.313828 0 0.185859 0 1.45945V14.1375C0 15.2801 1.25976 15.9687 2.24095 15.3959L13.1362 9.05836C14.1081 8.49469 14.1112 7.10531 13.1362 6.54164V6.54164Z"
                              fill="#FF6B4A"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect
                                width="13.8667"
                                height="15.6"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="absolute group-hover:opacity-0">
                        <svg
                          // width="30"
                          // height="32"
                          className="w-4 h-6 lg:w-6 lg:h-8"
                          viewBox="0 0 14 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0)">
                            <path
                              d="M13.1362 6.54164L2.24095 0.201094C1.35571 -0.313828 0 0.185859 0 1.45945V14.1375C0 15.2801 1.25976 15.9687 2.24095 15.3959L13.1362 9.05836C14.1081 8.49469 14.1112 7.10531 13.1362 6.54164Z"
                              fill="#4444CF"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="14" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <img
                    src={ClusterConfigThump}
                    className="h-full rounded-xl"
                    loading="lazy"
                  />
                </div>
              )} */}
            </div>
            <VideoPlayer
              url="https://www.youtube.com/watch?v=KpNhqx2pEZI"
              coverImage={ClusterConfigThump}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Configuration;
