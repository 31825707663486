import { COIN_INFO_PLATFORM } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import {
  BSScanLogo,
  CoinMC,
  CoingeckoLogo,
  DexScreenerLogo,
  EtherscanLogo,
  PolygonScanLogo,
  SolanaLogo,
  TokenSnifferLogo,
} from 'Utilities/images';
import { PropTypes } from 'prop-types';

PublicLinks.propTypes = {
  token_address: PropTypes.string,
  slug: PropTypes.string,
  provider: PropTypes.string,
};

const LINK_PROVIDER = {
  ethereum: {
    scan: 'https://etherscan.io/token/',
    ts: 'https://tokensniffer.com/token/eth/',
    ds: 'https://dexscreener.com/ethereum/',
    dt: 'https://dextools.io/app/en/ether/pair-explorer/0xc08e7911ee27169967ed8ec252fee73048cc0dff',
  },
  polygon: {
    scan: 'https://polygonscan.com/token/',
    ts: 'https://tokensniffer.com/token/poly/',
    ds: 'https://dexscreener.com/polygon/',
    dt: 'https://www.dextools.io/app/en/polygon/pair-explorer/',
  },
  arbitrum: {
    scan: 'https://arbiscan.com/token/',
    ts: 'https://tokensniffer.com/token/arb/',
    ds: 'https://dexscreener.com/arbitrum/',
    dt: 'https://www.dextools.io/app/en/arbitrum/pair-explorer/',
  },
  binance: {
    scan: 'https://bscscan.com/token/',
    ts: 'https://tokensniffer.com/token/bsc/',
    ds: 'https://dexscreener.com/bsc/',
    dt: '',
  },
  solana: {
    scan: 'https://solscan.io/token/',
    ts: 'https://tokensniffer.com/token/sol/',
    ds: 'https://dexscreener.com/solana/',
    dt: '',
  },
};

const LOGO_PROVIDER = {
  ethereum: EtherscanLogo,
  polygon: PolygonScanLogo,
  binance: BSScanLogo,
  solana: SolanaLogo,
};
function PublicLinks({ token_address, slug, provider }) {
  const chain = getUrlParam('chain');
  const redirect_url = `${COIN_INFO_PLATFORM[provider]}/${slug}`;

  const renderMarket = () => {
    if (slug === undefined || slug === null || slug === '') {
      return null;
    } else
      return (
        <div className="h-5 w-5 rounded-full border-2 border-white dark:border-gray-600">
          <a href={redirect_url} target="_blnk">
            <img
              src={provider === 'cg' ? CoingeckoLogo : CoinMC}
              className="rounded-full"
            />
          </a>
        </div>
      );
  };

  return (
    <div className="flex items-center space-x-2">
      <div className="h-5 w-5 rounded-full border-2 border-white dark:border-gray-600">
        <a
          href={`${LINK_PROVIDER[chain]['scan']}${token_address}`}
          target="_blnk"
        >
          <img src={LOGO_PROVIDER[chain]} className="rounded-full" />
        </a>
      </div>
      <div className="h-5 w-5 rounded-full border-2 border-white dark:border-gray-600">
        <a
          href={`${LINK_PROVIDER[chain]['ts']}${token_address}`}
          target="_blnk"
        >
          <img src={TokenSnifferLogo} className="rounded-full" />
        </a>
      </div>
      {renderMarket()}

      {/* <div className="h-5 w-5 rounded-full border-2 border-white dark:border-gray-600">
        <img src={CoinMC} className="rounded-full" />
      </div> */}

      <div className="h-5 w-5 rounded-full border-2 border-white dark:border-gray-600">
        <a
          href={`${LINK_PROVIDER[chain]['ds']}${token_address}`}
          target="_blnk"
        >
          <img src={DexScreenerLogo} className="rounded-full" />
        </a>
      </div>
      {/* <div className="h-5 w-5 rounded-full border-2 border-white dark:border-gray-600">
        <a
          href={`${LINK_PROVIDER[chain]['dt']}${token_address}`}
          target="_blnk"
        >
          <img src={TwitterLogo} className="rounded-full" />
        </a>
      </div> */}
    </div>
  );
}
export default PublicLinks;
