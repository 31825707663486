import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EarlyHolders from './EarlyHolders/earlyHolders';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { ComingSoon } from 'Utilities';

const WALLET_PAGES = {
  'early-holders': <EarlyHolders />,
  'buyers-timeframe': <ComingSoon />,
  'sellers-timeframe': <ComingSoon />,
  'flippers-timeframe': <ComingSoon />,
};
function BuilderPages() {
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <div>
      <ArrowLeftIcon
        className="cursor-pointer w-5 text-black dark:text-gray-400 mx-2 mb-4"
        onClick={() => navigate(-1)}
      />
      {WALLET_PAGES[id]}
    </div>
  );
}

export default BuilderPages;
