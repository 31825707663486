/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/prop-types */
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  CurrencyDollarIcon,
  FlagIcon,
  LightningBoltIcon as LightningBoltIconOutline,
  LogoutIcon,
  MenuAlt1Icon,
  MenuAlt2Icon,
  SearchIcon,
  UserCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import { LightningBoltIcon as LightningBoltIconSolid } from '@heroicons/react/solid';
import { useAuth } from 'Authentication/authProvider';
import MacroTop from 'Components/Home/Macro/MacroTop/macro-top';
import { getFngData } from 'Helper/services';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { ThemeToggle } from 'Utilities';
import { LogoDark, LogoLight } from 'Utilities/images';
import { PropTypes } from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { walletAuthentication } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import thermometer from '../../Assets/Images/thermometer.png';
import MenuIcon from './Atoms/menuIcon';
import NavGroupTitle from './Atoms/navGroupTitle';
import TokenExplorer from './Atoms/tokenExplorer';
import { earnToken, navigation } from './Helper/constants';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

Navbar.propTypes = {
  isLeaksSliderOver: PropTypes.bool,
  isWalletSliderOver: PropTypes.bool,
  isLeaksSliderOverz: PropTypes.bool,
  isWalletSliderOverz: PropTypes.bool,
  openSliderOver: PropTypes.bool,
  setIsLeaksSliderOver: PropTypes.func,
  setIsWalletSliderOver: PropTypes.func,
  setOpenSliderOver: PropTypes.func,
};
function Navbar({
  setIsLeaksSliderOver,
  setIsWalletSliderOver,
  setOpenSliderOver,
}) {
  const [fng, setFng] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let heatStyle = '';

  const getFngD = () => {
    getFngData()
      .then((res) => {
        setFng(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (fng) {
    if (fng.value >= 0 && fng.value <= 25) {
      heatStyle = 'bg-gradient-to-r from-red-700 to-yellow-500';
    }
    if (fng.value > 25 && fng.value <= 50) {
      heatStyle = 'bg-gradient-to-r from-yellow-400 to-yellow-600';
    }
    if (fng.value > 50 && fng.value <= 75) {
      heatStyle = 'bg-gradient-to-r from-yellow-600 to-green-500';
    }
    if (fng.value > 75 && fng.value <= 100) {
      heatStyle = 'bg-gradient-to-r from-green-500 to-green-600';
    }
  }

  const routeToProfile = () => {
    navigate('/app/profile/my-alpha-leads');
  };

  const auth = useAuth();

  const logout = async () => {
    await auth.logout();
  };

  const disconnectWallet = () => {
    logout();
    dispatch(walletAuthentication(false, ''));
    sessionStorage.removeItem('skValidated');
    localStorage.removeItem('verified_user');
  };
  const userNavigation = [
    { name: 'My Profile', icon: FlagIcon, method: routeToProfile },
    { name: 'Sign out', icon: LogoutIcon, method: disconnectWallet },
  ];

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  useEffect(() => {
    getFngD();
  }, []);

  const { user_address } = useGetCurrentUser();

  const styles = {
    groupItemStyle: `flex-1 font-semibold text-xs capitalize pl-1  ${
      theme === 'dark' ? 'text-white' : ''
    }`,
    borderBottom: theme === 'dark' && 'border-gray-700',
    disclosureButton: `${
      theme === 'dark'
        ? 'hover:bg-gray-700 hover:text-blue-400 text-white'
        : 'hover:bg-blue-50 hover:text-blue-700'
    } group w-full flex items-center px-1 py-3 rounded-full`,
    activeGroup: `${
      theme === 'dark' ? 'bg-gray-700 text-white' : 'text-blue-700 bg-blue-50'
    }`,
  };

  const renderHomeMenu = () => {
    return (
      <div className="">
        <Disclosure
          as="div"
          className="space-y- cursor-pointer  px-1"
          defaultOpen={
            location?.pathname.split('/')[2] === 'home' ? true : false
          }
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                as="div"
                className={classNames(
                  styles.disclosureButton,
                  location?.pathname.split('/')[2] === 'home' &&
                    styles.activeGroup
                )}
              >
                <NavGroupTitle groupName="Home" open={open} theme={theme} />
              </Disclosure.Button>
              <Disclosure.Panel className="space-y-1">
                {navigation[0]?.basic.map((item, key) => (
                  <div key={key} className="">
                    <Disclosure
                      as="div"
                      key={item.name}
                      className="space-y-1 cursor-pointer"
                      defaultOpen={
                        item?.slug == location?.pathname.split('/')[3]
                          ? true
                          : false
                      }
                    >
                      {() => (
                        <>
                          <Disclosure.Button
                            as="div"
                            className={`group w-full flex items-center pl-6 pr-1 py-3 text-left text-xs font-medium rounded-md  ${
                              theme === 'dark'
                                ? 'text-gray-300'
                                : 'text-gray-600'
                            }`}
                          >
                            <div className="w-full flex items-center space-x-2">
                              <MenuIcon
                                slug={item?.slug}
                                theme={theme}
                                isActive={
                                  item?.slug == location?.pathname.split('/')[3]
                                    ? 'active'
                                    : 'in_active'
                                }
                              />
                              <span className="flex-1">{item.name}</span>
                            </div>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1 pl-12 pb-2">
                            <div className="h-full">
                              {item.children.map((subItem) => (
                                <NavLink
                                  key={subItem.name}
                                  to={subItem.href}
                                  className={({ isActive }) =>
                                    `group flex space-x-2 w-full items-center  text-xs -ml-1  py-2  ${
                                      isActive
                                        ? ' text-dark-menuItem font-semibold'
                                        : `${
                                            theme === 'dark' && 'text-gray-300'
                                          }`
                                    } ${
                                      subItem?.slug ==
                                        location?.pathname.split('/')[4] &&
                                      'rounded dark:text-600 text-dark-menuItem font-semibold'
                                    }`
                                  }
                                  onClick={() => {
                                    setUuid(uuid + 1);
                                    setIsOpen(false);
                                  }}
                                >
                                  {subItem?.slug ==
                                  location?.pathname.split('/')[4] ? (
                                    <LightningBoltIconSolid className="w-4" />
                                  ) : (
                                    <LightningBoltIconOutline className="w-4 text-gray-500" />
                                  )}
                                  <span>{subItem.name}</span>
                                </NavLink>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  };

  const renderSocialMenu = () => {
    return (
      <div className="">
        <Disclosure
          as="div"
          className="space-y-1 cursor-pointer  px-1"
          defaultOpen={
            location?.pathname.split('/')[2] === 'social' ? true : false
          }
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                as="div"
                className={classNames(
                  styles.disclosureButton,
                  location?.pathname.split('/')[2] === 'social' &&
                    styles.activeGroup
                )}
              >
                <NavGroupTitle groupName="Social" open={open} theme={theme} />
              </Disclosure.Button>
              <Disclosure.Panel className="space-y-1">
                {navigation[1]?.social.map((item, key) => (
                  <div key={key} className="">
                    <Disclosure
                      as="div"
                      key={item.name}
                      className="space-y-1 cursor-pointer"
                      defaultOpen={
                        item?.slug == location?.pathname.split('/')[3]
                          ? true
                          : false
                      }
                    >
                      {() => (
                        <>
                          <Disclosure.Button
                            as="div"
                            className={`group w-full flex items-center pl-6 pr-1 py-3 text-left text-xs font-medium rounded-md  ${
                              theme === 'dark'
                                ? 'text-gray-300'
                                : 'text-gray-600'
                            }`}
                          >
                            <div
                              // to="/community-alpha"
                              className="w-full flex items-center space-x-2"
                            >
                              <MenuIcon
                                slug={item.slug}
                                theme={theme}
                                isActive={
                                  item?.slug == location?.pathname.split('/')[3]
                                    ? 'active'
                                    : 'in_active'
                                }
                              />
                              <span className="flex-1">{item.name}</span>
                            </div>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1 pl-12 pb-2">
                            <div className="h-full">
                              {item.children.map((subItem) => (
                                <NavLink
                                  key={subItem.name}
                                  to={subItem.href}
                                  className={({ isActive }) =>
                                    `group flex space-x-2 w-full items-center  text-xs -ml-1  py-2  ${
                                      isActive
                                        ? ' text-dark-menuItem font-semibold'
                                        : `${
                                            theme === 'dark' && 'text-gray-300'
                                          }`
                                    } ${
                                      subItem?.slug ==
                                        location?.pathname.split('/')[4] &&
                                      'rounded dark:text-600 font-semibold'
                                    }`
                                  }
                                  onClick={() => {
                                    setUuid(uuid + 1);
                                    setIsOpen(false);
                                  }}
                                >
                                  {subItem?.slug ==
                                  location?.pathname.split('/')[4] ? (
                                    <LightningBoltIconSolid className="w-4" />
                                  ) : (
                                    <LightningBoltIconOutline className="w-4 text-gray-500" />
                                  )}
                                  <span>{subItem.name}</span>
                                </NavLink>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  };

  const renderPrebuiltMenu = () => {
    return (
      <div>
        <Disclosure
          as="div"
          className="space-y-1 cursor-pointer  px-1"
          defaultOpen={
            location?.pathname.split('/')[2] === 'prebuilt' ? true : false
          }
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                as="div"
                className={classNames(
                  styles.disclosureButton,
                  location?.pathname.split('/')[2] === 'prebuilt' &&
                    styles.activeGroup
                )}
              >
                <NavGroupTitle groupName="Prebuilt" open={open} theme={theme} />
              </Disclosure.Button>
              <Disclosure.Panel className="space-y-1">
                {navigation[2].prebuilt.map((item, key) => (
                  <div key={key} className="">
                    <Disclosure
                      as="div"
                      key={item.name}
                      className="space-y-1 cursor-pointer"
                      defaultOpen={
                        item?.slug == location?.pathname.split('/')[3]
                          ? true
                          : false
                      }
                    >
                      {() => (
                        <>
                          <Disclosure.Button
                            as="div"
                            className={`group w-full flex items-center pl-6 pr-1 py-3 text-left text-xs font-medium rounded-md  ${
                              theme === 'dark'
                                ? 'text-gray-300'
                                : 'text-gray-600'
                            }`}
                          >
                            <div className="w-full flex items-center space-x-2">
                              <MenuIcon
                                slug={item.slug}
                                theme={theme}
                                isActive={
                                  item?.slug == location?.pathname.split('/')[3]
                                    ? 'active'
                                    : 'in_active'
                                }
                              />
                              <span className="flex-1">{item.name}</span>
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className={classNames(
                                  open
                                    ? 'text-gray-400 rotate-90'
                                    : 'text-gray-300',
                                  'ml-3 h-3 w-4 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                              </svg> */}
                            </div>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1 pl-12 pb-2">
                            <div className="h-full">
                              {item.children.map((subItem) => (
                                <NavLink
                                  key={subItem.name}
                                  to={subItem.href}
                                  className={({ isActive }) =>
                                    `group flex space-x-2 w-full items-center  text-xs -ml-1  py-2  ${
                                      isActive
                                        ? ' text-dark-menuItem font-semibold'
                                        : `${
                                            theme === 'dark' && 'text-gray-300'
                                          }`
                                    } ${
                                      subItem?.slug ==
                                        location?.pathname.split('/app/')[1] &&
                                      'rounded dark:text-600 font-semibold'
                                    }`
                                  }
                                  onClick={() => {
                                    setUuid(uuid + 1);
                                    setIsOpen(false);
                                  }}
                                >
                                  {subItem?.href ==
                                  location?.pathname.split('/app/')[1] ? (
                                    <LightningBoltIconSolid className="w-4" />
                                  ) : (
                                    <LightningBoltIconOutline className="w-4 text-gray-500" />
                                  )}
                                  <span>{subItem.name}</span>
                                </NavLink>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  };

  const renderPlatformsMenu = () => {
    return (
      <div>
        <Disclosure
          as="div"
          className="space-y-1 cursor-pointer  px-1"
          defaultOpen={
            location?.pathname.split('/')[2] === 'platforms' ? true : false
          }
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                as="div"
                className={classNames(
                  styles.disclosureButton,
                  location?.pathname.split('/')[2] === 'platforms' &&
                    styles.activeGroup
                )}
              >
                <NavGroupTitle
                  groupName="Platforms"
                  open={open}
                  theme={theme}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="space-y-1">
                {navigation[3].platforms.map((item, key) => (
                  <div key={key} className="">
                    <Disclosure
                      as="div"
                      key={item.name}
                      className="space-y-1 cursor-pointer"
                      defaultOpen={
                        item?.slug == location?.pathname.split('/')[3]
                          ? true
                          : false
                      }
                    >
                      {() => (
                        <>
                          <Disclosure.Button
                            as="div"
                            className={`group w-full flex items-center pl-6 pr-1 py-3 text-left text-xs font-medium rounded-md  ${
                              theme === 'dark'
                                ? 'text-gray-300'
                                : 'text-gray-600'
                            }`}
                          >
                            <div className="w-full flex items-center justify-between">
                              <div
                                className={`flex items-center ${
                                  item.name === 'Configuration'
                                    ? 'space-x-2'
                                    : 'space-x-3'
                                }`}
                              >
                                <MenuIcon
                                  slug={item.slug}
                                  theme={theme}
                                  isActive={
                                    item?.slug ==
                                    location?.pathname.split('/')[3]
                                      ? 'active'
                                      : 'in_active'
                                  }
                                />
                                <span className="flex-1">{item.name}</span>
                              </div>
                            </div>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1 pl-12 pb-2">
                            <div className="h-full">
                              {item.children.map((subItem) => (
                                <NavLink
                                  key={subItem.name}
                                  to={subItem.href}
                                  end
                                  className={({ isActive }) =>
                                    `group flex space-x-2 w-full items-center  text-xs -ml-1  py-2  ${
                                      isActive
                                        ? ' text-dark-menuItem font-semibold'
                                        : `${
                                            theme === 'dark' && 'text-gray-300'
                                          }`
                                    } ${
                                      subItem?.href ==
                                        location?.pathname.split('/app/')[4] &&
                                      'rounded dark:text-600 font-semibold'
                                    }`
                                  }
                                  onClick={() => {
                                    setUuid(uuid + 1);
                                    setIsOpen(false);
                                  }}
                                >
                                  {subItem?.href ==
                                  location?.pathname.split('/app/')[1] ? (
                                    <LightningBoltIconSolid className="w-4" />
                                  ) : (
                                    <LightningBoltIconOutline className="w-4 text-gray-500" />
                                  )}
                                  <span>{subItem.name}</span>
                                </NavLink>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    );
  };

  const renderIntegrationMenu = () => {
    return (
      <>
        <div>
          <Disclosure
            as="div"
            className="space-y-1 cursor-pointer px-1"
            defaultOpen={
              location?.pathname.split('/')[2] === 'integrations' ? true : false
            }
          >
            {({ open }) => (
              <>
                <Disclosure.Button
                  as="div"
                  className={classNames(
                    styles.disclosureButton,
                    location?.pathname.split('/')[2] === 'integrations' &&
                      styles.activeGroup
                  )}
                >
                  <NavGroupTitle
                    groupName="Integrations"
                    open={open}
                    theme={theme}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="space-y-1">
                  {navigation[4].integrations.map((item, key) => (
                    <div key={key} className="">
                      <Disclosure
                        as="div"
                        key={item.name}
                        className="space-y-1 cursor-pointer"
                        defaultOpen={
                          item?.slug == location?.pathname.split('/')[3]
                            ? true
                            : false
                        }
                      >
                        {() => (
                          <>
                            <Disclosure.Button
                              as="div"
                              className={`group w-full flex items-center pl-6 pr-1 py-3 text-left text-xs font-medium rounded-md  ${
                                theme === 'dark'
                                  ? 'text-gray-300'
                                  : 'text-gray-600'
                              }`}
                            >
                              <div className="w-full flex items-center space-x-2">
                                <MenuIcon
                                  slug={item.slug}
                                  theme={theme}
                                  isActive={
                                    item?.slug ==
                                    location?.pathname.split('/')[3]
                                      ? 'active'
                                      : 'in_active'
                                  }
                                />
                                <span className="">{item.name}</span>
                              </div>
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1 pl-12 pb-2">
                              <ul className="h-full">
                                {item.children.map((subItem, key) => (
                                  <li key={key} className="relative">
                                    <NavLink
                                      to={subItem.href}
                                      end
                                      className={({ isActive }) =>
                                        `group flex space-x-2 w-full items-center  text-xs -ml-1  py-2  ${
                                          isActive
                                            ? ' text-dark-menuItem font-semibold'
                                            : `${
                                                theme === 'dark' &&
                                                'text-gray-300'
                                              }`
                                        } ${
                                          subItem?.href ==
                                            location?.pathname.split(
                                              '/app/'
                                            )[1] &&
                                          'rounded dark:text-600 font-semibold'
                                        }`
                                      }
                                      onClick={() => {
                                        setUuid(uuid + 1);
                                        setIsOpen(false);
                                      }}
                                    >
                                      {subItem?.href ==
                                      location?.pathname.split('/app/')[1] ? (
                                        <LightningBoltIconSolid className="w-4" />
                                      ) : (
                                        <LightningBoltIconOutline className="w-4 text-gray-500" />
                                      )}
                                      <span>{subItem.name}</span>
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </>
    );
  };

  return (
    <div className="fixed top-0 inset-x-0 z-10">
      <Disclosure
        as="nav"
        className="flex-shrink-0 bg-light-themeSecondary dark:bg-dark-themePrimary border-b dark:border-gray-700"
      >
        {() => (
          <>
            <div className="mx-auto  px-2 sm:px-4 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                {/* Logo section */}
                <div className=" items-center hidden sm:flex lg:px-0 xl:w-64">
                  <div className="flex-shrink-0">
                    <NavLink to="/">
                      <img
                        className="h-10 w-auto"
                        src={theme == 'dark' ? LogoDark : LogoLight}
                        alt="ALPHASTACK"
                      />
                    </NavLink>
                  </div>
                </div>

                <div className="flex sm:hidden">
                  {/* Mobile menu button */}
                  <MenuAlt2Icon
                    className="block h-6 w-6 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                    onClick={() => setIsOpen(true)}
                  />
                </div>

                {/* Links section */}
                <div className="">
                  {/* macro icons */}
                  <div className="flex items-center ">
                    <div className="mr-2 hidden sm:block">
                      <TokenExplorer />
                    </div>
                    <div className="mr-2 hidden sm:block">
                      <MacroTop fng={fng} />
                    </div>
                    <div className="mr-2 block sm:hidden">
                      <NavLink
                        to="/app/prebuilt/on-chain-analytics/token"
                        className="bg-gray-300 rounded-full"
                      >
                        <SearchIcon className="w-4 text-black dark:text-white" />
                      </NavLink>
                    </div>
                    <div className="mr-2 block sm:hidden">
                      <NavLink to="/app/home/welcome/macro">
                        <img
                          src={thermometer}
                          className={`${heatStyle} rounded-full py-1 mx-1`}
                          width="16"
                        />
                      </NavLink>
                    </div>
                    {/* earn token button  */}
                    <div className="sm:mr-2 flex">
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs bg-blue-700 pt-2 pr-3 pb-2 pl-3 items-center rounded-md text-white font-semibold text-xs hidden sm:flex">
                            Earn Tokens
                            <ChevronDownIcon className="w-4 h-4" />
                          </Menu.Button>
                          <Menu.Button className="max-w-xs bg-blue-700 pt-1 pr-2 pb-1 pl-2 items-center rounded-md text-white font-semibold text-xs flex sm:hidden">
                            <CurrencyDollarIcon className="w-4 h-4" />
                            <ChevronDownIcon className="w-3 h-3" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-dark-themeBgDark border dark:border-gray-700">
                            {earnToken.map((item) => (
                              <Menu.Item key={item.name}>
                                {() => (
                                  <p
                                    onClick={() => {
                                      setOpenSliderOver(item.initiator);
                                      item.initiator === '0'
                                        ? setIsLeaksSliderOver(true)
                                        : item.initiator === '1'
                                        ? setIsWalletSliderOver(true)
                                        : null;
                                    }}
                                    className="block px-4 py-2 text-xs font-semibold text-gray-700 cursor-pointer dark:text-dark-textPrimary dark:border-gray-700"
                                  >
                                    {item.name}
                                  </p>
                                )}
                              </Menu.Item>
                            ))}
                            <Menu.Item className="block px-4 py-2 text-xs font-semibold text-gray-700 cursor-pointer dark:text-dark-textPrimary dark:border-gray-700">
                              <NavLink to="/app/social/community-alpha/alpha-request">
                                Answer Alpha-Request
                              </NavLink>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    {/* Profile dropdown */}
                    <div className="md:mr-2 flex sm:hidden">
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="p-2 max-w-md flex items-center text-sm rounded-md font-semibold">
                            <span className="sr-only">Open user menu</span>
                            <UserCircleIcon className="h-5 w-5 antialiased text-gray-500 dark:text-dark-textPrimary" />
                            <ChevronDownIcon className="w-4 h-4 text-gray-500 dark:text-dark-textPrimary" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-dark-themeBgDark">
                            <Menu.Item>
                              <div
                                className={
                                  'flex px-2 py-2 text-xs font-semibold text-gray-700 dark:text-dark-textPrimary border-b dark:border-gray-700 border-gray-400'
                                }
                              >
                                {user_address
                                  ? user_address.substring(0, 10) +
                                    '...' +
                                    user_address.substring(
                                      user_address.length - 10
                                    )
                                  : ''}
                              </div>
                            </Menu.Item>
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <div
                                    to={item.href}
                                    className={classNames(
                                      active ? 'bg-light-bgPrimary' : '',
                                      'cursor-pointer flex px-2 py-2 text-xs font-semibold text-gray-700 dark:text-dark-textPrimary'
                                    )}
                                    onClick={() => {
                                      item.method();
                                    }}
                                  >
                                    <item.icon
                                      className={`mr-4 flex-shrink-0 h-4 w-4 text-gray-700 dark:text-dark-textPrimary dark:border border-gray-300 ${
                                        item.icon === MenuAlt1Icon
                                          ? 'transform -rotate-90'
                                          : null
                                      }`}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>

                    <div className="hidden md:block">
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="p-2 max-w-md flex items-center text-sm rounded-md font-semibold dark:text-dark-textPrimary">
                            <UserCircleIcon className="h-4 w-4 antialiased text-gray-500 dark:text-dark-textPrimary" />
                            <span className="ml-1 text-gray-500 dark:text-dark-textPrimary text-xs xl:text-md">
                              {user_address
                                ? user_address.substring(0, 6) +
                                  '...' +
                                  user_address.substring(
                                    user_address.length - 4
                                  )
                                : ''}
                            </span>
                            <ChevronDownIcon className="w-4 h-4 text-gray-500 dark:text-dark-textPrimary" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform -translate-y-2 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 border rounded-md shadow-lg py-1 bg-white dark:bg-dark-themeBgDark dark:border-gray-700">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <div
                                    to={item.href}
                                    className={classNames(
                                      active
                                        ? 'bg-light-bgPrimary dark:bg-dark-bgMutedDark'
                                        : '',
                                      'cursor-pointer flex px-2 py-2 text-xs font-semibold text-gray-700 dark:text-dark-textPrimary'
                                    )}
                                    onClick={() => {
                                      item.method();
                                    }}
                                  >
                                    <item.icon
                                      className={`mr-4 flex-shrink-0 h-4 w-4 text-gray-700 dark:text-dark-textPrimary dark:border border-gray-300 ${
                                        item.icon === MenuAlt1Icon
                                          ? 'transform -rotate-90'
                                          : null
                                      }`}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="sm:ml-2">
                      <ThemeToggle />
                    </div>
                  </div>
                </div>
              </div>

              <Transition.Root show={isOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="fixed inset-0 flex z-50 md:hidden bg-light-bgNuetral dark:bg-gray-800 overflow-y-auto"
                  onClose={setIsOpen}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                  </Transition.Child>
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <div
                      className={`relative flex flex-col max-w-xs w-full ${
                        theme === 'dark'
                          ? 'bg-dark-themePrimary'
                          : 'bg-light-bgNuetral'
                      }`}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute top-0 right-0 -mr-12 pt-2 cursor-pointer">
                          <button
                            type="button"
                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full"
                            onClick={() => setIsOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <XIcon
                              className="h-4 w-4 text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </Transition.Child>
                      <div className="border-b border-gray-700 mb-3">
                        <NavLink to="/" className="">
                          <img
                            src={
                              theme === 'dark' || theme === 'undefined'
                                ? LogoDark
                                : LogoLight
                            }
                            className="w-40 m-2"
                            aria-hidden="true"
                          />
                        </NavLink>
                      </div>

                      <nav
                        className={`${
                          theme === 'dark'
                            ? 'bg-dark-themePrimary'
                            : 'bg-light-themeSecondary'
                        } flex-1 space-y-1`}
                        aria-label="Sidebar"
                      >
                        {renderHomeMenu()}
                        {/* social  */}
                        {renderSocialMenu()}
                        {/* prebuilt  */}
                        {renderPrebuiltMenu()}
                        {/* platforms  */}
                        {renderPlatformsMenu()}
                        {/* integrations */}
                        {renderIntegrationMenu()}
                      </nav>
                    </div>
                  </Transition.Child>
                </Dialog>
              </Transition.Root>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
}

export default Navbar;
