import React from 'react';
import trophyDark from '../../Assets/Images/trophy-dark.png';
import trophyLight from '../../Assets/Images/trophy-light.png';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

type TrophyIconProps = {
  width: string;
  height: string;
};
export default function TrophyIcon({
  width,
  height,
}: TrophyIconProps): JSX.Element {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const iconStyle = `w-${width} h-${height}`;
  return (
    <div>
      <img
        src={theme == 'dark' ? trophyDark : trophyLight}
        className={`${iconStyle} dark:text-gray-100`}
      />
    </div>
  );
}
