import BuilderIcon from 'Utilities/Icons/BuilderIcon';
import { AlphaLeadsIconDark } from 'Utilities/icons';
import { AlphaLeadsIcon } from 'Utilities/images';

export const builderOptions = [
  {
    value: 'wallet',
    label: 'Wallet',
    children: [
      {
        value: 'early-holders',
        label: 'Early Holders',
        icon: BuilderIcon,
        desc: 'View the current trading activities of the earliest recipients of the selected token or tokens.',
        url: 'wallet/early-holders?chain=ethereum',
      },
      {
        value: 'buyers-timeframe',
        label: 'Buyers during timeframe',
        desc: 'View the current trading activities of traders who engaged with the selected token or tokens during your specified timeframe.',
        icon: BuilderIcon,
        url: 'wallet/buyers-timeframe',
      },
      {
        value: 'sellers-timeframe',
        label: 'Sellers during timeframe',
        desc: 'View the current trading activities of sellers for the selected token or tokens during your specified timeframe.',
        icon: BuilderIcon,
        url: 'wallet/sellers-timeframe',
      },
      {
        value: 'flippers-timeframe',
        label: 'Flippers during timeframe',
        desc: 'View the current trading activities of flippers of the selected token or tokens during your specified timeframe.',
        icon: BuilderIcon,
        url: 'wallet/flippers-timeframe',
      },
    ],
  },
  // {
  //   value: 'smart-contract-analysis',
  //   label: 'Smart Contract Analysis',
  // },
  // {
  //   value: 'dashboard',
  //   label: 'Dashboard',
  // },
];

export const earliestWalletOptions = [
  {
    text: 'Earliest 100 Wallets',
    value: '100',
  },
];

export const durationOptions = [
  { text: '1 Hour', value: '1hr' },
  { text: '6 Hours', value: '6hr' },
  { text: '12 Hours', value: '12hr' },
  { text: '24 Hours', value: '24hr' },
  { text: '1 Week', value: 'week' },
  { text: '1 Month', value: 'month' },
];

export const countOptions = [
  {
    text: 'Top 10 Tokens',
    value: '10',
  },
  {
    text: 'Top 20 Tokens',
    value: '20',
  },
  {
    text: 'Top 30 Tokens',
    value: '30',
  },
];

export const operationOptions = [
  {
    text: 'Bought',
    value: 'buy',
  },
  {
    text: 'Sold',
    value: 'sell',
  },
];

export const filterOptions = [
  {
    val: 'volume_usd',
    valLabel: 'Vol USD',
    sortCol: 'sortCol',
    setSortCol: 'setSortCol',
    tooltip: 'Fetch by most Volume (USD) ',
  },
  {
    val: 'volume',
    valLabel: 'Volume',
    sortCol: 'sortCol',
    setSortCol: 'setSortCol',
    tooltip: 'Fetch by most Volume (Tokens)',
  },
  {
    val: 'tx_count',
    valLabel: 'Tx Count',
    sortCol: 'sortCol',
    setSortCol: 'setSortCol',
    tooltip: 'Fetch by most Transactions Count',
  },
];
