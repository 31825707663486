/* eslint-disable react/prop-types */
import { SCAN_ADDRESS, SCAN_NFT, SCAN_TX } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import moment from 'moment';
import DexTradeActivity from 'Utilities/Badges/dexTradeActivity';
import Etherscan from 'Utilities/Etherscan/etherscan';

export const column = [
  {
    Header: 'Tx Hash',
    accessor: 'transaction_hash',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.transaction_hash}
        type={SCAN_TX}
        isSubString={true}
      />
    ),
    isSearchClicked: true,
    action: 'nftLatestTrades',
    Filter: '',
  },
  {
    Header: 'Token Name',
    accessor: 'name',
    Cell: (cell) => (
      <a
        className="mr-1 text-blue-500"
        href={`https://etherscan.io/token/${`${
          cell.data[cell.row.index].contract_address
        }`}`}
        target="_blank"
        rel="noreferrer"
      >
        {cell.row.values.name}
      </a>
    ),
    Filter: '',
  },
  {
    Header: 'Trader Address',
    accessor: 'trader_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.trader_address}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    Filter: '',
  },
  {
    Header: 'Token ID',
    accessor: 'token_id',
    Cell: (cell) => {
      let isSubString = true;
      let contract_address = cell.data[cell.row.index].contract_address;
      return (
        <a
          className="mr-1 text-blue-500"
          href={`https://etherscan.io/${SCAN_NFT}/${
            cell.data[cell.row.index].contract_address
          }/${cell.row.values.token_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {contract_address
            ? `${contract_address.substring(0, 6)} 
            ...
            ${contract_address.substring(contract_address.length - 4)} / ${
                cell.row.values.token_id.length > 4
                  ? `${cell.row.values.token_id.substring(
                      0,
                      3
                    )}...${cell.row.values.token_id.substring(
                      cell.row.values.token_id.length - 2
                    )}`
                  : cell.row.values.token_id
              }
             
            `
            : ''}
        </a>
      );
    },
    Filter: '',
  },
  {
    Header: 'Trade Action',
    accessor: 'trade_action',
    Cell: (cell) => (
      <DexTradeActivity activity={cell.row.values.trade_action} />
    ),
    Filter: '',
  },
  {
    Header: 'DEX',
    accessor: 'dex',
    Filter: '',
  },
  {
    Header: 'Trade Type',
    accessor: 'trade_type',
    Filter: '',
  },
  {
    Header: 'Token Amount',
    accessor: 'token_amount',
    Cell: (cell) => (
      <>
        {cell.row.values.trade_action === 'buy' ||
        cell.row.values.trade_action === 'inflow' ? (
          <p className="text-green-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        ) : (
          <p className="text-red-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        )}
      </>
    ),
    Filter: '',
  },
  {
    Header: 'Eth Value',
    accessor: 'eth_value',
    Cell: ({ cell }) => {
      let decimal_Value = cell.row.values.eth_value
        ? cell.row.values.eth_value.substring(0, 8)
        : '';
      return decimal_Value;
    },
    Filter: '',
  },
  {
    Header: 'Est. USD Val (Tx Date)',
    accessor: 'token_value_usd_txdate',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.row.values.trade_action === 'buy' ||
            cell.row.values.trade_action === 'inflow'
              ? 'text-green-500'
              : 'text-red-500'
          }`}
        >
          {cell.row.values.token_value_usd_txdate
            ? '$' +
              formatValue('format', cell.row.values.token_value_usd_txdate)
            : '-'}
        </p>
      </>
    ),
    Filter: '',
  },
  {
    Header: 'USD Value (Current)',
    accessor: 'token_value_usd',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.data[cell.row.index].using_ethvalue_current != 'x'
              ? cell.row.values.trade_action === 'buy' ||
                cell.row.values.trade_action === 'inflow'
                ? 'text-green-500'
                : 'text-red-500'
              : 'text-gray-500'
          }`}
          title={
            cell.data[cell.row.index].using_ethvalue_current === 'x'
              ? 'token price not found, showing eth value'
              : ''
          }
        >
          {cell.row.values.token_value_usd
            ? '$' + formatValue('format', cell.row.values.token_value_usd)
            : '-'}
        </p>
      </>
    ),
    Filter: '',
  },
  {
    Header: 'Time',
    accessor: 'timestamp_utc',
    Cell: ({ cell }) => {
      let dateTime = moment.utc(cell.row.values.timestamp_utc * 1000).fromNow();
      return dateTime;
    },
    Filter: '',
  },
];

export const popupColumn = [
  {
    Header: 'Tx Hash',
    accessor: 'transaction_hash',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.transaction_hash}
        type={SCAN_TX}
        isSubString={true}
      />
    ),
    action: 'nftLatestTrades',
    Filter: '',
  },
  {
    Header: 'Token Name',
    accessor: 'name',
    Cell: (cell) => (
      <a
        className="mr-1 text-blue-500"
        href={`https://etherscan.io/token/${`${
          cell.data[cell.row.index].contract_address
        }`}`}
        target="_blank"
        rel="noreferrer"
      >
        {cell.row.values.name}
      </a>
    ),
    Filter: '',
  },
  {
    Header: 'Trader Address',
    accessor: 'trader_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.trader_address}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    Filter: '',
  },
  {
    Header: 'Token ID',
    accessor: 'token_id',
    Cell: (cell) => {
      let isSubString = true;
      let contract_address = cell.data[cell.row.index].contract_address;
      return (
        <a
          className="mr-1 text-blue-500"
          href={`https://etherscan.io/${SCAN_NFT}/${
            cell.data[cell.row.index].contract_address
          }/${cell.row.values.token_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {contract_address
            ? `${contract_address.substring(0, 6)} 
            ...
            ${contract_address.substring(contract_address.length - 4)} / ${
                cell.row.values.token_id.length > 4
                  ? `${cell.row.values.token_id.substring(
                      0,
                      3
                    )}...${cell.row.values.token_id.substring(
                      cell.row.values.token_id.length - 2
                    )}`
                  : cell.row.values.token_id
              }
             
            `
            : ''}
        </a>
      );
    },
    Filter: '',
  },
  {
    Header: 'Trade Action',
    accessor: 'trade_action',
    Cell: (cell) => (
      <DexTradeActivity activity={cell.row.values.trade_action} />
    ),
  },
  {
    Header: 'DEX',
    accessor: 'dex',
    Filter: '',
  },
  {
    Header: 'Trade Type',
    accessor: 'trade_type',
    Filter: '',
  },
  {
    Header: 'Token Amount',
    accessor: 'token_amount',
    Cell: (cell) => (
      <>
        {cell.row.values.trade_action === 'buy' ||
        cell.row.values.trade_action === 'inflow' ? (
          <p className="text-green-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        ) : (
          <p className="text-red-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        )}
      </>
    ),
    Filter: '',
  },
  {
    Header: 'Eth Value',
    accessor: 'eth_value',
    Filter: '',
  },
  {
    Header: 'Est. USD Val (Tx Date)',
    accessor: 'token_value_usd_txdate',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.row.values.trade_action === 'buy' ||
            cell.row.values.trade_action === 'inflow'
              ? 'text-green-500'
              : 'text-red-500'
          }`}
        >
          {cell.row.values.token_value_usd_txdate
            ? '$' +
              formatValue('format', cell.row.values.token_value_usd_txdate)
            : '-'}
        </p>
      </>
    ),
    Filter: '',
  },
  {
    Header: 'USD Value (Current)',
    accessor: 'token_value_usd',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.data[cell.row.index].using_ethvalue_current != 'x'
              ? cell.row.values.trade_action === 'buy' ||
                cell.row.values.trade_action === 'inflow'
                ? 'text-green-500'
                : 'text-red-500'
              : 'text-gray-500'
          }`}
          title={
            cell.data[cell.row.index].using_ethvalue_current === 'x'
              ? 'token price not found, showing eth value'
              : ''
          }
        >
          {cell.row.values.token_value_usd
            ? '$' + formatValue('format', cell.row.values.token_value_usd)
            : '-'}
        </p>
      </>
    ),
    Filter: '',
  },
  {
    Header: 'Time',
    accessor: 'timestamp_utc',
    Cell: ({ cell }) => {
      let dateTime = moment.utc(cell.row.values.timestamp_utc * 1000).fromNow();
      return dateTime;
    },
    Filter: '',
  },
];
