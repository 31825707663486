const stars = [
  'Sirius',
  'Epsilon',
  'Wolf',
  'Vega',
  'Bellatrix',
  'Mirzam',
  'Polaris',
  'Alsephina',
  'Capella',
  'Antares',
  'Castor',
  'Arcturus',
  'Canopus',
  'Procyon',
  'Regulus',
  'Sargas',
  'Achernar',
];

const islands = [
  'Tranquil-Isle',
  'Pearl-Peninsula',
  'Whispering-Cove',
  'Coconut-Grove',
  'Secret-Shores',
  'Golden-Sands',
  'Diamond-Headland',
  'Turquoise-Bay',
  'Secluded-Shore',
  'Driftwood-Island',
  'The-Coral-Cove',
  'The-Majestic-Beach',
  'The-Seabird-Sanctuary',
  'Oceanic-Oasis',
  'Midnight-Island',
  'Sunflower-Shoals',
];
export const generateNickname = () => {
  const star = stars[Math.floor(Math.random() * stars.length)];
  const island = islands[Math.floor(Math.random() * islands.length)];

  const nickname = `c-${star.toLowerCase()}-${island.toLowerCase()}`;
  return nickname;
};
