/* eslint-disable react/prop-types */
import React from 'react';

export default function GradientProgressBar({ step }) {
  let dynamicWidth = 'calc(100% * ' + step / 100 + ')';
  return (
    <div>
      <div className="relative w-full">
        <div className="bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 mb-2 h-1 w-full rounded"></div>
        <span
          className="absolute box-content h-2 w-2 rounded-full border-4 border-solid border-white bg-blue-600 shadow -top-2"
          style={{ left: dynamicWidth }}
        ></span>
        <ul className="relative flex h-4 justify-between text-xs font-normal text-gray-600 dark:text-zinc-300">
          <li className="absolute top-0 left-0">0</li>
          <li className="absolute top-0 left-1/4 -translate-x-1/2">25</li>
          <li className="absolute top-0 left-1/2 -translate-x-1/2">50</li>
          <li className="absolute top-0 left-3/4 -translate-x-1/2">75</li>
          <li className="absolute top-0 right-0">100</li>
        </ul>
      </div>
    </div>
  );
}
