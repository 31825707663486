import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppTheme } from 'statemgmt/actions';
export default function useThemeToggle() {
  const [theme, setTheme] = useState(localStorage.theme);
  const colorTheme = theme === 'light' ? 'dark' : 'light';
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAppTheme(theme));
    const rootEl = document.getElementById('root');
    const microRootEl = document.getElementById('sqlpad-frame');
    if (microRootEl != null) {
      microRootEl.contentWindow.postMessage(theme, '*');
    }
    rootEl.classList.remove(colorTheme);
    rootEl.classList.add(theme);
    localStorage.setItem('theme', theme === undefined ? 'dark' : theme);
  }, [theme, colorTheme]);
  return [colorTheme, setTheme];
}
