import { AdjustmentsIcon } from '@heroicons/react/solid';
import Tippy from '@tippyjs/react';
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import { getLastIndexed } from 'Helper/services';
import { useLocalStorage } from 'Hooks';
import {
  ContentWrapper,
  FilterButton,
  MenuWrapper,
  PopoverWrapper,
  Refresh,
  Select,
  Text,
  Tooltip,
} from 'Utilities';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToptTradesUnlisted } from 'statemgmt/actions';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import {
  buyFilterOptions,
  sellFilterOptions,
  token_duration,
  token_group,
  token_limit,
  token_type,
} from './Helper/constants';
import useHeaders from './Helper/header';
import { processToken } from './Helper/processToken';

import DataLastIndexed from 'Utilities/DataLastIndexed/DataLastIndexed';
import { useNavigate } from 'react-router-dom';
import TableTokenDashboard from 'Utilities/Table/tableTokenDashboard';
import SortOptions from './sortOptions';
import Filters from './filters';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TopTradesUnlisted() {
  const [limit, setLimit] = useLocalStorage('unlisted_limit', 20);
  const [group, setGroup] = useLocalStorage('unlisted_group', 'whales');
  const [type, setType] = useLocalStorage('unlisted_type', 'bought_sold');
  const [duration, setDuration] = useLocalStorage('unlisted_duration', '24hr');
  const [buySellFilter, setBuySellFilter] = useLocalStorage(
    'unlistedbuysellfilter',
    'buy_wallet_count'
  );

  const groupFromParam = getUrlParam('group');
  const limitFromParam = getUrlParam('limit');
  const typeFromParam = getUrlParam('type');
  const durationFromParam = getUrlParam('duration');
  const [lastIndexed, setLastIndexed] = useState('');
  const [refresh, setRefresh] = useState(false);

  const [column] = useHeaders();
  const block_chain = CHAINS[getUrlParam('chain')];
  const chain = getUrlParam('chain');
  const basePath = '';
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { topTradesUnlisted, loading } = useSelector((s = initialState) => {
    return {
      topTradesUnlisted: processToken(
        s.topTradesUnlistedData.topTradesUnlisted,
        type
      ),
      loading: s.topTradesUnlistedData.loading,
    };
  });

  const chartViewProps = {
    group,
    type,
    duration,
  };

  const validateURLParams = () => {
    const urlLimitBoolean = token_limit?.some(
      (item) => item.value === limitFromParam
    );
    if (urlLimitBoolean) setLimit(limitFromParam);

    const urlGroupBoolean = token_group?.some(
      (item) => item.value === groupFromParam
    );
    if (urlGroupBoolean) setGroup(groupFromParam);

    const urlDurationBoolean = token_duration?.some(
      (item) => item.value === durationFromParam
    );
    if (urlDurationBoolean) setDuration(durationFromParam);

    const urlTypeBoolean = token_type?.some(
      (item) => item.value === typeFromParam
    );
    if (urlTypeBoolean) setType(typeFromParam);
  };

  useEffect(() => {
    validateURLParams();
  }, []);

  const tableViewProps = {
    data: topTradesUnlisted,
    col: column,
    isloading: loading,
  };

  useEffect(async () => {
    dispatch(
      getToptTradesUnlisted(
        block_chain,
        limit,
        group,
        type,
        duration,
        buySellFilter
      )
    );

    const res = await getLastIndexed(block_chain, 'top-dex-trades-unlisted');
    setLastIndexed(res?.data?.metadata?.data_last_indexed_timestamp_utc);
    navigate(
      `${basePath}?chain=${chain}&tab=TopDexTrades(un-listed)&limit=${limit}&group=${group}&type=${type}&duration=${duration}&sort_key=${buySellFilter}`
    );
  }, [limit, group, type, duration, block_chain, refresh, buySellFilter]);

  const filterProps = Object.freeze({
    limit,
    setLimit,
    group,
    setGroup,
    duration,
    setDuration,
    type,
    setType,
  });

  return (
    <ContentWrapper>
      <div className="hidden sm:flex  justify-between">
        <div className="flex flex-wrap space-x-1 mr-1 m-4">
          <Filters {...filterProps} />
          <div className="mx-2">
            <PopoverWrapper
              Icon={AdjustmentsIcon}
              label="More..."
              kind="primary"
            >
              <SortOptions
                filter={buySellFilter}
                setFilter={setBuySellFilter}
              />
            </PopoverWrapper>
          </div>
        </div>
        <div className="flex items-center space-x-2 m-3">
          <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
          <Refresh
            state={refresh}
            setState={setRefresh}
            loadingbutton={loading}
          />
        </div>
      </div>

      {/* filter, sort options mobile view */}
      <div className="block sm:hidden py-4 px-2">
        <div className="sm:mt-2 flex justify-end space-x-2 items-center">
          <Refresh
            state={refresh}
            setState={setRefresh}
            loadingbutton={loading}
          />
          <div>
            <MenuWrapper>
              <div className="">
                <Filters {...filterProps} />
                <SortOptions
                  filter={buySellFilter}
                  setFilter={setBuySellFilter}
                />
              </div>
            </MenuWrapper>
          </div>
        </div>
        <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
      </div>
      <div className="lg:px-4">
        <TableTokenDashboard {...tableViewProps} />
      </div>
    </ContentWrapper>
  );
}

export default TopTradesUnlisted;
