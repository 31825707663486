/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import TraderActivityDM from './appIcons/trader_activity-dm';
import TraderActivityLM from './appIcons/trader_activity-lm';
import {
  TraderActivityIconDark,
  TraderActivityIconLight,
} from 'Utilities/icons';

function TraderActivityIcon({ className }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div className="-ml-1">
      {theme === 'dark' ? (
        <img src={TraderActivityIconDark} className="h-10 w-10" />
      ) : (
        <img src={TraderActivityIconLight} className="h-10 w-10" />
      )}
    </div>
  );
}

export default TraderActivityIcon;
