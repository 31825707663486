/* eslint-disable react/prop-types */
import { NftIconDark, NftIconLight } from 'Utilities/icons';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

function NftIcon({ className }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div className="-ml-2">
      {theme === 'dark' ? (
        <img src={NftIconDark} className="h-10 w-10" />
      ) : (
        <img src={NftIconLight} className="h-10 w-10" />
      )}
    </div>
  );
}

export default NftIcon;
