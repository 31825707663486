export const processToken = (topTradesChart, type) => {
  // to hold push result data
  let dataSet = [];
  if (topTradesChart && topTradesChart.length > 0) {
    if (type == 'bought_sold' && topTradesChart.length > 3) {
      for (let key of topTradesChart) {
        dataSet.push({
          name: key['f'][0]['v'],
          logo_url: key['f'][1]['v'],
          buy: key['f'][2]['v'],
          sell: key['f'][3]['v'],
        });
      }
    } else {
      for (let key of topTradesChart) {
        dataSet.push({
          name: key['f'][0]['v'],
          logo_url: key['f'][1]['v'],
          value: key['f'][2]['v'],
        });
      }
    }
  }
  return dataSet;
};

export const processTokens = (token, type) => {
  let dataSet = [];
  let rankingSet = [];
  let topTradeSet = [];
  let topTrades = token?.data?.toptrades;
  let ranking = token?.data?.ranking;
  if (ranking && ranking.length > 0) {
    for (let item of ranking) {
      rankingSet.push({
        contract_address: item['f'][0]['v'],
        prev_rank: item['f'][1]['v'],
      });
    }
  }
  if (topTrades && topTrades.length > 0) {
    if (type == 'bought_sold') {
      for (let key of topTrades) {
        topTradeSet.push({
          name: key['f'][1]['v'],
          address: key['f'][2]['v'],
          price: key['f'][3]['v'],
          overall_volume: key['f'][4]['v'],
          current_market_cap: key['f'][5]['v'],
          logo_url: key['f'][6]['v'],
          cg_id: key['f'][7]['v'],
          stable_coin: key['f'][8]['v'],
          native_coin: key['f'][9]['v'],
          buy_volume: key['f'][10]['v'],
          buy_wallet_count: key['f'][11]['v'],
          buy_tx_count: key['f'][12]['v'],
          buy_accumulation: key['f'][13]['v'],
          sell_volume: key['f'][14]['v'],
          sell_wallet_count: key['f'][15]['v'],
          sell_tx_count: key['f'][16]['v'],
          sell_accumulation: key['f'][17]['v'],
          cmc_id: key['f'][18]['v'],
          cmc_slug: key['f'][19]['v'],
          slug: key['f'][7]['v'] ? key['f'][7]['v'] : key['f'][19]['v'],
          coin_info_provider: key['f'][7]['v'] ? 'cg' : 'cmc',
        });
      }
    } else {
      for (let key of topTrades) {
        topTradeSet.push({
          name: key['f'][1]['v'],
          address: key['f'][2]['v'],
          price: key['f'][3]['v'],
          overall_volume: key['f'][4]['v'],
          current_market_cap: key['f'][5]['v'],
          logo_url: key['f'][6]['v'],
          cg_id: key['f'][7]['v'],
          stable_coin: key['f'][8]['v'],
          native_coin: key['f'][9]['v'],
          volume: key['f'][10]['v'],
          volume_score: key['f'][11]['v'],
          wallet_count: key['f'][12]['v'],
          wallet_count_score: key['f'][13]['v'],
          tx_count: key['f'][14]['v'],
          tx_count_score: key['f'][15]['v'],
          accumulation: key['f'][16]['v'],
          accumulation_score: key['f'][17]['v'],
          cmc_id: key['f'][18]['v'],
          cmc_slug: key['f'][19]['v'],
          slug: key['f'][7]['v'] ? key['f'][7]['v'] : key['f'][19]['v'],
          coin_info_provider: key['f'][7]['v'] ? 'cg' : 'cmc',
        });
      }
    }

    let matchedRank = [];
    topTradeSet.map((item) => {
      rankingSet.map((elem) => {
        if (item.address == elem.contract_address) {
          matchedRank.push({
            address: elem.contract_address,
            prev_rank: elem.prev_rank,
          });
        }
      });
    });
    dataSet = [...topTradeSet].map((item, idx) => ({
      ...item,
      ranking: matchedRank[idx],
    }));
    let volumeArray = [];
    let walletArray = [];
    let txArray = [];
    let accumulationArray = [];
    topTradeSet.map((item) => {
      if (item.volume != null) volumeArray.push(parseInt(item.volume));
      if (item.wallet_count != null)
        walletArray.push(parseInt(item.wallet_count));
      if (item.tx_count != null) txArray.push(parseInt(item.tx_count));
      if (item.accumulation != null)
        accumulationArray.push(parseFloat(item.accumulation));
    });
    dataSet['max_vol'] = Math.max(...volumeArray);
    dataSet['max_wallet_count'] = Math.max(...walletArray);
    dataSet['max_tx_count'] = Math.max(...txArray);
    dataSet['max_accumulation'] = Math.max(...accumulationArray);
  }
  return dataSet;
};
