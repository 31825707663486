import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';

type popOverWrapperProps = {
  children: JSX.Element;
  Icon: JSX.Element;
  label: string;
  kind: string;
};
export default function PopoverWrapper({
  children,
  Icon,
  label,
  kind,
}: popOverWrapperProps): JSX.Element {
  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                rounded-md flex items-center   ${
                  kind === 'primary' &&
                  'px-2 py-2.5 border border-gray-600 dark:text-dark-textPrimary'
                }`}
            >
              {/* {icon} */}
              <div className="flex text-xs space-x-1 font-semibold">
                <Icon className="w-4" />
                {label && <span>{label}</span>}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-50  w-max max-w-md px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl bg-light-themeSecondary dark:bg-dark-bgMutedDark rounded-lg shadow-lg p-2">
                {children}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
