import { Table } from 'Utilities';
import React from 'react';
import PropTypes from 'prop-types';

function GroupTrades({ analytics, column, isloading }) {
  return (
    <div>
      <h3 className="text-sm font-bold  dark:text-dark-textPrimary mb-2">
        Trades by the selected group
      </h3>
      <Table data={analytics} col={column} isloading={isloading} />
    </div>
  );
}

export default GroupTrades;

GroupTrades.propTypes = {
  analytics: PropTypes.array,
  column: PropTypes.object,
  isloading: PropTypes.bool,
};
