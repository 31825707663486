/* eslint-disable react/prop-types */
import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';
//import Tooltip from 'Utilities/Tooltip/tooltip';

function ComparisonProgressbar(props) {
  const { buy_vol, sell_vol } = props;
  let total = parseInt(buy_vol) + parseInt(sell_vol);
  if (!total) {
    if (!buy_vol && !sell_vol) {
      // do something
    } else if (!buy_vol) {
      total = sell_vol;
    } else if (!sell_vol) {
      total = buy_vol;
    }
  }

  return (
    <div>
      <div className="">
        <div className="group cursor-pointer">
          <Tippy
            content={
              <div className="text-xs">{`Buy: ${buy_vol ? buy_vol : 0} Sell:${
                sell_vol ? sell_vol : 0
              }`}</div>
            }
            placement="bottom"
          >
            <div className="overflow-hidden h-1.5 text-xs flex rounded bg-blue-100 w-32">
              <div
                style={{
                  width: (buy_vol / total) * 100 + '%',
                }}
                className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500
            `}
              ></div>
              <div
                style={{
                  width: (sell_vol / total) * 100 + '%',
                }}
                className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500
            `}
              ></div>
            </div>
          </Tippy>
        </div>
      </div>
    </div>
  );
}

export default ComparisonProgressbar;

// ${Math.round( (value / max) * 100)
