import { Popover, Transition } from '@headlessui/react';
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  NavLink,
  useLocation,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { setSelectedChain } from 'statemgmt/actions';
import {
  Arbitrum,
  Binance,
  EthereumLogo,
  PolygonLogo,
  SolanaLogo,
} from 'Utilities/images';

BlockChainSelector.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.func,
};
function BlockChainSelector({ setState }) {
  const [navLink, setNavLink] = useState();
  const blockChainList = [
    {
      label: 'Ethereum',
      value: 0,
      icon: EthereumLogo,
    },
    {
      label: 'Polygon',
      value: 1,
      icon: PolygonLogo,
    },
    {
      label: 'Arbitrum',
      value: 2,
      icon: Arbitrum,
    },
    {
      label: 'Binance',
      value: 3,
      icon: Binance,
    },
    {
      label: 'Solana',
      value: 4,
      icon: SolanaLogo,
    },
  ];

  const [selected, setSelected] = useState(getUrlParam('chain'));
  const [queryString, setQueryString] = useState('');
  // const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let params = Object.fromEntries(searchParams.entries());
  const navigate = useNavigate();
  // Use the params object to access the query parameters
  const dispatch = useDispatch();

  const navLinkUpdate = (label, value) => {
    let queryString = '';
    if (Object.keys(params).length > 0) {
      queryString = Object.keys(params)
        .map(
          (key) =>
            key + '=' + (key === 'chain' ? label?.toLowerCase() : params[key])
        )
        .join('&');
    }
    navigate(`${location?.pathname}?${queryString && queryString}`);
    setSelected(label);
    setState(value);
  };
  // const location = useLocation();

  // useEffect(() => {
  //   dispatch(setSelectedChain(0));
  // }, [selected]);
  return (
    <>
      <Popover className="relative m-3">
        {({ open }) => (
          <>
            <Popover.Button
              onClick={() => (open ? close() : open())}
              className="flex group space-x-3 border dark:border-gray-700 px-4 py-2 rounded"
            >
              <span className="text-gray-500 dark:text-white text-sm capitalize">
                {selected}
              </span>
              <img
                // src={renderIcon()}
                src={
                  blockChainList[CHAINS[getUrlParam('chain')]]?.icon &&
                  blockChainList[CHAINS[getUrlParam('chain')]]?.icon
                }
                className="w-5 h-5"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute  z-10 mt-2 w-auto max-w-lg sm:px-0 bg-white dark:bg-dark-themeBgDark border dark:border-gray-700 rounded p-2 dark:text-white ">
                {({ close }) => (
                  <>
                    {blockChainList.map((item, key) => (
                      <div key={key} className="flex items-center p-2">
                        <img src={item.icon} className="w-4" />
                        <div
                          className={`px-2 py-1 text-sm  cursor-pointer ${
                            item.label.toLowerCase() == selected
                              ? 'text-black dark:text-white font-semibold'
                              : 'text-gray-500'
                          }`}
                          onClick={() => {
                            navLinkUpdate(item.label, item.value);
                            close();
                          }}
                        >
                          {item.label}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}

export default BlockChainSelector;
