import { QuestionMarkCircleIcon, RefreshIcon } from '@heroicons/react/outline';
import { clusterConfigurationList } from 'Helper/constants';
import { generateNickname } from 'Helper/generateNickname';
import {
  assignUserCluster,
  getAllocatableCluster,
  triggerGkeClusterCreation,
} from 'Helper/services';
import { Tooltip } from 'Utilities';
import BannerBottom from 'Utilities/Banner/bannerBottom';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NewCluster() {
  const nickName = generateNickname();
  const [nickname, setNickname] = useState(nickName);
  const [userHasSubscription, setUserHasSubscription] = useState('yes');
  const [loading, setLoading] = useState();

  const navigate = useNavigate();

  const user = JSON.parse(sessionStorage.getItem('user_info'));
  const userid = user?.user_id || null;

  const createCluster = async () => {
    setLoading(true);
    try {
      let cluster_id = null;
      let assignment = null;
      let cluster_name = null;

      let allocatableCluster = await getAllocatableCluster(userid);

      if (allocatableCluster.status) {
        setUserHasSubscription('yes');
        let { user_id, line_item_id, plan } = allocatableCluster.data;

        if ('cluster_id' in allocatableCluster.data) {
          console.log('allocatable cluster found, assigning cluster');
          cluster_id = allocatableCluster.data.cluster_id;

          assignment = await assignUserCluster(
            user_id,
            line_item_id,
            cluster_id,
            nickname
          );
        } else {
          console.log('allocatable cluster not found, creating new');
          let newCluster = await triggerGkeClusterCreation(plan);
          if (newCluster) {
            cluster_name = newCluster?.cluster_name;
            cluster_id = newCluster?.cluster_id;

            assignment = await assignUserCluster(
              user_id,
              line_item_id,
              cluster_id,
              nickname
            );
          }
        }
        setLoading(false);
        sessionStorage.setItem('cluster_name', cluster_name);
        if (assignment.status) {
          navigate(
            `/app/platforms/configuration/user-cluster/configure?id=${assignment?.user_cluster_id}&cid=${cluster_id}&step=initial-setup`
          );
        }
      } else {
        setUserHasSubscription('no');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };

  const renderClusterNickName = () => {
    return (
      <div>
        <label
          htmlFor="email"
          className="block text-xs font-medium leading-6 text-gray-900 dark:text-white "
        >
          Enter a nick name for you cluster
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            value={nickname}
            required
            className="block w-full rounded-md p-1.5 dark:bg-transparent bg-white dark:text-white  focus:outline-none border border-gray-600 dark:border-gray-500 text-xs sm:leading-6"
            onChange={(e) => handleNicknameChange(e)}
          />
        </div>
      </div>
    );
  };

  const renderConfigurationList = () => {
    return (
      <div className="mt-6">
        <legend className="sr-only">Configurations</legend>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900 dark:text-dark-textPrimary"
        >
          The following services will be configured on your cluster
        </label>
        <div className="space-y-5 my-4">
          {clusterConfigurationList.map((itm, key) => (
            <div className="relative flex items-start" key={key}>
              <div className="flex h-6 items-center">
                <input
                  id="configuration"
                  aria-describedby="configuration-description"
                  name="configuration"
                  type="checkbox"
                  className="h-4 w-4 focus:ring-indigo-600"
                  disabled
                  checked
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label
                  htmlFor="configuration"
                  className="text-sm font-medium text-gray-900 dark:text-white flex space-x-1"
                >
                  <span>{itm.configuration}</span>
                  <Tooltip content={itm.tooltip} placement="top">
                    <span>
                      <QuestionMarkCircleIcon className="w-3.5" />
                    </span>
                  </Tooltip>
                </label>
                <p
                  id="configuration-description"
                  className="text-gray-500 text-xs"
                >
                  {itm.desc}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderClusterConfig = () => {
    return (
      <div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img
          className="mx-auto h-10 w-auto"
          src={LogoDark}
          // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt="Your Company"
        /> */}

          <div className="dark:text-white my-4 text-lg font-semibold text-center leading-9 tracking-tight">
            Create new cluster
          </div>
        </div>

        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-lg">
          {renderClusterNickName()}
          {renderConfigurationList()}
          <div className="mt-8 flex space-x-2">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={createCluster}
              disabled={loading}
            >
              {loading ? (
                <RefreshIcon className="w-4 animate-spin" />
              ) : (
                'Create Cluster'
              )}
            </button>
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent border-blue-600 px-4 py-1.5 text-sm font-medium dark:text-white shadow-sm "
              disabled={loading}
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center h-102">
      {userHasSubscription == 'no' && (
        <BannerBottom setShowBanner={setUserHasSubscription} />
      )}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
        {renderClusterConfig()}
      </div>
    </div>
  );
}

export default NewCluster;
