/* eslint-disable react/prop-types */
import { InformationCircleIcon } from '@heroicons/react/outline';
import { getUrlParam } from 'Helper/getUrlPram';
import Tooltip from 'Utilities/Tooltip/tooltip';
import { useNavigate } from 'react-router-dom';

export default function PillTab({ tabOptions, state, setState, basePath }) {
  const navigate = useNavigate();
  var queryString = '';

  const chain = getUrlParam('chain');
  const contract_address = getUrlParam('contract_address');

  const handleTabChange = (tabName) => {
    setState(tabName);
    const filterOptions = tabOptions?.map((item) => {
      if (item?.name.replace(/\s+/g, '') === tabName) return item?.filter;
    });
    const search = filterOptions?.filter(Boolean);
    if (Object.keys(search).length > 0 || search !== null) {
      queryString = Object.keys(search)
        .map((key) => key + '=' + search[key])
        .join('&');
    }
    navigate(
      `${basePath}?chain=${chain}&tab=${tabName}&contract_address=${contract_address}${
        queryString ? `&${queryString}` : ''
      }`
    );
  };
  return (
    <div className="dark:border-gray-40">
      <div className="sm:hidden flex -mb-3">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block px-2 py-2 bg-light-themeSecondary dark:bg-dark-themePrimary dark:text-white mb-3 rounded-tr-md text-sm"
          value={state}
          onChange={(e) => {
            handleTabChange(e.target.value);
          }}
        >
          {tabOptions.map((tab) => (
            <option key={tab.id} value={tab.name.replace(/\s+/g, '')}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      {/* default view */}
      <div className="hidden sm:block overflow-scroll pilltab">
        <div className="relative">
          <nav
            className="flex space-x-2 dark:text-dark-textPrimary lg:justify-start"
            aria-label="Tabs"
          >
            {tabOptions.map((tab) => (
              <div key={tab.name} className="rounded">
                <div
                  className={
                    'group relative text-gray-600 cursor-pointer rounded-t-lg p-2 font-semibold text-xs dark:text-white  ' +
                    (state != tab?.name.replace(/\s+/g, '')
                      ? ''
                      : 'font-bold antialiased rounded-t-lg p-2 active dark:text-white bg-light-themeSecondary dark:bg-dark-themePrimary  text-blue-600 dark:border-dark-tabPillBgSelectedDark')
                  }
                >
                  <div className="flex items-center hover:bg-gray-200 dark:hover:bg-transparent py-2 px-4 rounded-full">
                    <div
                      onClick={() => {
                        handleTabChange(tab?.name.replace(/\s+/g, ''));
                      }}
                      className=""
                    >
                      {tab.name}
                    </div>
                    <Tooltip content={tab.tooltip} placement="top">
                      <div>
                        <InformationCircleIcon className="lg:h-4 w-4 ml-0.5 inline-flex" />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
