/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// constants
import { Menu, Transition } from '@headlessui/react';
import { FilterIcon, RefreshIcon } from '@heroicons/react/outline';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import UseGetCurrentUser from 'Hooks/useGetCurrentUser';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLeaksData } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { Refresh, Select, Table } from 'Utilities';
import { column } from './header';

function MyAlphaLeads() {
  const [filter, setFilter] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const filterOptions = [
    {
      text: 'All',
      value: '1',
    },
    {
      text: 'Open',
      value: '2',
    },
    {
      text: 'Closed',
      value: '3',
    },
  ];
  const { userLeaks, loading } = useSelector((s = initialState) => {
    return {
      userLeaks: s.userLeaksData.userLeaks.data,
      loading: s.userLeaksData.loading,
    };
  });
  const { user_address } = useGetCurrentUser();
  useEffect(() => {
    dispatch(getUserLeaksData(user_address, filter));
  }, [filter, refresh]);

  return (
    <div className="h-full">
      <div className="dark:bg-dark-themePrimary bg-light-themeSecondary p-4 rounded-tr-md">
        <div className="p-2">
          <h2 className="font-bold text-sm sm:text-xl sm:ml-2 dark:text-dark-textPrimary">
            My Alpha Leads
          </h2>
          <p className="text-gray-400 sm:m-2 my-2 dark:text-dark-textSecondary text-xs sm:text-md">
            The scores may not update right after the alpha lead effect duration
            is complete. Scoring is automatic but may take some time.
          </p>
        </div>
        <div className="w-full border-t border-gray-300 dark:border-gray-700" />
        <div className="hidden sm:block">
          <div className="flow-root mt-2">
            <div className="flex justify-between mb-2">
              <div className="flex flex-wrap float-left">
                <Select
                  optionsObj={filterOptions}
                  state={filter}
                  setState={setFilter}
                />
              </div>
              <div className="mx-2 my-1 flex space-x-2">
                <div className="mx-2 ">
                  <div className="flex justify-between mt-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex flex-row-reverse my-2">
          <div className="">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                  <span className="sr-only">Open options</span>
                  <FilterIcon className="h-4 w-4" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-dark-bgMutedDark ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1 px-2">
                    <div className="flow-root ">
                      <div className="">
                        <Select
                          optionsObj={filterOptions}
                          state={filter}
                          setState={setFilter}
                        />
                      </div>
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="mx-2">
            <button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
              <span className="sr-only">refresh Option</span>
              <RefreshIcon
                className={`w-4 h-4 text-gray-400 cursor-pointer mouse-pointer `}
                onClick={() => {
                  setRefresh(!refresh);
                }}
              />
            </button>
          </div>
        </div>
        <div className="shadow overflow-hidden border-b border-gray-200 rounded-b-md overflow-y-scroll overflow-x-auto h-102 metric-scroll dark:border-dark-secondary dark:bg-dark-tabPillBgSelectedDark bg-light-tabPillBgSelectedLight">
          {userLeaks ? (
            <Table data={userLeaks} col={column} isloading={loading} />
          ) : (
            'loading...'
          )}
        </div>
      </div>
    </div>
  );
}

export default MyAlphaLeads;
