/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const processToken = (topTradesUnlisted, type) => {
  // to hold push result data
  let dataSet = [];
  let ttUnlisted = [];
  let topTradesUnlistedData = topTradesUnlisted?.data?.ttUnlisted;
  let ttUnlistedRankingData = topTradesUnlisted?.data?.ttUnlistedRanking;
  if (topTradesUnlistedData && topTradesUnlistedData.length > 0) {
    if (type == 'bought_sold') {
      for (let key of topTradesUnlistedData) {
        ttUnlisted.push({
          wallet_group: key['f'][0]['v'],
          address: key['f'][1]['v'],
          symbol: key['f'][2]['v'],
          name: key['f'][3]['v'],
          buy_volume: key['f'][4]['v'],
          buy_wallet_count: key['f'][5]['v'],
          buy_tx_count: key['f'][6]['v'],
          buy_accumulation: key['f'][7]['v'],
          sell_volume: key['f'][8]['v'],
          sell_wallet_count: key['f'][9]['v'],
          sell_tx_count: key['f'][10]['v'],
          sell_accumulation: key['f'][11]['v'],
        });
      }
    } else {
      for (let key of topTradesUnlistedData) {
        ttUnlisted.push({
          wallet_group: key['f'][0]['v'],
          address: key['f'][1]['v'],
          symbol: key['f'][2]['v'],
          name: key['f'][3]['v'],
          volume: key['f'][4]['v'],
          wallet_count_score: key['f'][5]['v'],
          wallet_count: key['f'][6]['v'],
          tx_count_score: key['f'][7]['v'],
          tx_count: key['f'][8]['v'],
          accumulation_score: key['f'][9]['v'],
          accumulation: key['f'][10]['v'],
        });
      }
    }
    dataSet = [...ttUnlisted].map((item, idx) => ({
      ...item,
      ranking: ttUnlistedRankingData[idx],
    }));
    let walletArray = [];
    let txArray = [];
    let accumulationArray = [];
    dataSet.map((item) => {
      if (item.wallet_count != null)
        walletArray.push(parseInt(item.wallet_count));
      if (item.tx_count != null) txArray.push(parseInt(item.tx_count));
      if (item.accumulation != null)
        accumulationArray.push(parseInt(item.accumulation));
    });
    dataSet['max_wallet_count'] = Math.max(...walletArray);
    dataSet['max_tx_count'] = Math.max(...txArray);
    dataSet['max_accumulation'] = Math.max(...accumulationArray);
  }
  return dataSet;
};

export const feedChart = (topTradesChart, type) => {
  // to hold push result data
  let dataSet = [];
  if (topTradesChart && topTradesChart.length > 0) {
    if (type == 'bought_sold' && topTradesChart.length > 3) {
      for (let key of topTradesChart) {
        dataSet.push({
          name: key['f'][0]['v'],
          logo_url: key['f'][1]['v'],
          buy: key['f'][2]['v'],
          sell: key['f'][3]['v'],
        });
      }
    } else {
      for (let key of topTradesChart) {
        dataSet.push({
          name: key['f'][0]['v'],
          logo_url: key['f'][1]['v'],
          value: key['f'][2]['v'],
        });
      }
    }
  }
  return dataSet;
};
