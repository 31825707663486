import React from 'react';

function ApprovalStatus(props) {
  // eslint-disable-next-line react/prop-types
  const { status } = props;
  return (
    <div>
      {status === 'APPROVED' ? (
        <span className="inline-flex items-center px-2 py-0.5 rounded text-sm font-medium text-green-500">
          {status}
        </span>
      ) : status === 'DECLINED' ? (
        <span className="inline-flex items-center px-2 py-0.5 rounded text-sm font-medium text-red-500">
          {status}
        </span>
      ) : (
        <span className="inline-flex items-center px-2 py-0.5 rounded text-sm font-medium text-gray-500">
          {status}
        </span>
      )}
    </div>
  );
}

export default ApprovalStatus;
