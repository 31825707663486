/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { ClockIcon, LinkIcon } from '@heroicons/react/outline';

function InfoCard(props) {
  const { metric, text, url, last_updated } = props;
  return (
    <div className="">
      <div className="px-1 py-1 border rounded-md dark:border-gray-700 h-48 bg-white dark:bg-dark-themeBgDark">
        <div className="overflow-hidden m-3">
          <div className="mt-2 font-bold capitalize text-lg dark:text-dark-textPrimary">
            {metric}
          </div>
          <div className="text-md dark:text-dark-textPrimary">{text}</div>
          <div className="mt-4 flex">
            <LinkIcon className="w-4 h-4 mr-2 mt-0.5 text-blue-500" />
            <a
              className="text-sm font-bold text-blue-700 text-truncate"
              href={url}
              target="_blank"
              rel="noreferrer"
            >
              {url ? url.substr(0, 30) + '....' : null}
            </a>
          </div>
          <div className="mt-4 flex text-sm dark:text-dark-textPrimary">
            <ClockIcon className="w-4 h-4 mr-2 mt-0.5 dark:text-dark-textPrimary" />
            {moment.utc(last_updated).fromNow(true)} ago
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCard;
