/* eslint-disable react/prop-types */
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

// type NotifyProps = {
//   show: boolean;
//   setShow: (show: boolean) => void;
//   type: string;
//   message: string;
// };
export default function Notification({ show, setShow, type, title, message }) {
  const iconObj = {
    success: CheckCircleIcon,
    warning: ExclamationIcon,
    error: XCircleIcon,
  };
  const NotifyIcon = () => {
    if (type == 'success') {
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      );
    }
    if (type == 'warning') {
      return (
        <ExclamationIcon
          className="h-6 w-6 text-yellow-400"
          aria-hidden="true"
        />
      );
    }
    if (type == 'error') {
      return (
        <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
      );
    }
  };
  return (
    <>
      <div
        aria-live="assertive"
        className="z-40 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <NotifyIcon />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-xs text-gray-500">{message}</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
