/* eslint-disable react/prop-types */
import { SCAN_ADDRESS, SCAN_TX } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import { CoinName } from 'Utilities';
import DexTradeActivity from 'Utilities/Badges/dexTradeActivity';
import PublicLinks from 'Utilities/Coins/publicLinks';
import Etherscan from 'Utilities/Etherscan/etherscan';
import InputFilter from 'Utilities/Table/inputFilter';
import NumberRangeFilter from 'Utilities/Table/numberRangeFilter';
import SelectFilter from 'Utilities/Table/selectFilter';
import moment from 'moment';

export const column = [
  {
    Header: 'Token Name',
    accessor: 'name',
    Cell: (cell) => (
      <div className="flex items-center justify-betwee space-x-6">
        {/* <a
          className="mr-1 text-blue-500"
          href={`${chainProvider(getUrlParam('chain'))}/token/${`${
            cell.data[cell.row.index].contract_address
          }`}`}
          target="_blank"
          rel="noreferrer"
        >
          {cell.row.values.name}
        </a> */}
        {/* <div className="mt-=">
          <PublicLinks />
        </div> */}
        <CoinName
          isSubtring={true}
          slug={cell.data[cell.row.index].contract_address}
          provider={''}
          name={cell.row.values.name}
          logo_url={''}
          showLogo={false}
        />
      </div>
    ),
    disableSortBy: true,
    Filter: '',
  },
  {
    Header: 'Token External Links',
    accessor: 'contract_address',
    Cell: (cell) => (
      <div className="">
        <PublicLinks token_address={cell.row.values.contract_address} />
      </div>
    ),
    Filter: '',
  },
  {
    Header: 'Tx Hash',
    accessor: 'transaction_hash',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.transaction_hash}
        type={SCAN_TX}
        isSubString={true}
      />
    ),
    isSearchClicked: true,
    action: 'defiLatestTrades',
    Filter: '',
  },
  {
    Header: 'Trader Address',
    accessor: 'trader_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.trader_address}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    disableSortBy: true,
    Filter: InputFilter,
  },
  {
    Header: 'Trade Action',
    accessor: 'trade_action',
    Cell: (cell) => (
      <DexTradeActivity activity={cell.row.values.trade_action} />
    ),
    disableSortBy: true,
    Filter: SelectFilter,
  },
  {
    Header: 'DEX',
    accessor: 'dex',
    Filter: '',
  },
  {
    Header: 'Swap Type',
    accessor: 'trade_type',
    Filter: '',
  },
  {
    Header: 'Token Amount',
    accessor: 'token_amount',
    Cell: (cell) => (
      <>
        {cell.row.values.trade_action === 'buy' ||
        cell.row.values.trade_action === 'inflow' ? (
          <p className="text-green-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        ) : (
          <p className="text-red-500">
            {formatValue('format', cell.row.values.token_amount)}
          </p>
        )}
      </>
    ),
    disableSortBy: true,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  {
    Header: 'Est. USD Val (Tx Date)',
    accessor: 'token_value_usd_txdate',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.data[cell.row.index].using_ethvalue_txdate != 'x'
              ? cell.row.values.trade_action === 'buy' ||
                cell.row.values.trade_action === 'inflow'
                ? 'text-green-500'
                : 'text-red-500'
              : 'text-gray-500'
          }`}
          title={
            cell.data[cell.row.index].using_ethvalue_txdate === 'x'
              ? 'token price not found, showing eth value'
              : ''
          }
        >
          {cell.row.values.token_value_usd_txdate
            ? '$' +
              formatValue('format', cell.row.values.token_value_usd_txdate)
            : '-'}
        </p>
      </>
    ),
    disableSortBy: true,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  {
    Header: 'USD Value (Current)',
    accessor: 'token_value_usd',
    Cell: (cell) => (
      <>
        <p
          className={`${
            cell.data[cell.row.index].using_ethvalue_current != 'x'
              ? cell.row.values.trade_action === 'buy' ||
                cell.row.values.trade_action === 'inflow'
                ? 'text-green-500'
                : 'text-red-500'
              : 'text-gray-500'
          }`}
          title={
            cell.data[cell.row.index].using_ethvalue_current === 'x'
              ? 'token price not found, showing eth value'
              : ''
          }
        >
          {cell.row.values.token_value_usd
            ? '$' + formatValue('format', cell.row.values.token_value_usd)
            : '-'}
        </p>
      </>
    ),
    disableSortBy: true,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  {
    Header: 'Time',
    accessor: 'timestamp_utc',
    Cell: ({ cell }) => {
      // let dateTime = new Date(cell.row.values.timestamp_utc * 1000).toUTCString(
      //   'en-US'
      // );
      let dateTime = moment.utc(cell.row.values.timestamp_utc * 1000).fromNow();
      return dateTime;
    },
    Filter: '',
  },
];
