/* eslint-disable react/prop-types */
import { Modal } from 'Utilities';
import React, { useState } from 'react';
import Activities from '../Actions/activities';
import { useNavigate } from 'react-router-dom';

//TODO: user action needed ? yellow inidcator
const statuses = {
  ready: 'text-green-400 bg-green-400/10',
  preparing: 'bg-transparent text-transparent',
  user_action_needed: 'text-yellow-400 bg-yellow-400/10',
};
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ConfigStatus({ cell, kind }) {
  const [show, setShow] = useState(false);

  // const status = 'preparing';
  // const status = 'user_action_needed';
  const status = cell.data[cell.row.index][kind];
  const user_clusters_id = cell.data[cell.row.index].user_clusters_id;
  const cluster_id = cell.data[cell.row.index].cluster_id;

  const navigate = useNavigate();

  const handleClick = () => {
    if (status === 'ready') {
      setShow(true);
    }
    if (status === 'preparing' || status === 'creating') {
      navigate(
        `/app/platforms/configuration/user-cluster/configure?id=${user_clusters_id}&cid=${cluster_id}&step=initial-setup`
      );
    }

    if (status === 'user_action_needed') {
      navigate(
        `/app/platforms/configuration/user-cluster/configure?id=${user_clusters_id}&step=vault-operations`
      );
    }
  };
  return (
    <>
      {show && (
        <Modal
          disableBackdropClick={true}
          showPopup={show}
          setShowPopup={setShow}
          width={'fit'}
        >
          <Activities uci={user_clusters_id} setShowActivities={setShow} />
        </Modal>
      )}
      <div className="flex items-center">
        {/* {status === 'ready' && ( */}
        <div
          className={classNames(statuses[status], 'flex-none rounded-full p-1')}
        >
          <div className="h-1.5 w-1.5 rounded-full bg-current" />
        </div>
        {/* )} */}

        <div className="cursor-pointer text-textLink" onClick={handleClick}>
          {status}
        </div>
      </div>
    </>
  );
}

export default ConfigStatus;
