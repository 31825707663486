import { useAuth } from 'Authentication/authProvider';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';

export default function OidcLogin() {
  const auth = useAuth();
  const navigate = useNavigate();

  const login = async () => {
    auth.isLoggedIn() && auth.isVerified()
      ? navigate('/app/home/welcome/quick-access')
      : await auth.startAuthentication();
  };
  return (
    <>
      <button
        className="w-full px-5 py-3 items-center justify-center text-sm font-semibold rounded-lg text-white bg-theme hover:opacity-75"
        onClick={() => login()}
      >
        Go to app
      </button>
    </>
  );
}
