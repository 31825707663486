/* eslint-disable react/prop-types */
import { XCircleIcon, XIcon } from '@heroicons/react/outline';
import React from 'react';

function MandatoryFieldNotification({ setIsSubmitted, setRequired, errors }) {
  // close alert method start
  return (
    <div>
      <div className="mb-2">
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">{errors}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                    onClick={() => {
                      setIsSubmitted(false);
                      setRequired(false);
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MandatoryFieldNotification;
