import { ClipboardIcon, DuplicateIcon, XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import Code from 'Utilities/Code/code';

LocalRunSetUp.propTypes = {
  setShowModal: PropTypes.func,
};
function LocalRunSetUp({ setShowModal }) {
  const [copied, setCopied] = useState(false);
  const resetCopy = useRef();

  const onCopyCode = () => {
    navigator.clipboard.writeText(children).then(() => setCopied(true));
  };

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  useEffect(() => {
    if (copied) {
      resetCopy.current = setTimeout(() => setCopied(false), 2500);
    }

    return () => {
      clearTimeout(resetCopy.current);
    };
  }, [copied]);
  return (
    <div className="p-4 overflow-y-auto metric-scroll h-96">
      <div className="flex justify-between">
        <h1 className="text-md sm:text-lg lg:text-xl my-2 font-semibold">
          Run Transformation Locally
        </h1>
        <XIcon className="w-4" onClick={() => setShowModal(false)} />
      </div>
      <h1 className="py-2 text-lg">
        Alphastack uses Open-Source software DBT for Transformations.
      </h1>
      <div className="px-4">
        <h4 className="font-semibold text-md mt-3">
          Install a dbt Docker image from Github Packages
        </h4>
        <p className="py-2 text-sm">
          Offical dbt docker images are hosted as
          <a
            className="text-green-500 ml-2 hover:underline"
            href="https://github.com/orgs/dbt-labs/packages?visibility=public"
            target="_blank"
            rel="noreferrer"
          >
            packages in the
            <span
              className={` rounded px-1 mx-1 ${
                theme === 'dark'
                  ? 'bg-gray-600 text-white'
                  : 'bg-gray-400 text-black'
              }`}
            >
              dbt-labs
            </span>
            GitHub organization.
          </a>
        </p>
        <div className="px-8 pt-4 pb-5">
          <ul className="list-disc text-sm">
            <li>latest: Latest overall version of dbt-core + this adapter</li>
            <li>
              &lt;Major&gt;.&lt;Minor&gt;.latest: Latest patch of dbt-core +
              this adapter for &lt;Major&gt;.&lt;Minor&gt; version family. For
              example,{' '}
              <span
                className={`${
                  theme === 'dark' ? 'bg-gray-600 ' : 'bg-gray-400'
                } rounded px-1`}
              >
                1.1.latest
              </span>{' '}
              includes the latest patches for dbt Core v1.1.
            </li>
          </ul>
        </div>
        <p>Install an image using the docker pull command:</p>
        <div
          className={`${
            theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'
          }  rounded-lg w-auto my-4 max-w-2xl`}
        >
          <div className="flex justify-between items-baseline px-2 py-2">
            <div
              className={`flex flex-col w-full font-mono text-sm p-2 ${
                theme === 'dark' ? 'text-white' : 'text-black'
              }`}
            >
              <div className="">
                docker pull
                ghcr.io/dbt-labs/&lt;db_adapter_name&gt;:&lt;version_tag&gt;
              </div>
            </div>
            <div>
              {copied ? (
                <ClipboardIcon
                  className={`text-gray-400 w-5 dark:text-gray-500 ${
                    theme === 'dark' ? 'text-white' : 'text-black'
                  }`}
                />
              ) : (
                <DuplicateIcon
                  className={`w-5 text-gray-400 dark:text-gray-500  ${
                    theme === 'dark' ? 'text-white' : 'text-black'
                  }`}
                  onClick={onCopyCode}
                />
              )}
            </div>
          </div>
        </div>
        <h4 className="font-semibold text-md pt-4 mb-3">
          Running a dbt Docker image in a container
        </h4>
        <p>
          The ENTRYPOINT for dbt Docker images is the command dbt. You can
          bind-mount your project to /usr/app and use dbt as normal:
        </p>
        <div
          className={`${
            theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'
          }  rounded-lg w-auto my-4 max-w-2xl`}
        >
          {/* <Code>docker run \</Code>
          <Code>--network=host</Code>
          <Code>
            --mount type=bind,source=path/to/project,target=/usr/app \
          </Code>
          <Code>
            --mount type=bind,source=path/to/profiles.yml,target=/root/.dbt/ \
          </Code>
          <Code>(dbt_image_name)</Code>
          <Code>ls</Code> */}
          <div className="flex justify-between items-baseline px-2 py-2">
            <div
              className={`flex flex-col w-full font-mono text-sm p-2 ${
                theme === 'dark' ? 'text-white' : 'text-black'
              }`}
            >
              <span>docker run \</span>
              <span>--network=host</span>
              <span>
                --mount type=bind,source=path/to/project,target=/usr/app \
              </span>
              <span>
                --mount type=bind,source=path/to/profiles.yml,target=/root/.dbt/
                \
              </span>
              <span>&lt;dbt_image_name&gt;</span>
              <span>ls</span>
            </div>

            <div>
              {copied ? (
                <ClipboardIcon
                  className={`text-gray-400 w-5 dark:text-gray-500 ${
                    theme === 'dark' ? 'text-white' : 'text-black'
                  }`}
                />
              ) : (
                <DuplicateIcon
                  className={`w-5 text-gray-400 dark:text-gray-500  ${
                    theme === 'dark' ? 'text-white' : 'text-black'
                  }`}
                  onClick={() => onCopyCode}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 md:mt-8 text-sm mb-3 font-semibold">Notes:</div>
        <ul className="list-disc text-sm px-8">
          <li>Bind-mount sources must be an absolute path</li>
          <li>
            You may need to make adjustments to the docker networking setting
            depending on the specifics of your data warehouse or database host.
          </li>
        </ul>
        <div className="mt-5">
          <h4 className="font-semibold text-md">
            Building your own dbt Docker image
          </h4>
          <p className="pt-3">
            If the pre-made images don&apos;t fit your use case, we also provide
            a{' '}
            <span
              className={`${
                theme === 'dark' ? 'bg-gray-600 ' : 'bg-gray-400'
              } rounded px-1`}
            >
              Dockerfile
            </span>{' '}
            and{' '}
            <span
              className={`${
                theme === 'dark' ? 'bg-gray-600 ' : 'bg-gray-400'
              } rounded px-1`}
            >
              README
            </span>{' '}
            that can be used to build custom images in a variety of ways.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LocalRunSetUp;
