import { Text } from 'Utilities';
import { RequestFormModalButton } from './Atoms';
import { PropTypes } from 'prop-types';

Header.propTypes = {
  isUser: PropTypes.boolean,
};

export default function Header({ isUser }) {
  const PAGE_TITLE = 'Latest Alpha Requests';

  return (
    <div>
      {/* <Notify /> */}

      <div className={`sm:flex sm:items-center ${isUser ? 'mt-0' : 'mt-4'}`}>
        <div className="sm:flex-auto">
          <Text
            size="lg"
            fontWeight="semibold"
            className="dark:text-white"
            text={PAGE_TITLE}
          />
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <RequestFormModalButton />
        </div>
      </div>
    </div>
  );
}
