import { Listbox, Menu, Popover, Transition } from '@headlessui/react';
import {
  ChartPieIcon,
  CheckIcon,
  ChevronDownIcon,
  CodeIcon,
  DotsVerticalIcon,
  EyeIcon,
  PencilAltIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import { Modal } from 'Utilities';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Activities from '../Actions/activities';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// eslint-disable-next-line react/prop-types
function ActionMenu({ user_clusters_id }) {
  const navigate = useNavigate();
  const [showActivities, setShowActivities] = useState(false);

  const viewActivities = () => {
    setShowActivities(true);
  };

  const menuOptions = [
    {
      label: 'View Activity',
      id: 1,
      kind: 'function',
      action: viewActivities,
      icon: EyeIcon,
      color: 'dark:text-dark-textPrimary text-gray-700',
    },
    {
      label: 'Edit Nickname',
      id: 2,
      kind: 'function',
      // action: fn,
      icon: PencilAltIcon,
      color: 'dark:text-dark-textPrimary text-gray-700',
    },
    // {
    //   label: 'Create Catalogs',
    //   id: 3,
    //   kind: 'function',
    //   action: redirectToCatalogs,
    //   icons: PlusIcon,
    // },
    // {
    //   label: 'SQL Dashboards',
    //   id: 4,
    //   kind: 'function',
    //   action: fn,
    //   icon: CodeIcon,
    // },
    {
      label: 'Delete',
      id: 5,
      kind: 'function',
      // action: fn,
      icon: TrashIcon,
      color: 'dark:text-red-400 text-red-700',
    },
  ];

  if (showActivities) {
    return (
      <Modal
        disableBackdropClick={true}
        showPopup={showActivities}
        setShowPopup={setShowActivities}
        width={'fit'}
      >
        <Activities
          uci={user_clusters_id}
          setShowActivities={setShowActivities}
        />
      </Modal>
    );
  }

  return (
    <div className="pt-4">
      <Menu as="div" className="relative text-left">
        <div>
          <Menu.Button className="inline-flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-10 mt-2 w-56 z-10 rounded-md bg-white dark:bg-dark-themePrimary shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {menuOptions.map((item, key) => (
                <Menu.Item key={key}>
                  <div
                    className="block px-4 py-2 text-xs cursor-pointer  hover:bg-light-themeSecondary dark:hover:bg-gray-700"
                    onClick={item.action}
                  >
                    <p className={item.color}>{item.label}</p>
                  </div>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default ActionMenu;
