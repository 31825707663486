import React from 'react';
import Index from './Molecules';

export default function Dashboard() {
  return (
    <>
      <Index />
    </>
  );
}
