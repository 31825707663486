import { CheckIcon, LockClosedIcon } from '@heroicons/react/outline';
import { ChevronRightIcon } from '@heroicons/react/solid';
// import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { USER_SECRET_KEY } from 'Helper/constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { SiweMessage } from 'siwe';
import { walletAuthentication } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { Divider, Modal } from 'Utilities';
import coinbase from '../../Assets/Images/coinbase.svg';
import metamask from '../../Assets/Images/metamask.svg';
import walletconnect from '../../Assets/Images/walletconnect.svg';
import {
  injected,
  resetWalletConnector,
  walletConnectConnector,
} from './Helper/connectors';
import OidcLogin from './oidc-login';

function Login() {
  const domain = window.location.host;
  const origin = window.location.origin;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const [showPopup, setShowPopup] = useState(false);
  const [sKey, setSkey] = useState('');
  const [sKeyError, setSKeyError] = useState('');

  // const createSiweMessage = (address, statement) => {
  //   const message = new SiweMessage({
  //     domain,
  //     address,
  //     statement,
  //     uri: origin,
  //     version: '1',
  //     chainId: '1',
  //   });
  //   return message.prepareMessage();
  // };
  // const signInWithEthereum = async () => {
  //   const message = createSiweMessage(
  //     await signer.getAddress(),
  //     'Sign in with Ethereum to the app.'
  //   );
  //   console.log(await signer.signMessage(message));
  //   setConnected(true);
  // };

  // wallet aplications
  const applications = [
    {
      name: 'Meta Mask',
      description: 'Connect using you browser wallet',
      image: metamask,
      action: 1,
    },
    {
      name: 'Wallet Connect',
      description: 'Connect using you mobile wallet',
      image: walletconnect,
      action: 2,
    },
    // {
    //   name: 'Coinbase Wallet',
    //   description: 'Connect using you coinbase wallet',
    //   image: walletconnect,
    //   action: 3,
    // },
    // {
    //   name: 'SIWE',
    //   description: 'Sign In With Etherium',
    //   image: coinbase,
    // },
  ];

  // context for meta mask authentication
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { active, account, activate, deactivate } = useWeb3React();

  async function disconnect() {
    try {
      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  }
  // connect to meta mask
  const connectInjected = async () => {
    try {
      const resp = await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  };
  // connect wallet trigger point
  const connectWalletConnect = async () => {
    try {
      resetWalletConnector(walletConnectConnector);
      await activate(walletConnectConnector);
    } catch (ex) {
      console.log(ex);
    }
  };

  // auth action objects
  const handlers = {
    1: connectInjected,
    2: connectWalletConnect,
  };

  // auth action initiator
  const initiateAuth = (authActionKey) => {
    const handler = handlers[authActionKey];
    if (!handler) throw Error('Action not recognized');
    return handler();
  };

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const verifyKey = () => {
    if (sKey.toUpperCase() == USER_SECRET_KEY) {
      sessionStorage.setItem('skValidated', true);
      navigate('/home');
    } else {
      setSKeyError('Invalid key');
    }
  };

  const listener = (e) => {
    console.log(sKey);
    if (e.code === 'Enter' || e.code === 'NumPadEnter') {
      verifyKey();
    }
  };
  useEffect(() => {
    dispatch(walletAuthentication(active, account));

    if (account) {
      // history.push('/home');
      if (!JSON.parse(sessionStorage.getItem('skValidated')))
        setShowPopup(true);
      else navigate('/home');
      localStorage.setItem(process.env.REACT_APP_PUBLIC_ADDRESS_KEY, account);
    }
  }, [active, account]);

  if (showPopup) {
    return (
      <>
        <Modal
          disableBackdropClick={true}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        >
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <LockClosedIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <div
                className={`text-xl font-semibold leading-6  ${
                  theme == 'dark' ? 'text-gray-100' : 'text-gray-700'
                }`}
              >
                Access Code
              </div>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  The app is currently in Beta and access is by invitation only
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <input
              type="text"
              value={sKey}
              placeholder="Please enter your invitation code"
              onChange={(e) => setSkey(e.target.value)}
              onKeyDown={listener}
              className={`w-full p-2 rounded-md border dark:border-gray-700 sm:text-sm border-gray-500 ${
                theme == 'dark' ? 'bg-transparent' : 'bg-white'
              }`}
            />
            {sKeyError.length > 0 && (
              <span className="text-xs text-red-500 font-mono">
                {sKeyError}
              </span>
            )}
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 mt-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
              onClick={verifyKey}
            >
              Go to App
            </button>
          </div>
        </Modal>
      </>
    );
  }
  return (
    <div className="container mx-auto p-8 flex  h-screen w-full text-gray-700">
      <div className="max-w-md w-full mx-auto md:my-24">
        <Link
          to="/"
          className="text-blue-700 font-semibold text-3xl flex justify-center mb-12"
        >
          {' '}
          A L P H A S T A C K
        </Link>
        <div className="bg-white dark:bg-dark-themeBgDark border dark:border-gray-700  rounded-lg overflow-hidden shadow-lg   ">
          <div className="p-8">
            <h1 className="text-2xl text-center mb-6 md:mb-12 font-semibold dark:text-dark-textPrimary">
              Login
            </h1>
            <div className="bg-white dark:bg-dark-themeBgDark rounded-lg border dark:border-gray-700">
              <ul className="divide-y-2 divide-gray-100 dark:divide-gray-700">
                {applications.map((application) => (
                  <li key={application.name}>
                    <div
                      className="block hover:bg-blue-50 dark:hover:bg-gray-800 cursor-pointer"
                      onClick={() => initiateAuth(application.action)}
                    >
                      <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex-1 flex items-center">
                          <div className="flex-shrink-0">
                            <img
                              className="h-12 w-12 rounded-full"
                              src={application.image}
                              alt=""
                            />
                          </div>
                          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                            <div>
                              <p className="font-semibold text-lg dark:text-gray-300 truncate">
                                {application.name}
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <span className="">
                                  {application.description}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <ChevronRightIcon
                            className="h-6 w-7 text-gray-700"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <Divider text="or" />
            <OidcLogin />
          </div>
          <div className="flex justify-center p-8 text-sm border-t border-gray-300 ">
            <p className="font-medium text-gray-400">
              {" Don't have wallet ? "}
              <a href="/" className="text-blue-700">
                Download here
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
