import { labelTrade } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestTradesDistribution } from 'statemgmt/actions';
import DistributionChart from 'Utilities/Charts/DistributionChart/distributionChart';
import Label from 'Utilities/Elements/label';
import Spinner from 'Utilities/Loaders/spinner';
import CustomLabel from './customLabel';

function LatestTradeDistributionView() {
  const contract_address = getUrlParam('contract_address');
  const dispatch = useDispatch();
  const setColor = (elem) => {
    const bubbleColor = {
      common_wallets: '#D3D3D3',
      smart_money_wallets: '#f59e0b',
      defi_whale_wallets: '#059669',
      nft_whale_wallets: '#3b82f6',
      default: '#FFFFFF',
    };
    let color = '';

    if (elem['f'][0]['v'] == 'x') color = bubbleColor['common_wallets'];
    else if (elem['f'][1]['v'] == 'x')
      color = bubbleColor['smart_money_wallets'];
    else if (elem['f'][2]['v'] == 'x')
      color = bubbleColor['defi_whale_wallets'];
    else if (elem['f'][3]['v'] == 'x') color = bubbleColor['nft_whale_wallets'];
    else color = bubbleColor['deafult'];

    return color;
  };

  const processDistribution = (latestTrade) => {
    // to hold push result data
    let dataSet = [];
    if (latestTrade && latestTrade.length > 0) {
      for (let key of latestTrade) {
        // let dateTime = moment(key['f'][6]['v'] * 1000).format();
        dataSet.push({
          common_wallets: key['f'][0]['v'],
          smart_money_wallets: key['f'][1]['v'],
          defi_whale_wallets: key['f'][2]['v'],
          nft_whale_wallets: key['f'][3]['v'],
          token_amount: key['f'][4]['v'],
          date: key['f'][5]['v'],
          timestamp_group: new Date(key['f'][6]['v'] * 1000),
          token_price_usd: key['f'][7]['v'],
          color: setColor(key),
        });
      }
    }
    return dataSet;
  };

  const { lastestTradeDistribution, loading } = useSelector(
    (s = initialState) => {
      return {
        lastestTradeDistribution:
          s.latestTradeDistributionData?.latestTradeDistribution.data,
        loading: s.latestTradeDistributionData.loading,
      };
    }
  );

  const buyData = lastestTradeDistribution
    ? processDistribution(lastestTradeDistribution?.buy_data)
    : null;
  const sellData = lastestTradeDistribution
    ? processDistribution(lastestTradeDistribution?.sell_data)
    : null;
  const addData = lastestTradeDistribution
    ? processDistribution(lastestTradeDistribution?.add_data)
    : null;
  const removeData = lastestTradeDistribution
    ? processDistribution(lastestTradeDistribution?.remove_data)
    : null;

  useEffect(() => {
    dispatch(getLatestTradesDistribution(contract_address));
  }, []);

  if (loading) {
    return (
      <>
        <div className="overflow-hidden flex-1 dark:bg-dark-themePrimary bg-light-themeSecondary h-96 flex">
          <div className="">
            <Spinner />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="overflow-hidden flex-1 dark:bg-dark-themePrimary bg-light-themeSecondary">
      <div className="p-6">
        <div className="grid grid-cols-1 lg:grid-cols-2  mx-2 gap-4">
          <div className="dark:text-dark-textPrimary font-bold">
            <div className="mb-6">
              <Label text={labelTrade.buy} />
              <CustomLabel />
            </div>
            {buyData && (
              <DistributionChart
                token={buyData}
                chartId={'buy_data'}
                xValue={'timestamp_group'}
                yValue={'token_amount'}
                xLabel={''}
                yLabel={'Token Amount'}
              />
            )}
          </div>
          <div className="dark:text-dark-textPrimary font-bold">
            <div className="mb-6">
              <Label text={labelTrade.sell} />
              <CustomLabel />
            </div>
            {sellData && (
              <DistributionChart
                token={sellData}
                chartId={'sell_data'}
                xValue={'timestamp_group'}
                yValue={'token_amount'}
                xLabel={''}
                yLabel={'Token Amount'}
              />
            )}
          </div>
          <div className="dark:text-dark-textPrimary font-bold lg:mt-16">
            <div className="mb-6">
              <Label text={labelTrade.add} />
              <CustomLabel />
            </div>
            {addData && (
              <DistributionChart
                token={addData}
                chartId={'add_data'}
                xValue={'timestamp_group'}
                yValue={'token_amount'}
                xLabel={''}
                yLabel={'Token Amount'}
              />
            )}
          </div>
          <div className="dark:text-dark-textPrimary font-bold lg:mt-16">
            <div className="mb-6">
              <Label text={labelTrade.remove} />
              <CustomLabel />
            </div>
            {removeData && (
              <DistributionChart
                token={removeData}
                chartId={'remove_data'}
                xValue={'timestamp_group'}
                yValue={'token_amount'}
                xLabel={''}
                yLabel={'Token Amount'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestTradeDistributionView;
