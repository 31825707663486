import AuthCallback from 'Authentication/authCallback';
import { AuthProvider } from 'Authentication/authProvider';
import Verify from 'Components/Auth/verify';
import AppRoutes from 'Components/Container/routes';
import { AppProvider } from 'Providers/appProvider';
import { ScrollToTop } from 'Utilities';
import RestorePosition from 'Utilities/ScrollToTop/restorePosition';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './Components/LandingPage/landingPage';
import Login from './Components/Login/login';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const App = () => {
  return (
    <>
      <AuthProvider>
        <AppProvider>
          <BrowserRouter>
            <RestorePosition>
              <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/callback" element={<AuthCallback />} />
                <Route path="/verify" element={<Verify />} />
              </Routes>
              <AppRoutes />
            </RestorePosition>
          </BrowserRouter>
          <ScrollToTop />
        </AppProvider>
      </AuthProvider>
    </>
  );
};

export default App;
