import { format } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNftDropsData } from 'statemgmt/actions';
import EmptyDataScreen from 'Utilities/Screen/emptyDataScreen';
import LoadingScreen from 'Utilities/Screen/loadingScreen';
import discord from '../../../Assets/Images/discord.png';
import twitter from '../../../Assets/Images/twitter.png';
import website from '../../../Assets/Images/website.png';
import CountDown from './CountDown';
import DatePicker from './datePicker';
import { blockChainLogo, nftCategories } from './Helper/constants';
function NftDrops() {
  const [mintDate, setMintDate] = useState(
    new Date('Tue Feb 01 2022 00:00:00 GMT+0530')
  );
  console.log(mintDate);
  const dispatch = useDispatch();
  const { nftDrops, loading } = useSelector((s = initialState) => {
    return {
      nftDrops: s.nftDropsData.nftDrops,
      loading: s.nftDropsData.loading,
      // loading: s.nftDropsData.nftDrop.loading,
    };
  });
  useEffect(() => {
    const frmtMintDate = format(mintDate, 'yyyy-MM-dd HH:mm:ss');
    dispatch(getNftDropsData(frmtMintDate));
  }, [mintDate]);

  return (
    <div className="">
      <div
        className={`dark:bg-dark-themePrimary bg-light-themeSecondary p-4 rounded-tr-md`}
      >
        <div className="px-4 sm:px-6 text-center my-5 text-3xl font-bold  dark:text-dark-textPrimary">
          NFT Drops
        </div>
        <div className="overflow-hidden rounded-lg">
          <div className="px-4 sm:p-6 container mx-auto">
            <div className="flex sm:flex-row-reverse">
              <div className="">
                <DatePicker mintDate={mintDate} setMintDate={setMintDate} />
              </div>
            </div>
            {loading && <LoadingScreen />}
            {nftDrops.length == 0 && <EmptyDataScreen />}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
              {nftDrops && nftDrops.length > 0
                ? nftDrops.map((item, key) => (
                    <div
                      className="p-3 my-2 bg-white dark:bg-dark-bgMutedDark rounded-lg border dark:border-gray-700 mb-4 shadow shadow-blue-700"
                      key={key}
                    >
                      <div className="flex items-center space-x-4 mb-4">
                        <img
                          className="h-10 w-10 object-cover object-center rounded-full"
                          src={item.logo_url}
                          alt="photo"
                        />
                        <div className="w-full">
                          <p className="text-md font-bold text-gray-700 dark:text-dark-textPrimary mb-1">
                            {item.name}
                          </p>
                          <div className="flex justify-between items-center">
                            <p
                              className={`px-2 bg-${
                                nftCategories[0][item.category]
                              }-100  rounded-full text-sm text-${
                                nftCategories[0][item.category]
                              }-400  font-bold`}
                            >
                              {item.category}
                            </p>
                            <p className="text-sm px-2 py-2 rounded-full font-medium bg-gray-100 dark:bg-gray-700 text-purple-700">
                              <img
                                src={blockChainLogo[0][item.blockchain]}
                                alt="block chain"
                                className="w-6 rounded-full"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="border-b dark:border-gray-600">
                        <p className="text-gray-400 leading-loose font-normal text-base truncate">
                          {item.description
                            ? item.description
                            : `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.`}
                        </p>
                      </div>
                      <div className="my-2">
                        <div className="flex justify-between text-gray-600 dark:text-dark-textPrimary font-semibold">
                          <div>Price</div>
                          <div>{item.mint_price}</div>
                        </div>
                        <div className="flex justify-between text-gray-600 dark:text-dark-textPrimary font-semibold">
                          <div>Total</div>
                          <div>{item.total_supply}</div>
                        </div>
                        <div className="flex justify-between text-gray-600 dark:text-dark-textPrimary font-semibold">
                          <div>Date</div>
                          <div>
                            {`${moment
                              .utc(item.mint_date_utc)
                              .format('lll')} UTC`}
                          </div>
                        </div>
                      </div>
                      <div className="my-3">
                        <div className="">
                          <CountDown timeTillDate={item.mint_date_utc} />
                        </div>
                      </div>
                      <div className="flex justify-between mt-5">
                        <div>
                          <div className="flex space-x-2 overflow-hidden">
                            <a
                              href={item.website_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="inline-block h-5 w-5 rounded-full"
                                src={website}
                                alt=""
                              />
                            </a>
                            <a
                              href={item.twitter_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="inline-block h-6 w-6 rounded-full"
                                src={twitter}
                                alt=""
                              />
                            </a>
                            <a
                              href={item.discord_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="inline-block h-6 w-6 rounded-full"
                                src={discord}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                        <div>
                          <a
                            href={item.website_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            type="button"
                            className="inline-flex items-center px-4 py-1.5 border border-blue-600 shadow-sm text-sm font-medium rounded-full text-blue-500 bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700"
                          >
                            Mint
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NftDrops;
