/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const column = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (cell) => (
      <div className="flex items-center">
        <img
          className="h-5 w-5 rounded-full"
          src={`${cell.data[cell.row.index].logo_url}`}
          alt="logo"
        />

        <div className="ml-4">
          <div className="whitespace-nowrap text-sm text-gray-900 dark:text-dark-textPrimary">
            {cell.row.values.name}
          </div>
        </div>
      </div>
    ),
  },
  {
    Header: 'Block Chain',
    accessor: 'block_chain',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Users',
    accessor: 'users',
  },
  {
    Header: 'User Change Percentage',
    accessor: 'users_change_percentage',
    Cell: (cell) => (
      <div
        className={
          cell.row.values.users_change_percentage.startsWith('-')
            ? 'text-red-500'
            : ''
        }
      >
        {cell.row.values.users_change_percentage}
      </div>
    ),
  },
];
