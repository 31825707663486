import React from 'react';
import ContentWrapper from 'Utilities/TWComponents/contentWrapper';
import LeaksLeaderboard from './Atoms/leaksLeaderboard';
import SentimentChild from './Atoms/sentimentChild';
import TokenBreaking from './Atoms/tokenBreaking';
import TopItems from './Atoms/topItems';
import TopNews from './Atoms/topNews';
import UpcomingNft from './Atoms/upcomingNft';

export default function Index() {
  return (
    <>
      <div className="bg-light-themePrimary dark:bg-dark-themeSecondary">
        <div className="sm:mx-12">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="col-span-1 md:cols-span-2 md:p-4">
              <SentimentChild />
            </div>
            <div className="flex flex-col md:p-4">
              <LeaksLeaderboard />
            </div>
          </div>
          <>
            <TopNews />
          </>
          <>
            <TokenBreaking />
          </>
          <>
            <TopItems />
          </>
          <>
            <UpcomingNft />
          </>
        </div>
      </div>
    </>
  );
}
