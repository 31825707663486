/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-var-requires */
import Chart from 'react-apexcharts';
// NoDataToDisplay(Highcharts);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BasicCharts = ({
  ohlc,
  symbol,
  contract_address,
  token,
  provider,
}) => {
  console.log('ohlc----------------', ohlc);
  let state = {
    options: {
      chart: {
        id: 'basic-bar',
        zoom: {
          autoScaleYaxis: true,
        },
        foreColor: '#ccc',
      },
      fill: {
        type: 'gradient',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          rotateAlways: true,
          datetimeUTC: false,
          formatter: function (value) {
            const options = { month: 'short', day: 'numeric' };
            if (provider === 'cmc' || provider === 'cg')
              return new Date(value).toLocaleDateString(undefined, options);
            return new Date(value * 1000).toLocaleDateString(
              undefined,
              options
            );
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        categories: symbol,
        tooltip: {
          enabled: false,
        },
      },

      noData: {
        text: undefined,
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    series: [
      {
        name: `${token} Price`,
        id: symbol,
        data: ohlc,
      },
    ],
  };

  return (
    <>
      <Chart
        options={state.options}
        series={state.series}
        type="area"
        width="80%"
      />
    </>
  );
};

export default BasicCharts;
