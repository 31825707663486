import ActionMenu from './actionMenu';

export const column = [
  {
    Header: 'Name',
    accessor: 'name',
    Filter: '',
  },
  {
    Header: 'Description',
    accessor: 'description',
    Filter: '',
  },
  {
    Header: 'Type',
    accessor: 'type',
    Filter: '',
  },
  {
    Header: 'Cluster',
    accessor: 'cluster_name',
    Filter: '',
  },
  {
    Header: 'Created(UTC)',
    accessor: 'created_utc',
    Filter: '',
  },
  {
    Header: 'Last Modified(UTC)',
    accessor: 'last_modified_utc',
    Filter: '',
  },
  {
    Header: 'Registration Status',
    accessor: 'registration_status',
    Filter: '',
  },
  {
    Header: 'Connection Status',
    Cell: (cell) => (
      <div className="py-2">
        <button className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-2 py-1 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto">
          Connection Status
        </button>
      </div>
    ),
    Filter: '',
  },
  {
    Header: 'Action',
    Cell: (cell) => (
      // eslint-disable-next-line react/prop-types
      <ActionMenu />
    ),
    Filter: '',
  },
];
