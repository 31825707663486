import { ChartPieIcon, TemplateIcon } from '@heroicons/react/outline';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
// eslint-disable-next-line react/prop-types
function TraderActivityToggle({ state, setState }) {
  return (
    <>
      <div className="p-1 bg-gray-100 dark:bg-gray-800 rounded-lg">
        <div className="block">
          <div className="flex space-x-1" aria-label="Tabs">
            <p
              onClick={() => setState(1)}
              className={classNames(
                state == 1
                  ? 'bg-gray-300 text-gray-700 dark:bg-dark-bgMutedDark dark:text-white'
                  : 'text-gray-400 hover:text-gray-300',
                'flex space-x-1.5 px-2 py-2 font-medium text-xs rounded-md cursor-pointer overflow-hidden'
              )}
            >
              <TemplateIcon className="w-4" />
              <span className="hidde sm:block">Summary</span>
            </p>
            <p
              onClick={() => setState(2)}
              className={classNames(
                state == 2
                  ? 'bg-gray-300 text-gray-700 dark:bg-dark-bgMutedDark dark:text-white'
                  : 'text-gray-400 hover:text-gray-300',
                'flex space-x-1.5 px-2 py-2 font-medium text-xs rounded-md cursor-pointer overflow-hidden'
              )}
            >
              <ChartPieIcon className="w-4" />
              <span className="hidde sm:block">Details</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TraderActivityToggle;
