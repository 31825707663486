import React, { useEffect } from 'react';
import { Field } from 'Utilities/FormField/field';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import { submitAlphaResponse } from 'Helper/services';
import PropTypes from 'prop-types';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
SubmitResponse.propTypes = {
  request_id: PropTypes.number,
  socket: PropTypes.object,
  responseList: PropTypes.array,
  setResponseList: PropTypes.func,
  updateResponse: PropTypes.func,
};

export default function SubmitResponse({
  request_id,
  socket,
  responseList,
  setResponseList,
  updateResponse,
}) {
  const style = {
    askButton: `ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`,
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const user_address = '0xc95ce9a1750017c647bc11462902da0be8f88f87';
  const { user_address } = useGetCurrentUser();

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const onSubmit = (formData) => {
    formData['request_id'] = request_id;
    formData['user_address'] = user_address;
    socket.emit('submitResponse', formData);
    reset();
  };

  // const rs = responseList;
  useEffect(() => {
    socket.on('refreshResponse', function (r) {
      setResponseList(responseList.concat(r));
    });
  }, []);
  return (
    <div className="my-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          {...register('response', {
            required: 'Please enter something to submit',
          })}
          dot={true}
          error={errors?.response?.message}
          name="response"
          placeholder="what is your answer"
          type="textarea"
          theme={theme}
        />

        <div className="flex flex-row-reverse">
          <button type="submit" className={style.askButton}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
