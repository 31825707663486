export const processAnalayticsData = (analytics) => {
  let dataSet = [];
  if (analytics && analytics.length > 0) {
    for (let idx in analytics) {
      dataSet.push({
        symbol: analytics[idx]['f'][1]?.v,
        token_name: analytics[idx]['f'][2]?.v,
        token_contract: analytics[idx]['f'][0]?.v,
        // cg_id: analytics[idx]['f'][5]?.v,
        tx_count: analytics[idx]['f'][5]?.v,
        volume: analytics[idx]['f'][3]?.v,
        volume_usd: analytics[idx]['f'][4]?.v,
      });
    }
  }
  console.log('processing analytics', dataSet);
  return dataSet;
};

export const processTokenAddress = (tokens) => {
  let tokenFormatting = [];
  if (tokens && tokens.length > 0) {
    tokenFormatting = tokens?.map((item) => {
      return { trader_address: item.replaceAll("'", '') };
    });
  }
  console.log(tokenFormatting);
  return tokenFormatting;
};

export const tokenSearchResultFormat = (tokenInfo) => {
  // to hold push result data
  let dataSet = [];
  // tokenInfo = tokenInfo?.data;
  if (tokenInfo && tokenInfo.length > 0) {
    for (let key of tokenInfo) {
      dataSet.push({
        contract_address: key['f'][0]['v'],
        symbol: key['f'][1]['v'],
        name: key['f'][2]['v'],
        price_usd: key['f'][3]['v'],
      });
    }
  }
  return dataSet;
};
