/* eslint-disable react/prop-types */
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useAuth } from 'Authentication/authProvider';
import { sourceValidUrl } from 'Helper/validators';
import jwtDecode from 'jwt-decode';
import JwtDecode from 'jwt-decode';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getLeaksFormPreload, postLeaksForm } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { MandatoryFieldNotification, SucessNotification } from 'Utilities';

import SubmitForm from './alphaLeaksForm';
import LeaksTypeSelector from './leaksTypeSelector';
import Urlform from './urlForm';

export default function LeaksFormSlider({
  isLeaksSliderOver,
  setIsLeaksSliderOver,
}) {
  let selectedAddress = '';
  const auth = useAuth();
  const localStorageUser = auth.getLocalStorageUser();
  if (localStorageUser === null || localStorageUser === undefined) {
    //do nothing
  } else {
    if (localStorageUser.length === 0) {
      //do nothing
    } else {
      selectedAddress = jwtDecode(localStorageUser['id_token'])[
        'preferred_username'
      ];
    }
  }
  // state initialization start
  const [projectname, setProjectName] = useState('');
  const [ticker, setTicker] = useState('');
  const [website, setWebsite] = useState('');
  const [markets, setMarkets] = useState('');
  const [alpha, setAlpha] = useState('');
  const [sentiments, setSentiments] = useState('negative');
  const [effects, setEffects] = useState('within 1 day');
  const [comments, setComments] = useState('');
  const [required, setRequired] = useState(); // for required field handling
  const [errors, setErrors] = useState('');
  const [isSubmitted, setIsSubmitted] = useState('');
  const [validUrl, setValidUrl] = useState(false);
  const [sourceUrl, setSourceUrl] = useState('');
  const [noToken, setNoToken] = useState(false);
  const [alphaButton, setAlphaButton] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [showUrlForm, setShowUrlForm] = useState(false);
  const [showSubmit, setshowSubmit] = useState(false);

  //probs for value passing
  const props = {
    noToken,
    setNoToken,
    validUrl,
    setValidUrl,
    projectname,
    setProjectName,
    ticker,
    setTicker,
    website,
    setWebsite,
    markets,
    setMarkets,
    alpha,
    setAlpha,
    sentiments,
    setSentiments,
    effects,
    setEffects,
    comments,
    setComments,
    errors,
    setErrors,
    alphaButton,
    setAlphaButton,
    showForm,
    setShowForm,
    setShowUrlForm,
    setSourceUrl,
    setshowSubmit,
    showUrlForm,
  };

  // state initialization end

  const validateSourceUrl = (tokenUrlType) => {
    if (sourceUrl.length <= 0) {
      setRequired(true);
      setErrors('Please enter a valid');
      setShowUrlForm(true);
      setNoToken(false);
      return 0;
    }
    if (tokenUrlType == 'token') {
      if (sourceValidUrl(sourceUrl, tokenUrlType)) {
        setSourceUrl('');
        let url = new URL(sourceUrl);
        let urlType = url.pathname.split('/')[3];
        setShowForm(true);
        setValidUrl(true);
        setShowUrlForm(false);
        setshowSubmit(true);
        fetchData(urlType);
      } else {
        setValidUrl(false);
        setRequired(true);
        setShowUrlForm(true);
        setshowSubmit(false);
        setErrors('Please enter a valid coingecko url');
        closeAlertWindow();
      }
    } else if (tokenUrlType == 'nft') {
      if (sourceValidUrl(sourceUrl, tokenUrlType)) {
        setSourceUrl('');
        setValidUrl(true);
        setShowForm(true);
        setShowUrlForm(false);
        setshowSubmit(true);
        fetchOpenSeaData(sourceUrl);
      } else {
        setValidUrl(false);
        setRequired(true);
        setShowUrlForm(true);
        setshowSubmit(false);
        setErrors('Please enter a valid opensea url');
        closeAlertWindow();
      }
    }
  };

  // form submission method start
  const handleSubmit = () => {
    // if form submitted without entering mandatory fields
    if (!projectname || !alpha || !sentiments || !effects) {
      setRequired(true);
      setErrors('Uh-oh! you missed some fields');
      return 0;
    }
    let project_info_auto_filled = noToken ? '' : 'x';
    const submitData = {
      project_name: projectname,
      ticker: ticker,
      website: website,
      markets: markets,
      alpha: alpha,
      sentiment: sentiments,
      effect_seen_in: effects,
      comments: comments,
      user_address: selectedAddress,
      market_segment: alphaButton,
      project_info_auto_filled: project_info_auto_filled,
    };
    // dispacthing request
    dispatch(postLeaksForm(submitData));
    // enabling alert window
    setIsSubmitted(true);
    // updating form field state
    setProjectName('');
    setTicker('');
    setMarkets('');
    setAlpha('');
    setSentiments('');
    setEffects('');
    setComments('');
    setWebsite('');
    setShowUrlForm(false);
    setValidUrl(false);
    setshowSubmit(false);
    setNoToken(false);

    // triggering close alert window after form submissions success/failure
    closeAlertWindow();
  };
  // form submission method end

  // method for auto closing alert window
  const closeAlertWindow = () => {
    setTimeout(() => {
      setIsSubmitted(false);
      setRequired(false);
    }, 2000);
  };

  // useDispatch() instance
  const dispatch = useDispatch();
  // listening to store changes
  const { preload, theme } = useSelector((s = initialState) => {
    return {
      preload: s.leakFormPreload.preload.data,
      theme: s.appTheme.theme,
    };
  });

  //conigicko API URL
  const fetchData = async (checkUrl) => {
    const url = `https://api.coingecko.com/api/v3/coins/${checkUrl}?localization=false&tickers=false&market_data=false&community_data=false&developer_data=false&sparkline=false`;
    try {
      const response = await fetch(url);
      const json = await response.json();
      setProjectName(json.name);
      setTicker(json.symbol);
      setWebsite(json.links.homepage[0]);
      setMarkets(sourceUrl);
    } catch (error) {
      console.log('error', error);
    }
  };

  //OPensea API URL
  const fetchOpenSeaData = async (sourceUrl) => {
    let openSeaUrl = new URL(sourceUrl);
    let path = openSeaUrl.pathname.split('/')[1];
    let slug = '';
    let contract_id = '';
    let token_id = '';
    let url = '';
    if (path == 'collection') {
      slug = openSeaUrl.pathname.split('/')[2];
      url = `https://api.opensea.io/api/v1/collection/${slug}`;
    }
    if (path == 'assets') {
      contract_id = openSeaUrl.pathname.split('/')[2];
      token_id = openSeaUrl.pathname.split('/')[3];
      url = `https://api.opensea.io/api/v1/asset/${contract_id}/${token_id}/?include_orders=false`;
    }
    try {
      const response = await fetch(url);
      const json = await response.json();
      setProjectName(json.collection.name);
      path == 'assets'
        ? setTicker(json.token_id)
        : setTicker(json.collection.primary_asset_contracts[0].symbol);
      setWebsite(json.collection.primary_asset_contracts[0].external_link);
      setMarkets(sourceUrl);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (!(preload && preload.length > 0)) {
      dispatch(getLeaksFormPreload());
    }
  }, []);

  return (
    <Transition.Root show={isLeaksSliderOver} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={setIsLeaksSliderOver}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed z-50 inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div
                  className={`h-full flex flex-col bg-white shadow-xl ${
                    theme === 'dark'
                      ? 'bg-gray-800 divide-y divide-gray-700'
                      : 'divide-y divide-gray-200'
                  }`}
                >
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div
                      className={`py-6 px-4 sm:px-6 border-b ${
                        theme === 'dark' ? 'border-b border-gray-700' : ''
                      }`}
                    >
                      <div className="flex items-center justify-between">
                        <Dialog.Title
                          className={`text-xl font-semibold  dark:text-white ${
                            theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                          }`}
                        >
                          Submit Alpha Leads
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="rounded-md text-indigo-200 hover:text-white"
                            onClick={() => setIsLeaksSliderOver(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon
                              className="h-6 w-6 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p
                          className={`text-sm  ${
                            theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
                          }`}
                        >
                          {/* Get started by filling in the information below */}
                          Share your insights and get rewarded
                        </p>
                      </div>
                    </div>
                    <div className="p-5">
                      {/* ALERT START */}
                      {isSubmitted ? (
                        <SucessNotification
                          setIsSubmitted={setIsSubmitted}
                          setRequired={setRequired}
                        />
                      ) : null}
                      {required ? (
                        <MandatoryFieldNotification
                          setIsSubmitted={setIsSubmitted}
                          setRequired={setRequired}
                          errors={errors}
                        />
                      ) : null}
                      {/* ALERT END */}

                      {/* showing control to enter token/nft url */}
                      {showUrlForm && !noToken && (
                        <Urlform
                          noToken={noToken}
                          setNoToken={setNoToken}
                          validUrl={validUrl}
                          setValidUrl={setValidUrl}
                          sourceUrl={sourceUrl}
                          setSourceUrl={setSourceUrl}
                          validateSourceUrl={validateSourceUrl}
                          alphaButton={alphaButton}
                          setAlphaButton={setAlphaButton}
                          setShowUrlForm={setShowUrlForm}
                          showUrlForm={showUrlForm}
                          setShowForm={setShowForm}
                          showForm={showForm}
                          setProjectName={setProjectName}
                          setTicker={setTicker}
                          setWebsite={setWebsite}
                          setMarkets={setMarkets}
                          setshowSubmit={setshowSubmit}
                        />
                      )}
                      {/* showing url type selector */}
                      {!validUrl && !showUrlForm && (
                        <LeaksTypeSelector
                          alphaButton={alphaButton}
                          setAlphaButton={setAlphaButton}
                          setShowUrlForm={setShowUrlForm}
                        />
                      )}
                      {/* showing form  */}
                      {validUrl || noToken ? <SubmitForm {...props} /> : null}
                    </div>
                  </div>
                  {showSubmit ? (
                    // <div className="flex-shrink-0 px-4 py-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 justify-between">
                    <button
                      type="button"
                      className={`items-center px-6 py-2  shadow-sm text-sm font-semibold rounded-md mb-2 mx-2 ${
                        theme === 'dark'
                          ? 'bg-blue-700 text-white'
                          : 'bg-blue-700 text-white border border-blue-700'
                      }`}
                      onClick={() => handleSubmit()}
                    >
                      Submit Alpha Leads
                    </button>
                  ) : // </div>
                  null}
                  <NavLink
                    to={`/app/profile/my-alpha-leads`}
                    className={`items-center px-6 py-2  shadow-sm text-sm font-semibold rounded-md text-center mx-2 mb-2  ${
                      theme === 'dark'
                        ? 'bg-gray-800 text-gray-300 border border-gray-300'
                        : 'bg-white text-blue-700 border border-blue-700'
                    }`}
                    onClick={() => setIsLeaksSliderOver(false)}
                  >
                    View Past Submissions
                  </NavLink>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
