import { RefreshIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderBoardData } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { Refresh, Table } from 'Utilities';
import { column } from './header';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function LeaderBoard() {
  const [refresh, setRefresh] = useState(false);
  // useDispatch() instance
  const dispatch = useDispatch();

  // listeing to redux store changes
  const { leaderBoard, loading } = useSelector((s = initialState) => {
    return {
      leaderBoard: s.leaderBoardData.leaderBoard.data,
      loading: s.leaderBoardData.leaderBoard.loading,
    };
  });
  useEffect(() => {
    dispatch(getLeaderBoardData());
  }, [refresh]);
  return (
    <div className="h-full">
      <div className="dark:bg-dark-tabPillBgSelectedDark bg-light-themeSecondary p-4 rounded-tr-md">
        <div className="flex flex-row-reverse">
          <div className="sm:mr-5">
            <Refresh
              state={refresh}
              setState={setRefresh}
              loadingbutton={loading}
            />
          </div>
        </div>
        {leaderBoard && leaderBoard.length > 0 ? (
          <div className="pt-3">
            <Table data={leaderBoard} col={column} loading={loading} />
          </div>
        ) : null}
        {/* </div> */}
      </div>
    </div>
  );
}

export default LeaderBoard;
