import { getUrlParam } from 'Helper/getUrlPram';
import Tooltip from 'Utilities/Tooltip/tooltip';
import PropTypes from 'prop-types';

function CoinName({ isSusbtring, provider, slug, name, logo_url, showLogo }) {
  const redirect_url = `/app/prebuilt/on-chain-analytics/token/details?chain=${getUrlParam(
    'chain'
  )}&tab=Overview&contract_address=${slug}`;

  const renderItem = () => {
    if (name) {
      if (name.length > 18) {
        return <span className="">{name.substring(0, 18) + '..'}</span>;
      } else {
        return <span className="">{name}</span>;
      }
    } else {
      return (
        <span>
          {slug?.substring(0, 6) + '...' + slug?.substring(slug.length - 4)}
        </span>
      );
    }
  };
  return (
    <div>
      <div className="flex space-x-3 items-center">
        <div className="">
          <div className="text-xs text-blue-500 font-semibold">
            <a
              href={redirect_url}
              className="text-right"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip content={name || slug} placement="top">
                {renderItem()}
              </Tooltip>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoinName;

CoinName.propTypes = {
  isSusbtring: PropTypes.bool,
  provider: PropTypes.string,
  slug: PropTypes.string,
  name: PropTypes.string,
  logo_url: PropTypes.string,
  showLogo: PropTypes.bool,
};
