/* eslint-disable react/prop-types */
import {
  ApiPlatformIconDark,
  ApiPlatformIconDarkActive,
  ApiPlatformIconDarkInActive,
  ApiPlatformIconLightActive,
  ApiPlatformIconLightInActive,
  CommunityAlphaIconDarkActive,
  CommunityAlphaIconDarkInActive,
  CommunityAlphaIconLighInActive,
  CommunityAlphaIconLightActive,
  ConfigurationIconDarkActive,
  ConfigurationIconDarkInActive,
  ConfigurationIconLightActive,
  ConfigurationIconLightInActive,
  DataPlatformIconDarkActive,
  DataPlatformIconDarkInActive,
  DataPlatformIconLightActive,
  DataPlatformIconLightInActive,
  MLPlatformIconDarkActive,
  MLPlatformIconDarkInActive,
  MLPlatformIconLightActive,
  MLPlatformIconLightInActive,
  OnChainAnalyticsIconDarkActive,
  OnChainAnalyticsIconDarkInActive,
  OnChainAnalyticsIconLightActive,
  OnChainAnalyticsIconLightInActive,
  WelcomeIconDarkActive,
  WelcomeIconDarkInActive,
  WelcomeIconLightActive,
  WelcomeIconLightInActive,
} from 'Utilities/icons';

function MenuIcon({ slug, theme, isActive }) {
  // const { theme } = useSelector((s = initialState) => {
  //   return {
  //     theme: s.appTheme.theme,
  //   };
  // });

  const pageKey = slug.replace(/-/g, '_');
  const icons = {
    welcome: [
      {
        dark: {
          active: WelcomeIconDarkActive,
          in_active: WelcomeIconDarkInActive,
        },
        light: {
          active: WelcomeIconLightActive,
          in_active: WelcomeIconLightInActive,
        },
      },
    ],
    community_alpha: [
      {
        dark: {
          active: CommunityAlphaIconDarkActive,
          in_active: CommunityAlphaIconDarkInActive,
        },
        light: {
          active: CommunityAlphaIconLightActive,
          in_active: CommunityAlphaIconLighInActive,
        },
      },
    ],
    on_chain_analytics: [
      {
        dark: {
          active: OnChainAnalyticsIconDarkActive,
          in_active: OnChainAnalyticsIconDarkInActive,
        },
        light: {
          active: OnChainAnalyticsIconLightActive,
          in_active: OnChainAnalyticsIconLightInActive,
        },
      },
    ],
    configuration: [
      {
        dark: {
          active: ConfigurationIconDarkActive,
          in_active: ConfigurationIconDarkInActive,
        },
        light: {
          active: ConfigurationIconLightActive,
          in_active: ConfigurationIconLightInActive,
        },
      },
    ],
    data_platform: [
      {
        dark: {
          active: DataPlatformIconDarkActive,
          in_active: DataPlatformIconDarkInActive,
        },
        light: {
          active: DataPlatformIconLightActive,
          in_active: DataPlatformIconLightInActive,
        },
      },
    ],
    ml_platform: [
      {
        dark: {
          active: MLPlatformIconDarkActive,
          in_active: MLPlatformIconDarkInActive,
        },
        light: {
          active: MLPlatformIconLightActive,
          in_active: MLPlatformIconLightInActive,
        },
      },
    ],
    api: [
      {
        dark: {
          active: ApiPlatformIconDarkActive,
          in_active: ApiPlatformIconDarkInActive,
        },
        light: {
          active: ApiPlatformIconLightActive,
          in_active: ApiPlatformIconLightInActive,
        },
      },
    ],
  };

  return (
    <div>
      {theme !== '' && (
        <img
          src={icons[pageKey][0][theme][isActive]}
          className="flex-shrink-0 w-5"
        />
      )}
    </div>
  );
}

export default MenuIcon;
