import { getLoggedUser, getOidcToken } from 'Helper/services';
import { useThemeToggle } from 'Hooks';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { AppContext } from 'Providers/appProvider';
import { Spinner } from 'Utilities';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

function GetOidcToken() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(true);
  const { catalogConf } = useContext(AppContext);

  const { user_address } = useGetCurrentUser();
  console.log(catalogConf);
  const navigate = useNavigate();
  const [colorTheme] = useThemeToggle();
  const getToken = async () => {
    setLoading(true);

    const user = await getLoggedUser(user_address);
    if (user) {
      const clusterId = sessionStorage.getItem('catalog_cluster');
      const body = {
        code: searchParams.get('code'),
        state: searchParams.get('state'),
        cluster: {
          clusterId,
          zone: 'us-central1-a',
          namespace: user.message[0].username_internal,
        },
      };
      const token = await getOidcToken(body);
      const type = sessionStorage.getItem('catalog_type');
      sessionStorage.removeItem('catalog_type');
      sessionStorage.removeItem('catalog_cluster');
      const navigatUrl = `/app/platforms/configuration/user-catalogs/new?type=${type}&step=catalog-config&name=${clusterId}`;
      setLoading(false);
      if (token) {
        navigate(navigatUrl);
      }
    }
    // if (token) {
    //   const isCatalogCreated = await createUserDataCatalog(catalogConf);
    //   if (isCatalogCreated) {
    //     setLoading(false);

    //     navigate('/app/platforms/configuration/user-catalogs');
    //   }
    // } else {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (searchParams) {
      getToken();
    }
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="grid place-content-center h-screen dark:bg-dark-themeSecondary ">
      {isLoading && (
        <div>
          <div className="grid place-content-center">
            <Spinner />
          </div>
          <div className="dark:text-white mt-5 animate-pulse">
            Please wait. This may take a few minutes..
          </div>
        </div>
      )}
    </div>
  );
}

export default GetOidcToken;
