import { ShieldCheckIcon, TableIcon, UserIcon } from '@heroicons/react/outline';
import {
  ConnectionSourcesImage,
  QueryFederationImage,
  UiVizFeature,
} from 'Utilities/images';

export const primaryFeatures = [
  {
    name: 'Community Alpha',
    description:
      'Engage and get rewarded. Give value by sharing info. Get value from crowd wisdom',
    slug: 'community_alpha',
  },
  {
    name: 'On-Chain Analytics',
    description:
      'Get powerful pre-built insights on blockchain data. Spend less time, get most value',
    slug: 'on_chain_analytics',
  },
  {
    name: 'API',
    description:
      'Reliable, Fast, SQL-friendly and Data-Science friendly APIs and multi-language SDKs',
    slug: 'api',
  },
  {
    name: 'Data Platform',
    description:
      'Build your own data insights using our stack of data solutions.',
    slug: 'data_platform',
  },
  {
    name: 'ML Platform',
    description:
      'Build ML models using our data science experimentation environment',
    slug: 'ml_platform',
  },
  {
    name: 'Secure Enclave',
    description:
      'Secure your data and secrets in a dedicated cluster just for you',
    slug: 'security_enclave',
  },
];

export const features2 = [
  {
    name: 'Self-sovereign identity',
    description:
      'Your Keys, Your Identifier! Sign in with Ethereum instead of relying on a traditional intermediary and enjoy total privacy.',
    icon: UserIcon,
    slug: 'self_sovereign_identity',
  },
  {
    name: 'Data Federation',
    description:
      'Securely combine your data that lives externally, with AlphaStack data. No need to move or copy your data to AlphaStack.',
    icon: UserIcon,
    slug: 'data_federation',
  },
  {
    name: 'Peace of mind',
    description:
      'Self-store and self-manage your secrets (connection info to external data) securely in a Vault enabled dedicted cluster.',
    icon: ShieldCheckIcon,
    slug: 'peace_of_mind',
  },
];

export const datafederation_features = [
  {
    name: 'Connect',
    description:
      'Connect to all your data sources, wherever they are. No need to move or copy data to Alphastack. Eliminate data movement delays, costs and security concerns.',
    imageSrc: ConnectionSourcesImage,
    slug: 'connect',
  },
  {
    name: 'Query',
    description:
      'Run queries with a massive parallel processing (MPP) engine, built for analyzing large amounts of distributed data with high concurrency.',
    imageSrc: QueryFederationImage,
    slug: 'query',
  },
  {
    name: 'Build Rich visualizations and Dashboards',
    description:
      'Use the Alphastack-integrated Superset platform to exploreand visualize data, using a wide array of beautifulvisualizations that are available, from simple line chartsto highly detailed geospatial charts. Quickly and easilyintegrate and explore data, using either Superset&apos;s simple no-code viz builder or state of the art SQL IDE.',
    imageSrc: UiVizFeature,
    slug: 'viz',
  },
];

export const highlights = [
  {
    name: 'Query',
    description:
      'Query Blockchain data and securely join it with external sources',
  },
  {
    name: 'Enrich',
    description:
      'External sources include AlphaStack-managed and your self-managed datasets.',
  },
  {
    name: 'Federate',
    description:
      'Join your external data virtually, no need to move or copy it to AlphaStack.',
  },
  {
    name: 'Insights',
    description:
      'Build powerful visualizations and ML models to find unique insights.',
  },
  {
    name: 'Rewards',
    description: 'Share insights and get rewarded.',
  },
  {
    name: 'Privacy',
    description:
      'Ethereum as Identity provider. Never give out any personal info',
  },
];
