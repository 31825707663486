import { markVote } from 'Helper/services';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Etherscan,
  Modal,
  Notification,
  Select,
  Spinner,
  Text,
  Tooltip,
  TrophyIcon,
} from 'Utilities';

import AwardIcon from 'Assets/Images/award.png';
import { SCAN_ADDRESS } from 'Helper/constants';
import DownVoteIcon from 'Utilities/Icons/downVoteIcon';
import UpVoteIcon from 'Utilities/Icons/upVoteIcon';
import SetWinnerPrompt from './Mirco/setWinnerPrompt';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
const filterOptions = [
  {
    text: 'Recent Reponse',
    value: 1,
  },
  {
    text: 'Highest Vote',
    value: 2,
  },
  {
    text: 'Older Response',
    value: 3,
  },
];

ResponseList.propTypes = {
  request_id: PropTypes.number,
  userAddress: PropTypes.string,
  responseList: PropTypes.array,
  setResponseList: PropTypes.func,
  socket: PropTypes.object,
  hasWinner: PropTypes.hasWinner,
  requestDetails: PropTypes.object,
  userVotes: PropTypes.array,
  setUserVotes: PropTypes.func,
  loading: PropTypes.bool,
};
export default function ResponseList({
  request_id,
  socket,
  responseList,
  setResponseList,
  userAddress,
  hasWinner,
  requestDetails,
  userVotes,
  setUserVotes,
  loading,
}) {
  const [filter, setFilter] = useState(1);
  // const [userVotes, setUserVotes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [response, setResponse] = useState([]);
  const [notifyTitle, setNotifyTitle] = useState('');
  const [notifyMessage, setNotifyMessage] = useState('');
  const [notifyType, setShowNotifyType] = useState('');

  const { user_address } = useGetCurrentUser();

  /**
   ** to handle upvote,downvote button click event
   * @param {*} vote_type
   * @param {*} response_id
   * @param {*} key
   * @param {*} currentVote
   * @param {*} userAddress
   */

  const voteResponse = (
    vote_type,
    response_id,
    key,
    currentVote,
    userAddress
  ) => {
    if (user_address == userAddress) {
      setShowNotify(true);
      setNotifyTitle('Cannot accept feedback');
      setNotifyMessage('You cannot vote for your own response');
      setShowNotifyType('warning');
      return 0;
    } else {
      const __updatedCount = responseList.map((item, index) => {
        // index of item clicked compared with index
        if (vote_type == 1) {
          if (index == key) {
            return {
              ...item,
              netvotes:
                currentVote == 1
                  ? parseInt(item.netvotes) - 1
                  : parseInt(item.netvotes) + 1,
            };
          }
          return item;
        }
        if (vote_type == -1) {
          if (index == key) {
            return {
              ...item,
              netvotes:
                currentVote == -1
                  ? parseInt(item.netvotes) + 1
                  : parseInt(item.netvotes) - 1,
            };
          }
          return item;
        }
      });

      // to update userVote type array object.This array restrict double vote
      // TODO: state update on this call is slower and doesn't handle click in quick succession
      updateUserVote(response_id, currentVote == -1 ? 1 : -1);
      setResponseList(__updatedCount);
      const formData = {
        vote_type: currentVote == 0 ? vote_type : 0,
        response_id: response_id,
        user_address: user_address,
        request_id: request_id,
      };

      markVote(formData);
      socket.emit('markVote', formData);
    }
  };

  const updateUserVote = (id, vote_type) => {
    let _d = userVotes.map((item) => {
      if (item?.alpha_response_id == id) {
        return {
          ...item,
          vote_type_id: vote_type,
        };
      }
      return item;
    });
    setUserVotes(_d);
  };

  const currentVote = (response_id) => {
    let _d = userVotes?.filter((x) => {
      if (x?.alpha_response_id == response_id) {
        return x;
      }
    });
    return _d[0]?.vote_type_id;
  };

  const setResponseWinner = (item) => {
    setShowPopup(true);
    setResponse(item);
  };

  useEffect(() => {
    socket.on('winnerRes', function (r) {
      setResponseList(responseList.concat(r));
    });
    socket.on('updatedVote', function (r) {
      setUserVotes(userVotes.concat(r));
    });
  }, [socket]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <Spinner />;
      </div>
    );
  }
  return (
    <div>
      {showNotify && (
        <Notification
          show={showNotify}
          setShow={setShowNotify}
          type={notifyType}
          title={notifyTitle}
          message={notifyMessage}
        />
      )}
      {showPopup && (
        <Modal
          disableBackdropClick={true}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          width={'5/6'}
        >
          <SetWinnerPrompt
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            response={response}
            request_id={request_id}
            responseList={responseList}
            setResponseList={setResponseList}
            socket={socket}
            requestDetails={requestDetails}
          />
        </Modal>
      )}
      <div className="flex justify-between pt-6">
        <Text
          size="sm"
          fontWeight="medium"
          text={`${responseList?.length} answer(s)`}
          className="dark:text-dark-textPrimary"
        />
        <Select
          optionsObj={filterOptions}
          state={filter}
          setState={setFilter}
        />
      </div>
      <ul
        role="list"
        className="divide-y divide-gray-200 dark:divide-gray-700 mt-2"
      >
        {responseList?.map((item, key) => (
          <li
            key={key}
            className={`p-4 ${
              item.is_winner == 'x' && 'bg-yellow-200 dark:bg-yellow-800'
            }`}
          >
            {item.is_winner == 'x' && (
              <div className="flex space-x-3 mb-6">
                <div>
                  <img src={AwardIcon} className="w-12" />
                </div>
                <div className="my-auto font-semibold text-yellow-900 dark:text-yellow-600">
                  Winning Response
                </div>
              </div>
            )}
            <div className="flex space-x-3">
              <ul className="">
                <Tooltip content={`${item.upvotes} Upvote(s)`} placement="top">
                  <li>
                    <button
                      className={`${
                        currentVote(item.id) == '-1' && 'pointer-events-none'
                      }`}
                      onClick={() =>
                        voteResponse(
                          1,
                          item.id,
                          key,
                          currentVote(item.id),
                          item.user_address
                        )
                      }
                    >
                      <UpVoteIcon
                        isActive={currentVote(item.id) == '1' ? true : false}
                      />
                    </button>
                  </li>
                </Tooltip>
                <li>
                  <p className="dark:text-dark-textPrimary ml-1 font-semibold">
                    {item.netvotes}
                  </p>
                </li>
                <Tooltip
                  content={`${item.downvotes} Downvote(s)`}
                  placement="top"
                >
                  <li>
                    <button
                      className={`${
                        currentVote(item.id) == '1' && 'pointer-events-none'
                      }`}
                      onClick={() =>
                        voteResponse(
                          -1,
                          item.id,
                          key,
                          currentVote(item.id),
                          item.user_address
                        )
                      }
                    >
                      <DownVoteIcon
                        isActive={currentVote(item.id) == '-1' ? true : false}
                      />
                    </button>
                  </li>
                </Tooltip>
              </ul>
              <img
                className="h-6 w-6 rounded-full"
                src={
                  'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80'
                }
                alt=""
              />
              <div className="flex-1 space-y-1">
                <div className="flex items-center sm:justify-between">
                  <div className="text-sm text-blue-600 font-semibold flex space-x-2">
                    <Etherscan
                      address={item.user_address}
                      type={SCAN_ADDRESS}
                      isSubString={true}
                    />
                    <div
                      className="cursor-pointer"
                      onClick={() => setResponseWinner(item)}
                    >
                      {userAddress == user_address && hasWinner == 'open' && (
                        <TrophyIcon width="4" height="4" />
                      )}
                    </div>
                  </div>
                  <p className="text-xs text-gray-800 dark:text-dark-textPrimary">
                    {moment.utc(item.submission_timestamp_utc).fromNow()}
                  </p>
                </div>
                <p className="text-sm text-gray-800 dark:text-dark-textPrimary">
                  {item.message}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
