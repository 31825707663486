import { Outlet, useLocation } from 'react-router-dom';
import ProfileTab from './profileTab';
import Rewards from './rewards';

function Profile() {
  const { pathname } = useLocation();
  return (
    <div className="mx-6">
      <div className="flex flex-col">
        <div className="mt-2">
          <>
            <Rewards />
          </>
          <div className="mt-4 flex flex-col lg:flex-row justify-between dark:border-gray-700">
            <ProfileTab path={pathname} />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Profile;
