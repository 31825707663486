import axios from 'axios';

export const getOSData = async (_nft) => {
  let dataSet = [];
  if (_nft) {
    // header options
    const options = {
      method: 'GET',
      headers: { 'X-API-KEY': 'dd2f50e248254e9799b415b92a122f4a' },
    };
    // generating request url
    _nft.map((item) => {
      const url = `https://api.opensea.io/api/v1/asset/${item.contract_address}/${item.token_id}`;
      dataSet.push(getUrlData(url, options));
    });
    // promise handle
    return Promise.all(dataSet);
  }
};

export const getOSContracts = async (_collections) => {
  let dataSet = [];
  if (_collections) {
    // header options
    //FIXME: if options is added in fetch request api call crashes with error code 429
    const options = {
      method: 'GET',
      headers: { 'X-API-KEY': 'dd2f50e248254e9799b415b92a122f4a' },
    };
    // generating request url
    _collections.map((item) => {
      const url = `https://api.opensea.io/api/v1/asset_contract/${item.contract_address}`;
      dataSet.push(getUrlData(url));
    });
    // promise handle
    return Promise.all(dataSet);
  }
};

// export const getTokenRanking = (
//   group,
//   type,
//   filter,
//   hideStandardCoins,
//   limit
// ) => {
//   let dataSet = [];
//   let tradeAction = '';
//   if (type == 'bought') tradeAction = 'buy';
//   if (type == 'sold') tradeAction = 'sell';
//   console.log(group);
//   const url = `/token_ranking?wallet_group=${group}&trade_action=${tradeAction}&sort_key=${filter}&isStandardCoin=${hideStandardCoins}&limit=${limit}&${Date.now()}`;

//   axios.get(url).then((_res) => {
//     return _res.data;
//   });
// };

const getUrlData = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        resolve(data);
      });
  });
};
