import { ClipboardIcon, DuplicateIcon, XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

LocalRunSetUp.propTypes = {
  setShowModal: PropTypes.func,
};
function LocalRunSetUp({ setShowModal }) {
  const [copied, setCopied] = useState(false);
  const resetCopy = useRef();

  const onCopyCode = () => {
    navigator.clipboard.writeText(children).then(() => setCopied(true));
  };

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  useEffect(() => {
    if (copied) {
      resetCopy.current = setTimeout(() => setCopied(false), 2500);
    }

    return () => {
      clearTimeout(resetCopy.current);
    };
  }, [copied]);
  return (
    <div className="p-4">
      <div className="flex justify-between">
        <h1 className="text-md sm:text-lg lg:text-xl my-2 font-semibold">
          Run Pipeline Locally
        </h1>
        <XIcon className="w-4" onClick={() => setShowModal(false)} />
      </div>
      <p className="py-2 text-sm">
        Alphastack uses Open-Source software Airbyte for Pipelines
      </p>
      <p className="py-2 mb-4 text-sm">
        Deploying Airbyte just takes two steps.
      </p>
      <div className="px-6">
        <ol className="list-decimal text-sm">
          <li>
            Install Docker on your workstation (see
            <a
              href="https://www.docker.com/products/docker-desktop/"
              className="text-blue-600 ml-2"
              target="_blank"
              rel="noreferrer"
            >
              instructions
            </a>
            ) . Make sure you&apos;re on the latest version of&nbsp;
            <span
              className={` rounded-lg px-1 ${
                theme === 'dark' ? 'bg-gray-600' : 'bg-gray-200'
              }`}
            >
              docker-compose.
            </span>
          </li>
          <li>Run the following commands in your terminal:</li>
        </ol>
        <div
          className={`${
            theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'
          }  rounded-lg w-auto my-4 max-w-2xl`}
        >
          <div className="flex justify-between items-baseline px-2 py-2">
            <div
              className={`flex flex-col w-full font-mono text-sm p-2 ${
                theme === 'dark' ? 'text-white' : 'text-black'
              }`}
            >
              <div className="">
                <span className="text-red-500">git</span> clone
                https://github.com/airbytehq/airbyte.git
              </div>
              <div>cd airbyte</div>
              <div>
                <span className="text-red-500">docker-compose </span>up
              </div>
            </div>
            <div>
              {copied ? (
                <ClipboardIcon
                  className={`text-black w-5 dark:text-gray-500 ${
                    theme === 'dark' ? 'text-white' : 'text-black'
                  }`}
                />
              ) : (
                <DuplicateIcon
                  className={`w-5 text-black dark:text-gray-500  ${
                    theme === 'dark' ? 'text-white' : 'text-black'
                  }`}
                  onClick={onCopyCode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <p className="mt-4 text-sm">
        Once you see an Airbyte banner, the UI is ready to go at
        http://localhost:8000!
      </p>
      <div className="mt-4 md:mt-8 text-sm">
        Syncs will be created by either:
      </div>
      <div className="px-8 py-6">
        <ul className="list-disc text-sm">
          <li>
            A Manual request (i.e : clicking the &quot;Sync Now&quot; button in
            the UI)
          </li>
          <li>A Schedule</li>
        </ul>
      </div>
      <p className="text-sm">
        When a schedules connection is created, a sync is executed as soon as
        possible. After that, a sync is run once the time since the last sync
        (whether it was triggered manually or due to a schedule) has exceeded
        the schedule interval
      </p>
    </div>
  );
}

export default LocalRunSetUp;
