/* eslint-disable react/prop-types */
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusSmIcon,
} from '@heroicons/react/solid';
import Tippy from '@tippyjs/react';
import React from 'react';

function TokenRanking({ cell }) {
  let currentRank = Number(cell.row.id) + Number(1);
  let prev_rank = cell.data[cell.row.index]?.ranking?.prev_rank;
  let difference = cell.data[cell.row.index].ranking?.prev_rank - currentRank;
  if (difference < 0)
    return (
      <div className="flex space-x-1">
        <Tippy
          content={`previous rank ${prev_rank ? prev_rank : ''}`}
          placement="top"
        >
          <p className="font-semibold mr-2">#{currentRank}</p>
        </Tippy>
        {difference == -1 ? (
          <ChevronDownIcon className="text-red-500 w-5 font-bold mt-.5" />
        ) : (
          <ChevronDoubleDownIcon className="text-red-500 w-5 font-bold mt-.5" />
        )}
      </div>
    );
  if (difference > 0)
    return (
      <div className="flex space-x-1">
        <Tippy
          content={`previous rank ${prev_rank ? prev_rank : ''}`}
          placement="top"
        >
          <p className="font-semibold mr-2">#{currentRank}</p>
        </Tippy>
        {difference == 1 ? (
          <ChevronUpIcon className="text-green-500 w-5 font-bold mt-.5" />
        ) : (
          <ChevronDoubleUpIcon className="text-green-500 w-5 font-bold mt-.5" />
        )}
      </div>
    );
  if (!prev_rank)
    return (
      <div className="flex space-x-1">
        <Tippy content={`New`} placement="top">
          <p className="font-semibold mr-2">#{currentRank}</p>
        </Tippy>
        <PlusSmIcon className="text-yellow-500 w-5 font-bold mt-.5" />
      </div>
    );
  return (
    <div className="flex space-x-1">
      <Tippy content={`New`} placement="top">
        <p className="font-semibold mr-2">#{currentRank}</p>
      </Tippy>
      <PlusSmIcon className="text-yellow-500 w-5 font-bold mt-.5" />
    </div>
  );
}

export default TokenRanking;
