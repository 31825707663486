/* eslint-disable react/prop-types */
import React from 'react';

function Icon({ Name }) {
  return (
    <div>
      <Name />
    </div>
  );
}

export default Icon;
