/* eslint-disable react/prop-types */
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, RefreshIcon, XIcon } from '@heroicons/react/outline';
import { FilterIcon } from '@heroicons/react/solid';
import { colourStyles } from 'Assets/Css/styles';
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import { useLocalStorage } from 'Hooks';
import Refresh from 'Utilities/Refresh/buttonRefresh';
import Search from 'Utilities/Search/search';
import SelectOption from 'Utilities/SelectOption/select';
import Table from 'Utilities/Table/table';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import { getTokenDexTrades } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import {
  token_group,
  token_vol_limit,
  trade_type_options,
} from './Helper/constants';
import { column } from './header';

function LatestDextrades() {
  // accesing url param
  const { search } = useLocation();
  const urlparams = new URLSearchParams(search);
  const contract_address = urlparams.get('contract_address');
  const [group, setGroup] = useLocalStorage('tklt_group', 'all');
  const [volLimit, setVolLimit] = useLocalStorage('tklt_vol_limit', '1');
  const [refresh, setRefresh] = useState(false);
  const [address, setAddress] = useState('');
  const [tradeType, setTradeType] = useState('dex');
  let addressType;
  const selectInputRef = useRef();

  // react select `onChange` event handling start
  const handleChange = (selectedInput) => {
    const selectType = [];
    for (let i in selectedInput) {
      selectType.push(selectedInput[i]['value']);
    }
    setTradeType(selectType);
  };
  // useDispatch() instance
  const dispatch = useDispatch();
  const chainId = CHAINS[getUrlParam('chain')];
  const processTokenDexTrades = (dexTrades) => {
    let dataSet = [];
    if (dexTrades) {
      for (let key of dexTrades) {
        dataSet.push({
          transaction_hash: key['f'][0]['v'],
          name: key['f'][1]['v'],
          contract_address: key['f'][2]['v'],
          dex: key['f'][3]['v'],
          trade_action: key['f'][4]['v'],
          trade_type: key['f'][5]['v'],
          token_amount: key['f'][6]['v'],
          token_value_usd: key['f'][7]['v'],
          timestamp_utc: key['f'][8]['v'],
          token_value_usd_txdate: key['f'][9]['v'],
          using_ethvalue_current: key['f'][10]['v'],
          using_ethvalue_txdate: key['f'][11]['v'],
          trader_address: key['f'][12]['v'],
        });
      }
    }
    return dataSet;
  };
  // listening to store changes
  const { dexTrades, loading } = useSelector((s = initialState) => {
    return {
      dexTrades: processTokenDexTrades(s.tokenDexTrades.dexTrades.data),
      loading: s.tokenDexTrades.loading,
    };
  });
  const getData = () => {
    if (address.toLocaleLowerCase().startsWith('0x')) {
      if (address.length == 42) {
        addressType = 'WALLET_ADDRESS';
      }
      if (address.length > 42 && address.length <= 66) {
        addressType = 'TX_HASH';
      }
    }
    // FIXME: ---Please add the notification to end User, If give data not startWith "ox".
    dispatch(
      getTokenDexTrades(
        group,
        volLimit,
        contract_address,
        address.toLocaleLowerCase(),
        tradeType,
        addressType,
        chainId
      )
    );
  };
  // useeffect hook start
  useEffect(() => {
    // dispatching request
    getData();
  }, [group, volLimit, refresh, tradeType]);
  // useeffect hook end
  // props object passed to search component
  const placeholder = 'Trader Wallet Address (or) TX Hash';
  const props = { address, setAddress, getData, placeholder };
  const ClearIndicator = () => {
    return (
      <div>
        <XIcon
          className={`h-3 w-4 mx-2 dark:text-dark-textPrimary cursor-pointer`}
          onClick={() => setTradeType([])}
        />
      </div>
    );
  };

  // dropdownIndicator controller - block chain select control
  const DropdownIndicator = () => {
    return (
      <div>
        <ChevronDownIcon
          className={`h-3 w-4 mx-2 dark:text-dark-textPrimary cursor-pointer`}
        />
      </div>
    );
  };

  const MultiValueRemove = (props) => {
    if (props.data.isFixed) {
      return null;
    }
    return (
      <components.MultiValueRemove {...props}>
        <XIcon className={`h-3 w-3 mx-1 cursor-pointer`} />
      </components.MultiValueRemove>
    );
  };
  return (
    <div className="dark:bg-dark-themePrimary bg-light-themeSecondary">
      <div className="rounded-lg h-full">
        {/* sort, filter default view */}
        <div className="hidden sm:block">
          <div className="flow-root mx-2">
            <div className="flex justify-between">
              <div className="flex flex-wrap mb-2 space-x-1 lg:mt-2">
                <div className="p-2 my-1 bg-white border dark:bg-dark-bgMutedDark text-gray-500 dark:border-gray-800 dark:text-dark-textPrimary rounded-md flex justify-center items-center">
                  <FilterIcon className="h-4 w-4" />
                </div>
                <div className="mx-2">
                  <SelectOption
                    optionsObj={token_group}
                    state={group}
                    setState={setGroup}
                  />
                </div>
                <div className="mx-2">
                  <SelectOption
                    optionsObj={token_vol_limit}
                    state={volLimit}
                    setState={setVolLimit}
                  />
                </div>
                <div className="flex">
                  <SelectOption
                    optionsObj={trade_type_options}
                    state={tradeType}
                    setState={setTradeType}
                  />
                </div>
                <Search {...props} />
              </div>
              <div className="mx-2 lg:mt-2">
                <div className="flex justify-between">
                  {/* <RefreshIcon
                    className={`w-5 h-5 text-gray-400 mt-2 cursor-pointer mouse-pointer `}
                    onClick={() => {
                      setRefresh(!refresh);
                    }}
                  /> */}
                  <Refresh
                    state={refresh}
                    setState={setRefresh}
                    loadingbutton={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* sort, filter responsive view */}
        <div className="sm:hidden block pt-4 mx-2">
          <Search {...props} />
          <div className="flex flex-row-reverse mt-2">
            <div>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                    <span className="sr-only">Open options</span>
                    <FilterIcon className="h-4 w-4" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-dark-bgMutedDark ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1 px-2">
                      <div className="flow-root">
                        <div className="flex justify-between">
                          <div className="flex flex-wrap">
                            <div className="my-1">
                              <SelectOption
                                optionsObj={token_group}
                                state={group}
                                setState={setGroup}
                              />
                            </div>
                            <div className="my-1">
                              <SelectOption
                                optionsObj={token_vol_limit}
                                state={volLimit}
                                setState={setVolLimit}
                              />
                            </div>
                            <div className="flex">
                              <SelectOption
                                optionsObj={trade_type_options}
                                state={tradeType}
                                setState={setTradeType}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="mx-2">
              <button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                <span className="sr-only">refresh Option</span>
                <RefreshIcon
                  className={`w-4 h-4 text-gray-400 cursor-pointer mouse-pointer `}
                  onClick={() => {
                    setRefresh(!refresh);
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        {/* <div className="shadow overflow-hidden  border-b border-gray-200 overflow-y-scroll flex-1 h-102 metric-scroll overflow-x-auto dark:border-dark-secondary"> */}
        <Table data={dexTrades} col={column} isloading={loading} />
        {/* </div> */}
      </div>
    </div>
  );
}

export default LatestDextrades;
