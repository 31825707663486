import { PropTypes } from 'prop-types';

import FeaturesIcon from 'Utilities/Icons/LandingPageIcons/featuresIcon';

FeatureCard.propTypes = {
  feature: PropTypes.object,
};

function FeatureCard({ feature }) {
  return (
    <div className="relative flex space-x-3 lg:space-x-6 rounded-lg px-6 py-5">
      <div className="flex-shrink-0">
        <FeaturesIcon featureId={feature.slug} />
      </div>
      <div className="min-w-0 flex-1">
        <div href="#" className="focus:outline-none">
          <p className="text-lg sm:text-xl font-semibold text-featureTitleLight dark:text-featureTitleDark  text-left">
            {feature.name}
          </p>
          <p className="text-sm sm:text-base text-featureDescLight dark:text-featureDescDark text-left mt-2">
            {feature.description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default FeatureCard;
