import {
  CheckCircleIcon,
  StopIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useAuth } from 'Authentication/authProvider';
import { log } from 'Helper/log';
import { getAlphaRequests, markView } from 'Helper/services';
import { useLocalStorage } from 'Hooks';
import jwtDecode from 'jwt-decode';
import JwtDecode from 'jwt-decode';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useMatch } from 'react-router-dom';
import { Modal, Notification, Spinner, Text, Tooltip } from 'Utilities';
import DeletRequest from './deletRequest';
import Filters from './filters';

LatestRequests.propTypes = {
  toggleContent: PropTypes.bool,
  setToggleContent: PropTypes.func,
  requestDetails: PropTypes.array,
  setRequestDeatils: PropTypes.func,
  hasWinner: PropTypes.bool,
  setHasWinner: PropTypes.func,
  isUser: PropTypes.bool,
};
export default function LatestRequests({
  toggleContent,
  setToggleContent,
  setRequestDeatils,
  setHasWinner,
  isUser,
}) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [duration, setDuration] = useLocalStorage('ar_duration', null);
  const [status, setStatus] = useLocalStorage('ar_status', null);
  const [reward, setReward] = useLocalStorage('ar_reward', null);
  const [commentedStat, setCommentedStat] = useLocalStorage(
    'ar_commentedStat',
    null
  );

  const [category, setCategory] = useLocalStorage('ar_category', null);
  const [alphaRequests, setAlphaRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasFilter, setHasFilter] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [alphaRequest, setAlphaRequest] = useState([]);
  const [showNotify, setShowNotify] = useState(false);

  let user_address = null;
  const auth = useAuth();
  const localStorageUser = auth.getLocalStorageUser();
  if (localStorageUser === null || localStorageUser === undefined) {
    //do nothing
  } else {
    if (localStorageUser.length === 0) {
      //do nothing
    } else {
      user_address = jwtDecode(localStorageUser['id_token'])[
        'preferred_username'
      ];
    }
  }

  const getAlphaRequestDetails = (id) => {
    let singleRequest = alphaRequests.filter((item) => {
      if (item.id == id) return item;
    });
    setRequestDeatils(singleRequest);
    setToggleContent(!toggleContent);
    markView({ alpha_request_id: id });
  };

  const getLatestRequests = () => {
    setLoading(true);
    getAlphaRequests(
      search,
      duration,
      status,
      reward,
      commentedStat,
      category,
      isUser,
      user_address
    )
      .then((res) => {
        // log(res);
        setAlphaRequests(res.response);
      })
      .catch((err) => {
        log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletReqest = (item) => {
    setShowPopup(true);
    setAlphaRequest(item);
  };

  const clearFilter = () => {
    setDuration(null);
    setStatus(null);
    setReward(null);
    setCommentedStat(null);
    setCategory(null);
    setHasFilter(false);
  };

  const clearSearch = () => {
    setSearch('');
    getLatestRequests();
  };

  useEffect(() => {
    if (
      duration != null ||
      reward != null ||
      category != null ||
      status != null ||
      commentedStat
    ) {
      setHasFilter(true);
    }
    getLatestRequests();
  }, [duration, reward, category, status, commentedStat]);

  const filterProps = {
    duration: duration,
    setDuration: setDuration,
    status: status,
    setStatus: setStatus,
    reward: reward,
    setReward: setReward,
    commentedStat: commentedStat,
    setCommentedStat: setCommentedStat,
    category: category,
    setCategory: setCategory,
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <Spinner />;
      </div>
    );
  }

  return (
    <div>
      {showPopup && (
        <Modal
          disableBackdropClick={true}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          width={'5/12'}
        >
          <DeletRequest
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            alphaRequests={alphaRequests}
            setAlphaRequests={setAlphaRequests}
            alphaRequest={alphaRequest}
            setShowNotify={setShowNotify}
          />
        </Modal>
      )}
      {showNotify && (
        <Notification
          show={showNotify}
          setShow={setShowNotify}
          type="warning"
          title="Not Allowed"
          message="You cannot delete this request"
        />
      )}
      <div className="mt-4 flex rounded-md">
        <div className="flex items-stretch  flex-grow lg:flex-grow-0 w-full focus-within:z-10 relative">
          <input
            type="text"
            value={search}
            className="border-l border-t border-b border-primary text-xs sm:text-md w-full transition px-4 h-9 rounded-l-md placeholder:italic placeholder:text-gray-400 focus:outline-none dark:text-dark-textPrimary bg-light-themePrimary dark:bg-dark-themeBgDark dark:border-gray-600"
            placeholder={'Search Alpha Request'}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                getLatestRequests();
              }
            }}
          />
          <div
            className={`absolute inset-y-0 right-0 pr-3  ${
              search.length > 0 ? 'flex items-center' : 'hidden'
            }`}
          >
            <XIcon
              className="h-5 w-5 text-gray-400 cursor-pointer"
              aria-hidden="true"
              onClick={clearSearch}
            />
          </div>
        </div>
        <button
          type="button"
          className={`items-center px-3 py-2 border-r border-t border-b border-blue-700 shadow-sm text-sm leading-4 font-semibold rounded-r-md  bg-blue-600 text-white  dark:border-gray-700 ${
            search.length > 0 ? '' : 'pointer-events-none'
          }`}
          onClick={() => getLatestRequests()}
        >
          <span>Go</span>
        </button>
      </div>
      <div className="flex justify-between">
        <div className="mt-4">
          <Filters {...filterProps} />
        </div>
        <div
          className={`mt-6  ${
            hasFilter ? 'inline-flex items-center' : 'hidden'
          }`}
        >
          <p
            className="text-blue-600 text-sm hover:underline cursor-pointer"
            onClick={clearFilter}
          >
            Clear filters
          </p>
          <XIcon className="w-3 text-blue-600" />
        </div>
      </div>
      <div className="overflow-x-auto">
        <div
          className="inline-block min-w-full mt-6 align-middle px-2 overflow-x-auto metric-scroll"
          id="sticky"
        >
          <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0"
                >
                  Question
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary"
                >
                  Reward
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary"
                >
                  Metrics
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary"
                >
                  Details
                </th>
                <th
                  scope="col"
                  className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {alphaRequests &&
                alphaRequests?.map((item, key) => (
                  <tr key={key}>
                    <td className="py-4 pl-4 pr-3 text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0">
                      <div className="flex space-x-2">
                        <div>
                          <Tooltip content={item.status} placement="right">
                            <div>
                              {item.status == 'open' ? (
                                <StopIcon className="text-green-500 w-5 h-5 mt-1" />
                              ) : (
                                <CheckCircleIcon className="text-purple-500 w-5 h-5 mt-1" />
                              )}
                            </div>
                          </Tooltip>
                        </div>
                        <div>
                          <Link
                            className="text-blue-600 text-md font-medium capitalize cursor-pointer"
                            to={`${item.id}`}
                            onClick={() => {
                              getAlphaRequestDetails(item.id);
                              setHasWinner(item.status);
                            }}
                          >
                            {item.title}
                          </Link>
                          <div className="font-extralight text-xs">
                            {item.description}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-dark-textPrimary  sm:pl-6 md:pl-0">
                      {item.reward_amount.slice(0, 4) +
                        ' ' +
                        item.reward_currency_symbol}
                    </td>
                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                      <div className="text-sm font-medium dark:text-dark-textPrimary">
                        {`${item.replies} answers`}
                      </div>
                      <div className="text-sm font-medium dark:text-dark-textPrimary">
                        {`${item.views} views`}
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-4 px-3 text-gray-500">
                      <div className="text-blue-600 text-md font-semibold inline-flex">
                        <Text
                          size="sm"
                          color="blue-600"
                          text={item.user_address.slice(0, 10)}
                          font-weight="semibold"
                        />
                      </div>
                      <div className="text-sm font-normal dark:text-dark-textPrimary">
                        <time
                          className="text-sm dark:text-dark-textPrimary"
                          dateTime={moment
                            .utc(item.submission_timestamp_utc)
                            .fromNow()}
                        >
                          {moment.utc(item.submission_timestamp_utc).fromNow()}
                        </time>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 dark:text-dark-textPrimary  sm:pl-6 md:pl-0">
                      <Tooltip
                        content={
                          item.deletable == 'x'
                            ? `Delete request`
                            : `Not allowed to delete`
                        }
                        placement="left"
                      >
                        <div className="">
                          <TrashIcon
                            className={`w-4 ${
                              item.user_address == user_address
                                ? `block cursor-pointer ${
                                    item.deletable == 'x'
                                      ? 'text-white cursor-pointer'
                                      : 'text-gray-600 pointer-events-none cursor-not-allowed'
                                  }`
                                : 'hidden text-gray-600'
                            }`}
                            onClick={() => deletReqest(item)}
                          />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
