/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

function Score(props) {
  // eslint-disable-next-line react/prop-types
  const { level } = props;
  return (
    <div>
      <div
        className={`w-4 h-4 rounded-full ${
          level === 'high'
            ? 'bg-green-500'
            : level === 'medium'
            ? 'bg-yellow-500'
            : level === 'low'
            ? 'bg-red-500'
            : null
        }`}
      />
    </div>
  );
}

export default Score;
