import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';

// Cuctom RPC URLs
const RPC_URLS = {
  1: 'https://eth-mainnet.alchemyapi.io/v2/_Gows5gJhyc4WXdsrYNhdXxNotaKMF6U',
};

// wallet connect object
export const walletConnectConnector = new WalletConnectConnector({
  rpc: {
    1: RPC_URLS[1],
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 120000,
});

// injected connected object
export const injected = new InjectedConnector({
  supportedChainIds: [
    1, // Mainnet
    3, // Ropsten
    4, // Rinkeby
    5, // Goerli
    42, // Kovan
  ],
});

// wallet connect modal sometime wont't show up after first render. Below code will fix this
export function resetWalletConnector(connector) {
  if (connector && connector instanceof WalletConnectConnector) {
    connector.walletConnectProvider = undefined;
  }
}

export const currentConnectedWallet = async () => {
  const eth = window.ethereum;
  if (window.ethereum) {
    try {
      const addressLst = await eth.request({
        method: 'eth_accounts',
      });
      if (addressLst.length > 0) {
        return { address: addressLst[0], isActive: true };
      } else {
        return { address: '', isActive: false };
      }
    } catch (e) {
      return { address: '', isActive: false };
    }
  }
  return { address: '', isActive: false };
};

export const disconnectEth = async () => {
  const eth = window.ethereum;
  if (window.ethereum) {
    try {
      const addressLst = await eth.request({
        method: 'eth_accounts',
      });
      if (addressLst.length > 0) {
        addressLst[0] = '';
      } else {
        return { address: '', isActive: false };
      }
    } catch (e) {
      return { address: '', isActive: false };
    }
  }
  return { address: '', isActive: false };
};
