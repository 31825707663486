/* eslint-disable react/prop-types */
import { Dialog, Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ShieldExclamationIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Select, { components } from 'react-select';
import { initialState } from 'statemgmt/initialState';
import { colourStyles } from '../../../../Assets/Css/styles';
import close from '../../../../Assets/Images/close.png';
import {
  getBlockChain,
  getRecenetWalletSubmissions,
  postWalletForm,
} from '../../../../statemgmt/actions';
export default function WalletFormSlider({
  isWalletSliderOver,
  setIsWalletSliderOver,
}) {
  // variable declaration start
  // const user_address = window.ethereum.selectedAddress;
  // const user_address =
  //   localStorage.getItem('currentAddress') || window.ethereum.selectedAddress;
  // useRef() instance
  const selectInputRef = useRef();

  const { user_address } = useGetCurrentUser();

  // state initialization start
  const [required, setRequired] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [blockchain, setBlockChain] = useState(null);
  const [label, setLabel] = useState([]);
  const [walletaddress, setWalletAddress] = useState();
  const [research, setResearch] = useState();
  // state initialization end

  // useDispatch() instance
  const dispatch = useDispatch();
  // close alert method
  const closeAlert = () => {
    setIsSubmitted(false);
    setRequired(false);
  };
  // tag methods start
  // add tag start
  const addTag = (event, name) => {
    if (name === 'label') {
      if (event.key === 'Enter' && event.target.value !== '') {
        setLabel([...label, event.target.value]);
        event.target.value = '';
      }
    }
  };
  // add tag end
  // remove tag start
  const removeTags = (index, name) => {
    if (name === 'label') {
      setLabel([...label.filter((tag) => label.indexOf(tag) !== index)]);
    }
  };
  // remove tag end
  // tag methods end

  /* form submissions method start */
  const handleSubmit = () => {
    // if form submitted without entering the required fields
    if (!blockchain || !label || !walletaddress || !research) {
      setRequired(true);
      return 0;
    }
    const submitData = {
      user_address: user_address,
      wallet_address: walletaddress,
      blockchains_json: blockchain,
      labels_json: label,
      research: research,
    };

    // dispatching post request
    dispatch(postWalletForm(submitData));

    /* dispatching get request to to trigger recent 
  submissions api after submitting form */
    dispatch(getRecenetWalletSubmissions(user_address));
    // disabling required field state
    setRequired(false);
    setIsSubmitted(true);
    // resetting form fields
    setLabel([]);
    setBlockChain([]);
    setResearch('');
    setWalletAddress('');
    selectInputRef.current.clearValue();
  };
  /* form submissions method end */

  // react select `onChange` event handling start
  const handleChange = (selectedInput) => {
    setBlockChain(selectedInput);
  };
  // react select `onChange` event handling end

  const processOptions = (blockChain) => {
    const options = [];
    if (blockChain) {
      for (let i in blockChain.data) {
        options.push({
          value: blockChain.data[i].name,
          label: blockChain.data[i].name,
        });
      }
    }
    return options;
  };
  // listen to store changes
  const { blockChain, theme } = useSelector((s = initialState) => {
    return {
      blockChain: processOptions(s.blockChainData.blockChain),
      theme: s.appTheme.theme,
    };
  });

  // clear seleted option controller - block chain select control
  const ClearIndicator = () => {
    return (
      <div>
        <XIcon
          className={`h-3 w-4 mx-2 dark:text-dark-textPrimary cursor-pointer  ${
            theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
          }`}
          onClick={() => setBlockChain([])}
        />
      </div>
    );
  };

  // dropdownIndicator controller - block chain select control
  const DropdownIndicator = () => {
    return (
      <div>
        <ChevronDownIcon
          className={`h-3 w-4 mx-2 dark:text-dark-textPrimary cursor-pointer  ${
            theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
          }`}
        />
      </div>
    );
  };

  // const MultiValueRemove = (props) => {
  //   return (
  //     <div className="m-auto">
  //       <XIcon
  //         className={`h-4 w-3 mx-1 cursor-pointer ${
  //           theme === 'dark' ? 'text-gray-700' : 'text-gray-700'
  //         }`}
  //         onClick={(e) => console.log(props)}
  //       />
  //     </div>
  //   );
  // };
  const MultiValueRemove = (props) => {
    if (props.data.isFixed) {
      return null;
    }
    return (
      <components.MultiValueRemove {...props}>
        <XIcon
          className={`h-3 w-3 mx-1 cursor-pointer ${
            theme === 'dark' ? 'text-gray-700' : 'text-gray-700'
          }`}
        />
      </components.MultiValueRemove>
    );
  };
  useEffect(() => {
    if (!(blockChain && blockChain.length > 0)) {
      dispatch(getBlockChain());
    }
  }, []);
  return (
    <Transition.Root show={isWalletSliderOver} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={setIsWalletSliderOver}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed z-50 inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div
                  className={`h-full flex flex-col bg-white  ${
                    theme === 'dark' ? 'bg-gray-800' : 'bg-white'
                  }`}
                >
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div
                      className={`py-6 px-4 sm:px-6 border-b ${
                        theme === 'dark' ? 'border-b border-gray-700' : ''
                      }`}
                    >
                      <div className="flex items-center justify-between">
                        <Dialog.Title
                          className={`text-xl font-semibold  dark:text-white ${
                            theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                          }`}
                        >
                          Submit Wallet Labels
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="rounded-md text-indigo-200"
                            onClick={() => setIsWalletSliderOver(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1 flex">
                        <span className="">
                          {' '}
                          <ShieldExclamationIcon className="w-4 h-4 mt-0.5 mr-1 text-red-600" />
                        </span>
                        <p
                          className={`text-sm  ${
                            theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
                          }`}
                        >
                          Only submit labels that you&apos;re comfortable
                          sharing with the world. Labels that expose a
                          person&apos;s identity beyond their own exposure (e.g.
                          ENS name) or what is publicly known information are
                          not tolerated, and will be removed.
                        </p>
                      </div>
                    </div>
                    <div className="p-5">
                      {/* ALERT START */}
                      {isSubmitted ? (
                        <div>
                          <div className="rounded-md bg-green-50 p-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <CheckCircleIcon
                                  className="h-5 w-5 text-green-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-sm font-medium text-green-800">
                                  All Done! Kudos{' '}
                                </p>
                              </div>
                              <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                  <button
                                    type="button"
                                    className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 "
                                  >
                                    <span className="sr-only">Dismiss</span>
                                    <XIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                      onClick={closeAlert}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {required ? (
                        <div>
                          <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <XCircleIcon
                                  className="h-5 w-5 text-red-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-sm font-medium text-red-800">
                                  Uh-oh! you missed some fields
                                </p>
                              </div>
                              <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                  <button
                                    type="button"
                                    className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100"
                                  >
                                    <span className="sr-only">Dismiss</span>
                                    <XIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                      onClick={closeAlert}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* ALERT END */}
                      <div className="grid grid-cols-6 sm:gap-3 md:gap-3">
                        <div className="col-span-6 mb-3">
                          <label
                            htmlFor="user_address"
                            className={`block text-sm text-left font-medium   mb-1 ${
                              theme === 'dark'
                                ? 'text-gray-300'
                                : 'text-gray-700'
                            }`}
                          >
                            Wallet Address{' '}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            value={walletaddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                            className={`rounded py-3 px-3 w-full shadow border text-sm placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                              theme === 'dark'
                                ? 'bg-gray-700 text-gray-300 border-gray-700'
                                : 'bg-white text-gray-700'
                            }`}
                            placeholder=" Eg: 0x0F4ee9631f4be0a63756515141281A3E2B293Bbe"
                          />
                        </div>
                        <div className="col-span-6">
                          <label
                            htmlFor="street_address"
                            className={`block text-sm text-left font-medium   mb-1 ${
                              theme === 'dark'
                                ? 'text-gray-300'
                                : 'text-gray-700'
                            }`}
                          >
                            BlockChains<span className="text-red-500">*</span>
                          </label>
                          <div className="h-auto">
                            <Select
                              closeMenuOnSelect={false}
                              isMulti
                              value={blockchain}
                              options={blockChain}
                              styles={colourStyles(true)}
                              onChange={handleChange}
                              ref={selectInputRef}
                              components={{
                                IndicatorSeparator: () => null,
                                ClearIndicator,
                                DropdownIndicator,
                                MultiValueRemove,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-6 mb-3">
                          <label
                            htmlFor="labels"
                            className={`block text-sm text-left font-medium   mb-1 ${
                              theme === 'dark'
                                ? 'text-gray-300'
                                : 'text-gray-700'
                            }`}
                          >
                            Labels <span className="text-red-500">*</span>
                          </label>
                          <ul className="">
                            {label.map((tag, index) => (
                              <li key={index} className="broker-tag ">
                                <span className="">{tag}</span>
                                <img
                                  className="cursor-pointer inline-block"
                                  src={close}
                                  width="16"
                                  alt=""
                                  onClick={() => removeTags(index, 'label')}
                                />
                              </li>
                            ))}
                          </ul>
                          <input
                            type="text"
                            className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                              theme === 'dark'
                                ? 'bg-gray-700 text-gray-300 border-gray-700'
                                : 'bg-white text-gray-700'
                            }`}
                            onKeyUp={(event) => addTag(event, 'label')}
                            title="Hit enter after each label"
                            placeholder=" Eg: VC , Fund"
                          />
                        </div>
                        <div className="col-span-6 mb-3">
                          <label
                            htmlFor="street_address"
                            className={`block text-sm text-left font-medium   mb-1 ${
                              theme === 'dark'
                                ? 'text-gray-300'
                                : 'text-gray-700'
                            }`}
                          >
                            Research <span className="text-red-500">*</span>
                          </label>
                          <textarea
                            cols={10}
                            value={research}
                            onChange={(e) => setResearch(e.target.value)}
                            className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                              theme === 'dark'
                                ? 'bg-gray-700 text-gray-300 border-gray-700'
                                : 'bg-white text-gray-700'
                            }`}
                            placeholder=" Eg: From my own research"
                          />
                        </div>
                        <div className="col-span-6 mb-3">
                          <div className="text-gray-400  text-xs text-left">
                            Marked fields(*) are mandatory
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="px-4 py-4 flex flex-col sm:flex-row space-y-2 sm:space-y-0 justify-between"> */}
                  <button
                    type="submit"
                    className={`items-center px-5 py-2 shadow-sm text-sm font-semibold rounded-md mx-2 my-2 ${
                      theme === 'dark'
                        ? 'bg-blue-700 text-white'
                        : 'bg-blue-700 text-white border border-blue-700'
                    }`}
                    onClick={() => handleSubmit()}
                  >
                    Submit Wallet Labels
                  </button>
                  <NavLink
                    to="/app/profile/my-wallet-submissions"
                    className={`items-center px-6 py-2  shadow-sm text-sm font-semibold rounded-md text-center mx-2 mb-2  ${
                      theme === 'dark'
                        ? 'bg-gray-800 text-gray-300 border border-gray-600'
                        : 'bg-white text-blue-700 border border-blue-700'
                    }`}
                    onClick={() => setIsWalletSliderOver(false)}
                  >
                    View Past Submissions
                  </NavLink>
                  {/* </div> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
