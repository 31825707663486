export const groupByKey = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const arrayFromJsonGroupByKey = (arr) => {
  return Object.keys(arr[0]).map((key) => {
    let o = {};
    o[key] = arr.map((x) => x[key]);
    return o;
  });
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const naiveRound = (num, decimalPlaces = 0) => {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
};

export const roundValue = (num) => {
  const digits = num.substring(0, 5);
  const arr = digits.split('');
  if (arr[4] >= 5) {
    return parseInt(digits.substring(0, 4)) + 1;
  } else {
    return digits.substring(0, 4);
  }
};

export const split = (str, index) => {
  const result = [str.slice(0, index), str.slice(index)];

  return result;
};
