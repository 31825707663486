/* eslint-disable react/prop-types */
import { Menu, Transition } from '@headlessui/react';
import { FilterIcon, RefreshIcon } from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { useLocalStorage } from 'Hooks';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopNftTrades } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import { ContentWrapper } from 'Utilities';
import Refresh from 'Utilities/Refresh/buttonRefresh';
import SelectOption from 'Utilities/SelectOption/select';
import Table from 'Utilities/Table/table';
import {
  token_duration,
  token_group,
  token_limit,
  token_type,
} from './Helper/constants';
import useHeaders from './Helper/header';
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
function NftTrades(props) {
  // to fire redux actions (which emit signals)
  // state initialization start
  // for setting progress bar max value
  const [limit, setLimit] = useLocalStorage('nft_toptrades_limit', 20);
  const [group, setGroup] = useLocalStorage('nft_toptrades_group', 'whales');
  const [type, setType] = useLocalStorage('nft_toptrades_type', 'bought');
  const [duration, setDuration] = useLocalStorage(
    'nft_toptrades_duration',
    '24hr'
  );

  const [refresh, setRefresh] = useState(false);

  // custom hook for managing header/column
  const [column, setShowValue, showValue] = useHeaders();
  // state initialization end

  // useDispatch() instance
  const dispatch = useDispatch();
  const block_chain = CHAINS[getUrlParam('chain')];

  // subscrbing to redux store
  const { nftTopTrades, loading } = useSelector((s = initialState) => {
    return {
      nftTopTrades: s.nftTopTradesData.nftTopTrades,
      loading: s.nftTopTradesData.loading,
      // block_chain: s.currentBlockChain.selectedBlockChain,
    };
  });

  useEffect(() => {
    dispatch(getTopNftTrades(block_chain, limit, group, type, duration));
  }, [block_chain, limit, group, type, duration, refresh]);

  if (block_chain == 1) {
    return (
      <>
        <ContentWrapper>
          <div className="flex justify-center items-center h-96">
            <h1 className="text-2xl sm:text-4xl lg:text-5xl text-gray-500 dark:text-gray-700 font-mono italic">
              Coming Soon...
            </h1>
          </div>
        </ContentWrapper>
      </>
    );
  }

  return (
    <div className="h-full">
      {/* dropdown start */}
      <div className="dark:bg-dark-themePrimary bg-light-themeSecondary mx-4 p-4 rounded-tr-md">
        <div className="hidden sm:block">
          <div className="flow-root mt-2">
            <div className="flex justify-between mb-2">
              <div className="flex flex-wrap float-left">
                <div className="flex flex-wrap space-x-1 mr-1 mb-2">
                  <div>
                    <SelectOption
                      optionsObj={token_limit}
                      state={limit}
                      setState={setLimit}
                    />
                  </div>

                  <div>
                    <SelectOption
                      optionsObj={token_group}
                      state={group}
                      setState={setGroup}
                    />
                  </div>
                  <div>
                    <SelectOption
                      optionsObj={token_type}
                      state={type}
                      setState={setType}
                    />
                  </div>
                  <div>
                    <SelectOption
                      optionsObj={token_duration}
                      state={duration}
                      setState={setDuration}
                    />
                  </div>
                </div>
              </div>
              <div className="mx-2 my-1 flex space-x-2">
                <div className="mx-2 ">
                  <div className="flex justify-between mt-2">
                    <Refresh
                      state={refresh}
                      setState={setRefresh}
                      loadingbutton={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* filter, sort  options mobile view */}
        <div className="sm:hidden block mt-2">
          <div className="flex justify-between">
            <div className="flex space-x-2">
              <div>
                <button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                  <span className="sr-only">refresh Option</span>
                  <RefreshIcon
                    className={`w-4 h-4 text-gray-400 cursor-pointer mouse-pointer `}
                    onClick={() => {
                      setRefresh(!refresh);
                    }}
                  />
                </button>
              </div>
              <div>
                <Menu as="div" className="inline-block text-left">
                  <div>
                    <Menu.Button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                      <span className="sr-only">Open options</span>
                      <FilterIcon className="h-4 w-4" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-dark-bgMutedDark ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1 px-2">
                        <div className="flow-root mt-2">
                          <div className="flex justify-between mb-2">
                            <div className="flex flex-wrap float-left">
                              <div className="flex flex-wrap space-x-1 mb-1 border-b dark:border-gray-600 sm:border-none">
                                <div>
                                  <SelectOption
                                    optionsObj={token_limit}
                                    state={limit}
                                    setState={setLimit}
                                  />
                                </div>

                                <div>
                                  <SelectOption
                                    optionsObj={token_group}
                                    state={group}
                                    setState={setGroup}
                                  />
                                </div>
                                <div>
                                  <SelectOption
                                    optionsObj={token_type}
                                    state={type}
                                    setState={setType}
                                  />
                                </div>
                                <div>
                                  <SelectOption
                                    optionsObj={token_duration}
                                    state={duration}
                                    setState={setDuration}
                                  />
                                </div>
                              </div>

                              <div className="mt-2">
                                <Tippy
                                  content={
                                    'Toggle between actual value and percentage scores'
                                  }
                                  placement="top"
                                >
                                  <p
                                    className="inline-flex items-center px-2.5 py-1.5 shadow-sm text-xs font-medium rounded text-gray-700  dark:text-dark-textPrimary dark:border-gray-700 cursor-pointer"
                                    onClick={() => {
                                      setShowValue(!showValue);
                                    }}
                                  >
                                    {showValue
                                      ? 'Hide percentage scores'
                                      : 'Show percentage scores'}
                                  </p>
                                </Tippy>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        {/* filter, sort  options mobile view */}
      </div>
      {/* dropdown end */}
      {/* table start */}
      <div className="px-4">
        <Table data={nftTopTrades} col={column} isloading={loading} />
      </div>

      {/* table end */}
    </div>
  );
}

export default NftTrades;
