import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { LinkIcon, VideoCameraIcon } from '@heroicons/react/solid';
import {
  CRPTO_PANIC_POST_FILTER,
  CRPTO_PANIC_POST_KIND,
} from 'Helper/constants';
import { getNewsData } from 'Helper/services';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Spinner from 'Utilities/Loaders/spinner';
import Select from 'Utilities/SelectOption/select';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';

export default function News() {
  const [news, setNews] = useState([]);
  const [kind, setKind] = useState('All');
  const [filter, setFilter] = useState('All');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [currency, setCurrency] = useState('');
  const getNews = (_page) => {
    setLoading(true);
    getNewsData(kind, filter, _page, currency)
      .then((res) => {
        setNews(res?.results);
        if (res.next != null) {
          let nextUrl = new URL(res.next);
          setPage(nextUrl.searchParams.get('page') - 1);
        } else {
          setPage(null);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getNews(page);
  }, [kind, filter, currency]);

  if (loading) {
    return (
      <>
        <div
          className={`overflow-hidden flex-1 bg-light-themePrimary dark:bg-dark-themeSecondary h-96 flex `}
        >
          <div className="m-auto">
            <Spinner />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="bg-light-themePrimary dark:bg-dark-themeSecondary rounded-tr-md p-8">
        <div className="p-2 flex justify-between">
          <>
            <div className="flex">
              <div className="mr-2">
                <Select
                  optionsObj={CRPTO_PANIC_POST_KIND}
                  state={kind}
                  setState={setKind}
                />
              </div>
              <div className="mr-2">
                <Select
                  optionsObj={CRPTO_PANIC_POST_FILTER}
                  state={filter}
                  setState={setFilter}
                />
              </div>
              <div
                className={`my-auto space-x-2 ${currency ? 'block' : 'hidden'}`}
              >
                <span className="text-sm dark:text-dark-textPrimary">
                  Currency:
                </span>
                <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-800 text-gray-100">
                  {currency && currency}
                  <button
                    type="button"
                    className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center hover:text-gray-200"
                    onClick={() => setCurrency('')}
                  >
                    <span className="sr-only">Remove small option</span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              </div>
            </div>
          </>
          <>
            <div className="relative z-0 inline-flex shadow-sm rounded-md">
              <div>
                <Tippy
                  content={<div className="text-xs">Previous</div>}
                  placement="bottom"
                >
                  <div>
                    <button
                      type="button"
                      className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-dark-tabPillBgSelectedDark text-sm font-medium text-gray-500 dark:text-dark-textPrimary hover:bg-gray-50 `}
                      onClick={() => getNews(page - 1)}
                      disabled={page == 2 && true}
                    >
                      <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </Tippy>
              </div>
              <div>
                <Tippy
                  content={<div className="text-xs">Next</div>}
                  placement="bottom"
                >
                  <div>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-dark-tabPillBgSelectedDark text-sm  dark:text-dark-textPrimary text-sm font-medium text-gray-500 hover:bg-gray-50"
                      onClick={() => getNews(page)}
                      disabled={page == null || (page == undefined && true)}
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-4 w-4"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Tippy>
              </div>
            </div>
          </>
        </div>
        <div className="overflow-x-auto metric-scroll">
          <div className="sm:flex sm:items-center"></div>
          <div className="flex flex-col">
            <div className="">
              <div className="inline-block min-w-full py-2 align-middle ">
                <table className="min-w-full  divide-y divide-gray-300 dark:divide-gray-700">
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {news.map((item, key) => (
                      <tr key={key} className="">
                        <td className="whitespace-nowrap text-xs font-medium py-4 px-4 text-gray-900 dark:text-dark-textPrimary md:pl-2">
                          <time
                            dateTime={moment.utc(item.published_at).fromNow()}
                          >
                            {moment.utc(item.published_at).fromNow()}
                          </time>
                        </td>
                        <td className="whitespace-nowrap  py-1 text-sm text-gray-900 dark:text-dark-textPrimary">
                          <div className="flex flex-row">
                            <div className="text-xs">{item.title}</div>
                            <div className="text-blue-600  dark:text-blue-300 ml-2 flex flex-row space-x-1">
                              <div>
                                {item.kind == 'news' && (
                                  <LinkIcon className="h-4 w-4 mt-.5" />
                                )}
                                {item.kind == 'media' && (
                                  <VideoCameraIcon className="h-4 w-4 mt-1" />
                                )}
                              </div>
                              <div className="">
                                {' '}
                                <a
                                  href={`${item.url}`}
                                  className="text-blue-600  dark:text-blue-300 text-xs"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.domain}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap text-sm font-medium text-gray-900 dark:text-dark-textPrimary md:pl-0 flex space-x-1">
                          {item?.currencies &&
                            item?.currencies.slice(0, 3).map((i, k) => (
                              <div className="" key={k}>
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-800 mt-4">
                                  <div
                                    className="text-gray-100 text-xs hover:underline hover:text-decoration-blue-400 cursor-pointer"
                                    onClick={() => setCurrency(i.code)}
                                  >
                                    {i.code}
                                  </div>
                                </span>
                              </div>
                            ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
