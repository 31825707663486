import React, { useState } from 'react';

// eslint-disable-next-line react/prop-types
function MetaStore({ msType, setMsType }) {
  const metaStoreTypes = [
    { id: 1, title: 'AWS Glue', slug: 'aws-glue' },
    { id: 2, title: 'Hive Metastore', slug: 'hive-metastore' },
  ];

  const AwsGlue = () => {
    return (
      <div className="flex flex-col space-y-4 m-2 border-l border-gray-300 p-3">
        <div>
          <select
            id="location"
            name="location"
            className="block w-full sm:w-1/2 rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
            defaultValue="Canada"
          >
            <option>United States</option>
            <option>Canada</option>
            <option>Mexico</option>
          </select>
        </div>
        <div>
          <input
            type="text"
            name="first-name"
            id="first-name"
            autoComplete="given-name"
            placeholder="Default SE bucket name"
            className="block w-full sm:w-1/2 rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
          />
        </div>
        <div>
          <input
            type="text"
            name="first-name"
            id="first-name"
            autoComplete="given-name"
            placeholder="Default directory name"
            className="block w-full sm:w-1/2 rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
          />
        </div>
        <div className="">
          <div className="flex h-5 items-center py-4">
            <input
              id="s3access"
              aria-describedby="s3access"
              name="s3access"
              type="checkbox"
              className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <div className="ml-3 text-xs">
              <p id="s3access-description" className="text-gray-500">
                Use the authentication details configured for S3 access
              </p>
            </div>
          </div>
          <span className="text-gray-400 dark:text-gray-500 text-xs">
            Authentication with*
          </span>

          <div className="flex items-center mt-2">
            <input
              id="awsh_auth_with"
              name="awsh_auth_with"
              type="radio"
              defaultChecked={true}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onClick={() => console.log('')}
            />
            <label
              htmlFor="awsh_auth_with"
              className="ml-3 block text-xs font-medium dark:text-dark-textPrimary"
            >
              AWS access key
            </label>
          </div>
          <div className="flex flex-col space-y-4 m-2 border-l border-gray-300 p-3">
            <div>
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                placeholder="Default SE bucket name"
                className="block w-full sm:w-1/2 rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
              />
            </div>
            <div>
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                placeholder="Default directory name"
                className="block w-full sm:w-1/2 rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const HiveStore = () => {
    return (
      <div className="m-2 border-l border-gray-300 p-3">
        <span className="text-gray-400 dark:text-gray-500 text-xs">
          Connection type*
        </span>
        <div className="flex items-center mt-2">
          <input
            id="connect_type"
            name="connect_type"
            type="radio"
            defaultChecked={true}
            className="h-3 w-3 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            onClick={() => console.log('')}
          />
          <label
            htmlFor="connect_type"
            className="ml-2 block text-xs font-medium dark:text-dark-textPrimary"
          >
            Connect Directly
          </label>
        </div>
        <div className="my-4 sm:w-1/2">
          <div className="grid grid-cols-3 gap-2">
            <div className="col-span-2">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                placeholder="Hive metastore host*"
                className="block w-full rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
              />
            </div>
            <div className="">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                placeholder="9083"
                className="block w-full rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MetaStoreForm = () => {
    switch (msType) {
      case 1:
        return <AwsGlue />;

      case 2:
        return <HiveStore />;

      default:
        return null;
    }
  };

  return (
    <div>
      <h1 className="dark:text-dark-textPrimary py-3  text-sm font-semibold">
        Metastore Configuration
      </h1>
      <p className="dark:text-dark-textPrimary pb-4 text-sm text-justify">
        Configure access to the metastore to provide metadata and mapping
        information about the objects stored in Amazon S3.
      </p>
      <div>
        <span className="text-gray-400 dark:text-gray-500 text-xs">
          Metastore type*
        </span>
        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 mt-2">
          {metaStoreTypes.map((item, key) => (
            <div key={key} className="flex items-center">
              <input
                id="metastore_type"
                name="metastore_type"
                type="radio"
                defaultChecked={item.id === msType}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                onChange={() => setMsType(item.id)}
              />
              <label
                htmlFor="metastore_type"
                className="ml-3 block text-sm font-medium dark:text-dark-textPrimary"
              >
                {item.title}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div>
        <MetaStoreForm />
      </div>
    </div>
  );
}

export default MetaStore;
