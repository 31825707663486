import { createContext, useContext, useState } from 'react';
import AuthService from './authService';
import PropTypes from 'prop-types';

AuthProvider.propTypes = {
  children: PropTypes.element,
};
function AuthProvider({ children }) {
  const authService = new AuthService();

  const [user, setUser] = useState(undefined);

  const startAuthentication = async () => {
    try {
      const user = await authService.startAuthentication();
      setUser(user);
    } catch (error) {
      console.log(error);
      setUser(null);
    }
  };

  const completeAuthentication = async () => {
    try {
      const authedUser = await authService.completeAuthentication();
      setUser(authedUser);
    } catch (error) {
      console.log(error);
      setUser(null);
    }
  };

  const logout = async () => {
    try {
      await authService.logout();
    } catch (error) {
      console.log(error);
    } finally {
      setUser(null);
    }
  };

  const isLoggedIn = () => authService.isLoggedIn();
  const isVerified = () => authService.isVerified();

  const getSessionStorageUserOrNewUser = async () =>
    authService.getSessionStorageUserOrNewUser();

  const getSessionStorageUser = () => authService.getSessionStorageUser();

  const getLocalStorageUserOrNewUser = async () =>
    authService.getLocalStorageUserOrNewUser();

  const getLocalStorageUser = () => authService.getLocalStorageUser();

  const value = {
    user,
    isLoggedIn,
    startAuthentication,
    completeAuthentication,
    getSessionStorageUser,
    getSessionStorageUserOrNewUser,
    getLocalStorageUser,
    getLocalStorageUserOrNewUser,
    logout,
    isVerified,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
