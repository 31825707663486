/* eslint-disable react/prop-types */
import React from 'react';
import { ThumbDownIcon as ThumbDownIconOutlined } from '@heroicons/react/outline';
import { ThumbDownIcon as ThumbDownIconSolid } from '@heroicons/react/solid';
export default function DownVoteIcon({ isActive }) {
  return (
    <>
      {isActive ? (
        <ThumbDownIconSolid
          className={`rounded w-5 text-blue-500 pointer-events-none`}
        />
      ) : (
        <ThumbDownIconOutlined
          className={`rounded w-5 dark:text-gray-300 text-gray-700 `}
        />
      )}
    </>
  );
}
