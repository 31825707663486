export const DASHBOARD_DATA_LOADING = 'DASHBOARD_DATA_LOADING';
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';
export const DASHBOARD_DATA_FAILURE = 'DASHBOARD_DATA_FAILURE';
export const HOMEPAGE_DATA_SUCCESS = 'HOMEPAGE_DATA_SUCCESS';
export const HOMEPAGE_DATA_FAILURE = 'HOMEPAGE_DATA_FAILURE';
export const HOMEPAGE_LOADING = 'HOMEPAGE_LOADING';
export const GET_LEAKS_SUBMISSION_SUCCESS = 'GET_LEAKS_SUBMISSION_SUCCESS';
export const GET_LEAKS_SUBMISSION_FAILURE = 'GET_LEAKS_SUBMISSION_FAILURE';
export const SUBMIT_LEAKS = 'SUBMIT_LEAKS';
export const SUBMIT_LEAKS_FAILURE = 'SUBMIT_LEAKS_FAILURE';
export const LEAKS_FORM_PRELOAD_SUCCESS = 'LEAKS_FORM_PRELOAD_SUCCESS';
export const LEAKS_FORM_PRELOAD_FAILURE = 'LEAKS_FORM_PRELOAD_FAILURE';
export const GET_BLOCKCHAIN_SUCCESS = 'GET_BLOCKCHAIN_SUCCESS';
export const GET_BLOCKCHAIN_FAILURE = 'GET_BLOCKCHAIN_FAILURE';
export const SUBMIT_WALLET = 'SUBMIT_WALLET';
export const SUBMIT_WALLET_FAILURE = 'SUBMIT_WALLLET_FAILURE';
export const GET_WALLET_SUBMISSION_SUCCESS =
  'GET_RECENT_WALLET_SUBMISSIONS_SUCCESS';
export const GET_WALLET_SUBMISSION_FAILURE =
  'GET_RECENT_WALLET_SUBMISSIONS_FAILURE';
export const GET_LATEST_DEX_TRADES_LOADING = 'GET_LATEST_DEX_TRADES_LOADING';
export const GET_LATEST_DEX_TRADES_SUCCESS = 'GET_LATEST_DEX_TRADES_SUCCESS';
export const GET_LATEST_DEX_TRADES_FAILURE = 'GET_LATEST_DEX_TRADES_FAILURE';
export const GET_LATEST_DEX_TRADES_POPUP_LOADING =
  'GET_LATEST_DEX_TRADES_LOADING';
export const GET_LATEST_DEX_TRADES_POPUP_SUCCESS =
  'GET_LATEST_DEX_TRADES_SUCCESS';
export const GET_LATEST_DEX_TRADES_POPUP_FAILURE =
  'GET_LATEST_DEX_TRADES_FAILURE';
export const GET_TOKEN_SUMMARY_SUCCESS = 'GET_TOKEN_SUMMARY_SUCCESS';
export const GET_TOKEN_SUMMARY_FAILURE = 'GET_TOKEN_SUMMARY_FAILURE';
export const GET_TOP_TRANSACTIONS_LOADING = 'GET_TOP_TRANSACTIONS_LOADING';
export const GET_TOP_TRANSACTIONS_SUCCESS = 'GET_TOP_TRANSACTIONS_SUCCESS';
export const GET_TOP_TRANSACTIONS_FAILURE = 'GET_TOP_TRANSACTIONS_FAILURE';
export const GET_TOP_BALANCES_LOADING = 'GET_TOP_BALANCES_LOADING';
export const GET_TOP_BALANCES_SUCCESS = 'GET_TOP_BALANCES_SUCCESS';
export const GET_TOP_BALANCES_FAILURE = 'GET_TOP_BALANCES_FAILURE';
export const GET_TOKEN_DEX_LOADING = 'GET_TOKEN_DEX_LOADING';
export const GET_TOKEN_DEX_SUCCESS = 'GET_TOKEN_DEX_SUCCESS';
export const GET_TOKEN_DEX_FAILURE = 'GET_TOKEN_DEX_FAILURE';
export const GET_TOKEN_DEX_POPUP_LOADING = 'GET_TOKEN_DEX_POPUP_LOADING';
export const GET_TOKEN_DEX_POPUP_SUCCESS = 'GET_TOKEN_DEX_POPUP_SUCCESS';
export const GET_TOKEN_DEX_POPUP_FAILURE = 'GET_TOKEN_DEX_POPUP_FAILURE';
export const GET_GAMING_DATA_LOADING = 'GET_GAMING_DATA_LOADING';
export const GET_GAMING_DATA_SUCCESS = 'GET_GAMING_DATA_SUCCESS';
export const GET_GAMING_DATA_FAILURE = 'GET_GAMING_DATA_FAILURE';
export const GET_VIEWALPHALEAKS_LOADING = 'GET_VIEWALPHALEAKS_LOADING';
export const GET_VIEWALPHALEAKS_SUCCESS = 'GET_VIEWALPHALEAKS_SUCCESS';
export const GET_VIEWALPHALEAKS_FAILURE = 'GET_VIEWALPHALEAKS_FAILURE';
export const GET_LEADERBOARD_LOADING = 'GET_LEADERBOARD_LOADING';
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_FAILURE = 'GET_LEADERBOARD_FAILURE';
export const GET_USERLEAKS_LOADING = 'GET_USERLEAKS_LOADING';
export const GET_USERLEAKS_SUCCESS = 'GET_USERLEAKS_SUCCESS';
export const GET_USERLEAKS_FAILURE = 'GET_USERLEAKS_FAILURE';
export const GET_LEAKS_DETAILS_SUCCESS = 'GET_LEAKS_DETAILS_SUCCESS';
export const GET_LEAKS_DETAILS_FAILURE = 'GET_LEAKS_DETAILS_FAILURE';
export const GET_TOP_NFT_BUY_LOADING = 'GET_TOP_NFT_BUY_LOADING';
export const GET_TOP_NFT_BUY_SUCCESS = 'GET_TOP_NFT_BUY_SUCCESS';
export const GET_TOP_NFT_BUY_FAILURE = 'GET_TOP_NFT_BUY_SUCCESS';
export const GET_TOP_NFT_TRANSACTION_LOADING =
  'GET_TOP_NFT_TRANSACTION_LOADING';
export const GET_TOP_NFT_TRANSACTION_SUCCESS =
  'GET_TOP_NFT_TRANSACTION_SUCCESS';
export const GET_TOP_NFT_TRANSACTION_FAILURE =
  'GET_TOP_NFT_TRANSACTION_FAILURE';
export const GET_ALL_WALLET_SUBMISSIONS_LOADING =
  'GET_ALL_WALLET_SUBMISSIONS_LOADING';
export const GET_ALL_WALLET_SUBMISSIONS_SUCCESS =
  'GET_ALL_WALLET_SUBMISSIONS_SUCCESS';
export const GET_ALL_WALLET_SUBMISSIONS_FAILURE =
  'GET_ALL_WALLET_SUBMISSIONS_FAILURE';
export const META_MASK_AUTH_SUCCESS = 'META_MASK_AUTH_SUCCESS';
export const META_MASK_AUTH_FAIL = 'META_MASK_AUTH_FAIL';
export const GET_WALLET_SUMISSION_DETAILS_SUCCESS =
  'GET_WALLET_SUMISSION_DETAILS_SUCCESS';
export const GET_WALLET_SUMISSION_DETAILS_FAILURE =
  'GET_WALLET_SUMISSION_DETAILS_FAILURE';
export const GET_ANALYTICS_DATA_LOADING = 'GET_ANALYTICS_DATA_LOADING';
export const GET_ANALYTICS_DATA_SUCCESS = 'GET_ANALYTICS_DATA_SUCCESS';
export const GET_ANALYTICS_DATA_FAILURE = 'GET_ANALYTICS_DATA_FAILURE';
export const GET_NOTABLETRADERS_DATA_LOADING =
  'GET_NOTABLETRADERS_DATA_LOADING';
export const GET_NOTABLETRADERS_DATA_SUCCESS =
  'GET_NOTABLETRADERS_DATA_SUCCESS';
export const GET_NOTABLETRADERS_DATA_FAILURE =
  'GET_NOTABLETRADERS_DATA_FAILURE';
export const GET_TOPTRADES_CHART_DATA_SUCCESS =
  'GET_TOPTRADES_CHART_DATA_SUCCESS';
export const GET_TOPTRADES_CHART_DATA_FAILURE =
  'GET_TOPTRADES_CHART_DATA_FAILURE';
export const GET_TOP_TRADES_UNLISTED_LOADING =
  'GET_TOP_TRADES_UNLISTED_LOADING';
export const GET_TOP_TRADES_UNLISTED_SUCCES = 'GET_TOP_TRADES_UNLISTED_SUCCES';
export const GET_TOP_TRADES_UNLISTED_FAILURE =
  'GET_TOP_TRADES_UNLISTED_FAILURE';
export const GET_TOP_TRADES_UNLISTED_CHART_SUCCESS =
  'GET_TOP_TRADES_UNLISTED_CHART_SUCCESS';
export const GET_TOP_TRADES_UNLISTED_CHART_FAILURE =
  'GET_TOP_TRADES_UNLISTED_CHART_FAILURE';
export const GET_LARGEST_TRANSFERS_DATA_SUCCESS =
  'GET_LARGEST_TRANSFERS_DATA_SUCCES';
export const GET_LARGEST_TRANSFERS_DATA_FAILURE =
  'GET_LARGEST_TRANSFERS_DATA_FAILURE';
export const GET_LARGEST_TRANSFERS_DATA_LOADING =
  'GET_LARGEST_TRANSFERS_DATA_LOADING';
export const GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_SUCCESS =
  'GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_SUCCESS';
export const GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_FAILURE =
  'GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_FAILURE';
export const GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_LOADING =
  'GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_LOADING';
export const GET_TOKEN_INFO_SUCCESS = 'GET_TOKEN_INFO_SUCCESS';
export const GET_TOKEN_INFO_FAILURE = 'GET_TOKEN_INFO_FAILURE';
export const GET_TOKEN_INFO_LOADING = 'GET_TOKEN_INFO_LOADING';
export const GET_ALPHALEAKS_SUCCESS = 'GET_ALPHALEAKS_SUCCESS';
export const GET_ALPHALEAKS_FAILURE = 'GET_ALPHALEAKS_FAILURE';
export const GET_ALPHALEAKS_LOADING = 'GET_ALPHALEAKS_LOADING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const TOKEN_SEARCH_SUCCESS = 'TOKEN_SEARCH_SUCCESS';
export const TOKEN_SEARCH_FAILURE = 'TOKEN_SEARCH_FAILURE';
export const TOKEN_SEARCH_LOADING = 'TOKEN_SEARCH_LOADING';
export const THEME_UPDATE = 'THEME_UPDATE';
export const GET_NFT_DROPS_SUCCESS = 'GET_NFT_DROPS_SUCCESS';
export const GET_NFT_DROPS_FAILURE = 'GET_NFT_DROPS_FAILURE';
export const GET_NFT_DROPS_LOADING = 'GET_NFT_DROPS_LOADING';
export const GET_TOP_LP_LOADING = 'GET_TOP_LP_LOADING';
export const GET_TOP_LP_SUCCESS = 'GET_TOP_LP_SUCCESS';
export const GET_TOP_LP_FAILURE = 'GET_TOP_LP_FAILURE';
export const GET_TOP_LP_CHART_LOADING = 'GET_TOP_LP_CHART_LOADING';
export const GET_TOP_LP_CHART_SUCCESS = 'GET_TOP_LP_CHART_SUCCESS';
export const GET_TOP_LP_CHART_FAILURE = 'GET_TOP_LP_CHART_FAILURE';
export const GET_TOP_LP_UNLISTED_LOADING = 'GET_TOP_LP_UNLISTED_LOADING';
export const GET_TOP_LP_UNLISTED_SUCCESS = 'GET_TOP_LP_UNLISTED_SUCCESS';
export const GET_TOP_LP_UNLISTED_FAILURE = 'GET_TOP_LP_UNLISTED_FAILURE';
export const GET_TOP_LP_UNLISTED_CHART_LOADING =
  'GET_TOP_LP_UNLISTED_CHART_LOADING';
export const GET_TOP_LP_UNLISTED_CHART_SUCCESS =
  'GET_TOP_LP_UNLISTED_CHART_SUCCESS';
export const GET_TOP_LP_UNLISTED_CHART_FAILURE =
  'GET_TOP_LP_UNLISTED_CHART_FAILURE';
export const GET_LATEST_NFT_TRADES_LOADING = 'GET_LATEST_NFT_TRADES_LOADING';
export const GET_LATEST_NFT_TRADES_SUCCESS = 'GET_LATEST_NFT_TRADES_SUCCESS';
export const GET_LATEST_NFT_TRADES_FAILURE = 'GET_LATEST_NFT_TRADES_FAILURE';
export const GET_LATEST_NFT_TRADES_BYTX_LOADING =
  'GET_LATEST_NFT_TRADES_BYTX_LOADING';
export const GET_LATEST_NFT_TRADES_BYTX_SUCCESS =
  'GET_LATEST_NFT_TRADES_BYTX_SUCCESS';
export const GET_LATEST_NFT_TRADES_BYTX_FAILURE =
  'GET_LATEST_NFT_TRADES_BYTX_FAILURE';
export const GET_TOP_NFT_TRADES_LOADING = 'GET_TOP_NFT_TRADES_LOADING';
export const GET_TOP_NFT_TRADES_SUCCESS = 'GET_TOP_NFT_TRADES_SUCCESS';
export const GET_TOP_NFT_TRADES_FAILURE = 'GET_TOP_NFT_TRADES_FAILURE';
export const GET_TOP_NFT_CONTRACT_LOADING = 'GET_TOP_NFT_CONTRACT_LOADING';
export const GET_TOP_NFT_CONTRACT_SUCCESS = 'GET_TOP_NFT_CONTRACT_SUCCESS';
export const GET_TOP_NFT_CONTRACT_FAILURE = 'GET_TOP_NFT_CONTRACT_FAILURE';

export const GET_LATEST_TRADE_DISTRIBUTION_LOADING =
  'GET_LATEST_TRADE_DISTRIBUTION_LOADING';
export const GET_LATEST_TRADE_DISTRIBUTION_SUCCESS =
  'GET_LATEST_TRADE_DISTRIBUTION_SUCCESS';
export const GET_LATEST_TRADE_DISTRIBUTION_FAILURE =
  'GET_LATEST_TRADE_DISTRIBUTION_FAILURE';

export const GET_NFT_LATEST_TRADES_LOADING = 'GET_NFT_LATEST_TRADES_LOADING';
export const GET_NFT_LATEST_TRADES_SUCCESS = 'GET_NFT_LATEST_TRADES_SUCCESS';
export const GET_NFT_LATEST_TRADES_FAILURE = 'GET_NFT_LATEST_TRADES_FAILURE';

export const GET_COLLECTION_TRADE_DISTRIBUTION_LOADING =
  'GET_COLLECTION_TRADE_DISTRIBUTION_LOADING';
export const GET_COLLECTION_TRADE_DISTRIBUTION_SUCCESS =
  'GET_COLLECTION_TRADE_DISTRIBUTION_SUCCESS';
export const GET_COLLECTION_TRADE_DISTRIBUTION_FAILURE =
  'GET_LCOLLECTION_TRADE_DISTRIBUTION_FAILURE';

export const GET_ALPHA_REQUEST_SUCCESS = 'GET_ALPHA_REQUEST_SUCCESS';
export const GET_ALPHA_REQUEST_FAILED = 'GET_ALPHA_REQUEST_FAILED';
export const GET_ALPHA_REQUEST_LOADING = 'GET_ALPHA_REQUEST_LOADING';

export const GET_ALPHA_RESPONSE_SUCCESS = 'GET_ALPHA_RESPONSE_SUCCESS';
export const GET_ALPHA_RESPONSE_FAILED = 'GET_ALPHA_RESPONSE_FAILED';
export const GET_ALPHA_RESPONSE_LOADING = 'GET_ALPHA_RESPONSE_LOADING';

export const GET_ALPHA_REQ_PRELOAD_SUCCESS = 'GET_ALPHA_REQ_PRELOAD_SUCCESS';
export const GET_ALPHA_REQ_PRELOAD_FAILED = 'GET_ALPHA_REQ_PRELOAD_FAILED';
export const GET_ALPHA_REQ_PRELOAD_LOADING = 'GET_ALPHA_REQ_PRELOAD_LOADING';
export const SET_BLOCKCHAIN = 'SET_BLOCKCHAIN';
export const GET_BLOCKCHAIN = 'GET_BLOCKCHAIN';

export const GET_LAST_INDEXED_SUCCESS = 'GET_LAST_INDEXED_SUCCESS';
export const GET_LAST_INDEXED_FAILED = 'GET_LAST_INDEXED_FAILED';
export const GET_LAST_INDEXED_LOADING = 'GET_LAST_INDEXED_LOADING';
