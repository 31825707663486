import { AdjustmentsIcon } from '@heroicons/react/solid';
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import { getLastIndexed } from 'Helper/services';
import { useLocalStorage } from 'Hooks';
import {
  ContentWrapper,
  MenuWrapper,
  PopoverWrapper,
  Refresh,
} from 'Utilities';
import DataLastIndexed from 'Utilities/DataLastIndexed/DataLastIndexed';
import TableTokenDashboard from 'Utilities/Table/tableTokenDashboard';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopLpData } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import useHeaders from './Helper/header';
import { processTokens } from './Helper/processToken';
import Filters from './filters';
import SortOptions from './sortOptions';
import SortToggle from './sortToggle';
import { useNavigate } from 'react-router-dom';
import {
  token_duration,
  token_group,
  token_limit,
  token_type,
} from './Helper/constants';

function TopLP(props) {
  // to fire redux actions (which emit signals)
  // state initialization start
  // for setting progress bar max value
  const groupFromParam = getUrlParam('group');
  const limitFromParam = getUrlParam('limit');
  const typeFromParam = getUrlParam('type');
  const durationFromParam = getUrlParam('duration');
  const buySellFromParam = getUrlParam('buysellFilter');
  const hideCoinsFromParam = getUrlParam('hideStandardCoins');

  const [limit, setLimit] = useLocalStorage('lp_limit', 20);
  const [group, setGroup] = useLocalStorage('lp_group', 'whales');
  const [type, setType] = useLocalStorage('lp_type', 'add_vs_remove');
  const [duration, setDuration] = useLocalStorage('lp_duration', '24hr');
  const [selected, setSelected] = useLocalStorage('lp_filter', 'wallet_count'); // for active filter
  const [filter, setFilter] = useLocalStorage(
    'lp_sort_key',
    buySellFromParam ? buySellFromParam : 'wallet_count'
  );
  const [hideStandardCoins, sethideStandardCoins] = useLocalStorage(
    'lp_hideStandardCoins',
    hideCoinsFromParam ? hideCoinsFromParam : true
  );
  const [refresh, setRefresh] = useState(false);
  const [buysellFilter, setBuySellFilter] = useLocalStorage(
    'lpbuysellfilter',
    'add_wallet_count'
  );
  const [lastIndexed, setLastIndexed] = useState('');

  const [column] = useHeaders();

  const dispatch = useDispatch();
  const block_chain = CHAINS[getUrlParam('chain')];
  const navigate = useNavigate();
  const basePath = '';
  const chain = getUrlParam('chain');

  const { token, loading } = useSelector((s = initialState) => {
    return {
      token: processTokens(s.topLPData.topLp, type),
      loading: s.topLPData.loading,
    };
  });

  const handleCallback = (isStable) => {
    sethideStandardCoins(isStable);
  };

  const tableProps = {
    data: token,
    col: column,
    isloading: loading,
    lastIndexed,
  };

  console.log(tableProps);

  const validateURLParams = () => {
    const urlLimitBoolean = token_limit?.some(
      (item) => item.value === limitFromParam
    );
    if (urlLimitBoolean) setLimit(limitFromParam);

    const urlGroupBoolean = token_group?.some(
      (item) => item.value === groupFromParam
    );
    if (urlGroupBoolean) setGroup(groupFromParam);

    const urlDurationBoolean = token_duration?.some(
      (item) => item.value === durationFromParam
    );
    if (urlDurationBoolean) setDuration(durationFromParam);

    const urlTypeBoolean = token_type?.some(
      (item) => item.value === typeFromParam
    );
    if (urlTypeBoolean) setType(typeFromParam);
  };

  useEffect(() => {
    validateURLParams();
  }, []);

  const getTokenInfo = (qToken) => {
    setQueryToken(qToken);
    token.map((item) => {
      if (item.address === qToken) {
        setCgId(item.cg_id);
        setTokenName(item.name);
      }
    });
  };
  const getTopLpRes = () => {
    dispatch(
      getTopLpData(
        block_chain,
        limit,
        group,
        type,
        duration,
        buysellFilter,
        hideStandardCoins
      )
    );
  };

  useEffect(async () => {
    getTopLpRes();
    const res = await getLastIndexed(block_chain, 'top-lp');
    setLastIndexed(res?.data?.metadata?.data_last_indexed_timestamp_utc);
    navigate(
      `${basePath}?chain=${chain}&tab=TopLP&limit=${limit}&group=${group}&type=${type}&duration=${duration}&buySellFilter=${filter}&hideStandardCoins=${hideStandardCoins}`
    );
  }, [
    block_chain,
    limit,
    group,
    type,
    duration,
    hideStandardCoins,
    refresh,
    buysellFilter,
  ]);

  const filterProps = Object.freeze({
    limit,
    setLimit,
    group,
    setGroup,
    duration,
    setDuration,
    type,
    setType,
  });

  return (
    <ContentWrapper>
      <div className="hidden sm:flex justify-between">
        <div className="flex flex-wrap space-x-1 mr-1 m-4 ">
          <Filters {...filterProps} />
          <div className="mx-2">
            <PopoverWrapper
              Icon={AdjustmentsIcon}
              label="More..."
              kind="primary"
            >
              <SortOptions
                filter={buysellFilter}
                setFilter={setBuySellFilter}
              />
            </PopoverWrapper>
          </div>
          <div className="mx-2">
            <div className="flex">
              <SortToggle
                parentCallback={handleCallback}
                isEnableStable={hideStandardCoins}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-2 m-3">
          <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
          <Refresh
            state={refresh}
            setState={setRefresh}
            loadingbutton={loading}
          />
        </div>
      </div>

      {/* filter, sort  options mobile view */}
      <div className="block sm:hidden py-4 px-2 lg:py-0 lg:px-0">
        <div className="flex justify-between">
          <div className="flex space-x-2">
            <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
            <Refresh
              state={refresh}
              setState={setRefresh}
              loadingbutton={loading}
            />

            <div>
              <MenuWrapper>
                <div className="p-2">
                  <Filters {...filterProps} />
                  <SortOptions
                    filter={buysellFilter}
                    setFilter={setBuySellFilter}
                  />

                  <SortToggle
                    parentCallback={handleCallback}
                    isEnableStable={hideStandardCoins}
                  />
                </div>
              </MenuWrapper>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <TableTokenDashboard {...tableProps} />
      </div>
    </ContentWrapper>
  );
}

export default TopLP;
