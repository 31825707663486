import React from 'react';
import Spinner from 'Utilities/Loaders/spinner';

function LoadingScreen() {
  return (
    <div>
      <div className="h-screen">
        <div className="h-full flex flex-col items-center my-12">
          <Spinner />
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
