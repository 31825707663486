/* eslint-disable react/prop-types */
import { LogoMinified } from 'Utilities/images';
import React from 'react';

function Overview({ title, desc }) {
  return (
    <div className="px-4 md:px-6 lg:px-8">
      <div className="flex flex-col items-center sm:items-start">
        <div className="flex items-center gap-2">
          <img src={LogoMinified} className="w-6" />
          <h1 className="text-md dark:text-white text-gray-800 font-semibold">
            {title}
          </h1>
        </div>
        <div className="my-2 w-ful lg:w-2/5">
          <h1 className="text-gray-800 dark:text-dark-textPrimary text-sm font-medium text-justify sm:text-left">
            {desc}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Overview;
