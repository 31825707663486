/* eslint-disable react/prop-types */
import Tippy from '@tippyjs/react';
import { getLoggedUser, getUserClusterList } from 'Helper/services';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { Refresh, Table } from 'Utilities';
import { useEffect, useState } from 'react';
import { column } from './Atoms/header';

function ClusterList() {
  const [loading, setLoading] = useState(false);
  const [userClusters, setUserClusters] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const { user_address } = useGetCurrentUser();

  const getClusterInfo = async () => {
    try {
      setLoading(true);
      const loggedUser = await getLoggedUser(user_address);
      if (loggedUser) {
        const userId = loggedUser.message[0].id;
        const userClusters = await getUserClusterList(userId, 'all');
        if (userClusters) setUserClusters(userClusters.data);
      }
    } catch (err) {
      console.log('failed to fetch clusters', err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClusterInfo();
  }, [refresh]);
  return (
    <div className="sm:px-4">
      <div className="flex justify-between items-center py-3">
        <div className="text-lg dark:text-white text-gray-800">My Clusters</div>
        <Tippy
          content={<div className="text-xs">refresh data</div>}
          placement="top"
        >
          <>
            <span className="sr-only">refresh Option</span>
            <Refresh
              state={refresh}
              setState={setRefresh}
              loadingbutton={loading}
            />
          </>
        </Tippy>
      </div>
      <div className="dark:bg-dark-themePrimary bg-light-themeSecondary p-2 rounded">
        <Table data={userClusters} col={column} isloading={loading} />
        {/* <div className="table-container">
          <table className="scrollable-table">
            <thead>
              <tr>
                <th>Column 1</th>
                <th>Column 2</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Row 1, Column 1</td>
                <td>Row 1, Column 2</td>
                <td>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex items-center justify-center w-full px-4 py-2 font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700">
                        Actions
                        <ChevronDownIcon
                          className="w-5 h-5 ml-2 -mr-1"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Menu.Items className="absolute right-0 mt-2 bg-white border border-gray-300 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active
                                ? 'bg-indigo-500 text-white'
                                : 'text-gray-900'
                            } group flex items-center px-4 py-2 text-sm`}
                          >
                            Edit
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active
                                ? 'bg-indigo-500 text-white'
                                : 'text-gray-900'
                            } group flex items-center px-4 py-2 text-sm`}
                          >
                            Delete
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  );
}

export default ClusterList;
