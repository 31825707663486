import { ArrowUpIcon } from '@heroicons/react/outline';
import { ArrowCircleUpIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', function (e) {
      toggleVisibility();
    });
  });

  function toggleVisibility() {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  return (
    <>
      {isVisible && (
        <div
          className="bottom-4 sm:bottom-6 right-0 sm:right-4 z-40 fixed duration-1000 h-10"
          onClick={() => scrollToTop()}
        >
          <button
            type="button"
            className="inline-flex items-center px-2 sm:px-4 py-2 font-semibold text-md rounded-full text-gray-700 dark:text-dark-textPrimary"
          >
            <ArrowCircleUpIcon
              className="h-10 w-10 text-black hover:opacity-100 dark:text-gray-500"
              aria-hidden="true"
            />
          </button>
        </div>
      )}
    </>
  );
}

export default ScrollToTop;
