/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHomePageData } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import Banner from 'Utilities/Banner/banner';
import InfoCard from './infoCard';
import TableCard from './tableCard';

function Matrics({ darkMode = false }) {
  // state initialization start
  const [isActive, setIsActive] = useState(2);

  // useDispatch() instance
  const dispatch = useDispatch();

  // listening to redux store
  const { matrics } = useSelector((s = initialState) => {
    // const _d = s.homePage.matrics?.data;
    // _d?.shift();
    return {
      matrics: s.homePage.matrics?.data,
    };
  });

  // `matricLength` is used to calculate the length of response object
  const matricsLength = matrics ? matrics.length : null;

  // `itemsRef` is a reference object which consists of  `ref` attributes
  const itemsRef = Array.from({ length: matricsLength }, () => createRef());

  //`scrollToElement` method is used to achieve scroll-to-element / scroll-to-div feature
  const scrollToElement = (key) => {
    setIsActive(key);
    itemsRef[key].current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };

  // useffect hook
  useEffect(() => {
    if (!(matrics && matrics.length > 0)) {
      dispatch(getHomePageData());
    }
  }, []);

  return (
    <>
      <div className="dark:bg-dark-themeSecondary bg-light-white p-4 rounded-tr-md">
        <div className="px-2 sm:px-6 md:px-8">
          <div className="sm:my-12">
            {!matrics || matrics == undefined ? (
              <div className="h-screen">
                <div className="flex justify-center items-center">
                  {/* <ServerDisconnected /> */}
                </div>
              </div>
            ) : (
              <>
                <div className="sm:hidden block sticky top-16">
                  <div className="my-4">
                    <select
                      className="p-2 border rounded cursor-pointer dark:bg-dark-dropdownBgDark text-xs w-full dark:border-dark-secondary dark:text-dark-textPrimary"
                      onChange={(e) => scrollToElement(e.target.value)}
                      title="Select title"
                    >
                      {matrics && matrics.length > 0 ? (
                        matrics.map((item, key) => (
                          <option className="" key={key} value={key}>
                            {item.metric}
                          </option>
                        ))
                      ) : (
                        <>
                          <div>ugh Oh something went wrong</div>
                        </>
                      )}
                    </select>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  {/* side list */}
                  <div className="sm:space-y-4 w-full sm:w-72 mr-20">
                    <div className="hidden sm:block sticky h-screen top-20 inset-y-0">
                      <ul className="">
                        {matrics && matrics.length > 0
                          ? matrics.map((item, key) => {
                              if (key > 1) {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => {
                                      scrollToElement(key);
                                    }}
                                    className={`py-4 flex ml-2 pl-2 pr-2  hover:bg-blue-50 dark:hover:bg-dark-bgMutedDark cursor-pointer  rounded-r-lg ${
                                      key === isActive
                                        ? 'bg-blue-100 border-blue-500 dark:bg-dark-bgMutedDark dark:border-dark-primary font-semibold border-l-4 text-sm'
                                        : null
                                    }`}
                                  >
                                    <p className="dark:text-dark-textPrimary text-xs">
                                      {item.metric}
                                    </p>
                                  </li>
                                );
                              }
                            })
                          : null}
                      </ul>
                    </div>
                  </div>
                  {/* side list */}
                  <div className="w-full">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      {matrics && matrics.length > 0
                        ? matrics.map((item, key) => {
                            if (key > 1) {
                              return (
                                <div
                                  key={key}
                                  className={`sm:mb-2 sm:mr-2 ${
                                    item.display_type === 'table'
                                      ? 'sm:col-span-2'
                                      : null
                                  }`}
                                  ref={itemsRef[key]}
                                >
                                  {item.display_type === 'info' ? (
                                    <InfoCard
                                      metric={item.metric}
                                      text={item.data_json.text}
                                      url={item.data_json.url}
                                      last_updated={
                                        item.metrics_data_last_updated_timestamp_utc
                                      }
                                    />
                                  ) : (
                                    <TableCard
                                      column={item.data_json.columns}
                                      row={item.data_json.rows}
                                      metric={item.metric}
                                      cols_type={item.data_json.column_types}
                                      last_updated={
                                        item.metrics_data_last_updated_timestamp_utc
                                      }
                                    />
                                  )}
                                </div>
                              );
                            }
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Matrics;
