import React from 'react';
import GamingTable from './table';

function Gaming() {
  return (
    <div className="px-2 m-4">
      <div className="p-2 border  dark:border-dark-secondary rounded-lg flex flex-col bg-light-tabPillBgSelectedLight dark:bg-dark-tabPillBgSelectedDark">
        <GamingTable />
      </div>
    </div>
  );
}

export default Gaming;
