import OidcLogin from 'Components/Login/oidc-login';
import { BannerImage, GradientBeam } from 'Utilities/images';
import PropTypes, { shape } from 'prop-types';
import { useSelector } from 'react-redux';

Hero.propTypes = {
  featureRef: shape({ current: PropTypes.instanceOf(Element) }),
};

const STYLES = {
  dark: {
    heroTextGradient:
      'bg-gradient-to-br from-heroTextPrimaryDark via-heroTextSecondaryDark to-heroTextTertiaryDark bg-clip-text text-transparent',
    heroBgGradient:
      'bg-gradient-to-br from-heroBgColorPrimaryDark to-heroBgColorSecondaryDark',
    highlightGradient:
      'inset-5 bg-gradient-to-r from-cyan-700 to-cyan-950 rounded-md filter blur-2xl mt-6',
  },
  light: {
    heroTextGradient:
      'bg-gradient-to-br from-heroTextPrimaryLight via-heroTextSecondaryLight to-heroTextTertiaryLight bg-clip-text text-transparent',
    highlightGradient:
      '-inset-5 bg-gradient-to-r from-yellow-100 via-red-100 to-yellow-200 rounded-full filter blur-2xl -mb-16 -ml-16 mt-2',
  },
};
function Hero({ featureRef }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const scrollToElement = () => {
    featureRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  function renderHeroText() {
    return (
      <div className="text-center sm:text-left">
        <h1
          className={`mx-auto max-w-4xl text-2xl md:text-4xl lg:text-4xl  ${STYLES[theme].heroTextGradient}`}
        >
          The most
        </h1>

        <div className="">
          <h1
            className={`text-3xl md:text-4xl lg:text-6xl font-semibold font-display  ${STYLES[theme].heroTextGradient}`}
          >
            powerful&nbsp;
            <span className="relative whitespace-nowrap">
              <svg
                className="absolute left-0 top-6 md:top-8 xl:top-11 w-full"
                viewBox="0 0 336 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M273.217 0C275.331 0 276.202 0.49996 277.509 0.49996C281.43 0.626802 283.986 0.151046 288.836 1C291.014 1 295.838 0.953751 299.209 0.728929C302.175 1.02188 305.399 1.56693 308.659 1.61462C317.686 1.74406 326.73 1.6555 335.775 1.6555C335.812 2.00295 335.849 2.3572 335.886 2.70465C331.87 2.82047 327.872 2.99759 323.838 3.03847C283.993 3.44723 244.111 3.64481 204.285 4.28521C162.45 4.95286 120.653 5.97478 78.8554 6.96944C56.05 7.51447 33.2814 8.33201 10.4944 8.97922C8.33913 9.04053 5.85236 8.9792 4.02867 8.61812C2.38919 8.29792 0.436523 7.49401 0.436523 6.90811C0.436523 6.39034 2.8313 5.49106 4.37868 5.42293C21.1235 4.68034 37.8683 3.89688 54.6868 3.47449C72.1685 3.03848 89.7054 2.99761 107.224 2.75916C123.471 2.54115 139.737 2.33677 155.966 2.03019C169.174 1.78493 182.345 1.27396 195.553 1.11726C200.674 1.05595 206.114 1.03551 211.29 1.11726C213.832 1.15814 216.082 1.11726 218.653 0.790262C218.672 0.701697 219.98 0.524551 220.367 0.606305C225.667 0.674457 230.111 0.11581 234.404 0.674457C239.172 0.599527 243.19 1.02873 247.667 0.599527C249.192 0.674457 251 0.599527 252.677 0.599527C254.703 0.599527 256.711 0.688078 258.756 0.626763C260.488 0.572261 262.735 0.463256 263.896 0.797082C267.489 0.626763 270.103 0.599527 273.18 0H273.217Z"
                  fill="url(#paint0_linear_564_680)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_564_680"
                    x1="-11.4399"
                    y1="-1.38848"
                    x2="-1.29396"
                    y2="65.8772"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#476075" />
                    <stop offset="0.463542" stopColor="#3A5B7C" />
                    <stop offset="1" stopColor="#1C5636" />
                  </linearGradient>
                </defs>
              </svg>
              <span className={`${STYLES[theme].heroTextGradient}`}>
                data platform
              </span>
            </span>
          </h1>
        </div>
        <div className="inline-flex mt-3 sm:mt-6">
          <h1
            className={`text-3xl md:text-4xl lg:text-6xl font-semibold ${STYLES[theme].heroTextGradient}`}
          >
            <span
              className={`text-2xl md:text-4xl lg:text-5xl font-normal leading-normal ${STYLES[theme].heroTextGradient}`}
            >
              for&nbsp;
            </span>
            blockchain analytics
          </h1>
        </div>
      </div>
    );
  }

  function renderHeroSubtext() {
    return (
      <>
        <p className="text-cueTextLight dark:text-cueTextDark  text-bg text-sm lg:text-xl mt-3 text-center lg:text-left px-4 sm:px-0 py-2 sm:py-0">
          <span className="font-semibold">Explore, Visualize,</span> and
          <span className="font-semibold"> Build </span>
          on blockchain data with Alphastack
        </p>
      </>
    );
  }
  return (
    <main
      className={`relative overflow-hidden pt-10  ${STYLES[theme].heroBgGradient}`}
    >
      <div
        className={`relative container mx-auto flex max-w-8xl flex-col pb-14 sm:px-10 md:pb-24 lg:min-h-630px 2xl:px-6`}
      >
        <div className="flex items-center flex-col sm:flex-row">
          <div className="flex-col sm:mt-20  md:text-left">
            {renderHeroText()}
            {renderHeroSubtext()}

            <div className="relative flex space-x-4 justify-center lg:justify-start my-8 z-50">
              <div>
                <OidcLogin />
              </div>

              <div className="">
                <div
                  onClick={scrollToElement}
                  className="w-full px-5 py-3 border border-theme bg-white text-sm font-semibold rounded-lg text-theme cursor-pointer z-50 hover:opacity-80"
                >
                  Learn More
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto w-full mt-6 hidden md:flex md:w-4/12 z-0">
            <img
              src={GradientBeam}
              alt=""
              className="absolute  max-w-5xl -right-28 top-10 filter blur-2xl"
            />

            <img
              src={BannerImage}
              width={4000}
              height={2000}
              className="min-w-54 relative rounded-md shadow-2xl "
              alt="Alphastack overview image"
              loading="lazy"
            />
          </div>
          {/* <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-20">
            <img
              src={GradientBeam}
              alt=""
              className="absolute  max-w-5xl -right-28 top-10 filter blur-2xl"
            />
            <div className="relative max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <img
                src={BannerImage}
                alt="App screenshot"
                width={2432}
                height={1442}
                className="w-1/4 rounded-md shadow-2xl"
              />
            </div>
          </div> */}
          <div className="relative mx-auto mt-2 mb-6 p-2 sm:hidden w-full ">
            <img
              src={GradientBeam}
              alt=""
              className="absolute max-w-3xl -right-20 -top-20 filter blur-2xl opacity-80 "
            />
            <img
              src={BannerImage}
              className="relative h-auto w-full rounded-xl"
              alt="Alphastack overview image"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Hero;
