/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import AlphaleadsDM from './appIcons/alphaleads-dm';
import AlphaleadsLM from './appIcons/alphaleads-lm';
import { AlphaLeadsIconDark, AlphaLeadsIconLight } from 'Utilities/icons';

function AlphaLeadsIcon() {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <>
      {theme === 'dark' ? (
        <img src={AlphaLeadsIconDark} className="w-8 h-8" />
      ) : (
        <img src={AlphaLeadsIconLight} className="w-8 h-8" />
      )}
    </>
  );
}

export default AlphaLeadsIcon;
