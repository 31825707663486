import React, { useEffect, useState } from 'react';
import PillTabNotableTraders from './pillTabNotableTraders';
import GroupWalletAddresses from './groupWalletAddresses';
import { notableTradersCols, walletsCols } from './Helper/header';
import GroupNotableTraders from './groupNotableTraders';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import { getNotableTraders } from 'statemgmt/actions';
// import { processAnalayticsData } from 'Components/Builder/EarlyHolders/helper/processData';

function NotableTraders() {
  const [notableTraders, setNotableTraders] = useState('whales');

  // const { traders, wallets, loading } = useSelector((s = initialState) => {
  //   console.log('value of s', s.notableTradersData);
  //   const tradersData = s.notableTradersData.notableTraders.data.traders;
  //   const walletData = s.notableTradersData.notableTraders.data.wallets;
  //   return {
  //     traders: tradersData, //processTradersData(tradersData),
  //     loading: s.notableTradersData.loading,
  //     wallets: walletData, //processWalletsData(walletData),
  //   };
  // });

  // const getNotableTrader = () => {
  //   dispatch(getNotableTraders(notableTraders));
  // };

  // useEffect(() => {
  //   getNotableTrader();
  // }, [notableTraders]);
  const tabOptions = [
    {
      id: '1',
      name: 'Top 5 Whales',
      tab: 'whales',
    },
    {
      id: '2',
      name: 'Top 5 Smart Money',
      tab: 'smartmoney',
    },
    {
      id: '3',
      name: 'Top 5 Holders',
      tab: 'holders',
    },
    {
      id: '4',
      name: 'Top 5 Performancer',
      tab: 'performancer',
    },
  ];

  const pillTabs = {
    tabOptions,
    state: notableTraders,
    setState: setNotableTraders,
  };

  const walletAddress = [
    { trader_address: '0x37a4df3793c1d0663586e59a161435d7d67a7f26' },
    { trader_address: '0x37a4df3793c1d0663586e59a161435d7d67a7f26' },
    { trader_address: '0x37a4df3793c1d0663586e59a161435d7d67a7f26' },
    { trader_address: '0x37a4df3793c1d0663586e59a161435d7d67a7f26' },
    { trader_address: '0x37a4df3793c1d0663586e59a161435d7d67a7f26' },
  ];
  const notableTradersArray = [
    {
      symbol: 'WETH',
      token_contract: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      token_name: 'Wrapped Ether',
      tx_count: '22',
      volume: '572.0866386599522',
      volume_usd: '1319731.81',
    },
    {
      symbol: 'WETH',
      token_contract: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      token_name: 'Wrapped Ether',
      tx_count: '22',
      volume: '572.0866386599522',
      volume_usd: '1319731.81',
    },
    {
      symbol: 'WETH',
      token_contract: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      token_name: 'Wrapped Ether',
      tx_count: '22',
      volume: '572.0866386599522',
      volume_usd: '1319731.81',
    },
  ];

  const loading = false;
  return (
    <div className="">
      <div className="grid md:grid-cols-2">
        <div>
          <PillTabNotableTraders {...pillTabs} />
        </div>
        <div>
          <GroupWalletAddresses
            walletAddresses={walletAddress}
            column={walletsCols}
            isloading={loading}
          />
        </div>
      </div>
      <div>
        <GroupNotableTraders
          walletAddresses={notableTradersArray}
          column={notableTradersCols}
          isloading={loading}
        />
      </div>
    </div>
  );
}

export default NotableTraders;
