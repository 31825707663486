/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { popupColumn } from 'Components/NFT/LatestTrades/Helper/header';
import { columnPopup } from 'Components/Token/LatestDexTrades/header';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLatestNftDexTradesByTx,
  getTokenDexTradesPopup,
} from 'statemgmt/actions';
import { processNftDexTrades, processTokenDexTrades } from './Helper/helper';
import PropTypes from 'prop-types';
import { Table } from 'Utilities';
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';

LatestTradesByTXHModal.propTypes = {
  setShowPopup: PropTypes.func.isRequired,
  showPopup: PropTypes.string.isRequired,
  txHash: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default function LatestTradesByTXHModal({
  setShowPopup,
  showPopup,
  txHash,
  action,
}) {
  // const [open, setOpen] = useState(true);
  // useDispatch() instance
  const dispatch = useDispatch();

  let cols = '';
  if (action == 'defiLatestTrades') {
    cols = columnPopup;
  }
  if (action == 'nftLatestTrades') {
    cols = popupColumn;
  }
  const block_chain = CHAINS[getUrlParam('chain')];

  // listening to store changes
  const { data, loading, theme } = useSelector((s = initialState) => {
    let data = [];
    let isLoading;
    if (action == 'defiLatestTrades') {
      data = processTokenDexTrades(s.tokenDexTradesPopup.dexTrades.data);
      isLoading = s.tokenDexTradesPopup.loading;
    }
    if (action == 'nftLatestTrades') {
      data = processNftDexTrades(
        s.nftLatestTradesByTXData.nftLatestTradesByTX.data
      );
      isLoading = s.nftLatestTradesByTXData.loading;
    }
    return {
      // block_chain: s.currentBlockChain.selectedBlockChain,
      data: data,
      loading: isLoading,
      theme: s.appTheme.theme,
    };
  });

  // useeffect hook start
  useEffect(() => {
    // dispatching request
    if (action == 'defiLatestTrades') {
      dispatch(getTokenDexTradesPopup(txHash, block_chain));
    }
    if (action == 'nftLatestTrades') {
      dispatch(getLatestNftDexTradesByTx(txHash));
    }
  }, []);

  return (
    <Transition.Root show={showPopup} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0" onClose={setShowPopup}>
        <div className="flex items-end justify-center my-40 sm:my-0 sm:min-h-screen pt-4 px-4 pb-20 h-auto text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom rounded-lg bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-5/6 sm:h-5/6 sm:p-6 ${
                theme === 'dark' ? 'bg-gray-800' : 'bg-white'
              }`}
            >
              {loading ? (
                <div
                  className={`${
                    theme === 'dark' ? 'text-white' : 'text-black'
                  } text-center animate-pulse`}
                >
                  Loading...
                </div>
              ) : (
                <div className="overflow-y-scroll overflow-x-auto metric-scroll">
                  <Table col={cols} data={data} />
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
