import { Menu, Popover, Transition } from '@headlessui/react';
import {
  FilterIcon,
  RefreshIcon,
  SortAscendingIcon,
  SwitchVerticalIcon,
} from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { useLocalStorage } from 'Hooks';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTopNftContracts } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import { ContentWrapper } from 'Utilities';
import FilterButton from 'Utilities/FilterButton/filterButton';
import Refresh from 'Utilities/Refresh/buttonRefresh';
import SelectOption from 'Utilities/SelectOption/select';
import Table from 'Utilities/Table/table';
import {
  buyFilterOptions,
  filterOptions,
  sellFilterOptions,
  token_duration,
  token_group,
  token_limit,
  token_type,
} from './Helper/constants';
import useHeaders from './Helper/header';
import { proccessCollection } from './Helper/processToken';
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';

function TopTrades(props) {
  // to fire redux actions (which emit signals)
  // state initialization start
  // for setting progress bar max value
  const [limit, setLimit] = useLocalStorage('nft_coll_limit', 20);
  const [group, setGroup] = useLocalStorage('nft_coll_group', 'whales');
  const [type, setType] = useLocalStorage('nft_col_type', 'bought');
  const [duration, setDuration] = useLocalStorage('nft_col_duration', '24hr');
  const [selected, setSelected] = useLocalStorage(
    'nft_col_filter',
    'wallet_count'
  ); // for active filter
  const [filter, setFilter] = useLocalStorage(
    'nft_col_sort_key',
    'wallet_count'
  );

  const [refresh, setRefresh] = useState(false);
  const [buysellFilter, setBuySellFilter] = useLocalStorage(
    'nftbuysellfilter',
    'buy_wallet_count'
  );

  // custom hook for managing header/column
  const [column, withbuysellcols] = useHeaders();

  // useDispatch() instance
  const dispatch = useDispatch();
  const block_chain = CHAINS[getUrlParam('chain')];

  // to subscribe to redux store
  const { nftTopContracts, loading } = useSelector((s = initialState) => {
    return {
      nftTopContracts: proccessCollection(
        s.nftTopContractsData.nftTopContracts,
        type
      ),
      loading: s.nftTopContractsData.loading,
      // block_chain: s.currentBlockChain.selectedBlockChain,
    };
  });

  const getTopNftCollectionRes = () => {
    if (type == 'bought_sold') {
      dispatch(
        getTopNftContracts(
          block_chain,
          limit,
          group,
          type,
          duration,
          buysellFilter
        )
      );
    } else {
      dispatch(
        getTopNftContracts(block_chain, limit, group, type, duration, filter)
      );
    }
  };
  // filter options
  useEffect(() => {
    getTopNftCollectionRes();
  }, [
    block_chain,
    limit,
    group,
    type,
    duration,
    filter,
    refresh,
    buysellFilter,
  ]);

  if (block_chain == 1) {
    return (
      <>
        <ContentWrapper>
          <div className="flex justify-center items-center h-96">
            <h1 className="text-2xl sm:text-4xl lg:text-5xl text-gray-500 dark:text-gray-700 font-mono italic">
              Coming Soon...
            </h1>
          </div>
        </ContentWrapper>
      </>
    );
  }
  return (
    <div className="h-full">
      {/* dropdown start */}
      <div className="dark:bg-dark-themePrimary bg-light-themeSecondary mx-4 p-4 rounded-tr-md">
        <div className="hidden sm:block">
          <div className="flow-root mt-2">
            <div className="flex justify-between mb-2">
              <div className="flex flex-wrap float-left">
                <div className="flex flex-wrap space-x-1 mr-1 mb-2">
                  <div>
                    <SelectOption
                      optionsObj={token_limit}
                      state={limit}
                      setState={setLimit}
                    />
                  </div>
                  <div>
                    <SelectOption
                      optionsObj={token_group}
                      state={group}
                      setState={setGroup}
                    />
                  </div>
                  <div>
                    <SelectOption
                      optionsObj={token_type}
                      state={type}
                      setState={setType}
                    />
                  </div>
                  <div>
                    <SelectOption
                      optionsObj={token_duration}
                      state={duration}
                      setState={setDuration}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="mx-2">
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={`
                ${open ? '' : 'text-opacity-90'}
                bg-gray-100  rounded-md flex items-center px-4 py-3 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary`}
                          >
                            <SwitchVerticalIcon
                              className={`${open ? '' : 'text-opacity-70'}
                              w-5 h-5 text-black dark:text-white cursor-pointer mouse-pointer group-hover:text-opacity-80 transition ease-in-out duration-150`}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-10  w-max max-w-md px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl">
                              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-5 bg-white dark:bg-dark-bgMutedDark">
                                {type == 'bought_sold' ? (
                                  <div className="space-y-2">
                                    <div className="dark:border-gray-700">
                                      <div className="flex flex-wrap border dark:border-gray-700 rounded-md">
                                        <div className="my-4 px-1 dark:border-gray-700 mr-1">
                                          <span className="text-center dark:text-dark-textPrimary text-xs">
                                            Buy
                                          </span>
                                        </div>
                                        <div className="m-2">
                                          {buyFilterOptions.map((item, key) => (
                                            <Tippy
                                              key={key}
                                              content={item.tooltip}
                                              placement="top"
                                            >
                                              <div className="group cursor-pointer relative inline-block my-1 flex-shrink">
                                                <FilterButton
                                                  key={key}
                                                  val={item.key}
                                                  valLabel={item.value}
                                                  filter={buysellFilter}
                                                  setFilter={setBuySellFilter}
                                                  selected={selected}
                                                  setSelected={setSelected}
                                                />
                                              </div>
                                            </Tippy>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="dark:border-gray-700">
                                      <div className="flex flex-wrap border dark:border-gray-700 rounded-md">
                                        <div className="my-4 px-1 dark:border-gray-700 mr-1">
                                          <span className="text-center dark:text-dark-textPrimary text-xs">
                                            Sell
                                          </span>
                                        </div>
                                        <div className="m-2">
                                          {sellFilterOptions.map(
                                            (item, key) => (
                                              <Tippy
                                                key={key}
                                                content={item.tooltip}
                                                placement="top"
                                              >
                                                <div className="group cursor-pointer relative inline-block my-1 flex-shrink">
                                                  <FilterButton
                                                    key={key}
                                                    val={item.key}
                                                    valLabel={item.value}
                                                    filter={buysellFilter}
                                                    setFilter={setBuySellFilter}
                                                    selected={selected}
                                                    setSelected={setSelected}
                                                  />
                                                </div>
                                              </Tippy>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex flex-wrap">
                                    {filterOptions.map((item, key) => (
                                      <Tippy
                                        key={key}
                                        content={item.tooltip}
                                        placement="top"
                                      >
                                        <div className="group cursor-pointer relative inline-block my-1 flex-shrink">
                                          <FilterButton
                                            key={key}
                                            val={item.key}
                                            valLabel={item.value}
                                            filter={filter}
                                            setFilter={setFilter}
                                            selected={selected}
                                            setSelected={setSelected}
                                          />
                                        </div>
                                      </Tippy>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                </div>
              </div>
              <div className="mx-2 my-1 flex space-x-2">
                <div className="mx-2 ">
                  <div className="flex justify-between mt-2">
                    <Refresh
                      state={refresh}
                      setState={setRefresh}
                      loadingbutton={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* filter, sort  options mobile view */}
        <div className="sm:hidden block mt-2">
          <div className="flex justify-between">
            <div className="flex space-x-2">
              <div>
                <button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                  <span className="sr-only">refresh Option</span>
                  <RefreshIcon
                    className={`w-4 h-4 text-gray-400 cursor-pointer mouse-pointer `}
                    onClick={() => {
                      setRefresh(!refresh);
                    }}
                  />
                </button>
              </div>
              <div>
                <Menu as="div" className="inline-block text-left">
                  <div>
                    <Menu.Button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                      <span className="sr-only">Open options</span>
                      <FilterIcon className="h-4 w-4" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-dark-bgMutedDark ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1 px-2">
                        <div className="flow-root mt-2">
                          <div className="flex justify-between mb-2">
                            <div className="flex flex-wrap float-left">
                              <div className="flex flex-wrap space-x-1 mb-1 border-b dark:border-gray-600 sm:border-none">
                                <div>
                                  <SelectOption
                                    optionsObj={token_limit}
                                    state={limit}
                                    setState={setLimit}
                                  />
                                </div>

                                <div>
                                  <SelectOption
                                    optionsObj={token_group}
                                    state={group}
                                    setState={setGroup}
                                  />
                                </div>
                                <div>
                                  <SelectOption
                                    optionsObj={token_type}
                                    state={type}
                                    setState={setType}
                                  />
                                </div>
                                <div>
                                  <SelectOption
                                    optionsObj={token_duration}
                                    state={duration}
                                    setState={setDuration}
                                  />
                                </div>
                              </div>

                              <div className="flex flex-wrap border-b dark:border-gray-600 sm:border-none">
                                {filterOptions.map((item, key) => (
                                  <div
                                    key={key}
                                    className="group cursor-pointer relative inline-block my-1 flex-shrink ml-1"
                                  >
                                    <FilterButton
                                      key={key}
                                      val={item.key}
                                      valLabel={item.value}
                                      filter={filter}
                                      setFilter={setFilter}
                                      selected={selected}
                                      setSelected={setSelected}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        {/* filter, sort  options mobile view */}
      </div>
      {/* dropdown end */}
      {/* table start */}

      <div className="px-4">
        <Table
          data={nftTopContracts}
          col={type == 'bought_sold' ? withbuysellcols : column}
          isloading={loading}
        />
      </div>

      {/* table end */}
    </div>
  );
}

export default TopTrades;
