export const chainProvider = (chain) => {
  switch (chain) {
    case 0:
      return 'https://etherscan.io';
    case 1:
      return 'https://polygonscan.com';
    case 2:
      return 'https://arbiscan.io';
    case 3:
      return 'https://bscscan.com';
    case 4:
      return 'https://solscan.io';
    default:
      return 'https://etherscan.io';
  }
};
