import { SCAN_ADDRESS } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import Etherscan from 'Utilities/Etherscan/etherscan';

export const column = [
  {
    Header: 'Name',
    accessor: 'name',
    Filter: '',
  },
  {
    Header: 'Holder',
    accessor: 'holder',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.holder}
        type={SCAN_ADDRESS}
        isSubString={false}
      />
    ),
    Filter: '',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    Cell: ({ cell }) => {
      return cell.row.values.balance;
    },
    Filter: '',
  },
  {
    Header: 'Wallet Labels',
    accessor: ' ',
    Cell: (cell) => {
      return 'coming soon';
    },
    Filter: '',
  },
];
