import { Popover, Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { useLocalStorage, useOnClickOutside } from 'Hooks';
import React, { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenSearch } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { ExpandArrowIcon, Select, Tooltip } from 'Utilities';
import {
  Arbitrum,
  Binance,
  EthereumLogo,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  LogoMinified,
  PolygonLogo,
  SolanaLogo,
} from 'Utilities/images';
import { NavLink } from 'react-router-dom';
import SelectTokenExplore from 'Utilities/SelectOption/selectTokenExplore';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
function TokenExplorer() {
  const [queryToken, setQueryToken] = useState('');
  const [chain, setChain] = useLocalStorage('chainId', 0);
  const [redirectOption, setRedirectOption] = useState('1');
  const [cgId, setCgId] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [isTokenSearch, setTokenSearch] = useState(false);
  let route;
  const ref = useRef();
  useOnClickOutside(ref, () => setTokenSearch(false));

  const dispatch = useDispatch();
  const processToken = (token) => {
    // to hold push result data
    let dataSet = [];
    token = token?.data;
    if (token && token.length > 0) {
      for (let key of token) {
        dataSet.push({
          name: key['f'][1]['v'],
          address: key['f'][2]['v'],
          cg_id: key['f'][7]['v'],
        });
      }
    }
    return dataSet;
  };
  const tokenSearchResultFormat = (tokenInfo) => {
    // to hold push result data
    let dataSet = [];
    // tokenInfo = tokenInfo?.data;
    if (tokenInfo && tokenInfo.length > 0) {
      for (let key of tokenInfo) {
        dataSet.push({
          contract_address: key['f'][0]['v'],
          symbol: key['f'][1]['v'],
          name: key['f'][2]['v'],
          price_usd: key['f'][3]['v'],
        });
      }
    }
    return dataSet;
  };

  const { token, tokenSearchInfo, searchLoading, theme } = useSelector(
    (s = initialState) => {
      return {
        token: processToken(s.dashboard.token),
        tokenSearchInfo: tokenSearchResultFormat(
          s.tokenSearchDetails.searchInfo
        ),
        searchLoading: s.tokenSearchDetails.loading,
        theme: s.appTheme.theme,
      };
    }
  );

  if (redirectOption === '1') {
    route = `/app/prebuilt/on-chain-analytics/token/details?contract_address=${queryToken.toLowerCase()}&tab=Overview`;
  } else {
    route = '/dashboard/wallet';
  }
  const getTokenInfo = (qToken) => {
    token.map((item) => {
      console.log(item.cg_id);
      if (item.address === qToken) {
        setCgId(item.cg_id);
        setTokenName(item.name);
      }
    });
  };

  const tokenSearch = (search_input) => {
    setQueryToken(search_input);
    setTokenSearch(false);
    let search_type;
    search_input = search_input.toLowerCase();
    if (search_input.length >= 3) {
      if (search_input.substring(0, 2).toLowerCase() == '0x') {
        search_type = 'contract_address';
      } else {
        search_type = 'symbol';
      }
      dispatch(getTokenSearch(search_input, search_type, chain));
      setTokenSearch(true);
    }
  };

  //FIXME: use more optimized way
  const chains = {
    0: 'ethereum',
    1: 'polygon',
    2: 'solana',
  };
  const groupProps = {
    optionsObj: [
      { text: 'Ethereum', value: 0, 0: EthereumLogo },
      { text: 'Polygon', value: 1, 1: PolygonLogo },
      { text: 'Solana', value: 2, 2: SolanaLogo },
    ],
    state: chain,
    setState: setChain,
  };

  return (
    <div>
      <div>
        <Popover className="static">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? 'text-gray-900' : 'text-gray-500',
                  'group rounded-md inline-flex items-center focus-ring-0'
                )}
                onClick={() => (open ? close() : { open })}
              >
                {/* <img
                  src={thermometer}
                  className={`mt-2 rounded-full py-1 mx-1`}
                  width="20"
                /> */}
                <Tooltip content="Explore tokens" placement="bottom">
                  <div className="mx-2">
                    <div className="flex items-center justify-center space-x-2 border border-blue-800 px-3 py-0.5 rounded-full">
                      <div>
                        <SearchIcon className="w-4 text-blue-800" />
                      </div>
                      <div>
                        <span className="text-xs text-gray-600 dark:text-white">
                          Explore Tokens
                        </span>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 transform -translate-x-3/4 mt-6 px-2 w-screen max-w-xl sm:px-0 bg-white dark:bg-dark-themePrimary border dark:border-gray-700 rounded-lg p-2">
                  <div className="ring-black ring-opacity-5 overflow-hidden">
                    <div className="flex justify-between m-2">
                      <div className="text-sm dark:text-white font-semibold">
                        Token Explorer
                      </div>
                      <Popover.Panel>
                        {({ close }) => (
                          <>
                            {location.pathname !=
                              '/app/prebuilt/on-chain-analytics/token' && (
                              <div>
                                <Tooltip
                                  content="Click to expand"
                                  placement="left"
                                >
                                  <a
                                    href="/app/prebuilt/on-chain-analytics/token"
                                    className=""
                                    onClick={() => close()}
                                  >
                                    <ExpandArrowIcon />
                                  </a>
                                </Tooltip>
                              </div>
                            )}
                          </>
                        )}
                      </Popover.Panel>
                    </div>
                    <div className="p-4 w-full">
                      <div className="grid grid-cols-6">
                        <div className="col-span-3">
                          <div>
                            <input
                              type="input"
                              className="flex-1 block border dark:border-gray-700 p-2 h-10 rounded dark:text-dark-textPrimary text-xs w-60 sm:w-80 dark:bg-dark-themeBgDark focus:outline-none"
                              placeholder="Search by Token Address/ Symbol/ Name"
                              value={queryToken}
                              onChange={(e) => tokenSearch(e.target.value)}
                              //   onBlur={() => setTokenSearch(false)}
                            />
                          </div>
                          {isTokenSearch ? (
                            <div className="absolute z-40 mt-4" ref={ref}>
                              <div
                                className={`bg-white dark:bg-dark-bgMutedDark border dark:border-gray-700 rounded-lg h-36 sm:w-96 overflow-y-auto ${
                                  theme === 'dark'
                                    ? 'metric-scroll-light'
                                    : 'metric-scroll-dark'
                                }`}
                              >
                                {searchLoading ? (
                                  <div className="text-center m-auto animate-pulse">
                                    Loading...
                                  </div>
                                ) : tokenSearchInfo.length > 0 ? (
                                  tokenSearchInfo.map((item, key) => (
                                    <div
                                      key={key}
                                      className="p-3 border-b dark:border-gray-600 cursor-pointer w-58 sm:w-full"
                                      onClick={() => {
                                        setQueryToken(item.contract_address);
                                        setTokenSearch(false);
                                        getTokenInfo(item.contract_address);
                                      }}
                                    >
                                      <div className="flex flex-col">
                                        <div className="flex">
                                          <p className="mr-2 dark:text-dark-textPrimary text-xs">
                                            <a
                                              href={`/app/prebuilt/on-chain-analytics/token/details?chain=${
                                                chains[chain]
                                              }&tab=Overview&contract_address=${item.contract_address.toLowerCase()}`}
                                              onClick={() => {
                                                setQueryToken(
                                                  item.contract_address
                                                );
                                                setTokenSearch(false);
                                                getTokenInfo(
                                                  item.contract_address
                                                );
                                                localStorage.setItem(
                                                  'cgid',
                                                  cgId
                                                );
                                                localStorage.setItem(
                                                  'cg_name',
                                                  tokenName
                                                );
                                              }}
                                            >
                                              <div className="flex space-x-2">
                                                <p>{item.name}</p>
                                                <p className="mr-2 dark:text-dark-textPrimary text-xs">
                                                  ${item.price_usd}
                                                </p>
                                              </div>
                                              <div className="text-gray-300 text-sm">
                                                <p>{item.contract_address}</p>
                                              </div>
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="text-center m-auto w-36 lg:w-auto my-12 text-gray-600 dark:text-dark-textPrimary text-sm">
                                    Ugh Oh! No data to show. Try with something
                                    else
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="flex justify-center col-span-2 ml-12">
                          <SelectTokenExplore {...groupProps} />
                        </div>
                        <div className="flex justify-center items-center w-6">
                          <img
                            src={groupProps.optionsObj[chain][chain]}
                            // src={chain == 0 ? EthereumLogo : PolygonLogo}
                            className="w-6 h-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center p-2">
                      <NavLink
                        to={`/app/prebuilt/on-chain-analytics/token/details?chain=${
                          chains[chain]
                        }&tab=Overview&contract_address=${queryToken.toLowerCase()}`}
                        className={`${
                          queryToken.length < 3 && 'pointer-events-none'
                        } inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                      >
                        Explore
                      </NavLink>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}

export default TokenExplorer;
