/* eslint-disable react/prop-types */
import { FilterIcon as FilterIconOutline } from '@heroicons/react/outline';
import { FilterIcon as FilterIconSolid, XIcon } from '@heroicons/react/solid';
import Modal from 'Utilities/Modal/modal';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

function InputFilter({
  column: { filterValue, preFilteredRows, setFilter, id, Header },
}) {
  const [txHash, setTxHash] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const handleApply = () => {
    if (txHash.length > 0) {
      setFilter(txHash);
      setShowPopup(false);
    }
  };

  const handleClear = () => {
    if (txHash.length > 0 || filterValue.length > 0) {
      setTxHash('');
      setFilter('');
      setShowPopup(false);
    }
  };
  return (
    <>
      <div
        className="flex text-xs space-x-1 font-semibold cursor-pointer"
        onClick={() => setShowPopup(true)}
      >
        {filterValue ? (
          <FilterIconSolid className="w-4 mx-2 text-gray-600 dark:text-gray-300" />
        ) : (
          <FilterIconOutline className="w-4 mx-2 text-gray-600 dark:text-gray-300" />
        )}
      </div>
      <Modal
        disableBackdropClick={false}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        width={'96'}
      >
        <div className="flex flex-row-reverse">
          <XIcon
            className={`w-4 cursor-pointer ${
              theme === 'dark' ? 'text-gray-600' : 'text-gray-700'
            }`}
            onClick={() => setShowPopup(false)}
          />
        </div>
        <div className="p-2 text-xs">Filter {Header}</div>
        <input
          type="input"
          className={`block border p-2 h-10 rounded text-xs w-full  dark: focus:outline-none ${
            theme === 'dark'
              ? 'bg-dark-themeBgDark border-gray-700 text-dark-textPrimary'
              : ''
          }`}
          placeholder={`Enter ${Header}`}
          value={txHash || filterValue}
          onChange={(e) => {
            setTxHash(e.target.value || undefined); //Set undefined to remove the filter entirely
          }}
        />
        <div className="flex space-x-2 mt-4 items-center justify-center">
          <div>
            <button
              type="button"
              className={`items-center px-3 py-2 border  shadow-sm text-xs font-medium rounded-md  ${
                theme === 'dark'
                  ? 'text-dark-textPrimary bg-dark-themeBgDark border-gray-700 hover:bg-gray-700'
                  : 'text-blue-700 border-blue-700 bg-white hover:bg-gray-50'
              }`}
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
          <div>
            <button
              type="button"
              className={`items-center px-3 py-2 border  shadow-sm text-xs font-medium rounded-md  ${
                theme === 'dark'
                  ? 'text-dark-textPrimary bg-dark-themeBgDark border-gray-700 hover:bg-gray-700'
                  : 'text-blue-700 border-blue-700 bg-white hover:bg-gray-50'
              }`}
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default InputFilter;
