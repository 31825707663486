import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AutoCloseForm() {
  const redirectURL = `/app/platforms/configuration/user-catalogs/new?type=mysql&step=vault-confirm`;
  const durationInSeconds = 60;
  const storageKey = 'autoCloseForm';
  const storedData = JSON.parse(localStorage.getItem(storageKey)) || {
    startTime: Math.floor(Date.now() / 1000),
  };

  const navigate = useNavigate();
  const calculateTimeRemaining = () => {
    const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
    const elapsedTime = currentTime - storedData.startTime;
    return Math.max(0, durationInSeconds - elapsedTime);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const [countdown, setCountdown] = useState(
    formatTime(calculateTimeRemaining())
  );

  const onClose = () => {
    navigate(redirectURL);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const remainingTime = calculateTimeRemaining();
      if (remainingTime === 0) {
        clearInterval(timer);
        onClose(); // Call the onClose function to close the form
        localStorage.removeItem(storageKey);
      } else {
        setCountdown(formatTime(remainingTime));
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, [onClose, durationInSeconds]);

  // Update localStorage when component unmounts or when key dependencies change
  useEffect(() => {
    localStorage.setItem(
      storageKey,
      JSON.stringify({ startTime: storedData.startTime })
    );
  }, [storedData.startTime]);

  return (
    <div>
      <p className="px-3 py-1 dark:bg-yellow-800 bg-red-200 rounded-full text-xs">
        The form will close in{' '}
        <span className="font-semibold">{countdown}</span>
      </p>
      {/* Your form content goes here */}
    </div>
  );
}
