import { useEffect, useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { Overview } from 'Utilities';
import {
  OrchestrationIcon,
  PipelineIcon,
  TransformationIcon,
} from 'Utilities/images';

import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import ExplorationIcon from 'Utilities/Icons/explorationIcon';
import {
  ExplorationIconDark,
  ExplorationIconLight,
  OrchestrationIconDark,
  OrchestrationIconLight,
  PipelineIconDark,
  PipelineIconLight,
  TraderActivityIconDark,
  TransformationIconDark,
  TransformationIconLight,
} from 'Utilities/icons';
const OVERVIEWMENU = [
  {
    title: 'Exploration',
    desc: '',
    url: '/app/data-platform/exploration',
    img: ExplorationIcon,
  },
  {
    title: 'Pipeline',
    desc: '',
    url: '',
    img: PipelineIcon,
  },
  {
    title: 'Transformation',
    desc: '',
    url: '',
    img: TransformationIcon,
  },
  {
    title: 'Orchestration',
    desc: '',
    url: '',
    img: OrchestrationIcon,
  },
];
function DataPlatform() {
  const [showAlert, setShowAlert] = useState({
    pipeline: false,
    transformation: false,
    orchestration: false,
  });

  const toolTipRef = useRef();

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const handleClick = (itm) => {
    setShowAlert((prevState) => ({ ...prevState, [itm]: true }));
  };

  useEffect(() => {
    if (showAlert) {
      toolTipRef.current = setTimeout(
        () =>
          setShowAlert((prevState) => ({
            pipeline: prevState.pipeline && false,
            transformation: prevState.transformation && false,
            orchestration: prevState.orchestration && false,
          })),
        2500
      );
    }
    return () => {
      clearTimeout(toolTipRef.current);
    };
  }, [showAlert]);

  const renderExploration = () => {
    return (
      <div className="group relative rounded-xl border border-gray-200 dark:border-gray-800 transform ease-in-out hover:-translate-y-1 hover:scale-100 duration-100">
        <div className="absolute -inset-0.5 rounded-xl border-2 border-transparent opacity-0 overview-card  group-hover:opacity-100 transition duration-100 group-hover:duration-100" />
        <div className="relative overflow-hidden rounded-xl p-6 bg-white hover:bg-sky-50 dark:bg-dark-themeSecondary h-full dark:hover:bg-gray-800">
          <img
            src={theme === 'dark' ? ExplorationIconDark : ExplorationIconLight}
            className="w-10 h-10"
          />
          <h2 className="mt-4 font-display text-base text-gray-900 dark:text-white">
            <NavLink to="/app/platforms/data-platform/exploration">
              <span className="absolute -inset-px rounded-xl" />
              Exploration
            </NavLink>
          </h2>
          <p className="mt-1 text-sm text-gray-700 dark:text-gray-400">
            Query and Visualize Alphastack hosted on-chain data and combine it
            with your own external datasets that live anywhere.
          </p>
        </div>
      </div>
    );
  };

  const renderPipeline = () => {
    return (
      <div
        className="group relative rounded-xl border border-gray-200 dark:border-gray-800 transform ease-in-out hover:-translate-y-1 hover:scale-100 duration-100"
        onClick={() => handleClick('pipeline')}
      >
        {showAlert.pipeline && (
          <div className="absolute -top-12 left-0 dark:text-white p-2 bg-gray-100 dark:bg-gray-700 rounded-md text-xs">
            Not available yet. Future release
          </div>
        )}
        <div className="absolute -inset-0.5 rounded-xl border-2 border-transparent opacity-0 overview-card  group-hover:opacity-100 transition duration-100 group-hover:duration-100" />
        <div className="relative overflow-hidden rounded-xl p-6 bg-white hover:bg-sky-50 dark:bg-dark-themeSecondary h-full dark:hover:bg-gray-800">
          <img
            src={theme === 'dark' ? PipelineIconDark : PipelineIconLight}
            className="w-10"
          />
          <h2 className="mt-4 font-display text-base text-gray-900 dark:text-white">
            <div>
              <span className="absolute -inset-px rounded-xl" />
              Pipeline
            </div>
          </h2>
          <p className="mt-1 text-sm text-gray-700 dark:text-gray-400">
            Create data pipelines with simple click of a button. Coming soon,
            stay tuned!
          </p>
        </div>
      </div>
      // </div>
    );
  };

  const renderTransformation = () => {
    return (
      <div
        className="group relative rounded-xl border border-gray-200 dark:border-gray-800 transform ease-in-out hover:-translate-y-1 hover:scale-100 duration-100"
        onClick={() => handleClick('transformation')}
      >
        {showAlert.transformation && (
          <div className="absolute -top-12 left-0 dark:text-white p-2 bg-gray-100 dark:bg-gray-700 rounded-md text-xs">
            Not available yet. Future release
          </div>
        )}
        <div className="absolute -inset-0.5 rounded-xl border-2 border-transparent opacity-0 overview-card  group-hover:opacity-100 transition duration-100 group-hover:duration-100" />
        <div className="relative overflow-hidden rounded-xl p-6 bg-white hover:bg-sky-50 dark:bg-dark-themeSecondary h-full dark:hover:bg-gray-800">
          <img
            src={
              theme === 'dark'
                ? TransformationIconDark
                : TransformationIconLight
            }
            className="w-10"
          />
          <h2 className="mt-4 font-display text-base text-gray-900 dark:text-white">
            <div>
              <span className="absolute -inset-px rounded-xl" />
              Transformation
            </div>
          </h2>
          <p className="mt-1 text-sm text-gray-700 dark:text-gray-400">
            Build, test, document and deploy complex data transformations.
            Coming soon, stay tuned!
          </p>
        </div>
      </div>
    );
  };

  const renderOrchestration = () => {
    return (
      <div
        className="group relative rounded-xl border border-gray-200 dark:border-gray-800 transform ease-in-out hover:-translate-y-1 hover:scale-100 duration-100"
        onClick={() => handleClick('orchestration')}
      >
        {showAlert.orchestration && (
          <div className="absolute -top-12 left-0 dark:text-white p-2 bg-gray-100 dark:bg-gray-700 rounded-md text-xs">
            Not available yet. Future release
          </div>
        )}
        <div className="absolute -inset-0.5 rounded-xl border-2 border-transparent opacity-0 overview-card  group-hover:opacity-100 transition duration-100 group-hover:duration-100" />
        <div className="relative overflow-hidden rounded-xl p-6 bg-white hover:bg-sky-50 dark:bg-dark-themeSecondary h-full dark:hover:bg-gray-800">
          <img
            src={
              theme === 'dark' ? OrchestrationIconDark : OrchestrationIconLight
            }
            className="w-10"
          />
          <h2 className="mt-4 font-display text-base text-gray-900 dark:text-white">
            <div>
              <span className="absolute -inset-px rounded-xl" />
              Orchestration
            </div>
          </h2>
          <p className="mt-1 text-sm text-gray-700 dark:text-gray-400">
            Automate and streamline your data pipelines. Coming soon, stay
            tuned!
          </p>
        </div>
      </div>
    );
  };
  return (
    <>
      <Overview
        title="Data Platform"
        desc="Turn blockchain data into insights using our stack of solutions
                for ingesting, transforming, querying and visualizing data."
      />
      <div className="max-w-7xl mt-6 mx-10 sm:mt-10">
        <div className="max-w-6xl">
          <div className="not-prose my-12 grid grid-cols-1 gap-10 lg:grid-cols-3">
            {renderExploration()}
            {renderPipeline()}
            {renderTransformation()}
            {renderOrchestration()}
          </div>
        </div>
      </div>
    </>
  );
}

export default DataPlatform;
