/* eslint-disable react/prop-types */
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import Tooltip from 'Utilities/Tooltip/tooltip';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const DataLastIndexed = (props) => {
  const { lastIndexed, visibility } = props;
  const renderWarning = () => {
    return (
      <div className="md:pb-0">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <Tooltip
              content="This data is not real-time. It is indexed on a schedule and there will be latency between indexing job runs. This field indicates how long ago the data was last indexed."
              placement="top"
            >
              <div className="">
                <ExclamationCircleIcon
                  className="h-4 w-4 dark:text-yellow-400 text-yellow-600"
                  aria-hidden="true"
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };
  const Loading = () => {
    return (
      <div
        className="w-4 h-4 mx-1 rounded-full animate-spin
          border-4 border-dotted border-blue-500 border-t-transparent"
      ></div>
    );
  };
  const renderLastUpdatedTime = () => {
    const date = new Date(lastIndexed * 1);
    const unixtime = date.toUTCString();
    return (
      <div className="text-xs dark:text-dark-textPrimary inline-flex sm:pb-0">
        <p className="mx-1">Data Last Indexed : </p>
        <span className="font-semibold">
          {lastIndexed ? moment.utc(unixtime).fromNow() : <Loading />}
        </span>
      </div>
    );
  };

  return visibility ? (
    <div className="flex space-x-1 items-center my-2">
      {renderLastUpdatedTime()}
      {lastIndexed && renderWarning()}
    </div>
  ) : null;
};
export default DataLastIndexed;
