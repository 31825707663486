/* eslint-disable react/prop-types */
import { useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';
// //import Tooltip from 'Utilities/Tooltip/tooltip';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SortToggle(props) {
  const [enableStable, setenableStable] = useState(props.isEnableStable);
  const { parentCallback } = props;
  function handleChange() {
    setenableStable(!enableStable);
    parentCallback(!enableStable);
  }

  return (
    <>
      <div className="m-2">
        <Tippy content={'Exclude stable and wrapped coins'} placement="top">
          <div className="flex items-center">
            <div
              onClick={() => {
                handleChange();
              }}
              className={classNames(
                enableStable
                  ? 'bg-blue-600 dark:bg-gray-400'
                  : 'bg-gray-200 dark:bg-dark-toggleBgDark',
                'inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'
              )}
            >
              {/* <Tooltip l1={'pegged coins'} /> */}
              <span
                className={classNames(
                  enableStable ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </div>
            <div className="group relative ml-3">
              <span className="text-xs font-medium text-gray-900 dark:text-dark-textPrimary">
                Exclude Pegged Coins
              </span>
            </div>
          </div>
        </Tippy>
      </div>
    </>
  );
}
