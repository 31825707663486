//  react select component style start
// const theme = localStorage.getItem('theme');
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const colourStyles = (theme) => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: theme ? '#0000' : 'white',
    height: 39,
    minHeight: 39,
    borderColor: theme ? '#4e5562' : '#e6e7eb',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'black',
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: theme ? '#0000' : 'white',
  }),

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: 'black',
    ':hover': {
      backgroundColor: '#a2a4a6',
      color: 'black',
      cursor: 'pointer',
    },
  }),
});
//  react select component style end
