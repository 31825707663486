/* eslint-disable react/prop-types */
import { ArrowSmLeftIcon } from '@heroicons/react/solid';
import React from 'react';
import { useSelector } from 'react-redux';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';

function SubmitForm({
  noToken,
  setNoToken,
  validUrl,
  projectname,
  setProjectName,
  ticker,
  setTicker,
  website,
  setWebsite,
  markets,
  setMarkets,
  alpha,
  setAlpha,
  sentiments,
  setSentiments,
  effects,
  setEffects,
  comments,
  setComments,
  errors,
  alphaButton,
  setAlphaButton,
  showForm,
  setShowForm,
  setShowUrlForm,
  setSourceUrl,
  setshowSubmit,
  Urlform,
}) {
  // listening to store changes
  const { preload, theme } = useSelector((s = initialState) => {
    return {
      preload: s.leakFormPreload.preload.data,
      theme: s.appTheme.theme,
    };
  });
  return (
    <>
      {showForm && (
        <>
          <ArrowSmLeftIcon
            className={`h-5 w-5 cursor-pointer mb-3 ${
              theme == 'dark' ? 'text-dark-textPrimary' : ''
            }`}
            onClick={() => {
              setShowForm(!showForm);
              setAlphaButton('');
              setShowUrlForm(true);
              setSourceUrl('');
              setAlphaButton(alphaButton);
              setNoToken(false);
              setshowSubmit(false);
            }}
          />
          <div className="grid grid-cols-6 sm:gap-3 md:gap-3">
            {!noToken ? (
              <>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="project_name"
                    className={`block text-sm text-left font-medium mb-1 ${
                      theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    {alphaButton == 'token' ? 'Project Name' : 'Collection'}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={projectname}
                    disabled
                    onChange={(e) => setProjectName(e.target.value)}
                    className={`rounded py-3 px-3 w-full shadow border text-sm placeholder-gray-600 focus:outline-none focus:shadow-outline ${
                      theme === 'dark'
                        ? ' text-gray-300 border-gray-700'
                        : 'bg-white text-gray-700'
                    }  ${
                      validUrl
                        ? theme == 'dark'
                          ? 'bg-gray-500'
                          : 'bg-gray-200'
                        : ''
                    }`}
                    placeholder=" Eg: Bitcoin"
                  />
                  <span className="text-red-500 text-xs">
                    {errors.projectname}
                  </span>
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="ticker"
                    className={`block text-sm text-left font-medium   mb-1 ${
                      theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    {alphaButton == 'token' ? 'Ticker' : 'Token ID'}
                  </label>
                  <input
                    type="text"
                    value={ticker.toUpperCase()}
                    disabled
                    onChange={(e) => setTicker(e.target.value)}
                    className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                      theme === 'dark'
                        ? 'text-gray-300 border-gray-700'
                        : 'bg-white text-gray-700'
                    } ${
                      validUrl
                        ? theme == 'dark'
                          ? 'bg-gray-500'
                          : 'bg-gray-200'
                        : ''
                    }`}
                    placeholder=" Eg: BTC"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="website"
                    className={`block text-sm text-left font-medium   mb-1 ${
                      theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Website
                  </label>
                  <input
                    type="text"
                    value={website}
                    disabled
                    onChange={(e) => setWebsite(e.target.value)}
                    className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                      theme === 'dark'
                        ? 'text-gray-300 border-gray-700'
                        : 'bg-white text-gray-700'
                    }  ${
                      validUrl
                        ? theme == 'dark'
                          ? 'bg-gray-500'
                          : 'bg-gray-200'
                        : ''
                    }`}
                    placeholder="Eg: https://bitcoin.org/"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="markets"
                    className={`block text-sm text-left font-medium   mb-1 ${
                      theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Markets
                  </label>
                  <input
                    type="text"
                    value={markets}
                    disabled
                    onChange={(e) => setMarkets(e.target.value)}
                    className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                      theme === 'dark'
                        ? 'text-gray-300 border-gray-700'
                        : 'bg-white text-gray-700'
                    } ${
                      validUrl
                        ? theme == 'dark'
                          ? 'bg-gray-500'
                          : 'bg-gray-200'
                        : ''
                    } `}
                    placeholder=" Coingecko URL"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="project_name"
                    className={`block text-sm text-left font-medium   mb-1 ${
                      theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    {alphaButton == 'token' ? 'Project Name' : 'Collection'}
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={projectname}
                    onChange={(e) => setProjectName(e.target.value)}
                    className={`rounded py-3 px-3 w-full shadow border text-sm placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                      theme === 'dark'
                        ? 'bg-gray-700 text-gray-300 border-gray-700'
                        : 'bg-white text-gray-700'
                    }`}
                    placeholder={
                      alphaButton == 'token' ? 'Bitcoin' : 'CryptoPunks'
                    }
                  />
                  <span className="text-red-500 text-xs">
                    {errors.projectname}
                  </span>
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="ticker"
                    className={`block text-sm text-left font-medium   mb-1 ${
                      theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    {alphaButton == 'token' ? 'Ticker' : 'Token ID'}
                  </label>
                  <input
                    type="text"
                    value={ticker}
                    onChange={(e) => setTicker(e.target.value)}
                    className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                      theme === 'dark'
                        ? 'bg-gray-700 text-gray-300 border-gray-700'
                        : 'bg-white text-gray-700'
                    }`}
                    placeholder={alphaButton == 'token' ? 'BTC' : 'PUNK'}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="website"
                    className={`block text-sm text-left font-medium   mb-1 ${
                      theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Website
                  </label>
                  <input
                    type="text"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                      theme === 'dark'
                        ? 'bg-gray-700 text-gray-300 border-gray-700'
                        : 'bg-white text-gray-700'
                    }`}
                    placeholder={
                      alphaButton == 'token'
                        ? 'https://bitcoin.org/'
                        : 'https://www.larvalabs.com/cryptopunks'
                    }
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-3">
                  <label
                    htmlFor="markets"
                    className={`block text-sm text-left font-medium   mb-1 ${
                      theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Market
                  </label>
                  <input
                    type="text"
                    value={markets}
                    onChange={(e) => setMarkets(e.target.value)}
                    className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                      theme === 'dark'
                        ? 'bg-gray-700 text-gray-300 border-gray-700'
                        : 'bg-white text-gray-700'
                    }`}
                    placeholder={
                      alphaButton == 'token'
                        ? 'https://www.coingecko.com/en/coins/bitcoin'
                        : 'https://opensea.io/collection/cryptopunks'
                    }
                  />
                </div>
              </>
            )}
            <div className="col-span-6 mb-3">
              <label
                htmlFor="alpha"
                className={`block text-sm text-left font-medium   mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                }`}
              >
                Alpha <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={alpha}
                onChange={(e) => setAlpha(e.target.value)}
                className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                  theme === 'dark'
                    ? 'bg-gray-700 text-gray-300 border-gray-700'
                    : 'bg-white text-gray-700'
                }`}
                placeholder=" Eg: Big Upcoming News!"
              />
            </div>

            <div className="col-span-6 sm:col-span-3 mb-3">
              <label
                htmlFor="sentiment"
                className={`block text-sm text-left font-medium   mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                }`}
              >
                Sentiment <span className="text-red-500">*</span>
              </label>
              <select
                className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                  theme === 'dark'
                    ? 'bg-gray-700 text-gray-300 border-gray-700'
                    : 'bg-white text-gray-700'
                }`}
                value={sentiments}
                onChange={(e) => setSentiments(e.target.value)}
              >
                <option className="text-gray-500" defaultValue="null" disabled>
                  Select sentiment
                </option>
                {preload && preload.response.length > 0
                  ? preload.response[0].sentiment.map((item) => (
                      <option key={item.sentiment} value={item.sentiment}>
                        {item.sentiment}
                      </option>
                    ))
                  : null}
              </select>
            </div>

            <div className="col-span-6 sm:col-span-3 mb-3">
              <label
                htmlFor="effect_seen"
                className={`block text-sm text-left font-medium   mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                }`}
              >
                Effect seen in <span className="text-red-500">*</span>
              </label>
              <select
                className={`rounded py-3 px-3 w-full  shadow border  text-sm  placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                  theme === 'dark'
                    ? 'bg-gray-700 text-gray-300 border-gray-700'
                    : 'bg-white text-gray-700'
                }`}
                value={effects}
                onChange={(e) => setEffects(e.target.value)}
              >
                <option className="text-gray-500" defaultValue="null" disabled>
                  Select Effect
                </option>
                {preload && preload.response.length > 0
                  ? preload.response[0].effect.map((item) => (
                      <option
                        key={item.effect_period}
                        value={item.effect_period}
                      >
                        {item.effect_period}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="col-span-6 mb-3">
              <label
                htmlFor="comments"
                className={`block text-sm text-left font-medium   mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
                }`}
              >
                Comments <span className="text-red-500">*</span>
              </label>
              <textarea
                cols={10}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className={`rounded py-3 px-3 w-full  shadow border text-sm placeholder-gray-400 focus:outline-none focus:shadow-outline ${
                  theme === 'dark'
                    ? 'bg-gray-700 text-gray-300 border-gray-700'
                    : 'bg-white text-gray-700'
                }`}
                placeholder=" Eg: Expecting Possitive Sentiment"
              />
            </div>
            <div className="col-span-6 text-left">
              <div className="text-gray-400  text-sm">
                Marked fileds(*) are mandatory
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SubmitForm;
