/* eslint-disable react/prop-types */
import { InformationCircleIcon } from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { Tooltip } from 'Utilities';
import { NavLink, useNavigate } from 'react-router-dom';

export default function AlphaLeadTab({ tabOptions, basePath }) {
  const navigate = useNavigate();
  const handleRoute = (e) => {
    navigate(`${e.target.value}`);
  };
  return (
    <div className="dark:border-gray-700">
      {/* mobile view */}
      <div className="sm:hidden flex -mb-3">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block px-2 py-2 bg-light-themeSecondary dark:bg-dark-tabPillBgSelectedDark dark:text-white mb-3 rounded-t-md text-sm"
          onChange={handleRoute}
        >
          {tabOptions.map((tab) => (
            <option key={tab.id} value={tab.route}>
              {tab.name}
              {/* <InformationCircleIcon className="lg:h-4 w-4 ml-0.5 inline-flex" /> */}
            </option>
          ))}
        </select>
      </div>
      {/* default view */}
      <div className="hidden sm:block">
        <div className="relative">
          <nav
            className="flex space-x-2 dark:text-dark-textPrimary lg:justify-start"
            aria-label="Tabs"
          >
            {tabOptions.map((tab) => (
              <div key={tab.name} className="rounded">
                <div>
                  <div className="flex hover:bg-gray-200 dark:hover:bg-transparent rounded-full">
                    <NavLink
                      to={`${tab.route}`}
                      className={({ isActive }) =>
                        `flex gap-3 group relative  cursor-pointer rounded-t-lg py-2 px-4 font-semibold text-xs dark:text-white ${
                          isActive
                            ? 'font-semibold text-blue-700 antialiased rounded-t-lg py-2 px-4 active dark:text-white bg-light-themeSecondary dark:bg-dark-themePrimary dark:border-dark-tabPillBgSelectedDark'
                            : ''
                        }`
                      }
                    >
                      {tab.name}
                      <Tooltip content={tab.tooltip} placement="top">
                        <div>
                          <InformationCircleIcon className="lg:h-4 w-4 ml-0.5 inline-flex" />
                        </div>
                      </Tooltip>
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
