/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ArrowCircleDownIcon } from '@heroicons/react/solid';

function DexTradeActivity({ activity }) {
  const activityHandlers = [
    {
      activity: 'buy',
      badgeColor: 'green',
      leadText: 'buy',
    },
    {
      activity: 'bought',
      badgeColor: 'green',
      leadText: 'buy',
    },
    {
      activity: 'sell',
      badgeColor: 'red',
      leadText: 'sell',
    },
    {
      activity: 'sold',
      badgeColor: 'red',
      leadText: 'sell',
    },
    {
      activity: 'inflow',
      badgeColor: 'green',
      leadText: 'inflow',
    },
    {
      activity: 'outflow',
      badgeColor: 'red',
      leadText: 'outflow',
    },
    {
      activity: 'add',
      badgeColor: 'green',
      leadtext: 'add',
    },
    {
      activity: 'remove',
      badgeColor: 'red',
      leadtext: 'remove',
    },
  ];

  return (
    <div>
      {activity === 'buy' || activity === 'bought' ? (
        <span className="px-4 py-0.5 inline-flex font-medium leading-5 rounded-full text-green-500">
          buy
        </span>
      ) : activity === 'sell' || activity === 'sold' ? (
        <span className="px-4 py-0.5 inline-flex font-medium leading-5 rounded-full text-red-500">
          sell
        </span>
      ) : activity === 'inflow' ? (
        <span className="px-4 py-0.5 inline-flex font-medium leading-5 rounded-full text-green-500">
          inflow
        </span>
      ) : activity === 'outflow' ? (
        <span className="px-4 py-0.5 inline-flex font-medium leading-5 rounded-full text-red-500">
          outflow
        </span>
      ) : activity === 'add' ? (
        <span className="px-4 py-0.5 inline-flex font-medium leading-5 rounded-full text-green-500">
          add
        </span>
      ) : activity === 'remove' ? (
        <span className="px-4 py-0.5 inline-flex font-medium leading-5 rounded-full text-red-500">
          remove
        </span>
      ) : null}
    </div>
  );
}

export default DexTradeActivity;
