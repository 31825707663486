export const token_group = [
  { text: 'All', value: 'all' },
  { text: 'Smart Money', value: 'smart_money' },
  { text: 'Whales', value: 'whales' },
];

export const token_vol_limit = [
  { text: 'All Volumes', value: 'all' },
  { text: 'volume > $50k', value: '2' },
  { text: 'volume between $10k and $50k', value: '3' },
  { text: 'volume < $10k', value: '4' },
];
