import {
  DocumentDuplicateIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import {
  ApiIcon,
  ChainAnalytics,
  CogIcon,
  Community,
  DataPlatform,
  MLPlatforms,
} from 'Utilities/images';

export const navigation = [
  {
    basic: [
      {
        name: 'Welcome',
        slug: 'welcome',
        current: true,
        slug: 'welcome',
        // href: 'home/welcome',
        children: [
          {
            name: 'Quick Access',
            href: 'home/welcome/quick-access',
            slug: 'quick-access',
          },
          // {
          //   name: 'Dashboard',
          //   slug: '',
          //   icon: DashboardIcon,
          //   current: true,
          //   href: 'home/welcome/',
          // },
          {
            name: 'Macro',
            href: 'home/welcome/macro',
            slug: 'macro',
          },
          {
            name: 'Metrics',
            href: 'home/welcome/metrics',
            slug: 'metrics',
          },
          {
            name: 'News',
            slug: 'news',
            href: 'home/welcome/news',
          },
        ],
      },
    ],
  },
  {
    social: [
      {
        name: 'Community Alpha',
        slug: 'community-alpha',
        icon: Community,
        current: false,
        children: [
          {
            name: 'How it works',
            href: 'social/community-alpha/how-it-works',
            slug: 'how-it-works',
          },
          {
            name: 'Alpha Leads',
            href: 'social/community-alpha/alpha-leads/lead-list',
            slug: 'alpha-leads',
          },
          {
            name: 'Alpha Requests',
            href: 'social/community-alpha/alpha-request',
            slug: 'alpha-request',
          },
        ],
      },
    ],
  },
  {
    prebuilt: [
      {
        name: 'On Chain Analytics',
        slug: 'on-chain-analytics',
        icon: ChainAnalytics,
        current: false,
        children: [
          {
            name: 'Overview',
            href: 'prebuilt/on-chain-analytics/overview',
            slug: 'overview',
          },
          {
            name: 'Token Details',
            href: 'prebuilt/on-chain-analytics/token',
            slug: 'token',
          },
          {
            name: 'Trader Activity',
            href: 'prebuilt/on-chain-analytics/trader-activity',
            slug: 'trader-activity',
          },
          {
            name: 'Defi',
            href: 'prebuilt/on-chain-analytics/defi?chain=ethereum&tab=TopDexTrades&limit=20&group=whales&type=bought_sold&duration=24hr&sort_key=wallet_count',
            slug: 'defi',
          },
          {
            name: 'NFT',
            href: 'prebuilt/on-chain-analytics/nft?chain=ethereum&tab=NFTDrops',
            slug: 'nft',
          },
          // { name: 'Gaming', href: 'on-chain-analytics/gaming' },
          {
            name: 'Insights',
            href: 'prebuilt/on-chain-analytics/insights',
            slug: 'insights',
          },
        ],
      },
    ],
  },
  {
    platforms: [
      {
        name: 'Configuration',
        slug: 'configuration',
        icon: CogIcon,
        current: false,
        children: [
          {
            name: 'Overview',
            href: 'platforms/configuration',
            slug: 'configuration',
          },
          {
            name: 'Cluster',
            href: 'platforms/configuration/user-clusters',
            slug: 'user-cluster',
          },
          {
            name: 'Catalog',
            href: 'platforms/configuration/user-catalogs',
            slug: 'user-catalogs',
          },
        ],
      },
      {
        name: 'Data Platform',
        slug: 'data-platform',
        icon: DataPlatform,
        current: false,
        children: [
          {
            name: 'Overview',
            href: 'platforms/data-platform/overview',
            slug: 'data-platform-overview',
          },
          {
            name: 'Exploration',
            href: 'platforms/data-platform/exploration',
            slug: 'exploration',
          },
          // {
          //   name: 'Pipeline',
          //   href: 'data-platform/pipeline',
          //   slug: 'pipeline',
          // },
          // {
          //   name: 'Transformation',
          //   href: 'data-platform/transformation',
          //   slug: 'transformation',
          // },
          // {
          //   name: 'Orchestration',
          //   href: 'data-platform/orchestration',
          //   slug: 'orchestration',
          // },
        ],
      },

      {
        name: 'ML Platform',
        slug: 'ml-platform',
        icon: MLPlatforms,
        current: false,
        children: [
          {
            name: 'Overview',
            href: 'platforms/ml-platform/overview',
            slug: 'ml-overview',
          },
          // {
          //   name: 'Model Building',
          //   href: 'ml-platform/model-building',
          //   slug: 'model-building',
          // },
          // { name: 'Training', href: 'ml-platform/training', slug: 'training' },
          // {
          //   name: 'Deployment',
          //   href: 'ml-platform/deployment',
          //   slug: 'deployment',
          // },
        ],
      },
    ],
  },
  {
    integrations: [
      {
        name: 'Api',
        slug: 'api',
        icon: ApiIcon,
        current: false,
        children: [
          {
            name: 'Overview',
            href: 'integrations/api/overview',
            slug: 'api-overview',
          },
        ],
      },
    ],
  },
];
export const earnToken = [
  { name: 'Submit Alpha Leads', initiator: '0' },
  { name: 'Submit Wallet Labels', initiator: '1' },
];

export const resource = [
  { resource: 'Product Document', icon: DocumentTextIcon, href: '#' },
  { resource: 'API Document', icon: DocumentDuplicateIcon, href: '#' },
];
