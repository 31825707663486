/* eslint-disable react/prop-types */
import { XIcon } from '@heroicons/react/solid';
import React from 'react';

function BannerBottom({ setShowBanner }) {
  return (
    <div>
      <div className="fixed inset-x-0 bottom-2 max-w-3xl mx-auto">
        <div className="bg-theme">
          <div className="mx-auto max-w-4xl py-1 px-3 sm:px-6">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <p className="ml-1 truncate font-medium text-white">
                  <span className="inline text-xs sm:text-sm">
                    This feature is currently in beta and is only available to a
                    closed group of users.
                  </span>
                </p>
              </div>

              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  className="-mr-1 flex rounded-md p-2 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon
                    className="h-4 w-4 text-white"
                    aria-hidden="true"
                    onClick={() => setShowBanner(null)}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerBottom;
