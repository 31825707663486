import Tooltip from 'Utilities/Tooltip/tooltip';
import PropTypes from 'prop-types';
function FilterButton({ value, label, tooltip, filter, setFilter }) {
  return (
    <div>
      <div className="cursor-pointer relative inline-block mr-2 flex-shrink">
        <Tooltip content={tooltip} placement="top">
          <span
            className={`inline-flex items-center px-4 py-2 cursor-pointer rounded-full text-xs bg-blue-50 dark:bg-dark-filterBgDark dark:text-dark-textPrimary font-medium text-blue-800 ${
              filter === value
                ? ' text-blue-600 border dark:text-dark-textPrimary border-blue-700 font-semibold'
                : ''
            }`}
            onClick={() => {
              setFilter(value);
            }}
          >
            {label}
          </span>
        </Tooltip>
      </div>
    </div>
  );
}

export default FilterButton;

FilterButton.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
};
