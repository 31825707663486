import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { initialState } from './initialState';
import rootReducer from './reducers';

export const configureStore = () => {
  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(thunk))
  );
};
