/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import ErrorIcon from 'Utilities/Icons/errorIcon';

export const Field = forwardRef(
  (
    {
      disabled,
      dot,
      error,
      icon,
      label,
      name,
      type,
      theme,
      selectKey,
      ...rest
    },
    ref
  ) => {
    const style = {
      dot: `after:content-['*'] after:ml-0.5 after:text-red-500`,
      error: `ring-red-500 ring-1`,
      disabled: `cursor-not-allowed`,
      container: `relative mb-6 mt-3`,
      errorMessage: `text-sm text-red-500 mt-2`,
      checkboxLabel: `block overflow-hidden h-6 rounded-full bg-gray-300`,
      checkboxContainer: `relative w-10 mr-2 align-middle select-none mt-2`,
      iconContainer: `absolute flex border border-transparent left-0 top-0 h-full w-10`,
      icon: `flex items-center justify-center rounded-tl rounded-bl z-10 text-gray-400 text-lg h-full w-full`,
      checkbox: `checked:bg-blue-500 checked:right-0 focus:outline-none right-4 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`,
      default: `text-sm relative flex flex-1 w-full mt-1 rounded-md py-2 px-4 bg-white border ${
        theme == 'dark' && 'bg-gray-900 border-gray-700 text-gray-100'
      }  placeholder-gray-400 focus:outline-none focus:ring-1 focus:border-transparent`,
      label: `text-sm font-medium  ${theme == 'dark' && 'text-gray-200'}`,
      inputSelectOption: `h-full py-0 pl-2 pr-1 bg-transparent text-gray-500 text-xs rounded-md ${
        theme == 'dark' && 'text-gray-100'
      }`,
    };

    let component;
    if (type === 'select') {
      component = (
        <select
          aria-required={dot}
          aria-invalid={!!error}
          className={`${style.default} ${disabled ? style.disabled : ''}
             ${error ? style.error : 'border-gray-300'}
          `}
          disabled={disabled}
          id={name}
          name={name}
          ref={ref}
          {...rest}
        />
      );
    }

    if (type === 'textarea') {
      component = (
        <textarea
          aria-required={dot}
          aria-invalid={!!error}
          className={`${style.default} ${disabled ? style.disabled : ''}
             ${error ? style.error : 'border-gray-300'}
          `}
          disabled={disabled}
          id={name}
          name={name}
          ref={ref}
          rows="6"
          {...rest}
        />
      );
    }

    if (type == 'input-with-dropdown') {
      component = (
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            name={name}
            id={name}
            disabled={disabled}
            className={`${style.default} ${disabled ? style.disabled : ''}
            ${error ? style.error : 'border-gray-300'}
         `}
            placeholder="0.00"
            ref={ref}
            // {...rest}
          />
          <div className="absolute inset-y-0 right-1 flex items-center">
            <select
              id={name}
              name={name}
              className={`${style.inputSelectOption}`}
              ref={ref}
              {...rest}
            />
          </div>
        </div>
      );
    }

    // if you won't use checkbox, you can delete this part and the classes checkbox, checkboxContainer and checkboxLabel
    if (type === 'checkbox') {
      component = (
        <div className={style.checkboxContainer}>
          <input
            aria-required={dot}
            aria-invalid={!!error}
            className={`${style.checkbox} ${disabled ? style.disabled : ''}`}
            disabled={disabled}
            id={name}
            name={name}
            type="checkbox"
            {...rest}
          />
          <span className={style.checkboxLabel} />
        </div>
      );
    }
    if (type == 'default') {
      component = (
        <div className="relative">
          <div className={style.iconContainer}>
            <div className={style.icon}>{icon}</div>
          </div>
          <input
            aria-required={dot}
            aria-invalid={!!error}
            className={`${style.default} ${icon ? 'pl-12' : ''}
               ${error ? style.error : 'border-gray-300'}
               ${disabled ? style.disabled : ''}
            `}
            disabled={disabled}
            id={name}
            name={name}
            type={type}
            ref={ref}
            {...rest}
          />
        </div>
      );
    }

    return (
      <div className={`${style.container} ${disabled ? 'opacity-50' : ''}`}>
        <label htmlFor={name} className={`${style.label} ${dot && style.dot}`}>
          {label}
        </label>
        {component}
        {error && (
          <span role="alert" className={style.errorMessage}>
            {error}
          </span>
        )}
      </div>
    );
  }
);

Field.displayName = 'Field';
