/* eslint-disable react/prop-types */
import React from 'react';
import ColumnChart from './columnChart';
import ChartWrapper from './chartWrappers';

function DistributionChart({ data }) {
  const renderHighlySuccssTraders = () => {
    return (
      <ChartWrapper title="Recent Highly Successful Traders">
        <ColumnChart
          buy={data.buy[6]?.recent_top_performing_traders_total_tokens || 0}
          sell={data.sell[6]?.recent_top_performing_traders_total_tokens || 0}
          trade_date={data.sell[4]?.trade_date || 0}
          title="Volume (Tokens)"
          type="column"
        />
        <ColumnChart
          buy={data.buy[7]?.recent_top_performing_traders_total_usd || 0}
          sell={data.sell[7]?.recent_top_performing_traders_total_usd || 0}
          trade_date={data.sell[4]?.trade_date || 0}
          title="Volume (USD)"
          type="column"
        />
        <ColumnChart
          buy={data.buy[5]?.recent_top_performing_traders || 0}
          sell={data.sell[5]?.recent_top_performing_traders || 0}
          trade_date={data.sell[4]?.trade_date || 0}
          title="Wallet Count"
          type="line"
        />
      </ChartWrapper>
    );
  };

  const renderWhales = () => {
    return (
      <ChartWrapper title="Whales">
        <ColumnChart
          buy={data.buy[9]?.whales_total_tokens || 0}
          sell={data.sell[9]?.whales_total_tokens || 0}
          trade_date={data.sell[4]?.trade_date || 0}
          title="Volume (Tokens)"
          type="column"
        />
        <ColumnChart
          buy={data.buy[10]?.whales_total_usd || 0}
          sell={data.sell[10]?.whales_total_usd || 0}
          trade_date={data.sell[4]?.trade_date || 0}
          title="Volume (USD)"
          type="column"
        />
        <ColumnChart
          buy={data.buy[8]?.whales || 0}
          sell={data.sell[8]?.whales || 0}
          trade_date={data.sell[4]?.trade_date || 0}
          title="Wallet Count"
          type="line"
        />
      </ChartWrapper>
    );
  };

  const renderSmartMoney = () => {
    return (
      <ChartWrapper title="Smart Money">
        <ColumnChart
          buy={data.buy[12]?.smart_money_total_token || 0}
          sell={data.sell[12]?.smart_money_total_tokens || 0}
          trade_date={data.sell[4]?.trade_date || 0}
          title="Volume (Tokens)"
          type="column"
        />
        <ColumnChart
          buy={data.buy[13]?.smart_money_total_usd || 0}
          sell={data.sell[13]?.smart_money_total_usd || 0}
          trade_date={data.sell[4]?.trade_date || 0}
          title="Volume (USD)"
          type="column"
        />
        <ColumnChart
          buy={data.buy[11]?.smart_money_total || 0}
          sell={data.sell[11]?.smart_money_total || 0}
          trade_date={data.sell[4]?.trade_date || 0}
          title="Wallet Count"
          type="line"
        />
      </ChartWrapper>
    );
  };
  return (
    <>
      {renderHighlySuccssTraders()}
      {renderWhales()}
      {renderSmartMoney()}
    </>
  );
}

export default DistributionChart;
