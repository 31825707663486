/* eslint-disable react/prop-types */
import { InformationCircleIcon } from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { NavLink, useNavigate } from 'react-router-dom';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
const tdt_tooltip = ' Alpha leaks submitted by me';
const ldt_tooltip = ' Wallet labels submitted by me';
const tabs = [
  {
    name: 'My Alpha Leads',
    id: '1',
    tooltip: tdt_tooltip,
    route: 'my-alpha-leads',
  },
  {
    name: 'My Wallet Submissions',
    id: '2',
    tooltip: ldt_tooltip,
    route: 'my-wallet-submissions',
  },
  {
    name: 'My Alpha Requests',
    id: '3',
    tooltip: 'Alpha requests submitted by me',
    route: 'my-alpha-requests',
  },
];
function ProfileTab({ path }) {
  const navigate = useNavigate();
  const handleRoute = (e) => {
    navigate(`${e.target.value}`);
  };
  return (
    <div className="dark:border-gray-700">
      {/* mobile view */}
      <div className="sm:hidden flex -mb-3">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block px-2 py-2 bg-light-themeSecondary dark:bg-dark-themePrimary dark:text-white mb-3 rounded-t-md text-sm"
          onChange={handleRoute}
        >
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.route}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      {/* default view */}
      <div className="hidden sm:block">
        <div className="relative">
          <nav
            className="flex space-x-2 dark:text-dark-textPrimary lg:justify-start"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <div key={tab.name} className="rounded">
                <div>
                  <Tippy content={tab.tooltip} placement="top">
                    <div className="flex">
                      <NavLink
                        to={`${tab.route}`}
                        // className="group relative text-gray-600 cursor-pointer rounded-t-lg p-4 font-semibold text-xs dark:text-white"
                        // activeClassName="font-semibold text-gray-800 antialiased rounded-t-lg p-4 active dark:text-white bg-light-themeSecondary dark:bg-dark-themePrimary"
                        className={({ isActive }) =>
                          `group relative text-gray-600 cursor-pointer rounded-t-lg p-4 font-semibold text-xs dark:text-white ${
                            isActive
                              ? 'font-semibold text-gray-800 antialiased rounded-t-lg p-4 active dark:text-white bg-light-themeSecondary dark:bg-dark-themePrimary'
                              : ''
                          }`
                        }
                      >
                        {tab.name}
                        <InformationCircleIcon className="lg:h-4 w-4 ml-0.5 inline-flex" />
                      </NavLink>
                    </div>
                  </Tippy>
                </div>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default ProfileTab;
