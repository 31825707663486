import { ChevronLeftIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentWrapper } from 'Utilities';
import AuthToS3 from './authToS3';
import CatalogInfo from './catalogInfo';
import MetaStore from './metaStore';

function AmazonS3() {
  const [msType, setMsType] = useState(1);
  const navigate = useNavigate();
  const deafultTableFormats = [
    { id: 1, title: 'Iceberg' },
    { id: 2, title: 'Hive' },
    { id: 3, title: 'Delta Lake' },
  ];
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-3xl">
        <div className="dark:text-white my-4 text-lg font-semibold">
          Amazon S3
        </div>
        <p className="dark:text-dark-textPrimary py-2 text-sm">
          Configure your catalog to query objects in Amazon S3
        </p>
        <CatalogInfo />
        <AuthToS3 />
        <MetaStore msType={msType} setMsType={setMsType} />

        <div>
          <h1 className="dark:text-dark-textPrimary py-3  text-sm font-semibold">
            Default table format
          </h1>
          <p className="dark:text-dark-textPrimary pb-4 text-sm text-justify">
            Select the default table format used for creating new tables. The
            catalog will be able to read from any type.
          </p>
          <span className="text-gray-400 dark:text-gray-500 text-xs">
            Default table format*
          </span>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-4 mt-2">
            {deafultTableFormats.map((item) => (
              <div key={item.id} className="flex items-center">
                <input
                  id={`${item.id}-n`}
                  name="notification-method"
                  type="radio"
                  defaultChecked={item.id === 1}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor={`${item.id}-n`}
                  className="ml-3 block text-sm font-medium dark:text-dark-textPrimary"
                >
                  {item.title}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="my-2">
          <h1 className="dark:text-dark-textPrimary py-3  text-md font-semibold">
            Test Configuration
          </h1>
          <p className="dark:text-dark-textPrimary pb-4 text-sm text-justify">
            Validate that the network configuration allows Starburst Galaxy to
            connect to the data source.
          </p>
          <div className="mt-6">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              // onClick={handleClick}
            >
              Test Connection
            </button>
          </div>
        </div>

        <div className="flex justify-between items-center mt-10">
          {/* <div
            className="flex items-center space-x-2 dark:text-dark-textPrimary cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="w-3" />
            <p>Back</p>
          </div> */}
          <div className="">
            <button
              type="button"
              disabled
              className="inline-flex items-center rounded-md border border-transparent bg-blue-200 px-4 py-1.5 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              // onClick={handleClick}
            >
              Connect catalog
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmazonS3;
