import { useThemeToggle } from 'Hooks';
import { Spinner } from 'Utilities';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './authProvider';

function AuthCallback() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [colorTheme] = useThemeToggle();

  const location = useLocation();
  const from = location.state?.from?.pathname || '/verify';

  useEffect(() => {
    const processSigninCallback = async () => {
      await auth.completeAuthentication();
      navigate(from, { replace: true });
    };
    void processSigninCallback();
  }, [auth, navigate, from]);

  return (
    <div
      className={`h-screen flex justify-center items-center ${
        colorTheme == 'dark' || colorTheme == undefined || colorTheme == ''
          ? 'bg-light-themePrimary'
          : 'bg-dark-themeSecondary'
      }`}
    >
      <Spinner />
    </div>
  );
}

export default AuthCallback;
