/* eslint-disable react/prop-types */
/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { LeaksDetailsPageHeading } from 'Components/AlphaLeads/LeadsDetails/heading';
import LeaksDetails from 'Components/AlphaLeads/LeadsDetails/leadsDetails';
import { WalletDetailsPageHeading } from 'Components/EarnToken/SubmitWallet/SubmissionDetails/heading';
import SubmissionDetails from 'Components/EarnToken/SubmitWallet/SubmissionDetails/submissionDetails';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

export default function SlideOver({ show, setShow, value, component }) {
  const [open, setOpen] = useState(true);
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <Transition.Root show={show ? show : false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={setShow}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-600"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div
                  className={`h-full flex flex-col bg-white shadow-xl overflow-y-scroll ${
                    theme === 'dark' ? 'bg-gray-800' : null
                  }`}
                >
                  <div
                    className={`px-4 py-6 sm:px-6 border-b ${
                      theme === 'dark' ? 'border-gray-500' : null
                    }`}
                  >
                    <div className="flex items-start justify-between">
                      <h2
                        className={`text-lg font-semibold  ${
                          theme === 'dark' ? 'text-gray-100' : 'text-gray-900'
                        }`}
                      >
                        {component === 'userleaks' || component === 'alphaleaks'
                          ? LeaksDetailsPageHeading
                          : component === 'userwallet'
                          ? WalletDetailsPageHeading
                          : null}
                      </h2>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="text-gray-400 hover:text-gray-500"
                          onClick={() => setShow(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <h5
                      className={`text-sm ${
                        theme === 'dark' ? 'text-gray-100' : null
                      }`}
                    >
                      {' '}
                      ID:{' '}
                      {component === 'userleaks' || component === 'alphaleaks'
                        ? value
                          ? value
                          : null
                        : component === 'userwallet'
                        ? value
                          ? value
                          : null
                        : null}
                    </h5>
                  </div>
                  {/* Main */}
                  {component === 'userleaks' || component === 'alphaleaks' ? (
                    <LeaksDetails
                      component={component ? component : null}
                      value={value ? value : null}
                    />
                  ) : (
                    <SubmissionDetails
                      component={component ? component : null}
                      value={value ? value : null}
                    />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
