import { RefreshIcon } from '@heroicons/react/solid';
import { getUrlParam } from 'Helper/getUrlPram';
import {
  checkCatalogAvailability,
  createUserDataCatalog,
} from 'Helper/services';
import { catalogFormValidator, isAlpha } from 'Helper/validators';
import { AppContext } from 'Providers/appProvider';
import { AWSLogo, AzureLogo, GCPLogo } from 'Utilities/images';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import encryptCatalogConnection from '../../Atoms/encryptSecret';
import MySQLAWS from './mysql-aws';

const CLOUD_PROVIDERS = [
  { id: 1, title: 'AWS', icon: AWSLogo },
  { id: 2, title: 'GCP', icon: GCPLogo },
  { id: 3, title: 'MS AZURE', icon: AzureLogo },
];

function Mysql() {
  const cluster_name = getUrlParam('name');
  const step = getUrlParam('step');
  const type = getUrlParam('type');

  const [cloudProvider, setCloudProvider] = useState(1);
  const [catalogNameError, setCatalogNameError] = useState('');
  const [formError, setFormError] = useState('');
  const [loading, setLoading] = useState(false);
  const [dbConfig, setDbConfig] = useState({});
  const [selectedCluster, seSelectedCluster] = useState(
    cluster_name ? cluster_name : null
  );

  const { catalogConf, setCatalogConf } = useContext(AppContext);

  const navigate = useNavigate();

  function handleInputChange(key, val) {
    if (key === 'catalog_name' && val.length > 0) {
      const regex = /^[a-z\d\_\s]+$/i;
      if (!isAlpha(val.charAt(0))) {
        setCatalogNameError('first character must be an alphabet');
        return;
      }

      if (!regex.test(val)) {
        setCatalogNameError(
          'Catalog name should only contain alphabets, numbers, underscore'
        );
        return;
      }
      setCatalogNameError('');
      setCatalogConf((prevState) => ({ ...prevState, [key]: val }));
    }
    setCatalogConf((prevState) => ({ ...prevState, [key]: val }));
  }

  async function checkNameAvailability(e) {
    if (e.target) {
      const { value } = e.target;
      if (value) {
        const isNameAvailable = await checkCatalogAvailability(value);
        if (isNameAvailable != null) {
          if (!isNameAvailable) {
            setCatalogNameError(
              'A catalog with this name already exists. Catalog name must be unique. Please choose another name.'
            );
            return;
          }
        }
      }
    }
  }

  async function handleConnectCatalog() {
    const errors = catalogFormValidator(catalogConf, dbConfig);
    console.log({ errors });
    if (Object.keys(errors).length > 0) {
      console.log('error');
      setFormError(errors);
      return;
    } else {
      console.log('no error');
      setLoading(true);
      setFormError('');
      const encodedString = encryptCatalogConnection(dbConfig);
      const postObj = {
        catalog: { ...catalogConf, connectionConfig: encodedString },
        kind: type,
        clusterId: selectedCluster,
      };
      setCatalogConf(postObj);
      console.log(postObj);
      try {
        const isCatalogCreated = await createUserDataCatalog(postObj);
        if (isCatalogCreated) {
          navigate('/app/platforms/configuration/user-catalogs');
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* <h1>Timer</h1> */}
        <div className="mx-auto max-w-3xl">
          <div className="dark:text-white my-4 text-lg font-semibold">
            MySQL
          </div>
          <p className="dark:text-dark-textPrimary py-2 text-sm">
            Configure your catalog to query a MySQL database,
          </p>
          {/* <h1 className="dark:text-dark-textPrimary py-3 text-sm font-semibold">
            Select cloud provider
          </h1>
          <div className="flex items-center space-x-10 mt-2">
            {CLOUD_PROVIDERS.map((item) => (
              <div key={item.id} className="flex items-center">
                <input
                  id="cp"
                  name="cp"
                  type="radio"
                  defaultChecked={item.id === cloudProvider}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  onChange={() => setCloudProvider(item.id)}
                />
                <label
                  htmlFor="cp"
                  className="ml-3 block text-sm font-medium dark:text-dark-textPrimary"
                >
                  <img src={item.icon} className="w-12 sm:w-14 " />
                </label>
              </div>
            ))}
          </div> */}

          <div className="mt-4">
            <h1 className="dark:text-dark-textPrimary py-2  text-sm font-semibold">
              Name and description
            </h1>
            <p className="dark:text-dark-textPrimary py-2 text-xs">
              Provide a unique name to identify the catalog in your SQL queries
              in the query editor and other client tools. The namespace for a
              table is typically {'<catalog_name>.<schema_name>.<table_name>'}
            </p>
            <div className="flex flex-col space-y-4 mt-4">
              <div>
                <input
                  type="text"
                  name="catalog-name"
                  id="catalog-name"
                  placeholder="Catalog name"
                  onChange={(e) =>
                    handleInputChange('catalog_name', e.target.value)
                  }
                  onBlur={checkNameAvailability}
                  className="block w-full sm:w-1/2 rounded-md p-2  text-xs  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
                />
                {catalogNameError && (
                  <span className="text-xs text-red-500 mx-2 text-justify">
                    {catalogNameError}
                  </span>
                )}
                {formError?.catalog_name && (
                  <span className="text-xs text-red-500 mx-2 text-justify">
                    {formError?.catalog_name}
                  </span>
                )}
              </div>
              <div>
                <input
                  type="text"
                  name="desc"
                  id="desc"
                  placeholder="Description"
                  onChange={(e) =>
                    handleInputChange('description', e.target.value)
                  }
                  className="block w-full sm:w-1/2 rounded-md p-2  text-xs  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
                />
                {formError?.description && (
                  <span className="text-xs text-red-500 mx-2 text-justify">
                    {formError?.description}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h1 className="dark:text-dark-textPrimary py-3 text-sm font-semibold">
              MySQL Connection
            </h1>
            <span className="text-gray-400 dark:text-gray-500 text-xs">
              Connection type*
            </span>
            <div className="flex items-center mt-2">
              <input
                id="connect_dir"
                name="notification-method"
                type="radio"
                defaultChecked={true}
                className="h-3 w-3 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                onClick={() => console.log('')}
              />
              <label
                htmlFor="connect_dir"
                className="ml-2 block text-xs font-medium dark:text-dark-textPrimary"
              >
                Connect Directly
              </label>
            </div>
            {/* 
            <CloudProviderConf
              cloudProvider={cloudProvider}
              dbConfig={dbConfig}
              setDbConfig={setDbConfig}
            /> */}
            <MySQLAWS
              dbConfig={dbConfig}
              setDbConfig={setDbConfig}
              cloudProvider={cloudProvider}
              formError={formError}
              catalogConf={catalogConf}
            />
          </div>
          {/* <div
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon className="w-3 dark:text-dark-textPrimary" />
              <p className="dark:text-dark-textPrimary">Back</p>
            </div> */}
          <div className="flex justify-end">
            <button
              type="button"
              className="inline-flex rounded-md border border-transparent  bg-blue-600 px-4 py-1.5 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={handleConnectCatalog}
            >
              {loading ? (
                <RefreshIcon className="w-4 animate-spin" />
              ) : (
                'Connect catalog'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mysql;
