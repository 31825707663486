/* eslint-disable react/prop-types */
import { getUserClusterByUci } from 'Helper/services';
import React, { useEffect, useState } from 'react';
import UserClusterCard from '../Atoms/userClusterCard';
import UserClusterConfigStatus from '../Atoms/userClusterConfigStatus';
import { Spinner } from 'Utilities';
import { XIcon } from '@heroicons/react/solid';

function Activities({ uci, setShowActivities }) {
  const [clusterInfo, setClusterInfo] = useState([]);
  const [conifgStatus, setConfigStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchClusterInfo = async () => {
    setLoading(true);
    try {
      const data = await getUserClusterByUci(uci);
      console.log(data);
      if (data) {
        setClusterInfo(data?.info);
        setConfigStatus(data?.config_status);
      }
    } catch (err) {
      setClusterInfo([]);
      setConfigStatus([]);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClusterInfo();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96 max-w-5xl">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="float-right">
        <div>
          <XIcon
            className="w-5 cursor-pointer"
            onClick={() => setShowActivities(false)}
          />
        </div>
      </div>
      {clusterInfo && (
        <div className="px-4">
          <UserClusterCard
            cluster_name={clusterInfo?.cluster_name}
            plan={clusterInfo?.user_subscription_plan}
            plan_updated_at={
              clusterInfo?.subscription_last_update_timestamp_utc
            }
            cluster_updated_at={clusterInfo?.cluster_last_update_timestamp_utc}
            config_updated_at={
              clusterInfo?.user_cluster_last_update_timestamp_utc
            }
            loading={loading}
          />
          <div className="mt-6">
            <div className="font-semibold dark:text-dark-textPrimary text-lg">
              Configuration Status
            </div>
            <UserClusterConfigStatus data={conifgStatus} loading={loading} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Activities;
