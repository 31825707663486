/* eslint-disable react/prop-types */
import {
  ArrowSmRightIcon,
  DotsCircleHorizontalIcon,
} from '@heroicons/react/outline';
import { CheckIcon, CogIcon } from '@heroicons/react/solid';
import {
  getClusterCreateStatus,
  getWorkflowGroupStatus,
} from 'Helper/services';
import { sleep } from 'Helper/utils';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
const services = [
  {
    id: 1,
    configuration: 'Creating Cluster (This may take about 15 mins)',
    desc: 'Lorem ipsum dolor sit amet, consectetur adip',
    href: '#',
    date: 'Sep 20',
    datetime: '2020-09-20',
    // status: '',
    iconBackground: 'bg-green-400',
  },
  {
    id: 2,
    configuration: 'Creating Cluster-Level Assets',
    desc: 'Lorem ipsum dolor sit amet, consectetur adip',
    href: '#',
    date: 'Sep 22',
    datetime: '2020-09-22',
    status: '',
    iconBackground: 'bg-gray-500',
  },
  {
    id: 3,
    configuration: 'Installing Trino',
    desc: 'Lorem ipsum dolor sit amet, consectetur adip',
    href: '#',
    date: 'Sep 28',
    datetime: '2020-09-28',
    status: '',
    iconBackground: 'bg-yellow-500',
  },
  {
    id: 4,
    configuration: 'Installing Vault',
    desc: 'Lorem ipsum dolor sit amet, consectetur adip',
    href: '#',
    date: 'Sep 30',
    datetime: '2020-09-30',
    status: '',
    iconBackground: 'bg-yellow-500',
  },
  {
    id: 5,
    configuration: 'Integrating Trino with Superset',
    desc: 'Lorem ipsum dolor sit amet, consectetur adip',
    href: '#',
    date: 'Sep 30',
    datetime: '2020-09-30',
    status: 'failed',
    iconBackground: 'bg-yellow-500',
  },
];

function ClusterConfigSteps(props) {
  const [clusterServices, setClusterServices] = useState(services);
  const [status, setStatus] = useState({});
  const [confStatus, setConfStatus] = useState(false);
  const { user_address } = useGetCurrentUser();
  const { user_cluster_id, cluster_id } = props;

  const checkForConfigStatus = async () => {
    const isClusterCreated = await getClusterCreationStatus();
    console.log('isClusterCreated', isClusterCreated);
    if (isClusterCreated) {
      // return;
      const isClusterLevelItemsInstalled = await getCluseterLevelStatus();
      if (isClusterLevelItemsInstalled) {
        const isTrinoInstalled = await getTrinoWorkloadStatus();
        if (isTrinoInstalled) {
          const isVaultInstalled = await getVaultWorkloadDependenciesStatus();
          if (isVaultInstalled) {
            const isSupersetTrinoInstalled = await getSupersetTrino();
            if (isSupersetTrinoInstalled) {
              setConfStatus(true);
            }
          }
        }
      }
    }
  };

  const getClusterCreationStatus = async () => {
    let status = null;
    while (status != 'ready') {
      const clusterStatus = await getClusterCreateStatus(cluster_id);
      if (clusterStatus.status) {
        if (clusterStatus?.data?.status === 'ready') {
          status = 'ready';
          setStatus((prevState) => ({ ...prevState, 1: 'ready' }));
          return true;
        } else {
          status = 'running';
          setStatus((prevState) => ({ ...prevState, 1: 'running' }));
        }
      }
      await sleep(20000);
    }
  };

  const getCluseterLevelStatus = async () => {
    let status = null;
    while (status !== 'completed') {
      const isClusterLevelItemsInstalled = await getWorkflowGroupStatus(
        user_cluster_id,
        user_address,
        'cluster-level-assets'
      );

      if (
        isClusterLevelItemsInstalled.message[0]?.last_status === 'completed'
      ) {
        status = 'completed';
        setStatus((prevState) => ({ ...prevState, 2: 'completed' }));
        return true;
      } else {
        status = 'running';
        setStatus((prevState) => ({ ...prevState, 2: 'running' }));
      }
      await sleep(20000);
    }
  };

  const getTrinoWorkloadStatus = async () => {
    let status = null;
    while (status !== 'completed') {
      const isClusterLevelItemsInstalled = await getWorkflowGroupStatus(
        user_cluster_id,
        user_address,
        'trino-workloads'
      );

      if (
        isClusterLevelItemsInstalled.message[0]?.last_status === 'completed'
      ) {
        status = 'completed';
        setStatus((prevState) => ({ ...prevState, 3: 'completed' }));
        return true;
      } else {
        status = 'running';
        setStatus((prevState) => ({ ...prevState, 3: 'running' }));
      }
      await sleep(20000);
    }
  };

  const getVaultWorkloadDependenciesStatus = async () => {
    let status = null;
    while (status !== 'completed') {
      const isVaultWorkloadsCreated = await getWorkflowGroupStatus(
        user_cluster_id,
        user_address,
        'vault-workload-dependencies'
      );
      if (isVaultWorkloadsCreated.message[0]?.last_status === 'completed') {
        const isVaultCreated = await getWorkflowGroupStatus(
          user_cluster_id,
          user_address,
          'vault-workloads'
        );
        if (isVaultCreated.message[0]?.last_status === 'completed') {
          status = 'completed';
          setStatus((prevState) => ({ ...prevState, 4: 'completed' }));
          return true;
        } else {
          status = 'running';
          setStatus((prevState) => ({ ...prevState, 4: 'running' }));
        }
      } else {
        status = 'running';
        setStatus((prevState) => ({ ...prevState, 4: 'running' }));
      }
      await sleep(20000);
    }
  };

  const getSupersetTrino = async () => {
    let status = null;
    while (status !== 'completed') {
      const isSupersetTrinoInstalled = await getWorkflowGroupStatus(
        user_cluster_id,
        user_address,
        'superset-trino-integration'
      );
      if (isSupersetTrinoInstalled.message[0]?.last_status === 'completed') {
        status = 'completed';
        setStatus((prevState) => ({ ...prevState, 5: 'completed' }));
        return true;
      } else {
        status = 'running';
        setStatus((prevState) => ({ ...prevState, 5: 'running' }));
      }
      await sleep(20000);
    }
  };

  // const updateStatus = (id, status) => {
  //   const newState = clusterServices.map((obj) => {
  //     if (obj.id === id) return { ...obj, status };
  //     return obj;
  //   });
  //   setClusterServices(newState);
  // };
  const renderIcon = (status) => {
    if (status === 'ready' || status === 'completed')
      return (
        <>
          <span className="h-8 w-8 rounded-full flex items-center justify-center  bg-green-500">
            <CheckIcon className="h-5 w-5 text-white" />
          </span>
        </>
      );
    if (status === 'running')
      return (
        <>
          <span className="h-8 w-8 rounded-full flex items-center justify-center  bg-yellow-500">
            <CogIcon className="h-5 w-5 text-white animate-spin" />
          </span>
        </>
      );
    if (status === 'failed')
      return (
        <>
          <span className="h-8 w-8 rounded-full flex items-center justify-center  bg-red-400">
            <DotsCircleHorizontalIcon className="h-5 w-5 text-white" />
          </span>
        </>
      );
    if (!status)
      return (
        <>
          <span className="h-8 w-8 rounded-full flex items-center justify-center  bg-gray-300">
            <CogIcon className="h-5 w-5 text-white" />
          </span>
        </>
      );
  };
  const renderInstallationProgress = () => {
    return (
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {clusterServices.map((event, eventIdx) => (
            <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== clusterServices.length - 1 ? (
                  <span
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>{renderIcon(status[event.id])}</div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div className="mx-2">
                      <p className="text-sm text-gray-500 dark:text-white">
                        {event.configuration}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    checkForConfigStatus();
  }, []);
  return (
    <div className="flex flex-col items-center justify-center h-102">
      <div className="block text-md font-semibold leading-10 text-gray-900 dark:text-white text-center">
        Please wait while your cluster is being created and services installed
      </div>
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="block text-md font-normal leading-6 text-gray-900 dark:text-dark-textPrimary text-center mt-4">
          {props.nickname}
        </div>

        <div className="mt-8 p-6 border rounded-lg dark:border-gray-700">
          {renderInstallationProgress()}
        </div>
        <div className="flex justify-center items-center mt-6">
          {/* <RealisticConfetti /> */}
          {confStatus && (
            <NavLink
              to={`/app/platforms/configuration/user-cluster/configure?id=${user_cluster_id}&step=vault-operations`}
              className="inline-flex  items-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium  text-white shadow-sm bg-blue-600 "
            >
              Proceed to Setup Vault
              <ArrowSmRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClusterConfigSteps;
