import React from 'react';
import PropTypes from 'prop-types';
function AlphaSentiment({ sentiment }) {
  return (
    <div>
      {sentiment === 'positive' ? (
        <span className="inline-flex items-center px-3 py-0.5 rounded text-xs font-medium text-green-500">
          {sentiment}
        </span>
      ) : (
        <span className="inline-flex items-center px-3 py-0.5 rounded text-xs font-medium text-red-500">
          {sentiment}
        </span>
      )}
    </div>
  );
}
export default AlphaSentiment;

AlphaSentiment.propTypes = {
  sentiment: PropTypes.string,
};
