import React, { useEffect, useState } from 'react';

export default function useCopyToClipBoard(_rstCopied) {
  const [isCopied, setIsCopied] = useState();

  const _setClipBoard = (text) => {
    navigator.permissions
      .query({ name: 'clipboard-write' })
      .then((res) => {
        if (res.state === 'granted' || res.state === 'prompt') {
          navigator.clipboard
            .writeText(text)
            .then(() => {
              setIsCopied(true);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log(`Permission ${result.state}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let timeOut;

    if (isCopied) {
      timeOut = setTimeout(() => setIsCopied(false), _rstCopied);
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [isCopied, _rstCopied]);

  return [isCopied, _setClipBoard];
}
