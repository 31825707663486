import { ExternalLinkIcon } from '@heroicons/react/solid';
import HasCluster from 'Components/Platforms/Molecules/hasCluster';
import { getLoggedUser, getUserClusterList } from 'Helper/services';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { useEffect, useState } from 'react';
import { Spinner } from 'Utilities';
import { DataExplorationThump } from 'Utilities/images';
import VideoPlayer from 'Utilities/VideoJs';

function Exploration() {
  const [hasCluster, setHasCluster] = useState(true);
  const [loading, setLoading] = useState(true);
  const { user_address } = useGetCurrentUser();

  const getUserCluster = async () => {
    try {
      setLoading(true);
      const loggedUser = await getLoggedUser(user_address);
      if (loggedUser) {
        const userId = loggedUser.message[0].id;
        const userClusters = await getUserClusterList(userId, 'all');
        if (userClusters?.data?.length > 0) {
          setHasCluster(true);
          console.log('user has cluster');
        } else {
          setHasCluster(false);
        }
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(async () => {
    getUserCluster();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }
  return (
    <div>
      {!hasCluster && !loading && <HasCluster />}
      <div className="px-4 md:px-6 xl:px-8 place-content-center lg:w-1/2 xl:w-auto">
        <div className="mt-6">
          <h1 className="dark:text-white lg:text-xl font-semibold py-3">
            Exploration
          </h1>
          <h1 className="lg:w-1/2 text-gray-800 dark:text-dark-textPrimary text-sm font-medium text-justify sm:text-left mt-1">
            Query and Visualize Alphastack hosted on-chain data that is enriched
            with various off-chain data points such as price, wallet labels etc.
            Use our federated SQL query solution to combine our data with your
            own external datasets to deliver real-time customized insights in a
            cost-efficient, scalable and secure manner.
          </h1>
          <div className="flex mt-4">
            <div className="flex  px-4 py-2 items-center dark:bg-dark-tabPillBgSelectedDark shadow-md border-b-1 border-gray-100 dark:border-gray-200 rounded-md">
              <a
                href="https://alphastack-superset-cige4pikta-uc.a.run.app"
                target="_blnk"
              >
                <div className="flex space-x-3 items-center">
                  <ExternalLinkIcon className="w-4 text-blue-700" />
                  <h3 className="text-blue-700 dark:text-blue-600 font-semibold text-xs">
                    SQL Query (with Data Federation) and Visualization
                  </h3>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="container mx-auto mt-12 lg:w-3/5">
          <h2 className="dark:text-white py-3 font-black tracking-tight text-center text-3xl">
            How to - Video
          </h2>
          <div className="flex justify-center pt-4">
            {/* {showVideo ? (
              <iframe
                className="w-full h-80 lg:h-96"
                src="https://www.youtube.com/embed/tN_ss2XixW4?controls=0&amp;start=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            ) : (
              <div
                className="relative h-full cursor-pointer group"
                onClick={() => setShowVideo(true)}
              >
                <div className="text-center absolute top-1/2  transform -translate-y-1/2 -translate-x-1/2 m-auto left-1/2  rounded-full">
                  <div className="flex justify-center items-center relative bg-white rounded-full w-12 h-12 lg:w-16 lg:h-16">
                    <div className="absolute transform ease-in delay-300">
                      <svg
                        className="w-4 h-6 lg:w-6 lg:h-8"
                        viewBox="0 0 14 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M13.1362 6.54164L2.24095 0.201094C1.35571 -0.313828 0 0.185859 0 1.45945V14.1375C0 15.2801 1.25976 15.9687 2.24095 15.3959L13.1362 9.05836C14.1081 8.49469 14.1112 7.10531 13.1362 6.54164V6.54164Z"
                            fill="#FF6B4A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="13.8667" height="15.6" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="absolute group-hover:opacity-0">
                      <svg
                        className="w-4 h-6 lg:w-6 lg:h-8"
                        viewBox="0 0 14 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M13.1362 6.54164L2.24095 0.201094C1.35571 -0.313828 0 0.185859 0 1.45945V14.1375C0 15.2801 1.25976 15.9687 2.24095 15.3959L13.1362 9.05836C14.1081 8.49469 14.1112 7.10531 13.1362 6.54164Z"
                            fill="#4444CF"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="14" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
                <img src={DataExplorationThump} className="h-full rounded-xl" />
              </div>
            )} */}
          </div>
          <VideoPlayer
            url="https://www.youtube.com/watch?v=tN_ss2XixW4"
            coverImage={DataExplorationThump}
          />
        </div>
      </div>
    </div>
  );
}

export default Exploration;
