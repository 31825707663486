import { ExternalLinkIcon } from '@heroicons/react/outline';
import { processTokens } from 'Components/Defi/TopTrades/Helper/processToken';
import { proccessCollection } from 'Components/NFT/NFTCollections/Helper/processToken';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getToptrades,
  getHomePageData,
  getTopNftContracts,
} from 'statemgmt/actions';
import { Loader, Text } from 'Utilities';

import { formatMetricData } from './Helper/formatData';

export default function TopItems() {
  const dispatch = useDispatch();
  const {
    metrics,
    topTrades,
    nftCollection,
    ttloading,
    nftloading,
    metricsloading,
    block_chain,
  } = useSelector((s = initialState) => {
    return {
      metrics: formatMetricData(s.homePage.matrics.data),
      topTrades: processTokens(s?.dashboard?.token?.data, 'bought'),
      nftCollection: proccessCollection(
        s.nftTopContractsData.nftTopContracts,
        'bought'
      ),
      ttloading: s?.dashboard?.loading,
      nftloading: s.nftTopContractsData.loading,
      metricsloading: s.homePage.loading,
      block_chain: s.currentBlockChain.selectedBlockChain,
    };
  });

  useEffect(() => {
    dispatch(
      getToptrades(1, 3, 'whales', 'bought', '12hr', 'wallet_count', true)
    );
    dispatch(
      getTopNftContracts(1, 3, 'whales', 'bought', '12hr', 'wallet_count')
    );
    dispatch(getHomePageData());
  }, []);
  return (
    <>
      <div className="flex flex-col lg:flex-row">
        <div className="md:p-4 w-full flex flex-col">
          <div className="inline-flex">
            <Text
              size="lg"
              fontWeight="semibold"
              text="Top Air Drops"
              className="dark:text-white mt-6 md:mt-0"
            />
            <a href="metric" target="_blank" rel="noopener noreferrer">
              <ExternalLinkIcon className="m-1 h-4 w-4 cursor-pointer dark:text-white" />
            </a>
          </div>

          <Text
            size="xs"
            fontWeight="medium"
            text="Top 3 tokens bought by whales in the last 12 hours"
            className="dark:text-dark-textPrimary mb-4"
          />
          <div className="flex-1 bg-white dark:bg-dark-themeCard p-4 rounded-lg border dark:border-gray-700">
            {metricsloading && (
              <div className="m-auto">
                <Loader color="#1d4ed8" className="h-12" />
              </div>
            )}
            {!metricsloading && (
              <table className="min-w-full divide-y dark:divide-gray-700">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0"
                    >
                      Rank
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold  text-gray-900 dark:text-dark-textPrimary"
                    >
                      Coin
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {metrics &&
                    metrics?.airdrop?.rows.slice(0, 3).map((person, key) => (
                      <tr key={key}>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900 dark:text-dark-textPrimary">
                          {key + 1}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-dark-textPrimary sm:pl-6 md:pl-0">
                          <a
                            href={person.url}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-600 font-semibold"
                          >
                            {person.Coin}
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="md:p-4 w-full flex flex-col">
          <div className="inline-flex">
            <Text
              size="lg"
              fontWeight="semibold"
              text="Top Dex Trades"
              className="dark:text-white mt-6 md:mt-0"
            />
            <a
              href="/app/prebuilt/on-chain-analytics/defi?chain=ethereum&tab=TopDexTrades&limit=20&group=whales&type=bought_sold&duration=24hr&sort_key=wallet_count"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalLinkIcon className="m-1 h-4 w-4 cursor-pointer dark:text-white" />
            </a>
          </div>
          <Text
            size="xs"
            fontWeight="medium"
            text="Top 3 tokens bought by whales in the last 12 hours"
            className="dark:text-dark-textPrimary mb-4"
          />
          <div className="flex-1 bg-white dark:bg-dark-themeCard p-4 rounded-lg border dark:border-gray-700">
            {ttloading && (
              <div className="m-auto">
                <Loader color="#1d4ed8" className="h-12" />
              </div>
            )}
            {!ttloading && (
              <table className="min-w-full divide-y dark:divide-gray-700 ">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0"
                    >
                      Rank
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold  text-gray-900 dark:text-dark-textPrimary"
                    >
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {topTrades &&
                    topTrades.map((item, key) => (
                      <tr key={key}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0">
                          {key + 1}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900 dark:text-dark-textPrimary">
                          <div className="flex items-center">
                            <div className="h-8 w-8 flex-shrink-0">
                              <a
                                href={`https://www.coingecko.com/en/coins/${item.cg_id}`}
                              >
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={item.logo_url}
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900 dark:text-dark-textPrimary">
                                <a
                                  href={`https://www.coingecko.com/en/coins/${item.cg_id}`}
                                  className="text-blue-600 font-semibold"
                                >
                                  {item.name}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="md:p-4 w-full flex flex-col">
          <div className="inline-flex">
            <Text
              size="lg"
              fontWeight="semibold"
              text="Top Collections"
              className="dark:text-white mt-6 md:mt-0"
            />
            <a
              href="/app/prebuilt/on-chain-analytics/nft?chain=ethereum&tab=TopNFTCollections"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalLinkIcon className="m-1 h-4 w-4 cursor-pointer dark:text-white" />
            </a>
          </div>
          <Text
            size="xs"
            fontWeight="medium"
            text="Top 3 NFT bought by whales in the last 12 hours"
            className="dark:text-dark-textPrimary mb-4"
          />
          <div className="flex-1 bg-white dark:bg-dark-themeCard p-4 rounded-lg border dark:border-gray-700">
            {nftloading && (
              <div className="m-auto">
                <Loader color="#1d4ed8" className="h-12" />
              </div>
            )}
            {!nftloading && (
              <table className="min-w-full divide-y dark:divide-gray-700 ">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0"
                    >
                      Rank
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold  text-gray-900 dark:text-dark-textPrimary"
                    >
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {nftCollection &&
                    nftCollection.map((item, key) => (
                      <tr key={key}>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900 dark:text-dark-textPrimary">
                          {key + 1}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-dark-textPrimary sm:pl-6 md:pl-0">
                          <div className="flex items-center">
                            <div className="h-8 w-8 flex-shrink-0">
                              <a
                                href={`https://opensea.io/collection/${item?.collection?.slug}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={item.image_url}
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900 dark:text-dark-textPrimary">
                                <a
                                  href={`https://opensea.io/collection/${item?.collection?.slug}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-blue-600 font-semibold"
                                >
                                  {item.name}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
