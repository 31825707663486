/* eslint-disable react/prop-types */
import { ClockIcon } from '@heroicons/react/outline';
import React from 'react';
import GradientProgressBar from 'Utilities/ProgressBar/gradientProgressBar';
import Flex from 'Utilities/TWComponents/FlexWrapper';
import Text from 'Utilities/TWComponents/text';
export default function SentimentFng({ fng }) {
  return (
    <>
      <div
        className={`relative py-12 px-6 bg-white border dark:bg-dark-themeCard rounded-xl blur-sm mb-4 dark:border-gray-700`}
      >
        <Flex direction="row" justifyContent="between" className="">
          <div>
            <Text
              size="sm"
              text="Fear and greed index"
              fontWeight="semibold"
              className="dark:text-white"
            />
            <Text
              size="xs"
              text=""
              fontWeight="medium"
              className="dark:text-dark-textPrimary"
            />
          </div>
          <div>
            <Text
              size="xs"
              text="Current Level"
              className="dark:text-dark-textPrimary text-right"
            />
            <Flex direction="row" space="2">
              <Text
                size="sm"
                text={fng.value}
                className=""
                fontWeight="semibold"
                color="red-500"
              />
              <Text
                size="xs"
                text={fng.value_classification}
                className="dark:text-white"
                fontWeight="semibold"
              />
            </Flex>
          </div>
        </Flex>
        <div className="mt-6">
          <GradientProgressBar step={fng.value} />
        </div>
        <Flex direction="row-reverse">
          <>
            <div className="text-xs text-gray-400 mt-4 inline-flex">
              <span>
                <ClockIcon className="w-4 mr-1" />
              </span>
              Next update in:{' '}
              {`${Math.floor(fng.time_until_update / 60 / 60)}:${
                Math.floor(fng.time_until_update / 60) -
                Math.floor(fng.time_until_update / 60 / 60) * 60
              }:${fng.time_until_update % 60} `}
            </div>
          </>
        </Flex>
      </div>
    </>
  );
}
