/* eslint-disable react/prop-types */
import { CubeIcon } from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import MiniChart from './minichart';
import { getTokenPrices } from 'Helper/services';
import { XIcon } from '@heroicons/react/solid';

function CoinMiniChart({ symbol, provider, token_name }) {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const getTokenPrice = () => {
    setLoading(true);
    getTokenPrices(symbol, provider)
      .then((_r) => {
        setPrices(_r.message);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let redirectURL = ``;
  let tooltip = ``;
  if (provider === 'cg') {
    redirectURL = `https://www.coingecko.com/en/coins/${token_name}/btc?chart=7_days#panel`;
    tooltip = `Open in coingecko`;
  } else {
    redirectURL = `https://coinmarketcap.com/currencies/${token_name}/?period=7d`;
    tooltip = `Open in coinmarketcap`;
  }

  useEffect(() => {
    getTokenPrice();
  }, [symbol]);

  if (loading) {
    return (
      <div className="App" style={{ marginTop: '1%' }}>
        <div className="flex justify-center">
          <CubeIcon className="animate-spin h-4 w-4 " />
        </div>
      </div>
    );
  } else {
    return (
      <div className="mini-chart bg-transparent w-48 flex items-center justify-center">
        {prices.length > 0 ? (
          <Tippy
            content={<div className="text-xs">{tooltip}</div>}
            placement="top"
          >
            <a
              className="text-right dark:hover:"
              href={redirectURL}
              target="_blank"
              rel="noreferrer"
            >
              <MiniChart
                data={prices}
                height={4}
                width={30}
                strokeWidth={0.15}
              />
            </a>
          </Tippy>
        ) : (
          <div>-</div>
        )}
      </div>
    );
  }
}

export default CoinMiniChart;
