// constants
import { Menu, Transition } from '@headlessui/react';
import { FilterIcon, RefreshIcon } from '@heroicons/react/outline';
import { SCAN_ADDRESS } from 'Helper/constants';
import { useLocalStorage } from 'Hooks';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLeaksData } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import {
  Refresh,
  CopyToClipboard,
  Etherscan,
  MenuWrapper,
  Search,
  Select,
  Table,
} from 'Utilities';

import { column } from './header';

export default function UserLeads() {
  // state initialization start
  const [filter, setFilter] = useState(1);
  const [refresh, setRefresh] = useState(false); // for active filter
  const [address, setAddress] = useLocalStorage('user_address', '');
  // state initialization end

  // const selectedAddress = localStorage.getItem('address');
  // let user_address;
  // const { search } = useLocation();
  // const urlparams = new URLSearchParams(search);
  // user_address = urlparams.get('user_address');

  // filter options
  const filterOptions = [
    {
      text: 'All',
      value: 1,
    },
    {
      text: 'Open',
      value: 2,
    },
    {
      text: 'Closed',
      value: 3,
    },
  ];

  const dispatch = useDispatch();

  // listeing to redux store changes
  const { userLeaks, loading } = useSelector((s = initialState) => {
    return {
      userLeaks: s.userLeaksData.userLeaks.data,
      loading: s.userLeaksData.loading,
    };
  });
  const handleCallBack = (selected) => {
    setFilter(selected);
  };
  const dispatchAction = () => {
    dispatch(getUserLeaksData(address, filter));
  };
  const getData = () => {
    dispatchAction();
  };
  // useeffect hook start
  useEffect(() => {
    dispatchAction();

    return () => {
      localStorage.removeItem('user_address');
    };
  }, [filter, refresh]);
  // useeffect hook end

  // props object passed to search component
  const placeholder = 'Enter user address';
  const props = { address, setAddress, getData, placeholder };
  return (
    <div className="h-full">
      <div className="dark:bg-dark-themePrimary bg-light-themeSecondary p-4 rounded-tr-md">
        <div className="">
          <Search {...props} />
        </div>
        <div className="w-full  border-gray-300 dark:border-gray-700" />

        <>
          <div className="hidden sm:block">
            <div className="flex flex-col lg:flex-row-reverse flex-space-4 my-4  lg:justify-between space-y-4 lg:space-y-0">
              {address?.length > 0 && (
                <div className="flex flex-col sm:flex-row mt-1 items-center space-x-2">
                  <h2 className="font-bold text-xs dark:text-dark-textPrimary">
                    Alpha Leaks By User :
                  </h2>

                  <Etherscan
                    address={address}
                    type={SCAN_ADDRESS}
                    isSubString={true}
                    chainId={''}
                  />

                  <CopyToClipboard _str={address} />
                </div>
              )}
              <div className="flex space-x-6 items-center">
                <div className="">
                  <Select
                    optionsObj={filterOptions}
                    state={filter}
                    setState={setFilter}
                  />
                </div>
                <div className="">
                  <Refresh
                    state={refresh}
                    setState={setRefresh}
                    loadingbutton={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sm:hidden block">
            {address?.length > 0 && (
              <div className="flex flex-row mt-1 justify-center items-center">
                <h2 className="font-bold text-sm ml-2 dark:text-dark-textPrimary">
                  Alpha Leaks By User :
                </h2>
                <h2 className="font-bold text-sm ml-2 dark:text-dark-textPrimary">
                  {
                    <Etherscan
                      address={address}
                      type={SCAN_ADDRESS}
                      isSubString={true}
                      chainId={''}
                    />
                  }
                </h2>
                <CopyToClipboard _str={address} />
              </div>
            )}
            <div className="flex flex-row-reverse mt-4">
              <div>
                <MenuWrapper>
                  <div className="flex flex-wrap px-2 py-3 gap-1.5">
                    <Select
                      optionsObj={filterOptions}
                      state={filter}
                      setState={setFilter}
                    />
                  </div>
                </MenuWrapper>
              </div>
              <div className="mx-2">
                <Refresh
                  state={refresh}
                  setState={setRefresh}
                  loadingbutton={loading}
                />
              </div>
            </div>
          </div>
          {userLeaks ? (
            <div className="pt-3">
              <Table data={userLeaks} col={column} isloading={loading} />
            </div>
          ) : (
            ''
          )}
        </>
      </div>
    </div>
  );
}
