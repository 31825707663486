import { combineReducers } from 'redux';
import {
  AUTH_SUCCESS,
  DASHBOARD_DATA_FAILURE,
  DASHBOARD_DATA_LOADING,
  DASHBOARD_DATA_SUCCESS,
  GET_ALL_WALLET_SUBMISSIONS_FAILURE,
  GET_ALL_WALLET_SUBMISSIONS_LOADING,
  GET_ALL_WALLET_SUBMISSIONS_SUCCESS,
  GET_ALPHALEAKS_FAILURE,
  GET_ALPHALEAKS_LOADING,
  GET_ALPHALEAKS_SUCCESS,
  GET_ANALYTICS_DATA_FAILURE,
  GET_ANALYTICS_DATA_LOADING,
  GET_ANALYTICS_DATA_SUCCESS,
  GET_NOTABLETRADERS_DATA_FAILURE,
  GET_NOTABLETRADERS_DATA_LOADING,
  GET_NOTABLETRADERS_DATA_SUCCESS,
  GET_BLOCKCHAIN_FAILURE,
  GET_BLOCKCHAIN_SUCCESS,
  GET_GAMING_DATA_FAILURE,
  GET_GAMING_DATA_LOADING,
  GET_GAMING_DATA_SUCCESS,
  GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_FAILURE,
  GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_LOADING,
  GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_SUCCESS,
  GET_LARGEST_TRANSFERS_DATA_FAILURE,
  GET_LARGEST_TRANSFERS_DATA_LOADING,
  GET_LARGEST_TRANSFERS_DATA_SUCCESS,
  GET_LATEST_DEX_TRADES_FAILURE,
  GET_LATEST_DEX_TRADES_LOADING,
  GET_LATEST_DEX_TRADES_POPUP_FAILURE,
  GET_LATEST_DEX_TRADES_POPUP_LOADING,
  GET_LATEST_DEX_TRADES_POPUP_SUCCESS,
  GET_LATEST_DEX_TRADES_SUCCESS,
  GET_LATEST_NFT_TRADES_BYTX_FAILURE,
  GET_LATEST_NFT_TRADES_BYTX_LOADING,
  GET_LATEST_NFT_TRADES_BYTX_SUCCESS,
  GET_LATEST_NFT_TRADES_FAILURE,
  GET_LATEST_NFT_TRADES_LOADING,
  GET_LATEST_NFT_TRADES_SUCCESS,
  GET_LEADERBOARD_FAILURE,
  GET_LEADERBOARD_LOADING,
  GET_LEADERBOARD_SUCCESS,
  GET_LEAKS_DETAILS_FAILURE,
  GET_LEAKS_DETAILS_SUCCESS,
  GET_LEAKS_SUBMISSION_FAILURE,
  GET_LEAKS_SUBMISSION_SUCCESS,
  GET_NFT_DROPS_FAILURE,
  GET_NFT_DROPS_LOADING,
  GET_NFT_DROPS_SUCCESS,
  GET_TOKEN_DEX_FAILURE,
  GET_TOKEN_DEX_LOADING,
  GET_TOKEN_DEX_POPUP_FAILURE,
  GET_TOKEN_DEX_POPUP_LOADING,
  GET_TOKEN_DEX_POPUP_SUCCESS,
  GET_TOKEN_DEX_SUCCESS,
  GET_TOKEN_INFO_FAILURE,
  GET_TOKEN_INFO_LOADING,
  GET_TOKEN_INFO_SUCCESS,
  GET_TOKEN_SUMMARY_FAILURE,
  GET_TOKEN_SUMMARY_SUCCESS,
  GET_TOPTRADES_CHART_DATA_FAILURE,
  GET_TOPTRADES_CHART_DATA_SUCCESS,
  GET_TOP_BALANCES_FAILURE,
  GET_TOP_BALANCES_LOADING,
  GET_TOP_BALANCES_SUCCESS,
  GET_TOP_LP_CHART_FAILURE,
  GET_TOP_LP_CHART_LOADING,
  GET_TOP_LP_CHART_SUCCESS,
  GET_TOP_LP_FAILURE,
  GET_TOP_LP_LOADING,
  GET_TOP_LP_SUCCESS,
  GET_TOP_LP_UNLISTED_CHART_FAILURE,
  GET_TOP_LP_UNLISTED_CHART_SUCCESS,
  GET_TOP_LP_UNLISTED_FAILURE,
  GET_TOP_LP_UNLISTED_LOADING,
  GET_TOP_LP_UNLISTED_SUCCESS,
  GET_TOP_NFT_BUY_FAILURE,
  GET_TOP_NFT_BUY_LOADING,
  GET_TOP_NFT_BUY_SUCCESS,
  GET_TOP_NFT_CONTRACT_FAILURE,
  GET_TOP_NFT_CONTRACT_LOADING,
  GET_TOP_NFT_CONTRACT_SUCCESS,
  GET_TOP_NFT_TRADES_FAILURE,
  GET_TOP_NFT_TRADES_LOADING,
  GET_TOP_NFT_TRADES_SUCCESS,
  GET_TOP_NFT_TRANSACTION_FAILURE,
  GET_TOP_NFT_TRANSACTION_LOADING,
  GET_TOP_NFT_TRANSACTION_SUCCESS,
  GET_TOP_TRADES_UNLISTED_CHART_FAILURE,
  GET_TOP_TRADES_UNLISTED_CHART_SUCCESS,
  GET_TOP_TRADES_UNLISTED_FAILURE,
  GET_TOP_TRADES_UNLISTED_LOADING,
  GET_TOP_TRADES_UNLISTED_SUCCES,
  GET_TOP_TRANSACTIONS_FAILURE,
  GET_TOP_TRANSACTIONS_LOADING,
  GET_TOP_TRANSACTIONS_SUCCESS,
  GET_USERLEAKS_FAILURE,
  GET_USERLEAKS_LOADING,
  GET_USERLEAKS_SUCCESS,
  GET_VIEWALPHALEAKS_FAILURE,
  GET_VIEWALPHALEAKS_LOADING,
  GET_VIEWALPHALEAKS_SUCCESS,
  GET_WALLET_SUBMISSION_FAILURE,
  GET_WALLET_SUBMISSION_SUCCESS,
  GET_WALLET_SUMISSION_DETAILS_FAILURE,
  GET_WALLET_SUMISSION_DETAILS_SUCCESS,
  HOMEPAGE_DATA_FAILURE,
  HOMEPAGE_DATA_SUCCESS,
  LEAKS_FORM_PRELOAD_FAILURE,
  LEAKS_FORM_PRELOAD_SUCCESS,
  SUBMIT_LEAKS,
  SUBMIT_LEAKS_FAILURE,
  SUBMIT_WALLET,
  SUBMIT_WALLET_FAILURE,
  TOKEN_SEARCH_FAILURE,
  TOKEN_SEARCH_LOADING,
  TOKEN_SEARCH_SUCCESS,
  GET_LATEST_TRADE_DISTRIBUTION_LOADING,
  GET_LATEST_TRADE_DISTRIBUTION_SUCCESS,
  GET_LATEST_TRADE_DISTRIBUTION_FAILURE,
  GET_NFT_LATEST_TRADES_LOADING,
  GET_NFT_LATEST_TRADES_SUCCESS,
  GET_NFT_LATEST_TRADES_FAILURE,
  GET_COLLECTION_TRADE_DISTRIBUTION_LOADING,
  GET_COLLECTION_TRADE_DISTRIBUTION_SUCCESS,
  GET_COLLECTION_TRADE_DISTRIBUTION_FAILURE,
  HOMEPAGE_LOADING,
  GET_ALPHA_REQUEST_LOADING,
  GET_ALPHA_REQUEST_SUCCESS,
  GET_ALPHA_REQUEST_FAILED,
  GET_ALPHA_RESPONSE_LOADING,
  GET_ALPHA_RESPONSE_SUCCESS,
  GET_ALPHA_RESPONSE_FAILED,
  GET_ALPHA_REQ_PRELOAD_SUCCESS,
  GET_ALPHA_REQ_PRELOAD_LOADING,
  GET_ALPHA_REQ_PRELOAD_FAILED,
  SET_BLOCKCHAIN,
} from './action_type'; //Import the actions types constant we defined in our action type
import { initialState } from './initialState';

const dashboard = (state = initialState.dashboard, action) => {
  switch (action.type) {
    case DASHBOARD_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        token: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        token: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

// home page reducer
const homePage = (state = initialState.homePage, action) => {
  switch (action.type) {
    case HOMEPAGE_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case HOMEPAGE_DATA_SUCCESS:
      return {
        ...state,
        matrics: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case HOMEPAGE_DATA_FAILURE:
      return {
        ...state,
        matrics: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

// get leask submissions
const recentLeaks = (state = initialState.recentLeaks, action) => {
  switch (action.type) {
    case GET_LEAKS_SUBMISSION_SUCCESS:
      return {
        ...state,
        leaks: action?.data?.response,
        error: action?.data?.error,
      };
    case GET_LEAKS_SUBMISSION_FAILURE:
      return {
        ...state,
        leaks: [],
        error: action?.data.error,
      };
    default:
      return state;
  }
};

// post leaks form
const submitLeaksForm = (state = initialState.submitLeaksForm, action) => {
  switch (action.type) {
    case SUBMIT_LEAKS:
      const submit_data = state.leaksdata.concat(action.data);
      return {
        ...state,
        submit_data,
      };
    case SUBMIT_LEAKS_FAILURE:
      return {
        ...state,
        error: action?.data.error,
      };
    default:
      return state;
  }
};

const leakFormPreload = (state = initialState.leakFormPreload, action) => {
  switch (action.type) {
    case LEAKS_FORM_PRELOAD_SUCCESS:
      return {
        ...state,
        preload: action?.data.response,
        error: action?.data.error,
      };
    case LEAKS_FORM_PRELOAD_FAILURE:
      return {
        ...state,
        preload: [],
        error: action?.data.error,
      };
    default:
      return state;
  }
};

// get block chain
const blockChainData = (state = initialState.blockChainData, action) => {
  switch (action.type) {
    case GET_BLOCKCHAIN_SUCCESS:
      return {
        ...state,
        blockChain: action?.data.response,
        error: action?.data.error,
      };
    case GET_BLOCKCHAIN_FAILURE:
      return {
        ...state,
        blockChain: [],
        error: action?.data.error,
      };
    default:
      return state;
  }
};

const submitWalletForm = (state = initialState.submitWalletForm, action) => {
  switch (action.type) {
    case SUBMIT_WALLET:
      // appending / replacing incoming state with existing state
      const submit_data = state.walletdata.concat(action.data);
      return {
        ...state,
        submit_data,
      };
    case SUBMIT_WALLET_FAILURE:
      return {
        ...state,
        error: action?.data.error,
      };
    default:
      return state;
  }
};

const recentWalletSubmissions = (
  state = initialState.recentWalletSubmissions,
  action
) => {
  switch (action.type) {
    case GET_WALLET_SUBMISSION_SUCCESS:
      return {
        ...state,
        recentSubmissions: action?.data?.response,
        error: action?.data?.error,
      };
    case GET_WALLET_SUBMISSION_FAILURE:
      return {
        ...state,
        recentSubmissions: [],
        error: action?.data?.error,
      };
    default:
      return state;
  }
};

const latestDexTrades = (state = initialState.latestDexTrades, action) => {
  switch (action.type) {
    case GET_LATEST_DEX_TRADES_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_LATEST_DEX_TRADES_SUCCESS:
      return {
        ...state,
        dexTrades: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_LATEST_DEX_TRADES_FAILURE:
      return {
        ...state,
        dexTrades: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const latestDexTradesPopup = (
  state = initialState.latestDexTradesPopup,
  action
) => {
  switch (action.type) {
    case GET_LATEST_DEX_TRADES_POPUP_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_LATEST_DEX_TRADES_POPUP_SUCCESS:
      return {
        ...state,
        dexTrades: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_LATEST_DEX_TRADES_POPUP_FAILURE:
      return {
        ...state,
        dexTrades: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const tokenSummaryData = (state = initialState.tokenSummaryData, action) => {
  switch (action.type) {
    case GET_TOKEN_SUMMARY_SUCCESS:
      return {
        ...state,
        tokenSummary: action?.data?.response,
        error: action?.data?.error,
      };
    case GET_TOKEN_SUMMARY_FAILURE:
      return {
        ...state,
        tokenSummary: [],
        error: action?.data.error,
      };
    default:
      return state;
  }
};

const topTransactionData = (
  state = initialState.topTransactionData,
  action
) => {
  switch (action.type) {
    case GET_TOP_TRANSACTIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        topTransaction: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_TRANSACTIONS_FAILURE:
      return {
        ...state,
        topTransaction: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const topBalanceData = (state = initialState.topBalanceData, action) => {
  switch (action.type) {
    case GET_TOP_BALANCES_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_BALANCES_SUCCESS:
      return {
        ...state,
        topBalance: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_BALANCES_FAILURE:
      return {
        ...state,
        topBalance: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const tokenDexTrades = (state = initialState.tokenDexTrades, action) => {
  switch (action.type) {
    case GET_TOKEN_DEX_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOKEN_DEX_SUCCESS:
      return {
        ...state,
        dexTrades: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOKEN_DEX_FAILURE:
      return {
        ...state,
        dexTrades: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const tokenDexTradesPopup = (
  state = initialState.tokenDexTradesPopup,
  action
) => {
  switch (action.type) {
    case GET_TOKEN_DEX_POPUP_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOKEN_DEX_POPUP_SUCCESS:
      return {
        ...state,
        dexTrades: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOKEN_DEX_POPUP_FAILURE:
      return {
        ...state,
        dexTrades: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const gamingData = (state = initialState.gamingData, action) => {
  switch (action.type) {
    case GET_GAMING_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_GAMING_DATA_SUCCESS:
      return {
        ...state,
        gaming: action?.data?.response,
        loading: false,
        error: action?.data?.error,
      };
    case GET_GAMING_DATA_FAILURE:
      return {
        ...state,
        gaming: [],
        loading: false,
        error: action?.data?.error,
      };
    default:
      return state;
  }
};

const viewLeaksData = (state = initialState.viewLeaksData, action) => {
  switch (action.type) {
    case GET_VIEWALPHALEAKS_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_VIEWALPHALEAKS_SUCCESS:
      return {
        ...state,
        viewLeaks: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_VIEWALPHALEAKS_FAILURE:
      return {
        ...state,
        viewLeaks: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const leaderBoardData = (state = initialState.leaderBoardData, action) => {
  switch (action.type) {
    case GET_LEADERBOARD_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderBoard: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_LEADERBOARD_FAILURE:
      return {
        ...state,
        leaderBoard: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const userLeaksData = (state = initialState.userLeaksData, action) => {
  switch (action.type) {
    case GET_USERLEAKS_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_USERLEAKS_SUCCESS:
      return {
        ...state,
        userLeaks: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_USERLEAKS_FAILURE:
      return {
        ...state,
        userLeaks: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const leaksDetailsData = (state = initialState.leaksDetailsData, action) => {
  switch (action.type) {
    case GET_LEAKS_DETAILS_SUCCESS:
      return {
        ...state,
        leaksDetails: action?.data?.response,
        error: action?.data?.error,
      };
    case GET_LEAKS_DETAILS_FAILURE:
      return {
        ...state,
        leaksDetails: [],
        error: action?.data?.error,
      };
    default:
      return state;
  }
};

const nftTopBuysData = (state = initialState.nftTopBuysData, action) => {
  switch (action.type) {
    case GET_TOP_NFT_BUY_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_NFT_BUY_SUCCESS:
      return {
        ...state,
        nftTopBuys: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_NFT_BUY_FAILURE:
      return {
        ...state,
        nftTopBuys: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const nftTopTransactionData = (
  state = initialState.nftTopTransactionData,
  action
) => {
  switch (action.type) {
    case GET_TOP_NFT_TRANSACTION_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_NFT_TRANSACTION_SUCCESS:
      return {
        ...state,
        nftTopTransaction: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_NFT_TRANSACTION_FAILURE:
      return {
        ...state,
        nftTopTransaction: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const allWalletSubmissionsData = (
  state = initialState.allWalletSubmissionsData,
  action
) => {
  switch (action.type) {
    case GET_ALL_WALLET_SUBMISSIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_ALL_WALLET_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        allWalletSubmissions: action?.data?.response,
        error: action?.data?.error,
      };
    case GET_ALL_WALLET_SUBMISSIONS_FAILURE:
      return {
        ...state,
        allWalletSubmissions: [],
        error: action?.data?.error,
      };
    default:
      return state;
  }
};
const walletSubmissiondetailsData = (
  state = initialState.walletSubmissiondetailsData,
  action
) => {
  switch (action.type) {
    case GET_WALLET_SUMISSION_DETAILS_SUCCESS:
      return {
        ...state,
        submissionDetails: action?.data?.response,
        error: action?.data?.error,
      };
    case GET_WALLET_SUMISSION_DETAILS_FAILURE:
      return {
        ...state,
        submissionDetails: [],
        error: action?.data?.error,
      };
    default:
      return state;
  }
};
const analyticsData = (state = initialState.analyticsData, action) => {
  switch (action.type) {
    case GET_ANALYTICS_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        analytics: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_ANALYTICS_DATA_FAILURE:
      return {
        ...state,
        analytics: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const notableTraders = (state = initialState.notableTradersData, action) => {
  switch (action.type) {
    case GET_NOTABLETRADERS_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_NOTABLETRADERS_DATA_SUCCESS:
      return {
        ...state,
        notableTraders: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_NOTABLETRADERS_DATA_FAILURE:
      return {
        ...state,
        notableTraders: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const topTradesChartData = (
  state = initialState.topTradesChartData,
  action
) => {
  switch (action.type) {
    case GET_TOPTRADES_CHART_DATA_SUCCESS:
      return {
        ...state,
        topTradesChart: action?.data?.response,
        error: action?.data?.error,
      };
    case GET_TOPTRADES_CHART_DATA_FAILURE:
      return {
        ...state,
        topTradesChart: [],
        error: action?.data?.error,
      };
    default:
      return state;
  }
};
const appTheme = (state = initialState.appTheme, action) => {
  const THEME_UPDATE = 'THEME_UPDATE';
  switch (action.type) {
    case THEME_UPDATE:
      return {
        ...state,
        theme: action.data,
      };
    default:
      return state;
  }
};
const topTradesUnlistedData = (
  state = initialState.topTradesUnlistedData,
  action
) => {
  switch (action.type) {
    case GET_TOP_TRADES_UNLISTED_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_TRADES_UNLISTED_SUCCES:
      return {
        ...state,
        topTradesUnlisted: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_TRADES_UNLISTED_FAILURE:
      return {
        ...state,
        topTradesUnlisted: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const topTradesUnlistedChartData = (
  state = initialState.topTradesUnlistedChartData,
  action
) => {
  switch (action.type) {
    case GET_TOP_TRADES_UNLISTED_CHART_SUCCESS:
      return {
        ...state,
        topTradesUnlistedChart: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_TRADES_UNLISTED_CHART_FAILURE:
      return {
        ...state,
        topTradesUnlistedChart: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const largestTranfersData = (
  state = initialState.largestTranfersData,
  action
) => {
  switch (action.type) {
    case GET_LARGEST_TRANSFERS_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_LARGEST_TRANSFERS_DATA_SUCCESS:
      return {
        ...state,
        largestTransfers: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_LARGEST_TRANSFERS_DATA_FAILURE:
      return {
        ...state,
        largestTransfers: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const largestTranfersByTokenData = (
  state = initialState.largestTranfersByTokenData,
  action
) => {
  switch (action.type) {
    case GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_SUCCESS:
      return {
        ...state,
        largestTransfersbytoken: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_LARGEST_TRANSFERS_DATA_BY_TOKEN_FAILURE:
      return {
        ...state,
        largestTransfersbytoken: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const tokenInfoData = (state = initialState.tokenInfoData, action) => {
  switch (action.type) {
    case GET_TOKEN_INFO_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOKEN_INFO_SUCCESS:
      return {
        ...state,
        tokenInfo: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOKEN_INFO_FAILURE:
      return {
        ...state,
        tokenInfo: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const alphaleaksData = (state = initialState.alphaleaksData, action) => {
  switch (action.type) {
    case GET_ALPHALEAKS_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_ALPHALEAKS_SUCCESS:
      return {
        ...state,
        alphaleaks: action?.data?.response?.data,
        error: action?.data?.error,
        loading: false,
      };
    case GET_ALPHALEAKS_FAILURE:
      return {
        ...state,
        alphaleaks: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const walletAuthentication = (
  state = initialState.walletAuthentication,
  action
) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        userAddress: action?.data?.address,
        authStatus: action?.data?.status,
      };
    default:
      return state;
  }
};
const tokenSearchDetails = (
  state = initialState.tokenSearchDetails,
  action
) => {
  switch (action.type) {
    case TOKEN_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case TOKEN_SEARCH_SUCCESS:
      return {
        ...state,
        searchInfo: action?.data?.response?.data,
        error: action?.data?.error,
        loading: false,
      };
    case TOKEN_SEARCH_FAILURE:
      return {
        ...state,
        searchInfo: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const nftDropsData = (state = initialState.nftDropsData, action) => {
  switch (action.type) {
    case GET_NFT_DROPS_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_NFT_DROPS_SUCCESS:
      return {
        ...state,
        nftDrops: action?.data?.response?.data,
        error: action?.data?.error,
        loading: false,
      };
    case GET_NFT_DROPS_FAILURE:
      return {
        ...state,
        nftDrops: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

// GET TOP LP REDUCER
const topLPData = (state = initialState.topLPData, action) => {
  switch (action.type) {
    case GET_TOP_LP_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_LP_SUCCESS:
      return {
        ...state,
        topLp: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_LP_FAILURE:
      return {
        ...state,
        topLp: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

// GET TOP LP  CHART REDUCER
const topLpChartData = (state = initialState.topLpChartData, action) => {
  switch (action.type) {
    case GET_TOP_LP_CHART_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_LP_CHART_SUCCESS:
      return {
        ...state,
        toplpchart: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_LP_CHART_FAILURE:
      return {
        ...state,
        toplpchart: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

// GET TOP LP UNLISTED
const topLpUnlistedData = (state = initialState.topLpUnlistedData, action) => {
  switch (action.type) {
    case GET_TOP_LP_UNLISTED_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_LP_UNLISTED_SUCCESS:
      return {
        ...state,
        topLpUnlisted: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_LP_UNLISTED_FAILURE:
      return {
        ...state,
        topLpUnlisted: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

// GET TOP LP UNLISTED CHART
const topLpUnlistedChartData = (
  state = initialState.topLpUnlistedChartData,
  action
) => {
  switch (action.type) {
    case GET_TOP_LP_UNLISTED_CHART_SUCCESS:
      return {
        ...state,
        topLpUnlistedChart: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_LP_UNLISTED_CHART_FAILURE:
      return {
        ...state,
        topLpUnlistedChart: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
// GET NFT LATEST TRADES
const nftLatestTradesData = (
  state = initialState.nftLatestTradesData,
  action
) => {
  switch (action.type) {
    case GET_LATEST_NFT_TRADES_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_LATEST_NFT_TRADES_SUCCESS:
      return {
        ...state,
        nftLatestTrades: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_LATEST_NFT_TRADES_FAILURE:
      return {
        ...state,
        nftLatestTrades: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

// LATEST NFT TRADES BY TX HASH
const nftLatestTradesByTXData = (
  state = initialState.nftLatestTradesByTXData,
  action
) => {
  switch (action.type) {
    case GET_LATEST_NFT_TRADES_BYTX_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_LATEST_NFT_TRADES_BYTX_SUCCESS:
      return {
        ...state,
        nftLatestTradesByTX: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_LATEST_NFT_TRADES_BYTX_FAILURE:
      return {
        ...state,
        nftLatestTradesByTX: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const nftTopTradesData = (state = initialState.nftTopTradesData, action) => {
  switch (action.type) {
    case GET_TOP_NFT_TRADES_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_NFT_TRADES_SUCCESS:
      return {
        ...state,
        nftTopTrades: action?.data?.ele,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_NFT_TRADES_FAILURE:
      return {
        ...state,
        nftTopTrades: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const nftTopContractsData = (
  state = initialState.nftTopContractsData,
  action
) => {
  switch (action.type) {
    case GET_TOP_NFT_CONTRACT_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_TOP_NFT_CONTRACT_SUCCESS:
      return {
        ...state,
        nftTopContracts: action?.data?.bindData,
        error: action?.data?.error,
        loading: false,
      };
    case GET_TOP_NFT_CONTRACT_FAILURE:
      return {
        ...state,
        nftTopContracts: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const latestTradeDistributionData = (
  state = initialState.latestTradeDistributionData,
  action
) => {
  switch (action.type) {
    case GET_LATEST_TRADE_DISTRIBUTION_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_LATEST_TRADE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        latestTradeDistribution: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_LATEST_TRADE_DISTRIBUTION_FAILURE:
      return {
        ...state,
        latestTradeDistribution: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const collectionLatestTradesData = (
  state = initialState.collectionLatestTradesData,
  action
) => {
  switch (action.type) {
    case GET_NFT_LATEST_TRADES_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_NFT_LATEST_TRADES_SUCCESS:
      return {
        ...state,
        collectionLatestTrades: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_NFT_LATEST_TRADES_FAILURE:
      return {
        ...state,
        collectionLatestTrades: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};
const collectionTradeDistributionData = (
  state = initialState.collectionTradeDistributionData,
  action
) => {
  switch (action.type) {
    case GET_COLLECTION_TRADE_DISTRIBUTION_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_COLLECTION_TRADE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        collectionTradeDistribution: action?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_COLLECTION_TRADE_DISTRIBUTION_FAILURE:
      return {
        ...state,
        collectionTradeDistribution: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const alphaRequestData = (state = initialState.alphaRequestData, action) => {
  switch (action.type) {
    case GET_ALPHA_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_ALPHA_REQUEST_SUCCESS:
      return {
        ...state,
        response: action?.data?.response?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_ALPHA_REQUEST_FAILED:
      return {
        ...state,
        response: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const alphaResponseData = (state = initialState.alphaResponseData, action) => {
  switch (action.type) {
    case GET_ALPHA_RESPONSE_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_ALPHA_RESPONSE_SUCCESS:
      return {
        ...state,
        response: action?.data?.response?.data?.response,
        error: action?.data?.error,
        loading: false,
      };
    case GET_ALPHA_RESPONSE_FAILED:
      return {
        ...state,
        response: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const alphaReqPreloadData = (
  state = initialState.alphaReqPreloadData,
  action
) => {
  switch (action.type) {
    case GET_ALPHA_REQ_PRELOAD_LOADING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_ALPHA_REQ_PRELOAD_SUCCESS:
      return {
        ...state,
        response: action?.data?.response?.data?.response[0]?.requestCategories,
        error: action?.data?.error,
        loading: false,
      };
    case GET_ALPHA_REQ_PRELOAD_FAILED:
      return {
        ...state,
        response: [],
        error: action?.data?.error,
        loading: false,
      };
    default:
      return state;
  }
};

const currentBlockChain = (state = initialState.currentBlockChain, action) => {
  switch (action.type) {
    case SET_BLOCKCHAIN:
      return {
        ...state,
        selectedBlockChain: action.chain_id,
      };
    default:
      return state;
  }
};
const earnTokenSlider = (state = initialState.earnTokenSlider, action) => {
  switch (action.type) {
    case 'SET_SLIDER ':
      return {
        ...state,
        isLeaksSliderOver: action.isLeaksSliderOver,
        isWalletSliderOver: action.isWalletSliderOver,
        openSlideOver: action.openSlideOver,
      };
    default:
      return state;
  }
};

// Combine all the reducers
const rootReducer = combineReducers({
  dashboard,
  homePage,
  recentLeaks,
  submitLeaksForm,
  leakFormPreload,
  blockChainData,
  submitWalletForm,
  recentWalletSubmissions,
  latestDexTrades,
  latestDexTradesPopup,
  tokenSummaryData,
  topTransactionData,
  topBalanceData,
  tokenDexTrades,
  tokenDexTradesPopup,
  gamingData,
  viewLeaksData,
  leaderBoardData,
  userLeaksData,
  leaksDetailsData,
  nftTopBuysData,
  nftTopTransactionData,
  allWalletSubmissionsData,
  walletSubmissiondetailsData,
  analyticsData,
  notableTraders,
  topTradesChartData,
  appTheme,
  topTradesUnlistedData,
  topTradesUnlistedChartData,
  largestTranfersData,
  largestTranfersByTokenData,
  tokenInfoData,
  alphaleaksData,
  walletAuthentication,
  tokenSearchDetails,
  nftDropsData,
  topLPData,
  topLpChartData,
  topLpUnlistedData,
  topLpUnlistedChartData,
  nftLatestTradesData,
  nftLatestTradesByTXData,
  nftTopTradesData,
  nftTopContractsData,
  latestTradeDistributionData,
  collectionLatestTradesData,
  collectionTradeDistributionData,
  alphaRequestData,
  alphaResponseData,
  alphaReqPreloadData,
  currentBlockChain,
  earnTokenSlider,
});
export default rootReducer;
