import PropTypes from 'prop-types';
import { AppContext } from 'Providers/appProvider';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { initialState } from 'statemgmt/initialState';
import {
  ApiPlatformIconDark,
  ApiPlatformIconLight,
  CommunityAlphaIconDark,
  CommunityAlphaIconLight,
  DataPlatformIconDark,
  DataPlatformIconLight,
  MLPlatformIconDark,
  MLPlatformIconLight,
  OnchainAnalyticsIconDark,
  OnchainAnalyticsIconLight,
  PlatformConfigurationIconDark,
  PlatformConfigurationIconLight,
} from 'Utilities/icons';

const FEATURED_PRODUCTS = [
  {
    title: 'Community Alpha',
    description:
      'Engage and get rewarded. Provide value by sharing info. Get value from crowd wisdom.',
    href: '/app/social/community-alpha/how-it-works',
    slug: 'community_alpha',
  },
  {
    title: 'On Chain Analytics',
    description:
      'Get powerful pre-built insights on blockchain data. Spend less time, get most value.',
    href: '/app/prebuilt/on-chain-analytics/overview',
    slug: 'on_chain_analytics',
  },
  {
    title: 'Platform Configuration',
    description:
      'Setup your own dedicated secure cluster to enable advanced features.',
    href: '/app/platforms/configuration',
    slug: 'platform_configuration',
  },
  {
    title: 'Data Platform',
    description:
      'Build your own data insights using our stack of data solutions.',
    href: '/app/platforms/data-platform/overview',
    slug: 'data_platform',
  },

  {
    title: 'ML Platform',
    description:
      'Build ML models using our data science experimentation environment.',
    href: '/app/platforms/ml-platform/overview',
    slug: 'ml_platform',
  },
  {
    title: 'API',
    description: 'Reliable, Fast, SQL-friendly and Data-Science friendly APIs',
    href: '/app/integrations/api/overview',
    slug: 'api',
  },
];

const ICONS = {
  dark: {
    community_alpha: CommunityAlphaIconDark,
    on_chain_analytics: OnchainAnalyticsIconDark,
    platform_configuration: PlatformConfigurationIconDark,
    data_platform: DataPlatformIconDark,
    ml_platform: MLPlatformIconDark,
    api: ApiPlatformIconDark,
  },
  light: {
    community_alpha: CommunityAlphaIconLight,
    on_chain_analytics: OnchainAnalyticsIconLight,
    platform_configuration: PlatformConfigurationIconLight,
    data_platform: DataPlatformIconLight,
    ml_platform: MLPlatformIconLight,
    api: ApiPlatformIconLight,
  },
};

const FeaturedProduct = ({ image, title, description, href, productId }) => {
  const { uuid, setUuid } = useContext(AppContext); //WHATISTHIS: used for re rendering the ui on path change
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div className="group relative rounded-xl">
      <div className="absolute -inset-px rounded-xl border-2 border-transparent opacity-0 overview-card group-hover:opacity-100" />
      <div className="relative h-full p-8 rounded-xl">
        <NavLink
          to={href}
          className="flex flex-col items-center"
          onClick={() => setUuid(uuid + 1)}
        >
          <img src={ICONS[theme][productId]} className="place-items-center" />
          <h1 className="text-black dark:text-white text-md font-medium py-6 text-center">
            {title}
          </h1>
          <p className="text-center   text-gray-500 dark:text-dark-textPrimary">
            {description}
          </p>
        </NavLink>
      </div>
    </div>
  );
};
function FeaturedProducts() {
  return (
    <div className="my-4">
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
        {FEATURED_PRODUCTS.map((itm, key) => (
          <FeaturedProduct
            key={key}
            title={itm.title}
            description={itm.description}
            href={itm.href}
            productId={itm.slug}
          />
        ))}
      </div>
    </div>
  );
}

export default FeaturedProducts;

FeaturedProduct.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  productId: PropTypes.string,
};
