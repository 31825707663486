/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { SCAN_ADDRESS } from 'Helper/constants';
import React from 'react';
import { Etherscan } from 'Utilities';
export const column = [
  {
    Header: 'Rank',
    accessor: 'influence_rank',
    Filter: '',
  },
  {
    Header: 'Account Address',
    accessor: 'user_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.user_address}
        type={SCAN_ADDRESS}
        isSubString={false}
        chainId={''}
      />
    ),
    Filter: '',
  },
  {
    Header: 'Alpha Score',
    accessor: 'alpha_score',
    Cell: (cell) => (
      <>
        {cell.row.values.alpha_score < 0 ? (
          <p className="text-red-600">{cell.row.values.alpha_score}</p>
        ) : (
          <p>{cell.row.values.alpha_score}</p>
        )}
      </>
    ),
    Filter: '',
  },
  {
    Header: 'Token Balance',
    accessor: 'token_balance',
    Filter: '',
  },
  {
    width: 300,
    Header: ' ',
    Cell: ({ cell }) => (
      <div className="my-3">
        <a
          className="items-center inline-flex px-3 py-2 border border-blue-700 shadow-sm text-xs font-semibold rounded-md text-blue-700  dark:text-dark-textPrimary  dark:border-gray-700"
          onClick={() => {
            localStorage.setItem('alpha_leaks_tab', `"3"`);
            localStorage.setItem(
              'user_address',
              `"${cell.row.values.user_address}"`
            );
            // getTabChange(3);
          }}
          href={`user-leads`}
        >
          View all leads by user
          <ExternalLinkIcon className="w-4 mx-2" />
        </a>
      </div>
    ),
    openUtab: true,
    Filter: '',
  },
];
