import React from 'react';
import PropTypes from 'prop-types';

Divider.propTypes = {
  text: PropTypes.string,
};
export default function Divider({ text }) {
  return (
    <div className="relative mt-3">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300 dark:border-gray-700" />
      </div>
      <div className="relative flex justify-center">
        <span className="dark:bg-dark-themeBgDark px-3 text-sm font-medium dark:text-gray-300 text-gray-900">
          {text}
        </span>
      </div>
    </div>
  );
}
