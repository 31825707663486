import { Menu, Transition } from '@headlessui/react';
import {
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// eslint-disable-next-line react/prop-types
function ActionMenu({ user_clusters_id }) {
  const navigate = useNavigate();

  const redirectToLogs = () => {
    console.log('called');
  };

  const fn = () => {
    console.log('sample function');
  };

  const menuOptions = [
    {
      label: 'Edit',
      id: 1,
      kind: 'function',
      action: redirectToLogs,
      icon: PencilIcon,
    },
    {
      label: 'Delete',
      id: 2,
      kind: 'function',
      action: fn,
      icon: TrashIcon,
    },
  ];

  return (
    <div>
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="sr-only">Open options</span>
            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-36 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-dark-themePrimary shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {menuOptions.map((item, key) => (
                <Menu.Item key={key}>
                  <div
                    className="flex px-4 py-2 text-xs cursor-pointer dark:text-dark-textPrimary text-gray-700 hover:bg-light-themeSecondary dark:hover:bg-gray-700"
                    onClick={item.action}
                  >
                    {/* <item.icon className="w-6 h-6 dark:text-gray-800" /> */}
                    <p>{item.label}</p>
                  </div>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default ActionMenu;
