/* eslint-disable react/prop-types */
import SentimentFng from 'Components/Home/SharedComponents/sentimentFng';
import React from 'react';
import SentimentBTCLs from './Atoms/sentimentBTCLs';

export default function Sentiment({ fng }) {
  return (
    <>
      {' '}
      <div className="px-5 py-1 bg-white dark:bg-dark-themeSecondary border-b dark:border-gray-700">
        <>
          <div className="font-semibold dark:text-white mt-2">Sentiment</div>
          <div className="text-xs text-gray-400 mb-2">
            General sentiment of the current Bitcoin market.
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <SentimentFng fng={fng} />
            <SentimentBTCLs />
          </div>
        </>
      </div>
    </>
  );
}
