import { PageNotFound } from 'Utilities';
import PropTypes from 'prop-types';
import AmazonS3 from './AS3/amazonS3';
import Mysql from './MySQL/mysql';
import AutoCloseForm from './autoExpireForm';

function DataSource({ source }) {
  function renderSource() {
    switch (source) {
      case 'amazon_s3':
        return <AmazonS3 />;
      case 'mysql':
        return <Mysql />;
      default:
        return <PageNotFound />;
    }
  }

  return (
    <>
      <div className="mx-2 flex flex-row-reverse">
        <h1 className="dark:text-white float-right">
          <AutoCloseForm />
        </h1>
      </div>

      {renderSource()}
    </>
  );
}

export default DataSource;

DataSource.propTypes = {
  source: PropTypes.string,
};
