export const processTokenDexTrades = (dexTrades) => {
  let dataSet = [];
  if (dexTrades) {
    for (let key of dexTrades) {
      dataSet.push({
        transaction_hash: key['f'][0]['v'],
        name: key['f'][1]['v'],
        contract_address: key['f'][2]['v'],
        dex: key['f'][3]['v'],
        trade_action: key['f'][4]['v'],
        trade_type: key['f'][5]['v'],
        token_amount: key['f'][6]['v'],
        token_value_usd: key['f'][7]['v'],
        timestamp_utc: key['f'][8]['v'],
        token_value_usd_txdate: key['f'][9]['v'],
        using_ethvalue_current: key['f'][10]['v'],
        using_ethvalue_txdate: key['f'][11]['v'],
        trader_address: key['f'][12]['v'],
      });
    }
  }
  return dataSet;
};

export const processNftDexTrades = (dextrades) => {
  let dataSet = [];
  if (dextrades) {
    for (let key of dextrades) {
      dataSet.push({
        transaction_hash: key['f'][0]['v'],
        name: key['f'][1]['v'],
        contract_address: key['f'][2]['v'],
        token_id: key['f'][3]['v'],
        dex: key['f'][4]['v'],
        trade_action: key['f'][5]['v'],
        token_amount: key['f'][6]['v'],
        token_value_usd: key['f'][7]['v'],
        timestamp_utc: key['f'][8]['v'],
        token_value_usd_txdate: key['f'][9]['v'],
        eth_value: key['f'][10]['v'],
        trader_address: key['f'][11]['v'],
        trade_type: key['f'][12]['v'],
      });
    }
  }
  return dataSet;
};
