import { RefreshIcon } from '@heroicons/react/outline';
import { getUrlParam } from 'Helper/getUrlPram';
import { configureVault, getLoggedUser } from 'Helper/services';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import { Modal } from 'Utilities';
import RealisticConfetti from 'Utilities/Confetti/reaclisticConfetti';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ConfigureVault() {
  const [userKeys, setUserKeys] = useState({});
  const [loading, setLoading] = useState('');
  const [showModal, setShowModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { user_address } = useGetCurrentUser();
  const user_cluster_id = getUrlParam('id');

  const configVault = async () => {
    setLoading(true);

    const isVaultConfigCompleted = await configureVault({ user_cluster_id });
    if (isVaultConfigCompleted.status) {
      setLoading(false);
      const vault_auth_keys = isVaultConfigCompleted?.vaultAuthKeys?.data;
      const oidc_client = isVaultConfigCompleted?.oidcClient?.data;
      const vaultkeys = {
        recovery_keys: vault_auth_keys?.recovery_keys_base64,
        root_token: vault_auth_keys?.root_token,
      };
      const oidc_client_keys = {
        client_id: oidc_client?.client_id,
        client_secret: oidc_client?.client_secret,
        client_registration_access_token:
          oidc_client?.client_registration_access_token,
        client_registration_client_uri:
          oidc_client?.client_registration_client_uri,
      };
      setShowModal(true);
      setUserKeys({ vaultkeys, oidc_client_keys });
    }
  };

  const UserVaultKeysModal = () => {
    return (
      <div className="p-2">
        <h1 className="">Keep it safe!</h1>
        <div className="my-3">
          <p className="text-sm my-2">
            Please store this file somewhere secure
          </p>
          <p className="text-sm">
            Click on the download button to save on to your machine.
          </p>
          <button
            type="button"
            className="inline-flex float-right items-center rounded-md border border-transparent bg-blue-600 px-4 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mt-4"
            onClick={jsonFileDownload}
          >
            Dowload
          </button>
        </div>
      </div>
    );
  };

  const jsonFileDownload = async () => {
    const user = await getLoggedUser(user_address);
    if (user) {
      const namespace = user.message[0].username_internal;
      const fileName = `v_${user_cluster_id}_${namespace}.json`;
      const data = new Blob([JSON.stringify(userKeys)], { type: 'text/json' });
      const jsonURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = jsonURL;
      link.setAttribute('download', fileName);
      link.click();
      document.body.removeChild(link);
      setShowModal(false);
      navigate('/app/platforms/configuration/user-clusters');
    }
  };
  return (
    <>
      {showModal && (
        <Modal
          disableBackdropClick={true}
          showPopup={showModal}
          setShowPopup={setShowModal}
          width={'4/12'}
        >
          <UserVaultKeysModal />
        </Modal>
      )}
      {showModal && (
        <>
          <RealisticConfetti />
        </>
      )}
      <div className="flex flex-col items-center justify-center h-102">
        <div className="block text-md font-semibold leading-10 text-gray-900 dark:text-white text-center">
          Sign in with Ethereum to configure Vault with your Ethereum address as
          your identifier..
        </div>
        <div className="mx-auto w-full max-w-2xl">
          <div className="mt-8 p-6 border rounded-lg dark:border-gray-700 w-full">
            <div className="text-sm font-medium text-gray-900 dark:text-white flex space-x-1">
              Important Notes:
            </div>
            <ul className="list-disc dark:text-dark-textPrimary text-sm mx-3 my-4">
              <li className="py-3">
                Once you start this process please
                <span className="dark:text-white font-semibold dark:font-normal px-1">
                  do NOT close the window
                </span>
                until the process is complete.
              </li>
              <li className="py-3">
                At the end of this step you will be prompted to download a file
                that contains the Vault keys. Store the file securely because
                <span className="dark:text-white font-semibold dark:font-normal px-1">
                  this key can&apos;t be recovered if lost.
                </span>
              </li>
              <li className="py-3">
                This process should take
                <span className="dark:text-white font-semibold dark:font-normal px-1">
                  less than a minute.
                </span>
              </li>
            </ul>
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={configVault}
            >
              {loading ? (
                <RefreshIcon className="w-4 animate-spin" />
              ) : (
                'Proceed'
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfigureVault;
