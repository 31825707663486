/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import BuilderDM from './appIcons/builder-dm';
import BuilderLM from './appIcons/builder-lm';
import { BuilderIconDark, BuilderIconLight } from 'Utilities/icons';

function BuilderIcon() {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return theme === 'dark' ? (
    <img src={BuilderIconDark} className="h-10 w-10" />
  ) : (
    <img src={BuilderIconLight} className="h-10 w-10" />
  );
}

export default BuilderIcon;
