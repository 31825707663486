/* This example requires Tailwind CSS v2.0+ */
import { getUrlParam } from 'Helper/getUrlPram';
import { useLocalStorage } from 'Hooks';
import { Overview } from 'Utilities';
import { useRef } from 'react';
import EarlyHolders from './EarlyHolders/earlyHolders';
import { builderOptions } from './constants';
import { LightningBoltIcon } from '@heroicons/react/outline';
import OverviewCard from 'Utilities/Cards/overviewCard';
import { AlphaLeadsIcon } from 'Utilities/images';
import { ArrowDownIcon, ChevronDownIcon } from '@heroicons/react/solid';

export default function Builder() {
  const container = useRef(null);
  const [analyticsTab, setAnalyticsTab] = useLocalStorage(
    'analytics_active_tab',
    'WEH'
  );
  // collection of components!
  const tabObj = [
    {
      tab_id: 'WEH',
      name: 'early-holders',
      component: EarlyHolders,
    },
  ];

  const props = {
    analyticsTab,
    setAnalyticsTab,
    basePath: '',
    search: { chain: getUrlParam('chain') },
  };
  return (
    <div className="mx-1 my-6">
      <Overview
        title="Insights"
        desc="Discover on-chain data insights interactively for any token or a list of tokens. With 'Insights', use various filter options to easily access customized, relevant data, helping you make informed decisions in the decentralized exchange (DEx) space."
      />
      <div className="max-w-7xl mt-6 mx-10 sm:mt-8">
        <div className="max-w-6xl">
          {builderOptions.map((item, k) => (
            <div key={k} className="my-4">
              <div className="flex justify-between">
                <h1 className="dark:text-gray-300 m-2 text-sm">{item.label}</h1>
                {/* <ChevronDownIcon className="w-5 dark:text-gray-300 font-normal" /> */}
              </div>
              <div className="grid grid-cols-1 gap-10 lg:grid-cols-3 p-1">
                {item?.children &&
                  item.children.map((child, j) => (
                    <div key={j} className="col-span-1 flex">
                      <OverviewCard
                        key={j}
                        title={child.label}
                        url={child.url}
                        desc={child.desc}
                        idx={j}
                        // icon={child.icon}
                      />
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
