import React, { createRef, useEffect, useRef } from 'react';
import GradientProgressBar from 'Utilities/ProgressBar/gradientProgressBar';
import { Helmet } from 'react-helmet';
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';
import { useSelector } from 'react-redux';

export default function CorrelatedChart() {
  const myRef = useRef();
  useEffect(() => {
    // const script = document.createElement('script');
    // script.src = 'https://s3.tradingview.com/tv.js';
    // script.async = false;
    // script.innerHTML = JSON.stringify({
    //   autosize: true,
    //   symbol: 'INDEX:DXY',
    //   interval: 'D',
    //   timezone: 'Etc/UTC',
    //   theme: 'light',
    //   style: '2',
    //   locale: 'en',
    //   toolbar_bg: '#f1f3f6',
    //   enable_publishing: false,
    //   hide_top_toolbar: true,
    //   hide_legend: true,
    //   save_image: false,
    //   container_id: 'tradingview_53b83',
    // });
    // myRef.current.appendChild(script);
  }, []);
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <>
      <div className="h-24 md:h-40">
        <AdvancedRealTimeChart
          autosize
          interval="D"
          timezone="Etc/UTC"
          theme={theme}
          style="2"
          locale=" en"
          toolbar_bg="#f1f3f6"
          enable_publishing={false}
          hide_top_toolbar={true}
          hide_legend={true}
          save_image={false}
          container_id="tradingview_53b83"
          symbol="INDEX:DXY"
        />
      </div>
    </>
  );
}
