import { ApprovalStatus, Labels, SlideOver } from 'Utilities';

/* eslint-disable react/prop-types */
export const column = [
  {
    Header: 'Wallet Address',
    accessor: 'wallet_address',
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userwallet',
  },
  {
    Header: 'Score',
    accessor: 'score',
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userwallet',
  },
  {
    Header: 'Approval Status',
    accessor: 'approval_status',
    Cell: ({ cell }) => {
      return cell.row.values.approval_status ? (
        <ApprovalStatus status={cell.row.values.approval_status} />
      ) : (
        '-'
      );
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userwallet',
  },
  {
    Header: 'Submitted on',
    accessor: 'submission_timestamp_utc',
    Cell: ({ cell }) => {
      return new Date(cell.row.values.submission_timestamp_utc).toDateString();
    },
    Filter: '',
  },
  {
    Header: 'Labels',
    accessor: 'labels_json',
    Cell: ({ cell }) => {
      return <Labels label={cell.row.values.labels_json} />;
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userwallet',
  },
  {
    width: 300,
    Header: ' ',
    accessor: 'id',
    Cell: ({ cell }) => (
      <>
        <button
          type="button"
          className="items-center px-3 py-2 border border-blue-700 shadow-sm text-sm leading-4 font-semibold rounded-md text-blue-700 bg-white hover:bg-gray-50 dark:text-dark-textPrimary dark:bg-dark-themeBgDark dark:border-gray-700"
        >
          Details
        </button>
        <SlideOver />
      </>
    ),
    disableSortBy: true,
    isDrawer: true,
    drawerComponent: 'userwallet',
    Filter: '',
  },
];
