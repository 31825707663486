import { getRelatedRequests } from 'Helper/services';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'Utilities';

RecentRequests.propTypes = {
  category_id: PropTypes.number,
  request_id: PropTypes.number,
};
export default function RecentRequests({ category_id, request_id }) {
  const [relatedRequests, setRelatedRequests] = useState();
  const getRelatedRequest = () => {
    getRelatedRequests(category_id, request_id).then((response) => {
      setRelatedRequests(response);
    });
  };
  useEffect(() => {
    getRelatedRequest();
  }, []);
  return (
    <div className="bg-gray-200 dark:bg-dark-themeBgDark p-6 rounded-lg">
      <Text
        size="lg"
        fontWeight="semibold"
        text="Related Alpha Requests"
        className="dark:text-white my-4"
      />

      <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
        {relatedRequests?.response?.map((item, key) => (
          <li key={key} className="py-4 flex">
            <div className="ml-3">
              <Link
                to={`/app/social/community-alpha/alpha-request/${item.id}`}
                target="_blank"
                className="text-md font-medium text-blue-600 cursor-pointer"
              >
                {item.title}
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
