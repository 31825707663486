import { Menu, Transition } from '@headlessui/react';
import { FilterIcon, RefreshIcon } from '@heroicons/react/outline';
import useGetCurrentUser from 'Hooks/useGetCurrentUser';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWalletSubmissions } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { Refresh, Select, Table } from 'Utilities';

import { column } from './header';

function UserWalletSubmissions() {
  const [filter, setFilter] = useState('All');
  const [refresh, setRefresh] = useState(false);

  // useDispatch() instance
  const dispatch = useDispatch();
  // listening to redux store changes
  const { allWalletSubmissions, loading, userAddress } = useSelector(
    (s = initialState) => {
      return {
        allWalletSubmissions:
          s.allWalletSubmissionsData.allWalletSubmissions.data,
        loading: s.viewLeaksData.loading,
        userAddress: s.walletAuthentication.userAddress,
      };
    }
  );

  const filterOptions = [
    {
      text: 'All',
      value: 'All',
    },
    {
      text: 'Approved',
      value: 'APPROVED',
    },
    {
      text: 'Declined',
      value: 'DECLINED',
    },
  ];
  const handleCallBack = (selected) => {
    setFilter(selected);
  };
  const { user_address } = useGetCurrentUser();
  // FIXME: get the login object now
  // useeffect hook start
  useEffect(() => {
    dispatch(getAllWalletSubmissions(user_address, filter));
  }, [filter, refresh]);
  // useeffect hook end

  return (
    <div className="h-full">
      <div className="bg-light-themeSecondary dark:bg-dark-themePrimary p-4 rounded-tr-md">
        <div className="p-2">
          <h2 className="font-bold text-xl ml-2 dark:text-dark-textPrimary">
            Wallet Submissions
          </h2>
          <p className="text-gray-400 m-2 dark:text-dark-textSecondary">
            Score for each submission will be updated after review completion.
            Reviews are manual.
          </p>
        </div>
        <div className="w-full border-t border-gray-300 dark:border-gray-700" />
        <div className="hidden sm:block">
          <div className="flow-root mt-2">
            <div className="flex justify-between mb-2">
              <div className="flex flex-wrap float-left">
                <Select
                  optionsObj={filterOptions}
                  state={filter}
                  setState={setFilter}
                />
              </div>
              <div className="mx-2 my-1 flex space-x-2">
                <div className="mx-2 ">
                  <div className="flex justify-between mt-2">
                    {/* <button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                      <span className="sr-only">refresh Option</span>
                      <RefreshIcon
                        className={`w-4 h-4 text-gray-400 cursor-pointer mouse-pointer `}
                        onClick={() => {
                          setRefresh(!refresh);
                        }}
                      />
                    </button> */}
                    <Refresh
                      state={refresh}
                      setState={setRefresh}
                      loadingbutton={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex flex-row-reverse my-2">
          <div className="">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                  <span className="sr-only">Open options</span>
                  <FilterIcon className="h-4 w-4" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-dark-bgMutedDark ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1 px-2">
                    <div className="flow-root ">
                      <div className="">
                        <Select
                          optionsObj={filterOptions}
                          state={filter}
                          setState={setFilter}
                        />
                      </div>
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="mx-2">
            <button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
              <span className="sr-only">refresh Option</span>
              <RefreshIcon
                className={`w-4 h-4 text-gray-400 cursor-pointer mouse-pointer `}
                onClick={() => {
                  setRefresh(!refresh);
                }}
              />
            </button>
          </div>
        </div>
        <div className="shadow overflow-hidden  border-b border-gray-200 rounded-b-md overflow-y-scroll overflow-x-auto h-102 metric-scroll dark:border-dark-secondary dark:bg-dark-tabPillBgSelectedDark bg-light-tabPillBgSelectedLight">
          {allWalletSubmissions ? (
            <Table
              data={allWalletSubmissions}
              col={column}
              isloading={loading}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default UserWalletSubmissions;
