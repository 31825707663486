import TokenSymbol from 'Components/Defi/Atoms/tokenSymbol';
import { SCAN_ADDRESS, SCAN_TOKEN, SCAN_TX } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import moment from 'moment';
import { Etherscan } from 'Utilities';

export const column = [
  {
    Header: 'TX Hash',
    accessor: 'tx_hash',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.tx_hash}
        type={SCAN_TX}
        isSubString={true}
      />
    ),
    Filter: '',
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: (cell) => <TokenSymbol symbol={cell.row.values.symbol} />,
    Filter: '',
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: '',
  },
  {
    Header: 'Token Address',
    accessor: 'token_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.token_address}
        type={SCAN_TOKEN}
        isSubString={true}
      />
    ),
    Filter: '',
  },

  {
    Header: 'From Address',
    accessor: 'from_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.from_address}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    Filter: '',
  },
  {
    Header: 'To Address',
    accessor: 'to_address',
    Cell: (cell) => (
      <Etherscan
        address={cell.row.values.to_address}
        type={SCAN_ADDRESS}
        isSubString={true}
      />
    ),
    Filter: '',
  },
  {
    Header: 'Value',
    accessor: 'value',
    Cell: (cell) => (
      <>
        {cell.row.values.value
          ? formatValue('format', cell.row.values.value)
          : '-'}
      </>
    ),
    Filter: '',
  },
  {
    Header: 'USD Value (Current)',
    accessor: 'usd_value',
    Cell: (cell) => (
      <>{cell.row.values.usd_value ? '$' + cell.row.values.usd_value : '-'}</>
    ),
    Filter: '',
  },
  {
    Header: 'Block Timestamp',
    accessor: 'block_timestamp',
    Cell: ({ cell }) => {
      // let dateTime = new Date(
      //   cell.row.values.block_timestamp * 1000
      // ).toUTCString('en-US');

      let dateTime = moment
        .utc(cell.row.values.block_timestamp * 1000)
        .fromNow();

      return dateTime;
    },
    Filter: '',
  },
];
