import React from 'react';
import no from '../../Assets/Images/no.png';
function EmptyDataScreen() {
  return (
    <div>
      <div className="h-screen">
        <div className="h-full flex flex-col items-center my-12">
          <div>
            <img src={no} alt="No data found" className="w-96" />
          </div>
          <div className="text-3xl font-bold dark:text-dark-textPrimary">
            No Drops Found
          </div>
          <div className="text-md dark:text-dark-textPrimary">
            Try with different date
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptyDataScreen;
