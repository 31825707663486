import axios from 'axios';
import { MOCK_TOKEN, alphastack_web_backend_host } from './constants';

export const getAxios = () => {
  const __axios = axios.create({
    baseURL:
      process.env.NODE_ENV === 'production'
        ? '/' + alphastack_web_backend_host + '/'
        : '/',
  });
  // Add a request interceptor
  __axios.interceptors.request.use(
    function (config) {
      config.headers.Authorization = `Bearer ${MOCK_TOKEN}`;
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      console.log(error);
      return Promise.reject(error);
    }
  );
  return __axios;
};
