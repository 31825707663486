import { ClockIcon } from '@heroicons/react/outline';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { getNewsData } from 'Helper/services';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Flex, Text } from 'Utilities';

export default function TopNews() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNews = (_page) => {
    setLoading(true);
    getNewsData('All', 'All', _page, '')
      .then((res) => {
        setNews(res?.results);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getNews(1);
  }, []);
  return (
    <>
      {news && (
        <Flex justifyContent="between" className="py-2 md:py-0 md:mx-4">
          <>
            <Text
              size="lg"
              fontWeight="semibold"
              text="Top News"
              className="dark:text-white"
            />
          </>
          <a
            href="news"
            className="flex space-x-2"
            target="_balnk"
            rel="noopener"
          >
            <ExternalLinkIcon className="h-4 w-4 dark:text-dark-textPrimary" />
            <Text
              size="xs"
              fontWeight="medium"
              className="dark:text-dark-textPrimary"
              text="See all news"
            />
          </a>
        </Flex>
      )}
      <div className="flex flex-wrap">
        {news &&
          news.slice(0, 3).map((item, key) => (
            <div key={key} className="py-2 md:p-4 xl:w-1/3 md:w-1/2 w-full">
              <div className="h-full p-6 rounded-lg bg-white border dark:bg-dark-themeCard  dark:border-gray-700 flex flex-col relative overflow-hidden">
                <Text
                  size="md"
                  fontWeight="semibold"
                  text={item.title}
                  className="dark:text-white"
                />
                <Flex space="2" className="mb-4">
                  {item?.currencies &&
                    item?.currencies.slice(0, 3).map((i, k) => (
                      <div className="" key={k}>
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-800 mt-4">
                          <div
                            className="text-gray-100 text-xs hover:underline hover:text-decoration-blue-400 cursor-pointer"
                            onClick={() => setCurrency(i.code)}
                          >
                            {i.code}
                          </div>
                        </span>
                      </div>
                    ))}
                </Flex>
                <div className="flex justify-between mt-auto">
                  <div className="flex">
                    <ExternalLinkIcon className="w-4 text-blue-600  dark:text-blue-700 mr-2" />
                    <a
                      href={`${item.url}`}
                      className="text-blue-600  dark:text-blue-700 text-sm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.domain}
                    </a>
                  </div>
                  <div className="flex">
                    <ClockIcon className="w-4 dark:text-dark-textPrimary mr-1" />
                    <time
                      className="text-sm dark:text-dark-textPrimary"
                      dateTime={moment.utc(item.published_at).fromNow()}
                    >
                      {moment.utc(item.published_at).fromNow()}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
