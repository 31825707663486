/* eslint-disable react/prop-types */
import { FilterIcon as FilterIconSolid, XIcon } from '@heroicons/react/solid';
import { FilterIcon as FilterIconOutline } from '@heroicons/react/outline';
import Modal from 'Utilities/Modal/modal';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

function NumberRangeFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id, Header },
}) {
  const [rangeValue, setRangeValues] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  const handleChange = (val, control) => {
    if (val) {
      switch (control) {
        case 1:
          setRangeValues((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
          break;
        case 2:
          setRangeValues((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
          break;
      }
    }
  };

  const handleApply = () => {
    if (rangeValue.length > 0) {
      setFilter(rangeValue);
      setShowPopup(false);
    }
  };

  const handleClear = () => {
    if (rangeValue.length > 0) {
      setFilter([]);
      setRangeValues([]);
      setShowPopup(false);
    }
  };
  return (
    <>
      <div
        className="flex text-xs space-x-1 font-semibold cursor-pointer"
        onClick={() => setShowPopup(true)}
      >
        {filterValue.length > 0 ? (
          <FilterIconSolid className="w-4 mx-2 text-gray-600 dark:text-gray-300" />
        ) : (
          <FilterIconOutline className="w-4 mx-2 text-gray-600 dark:text-gray-300" />
        )}
      </div>
      <Modal
        disableBackdropClick={false}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        width={'96'}
      >
        <div className="flex flex-row-reverse">
          <XIcon
            className={`w-4 cursor-pointer ${
              theme === 'dark' ? 'text-gray-600' : 'text-gray-700'
            }`}
            onClick={() => setShowPopup(false)}
          />
        </div>
        <div className="pt-2 pb-3 text-xs">Filter {Header}</div>

        <div className="flex flex-col space-y-3">
          <input
            value={rangeValue[0] || ''}
            type="number"
            onChange={(e) => handleChange(e.target.value, 1)}
            placeholder={`Min (${min})`}
            className={`block border p-2 h-10 rounded text-xs w-full focus:outline-none [appearance:textfield] ${
              theme === 'dark'
                ? 'bg-dark-themeBgDark border-gray-700 text-dark-textPrimary'
                : ''
            }`}
          />

          <input
            value={rangeValue[1] || ''}
            type="number"
            onChange={(e) => handleChange(e.target.value, 2)}
            placeholder={`Max (${max})`}
            className={`block border p-2 h-10 rounded text-xs w-full focus:outline-none [appearance:textfield] ${
              theme === 'dark'
                ? 'bg-dark-themeBgDark border-gray-700 text-dark-textPrimary'
                : ''
            }`}
          />
        </div>
        <div className="flex space-x-2 my-2 items-center justify-center">
          <div>
            <button
              type="button"
              className={`items-center px-3 py-2 border  shadow-sm text-xs font-medium rounded-md  ${
                theme === 'dark'
                  ? 'text-dark-textPrimary bg-dark-themeBgDark border-gray-700 hover:bg-gray-700'
                  : 'text-blue-700 border-blue-700 bg-white hover:bg-gray-50'
              }`}
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
          <div>
            <button
              type="button"
              className={`items-center px-3 py-2 border  shadow-sm text-xs font-medium rounded-md  ${
                theme === 'dark'
                  ? 'text-dark-textPrimary bg-dark-themeBgDark border-gray-700 hover:bg-gray-700'
                  : 'text-blue-700 border-blue-700 bg-white hover:bg-gray-50'
              }`}
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default NumberRangeFilter;
