import FeaturesIcon from 'Utilities/Icons/LandingPageIcons/featuresIcon';
import { LCheckIcon } from 'Utilities/icons';
import { AlphastackAppDark } from 'Utilities/images';
import PropTypes, { shape } from 'prop-types';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import {
  datafederation_features,
  features2,
  highlights,
  primaryFeatures,
} from './constants';
import FeatureCard from './featureCard';

Features.propTypes = {
  featureRef: shape({ current: PropTypes.instanceOf(Element) }),
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const STYLES = {
  dark: {
    titleColor:
      'from-featureSectionTitlePrimaryDark via-featureSectionTitleSecondaryDark to-featureSectionTitleTertiaryDark',
    backgroundEven:
      'bg-gradient-to-b from-sectionBgColorPrimaryDark to-sectionBgColorSecondaryDark',
    backgroundOdd:
      'bg-gradient-to-br from-heroBgColorPrimaryDark to-heroBgColorSecondaryDark',
    heroTextGradient:
      'bg-gradient-to-r from-heroTextPrimaryDark via-heroTextSecondaryDark to-heroTextTertiaryDark bg-clip-text text-transparent',
  },
  light: {
    titleColor:
      'from-featureSectionTitlePrimaryLight via-featureSectionTitleSecondaryLight to-featureSectionTitleTertiaryLight',
    backgroundEven:
      'bg-gradient-to-r from-sectionBgColorPrimaryLight to-sectionBgColorSecondaryLight',
    backgroundOdd:
      'bg-gradient-to-b from-sectionBgColorPrimaryLight to-sectionBgColorSecondaryLight',
    heroTextGradient:
      'bg-gradient-to-r from-heroTextPrimaryLight via-heroTextSecondaryLight to-heroTextTertiaryLight bg-clip-text text-transparent',
  },
};

function Features({ featureRef }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  function renderFeatures() {
    return (
      <div
        ref={featureRef}
        // data-aos="fade-up"
        className={`relative bg-white py-16 sm:py-24 lg:py-32 ${STYLES[theme].backgroundEven}`}
      >
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <h3 className="mt-2 text-2xl sm:text-4xl font-thin tracking-tight text-featureSectionCueTitleLight dark:text-featureSectionCueTitleDark">
            The power of many tools in one
          </h3>
          <p
            className={`bg-gradient-to-r ${STYLES[theme].titleColor} bg-clip-text text-transparent text-2xl sm:text-4xl py-2`}
          >
            to turn onChain data into actionable insights
          </p>

          <div className="mt-6 sm:mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {primaryFeatures.map((feature) => (
                <FeatureCard feature={feature} key={feature.slug} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderHighlights() {
    return (
      <div
        className={`${STYLES[theme].backgroundOdd}`}
        // ref={featureRef}
        // data-aos="fade-up"
      >
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
          <div className="flex flex-col justify-center">
            <h2
              className={`text-sm sm:text-base text-center lg:text-left font-semibold bg-gradient-to-r w-full ${STYLES[theme].titleColor} bg-clip-text text-transparent`}
            >
              Everything you need
            </h2>
            <p className="mt-2 text-2xl sm:text-4xl text-center lg:text-left font-bold tracking-tight text-featureDescLight dark:text-featureDescDark">
              All-in-one platform
            </p>
            <p className="mt-4 text-sm sm:text-base text-center lg:text-left text-featureDescLight dark:text-featureDescDark">
              Analyzing on-chain data and finding insights is hard. But with
              AlphaStack it is made simpler, more fun and better than ever!
            </p>
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <dl className="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-3 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8">
              {highlights.map((highlight) => (
                <div key={highlight.name} className="relative pl-12">
                  <dt className="text-lg lg:text-xl font-semibold text-featureDescLight dark:text-featureDescDark text-left">
                    <div className="absolute left-0 top-0 flex h-9 w-9 items-center justify-center rounded-full bg-highlightCircleLight dark:bg-highlightCircleDark">
                      <img src={LCheckIcon} className="w-6" />
                    </div>
                    {highlight.name}
                  </dt>
                  <dd className="text-sm sm:text-base  text-left mt-2 text-featureDescLight dark:text-featureDescDark">
                    {highlight.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    );
  }

  function renderDataFederationFeatures() {
    return (
      <div className={`${STYLES[theme].backgroundEven}`}>
        <div className="mx-auto max-w-2xl py-12 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
          <div className="mx-auto max-w-4xl">
            <h2 className="text-2xl sm:text-4xl tracking-tight text-ctaCueTextLight dark:text-featureDescDark text-center">
              <span className="block">
                A&nbsp;
                <span className="relative">
                  <svg
                    className="absolute right-0 top-5 md:top-7 xl:top-7 w-full"
                    width="336"
                    height="5"
                    viewBox="0 0 336 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M273.228 0C275.345 0 276.218 0.277756 277.527 0.277756C281.455 0.348224 284.015 0.0839145 288.873 0.555556C291.055 0.555556 295.887 0.529861 299.263 0.404961C302.234 0.56771 305.463 0.870518 308.729 0.897012C317.77 0.968925 326.83 0.919721 335.889 0.919721C335.926 1.11275 335.963 1.30955 336 1.50258C331.978 1.56693 327.974 1.66533 323.933 1.68804C284.022 1.91513 244.075 2.0249 204.183 2.38067C162.28 2.75159 120.414 3.31932 78.5476 3.87191C55.7048 4.1747 32.8988 4.62889 10.0744 4.98846C7.91558 5.02252 5.42474 4.98844 3.59805 4.78785C1.95587 4.60996 0 4.16334 0 3.83784C0 3.55019 2.39871 3.05059 3.94863 3.01274C20.721 2.60019 37.4933 2.16494 54.3394 1.93027C71.8498 1.68804 89.4154 1.66534 106.963 1.53287C123.237 1.41175 139.529 1.2982 155.785 1.12788C169.015 0.991629 182.208 0.707753 195.437 0.620701C200.567 0.586637 206.015 0.575283 211.2 0.620701C213.746 0.64341 216 0.620701 218.575 0.439035C218.594 0.389831 219.904 0.291417 220.291 0.336836C225.6 0.374698 230.052 0.0643391 234.351 0.374698C239.127 0.33307 243.153 0.571517 247.636 0.33307C249.164 0.374698 250.975 0.33307 252.655 0.33307C254.684 0.33307 256.696 0.382265 258.744 0.348202C260.478 0.317923 262.729 0.257365 263.892 0.442823C267.491 0.348202 270.109 0.33307 273.191 0H273.228Z"
                      fill="url(#paint0_linear_902_866)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_902_866"
                        x1="-11.8959"
                        y1="-0.771377"
                        x2="-8.7207"
                        y2="37.183"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#476075" />
                        <stop offset="0.463542" stopColor="#3A5B7C" />
                        <stop offset="1" stopColor="#1C5636" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className="font-semibold">
                    single point of access&nbsp;
                  </span>
                </span>
                to query&nbsp;
              </span>
            </h2>
            <h1 className="text-2xl sm:text-4xl tracking-tight text-ctaCueTextLight dark:text-featureDescDark text-center mt-4">
              <span className="relative whitespace-nowrap">
                <svg
                  className="absolute right-0 top-4 md:top-6 xl:top-7 w-full"
                  width="336"
                  height="5"
                  viewBox="0 0 336 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M273.228 0C275.345 0 276.218 0.277756 277.527 0.277756C281.455 0.348224 284.015 0.0839145 288.873 0.555556C291.055 0.555556 295.887 0.529861 299.263 0.404961C302.234 0.56771 305.463 0.870518 308.729 0.897012C317.77 0.968925 326.83 0.919721 335.889 0.919721C335.926 1.11275 335.963 1.30955 336 1.50258C331.978 1.56693 327.974 1.66533 323.933 1.68804C284.022 1.91513 244.075 2.0249 204.183 2.38067C162.28 2.75159 120.414 3.31932 78.5476 3.87191C55.7048 4.1747 32.8988 4.62889 10.0744 4.98846C7.91558 5.02252 5.42474 4.98844 3.59805 4.78785C1.95587 4.60996 0 4.16334 0 3.83784C0 3.55019 2.39871 3.05059 3.94863 3.01274C20.721 2.60019 37.4933 2.16494 54.3394 1.93027C71.8498 1.68804 89.4154 1.66534 106.963 1.53287C123.237 1.41175 139.529 1.2982 155.785 1.12788C169.015 0.991629 182.208 0.707753 195.437 0.620701C200.567 0.586637 206.015 0.575283 211.2 0.620701C213.746 0.64341 216 0.620701 218.575 0.439035C218.594 0.389831 219.904 0.291417 220.291 0.336836C225.6 0.374698 230.052 0.0643391 234.351 0.374698C239.127 0.33307 243.153 0.571517 247.636 0.33307C249.164 0.374698 250.975 0.33307 252.655 0.33307C254.684 0.33307 256.696 0.382265 258.744 0.348202C260.478 0.317923 262.729 0.257365 263.892 0.442823C267.491 0.348202 270.109 0.33307 273.191 0H273.228Z"
                    fill="url(#paint0_linear_902_866)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_902_866"
                      x1="-11.8959"
                      y1="-0.771377"
                      x2="-8.7207"
                      y2="37.183"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#476075" />
                      <stop offset="0.463542" stopColor="#3A5B7C" />
                      <stop offset="1" stopColor="#1C5636" />
                    </linearGradient>
                  </defs>
                </svg>
                <span className="font-semibold">blockchain data&nbsp;</span>
              </span>
              and all your data sources
            </h1>
            <p className="mt-4 text-gray-400 text-center">
              Alphastack gives you the flexibility to run federated interactive
              queries using a single query engine, to query across
              alphastack-provisioned blockchain data and all your data sources
              (on prem and in the cloud) as if they all live on the same single
              database instance.
            </p>
          </div>

          <div className="mt-12 space-y-16">
            {datafederation_features.map((feature, featureIdx) => (
              <div
                key={feature.name}
                className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
              >
                <div
                  className={classNames(
                    featureIdx % 2 === 0
                      ? 'lg:col-start-1'
                      : 'lg:col-start-8 xl:col-start-9',
                    'mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4'
                  )}
                >
                  <div className="relative pl-16">
                    <div className="sm:text-3xl font-bold text-featureTitleLight dark:text-cueTextDark">
                      <div className="absolute left-0 top-0 flex h-12 w-12 items-center justify-center rounded-full bg-iconHighLightLight dark:bg-iconHighLightDark">
                        <FeaturesIcon
                          featureId={feature.slug}
                          className={'h-6 w-6'}
                        />
                      </div>
                      {feature.name}
                    </div>
                    <p className="py-2 text-md text-featureDescLight dark:text-featureDescDark">
                      {feature.description}
                    </p>
                  </div>
                </div>
                <div
                  className={classNames(
                    featureIdx % 2 === 0
                      ? 'lg:col-start-6 xl:col-start-5'
                      : 'lg:col-start-1',
                    'flex-auto lg:row-start-1 lg:col-span-7 xl:col-span-8'
                  )}
                >
                  <div className="aspect-w-5 aspect-h-2 overflow-hidden rounded-lg">
                    <img
                      src={feature.imageSrc}
                      className="object-cover object-center rounded-lg"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  function renderAppOverview() {
    return (
      <div
        className={`relative overflow-hidden pt-16 sm:pt-24 lg:pt-32  ${STYLES[theme].backgroundOdd}`}
      >
        <div className="flex flex-col justify-center items-center mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div>
            <h2 className="text-xl sm:text-3xl font-semibold dark:text-featureSectionCueTitleDark">
              Powerfully simple.
            </h2>
            <p
              className={`mt-2 text-3xl sm:text-5xl font-extrabold tracking-tight ${STYLES[theme].heroTextGradient}`}
            >
              No Technical Skills? No problem.
            </p>
            <p className=" mt-5 max-w-prose text-base sm:text-lg dark:text-featureDescDark text-center">
              Use the easy-to-understand prebuilt analytics right out of the box
              with simple button clicks, or build a variety of insights using
              the builder tool.
            </p>
          </div>
          <div className="mt-12 mb-24">
            <img
              src={AlphastackAppDark}
              className="rounded-lg ring-black ring-opacity-5 w-screen"
            />
          </div>
        </div>
      </div>
    );
  }

  function renderSecondaryFeatures() {
    return (
      <div
        className={` ${
          theme === 'dark'
            ? 'bg-gradient-to-b from-sectionBgColorPrimaryDark to-sectionBgColorSecondaryDark'
            : 'bg-white'
        }`}
      >
        <div className="relative py-16 sm:py-24 lg:py-32">
          <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="mt-12">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {features2.map((feature) => (
                  <div
                    key={feature.slug}
                    className="flow-root rounded-lg bg-cardBgLight dark:bg-cardBgDark px-6 pb-8"
                  >
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-full p-3 shadow-lg  bg-iconHighLightLight dark:bg-iconHighLightDark">
                          <FeaturesIcon featureId={feature.slug} />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold tracking-tight text-featureTitleLight dark:text-cueTextDark">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500 dark:text-featureDescDark">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div ref={featureRef}>{renderFeatures()}</div>
      {renderHighlights()}
      {renderDataFederationFeatures()}
      {renderAppOverview()}
      {renderSecondaryFeatures()}
    </div>
  );
}

export default Features;
