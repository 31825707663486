/* eslint-disable react/prop-types */
/* This example requires Tailwind CSS v2.0+ */
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import { Fragment, useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SelectTokenExplore({ optionsObj, state, setState }) {
  const [selected, setSelected] = useState('All');
  const handleSelection = () => {
    optionsObj.map((item) => {
      item.value == state && setSelected(item.text);
    });
  };
  useEffect(() => {
    handleSelection();
  }, [state]);
  return (
    <Listbox className="z-10 relative" value={state} onChange={setState}>
      {({ open }) => (
        <>
          {/* <Tippy content={''} placement="bottom"> */}
          <div className="absolute h-auto">
            <Listbox.Button className="relative bg-transparent border border-gray-300 dark:border-gray-700 rounded-md shadow-sm pl-3 pr-10 py-3 font-semibold text-left cursor-pointer text-xs dark:bg-dark-themeBgDark dark:text-dark-textPrimary h-10">
              <span className="block truncate">{selected}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-4 w-4 text-gray-400 dark:text-dark-textPrimary"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-15 mt-1 bg-white shadow-lg border dark:border-gray-700 rounded-md py-1 text-xs ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none  dark:bg-dark-themeBgDark ">
                {optionsObj.map((filter) => (
                  <Listbox.Option
                    key={filter.text}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'text-white bg-blue-600 dark:bg-dark-bgMutedDark'
                          : 'text-gray-900 dark:text-dark-textPrimary',
                        'cursor-pointer select-none relative py-3 pl-8 pr-4 dark:text-dark-textPrimary font-bold'
                      )
                    }
                    value={filter.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-bold',
                            'block truncate font-bold'
                          )}
                        >
                          {filter.text}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active
                                ? 'text-white'
                                : 'text-blue-600 dark:text-gray-700',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {/* </Tippy> */}
        </>
      )}
    </Listbox>
  );
}
