/* eslint-disable react/prop-types */
import * as am4core from '@amcharts/amcharts4/core';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

am4core.useTheme(am4themes_animated);
//am4core.unuseTheme(am4themes_animated);

// eslint-disable-next-line react/prop-types
export default function ForceDirectedChart({ data, sortCol = 'volume' }) {
  const [chart, setChart] = useState();
  // const [state, setState] = useState(false);
  const chartRef = useRef(null);
  // const drawChart = () => {};
  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);
    //am4core.unuseTheme(am4themes_animated);
    var chart = am4core.create(
      'chartdiv',
      am4plugins_forceDirected.ForceDirectedTree
    );

    var series = chart.series.push(
      new am4plugins_forceDirected.ForceDirectedSeries()
    );

    series.events.on('inited', function () {
      series.animate(
        {
          property: 'velocityDecay',
          to: 1,
        },
        5000
      );
    });

    series.dataFields.downDepth = 1;
    series.dataFields.initialDepth = 1;
    series.dataFields.singleBranchOnly = true;

    series.nodes.template.outerCircle.filters.push(
      new am4core.DropShadowFilter()
    );

    // Add labels
    series.dataFields.value = `${sortCol}`;
    series.dataFields.name = 'name';
    series.dataFields.id = 'id';
    series.dataFields.children = 'children';
    series.dataFields.linkWith = 'link';
    // Add labels
    series.tooltip.label.interactionsEnabled = true;
    series.nodes.template.label.text = '{symbol}';
    series.nodes.template.showTooltipOn = 'hit';
    series.nodes.template.tooltipHTML =
      "<div><a target='_blank' style=\"color=blue;\" href='https://etherscan.io/token/{token_contract}'>{cg_id} ({symbol})<br/>\ntx_count: {tx_count}<br/>\nvolume: {volume}<br/>\nvolume_usd: {volume_usd}</a></div>";

    series.minRadius = am4core.percent(5);
    series.maxRadius = am4core.percent(10);
    series.dataFields.linkWith = 'linkWith';
    series.data = data;

    /*
    series.fontSize = 10;
    series.nodes.template.fillOpacity = 1;
    series.manyBodyStrength = -20;
    series.links.template.strength = 0.8;
    */

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data]);
  return (
    <div className="flex dark:border-gray-700 justify-center h-screen">
      <div
        id="chartdiv"
        className="w-full"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}
