/* eslint-disable react/prop-types */
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import { getLastIndexed } from 'Helper/services';
import { useLocalStorage } from 'Hooks';
import { MenuWrapper } from 'Utilities';
import DataLastIndexed from 'Utilities/DataLastIndexed/DataLastIndexed';
import Refresh from 'Utilities/Refresh/buttonRefresh';
import Search from 'Utilities/Search/search';
import SelectOption from 'Utilities/SelectOption/select';
import Table from 'Utilities/Table/table';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestDexTrades } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import {
  token_group,
  token_vol_limit,
  trade_type_options,
} from './Helper/constants';
import { useNavigate } from 'react-router-dom';
import { column } from './header';

function DexTrades() {
  const addressFromParam = getUrlParam('address');
  const chain = getUrlParam('chain');
  const groupFromParam = getUrlParam('group');
  const limitFromParam = getUrlParam('limit');
  const typeFromParam = getUrlParam('type');
  const [refresh, setRefresh] = useState(false);
  const [group, setGroup] = useLocalStorage('td_group', 'all');
  const [volLimit, setVolLimit] = useLocalStorage('td_vol_limit', '1');
  const [address, setAddress] = useState(addressFromParam || '');
  const [tradeType, setTradeType] = useState('dex');
  let addressType;

  const [lastIndexed, setLastIndexed] = useState('');
  const block_chain = CHAINS[getUrlParam('chain')];
  const navigate = useNavigate();
  const basePath = '';

  // react select `onChange` event handling start
  const handleChange = (selectedInput) => {
    const selectType = [];
    for (let i in selectedInput) {
      selectType.push(selectedInput[i]['value']);
    }
    setTradeType(selectType);
  };
  // useDispatch() instance
  const dispatch = useDispatch();
  const processDexTrades = (dextrades) => {
    let dataSet = [];
    if (dextrades?.data && dextrades?.data?.length > 0) {
      for (let key of dextrades?.data) {
        dataSet.push({
          transaction_hash: key['f'][0]['v'],
          name: key['f'][1]['v'],
          contract_address: key['f'][2]['v'],
          dex: key['f'][3]['v'],
          trade_action: key['f'][4]['v'],
          trade_type: key['f'][5]['v'],
          token_amount: key['f'][6]['v'],
          token_value_usd: key['f'][7]['v'],
          timestamp_utc: key['f'][8]['v'],
          token_value_usd_txdate: key['f'][9]['v'],
          using_ethvalue_current: key['f'][10]['v'],
          using_ethvalue_txdate: key['f'][11]['v'],
          trader_address: key['f'][12]['v'],
        });
      }
    }
    return dataSet;
  };
  // listening to store changes
  const { dexTrades, loading } = useSelector((s = initialState) => {
    return {
      dexTrades: processDexTrades(s.latestDexTrades.dexTrades?.data),
      loading: s.latestDexTrades.loading,
      // block_chain: s.currentBlockChain.selectedBlockChain,
    };
  });
  const validateURLParams = () => {
    const urlLimitBoolean = token_vol_limit?.some(
      (item) => item.value === limitFromParam
    );
    if (urlLimitBoolean) setVolLimit(limitFromParam);

    const urlGroupBoolean = token_group?.some(
      (item) => item.value === groupFromParam
    );
    if (urlGroupBoolean) setGroup(groupFromParam);
    if (addressFromParam) setAddress(addressFromParam);

    const urlTypeBoolean = trade_type_options?.some(
      (item) => item.value === typeFromParam
    );
    if (urlTypeBoolean) setTradeType(typeFromParam);
  };

  useEffect(() => {
    validateURLParams();
  }, [validateURLParams]);
  const getData = () => {
    if (address.toLocaleLowerCase().startsWith('0x')) {
      if (address.length == 42) {
        addressType = 'WALLET_ADDRESS';
      }
      if (address.length > 42 && address.length <= 66) {
        addressType = 'TX_HASH';
      }
    }
    console.log('address', address);
    // FIXME: ---Please add the notification to end User, If give data not startWith "ox".
    dispatch(
      getLatestDexTrades(
        block_chain,
        group,
        volLimit,
        address.toLocaleLowerCase(),
        tradeType,
        addressType
      )
    );
  };

  useEffect(async () => {
    getData();
    const res = await getLastIndexed(block_chain, 'latest-trades');
    setLastIndexed(res?.data?.metadata?.data_last_indexed_timestamp_utc);
    navigate(
      `${basePath}?chain=${chain}&tab=LatestTrades&limit=${volLimit}&group=${group}&type=${tradeType}${
        address && `&address=${address.toLocaleLowerCase()}`
      }`
    );
  }, [block_chain, refresh, group, volLimit, tradeType, address]);
  const placeholder = 'Trader Wallet Address (or) TX Hash';
  const props = { address, setAddress, getData, placeholder };
  return (
    <>
      <div className="dark:bg-dark-themePrimary bg-light-themeSecondary p-4 rounded-tr-md">
        <div className="hidden sm:block">
          <div className="flex justify-between">
            <div className="flex flex-wrap space-x-1 mb-2 lg:mt-2">
              <SelectOption
                optionsObj={token_group}
                state={group}
                setState={setGroup}
              />
              <SelectOption
                optionsObj={token_vol_limit}
                state={volLimit}
                setState={setVolLimit}
              />
              <SelectOption
                optionsObj={trade_type_options}
                state={tradeType}
                setState={setTradeType}
              />
              <Search {...props} />
            </div>
            <div className="mx-2">
              <div className="flex items-center">
                <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
                <div className="ml-4">
                  <Refresh
                    state={refresh}
                    setState={setRefresh}
                    loadingbutton={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* filters, sort mobile view */}
        <div className="sm:hidden block">
          <div className="flex flex-row-reverse">
            <MenuWrapper>
              <div className="flex flex-wrap  border-b dark:border-gray-700 gap-1.5">
                <div>
                  <SelectOption
                    optionsObj={token_group}
                    state={group}
                    setState={setGroup}
                  />
                </div>
                <div>
                  <SelectOption
                    optionsObj={token_vol_limit}
                    state={volLimit}
                    setState={setVolLimit}
                  />
                </div>
                <div className="mt-2">
                  <SelectOption
                    optionsObj={trade_type_options}
                    state={tradeType}
                    setState={setTradeType}
                  />
                </div>
              </div>
            </MenuWrapper>
            <div className="mx-2">
              <Refresh
                state={refresh}
                setState={setRefresh}
                loadingbutton={loading}
              />
            </div>
            <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
          </div>
          <div className="mt-4">
            <Search {...props} />
          </div>
        </div>
        <div className="pt-2">
          <Table data={dexTrades} col={column} isloading={loading} />
        </div>
      </div>
    </>
  );
}

export default DexTrades;
