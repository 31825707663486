/* eslint-disable react/prop-types */
import { NavLink } from 'react-router-dom';
import Icon from 'Utilities/Icons/icon';

function OverviewCard({ title, url, desc, img, idx, icon }) {
  return (
    <div className="group relative rounded-xl border border-gray-200 dark:border-gray-800 transform ease-in-out hover:-translate-y-1 hover:scale-100 duration-100 ">
      <div className="absolute -inset-px rounded-xl border-2 border-transparent opacity-0 overview-card group-hover:opacity-100" />
      <div className="relative overflow-hidden rounded-xl p-6">
        {icon && <Icon Name={icon} />}
        <h2 className="mt-4 font-medium text-base text-gray-900 dark:text-white">
          <NavLink to={url}>
            <span className="absolute -inset-px rounded-xl" />
            {title}
          </NavLink>
        </h2>
        <p className="mt-1 text-sm text-gray-700 dark:text-gray-400 leading-relaxed">
          {desc}
        </p>
      </div>
    </div>
  );
}

export default OverviewCard;
