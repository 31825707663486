import { ArrowDownIcon } from '@heroicons/react/outline';
import Tippy from '@tippyjs/react';
import { format } from 'date-fns';
import { getTradingSession } from 'Helper/services';
import React, { useEffect, useState } from 'react';
import UTCClock from 'Utilities/UTCClock/utcClock';

const sessionObj = [
  {
    id: 1,
    session_name: 'AS',
    sessions: [0, 1, 2, 3, 4, 5, 6, 7, 9, 23],
  },
  {
    id: 2,
    session_name: 'ES',
    sessions: [7, 8, 9, 10, 11, 12, 13, 14, 15],
  },
  {
    id: 3,
    session_name: 'NAS',
    sessions: [13, 14, 15, 16, 17, 18],
  },
];

export default function TradingSession() {
  const [asNS, setAsNs] = useState('');
  const [esNS, setEsNs] = useState('');
  const [nasNS, setNasNs] = useState('');
  const [showIndicator, setShowIndicator] = useState(true);
  const [utcHour, setUtcHour] = useState(new Date().getUTCHours());
  const [asiaOpen, setAsiaOpen] = useState();
  const [europeOpen, setEuropeOpen] = useState();
  const [usOpen, setUsOpen] = useState();

  const weekends = ['Saturday', 'Sunday'];
  // const weekends = ['Wed', 'Thu'];
  const dayStr = format(new Date(), 'EEEE');
  const isWeekend = weekends.includes(dayStr) ? true : false;
  let hourBase = dayStr == 'Saturday' ? 48 : 24;
  // const utcHour = 22;
  let as = [0, 1, 2, 3, 4, 5, 6, 7, 23];
  let es = [7, 8, 9, 10, 11, 12, 13, 14, 15];
  let nas = [13, 14, 15, 16, 17, 18, 19, 20];
  let noSessionArray = [19, 20, 21, 22];
  const isoDate = new Date().toISOString();
  let dateStr = isoDate.substr(0, 10);
  let hoursAr = [];
  for (var i = 0; i < 25; i++) {
    hoursAr.push(i);
  }
  // to find time difference
  const getDiff24r = (s, e) => (s > e ? Math.abs(s - 24 - e) : e - s);

  const getTradingSessions = async () => {
    await getTradingSession('HK')
      .then((result) => {
        setAsiaOpen(result.isOpen);
        if (result.isOpen == false) {
          if (isWeekend) {
            let asiaDiff = hourBase - utcHour;
            setAsNs(asiaDiff);
          } else if (as.includes(utcHour) == false) {
            let asiaDiff = 23 - utcHour;
            setAsNs(asiaDiff);
          } else {
            let asinanExchangeHolidays = result.ExchangeHolidays;
            console.log(Object.keys(asinanExchangeHolidays));
            if (Object.keys(asinanExchangeHolidays).length > 0) {
              console.log(true);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await getTradingSession('LSE')
      .then((result) => {
        setEuropeOpen(result.isOpen);
        let open = false;
        if (open == false) {
          if (isWeekend) {
            let europeDiff = hourBase - utcHour + 7;
            setEsNs(europeDiff);
          } else if (es.includes(utcHour) == false) {
            if (utcHour < 7) {
              let europeDiff = 7 - utcHour;
              setEsNs(europeDiff);
            }
            if (utcHour > 15) {
              let europeDiff = getDiff24r(utcHour, 7);
              setEsNs(europeDiff);
            }
          } else {
            let europeanExchangeHolidays = result.ExchangeHolidays;
            if (Object.keys(europeanExchangeHolidays).length > 0) {
              let date = Object.entries(europeanExchangeHolidays).map(
                ([key, value]) => {
                  // returning all date which are = current month and >=  today
                  if (
                    new Date().getUTCMonth() ==
                      new Date(value.Date).getUTCMonth() &&
                    new Date().getUTCDate() <= new Date(value.Date).getUTCDate()
                  ) {
                    return value.Date;
                  }
                }
              );
              // removing all undefined values from the array
              const upComingDates = date.filter((element) => {
                return element !== undefined;
              });
              if (upComingDates.length > 0) {
                // if only one date is available in array
                if (upComingDates.length == 1) {
                  if (upComingDates[0] == dateStr) {
                    if (utcHour < 7) {
                      let europeDiff = 7 - utcHour + 48;
                      setEsNs(europeDiff);
                    }
                    if (utcHour > 15) {
                      let europeDiff = getDiff24r(utcHour, 7) + 48;
                      setEsNs(europeDiff);
                    }
                  }
                } else {
                  let _udArray = [];
                  upComingDates.map((element) => {
                    let _d = new Date(element).getDate();
                    _udArray.push(_d);
                  });
                  console.log(_udArray);
                }
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await getTradingSession('US')
      .then((result) => {
        setUsOpen(result.isOpen);
        if (result.isOpen == false) {
          if (isWeekend) {
            let americanDiff = hourBase - utcHour;
            setNasNs(americanDiff);
          } else if (nas.includes(utcHour) == false) {
            if (utcHour < 13) {
              let americanDiff = 13 - utcHour;
              setNasNs(americanDiff);
            }
            if (utcHour > 20) {
              let americanDiff = getDiff24r(utcHour, 13);
              setNasNs(americanDiff);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTradingSessions();
  }, []);

  return (
    <div className="">
      <div className="bg-white dark:bg-dark-themeBgDark p-2 rounded mt-4 hidden sm:block">
        <div className="font-semibold mb-2 dark:text-white border-t dark:border-gray-700 p-1">
          Trading Session
        </div>
        <table className="shadow-lg bg-white dark:bg-dark-themeCard border-separate p-2 border dark:border-gray-700 rounded w-full">
          <tbody>
            <tr className="text-center">
              <th className="bg-blue-100 dark:bg-dark-themeBgDark border dark:border-gray-700 rounded dark:text-white text-left text-xs">
                Session
              </th>
              {hoursAr.slice(0, 24).map((d, k) => (
                <th
                  key={k}
                  className="relative bg-blue-100 border dark:border-gray-700 text-center text-xs dark:bg-dark-themeBgDark rounded dark:text-white"
                >
                  {d}
                  {showIndicator
                    ? utcHour == d && (
                        <>
                          <Tippy
                            content={
                              <div className="text-xs inline-flex">
                                <span className="text-xs mr-1">
                                  Current UTC Time:
                                </span>{' '}
                                <UTCClock />
                              </div>
                            }
                            placement="bottom"
                          >
                            <div>
                              <span
                                className={`absolute inline-flex items-center px-1 py-1 rounded-full text-xs font-medium ${
                                  noSessionArray.includes(utcHour)
                                    ? 'bg-gray-500'
                                    : 'bg-green-500'
                                } border-4 border-solid  text-gray-800 -top-3 right-1 animate-pulse shadow border-blue box-content`}
                              ></span>
                            </div>
                          </Tippy>

                          <span
                            className={`absolute w-24 text-xs text-yellow-600 -top-11 -right-4`}
                          >
                            You&apos;re here now
                            <ArrowDownIcon className="w-3 mx-auto font-bold" />
                          </span>
                        </>
                      )
                    : null}
                </th>
              ))}
              <th className="relative bg-blue-100 border dark:border-gray-700 text-center text-xs dark:bg-dark-themeBgDark rounded dark:text-white">
                Next Session (Hrs)
              </th>
            </tr>
            <tr>
              <td className="border px-2 py-1 text-xs dark:border-gray-700 dark:text-white rounded">
                Asia
              </td>
              {hoursAr.slice(0, 24).map((a, k) => (
                <td
                  key={k}
                  className={`border px-2.5 py-1 dark:border-gray-700 ${
                    as.includes(a) && 'bg-purple-300 rounded'
                  }`}
                ></td>
              ))}
              <td className="border px-2.5 py-1 dark:border-gray-700 text-xs dark:text-white">
                {asiaOpen ? (
                  <div className="flex">
                    <p>Live</p>
                    <span className="inline-flex items-center px-1 py-1 rounded-full text-xs font-medium bg-red-500 border-4 border-solid  text-gray-800 -top-3 right-1 animate-pulse shadow border-blue box-content"></span>
                  </div>
                ) : (
                  asNS
                )}
              </td>
            </tr>
            <tr>
              <td className="border px-2.5 py-1 dark:border-gray-700 text-xs dark:text-white rounded">
                Europe
              </td>
              {hoursAr.slice(0, 24).map((e, k) => (
                <td
                  key={k}
                  className={`border px-2.5 py-1 dark:border-gray-700 ${
                    es.includes(e) && 'bg-yellow-300 rounded'
                  }`}
                ></td>
              ))}
              <td className="border px-2.5 py-1 dark:border-gray-700 text-xs dark:text-white">
                {europeOpen ? (
                  <div className="flex">
                    <p>Live</p>
                    <span className="inline-flex items-center px-1 py-1 rounded-full text-xs font-medium bg-red-500 border-4 border-solid  text-gray-800 -top-3 right-1 animate-pulse shadow border-blue box-content"></span>
                  </div>
                ) : (
                  esNS
                )}
              </td>
            </tr>
            <tr>
              <td className="border px-2.5 py-1 dark:border-gray-700 text-xs dark:text-white rounded">
                America
              </td>
              {hoursAr.slice(0, 24).map((na, k) => (
                <td
                  key={k}
                  className={`border px-2.5 py-1 dark:border-gray-700 ${
                    nas.includes(na) && 'bg-blue-300 rounded'
                  }`}
                ></td>
              ))}
              <td className="border px-2.5 py-1 dark:border-gray-700 text-xs dark:text-white">
                {usOpen ? (
                  <div className="flex">
                    <p>Live</p>
                    <span className="inline-flex items-center px-1 py-1 rounded-full text-xs font-medium bg-red-500 border-4 border-solid  text-gray-800 -top-3 right-1 animate-pulse shadow border-blue box-content"></span>
                  </div>
                ) : (
                  nasNS
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Mobile view */}
      <div className="bg-white dark:bg-dark-themeBgDark shadow overflow-hidden sm:rounded-md mt-6 sm:hidden">
        <div className="relative rounded border border-gray-300 dark:border-gray-700 bg-white dark:bg-dark-themeBgDark px-6 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
          <div className="flex-1 min-w-0">
            <a href="#" className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-lg  text-gray-900 dark:text-white font-semibold">
                Trading Session
              </p>
              <p className="flex text-xs text-gray-500 dark:text-dark-textPrimary">
                <span className="text-xs mr-1">Current UTC Time:</span>{' '}
                <UTCClock /> (UTC)
              </p>
            </a>
          </div>
        </div>
        <ul
          role="list"
          className="divide-y divide-gray-200 dark:divide-gray-700"
        >
          <li>
            <a href="#" className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-md font-semibold dark:text-dark-textPrimary truncate">
                    Asia
                  </p>
                  <div className="ml-2 flex-shrink-0 flex">
                    {asNS ? (
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                        Closed
                      </p>
                    ) : (
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Live
                      </p>
                    )}
                  </div>
                </div>
                <div className="text-sm dark:text-dark-textPrimary">
                  {asNS && `Next Session in : ${asNS} Hrs`}
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#" className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-md font-semibold dark:text-dark-textPrimary truncate">
                    Europe
                  </p>
                  <div className="ml-2 flex-shrink-0 flex">
                    {esNS ? (
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                        Closed
                      </p>
                    ) : (
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Live
                      </p>
                    )}
                  </div>
                </div>
                <div className="text-sm dark:text-dark-textPrimary">
                  {esNS && `Next Session in : ${esNS} Hrs`}
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#" className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-md font-semibold dark:text-dark-textPrimary truncate">
                    America
                  </p>
                  <div className="ml-2 flex-shrink-0 flex">
                    {nasNS ? (
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                        Closed
                      </p>
                    ) : (
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Live
                      </p>
                    )}
                  </div>
                </div>
                <div className="text-sm dark:text-dark-textPrimary">
                  {nasNS && `Next Session in : ${nasNS} Hrs`}
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      {/* Mobile view */}
    </div>
  );
}
