/* eslint-disable react/prop-types */
import useQuery from 'Hooks/useQuery';
import { useContext, useState } from 'react';

import { AppContext } from 'Providers/appProvider';
import { Outlet, useLocation, useMatch } from 'react-router-dom';
import AlphaLeadTab from './alphaLeadsTab';
import LeaderBoard from './LeaderBoard/leaderBoard';
import UserLeads from './UserLeads/userLeads';
import ViewAlphaLeads from './ViewAlphaLeads/viewAlphaLeads';

export default function AlphaLeadsHome({ match, userId, children }) {
  let query = useQuery();
  const tabOptions = [
    {
      id: '1',
      component: ViewAlphaLeads,
      name: 'Alpha Leads',
      route: 'lead-list',
      tooltip: 'Alpha leads by you',
    },
    {
      id: '2',
      component: LeaderBoard,
      name: 'Leaderboard',
      route: 'leaderboard',
      tooltip: 'Leader board',
    },
    {
      id: '3',
      component: UserLeads,
      name: 'Alpha Leads by user',
      route: 'user-leads',
      tooltip: 'Alpha leads by users',
    },
  ];
  const [alphaLeadsTab, setalphaLeadsTab] = useState(String(query.get('t')));
  const { pathname } = useLocation();
  console.log(alphaLeadsTab);
  const props = {
    tabOptions,
    state: alphaLeadsTab,
    setState: setalphaLeadsTab,
    basePath: pathname,
    search: {},
  };

  const { setIsLeaksSliderOver, setOpenSliderOver } = useContext(AppContext);

  return (
    <div className="p-4">
      <div className="flex sm:flex-row-reverse mb-2 sm:mb-0">
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-700 px-4 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => {
            setOpenSliderOver('0');
            setIsLeaksSliderOver(true);
          }}
        >
          Submit Alpha Leads
        </button>
      </div>
      <div className="flex flex-col ">
        <div className="mt-2">
          <div className="flex flex-col lg:flex-row justify-between">
            <AlphaLeadTab {...props} />
          </div>
          <div className="lg:border-b dark:border-dark-tabPillBgSelectedDark" />
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
