import { Menu, Transition } from '@headlessui/react';
import { FilterIcon, RefreshIcon } from '@heroicons/react/outline';
import { useLocalStorage } from 'Hooks';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNftLatestDexTrades } from 'statemgmt/actions';
import { ContentWrapper } from 'Utilities';
import Refresh from 'Utilities/Refresh/buttonRefresh';
import Search from 'Utilities/Search/search';
import SelectOption from 'Utilities/SelectOption/select';
import Table from 'Utilities/Table/table';
import { token_group, token_vol_limit } from './Helper/constants';
import { column } from './Helper/header';
import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';

function LatestTrades() {
  const [refresh, setRefresh] = useState(false);
  const [group, setGroup] = useLocalStorage('nftlt_group', 'all');
  const [volLimit, setVolLimit] = useLocalStorage('nftlt_group_vol_limit', '1');
  const [address, setAddress] = useState('');

  let addressType;

  // useDispatch() instance
  const dispatch = useDispatch();
  const processDexTrades = (dextrades) => {
    let dataSet = [];
    if (dextrades.data && dextrades.data.length > 0) {
      for (let key of dextrades.data) {
        dataSet.push({
          transaction_hash: key['f'][0]['v'],
          name: key['f'][1]['v'],
          contract_address: key['f'][2]['v'],
          token_id: key['f'][3]['v'],
          dex: key['f'][4]['v'],
          trade_action: key['f'][5]['v'],
          token_amount: key['f'][6]['v'],
          token_value_usd: key['f'][7]['v'],
          timestamp_utc: key['f'][8]['v'],
          token_value_usd_txdate: key['f'][9]['v'],
          eth_value: key['f'][10]['v'],
          trader_address: key['f'][11]['v'],
          trade_type: key['f'][12]['v'],
        });
      }
    }
    return dataSet;
  };
  const block_chain = CHAINS[getUrlParam('chain')];

  // listening to store changes
  const { nftTopTrades, loading } = useSelector((s = initialState) => {
    return {
      nftTopTrades: processDexTrades(s.nftLatestTradesData.nftLatestTrades),
      loading: s.nftLatestTradesData.loading,
      // block_chain: s.currentBlockChain.selectedBlockChain,
    };
  });
  const getData = () => {
    if (address.toLocaleLowerCase().startsWith('0x')) {
      if (address.length == 42) {
        addressType = 'WALLET_ADDRESS';
      }
      if (address.length > 42 && address.length <= 66) {
        addressType = 'TX_HASH';
      }
    }
    // FIXME: ---Please add the notification to end User, If give data not startWith "ox".
    dispatch(
      getNftLatestDexTrades(
        block_chain,
        group,
        volLimit,
        address.toLocaleLowerCase(),
        addressType
      )
    );
  };

  // useeffect hook start
  useEffect(() => {
    getData();
  }, [block_chain, refresh, group, volLimit]);

  // useeffect hook end

  // props object passed to search component
  const placeholder = 'Trader Wallet Address (or) TX Hash';
  const props = { address, setAddress, getData, placeholder };

  if (block_chain == 1) {
    return (
      <>
        <ContentWrapper>
          <div className="flex justify-center items-center h-96">
            <h1 className="text-2xl sm:text-4xl lg:text-5xl text-gray-500 dark:text-gray-700 font-mono italic">
              Coming Soon...
            </h1>
          </div>
        </ContentWrapper>
      </>
    );
  }
  return (
    <>
      <div className="dark:bg-dark-themePrimary bg-light-themeSecondary mx-4 px-4 rounded-tr-md">
        <div className="hidden sm:block">
          <div className="flow-root ">
            <div className="flex justify-between">
              <div className="flex flex-wrap space-x-1 mb-2 lg:mt-2">
                {/* <div className="bg-gray-100  rounded-md flex items-center p-3 dark:bg-gray-700 border-gray-700 text-dark-textPrimary">
                  <FilterIcon className="h-5 w-5" />
                </div> */}
                <div>
                  <SelectOption
                    optionsObj={token_group}
                    state={group}
                    setState={setGroup}
                  />
                </div>
                <div>
                  <SelectOption
                    optionsObj={token_vol_limit}
                    state={volLimit}
                    setState={setVolLimit}
                  />
                </div>

                <div className="flex">
                  <Search {...props} />
                </div>
              </div>
              <div className="mt-2">
                <div className="flex justify-between ">
                  {/* <button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                    <span className="sr-only">refresh Option</span>
                    <RefreshIcon
                      className={`w-4 h-4 text-gray-400 cursor-pointer mouse-pointer `}
                      onClick={() => {
                        setRefresh(!refresh);
                      }}
                    />
                  </button> */}
                  <Refresh
                    state={refresh}
                    setState={setRefresh}
                    loadingbutton={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* filters, sort mobile view */}
        <div className="sm:hidden block">
          <div className="flex flex-row-reverse">
            <div>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                    <span className="sr-only">Open options</span>
                    <FilterIcon className="h-4 w-4" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-dark-bgMutedDark ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1 px-2">
                      <div className="flow-root mt-2">
                        <div className="flex">
                          <div className="flex flex-wrap mb-2 flex-row lg:mt-2">
                            <div>
                              <SelectOption
                                optionsObj={token_group}
                                state={group}
                                setState={setGroup}
                              />
                            </div>
                            <div>
                              <SelectOption
                                optionsObj={token_vol_limit}
                                state={volLimit}
                                setState={setVolLimit}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="mx-2">
              <button className="bg-gray-100  rounded-md flex items-center px-2 py-2 dark:bg-gray-700 dark:border-gray-700 dark:text-dark-textPrimary">
                <span className="sr-only">refresh Option</span>
                <RefreshIcon
                  className={`w-4 h-4 text-gray-400 cursor-pointer mouse-pointer `}
                  onClick={() => {
                    setRefresh(!refresh);
                  }}
                />
              </button>
            </div>
          </div>
          <div className="mt-4">
            <Search {...props} />
          </div>
        </div>
      </div>
      <div className="px-4">
        <Table data={nftTopTrades} col={column} isloading={loading} />
      </div>
    </>
  );
}

export default LatestTrades;
