/* eslint-disable react/prop-types */
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import React from 'react';

function SucessNotification({ setIsSubmitted, setRequired }) {
  return (
    <div>
      <div className="m-2">
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">
                All Done! Kudos{' '}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 "
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                    onClick={() => {
                      setIsSubmitted(false);
                      setRequired(false);
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SucessNotification;
