/* eslint-disable react/prop-types */
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React, { useEffect, useRef } from 'react';

am4core.useTheme(am4themes_animated);

export default function TopTradesChart(props) {
  const chartRef = useRef(null);
  // eslint-disable-next-line react/prop-types
  const { token, chartId } = props;
  // eslint-disable-next-line react/prop-types
  const items = token;
  useEffect(() => {
    // feedVolumeChart();

    if (!chartRef.current) {
      chartRef.current = am4core.create(chartId, am4charts.XYChart);
      am4core.options.autoDispose = true;
      chartRef.current.data = items;
      // Add X Axis
      let xAxis = chartRef.current.xAxes.push(new am4charts.CategoryAxis());
      xAxis.renderer.grid.template.location = 0;
      xAxis.dataFields.category = 'name';
      xAxis.renderer.minGridDistance = 20;
      xAxis.fontSize = 11;
      xAxis.renderer.labels.template.dy = 5;
      xAxis.renderer.labels.template.rotation = -45;
      xAxis.renderer.labels.template.horizontalCenter = 'right';
      xAxis.renderer.labels.template.verticalCenter = 'middle';
      xAxis.renderer.labels.template.fill = am4core.color('#9CA3AF');
      xAxis.renderer.grid.template.strokeWidth = 0;

      var image = new am4core.Image();
      image.horizontalCenter = 'middle';
      image.width = 20;
      image.height = 20;
      image.verticalCenter = 'middle';
      image.adapter.add('href', (href, target) => {
        let dataContext = target.dataItem.dataContext;
        if (dataContext) {
          return target.dataItem.dataContext['logo_url'];
        }
        return href;
      });
      xAxis.dataItems.template.bullet = image;
      // Add Y Axis
      let yAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
      yAxis.min = 0;
      yAxis.renderer.minGridDistance = 30;
      yAxis.renderer.baseGrid.disabled = true;
      yAxis.renderer.labels.template.fill = am4core.color('#9CA3AF');
      yAxis.renderer.grid.template.strokeWidth = 0;

      // Create series
      let series = chartRef.current.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = 'name';
      series.dataFields.valueY = 'value';
      series.columns.template.tooltipText = '{valueY.value}';
      series.columns.template.tooltipY = 0;
      series.columns.template.strokeOpacity = 0;
      series.columns.template.adapter.add('fill', function (fill, target) {
        return chartRef.current.colors.getIndex(target.dataItem.index);
      });

      // Series tooltip
      series.tooltipText = '{categoryX}: [bold]{valueY}[/]';
      series.tooltip.pointerOrientation = 'down';
      series.tooltip.dy = -5;
      series.tooltip.background.filters.clear();
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color('#2a2b2e');
      series.tooltip.background.stroke = am4core.color('#2a2b2e');

      // Add cursor
      chartRef.current.cursor = new am4charts.XYCursor();

      // Disable axis lines
      chartRef.current.cursor.lineX.disabled = true;
      chartRef.current.cursor.lineY.disabled = true;

      // Disable axis tooltips
      xAxis.cursorTooltipEnabled = false;
      yAxis.cursorTooltipEnabled = false;

      // Disable zoom
      chartRef.current.cursor.behavior = 'none';
    }

    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  // Load data into chart
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = items;
    }
  }, [items]);

  // Handle component unmounting, dispose chart
  useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  return (
    <>
      <div
        id={chartId}
        style={{
          width: '100%',
          height: '300px',
          margin: '50px 0',
        }}
      />
    </>
  );
}
