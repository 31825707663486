import { getUrlParam } from 'Helper/getUrlPram';
import { useLocalStorage } from 'Hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToptLpUnlisted } from 'statemgmt/actions';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import {
  ContentWrapper,
  MenuWrapper,
  PopoverWrapper,
  Refresh,
} from 'Utilities';

import { AdjustmentsIcon } from '@heroicons/react/solid';
import { CHAINS } from 'Helper/constants';
import { getLastIndexed } from 'Helper/services';
import DataLastIndexed from 'Utilities/DataLastIndexed/DataLastIndexed';
import TableTokenDashboard from 'Utilities/Table/tableTokenDashboard';
import Filters from './filters';
import useHeaders from './Helper/header';
import { processToken } from './Helper/processToken';
import SortOptions from './sortOptions';
import SortToggle from './sortToggle';
import { useNavigate } from 'react-router-dom';
import {
  token_duration,
  token_group,
  token_limit,
  token_type,
} from './Helper/constants';

function TopLpUnlisted() {
  const groupFromParam = getUrlParam('group');
  const limitFromParam = getUrlParam('limit');
  const typeFromParam = getUrlParam('type');
  const durationFromParam = getUrlParam('duration');
  const buySellFromParam = getUrlParam('buySellFilter');

  const [limit, setLimit] = useLocalStorage('lp_unlisted_limit', 20);
  const [group, setGroup] = useLocalStorage('lp_unlisted_group', 'whales');
  const [type, setType] = useLocalStorage('lp_unlisted_type', 'add_vs_remove');
  const [duration, setDuration] = useLocalStorage(
    'lp_unlisted_duration',
    '24hr'
  );

  const [refresh, setRefresh] = useState(false);
  const [buySellFilter, setBuySellFilter] = useLocalStorage(
    'lpunlistedbuysellfilter',
    buySellFromParam ? buySellFromParam : 'add_wallet_count'
  );
  const [lastIndexed, setLastIndexed] = useState('');
  const [column] = useHeaders();
  const dispatch = useDispatch();
  const block_chain = CHAINS[getUrlParam('chain')];
  const navigate = useNavigate();
  const basePath = '';
  const chain = getUrlParam('chain');

  const { topLpUnlisted, loading } = useSelector((s = initialState) => {
    return {
      topLpUnlisted: processToken(s.topLpUnlistedData.topLpUnlisted, type),
      loading: s.topLpUnlistedData.loading,
    };
  });

  const tableProps = {
    data: topLpUnlisted,
    col: column,
    isloading: loading,
    lastIndexed: lastIndexed,
  };

  const getTopLPUnlistedRes = () => {
    dispatch(
      getToptLpUnlisted(
        block_chain,
        limit,
        group,
        type,
        duration,
        buySellFilter
      )
    );
  };
  const validateURLParams = () => {
    const urlLimitBoolean = token_limit?.some(
      (item) => item.value === limitFromParam
    );
    if (urlLimitBoolean) setLimit(limitFromParam);

    const urlGroupBoolean = token_group?.some(
      (item) => item.value === groupFromParam
    );
    if (urlGroupBoolean) setGroup(groupFromParam);

    const urlDurationBoolean = token_duration?.some(
      (item) => item.value === durationFromParam
    );
    if (urlDurationBoolean) setDuration(durationFromParam);

    const urlTypeBoolean = token_type?.some(
      (item) => item.value === typeFromParam
    );
    if (urlTypeBoolean) setType(typeFromParam);
  };

  useEffect(() => {
    validateURLParams();
  }, []);

  useEffect(async () => {
    getTopLPUnlistedRes();
    const res = await getLastIndexed(block_chain, 'top-lp-unlisted');
    setLastIndexed(res?.data?.metadata?.data_last_indexed_timestamp_utc);
    navigate(
      `${basePath}?chain=${chain}&tab=TopLP(un-listed)&limit=${limit}&group=${group}&type=${type}&duration=${duration}&buySellFilter=${buySellFilter}`
    );
  }, [block_chain, limit, group, type, duration, refresh, buySellFilter]);

  const filterProps = Object.freeze({
    limit,
    setLimit,
    group,
    setGroup,
    duration,
    setDuration,
    type,
    setType,
  });

  return (
    <ContentWrapper>
      <div className="hidden sm:flex justify-between">
        <div className="flex flex-wrap space-x-1 mr-1 m-4 ">
          <Filters {...filterProps} />
          <div className="mx-2">
            <PopoverWrapper
              Icon={AdjustmentsIcon}
              label="More..."
              kind="primary"
            >
              <SortOptions
                filter={buySellFilter}
                setFilter={setBuySellFilter}
              />
            </PopoverWrapper>
          </div>
        </div>

        <div className="flex items-center space-x-2 m-3">
          <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
          <Refresh
            state={refresh}
            setState={setRefresh}
            loadingbutton={loading}
          />
        </div>
      </div>

      {/* filter, sort  options mobile view */}
      <div className="block sm:hidden py-4 px-2 lg:py-0 lg:px-0">
        <div className="flex justify-between">
          <div className="flex space-x-2">
            <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
            <Refresh
              state={refresh}
              setState={setRefresh}
              loadingbutton={loading}
            />

            <div>
              <MenuWrapper>
                <div className="p-2">
                  <Filters {...filterProps} />
                  <SortOptions
                    filter={buySellFilter}
                    setFilter={setBuySellFilter}
                  />
                </div>
              </MenuWrapper>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <TableTokenDashboard {...tableProps} />
      </div>
    </ContentWrapper>
  );
}

export default TopLpUnlisted;
