export const rankOptions = [
  {
    text: 'All Ranks',
    value: 'all',
  },
  {
    text: 'Top 10',
    value: 10,
  },
  {
    text: 'Top 20',
    value: 20,
  },
  {
    text: 'Top 50',
    value: 50,
  },
];

export const sortOptions = [
  {
    label: 'Latest Submissions',
    value: 'latest_submissions',
    tooltip: 'Latest Submissions',
  },
  {
    label: 'Ending Soonest',
    value: 'ending_soonest',
    tooltip: 'Ending Soonest',
  },
];

export const filterOptions = [
  {
    text: 'All',
    value: 'all',
    tooltip: 'All submission',
  },
  {
    text: 'Open',
    value: 'open',
    tooltip: 'Open submission',
  },
  {
    text: 'Closed',
    value: 'closed',
    tooltip: 'Closed submission',
  },
];
