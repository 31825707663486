import { CoinName } from 'Utilities';
import PublicLinks from 'Utilities/Coins/publicLinks';
import moment from 'moment';
import TokenSymbol from '../Atoms/tokenSymbol';

export const column = [
  {
    Header: 'Latest Token Pair Creation Age',
    accessor: 'latest_token_pair_creation_age',
    Filter: '',
  },
  {
    Header: 'Recent Top Performing Traders',
    accessor: 'recent_top_performing_traders',
    Cell: (cell) => (
      <div className="text-center">
        {cell.row.values.recent_top_performing_traders}
      </div>
    ),
    Filter: '',
  },
  // {
  //   Header: 'Recent Top Performing Traders Exited',
  //   accessor: 'recent_top_performing_traders_exited',
  //   Filter: '',
  // },
  {
    Header: 'Whales',
    accessor: 'whales',
    Cell: (cell) => <div className="text-center">{cell.row.values.whales}</div>,
    Filter: '',
  },
  {
    Header: 'Smart Money',
    accessor: 'smart_money',
    Cell: (cell) => (
      <div className="flex items-center justify-center w-full">
        <span>{cell.row.values.smart_money}</span>
      </div>
    ),
    Filter: '',
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (cell) => (
      <div className="flex items-center justify-betwee space-x-6">
        <CoinName
          isSubtring={true}
          slug={cell.data[cell.row.index].token_contract}
          provider={''}
          name={cell.row.values.name}
          logo_url={''}
          showLogo={false}
        />
      </div>
    ),
    Filter: '',
  },
  {
    Header: 'Token External Links',
    accessor: '',
    Cell: (cell) => (
      <div className="">
        <PublicLinks token_address={cell.row.values.token_contract} />
      </div>
    ),
    disableSortBy: true,
    disableFilters: true,
    Filter: '',
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: (cell) => <TokenSymbol symbol={cell.row.values.symbol} />,
    Filter: '',
  },
  {
    Header: 'Token Contract',
    accessor: 'token_contract',
    Cell: (cell) => (
      <div className="text-xs dark:text-dark-textPrimaryx">
        {cell.row.values.token_contract}
      </div>
    ),
    Filter: '',
  },
  {
    Header: 'Latest Token Traded Age',
    accessor: 'latest_token_traded_age',
    Filter: '',
  },
  {
    Header: 'Latest Token Pair Creation Timestamp',
    accessor: 'latest_token_pair_creation_timestamp',
    Cell: (cell) => (
      <div className="text-xs dark:text-dark-textPrimary">
        {moment
          .utc(cell.row.values.latest_token_pair_creation_timestamp * 1000)
          .format('LLL')}
      </div>
    ),
    Filter: '',
  },
  {
    Header: 'Latest Token Traded Timestamp',
    accessor: 'latest_token_traded_timestamp',
    Cell: (cell) => (
      <div className="text-xs dark:text-dark-textPrimary">
        {moment
          .utc(cell.row.values.latest_token_traded_timestamp * 1000)
          .format('lll')}
      </div>
    ),
    Filter: '',
  },
];
