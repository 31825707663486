import { ExternalLinkIcon } from '@heroicons/react/outline';
import { SCAN_ADDRESS } from 'Helper/constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLeaderBoardData } from 'statemgmt/actions';
import { Etherscan, Text } from 'Utilities';

const people = [
  {
    name: 'Lindsay Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
  },
  // More people...
];
export default function LeaksLeaderboard() {
  const dispatch = useDispatch();
  const { leaderBoard, loading } = useSelector((s = initialState) => {
    return {
      leaderBoard: s.leaderBoardData.leaderBoard.data,
      loading: s.leaderBoardData.leaderBoard.loading,
    };
  });
  useEffect(() => {
    dispatch(getLeaderBoardData());
  }, []);
  return (
    <div className="flex flex-col">
      <div className="flex">
        <Text
          size={'lg'}
          fontWeight={'semibold'}
          text={'Alpha Leads Leaderboard'}
          className={'dark:text-white'}
        />
        <a
          href="community-alpha/alpha-leads/leaderboard"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExternalLinkIcon className="m-1 h-4 w-4 cursor-pointer dark:text-white" />
        </a>
      </div>

      <Text
        size={'xs'}
        fontWeight={'medium'}
        text={'Top 3 ranked alphaleaks'}
        className={'dark:text-dark-textPrimary mb-4'}
      />
      <div className="p-2 bg-white border dark:bg-dark-themeCard rounded-xl blur-sm mb-4 dark:border-gray-700">
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700 ">
          <thead className="">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-center text-xs font-semibold text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0 "
              >
                Rank
              </th>
              <th
                scope="col"
                className="py-3.5 px-3 text-center text-xs font-semibold text-gray-900 dark:text-dark-textPrimary"
              >
                Account Address
              </th>
              <th
                scope="col"
                className="py-3.5 px-3 text-center text-xs font-semibold text-gray-900 dark:text-dark-textPrimary"
              >
                Alpha Score
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700 text-center">
            {leaderBoard &&
              leaderBoard.slice(0, 3).map((item, idx) => (
                <tr key={idx}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 dark:text-dark-textPrimary sm:pl-6 md:pl-0">
                    {item.influence_rank}
                  </td>
                  <td className="whitespace-nowrap py-4 px-3 text-xs text-gray-900 dark:text-dark-textPrimary">
                    <Etherscan
                      address={item.user_address}
                      type={SCAN_ADDRESS}
                      isSubString={true}
                    />
                  </td>
                  <td className="whitespace-nowrap py-4 px-3 text-xs text-gray-900 dark:text-dark-textPrimary">
                    {item.alpha_score}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
