import { ArrowLeftIcon } from '@heroicons/react/outline';
import { getUrlParam } from 'Helper/getUrlPram';
import useQuery from 'Hooks/useQuery';
import PillTab from 'Utilities/PillTab/pillTab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { getTokenInfo } from 'statemgmt/actions';
import LatestDextrades from './LatestDexTrades/latestDextrades';
import LatestTradeDistributionView from './LatestTradeDistribution/latestTradeDistributionView';
import LatestTransactions from './LatestTransactions/latestTransactions';
import NotableTraders from './NotableTraders/notableTraders';
import TokenOverview from './Overview/tokenOverview';
import { processData } from './TokenInfo/Helper/processData';
import TokenInfo from './TokenInfo/tokenInfo';
import TopHolders from './Top Holders/topHolders';
import { CHAINS } from 'Helper/constants';
import LatestTradeDistribution from './LatestTradeDistribution';

const tabOptions = [
  {
    name: 'Overview',
    id: '1',
    tab: 'Overview',
    tooltip: 'Token Market Overview',
    component: '<TokenOverview tokenInfo={tokenInfo} />',
  },
  {
    name: 'Notable Traders',
    id: '2',
    tab: 'NotableTraders',
    tooltip: 'Notable Wallets trading the token on DEXs',
    component: NotableTraders,
  },
  // {
  //   name: 'Top Holders',
  //   id: '3',
  //   tab: 'TopHolders',
  //   tooltip: 'Token Top Balances by Wallet Addresses',
  //   component: TopHolders,
  // },
  {
    name: 'Latest Trades',
    id: '4',
    tab: 'LasterTrades',
    tooltip: 'Token Latest DEx Trades with useful filters',
    component: LatestDextrades,
  },
  {
    name: 'Latest Trades (Distribution)',
    id: '5',
    tab: 'LastestTrades(Distribution)',
    tooltip: 'Latest Trades (Distribution)',
    component: LatestTradeDistribution,
  },
  {
    name: 'Latest Transactions',
    id: '6',
    tab: 'LatestTransactions',
    tooltip: 'Token Latest Token Transactions with useful filters',
    component: LatestTransactions,
  },
];
function Token() {
  const dispatch = useDispatch();
  let query = useQuery();
  const navigate = useNavigate();

  const [tokenTab, setTokenTab] = useState(String(query.get('tab')));

  const { tokenInfo } = useSelector((s = initialState) => {
    return {
      tokenInfo: processData(s.tokenInfoData.tokenInfo),
    };
  });
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const contract_address = params.get('contract_address');
  const chain = getUrlParam('chain');
  const chainId = CHAINS[chain];
  useEffect(() => {
    dispatch(getTokenInfo(contract_address, chainId));
  }, []);

  const props = {
    tabOptions,
    state: tokenTab,
    setState: setTokenTab,
    basePath: '/app/prebuilt/on-chain-analytics/token/details',
    search: { contract_address: contract_address, chain: chain },
  };
  return (
    <div className="min-h-screen">
      <NavLink to="/app/prebuilt/on-chain-analytics/token" className="w-5">
        <ArrowLeftIcon
          className="cursor-pointer w-5 text-black dark:text-white mx-8"
          // onClick={() => navigate(-1)}
        />
      </NavLink>
      <div className="mx-6">
        <TokenInfo tokenInfo={tokenInfo} chain={chain} />
      </div>
      <div className="px-6">
        <div className="flex flex-col">
          <div className="mt-2">
            <div className="flex flex-col lg:flex-row justify-between border-b dark:border-dark-tabPillBgSelectedDark">
              <PillTab {...props} />
            </div>
            <div className="">
              {tokenTab === 'Overview' ? (
                <TokenOverview tokenInfo={tokenInfo} />
              ) : tokenTab === 'NotableTraders' ? (
                <NotableTraders />
              ) : tokenTab === 'TopHolders' ? (
                <TopHolders />
              ) : tokenTab === 'LatestTrades' ? (
                <LatestDextrades />
              ) : tokenTab === 'LatestTrades(Distribution)' ? (
                <LatestTradeDistribution />
              ) : tokenTab === 'LatestTransactions' ? (
                <LatestTransactions />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Token;
