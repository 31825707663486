import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import { getLastIndexed } from 'Helper/services';
import { useLocalStorage } from 'Hooks';
import { MenuWrapper, Refresh, Select, Table } from 'Utilities';
import DataLastIndexed from 'Utilities/DataLastIndexed/DataLastIndexed';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLargestTransfers } from 'statemgmt/actions';
import { token_group, token_vol_limit } from './Helper/constants';
import { column } from './Helper/header';
import { processData } from './Helper/processData';
import { useNavigate } from 'react-router-dom';

export default function LatestTransaction() {
  const groupFromParam = getUrlParam('group');
  const limitFromParam = getUrlParam('limit');
  const [group, setGroup] = useLocalStorage('lt_group', 'all');
  const [volLimit, setVolLimit] = useLocalStorage('lt_vol_limit', '1');
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const [lastIndexed, setLastIndexed] = useState('');
  const block_chain = CHAINS[getUrlParam('chain')];
  const navigate = useNavigate();
  const basePath = '';
  const chain = getUrlParam('chain');

  // const validateURLParams = () => {
  //   const urlLimitBoolean = token_vol_limit?.some(
  //     (item) => item.value === limitFromParam
  //   );
  //   if (urlLimitBoolean) setVolLimit(limitFromParam);

  //   const urlGroupBoolean = token_group?.some(
  //     (item) => item.value === groupFromParam
  //   );
  //   if (urlGroupBoolean) setGroup(groupFromParam);
  // };

  // useEffect(() => {
  //   validateURLParams();
  // }, []);

  const { largestTransfers, loading } = useSelector((s = initialState) => {
    return {
      largestTransfers: processData(s.largestTranfersData.largestTransfers),
      loading: s.largestTranfersData.loading,
      // block_chain: s.currentBlockChain.selectedBlockChain,
    };
  });
  useEffect(async () => {
    dispatch(getLargestTransfers(block_chain, group, volLimit));
    const res = await getLastIndexed(block_chain, 'latest-transactions');
    setLastIndexed(res?.data?.metadata?.data_last_indexed_timestamp_utc);
    navigate(
      `${basePath}?chain=${chain}&tab=LatestTransactions&limit=${volLimit}&group=${group}`
    );
  }, [block_chain, group, volLimit, refresh]);
  return (
    <>
      <div className="dark:bg-dark-themePrimary bg-light-themeSecondary p-4 rounded-tr-md">
        <div className="hidden sm:block">
          <div className="flow-root ">
            <div className="flex justify-between">
              <div className="flex flex-wrap space-x-1 lg:mt-2">
                {/* <div className="bg-gray-100  rounded-md flex items-center p-2 my-1 dark:bg-gray-700 border-gray-700 text-dark-textPrimary">
                  <FilterIcon className="h-4 w-4" />
                </div> */}
                <div>
                  <Select
                    optionsObj={token_group}
                    state={group}
                    setState={setGroup}
                  />
                </div>
                <div className="">
                  <Select
                    optionsObj={token_vol_limit}
                    state={volLimit}
                    setState={setVolLimit}
                  />
                </div>
              </div>
              <div className="">
                <div className="flex justify-between">
                  <DataLastIndexed
                    lastIndexed={lastIndexed}
                    visibility={true}
                  />
                  <div className="ml-4">
                    <Refresh
                      state={refresh}
                      setState={setRefresh}
                      loadingbutton={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* filter and sort options mobile view */}
        <div className="sm:hidden block">
          <div className="flex flex-row-reverse">
            <div>
              <MenuWrapper>
                <div className="flex flex-wrap lg:mt-2">
                  <div className="my-1">
                    <Select
                      optionsObj={token_group}
                      state={group}
                      setState={setGroup}
                    />
                  </div>
                  <div className="my-1">
                    <Select
                      optionsObj={token_vol_limit}
                      state={volLimit}
                      setState={setVolLimit}
                    />
                  </div>
                </div>
              </MenuWrapper>
            </div>
            <div className="mx-2">
              <Refresh
                state={refresh}
                setState={setRefresh}
                loadingbutton={loading}
              />
            </div>
            <DataLastIndexed lastIndexed={lastIndexed} visibility={true} />
          </div>
        </div>
        <div className="pt-4">
          <Table data={largestTransfers} col={column} isloading={loading} />
        </div>
      </div>
    </>
  );
}
