/* eslint-disable react/prop-types */
import { naiveRound, roundValue, split } from 'Helper/utils';
import Tooltip from 'Utilities/Tooltip/tooltip';
import React from 'react';

const FormatPrice = ({ price }) => {
  if (!price) {
    return <div>-</div>;
  }
  if (price >= 1) {
    return (
      <span>
        <Tooltip content={price} placement="top">
          <div>{naiveRound(price, 4)}</div>
        </Tooltip>
      </span>
    );
  }
  if (price < 1) {
    const [wholePart, decimalPart] = price?.toString().split('.');
    const countZeros = decimalPart ? decimalPart.match(/0*/)[0].length : null;
    const [zeros, valueAfterZero] = split(decimalPart, countZeros);

    if (!countZeros) {
      return (
        <span>
          <Tooltip content={price} placement="top">
            <div>{naiveRound(price, 7)}</div>
          </Tooltip>
        </span>
      );
    }

    if (countZeros > 5) {
      return (
        <div className="inline-flex">
          {wholePart}
          {decimalPart && (
            <Tooltip content={price} placement="top">
              <div className="flex">
                <span>.</span>
                <span>0</span>
                <span>
                  <sub style={{ verticalAlign: 'sub' }}>
                    {countZeros > 1 && countZeros}
                  </sub>
                </span>
                <span>
                  {valueAfterZero > 4
                    ? roundValue(valueAfterZero)
                    : decimalPart.slice(countZeros)}
                </span>
              </div>
            </Tooltip>
          )}
        </div>
      );
    }
    if (countZeros <= 5) {
      if (valueAfterZero > 4) {
        return (
          <Tooltip content={price} placement="top">
            <div>
              {wholePart}.{zeros}
              {roundValue(valueAfterZero)}
            </div>
          </Tooltip>
        );
      }
    }

    return <div>{price}</div>;
  }
};

export default FormatPrice;
