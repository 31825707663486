import React from 'react';

function EffectStatus(props) {
  // eslint-disable-next-line react/prop-types
  const { status } = props;
  return (
    <div>
      {status === 'Open' ? (
        <span className="inline-flex items-center px-4 py-0.5 rounded text-sm font-medium bg-blue-300 text-gray-700">
          {status}
        </span>
      ) : (
        <span className="inline-flex items-center px-4 py-0.5 rounded text-sm font-medium bg-gray-600 text-gray-200">
          {status}
        </span>
      )}
    </div>
  );
}

export default EffectStatus;
