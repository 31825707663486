import React from 'react';
import PropTypes from 'prop-types';
function AlphaResult({ result }) {
  return (
    <div>
      {result === 'Success' ? (
        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 dark:bg-green-800 dark:text-gray-300">
          {result}
        </span>
      ) : (
        <span className="inline-flex items-center px-6 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
          {result}
        </span>
      )}
    </div>
  );
}
export default AlphaResult;

AlphaResult.propTypes = {
  result: PropTypes.string,
};
