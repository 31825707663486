/* eslint-disable react/prop-types */
import formatValue from 'Helper/formatValue';
import { BasicProgressBar } from 'Utilities';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import React from 'react';

const COLORS = {
  top_trades: {
    light: {
      Buy: 'text-tradeBuy',
      Sell: 'text-tradeSell',
    },
    dark: {
      Buy: 'text-tradeBuyDark',
      Sell: 'text-tradeSellDark',
    },
  },
  lp: {
    light: {
      Add: 'text-tradeBuy',
      Remove: 'text-tradeSell',
    },
    dark: {
      Add: 'text-tradeBuyDark',
      Remove: 'text-tradeSellDark',
    },
  },
};

const ACTION_GROUPS = {
  top_trades: ['Buy', 'Sell'],
  lp: ['Add', 'Remove'],
};

function TokenActivity({ cell, kind, action_group }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const positiveColor =
    theme === 'dark'
      ? COLORS[action_group].dark[ACTION_GROUPS[action_group][0]]
      : COLORS[action_group].light[ACTION_GROUPS[action_group][0]];
  const negativeColor =
    theme === 'dark'
      ? COLORS[action_group].dark[ACTION_GROUPS[action_group][1]]
      : COLORS[action_group].light[ACTION_GROUPS[action_group][1]];

  const ActionValues = ({ buy_volume, sell_volume, currency_symbol }) => {
    return (
      <>
        <div>
          {/* <div className="py-1 font-semibold">Volume USD</div> */}
          <div className={`mt-3 ${positiveColor}`}>
            {ACTION_GROUPS[action_group][0]}: {currency_symbol}
            <span>{formatValue('abbr', buy_volume) || 0}</span>
          </div>
          <div className={`${negativeColor}`}>
            {ACTION_GROUPS[action_group][1]}: {currency_symbol}
            <span>{formatValue('abbr', sell_volume) || 0}</span>
          </div>
        </div>
      </>
    );
  };

  const renderVolumeUsd = () => {
    const buy_volume = cell.data[cell.row.index].buy_volume || 0;
    const sell_volume = cell.data[cell.row.index].sell_volume || 0;
    const total_volume = parseInt(buy_volume) + parseInt(sell_volume);
    return (
      <div>
        <div className="flex flex-col space-y-2">
          <BasicProgressBar
            value={buy_volume}
            max={total_volume}
            action="buy"
          />
          <BasicProgressBar
            value={sell_volume}
            max={total_volume}
            action="sell"
          />
        </div>
        <ActionValues
          buy_volume={buy_volume}
          sell_volume={sell_volume}
          total_volume={total_volume}
          currency_symbol="$"
        />
      </div>
    );
  };

  const renderWalletCount = () => {
    const buy_wallet_count =
      parseInt(cell.data[cell.row.index].buy_wallet_count) || 0;
    const sell_wallet_count =
      parseInt(cell.data[cell.row.index].sell_wallet_count) || 0;
    const total_wallet_count = buy_wallet_count + sell_wallet_count;
    return (
      <div>
        <div className="flex flex-col space-y-2">
          <BasicProgressBar
            value={buy_wallet_count}
            max={total_wallet_count}
            action="buy"
          />
          <BasicProgressBar
            value={sell_wallet_count}
            max={total_wallet_count}
            action="sell"
          />
        </div>
        <ActionValues
          buy_volume={buy_wallet_count}
          sell_volume={sell_wallet_count}
          total_volume={total_wallet_count}
        />
      </div>
    );
  };

  const renderTxCount = () => {
    const buy_tx_count = cell.data[cell.row.index].buy_tx_count || 0;
    const sell_tx_count = cell.data[cell.row.index].sell_tx_count || 0;
    const total_wallet_count = parseInt(buy_tx_count) + parseInt(sell_tx_count);
    return (
      <div>
        <div className="flex flex-col space-y-2">
          <BasicProgressBar
            value={buy_tx_count}
            max={total_wallet_count}
            action="buy"
          />
          <BasicProgressBar
            value={sell_tx_count}
            max={total_wallet_count}
            action="sell"
          />
        </div>
        <ActionValues
          buy_volume={buy_tx_count}
          sell_volume={sell_tx_count}
          total_volume={total_wallet_count}
        />
      </div>
    );
  };

  const renderTxPerWallet = () => {
    const buy_accumulation = cell.data[cell.row.index].buy_accumulation || 0;
    const sell_accumulation = cell.data[cell.row.index].sell_accumulation || 0;
    const total_wallet_count =
      parseInt(buy_accumulation) + parseInt(sell_accumulation);
    return (
      <div>
        <div className="flex flex-col space-y-2">
          <BasicProgressBar
            value={buy_accumulation}
            max={total_wallet_count}
            action="buy"
          />
          <BasicProgressBar
            value={sell_accumulation}
            max={total_wallet_count}
            action="sell"
          />
        </div>
        <ActionValues
          buy_volume={buy_accumulation}
          sell_volume={sell_accumulation}
          total_volume={total_wallet_count}
        />
      </div>
    );
  };

  if (kind === 'volume_usd') return renderVolumeUsd();
  if (kind === 'wallet_count') return renderWalletCount();
  if (kind === 'tx_count') return renderTxCount();
  if (kind === 'tx_per_wallet') return renderTxPerWallet();

  // return (
  //   <>
  //     <div className="flex space-x-6">
  //       {renderVolumeUsd()}
  //       {renderWalletCount()}
  //       {renderTxCount()}
  //       {renderTxPerWallet()}
  //     </div>
  //   </>
  // );
}

export default TokenActivity;
