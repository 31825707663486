/* eslint-disable react/prop-types */
import { RefreshIcon } from '@heroicons/react/solid';
import Tooltip from 'Utilities/Tooltip/tooltip';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Refresh({ state, setState, loadingbutton }) {
  return (
    <div>
      <Tooltip content="reload" placement="top">
        <button className="rounded-md flex items-center px-1.5 py-1.5  antialiased bg-gray-100 dark:bg-gray-700">
          <span className="sr-only">refresh Option</span>
          <RefreshIcon
            className={`w-4 h-4 text-gray-600 dark:text-gray-300 cursor-pointer font-thin ${
              loadingbutton && 'animate-spin'
            }`}
            onClick={() => setState(!state)}
          />
        </button>
      </Tooltip>
    </div>
  );
}

export default Refresh;
