import { getTopIndicators } from 'Helper/services';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import bull from 'Assets/Images/bull.png';
import risk from 'Assets/Images/risk.png';

export default function TopIndicators() {
  const [ccbi, setCbbi] = useState('');

  const location = useLocation();

  const topIndicators = () => {
    getTopIndicators()
      .then((res) => {
        let keyAr = Object.keys(res);
        let dataAr = [];
        let maxVal = [];
        keyAr.map((item) => {
          dataAr.push(res[item]);
        });
        dataAr.map((item) => {
          let data = Object.entries(item).map(([key, value]) => {
            return value;
          });
          maxVal.push(data[data.length - 1]);
        });
        maxVal.shift();
        let sum = maxVal.reduce(function (acc, val) {
          return acc + val;
        }, 0);
        setCbbi(Math.round(parseInt(sum) * 10));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    topIndicators();
  }, []);
  return (
    <>
      {' '}
      <div className="relative bg-white dark:bg-dark-themeSecondary px-5 py-1  border-b dark:border-gray-700">
        <>
          <div
            className="flex flex-row justify-between
          "
          >
            <div className="font-semibold dark:text-white">Indicators</div>
            {location.pathname != '/macro' && (
              <div className="relative">
                {/* <Link to="app//macro" className="">
                  <img src={expand} className="mt-2 right-0 w-6" />
                </Link> */}
              </div>
            )}
          </div>
          <div className="text-xs text-gray-400">
            Not investment advice. These intend to be a fun way of looking at
            long-term price movements, disregarding daily noise in volatility.
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 my-3">
            <div
              className={`relative rounded-lg border  bg-white dark:bg-dark-themeCard py-5 shadow-sm items-center space-x-3 dark:border-gray-700`}
            >
              <div className="flex items-center space-x-2">
                <div className="px-3">
                  <img className="h-12 w-12 " src={bull} alt="" />
                </div>
                <div className="text-xs sm:text-lg font-semibold text-gray-900 dark:text-white">
                  Bitcoin Bull-run Index:
                </div>
              </div>
              <div>
                <div className="mt-2 flex justify-between md:block lg:flex">
                  <div className="flex items-baseline text-xs font-semibold text-gray-400 dark:text-gray-500">
                    Aggregated index that utilizes advanced, real-time analysis
                    of 10 metrics to help us understand what stage of the
                    Bitcoin bull run and bear market cycles we are in. On a
                    scale of 1 to 100, higher number indicates closer to the
                    top, lower indicates closer to bottom.
                  </div>
                </div>
                <dd className="mt-1 flex justify-between md:block lg:flex">
                  <div className="flex items-baseline sm:text-2xl font-semibold text-black dark:text-dark-textPrimary">
                    {ccbi && ccbi}
                  </div>
                </dd>
              </div>
            </div>
            <div
              className={`relative rounded-lg border bg-white dark:bg-dark-themeCard py-5 shadow-sm items-center space-x-3 dark:border-gray-700`}
            >
              <div className="flex items-center space-x-2">
                <div className="px-2">
                  <img className="h-12 w-12" src={risk} alt="" />
                </div>
                <div className="text-xs sm:text-lg font-semibold text-gray-900 dark:text-white">
                  Bitcoin Aggregate Risk :
                </div>
              </div>
              <div>
                <div className="mt-2 flex justify-between md:block lg:flex">
                  <div className="flex items-baseline text-xs font-semibold text-gray-400 dark:text-gray-500">
                    Aggregate Risk Model that uses on-chain metrics to
                    understand when the market is overheated as a result of
                    excessive sell-side pressure, by comapring the sell-side of
                    the market to the buy-side of the market. On a scale of 1 to
                    10, higher number indicates higher risk and lower number
                    indicates lower risk.
                  </div>
                </div>
                <dd className="mt-1 flex justify-between md:block lg:flex">
                  <div className="flex items-baseline sm:text-2xl font-semibold text-black dark:text-dark-textPrimary">
                    2.9
                  </div>
                </dd>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}
