import React from 'react';
import PropTypes from 'prop-types';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
function NavGroupTitle({ groupName, open }) {
  return (
    <div className="w-full flex items-center ml-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        strokeWidth="1.5"
        stroke="currentColor"
        className={classNames(
          open ? 'text-gray-400 rotate-90' : 'text-gray-300',
          'mx-1 w-3 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400 '
        )}
        viewBox="0 0 20 20"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
      <span
        className={classNames(
          'flex-1 font-semibold text-xs capitalize pl-1 -mb-0.5'
        )}
      >
        {groupName}
      </span>
    </div>
  );
}

export default NavGroupTitle;

NavGroupTitle.propTypes = {
  groupName: PropTypes.string,
  open: PropTypes.bool,
  theme: PropTypes.string,
};
