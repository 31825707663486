import { MoonIcon, SunIcon } from '@heroicons/react/outline';
import React from 'react';
import useThemeToggle from '../../Hooks/useThemeToggle';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';
export default function ThemeToggle() {
  // custom hook for toggling theme
  const [colorTheme, setTheme] = useThemeToggle();
  const toggleDarkMode = () => {
    setTheme(colorTheme);
  };
  // console.log(colorTheme);
  return (
    <>
      <Tippy
        content={
          <div className="text-xs">
            {colorTheme == 'light' ? 'Day Mode' : 'Night Mode'}
          </div>
        }
        placement="top"
      >
        <button
          onClick={toggleDarkMode}
          className="px-2 py-2  p-1 rounded-full text-gray-400 hover:text-gray-500"
        >
          {colorTheme === 'light' ? (
            <SunIcon className="h-5 w-5" />
          ) : (
            <MoonIcon className="h-5 w-5" />
          )}
        </button>
      </Tippy>
    </>
  );
}
