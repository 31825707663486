/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Header, LatestRequests } from './Molecules';

export default function AlphaRequest({ isUser }) {
  const [toggleContent, setToggleContent] = useState(true);
  const [requestDetails, setRequestDeatils] = useState('');
  const [hasWinner, setHasWinner] = useState();
  const props = {
    toggleContent: toggleContent,
    setToggleContent: setToggleContent,
    requestDetails: requestDetails,
    setRequestDeatils: setRequestDeatils,
    hasWinner: hasWinner,
    setHasWinner: setHasWinner,
    isUser: isUser,
  };

  return (
    <>
      <div>
        <Outlet context={{ props }} />
      </div>
    </>
  );
}

export const LatestRequestComponent = () => {
  const { props } = useOutletContext();
  return (
    <div
      className={`${
        props.isUser === true
          ? 'dark:bg-dark-themePrimary bg-light-themeSecondary'
          : 'bg-light-themePrimary dark:bg-dark-themeSecondary'
      } px-8 py-4`}
    >
      <Header isUser={props.isUser} />
      <LatestRequests {...props} />
    </div>
  );
};
