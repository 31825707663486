/* eslint-disable react/prop-types */
import { Menu, Transition } from '@headlessui/react';
import { Spinner } from 'Utilities';
import { ClusterIcon } from 'Utilities/images';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function UserClusterCard({
  cluster_name,
  plan,
  plan_updated_at,
  cluster_updated_at,
  config_updated_at,
  loading,
}) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const dtStyle = `${
    theme === 'dark' ? 'text-white' : 'text-gray-900'
  } text-xs`;
  const ddStyle = `${
    theme === 'dark' ? 'text-dark-textPrimary' : 'text-gray-900'
  } text-xs`;
  return (
    <div className="pt-6 pb-4 lg:w-1/2">
      <ul role="list" className="">
        <li
          className={`overflow-hidden rounded-xl border  ${
            theme === 'dark' ? 'border-gray-700' : 'border-gray-200'
          }`}
        >
          <div
            className={`flex items-center gap-x-4   p-6 ${
              theme == 'dark' ? 'bg-gray-700' : 'bg-gray-50'
            }`}
          >
            <img
              src={ClusterIcon}
              alt="cluster-img"
              className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 bg-blend-color-burn"
            />
            <div className="text-sm font-semibold leading-6 dark:text-white">
              {cluster_name}
            </div>
          </div>
          <dl className="divide-y divide-gray-100 px-6 py-1 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className={dtStyle}>Plan</dt>
              <dd className={ddStyle}>
                <div>{plan}</div>
              </dd>
            </div>
          </dl>
          <dl className="divide-y divide-gray-100 px-6 py-1 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className={dtStyle}> Plan Updated At</dt>
              <dd className={ddStyle}>
                <div>
                  {plan_updated_at?.replace('T', ' ')?.replace('Z', ' ')}
                </div>
              </dd>
            </div>
          </dl>
          <dl className="divide-y divide-gray-100 px-6 py-1 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className={dtStyle}> Cluster Updated At</dt>
              <dd className={ddStyle}>
                <div>
                  {cluster_updated_at?.replace('T', ' ')?.replace('Z', ' ')}
                </div>
              </dd>
            </div>
          </dl>
          <dl className="divide-y divide-gray-100 px-6 py-1 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className={dtStyle}> Config Updated At</dt>
              <dd className={ddStyle}>
                <div>
                  {config_updated_at?.replace('T', ' ')?.replace('Z', ' ')}
                </div>
              </dd>
            </div>
          </dl>
        </li>
      </ul>
    </div>
  );
}

export default UserClusterCard;
