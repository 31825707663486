/* eslint-disable react/prop-types */
import formatValue from 'Helper/formatValue';
import { CoinMiniChart } from 'Utilities';
import FormatPrice from 'Utilities/Misc/formatPrice';
import React from 'react';

function TokenMarket({ cell }) {
  const overall_volume = cell.data[cell.row.index].overall_volume || 0;
  const current_market_cap = cell.data[cell.row.index].current_market_cap || 0;
  return (
    <div>
      <div className="">
        <CoinMiniChart
          symbol={
            cell.data[cell.row.index].cg_id
              ? cell.data[cell.row.index].cg_id
              : cell.data[cell.row.index].cmc_id
          }
          provider={cell.data[cell.row.index].coin_info_provider}
          token_name={
            cell.data[cell.row.index].cg_id
              ? cell.data[cell.row.index].cg_id
              : cell.data[cell.row.index].cmc_slug
          }
        />
      </div>
      <div className="py-3 text-xs">
        <div className="font-semibold">
          {/* &#36; {cell.data[cell.row.index].price} */}
          <span className="inline-flex">
            $ <FormatPrice price={cell.data[cell.row.index].price} />
          </span>
          {/* <span className="align-bottom">8</span> */}
        </div>
        <div className="pt-2 text-gray-400">
          24 Hr Overall Vol:
          <span className="ml-1 text-gray-800 dark:text-white font-semibold">
            $
            {overall_volume > 0
              ? formatValue('abbr', overall_volume)
              : overall_volume}
          </span>
        </div>
        <div className="text-gray-400">
          Market Cap:
          <span className="ml-1 text-gray-800 dark:text-white font-semibold">
            {current_market_cap > 0
              ? '$' + formatValue('abbr', current_market_cap)
              : '?'}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TokenMarket;
