import { getFngData } from 'Helper/services';
import React, { useEffect, useState } from 'react';
import CorrelationInverse from '../MacroTop/Molecules/correlationInverse';
import Sentiment from '../MacroTop/Molecules/sentiment';
import TopIndicators from '../MacroTop/Molecules/topIndicators';
import TradingSession from '../MacroTop/Molecules/tradingSession';

export default function MacroSide() {
  const [fng, setFng] = useState([]);
  const getFng = () => {
    getFngData()
      .then((res) => {
        setFng(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getFng();
  }, []);
  return (
    <div className="dark:bg-dark-themeSecondary bg-light-white p-6 rounded-tr-md">
      <div className="text-2xl dark:text-white font-semibold px-4">Macro</div>
      <div className="">
        <div className="py-2 dark:border-dark-secondary rounded-lg flex flex-col ">
          <TopIndicators />
          <Sentiment fng={fng} />
          <CorrelationInverse />
          <div className="">
            <TradingSession />
          </div>
        </div>
      </div>
    </div>
  );
}
