/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getUrlParam } from 'Helper/getUrlPram';
import {
  getClusterLastConfig,
  getWorkFlowStatus,
  triggerUserClusterConfiguration,
} from 'Helper/services';
import { useEffect } from 'react';
import ClusterConfigSteps from './Atoms/clusterConfigSteps';
import ConfigureVault from './configureVault';
import { useNavigate } from 'react-router-dom';

function ConfigCluster() {
  const user_cluster_id = getUrlParam('id');
  const step = getUrlParam('step');
  const cid = getUrlParam('cid');

  const navigate = useNavigate();

  const checkUserClusterWfStatus = async () => {
    const uc_wf_status = await getWorkFlowStatus(user_cluster_id);

    if (!uc_wf_status?.status) {
      console.log('failed to get workflow status, please try again');
      return;
    }
    if (uc_wf_status?.data?.workflow_status === 'NEED_VAULT_INIT') {
      console.log('true');
      navigate(
        `/app/platforms/configuration/user-cluster/configure?id=${user_cluster_id}&cid=${cid}&step=vault-operations`
      );
    }
    let start_at_step = null;
    let end_at_step = null;
    if (uc_wf_status?.data?.last_config !== null) {
      const last_config = uc_wf_status?.data?.last_config;
      start_at_step = await getClusterLastConfig(last_config);
      if (uc_wf_status?.data?.last_config_completed !== 'x') {
        console.log({ start_at_step, end_at_step });
        // return;
        await triggerUserClusterConfiguration(
          user_cluster_id,
          start_at_step,
          end_at_step
        );
      }
    } else {
      // return;
      await triggerUserClusterConfiguration(
        user_cluster_id,
        start_at_step,
        end_at_step
      );
    }
  };

  useEffect(() => {
    checkUserClusterWfStatus();
  }, []);

  if (step === 'initial-setup')
    return (
      <ClusterConfigSteps user_cluster_id={user_cluster_id} cluster_id={cid} />
    );
  if (step === 'vault-operations')
    return <ConfigureVault user_cluster_id={user_cluster_id} />;
}

export default ConfigCluster;
