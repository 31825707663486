/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-var-requires */
import HighchartsReact from 'highcharts-react-official';
import { NoDataToDisplay } from 'react-highcharts-no-data-to-display';

// Import Highcharts
import Highcharts from 'highcharts/highstock';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/zigzag')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
// Import Highcharts

NoDataToDisplay(Highcharts);
// require('highcharts/modules/map')(Highcharts)
const StockChart = ({ options, ohlc }) => (
  <HighchartsReact
    highcharts={Highcharts}
    constructorType={'stockChart'}
    options={options}
  />
);

export const CandleCharts = ({ ohlc, symbol, contract_address, token }) => {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  let option = {
    chart: {
      backgroundColor: theme === 'dark' ? '#18242e' : '#ffffff',
      height: '600px',
      marginRight: 70,
    },
    title: {
      text: `Token Contract ${contract_address} (${token || symbol})`,
      style: {
        color: theme === 'dark' ? '#ffff' : '#2a3340',
        fontSize: '10px',
      },
    },
    yAxis: [
      {
        height: '100%',
        labels: {
          align: 'right',
          x: 50,
        },
        title: {
          text: symbol,
        },
        gridLineColor: theme === 'dark' ? '#96a3b0' : '#dddddd',
        gridLineWidth: 0.5,
        point: {
          pointWidth: 10,
        },
      },
      {
        top: '75%',
        height: '25%',
        width: '20%',
        labels: {
          align: 'right',
          x: -3,
        },
        offset: 0,
        title: {
          text: 'MACD',
        },
      },
    ],
    rangeSelector: {
      allButtonsEnabled: true,
      selected: 1,
    },
    plotOptions: {
      candlestick: {
        color: '#fd4b4e',
        upColor: '#3ac864',
        lineWidth: 1,
        pointWidth: 5,
      },
    },
    lang: {
      noData: 'no data!',
    },
    noData: {
      position: {
        x: 0,
        y: 0,
        align: 'center',
        verticalAlign: 'middle',
      },
    },
    series: [
      {
        data: ohlc,
        type: 'candlestick',
        name: `${token} Price`,
        id: symbol,
        groupPadding: 0,
        pointPadding: 0.03,
      },

      // {
      //   type: "pivotpoints",
      //   linkedTo: symbol,
      //   zIndex: 0,
      //   lineWidth: 1,
      //   dataLabels: {
      //     overflow: "none",
      //     crop: false,
      //     y: 4,
      //     style: {
      //       fontSize: 9
      //     }
      //   }
      // },
      {
        type: 'macd',
        yAxis: 1,
        linkedTo: symbol,
      },
    ],
    lang: {
      noData: 'No Market Data. Please Check Other Tabs',
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: theme === 'dark' ? '#ffff' : '#2a3340',
      },
    },
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={option}
      />
    </>
  );
};

export default CandleCharts;
