export const processData = (largestTransfersbytoken) => {
  // to hold push result data
  let dataSet = [];
  largestTransfersbytoken = largestTransfersbytoken?.data;
  if (largestTransfersbytoken && largestTransfersbytoken.length > 0) {
    for (let key of largestTransfersbytoken) {
      dataSet.push({
        name: key['f'][0]['v'],
        symbol: key['f'][1]['v'],
        token_address: key['f'][2]['v'],
        tx_hash: key['f'][3]['v'],
        from_address: key['f'][4]['v'],
        to_address: key['f'][5]['v'],
        value: key['f'][6]['v'],
        usd_value: key['f'][7]['v'],
        block_timestamp: key['f'][8]['v'],
      });
    }
  }
  return dataSet;
};
