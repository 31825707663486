import { ExclamationCircleIcon, XIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';

function Banner() {
  const [showBanner, setShowBanner] = useState(true);
  return (
    <div className="">
      {showBanner ? (
        <div className="fixed inset-x-0 bottom-4 rounded-2xl container mx-auto bg-blue-100 dark:bg-dark-themeBgDark border dark:border-gray-800">
          <div className="mx-auto py-2 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-blue-100 dark:bg-gray-600">
                  <ExclamationCircleIcon
                    className="h-4 w-4 italic  text-gray-500 dark:text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium  text-gray-500 dark:text-dark-textPrimary truncate">
                  <span className="md:hidden text-sm">Product in beta</span>
                  <span className="hidden md:inline text-sm">
                    The app is currently in beta phase and still work in
                    progress. Email us at{' '}
                    <span className="font-black">
                      alphastack.support@make.tech
                    </span>{' '}
                    with any suggestions or feedback!
                  </span>
                </p>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                  onClick={() => {
                    setShowBanner(false);
                  }}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon
                    className="h-4 w-4 text-gray-600 dark:text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Banner;
