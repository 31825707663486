import { Modal } from 'Utilities';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

function HasCluster() {
  const [showPopup, setShowPopup] = useState(true);

  return (
    <div>
      <Modal
        disableBackdropClick={true}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        width={96}
      >
        <div>
          <div className="flex justify-center items-center p-2">
            <div className="text-center">
              <h3 className="mt-2 text-md  text-gray-500 font-semibold">
                No Clusters Available
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                A cluster is required in order to use this feature
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Get started by creating a new cluster.
              </p>

              <div className="relative">
                <div className="mt-6">
                  <NavLink
                    to="/app/platforms/configuration/user-clusters"
                    className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Go to clusters page
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default HasCluster;
