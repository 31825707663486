import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Flex, Text, TrophyIcon } from 'Utilities';

RequestDetails.propTypes = {
  requestDetails: PropTypes.array,
};
export default function RequestDetails({ requestDetails }) {
  return (
    <>
      <Text
        size="lg"
        fontWeight="semibold"
        text={requestDetails && requestDetails?.title}
        className="dark:text-white"
      />
      <Flex justifyContent="between">
        <div className="inline-flex">
          <Text
            size="sm"
            fontWeight="medium"
            text="by"
            className="dark:text-dark-textPrimary mr-1"
          />
          <Text
            size="sm"
            color="blue-600"
            fontWeight="semibold"
            text={requestDetails?.user_address.slice(0, 10)}
          />
          <span className="w-1.5 h-1.5 rounded-full bg-gray-500 mx-2 my-2" />
          <Text
            size="sm"
            fontWeight="medium"
            text={moment
              .utc(requestDetails?.submission_timestamp_utc)
              .fromNow()}
            className="dark:text-dark-textPrimary mr-1"
          />
        </div>
        <div className="inline-flex">
          <TrophyIcon width="4" height="4" />
          <Text
            size="sm"
            fontWeight="medium"
            text={
              requestDetails?.reward_amount.slice(0, 4) +
              ' ' +
              requestDetails?.reward_currency_symbol
            }
            className="dark:text-dark-textPrimary mx-2"
          />
        </div>
      </Flex>
      <Text
        size="md"
        fontWeight="medium"
        text={requestDetails?.description}
        className="my-4 dark:text-dark-textPrimary"
      />
    </>
  );
}
