import React from 'react';
import error from '../../Assets/Images/error.png';
function ServerDisconnected() {
  return (
    <div className="flex flex-col justify-center items-center">
      <img src={error} alt="error" className="w-1/2" />
      <div>Something Unusual Happened</div>
    </div>
  );
}

export default ServerDisconnected;
