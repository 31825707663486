// CONSTANT VARIABLES FOR GLOABAL USE

export const SCAN_ADDRESS = 'address'; //TO USE WHEN SCAN USER ADDRESS IN ETHERSCAN.IO
export const SCAN_TOKEN = 'token'; //TO USE WHEN SCAN TOKEN IN ETHERSCAN.IO
export const SCAN_TX = 'tx'; //TO USE WHEN SCAN TOKEN IN ETHERSCAN.IO
export const SCAN_NFT = 'nft';

// backend host
export const alphastack_web_backend_host =
  process.env.REACT_APP_ALPHASTACK_WEB_BACKEND_HOST;

export const labelTexts = Object.freeze({
  ttcVol: 'By Volume (USD)',
  ttcWC: 'By Wallet Count',
  ttcTXC: 'By Tx Count',
  ttcAC: 'By Tx-Per-Wallet Count',
});

export const labelTrade = Object.freeze({
  buy: 'BUY',
  sell: 'SELL',
  add: 'LP-ADD',
  remove: 'LP-REMOVE',
});

export const tooltipTexts = Object.freeze({
  SHOWSCORE: 'show/ hide percentage scores',
});

export const CRYPTO_PANIC_KEY = '28e39cda048e072e8d7d73d6f6a324b4ea8e72a1';

export const CRPTO_PANIC_POST_KIND = Object.freeze([
  {
    text: 'All',
    value: 'All',
  },
  {
    text: 'news',
    value: 'news',
  },
  {
    text: 'media',
    value: 'media',
  },
]);

export const CRPTO_PANIC_POST_FILTER = Object.freeze([
  {
    text: 'All',
    value: 'All',
  },
  {
    text: 'rising',
    value: 'rising',
  },
  {
    text: 'hot',
    value: 'hot',
  },
  {
    text: 'bullish',
    value: 'bullish',
  },
  {
    text: 'bearish',
    value: 'bearish',
  },
  {
    text: 'important',
    value: 'important',
  },
  {
    text: 'saved',
    value: 'saved',
  },
  {
    text: 'lol',
    value: 'lol',
  },
]);

export const CURRENCIES = Object.freeze({
  0: 'ETH',
  1: 'ALPHAS',
});

// alpha request duration filter options
export const ARDO = Object.freeze([
  // [Last hour, Today, This week, This month, This year]

  {
    text: 'All Time',
    value: null,
  },
  {
    text: 'Last hour',
    value: 1,
  },
  {
    text: 'Today',
    value: 2,
  },
  {
    text: 'This week',
    value: 3,
  },
  {
    text: 'This month',
    value: 4,
  },
  {
    text: 'This year',
    value: 5,
  },
]);

// alpha request status filter options
export const ARSO = Object.freeze([
  {
    text: 'All Status',
    value: null,
  },
  {
    text: 'Open',
    value: 'open',
  },
  {
    text: 'Closed',
    value: 'closed',
  },
]);

// alpha request reward filter options
export const ARRO = Object.freeze([
  {
    text: 'All',
    value: null,
  },
  {
    text: 'Alphastack',
    value: 'ALPHAS',
  },
  {
    text: 'Ethereum',
    value: 'ETH',
  },
]);

// alpha request commented status request filter options
export const ARCSO = Object.freeze([
  {
    text: 'All Comments',
    value: null,
  },
  {
    text: 'Most Commented',
    value: 1,
  },
  {
    text: 'Least Commented',
    value: 2,
  },
]);

export const chainDetailExternalLink = Object.freeze({
  0: 'https://etherscan.io',
  1: 'https://polygonscan.com',
});

export const USER_SECRET_KEY = '@lphA$_73!';

export const CLIENT_ID =
  process.env.NODE_ENV == 'development'
    ? '392841dd-e140-4920-b0b9-77fd0869660a'
    : 'e37c5230-538d-4fa1-a54a-7a13d27220bf';

export const REDIRECT_URI =
  process.env.NODE_ENV == 'development'
    ? 'http://localhost:3000/callback'
    : 'https://alphastack.io/callback';

export const VAULT_REDIRECT_URI =
  process.env.NODE_ENV == 'development'
    ? 'http://localhost:3000/app/platforms/configuration/user-catalogs/vault-oidc/callback'
    : 'https://alphastack.io/app/platforms/configuration/user-catalogs/vault-oidc/callback';

export const AS_PLATFORM_SERVICE_BASE_URL = `https://alphastack-user-platform-service-cige4pikta-uc.a.run.app`;
export const AS_TERRAFORMIZE_BASE_URL = `https://alphastack-terraformize-cige4pikta-uc.a.run.app`;
export const AS_SUPERSET_USER_REG_URL = `https://alphastack-superset-user-registration-cige4pikta-uc.a.run.app`;
export const AS_SUPER_SET_REDIRECT_URL = `https://alphastack-superset-cige4pikta-uc.a.run.app`;
export const AS_GCS_FILE_COPY_URL = `https://gcs-file-copy-cige4pikta-uc.a.run.app`;

export const CHAINS = Object.freeze({
  ethereum: 0,
  polygon: 1,
  arbitrum: 2,
  binance: 3,
  solana: 4,
});

export const COIN_INFO_PLATFORM = {
  cg: 'https://www.coingecko.com/en/coins',
  cmc: 'https://coinmarketcap.com/currencies',
};

export const clusterConfigurationList = [
  {
    configuration: 'Trino',
    desc: 'Distributed Federated SQL Query Engine',
    tooltip:
      'Query Blockchain data and securely join it with external sources. Join your external data virtually, no need to move or copy it to AlphaStack.',
  },
  {
    configuration: 'Vault',
    desc: 'Secrets Management System',
    tooltip:
      'Self-store and self-manage your secrets (connection info to external data) securely in your dedicted cluster. Sign in with Ethereum instead of relying on a traditional intermediary and enjoy total privacy.',
  },
  {
    configuration: 'Superset-Trino-Integration',
    desc: 'Trino Integration with Data Exploration & Visualization Platform',
    tooltip:
      'Explore and visualize data using a wide array of beautiful visualizations that you can build on your queries to find unique insights.',
  },
];

export const MOCK_TOKEN = 'ple1Z0ry8qQ';
