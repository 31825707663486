/* eslint-disable react/prop-types */
import { DuplicateIcon, RefreshIcon } from '@heroicons/react/outline';
import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';
import { useCopyToClipBoard } from 'Hooks';

function CopyToClipboard(props) {
  const [isCopied, _setClipBoard] = useCopyToClipBoard();
  return (
    <Tippy content={props.tooltip} placement="top">
      <div>
        <DuplicateIcon
          className="hidden sm:block h-3 w-3 sm:h-4 sm:w-4 dark:text-dark-textPrimary cursor-pointer sm:ml-1 sm:mt-1"
          onClick={() => _setClipBoard(props._str)}
        />
      </div>
    </Tippy>
  );
}

export default CopyToClipboard;
