import React from 'react';

import { NavLink } from 'react-router-dom';
import { IntroMenu, Overview } from 'Utilities';
import { ChainAnalytics, LogoMinified, News, NftIcon } from 'Utilities/images';
function MLPlatform() {
  return (
    <div>
      <>
        <div className="dark:bg-dark-themeSecondary bg-light-themePrimary  p-4 rounded-tr-md">
          <div className="p-2 dark:border-dark-secondary rounded-lg flex flex-col lg:h-102">
            <main className="sm:flex mt-20">
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <div className="flex">
                    <h1 className="text-4xl font-medium text-gray-900 tracking-tight sm:text-5xl dark:text-dark-textPrimary">
                      Coming soon...
                    </h1>
                  </div>
                  <div className="sm:w-1/2 mt-4 text-justify dark:text-dark-textPrimary">
                    Build ML models on top of Alphastack&apos;s data platform
                    using this data science experimentation environment with
                    tooling for model training, debugging, validation and
                    deployment. Stay tuned
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    </div>
  );
}

export default MLPlatform;
