/* eslint-disable react/prop-types */
import React from 'react';
import { ThumbUpIcon as ThumbUpIconOutlined } from '@heroicons/react/outline';
import { ThumbUpIcon as ThumbUpIconSolid } from '@heroicons/react/solid';
export default function UpVoteIcon({ isActive }) {
  console.log({ isActive });
  return (
    <>
      {isActive ? (
        <ThumbUpIconSolid
          className={`rounded w-5 text-blue-500 pointer-events-none`}
        />
      ) : (
        <ThumbUpIconOutlined
          className={`rounded w-5 dark:text-gray-300 text-gray-700 `}
        />
      )}
    </>
  );
}
