import { CHAINS } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import { getLatestTradeDistribution } from 'Helper/services';
import { arrayFromJsonGroupByKey, groupByKey } from 'Helper/utils';
import { ContentWrapper, Spinner } from 'Utilities';
import { useEffect, useState } from 'react';
import DistributionChart from './Atoms/distributionChart';

function LatestTradeDistribution() {
  const [distributionData, setDistributionData] = useState([]);
  const [loading, setLoading] = useState(true);

  const chainId = CHAINS[getUrlParam('chain')];
  const contract_address = getUrlParam('contract_address');

  useEffect(() => {
    getLatestTradeDistribution(contract_address, chainId)
      .then((_r) => {
        const groupedData = groupByKey(_r, 'trade_action');
        const buyData = groupedData['buy']
          ? arrayFromJsonGroupByKey(groupedData['buy'])
          : 0;
        const sellData = groupedData['sell']
          ? arrayFromJsonGroupByKey(groupedData['sell'])
          : 0;
        if (buyData === 0 && sellData === 0) setDistributionData(null);
        else {
          const _d = { buy: buyData, sell: sellData };
          setDistributionData(_d);
        }
      })
      .catch((err) => {
        console.error(err);
        setDistributionData([]);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  }, [chainId]);

  const RenderNodata = () => {
    return (
      <ContentWrapper>
        <main className="sm:flex py-20">
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <div className="flex">
                <h1 className="text-sm font-extrabold text-gray-900 tracking-tight dark:text-dark-textPrimary">
                  No Market Data Yet
                </h1>
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Please Check Other Tabs
              </p>
            </div>
          </div>
        </main>
      </ContentWrapper>
    );
  };

  const Loading = () => {
    return (
      <div
        className="w-4 h-4 mx-1 rounded-full animate-spin
          border-4 border-dotted border-blue-500 border-t-transparent"
      ></div>
    );
  };
  const renderLastUpdatedTime = () => {
    return (
      <div className="text-xs dark:text-dark-textPrimary inline-flex pb-4 sm:pb-0">
        <p className="mx-1">Data Last Indexed : </p>
        <span className="font-semibold">
          {distributionData ? (
            distributionData.buy[14].data_last_indexed[0]
          ) : (
            <Loading />
          )}
        </span>
      </div>
    );
  };

  if (loading) {
    return (
      <ContentWrapper>
        <main className="flex items-center justify-center p-6">
          <Spinner />
        </main>
      </ContentWrapper>
    );
  }

  if (distributionData && Object.keys(distributionData).length > 0) {
    return (
      <ContentWrapper>
        <h1 className="dark:text-dark-textPrimary text-center font-semibold">
          Token Trades Breakdown by Wallet Group - Last 7 Days
        </h1>
        <div className="flex flex-row-reverse md:m-2">
          {renderLastUpdatedTime()}
        </div>
        <DistributionChart data={distributionData} />
      </ContentWrapper>
    );
  } else {
    return <RenderNodata />;
  }
}

export default LatestTradeDistribution;
