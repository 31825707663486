import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useRef } from 'react';
import Cta from './CtaSection/cta';
import Features from './FeatureSection/features';
import Hero from './HeroSection/hero';
import Navbar from './Navigation/navBar';

function LandingPage() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const callback = function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate-fadeIn');
      } else {
        entry.target.classList.remove('animate-fadeIn');
      }
    });
  };

  const observer = new IntersectionObserver(callback);
  const featureRef = useRef();

  const targets = document.querySelectorAll('.js-show-on-scroll');
  targets.forEach(function (target) {
    target.classList.add('opacity-0');
    observer.observe(target);
  });
  return (
    <div>
      <main className="lg:relative">
        <Navbar />
        <Hero featureRef={featureRef} />
        {/* <HeroNew /> */}
        <Features featureRef={featureRef} />
        {/* <Cta /> */}
        <Cta />
      </main>
      {/* <hr /> */}
    </div>
  );
}

export default LandingPage;
