/* eslint-disable react/prop-types */
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusSmIcon,
} from '@heroicons/react/solid';
import Tippy from '@tippyjs/react';
import { addressFormat } from 'Components/Login/Helper/formater';
import { SCAN_TOKEN } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import { CoinName, Etherscan } from 'Utilities';
import PublicLinks from 'Utilities/Coins/publicLinks';
import React from 'react';

function TokenName(props) {
  const { cell, showLogo } = props;
  const redirect_url = `/app/prebuilt/on-chain-analytics/token/details?chain=${getUrlParam(
    'chain'
  )}&tab=Overview&contract_address=${cell.data[cell.row.index].address}`;

  const renderLogo = () => {
    if (cell.data[cell.row.index].logo_url) {
      return (
        <img
          className="w-9 items-center rounded-full"
          src={cell.data[cell.row.index].logo_url}
          alt="logo"
        />
      );
    } else {
      return null;
    }
  };
  const renderRanking = () => {
    let currentRank = Number(cell.row.id) + Number(1);
    let prev_rank = cell.data[cell.row.index]?.ranking?.prev_rank;
    let difference = cell.data[cell.row.index].ranking?.prev_rank - currentRank;
    console.log(prev_rank);
    if (difference < 0)
      return (
        <div className="flex space-x-1">
          <Tippy
            content={`previous rank ${prev_rank ? prev_rank : ''}`}
            placement="top"
          >
            <p className="font-semibold mr-2">#{currentRank}</p>
          </Tippy>
          {difference == -1 ? (
            <ChevronDownIcon className="text-red-500 w-5 font-bold mt-.5" />
          ) : (
            <ChevronDoubleDownIcon className="text-red-500 w-5 font-bold mt-.5" />
          )}
        </div>
      );
    if (difference > 0)
      return (
        <div className="flex space-x-1">
          <Tippy
            content={`previous rank ${prev_rank ? prev_rank : ''}`}
            placement="top"
          >
            <p className="font-semibold mr-2">#{currentRank}</p>
          </Tippy>
          {difference == 1 ? (
            <ChevronUpIcon className="text-green-500 w-5 font-bold mt-.5" />
          ) : (
            <ChevronDoubleUpIcon className="text-green-500 w-5 font-bold mt-.5" />
          )}
        </div>
      );
    if (!prev_rank)
      return (
        <div className="flex space-x-1">
          <Tippy content={`New`} placement="top">
            <p className="font-semibold mr-2">#{currentRank}</p>
          </Tippy>
          <PlusSmIcon className="text-yellow-500 w-5 font-bold mt-.5" />
        </div>
      );
    return (
      <div className="flex space-x-1">
        <Tippy content={`New`} placement="top">
          <p className="font-semibold mr-2">#{currentRank}</p>
        </Tippy>
        <PlusSmIcon className="text-yellow-500 w-5 font-bold mt-.5" />
      </div>
    );
  };
  return (
    <div className="px-2 flex space-x-6 items-center">
      <div className="flex space-x-6 items-center">
        {showLogo && (
          <div>
            <div className="w-9 flex-shrink-0">
              <a href={redirect_url} target="_blank" rel="noreferrer">
                {renderLogo()}
              </a>
            </div>
          </div>
        )}

        <div>
          <CoinName
            isSubtring={true}
            slug={cell.data[cell.row.index].address}
            provider={cell.data[cell.row.index].coin_info_provider}
            name={cell.row.values.name}
            logo_url={cell.data[cell.row.index].logo_url}
            showLogo={true}
          />
          <div>
            {/* {addressFormat(cell.data[cell.row.index].address)} */}
            {/* <Etherscan
              address={cell.data[cell.row.index].address}
              type={SCAN_TOKEN}
              isSubString={true}
            /> */}
            <CoinName
              isSubtring={true}
              slug={cell.data[cell.row.index].address}
              provider={cell.data[cell.row.index].coin_info_provider}
              //name={cell.data[cell.row.index].address}
              logo_url={cell.data[cell.row.index].logo_url}
              showLogo={true}
            />
          </div>
          <div className="flex mt-2">
            <PublicLinks
              token_address={cell.data[cell.row.index].address}
              slug={cell.data[cell.row.index].slug}
              provider={cell.data[cell.row.index].coin_info_provider}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TokenName;
