/* eslint-disable react/jsx-no-undef */
import { AlphaRequestResponse } from 'Components/AlphaLeads/AlphaRequest/Molecules';
import AlphaRequest, {
  LatestRequestComponent,
} from 'Components/AlphaLeads/AlphaRequest/alphaRequest';
import LeaderBoard from 'Components/AlphaLeads/LeaderBoard/leaderBoard';
import MyAlphaLeads from 'Components/AlphaLeads/MyAlphaLeads/myAlphaLeads';
import UserLeads from 'Components/AlphaLeads/UserLeads/userLeads';
import ViewAlphaLeads from 'Components/AlphaLeads/ViewAlphaLeads/viewAlphaLeads';
import AlphaLeadsHome from 'Components/AlphaLeads/alphaLeadsHome';
import GetOidcToken from 'Components/Auth/vault-verify';
import Builder from 'Components/Builder';
import CommunityAlpha from 'Components/CommunityAlpha';
import Defi from 'Components/Defi/defi';
import UserWalletSubmissions from 'Components/EarnToken/SubmitWallet/AllSubmissions/allSubmissions';
import Gaming from 'Components/Gaming/gaming';
import Dashboard from 'Components/Home/Dashboard/dashboard';
import MacroSide from 'Components/Home/Macro/MacroSide/macroSide';
import Matrics from 'Components/Home/Matrics/matrics';
import News from 'Components/Home/News/news';
import Integrations from 'Components/Integrations/overview';
import Nft from 'Components/NFT/nft';
import OnChainAnalytics from 'Components/OnChainAnalytics';
import TraderExplorer from 'Components/OnChainAnalytics/TraderActivity';
import Configuration from 'Components/Platforms/Configuration';
import Catalog from 'Components/Platforms/Configuration/Catalog/catalog';
import NewDataSource from 'Components/Platforms/Configuration/Catalog/newDataSource';
import Cluster from 'Components/Platforms/Configuration/Cluster/cluster';
import ConfigCluster from 'Components/Platforms/Configuration/Cluster/configCluster';
import NewCluster from 'Components/Platforms/Configuration/Cluster/newCluster';
import DataPlatform from 'Components/Platforms/DataPlatform';
import Exploration from 'Components/Platforms/DataPlatform/Exploration/exploration';
import Orchestration from 'Components/Platforms/DataPlatform/Orchestration/orchastration';
import PipeLine from 'Components/Platforms/DataPlatform/Pipeline/pipeLine';
import Transformation from 'Components/Platforms/DataPlatform/Transformation/transformation';
import MLPlatform from 'Components/Platforms/MLPlatform';
import Deployment from 'Components/Platforms/MLPlatform/Deployment/deployment';
import ModelBuilding from 'Components/Platforms/MLPlatform/ModelBuilding/modelBuilding';
import Training from 'Components/Platforms/MLPlatform/Training/training';
import Profile from 'Components/Profile/profile';
import Token from 'Components/Token/token';
import TokenExplorer from 'Components/Token/tokenExplorer';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import Container from './container';
import BuilderPages from 'Components/Builder/pages';

// eslint-disable-next-line react/prop-types
function AppRoutes() {
  return (
    // <Container>
    <Routes>
      <Route path="app" element={<Container />}>
        <Route path="home">
          <Route path="welcome">
            <Route index element={<Home />} />
            <Route path="quick-access" element={<Home />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="macro" element={<MacroSide />} />
            <Route path="metrics" element={<Matrics />} />
            <Route path="news" element={<News />} />
          </Route>
        </Route>
        {/* social routes */}
        <Route path="social">
          <Route path="community-alpha">
            <Route path="how-it-works" element={<CommunityAlpha />} />
            <Route path="alpha-leads" element={<AlphaLeadsHome />}>
              <Route path="lead-list" element={<ViewAlphaLeads />} />
              <Route path="leaderboard" element={<LeaderBoard />} />
              <Route path="user-leads" element={<UserLeads />} />
            </Route>
            <Route
              path="alpha-request"
              element={<AlphaRequest isUser={false} />}
            >
              <Route index element={<LatestRequestComponent />} />
              <Route
                path=":id"
                element={<AlphaRequestResponse isUser={false} />}
              />
            </Route>
          </Route>
        </Route>
        {/* social routes */}
        {/* prebuilt routes */}
        <Route path="prebuilt">
          <Route path="on-chain-analytics">
            <Route path="overview" element={<OnChainAnalytics />} />
            <Route path="token">
              <Route index element={<TokenExplorer />} />
              <Route path="details" element={<Token />} />
            </Route>
            <Route path="trader-activity">
              <Route index element={<TraderExplorer />} />
            </Route>
            <Route path="defi" element={<Defi />} />
            <Route path="nft" title="NFT" element={<Nft />} />
            <Route path="gaming" title="gaming" element={<Gaming />} />
            <Route path="insights">
              <Route index element={<Builder />} />
              <Route path="wallet">
                <Route path=":id" element={<BuilderPages />} />
              </Route>
            </Route>
          </Route>
        </Route>
        {/* prebuilt routes */}
        {/* data platform routes */}
        <Route path="platforms">
          <Route path="data-platform">
            <Route path="overview" element={<DataPlatform />} />
            <Route path="pipeline" element={<PipeLine />} />
            <Route path="transformation" element={<Transformation />} />
            <Route path="orchestration" element={<Orchestration />} />
            <Route path="exploration">
              <Route index element={<Exploration />} />
              {/* <Route path="catelogs">
                <Route index element={<Catelogs />} />
                <Route path="configure" element={<ConnectConfiguration />} />
              </Route> */}
            </Route>
          </Route>

          {/* data platform routes */}
          {/* ML Platform routes */}
          <Route path="ml-platform">
            <Route path="overview" element={<MLPlatform />} />
            <Route path="model-building" element={<ModelBuilding />} />
            <Route path="training" element={<Training />} />
            <Route path="deployment" element={<Deployment />} />
          </Route>
          {/* ML Platform routes */}

          {/* configuration start */}
          <Route path="configuration">
            <Route index element={<Configuration />} />
            <Route path="user-clusters" element={<Cluster />} />
            <Route path="user-cluster/new" element={<NewCluster />} />
            <Route path="user-cluster/configure" element={<ConfigCluster />} />

            <Route path="user-catalogs">
              <Route index element={<Catalog />} />
              <Route path="vault-oidc/callback" element={<GetOidcToken />} />
              <Route path="new">
                <Route index element={<NewDataSource />} />
              </Route>
            </Route>
          </Route>
        </Route>
        {/* configuration end */}
        {/* integrations routes */}
        <Route path="integrations">
          <Route path="api">
            <Route path="overview" element={<Integrations />} />
          </Route>
        </Route>
        {/* integrations routes */}
        {/* profile routes */}
        <Route path="profile" element={<Profile />}>
          <Route path="my-alpha-leads" element={<MyAlphaLeads />} />
          <Route
            path="my-wallet-submissions"
            element={<UserWalletSubmissions />}
          />
          <Route
            path="my-alpha-requests"
            element={<AlphaRequest isUser={true} />}
          >
            <Route index element={<LatestRequestComponent />} />
            <Route
              path=":id"
              element={<AlphaRequestResponse isUser={true} />}
            />
          </Route>
        </Route>
        {/* profile routes */}
      </Route>
    </Routes>
  );
}

export default AppRoutes;
