import { Overview } from 'Utilities';
import OverviewCard from 'Utilities/Cards/overviewCard';
import BuilderIcon from 'Utilities/Icons/BuilderIcon';
import DefiIcon from 'Utilities/Icons/DefiIcon';
import NftIcon from 'Utilities/Icons/NftIcon';
import TokenExplorerIcon from 'Utilities/Icons/TokenExplorerIcon';
import TraderActivityIcon from 'Utilities/Icons/TradeActivityIcon';

const OVERVIEWMENU = [
  {
    title: 'Token Explorer',
    desc: 'Search by Token Address/ Symbol/ Name to get a lot of useful information about the token',
    url: '/app/prebuilt/on-chain-analytics/token',
    icon: TokenExplorerIcon,
  },
  {
    title: 'Trader Activity',
    desc: 'Search by Wallet Address to view the DEx trades made by that wallet',
    url: '/app/prebuilt/on-chain-analytics/trader-activity',
    icon: TraderActivityIcon,
  },
  {
    title: 'Defi',
    desc: 'A wide variety of powerful pre-built insights on Defi data that are easy-to-understand',
    url: '/app/prebuilt/on-chain-analytics/defi?chain=ethereum&tab=TopDexTrades&limit=20&group=whales&type=bought_sold&duration=24hr&sort_key=wallet_count',
    icon: DefiIcon,
  },
  {
    title: 'NFT',
    desc: 'A wide variety of powerful pre-built insights on NFT data that are easy-to-understand',
    url: '/app/prebuilt/on-chain-analytics/nft?chain=ethereum&tab=NFTDrops',
    icon: NftIcon,
  },
  {
    title: 'Insights',
    desc: 'Discover DEx trading insights using simple clicks of buttons',
    url: '/app/prebuilt/on-chain-analytics/insights',
    icon: BuilderIcon,
  },
];
function OnChainAnalytics() {
  return (
    <div>
      <>
        <Overview
          title="On Chain Analytics"
          desc=" Powerful pre-built insights on blockchain data that are
                easy-to-understand. Actionable insigths for all skill levels.
                Use the prebuilt analytics right out of the box or build a
                variety of insights using the builder tool."
        />
        <div className="max-w-7xl mt-6 mx-10 sm:mt-10">
          <div className="max-w-6xl">
            <div className="not-prose my-12 grid grid-cols-1 gap-10 lg:grid-cols-3">
              {OVERVIEWMENU.map((itm, key) => (
                <OverviewCard
                  key={key}
                  title={itm.title}
                  url={itm.url}
                  desc={itm.desc}
                  idx={key}
                  icon={itm.icon}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default OnChainAnalytics;
