/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */

import { AlphaResult, AlphaSentiment, Tooltip } from 'Utilities';

export const column = [
  {
    Header: 'Project Name',
    accessor: 'id',
    Cell: (cell) => (
      <Tooltip placement="top" content="Click to view more">
        <div className="text-blue-600 cursor-pointer font-semibold">
          {cell.data[cell.row.index].project_name
            ? cell.data[cell.row.index].project_name
            : '-'}
        </div>
      </Tooltip>
    ),
    Filter: '',
    isDrawer: true,
    drawerComponent: 'alphaleaks',
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Ticker',
    accessor: 'ticker',
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Alpha Sentiment',
    accessor: 'alpha_sentiment',
    Cell: ({ cell }) => {
      return cell.row.values.alpha_sentiment ? (
        <AlphaSentiment sentiment={cell.row.values.alpha_sentiment} />
      ) : (
        '-'
      );
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Submitted On',
    accessor: 'submitted_on_utc',
    Cell: ({ cell }) => {
      let dateTime = new Date(cell.row.values.submitted_on_utc).toUTCString(
        'en-US'
      );

      return dateTime;
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'alphaleaks',
  },
  {
    Header: 'Effect Period',
    accessor: 'effect_period',
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Expiration ',
    accessor: 'expiration_utc',
    Cell: ({ cell }) => {
      let dateTime = new Date(cell.row.values.expiration_utc).toUTCString(
        'en-US'
      );

      return dateTime;
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Actual Price at Start ',
    accessor: 'actual_price_at_start',
    Cell: (cell) => (
      <>
        {cell.row.values.actual_price_at_start
          ? '' + cell.row.values.actual_price_at_start
          : '-'}
      </>
    ),
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Actual Price at Close ',
    accessor: 'actual_price_at_close',
    Cell: (cell) => (
      <>
        {cell.row.values.actual_price_at_close
          ? '' + cell.row.values.actual_price_at_close
          : '-'}
      </>
    ),
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Result',
    accessor: 'result',
    Cell: ({ cell }) => {
      return cell.row.values.result ? (
        <AlphaResult result={cell.row.values.result} />
      ) : (
        '-'
      );
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  {
    Header: 'Score',
    accessor: 'score',
    Cell: ({ cell }) => {
      return cell.row.values.score ? cell.row.values.score : '-';
    },
    Filter: '',
    // isDrawer: true,
    // drawerComponent: 'userleaks',
  },
  // {
  //   width: 300,
  //   Header: ' ',
  //   accessor: 'id',
  //   Cell: ({ cell }) => (
  //     <>
  //       <button
  //         type="button"
  //         className="items-center px-3 py-2 border border-blue-700 shadow-sm text-sm leading-4 font-semibold rounded-md text-blue-700 bg-white hover:bg-gray-50 dark:text-dark-textPrimary dark:bg-dark-themeBgDark dark:border-gray-700"
  //       >
  //         Details
  //       </button>
  //     </>
  //   ),
  //   disableSortBy: true,
  //   isDrawer: true,
  //   drawerComponent: 'userleaks',
  //   Filter: '',
  // },
];
