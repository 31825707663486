/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import {
  format,
  subMonths,
  addMonths,
  subYears,
  addYears,
  isEqual,
  getDaysInMonth,
  getDay,
} from 'date-fns';

const DatepickerType = ['date', 'month', 'year'];

export default function DatePicker({ mintDate, setMintDate }) {
  const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [dayCount, setDayCount] = useState([]);
  const [blankDays, setBlankDays] = useState([]);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(
    new Date('Tue Feb 01 2022 00:00:00 GMT+0530')
  );
  const [type, setType] = useState(DatepickerType[0]);
  const today = new Date();

  const decrement = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      case 'month':
        setDatepickerHeaderDate((prev) => subYears(prev, 1));
        break;
      case 'year':
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
    }
  };

  const increment = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
        break;
      case 'month':
        setDatepickerHeaderDate((prev) => addYears(prev, 1));
        break;
      case 'year':
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
    }
  };

  const isToday = (date) =>
    isEqual(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth(), date),
      selectedDate
    );

  const setDateValue = (date) => () => {
    setSelectedDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date
      )
    );
    setMintDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        datepickerHeaderDate.getMonth(),
        date,
        datepickerHeaderDate.getHours(),
        datepickerHeaderDate.getMinutes(),
        datepickerHeaderDate.getSeconds()
      )
    );
    setShowDatepicker(false);
  };

  const getDayCount = (date) => {
    let daysInMonth = getDaysInMonth(date);

    // find where to start calendar day of week
    let dayOfWeek = getDay(new Date(date.getFullYear(), date.getMonth(), 1));
    let blankdaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    let daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  };

  const isSelectedMonth = (month) =>
    isEqual(
      new Date(selectedDate.getFullYear(), month, selectedDate.getDate()),
      selectedDate
    );

  const setMonthValue = (month) => () => {
    setDatepickerHeaderDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        month,
        datepickerHeaderDate.getDate()
      )
    );
    setType('date');
  };

  const toggleDatepicker = () => setShowDatepicker((prev) => !prev);

  const showMonthPicker = () => setType('month');

  const showYearPicker = () => setType('date');

  const setDateToCurrent = () => {
    setSelectedDate(
      new Date(today.getFullYear(), today.getMonth(), today.getDate())
    );
    setMintDate(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        today.getSeconds()
      )
    );
  };
  useEffect(() => {
    getDayCount(datepickerHeaderDate);
  }, [datepickerHeaderDate]);

  return (
    <div className="flex">
      <div className="antialiased sans-serif">
        <div>
          <div className="container mx-auto">
            <label
              htmlFor="datepicker"
              className="font-bold text-sm mb-1 text-gray-700 dark:text-dark-textPrimary block"
            >
              Select from-date
            </label>
            <div className="mb-5 w-64 flex space-x-2 bg-dark-themeBgDark rounded-md border-2 dark:border-dark-tabPillBgSelectedDark">
              <div className="relative">
                <input type="hidden" name="date" />
                <input
                  type="text"
                  readOnly
                  className="cursor-pointer w-full pl-4 pr-10 py-3 leading-none text-md font-semibold rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 dark:text-dark-textPrimary bg-white dark:bg-dark-themeBgDark"
                  placeholder="Select date"
                  value={format(selectedDate, 'LLL dd,yyyy')}
                  onClick={toggleDatepicker}
                  onBlur={toggleDatepicker}
                />
                <div
                  className="cursor-pointer absolute top-0 right-0 px-3 py-2"
                  onClick={toggleDatepicker}
                >
                  <svg
                    className="h-6 w-6 text-gray-400 dark:text-dark-textPrimary"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                {showDatepicker && (
                  <div
                    className="bg-white dark:bg-dark-themeBgDark mt-12 rounded-lg shadow p-4 absolute top-0 left-0"
                    style={{ width: '17rem' }}
                  >
                    <div className="flex justify-between items-center mb-2">
                      <div>
                        <button
                          type="button"
                          className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 dark:hover:bg-dark-bgMutedDark p-1 rounded-full"
                          onClick={decrement}
                        >
                          <svg
                            className="h-6 w-6 text-gray-500 inline-flex"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 19l-7-7 7-7"
                            />
                          </svg>
                        </button>
                      </div>
                      {type === 'date' && (
                        <div
                          onClick={showMonthPicker}
                          className="flex-grow p-1 text-lg font-bold text-gray-800 dark:text-dark-textPrimary cursor-pointer hover:bg-gray-200 dark:hover:bg-dark-bgMutedDark rounded-lg"
                        >
                          <p className="text-center">
                            {format(datepickerHeaderDate, 'MMMM')}
                          </p>
                        </div>
                      )}
                      <div
                        onClick={showYearPicker}
                        className="flex-grow p-1 text-lg font-bold text-gray-800 dark:text-dark-textPrimary cursor-pointer hover:bg-gray-200 dark:hover:bg-dark-bgMutedDark rounded-lg"
                      >
                        <p className="text-center">
                          {format(datepickerHeaderDate, 'yyyy')}
                        </p>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 dark:hover:bg-dark-bgMutedDark p-1 rounded-full"
                          onClick={increment}
                        >
                          <svg
                            className="h-6 w-6 text-gray-500 dark:text-dark-textPrimary inline-flex"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {type === 'date' && (
                      <>
                        <div className="flex flex-wrap mb-3 -mx-1">
                          {DAYS.map((day, i) => (
                            <div
                              key={i}
                              style={{ width: '14.26%' }}
                              className="px-1"
                            >
                              <div className="text-gray-800 dark:text-dark-textPrimary font-medium text-center text-xs">
                                {day}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-wrap -mx-1">
                          {blankDays.map((_, i) => (
                            <div
                              key={i}
                              style={{ width: '14.26%' }}
                              className="text-center border p-1 border-transparent text-sm"
                            ></div>
                          ))}
                          {dayCount.map((d, i) => (
                            <div
                              key={i}
                              style={{ width: '14.26%' }}
                              className="px-1 mb-1"
                            >
                              <div
                                onClick={setDateValue(d)}
                                className={`cursor-pointer text-center text-sm leading-none rounded-full sm:leading-loose transition ease-in-out duration-100 ${
                                  isToday(d)
                                    ? 'bg-blue-500 dark:bg-dark-bgMutedDark text-white'
                                    : 'text-gray-700 dark:text-dark-textPrimary hover:bg-blue-200 dark:hover:bg-dark-bgMutedDark'
                                }`}
                              >
                                {d}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                    {type === 'month' && (
                      <div className="flex flex-wrap -mx-1">
                        {Array(12)
                          .fill(null)
                          .map((_, i) => (
                            <div
                              key={i}
                              onClick={setMonthValue(i)}
                              style={{ width: '25%' }}
                            >
                              <div
                                className={`cursor-pointer p-5 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 ${
                                  isSelectedMonth(i)
                                    ? 'bg-blue-500 dark:bg-dark-bgMutedDark text-white'
                                    : 'text-gray-700 dark:text-dark-textPrimary hover:bg-blue-200 dark:hover:bg-dark-bgMutedDark'
                                }`}
                              >
                                {format(
                                  new Date(
                                    datepickerHeaderDate.getFullYear(),
                                    i,
                                    datepickerHeaderDate.getDate()
                                  ),
                                  'MMM'
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}{' '}
                    {
                      type === 'year' && null
                      //   <Datepicker
                      //     datepickerHeaderDate={datepickerHeaderDate}
                      //     selectedDate={selectedDate}
                      //     setSelectedDate={setSelectedDate}
                      //     closeDatepicker={() => setShowDatepicker(false)}
                      //   />
                    }
                  </div>
                )}
              </div>
              <div>
                <button
                  type="button"
                  className={`inline-flex items-center px-4 py-1 m-1.5 border border-transparent text-sm font-semibold rounded-md shadow-sm text-white bg-blue-700 hover:bg-blue-800 ${
                    isToday(mintDate) ? 'pointer-events-none' : null
                  }`}
                  onClick={() => {
                    setDateToCurrent();
                  }}
                >
                  Today
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
