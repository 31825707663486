//import { User, UserManager } from 'oidc-client-ts';
import { USER_SECRET_KEY } from 'Helper/constants';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

export default class AuthService {
  //userManager: UserManager;
  userManager;

  constructor() {
    const settings = {
      authority: process.env.REACT_APP_OIDC_AUTHORITY,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
      automaticSilentRenew: false, //TO_DO: setup silent_redirect_uri and set automaticSilentRenew to true
      monitorSession: true,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };
    this.userManager = new UserManager(settings);
    this.userManager.getUser().then((user) => {
      this.user = user;
    });
  }

  async startAuthentication() {
    try {
      // start a login redirect
      const result = await this.userManager.signinRedirect();
      return this.userManager.getUser(result);
    } catch (error) {
      return null;
    }
  }

  async completeAuthentication() {
    try {
      const user = await this.userManager.signinRedirectCallback();
      this.user = user;
    } catch (e) {
      // handle and rethrow OAuth response errors
      console.log('error', e);
    } finally {
      // always replace the browser location, to remove OAuth details from back navigation
    }
  }

  logout() {
    return this.userManager.signoutRedirect();
  }

  async getUser() {
    return this.userManager.getUser();
  }

  async getSessionStorageUserOrNewUser() {
    const sessionStorageKey =
      'oidc.user:' +
      process.env.REACT_APP_OIDC_AUTHORITY +
      ':' +
      process.env.REACT_APP_CLIENT_ID;
    return (
      JSON.parse(sessionStorage.getItem(sessionStorageKey)) ||
      this.userManager.getUser()
    );
  }

  getSessionStorageUser() {
    const sessionStorageKey =
      'oidc.user:' +
      process.env.REACT_APP_OIDC_AUTHORITY +
      ':' +
      process.env.REACT_APP_CLIENT_ID;
    return JSON.parse(sessionStorage.getItem(sessionStorageKey));
  }

  async getLocalStorageUserOrNewUser() {
    const localStorageKey =
      'oidc.user:' +
      process.env.REACT_APP_OIDC_AUTHORITY +
      ':' +
      process.env.REACT_APP_CLIENT_ID;
    return (
      JSON.parse(localStorage.getItem(localStorageKey)) ||
      this.userManager.getUser()
    );
  }

  getLocalStorageUser() {
    const localStorageKey =
      'oidc.user:' +
      process.env.REACT_APP_OIDC_AUTHORITY +
      ':' +
      process.env.REACT_APP_CLIENT_ID;
    return JSON.parse(localStorage.getItem(localStorageKey));
  }

  isLoggedIn() {
    const localStorageKey =
      'oidc.user:' +
      process.env.REACT_APP_OIDC_AUTHORITY +
      ':' +
      process.env.REACT_APP_CLIENT_ID;
    this.user = JSON.parse(localStorage.getItem(localStorageKey));
    return this.user != null && !this.user.expired;
  }

  isVerified() {
    const localStorageKey = 'verified_user';
    this.user = JSON.parse(localStorage.getItem(localStorageKey));
    const currentMil = new Date() * 1000;
    return (
      this.user != null &&
      this.user.key === USER_SECRET_KEY &&
      this.user.expires_at < currentMil
    );
  }
}
