import React, { useState } from 'react';
import { Modal } from 'Utilities';
import AlphaRequestForm from '../RequestForm/alphaRequestForm';

export default function RequestFormModalButton() {
  const [showPopup, setShowPopup] = useState(false);

  if (showPopup)
    return (
      <Modal
        disableBackdropClick={true}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        width={'5/6'}
      >
        <AlphaRequestForm showPopup={showPopup} setShowPopup={setShowPopup} />
      </Modal>
    );
  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-700 px-4 py-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
        onClick={() => setShowPopup(true)}
      >
        Submit Alpha Request
      </button>
    </div>
  );
}
