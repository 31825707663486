import { CHAINS } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import { getUrlParam } from 'Helper/getUrlPram';
import FormatPrice from 'Utilities/Misc/formatPrice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getTokenSummary } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Summary() {
  // state initilization start
  const [load, setLoad] = useState(false);
  // state initilization start
  // eslint-disable-next-line react/prop-types
  // accessing url param
  const { search } = useLocation();
  const urlparams = new URLSearchParams(search);
  const contract_address = urlparams.get('contract_address');
  const network = getUrlParam('chain');
  const chainId = CHAINS[network];
  // accessing url param
  const networkMapping = {
    ethereum: 'eth',
    polygon: 'polygon_pos',
    arbitrum: 'arbitrum',
    bsc: 'bsc',
    solana: 'solana',
  };
  // useDispatch() instance
  const dispatch = useDispatch();

  // listening to store changes
  const { tokenSummary } = useSelector((s = initialState) => {
    return {
      tokenSummary: s.tokenSummaryData.tokenSummary.data,
    };
  });

  useEffect(() => {
    if (!(tokenSummary && tokenSummary.length > 0)) {
      dispatch(
        getTokenSummary(contract_address, chainId, networkMapping[network])
      );
    }
  }, []);

  if (tokenSummary && tokenSummary.length > 0) {
    localStorage.setItem('logo', tokenSummary[0]['f'][3]['v']);
    localStorage.setItem('token', tokenSummary[0]['f'][0]['v']);
  }
  return (
    <div className="mt-4 mx-2">
      {tokenSummary ? (
        <div className="border rounded-lg p-2 dark:border-gray-700">
          <div className="p-4 border-b dark:border-gray-700">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {tokenSummary ? (
                !load ? (
                  <div className="relative flex items-center justify-center space-x-3">
                    <div className="flex-shrink-0">
                      {tokenSummary.summary.length > 0 ? (
                        <img
                          className="rounded-full w-10 h-10"
                          src={tokenSummary['summary'][0]['f'][3]['v']}
                          alt=""
                        />
                      ) : (
                        <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                          <span className="font-medium text-gray-600 dark:text-gray-300"></span>
                        </div>
                      )}
                    </div>
                    <div className="flex-1 min-w-0">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-md font-medium text-gray-900 dark:text-dark-textPrimary">
                        {tokenSummary.summary.length > 0
                          ? tokenSummary['summary'][0]['f'][0]['v']
                          : 'top_pool_data' in tokenSummary
                          ? 'attributes' in tokenSummary.top_pool_data
                            ? 'name' in tokenSummary.top_pool_data.attributes
                              ? tokenSummary.top_pool_data.attributes.name
                              : 'n/a'
                            : 'n/a'
                          : 'n/a'}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p>Loading....</p>
                )
              ) : null}
            </div>
          </div>
          <div className="mt-6">
            <div>
              {!load ? (
                <div>
                  <dl className="m-2">
                    <div className="py-3 flex justify-between text-sm font-medium">
                      <dt className="text-gray-500 text-sm dark:text-dark-textSecondary">
                        Ticker
                      </dt>
                      <dd className="text-gray-900 font-semibold dark:text-dark-textPrimary">
                        {tokenSummary.summary.length > 0
                          ? tokenSummary['summary'][0]['f'][2]['v']
                          : null}
                      </dd>
                    </div>
                    <div className="py-3 flex justify-between text-sm font-medium">
                      <dt className="text-gray-500 dark:text-dark-textSecondary">
                        Current Price
                      </dt>

                      <dd className="text-gray-900 font-semibold dark:text-dark-textPrimary inline-flex items-center">
                        $
                        {tokenSummary.summary.length > 0 ? (
                          <FormatPrice
                            price={tokenSummary['summary'][0]['f'][4]['v']}
                          />
                        ) : 'top_pool_data' in tokenSummary ? (
                          'attributes' in tokenSummary.top_pool_data ? (
                            'token_price_usd' in
                            tokenSummary.top_pool_data.attributes ? (
                              <FormatPrice
                                price={
                                  tokenSummary.top_pool_data.attributes
                                    .token_price_usd
                                }
                              />
                            ) : (
                              'n/a'
                            )
                          ) : (
                            'n/a'
                          )
                        ) : (
                          'n/a'
                        )}
                      </dd>
                    </div>
                    <div className="py-3 flex justify-between text-sm font-medium">
                      <dt className="text-gray-500 dark:text-dark-textSecondary">
                        24Hr Volume
                      </dt>
                      <dd className="text-gray-900 font-semibold dark:text-dark-textPrimary">
                        {tokenSummary.summary.length > 0
                          ? '$' +
                            formatValue(
                              'abbr',
                              tokenSummary['summary'][0]['f'][5]['v']
                            )
                          : null}
                      </dd>
                    </div>
                    <div className="py-3 flex justify-between text-sm font-medium">
                      <dt className="text-gray-500 dark:text-dark-textSecondary">
                        Market Cap
                      </dt>
                      <dd className="text-gray-900 font-semibold dark:text-dark-textPrimary">
                        {tokenSummary.summary.length > 0
                          ? '$' +
                            formatValue(
                              'abbr',
                              tokenSummary['summary'][0]['f'][6]['v']
                            )
                          : null}
                      </dd>
                    </div>
                    <div className="py-3 flex justify-between text-sm font-medium">
                      <dt className="text-gray-500 dark:text-dark-textSecondary">
                        Fully Diluted Market Cap
                      </dt>
                      <dd className="text-gray-900 font-semibold dark:text-dark-textPrimary">
                        {tokenSummary.summary.length > 0
                          ? tokenSummary['summary'][0]['f'][7]['v']
                            ? tokenSummary['summary'][0]['f'][7]['v'] > 0
                              ? '$' +
                                formatValue(
                                  'abbr',
                                  tokenSummary['summary'][0]['f'][7]['v']
                                )
                              : '-'
                            : tokenSummary['summary'][0]['f'][7]['v']
                          : null}
                      </dd>
                    </div>
                  </dl>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Summary;
