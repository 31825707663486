import React from 'react';
import PropTypes from 'prop-types';

import {
  token_duration,
  token_group,
  token_limit,
  token_type,
} from './Helper/constants';
import { Select } from 'Utilities';

function Filters({
  limit,
  setLimit,
  group,
  setGroup,
  duration,
  setDuration,
  type,
  setType,
}) {
  const limitProps = Object.freeze({
    optionsObj: token_limit,
    state: limit,
    setState: setLimit,
  });
  const groupProps = Object.freeze({
    optionsObj: token_group,
    state: group,
    setState: setGroup,
  });
  const durationProps = Object.freeze({
    optionsObj: token_duration,
    state: duration,
    setState: setDuration,
  });
  const optionsProps = Object.freeze({
    optionsObj: token_type,
    state: type,
    setState: setType,
  });

  return (
    <div className="flex flex-wrap space-x-1">
      <Select {...limitProps} />
      <Select {...groupProps} />
      <Select {...optionsProps} />
      <Select {...durationProps} />
    </div>
  );
}

export default Filters;

Filters.propTypes = {
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  group: PropTypes.string,
  setGroup: PropTypes.func,
  duration: PropTypes.string,
  setDuration: PropTypes.func,
  type: PropTypes.string,
  setType: PropTypes.func,
};
