import { ChevronLeftIcon } from '@heroicons/react/solid';
import { useNavigate, useParams } from 'react-router-dom';
import { RequestDetailsWrapper, RequestFormModalButton } from './Atoms';
import PropTypes from 'prop-types';

AlphaRequestResponse.propTypes = {
  isUser: PropTypes.bool,
};
export default function AlphaRequestResponse({ isUser }) {
  const { id } = useParams();
  let navigate = useNavigate();

  return (
    <div
      className={`${
        isUser === true
          ? 'dark:bg-dark-themePrimary bg-light-themeSecondary p-4'
          : 'bg-light-themePrimary dark:bg-dark-themeSecondary p-7'
      }  `}
    >
      <div className="flex justify-between">
        <div
          className="inline-flex my-4 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon className=" w-4 mr-1 dark:text-dark-textPrimary" />
          <div className="text-sm dark:text-dark-textPrimary font-semibold">
            Back
          </div>
        </div>
        <div className="my-auto">
          <RequestFormModalButton />
        </div>
      </div>
      <>
        <RequestDetailsWrapper request_id={id} />
      </>
    </div>
  );
}
