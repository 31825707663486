/* eslint-disable react/prop-types */
import { SCAN_TOKEN } from 'Helper/constants';
import { getUrlParam } from 'Helper/getUrlPram';
import React from 'react';
import CopyToClipboard from 'Utilities/CopyToClipboard/copyToClipboard';
import Etherscan from 'Utilities/Etherscan/etherscan';
import coingecko from '../../../Assets/Images/coingecko.png';
import etherscan from '../../../Assets/Images/etherscan.png';
import twitter from '../../../Assets/Images/twitter.png';
import projecthome from '../../../Assets/Images/project_home.png';
import { InformationCircleIcon } from '@heroicons/react/solid';
import {
  CoinMC,
  DexScreenerLogo,
  DexToolsLogo,
  TokenSnifferLogo,
} from 'Utilities/images';

const chainMatchWithDexTool = {
  ethereum: 'ether',
  polygon: 'polygon',
  arbitrum: 'arbitrum',
  binance: 'bnb',
  solana: 'solana',
};

const chainMatchWithDexScreener = {
  ethereum: 'ethereum',
  polygon: 'polygon',
  arbitrum: 'arbitrum',
  binance: 'bsc',
  solana: 'solana',
};

const chainMarchWithTokenSniffer = {
  ethereum: 'eth',
  polygon: 'poly',
  arbitrum: 'arb',
  binance: 'bsc',
  solana: 'sol',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TokenInfo({ tokenInfo, chain }) {
  const token_contract = getUrlParam('contract_address');

  const convertStringToURL = (value) => {
    if (value) return value.replace(/['"]+/g, '');
  };

  const renderEtherscan = () => {
    return (
      <a
        href={`https://etherscan.io/address/${token_contract}`}
        target="_blank"
        rel="noreferrer"
      >
        <img className="h-5 w-5 rounded-full" src={etherscan} alt="logo" />
      </a>
    );
  };

  const renderCoingecko = () => {
    if (tokenInfo[0]?.cg_id) {
      return (
        <a
          href={`https://www.coingecko.com/en/coins/${
            tokenInfo[0]?.cg_id || null
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <img className="h-5 w-5 rounded-full" src={coingecko} alt="logo" />
        </a>
      );
    }
  };

  const renderCoinMarketCap = () => {
    if (tokenInfo[0]?.cmc_id) {
      return (
        <a
          href={`https://coinmarketcap.com/currencies/${
            tokenInfo[0]?.cmc_slug || null
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <img className="h-5 w-5 rounded-full" src={CoinMC} alt="logo" />
        </a>
      );
    }
  };

  const renderWebsite = () => {
    if (tokenInfo.length > 0) {
      return (
        <a
          target="_blank"
          rel="alternate noreferrer"
          href={convertStringToURL(tokenInfo[0]?.homepage)}
        >
          <img
            className="h-5 w-5 rounded-full"
            src={projecthome}
            alt="Home_logo"
          />
        </a>
      );
    }
  };

  const renderTwitter = () => {
    return (
      <a
        href={`https://twitter.com/${tokenInfo[0]?.twitter_handle}`}
        target="_blank"
        rel="noreferrer"
      >
        <img className="h-5 w-5 rounded-full" src={twitter} alt="logo" />
      </a>
    );
  };

  const renderTokenSniffer = () => {
    if (token_contract) {
      return (
        <a
          href={`https://tokensniffer.com/token/${chainMarchWithTokenSniffer[chain]}/${token_contract}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="h-5 w-5 rounded-full"
            src={TokenSnifferLogo}
            alt="tokensniffer_logo"
          />
        </a>
      );
    }
  };

  const renderDexScreener = () => {
    if (token_contract) {
      return (
        <a
          href={`https://dexscreener.com/${chainMatchWithDexScreener[chain]}/${token_contract}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="h-5 w-5 rounded-full"
            src={DexScreenerLogo}
            alt="dexscreener_logo"
          />
        </a>
      );
    }
  };

  const renderDexTools = () => {
    if (token_contract) {
      return (
        <a
          href={`https://www.dextools.io/app/en/${chainMatchWithDexTool[chain]}/pair-explorer/${token_contract}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="h-5 w-5 rounded-full"
            src={DexToolsLogo}
            alt="DexTools_logo"
          />
        </a>
      );
    }
  };

  const renderChainInfo = () => {
    return (
      <div className="flex mx-4 xl:mx-8 mt-3">
        <div
          className={`flex space-x-2 items-center px-2 py-1 bg-blue-50 dark:bg-blue-200 text-xs rounded-md`}
        >
          <div className="">
            <InformationCircleIcon
              className="h-4 w-4 text-blue-500"
              aria-hidden="true"
            />
          </div>

          <p className="text-xs text-blue-700 dark:text-blue-800">
            You are currently viewing info for
            <span className="mx-1 font-semibold">{chain}</span> chain
          </p>
        </div>
      </div>
    );
  };
  return (
    <div className="container">
      {renderChainInfo()}
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="flex flex-col bg-gray-50 dark:bg-dark-themeCard p-3 rounded-md text-gray-500 dark:text-dark-textPrimary mx-2 xl:mx-8 my-3">
          <div className="flex">
            <p className="text-xs sm:text-sm font-bold mt-3">
              Contract Address:{' '}
              <span>
                <Etherscan
                  address={token_contract}
                  type={SCAN_TOKEN}
                  isSubString={false}
                />
              </span>
            </p>
            <div className="mt-3">
              <CopyToClipboard
                _str={token_contract}
                tooltip={'Copy Contract Address'}
              />
            </div>
          </div>
          <div className="flex space-x-3">
            <p className="text-xs sm:text-sm font-bold mt-3">Symbol:</p>
            <p className="text-xs sm:text-sm font-bold mt-3">
              {tokenInfo.length > 0 ? tokenInfo[0].symbol : null}
            </p>
          </div>
          <div className="flex space-x-3">
            <p className="text-xs sm:text-sm font-bold mt-3">Name:</p>
            <p className="text-xs sm:text-sm font-bold mt-3">
              {tokenInfo.length > 0 ? tokenInfo[0].name : null}
            </p>
          </div>
          <div className="mb-4 flex space-x-4 mt-3">
            {renderEtherscan()}
            {renderCoingecko()}
            {renderCoinMarketCap()}
            {renderWebsite()}
            {tokenInfo[0]?.twitter_handle && renderTwitter()}
            {renderTokenSniffer()}
            {renderDexScreener()}
            {renderDexTools()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TokenInfo;
