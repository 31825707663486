/* eslint-disable react/prop-types */
import Tooltip from 'Utilities/Tooltip/tooltip';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
//import Tooltip from 'Utilities/Tooltip/tooltip';

const THUMB_COLOR = {
  light: {
    buy: 'bg-tradeBuy',
    sell: 'bg-tradeSell',
  },
  dark: { buy: 'bg-tradeBuyDark', sell: 'bg-tradeSellDark' },
};

const TRACK_COLOR = {
  light: {
    buy: 'bg-trackHigh',
    sell: 'bg-trackMedium',
  },
  dark: {
    buy: 'bg-trackHighDark',
    sell: 'bg-trackMediumDark',
  },
};

function BasicProgressBar(props) {
  const { value, max, action } = props;

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });

  const trackColor =
    theme === 'dark' ? TRACK_COLOR.dark[action] : TRACK_COLOR.light[action];
  const thumpColor =
    theme === 'dark' ? THUMB_COLOR.dark[action] : THUMB_COLOR.light[action];
  return (
    <div>
      <div className="">
        <div className="group cursor-pointer">
          <Tooltip
            content={
              <>
                {/* <p>{`Overall action: ${
                  action.substr(0, 1).toUpperCase() + action.substr(1)
                }`}</p> */}
                <p>{`${
                  Math.round((value / max) * 100) >= 100
                    ? `Within the current list: Highest`
                    : ` Within the current list: ${Math.round(
                        (value / max) * 100
                      )}% as compared to the Highest`
                }`}</p>
              </>
            }
            placement="top"
          >
            <div
              className={`overflow-hidden h-3 rounded text-xs flex w-32 ${trackColor}`}
            >
              <div
                style={{
                  width: (value / max) * 100 + '%',
                }}
                className={`shadow-none flex flex-col text-center whitespace-nowrap rounded text-white justify-center ${thumpColor}`}
              ></div>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default BasicProgressBar;

// ${Math.round( (value / max) * 100)
