import { useLocalStorage, useOnClickOutside } from 'Hooks';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenSearch } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { Select } from 'Utilities';
import {
  Arbitrum,
  Binance,
  EthereumLogo,
  LogoMinified,
  PolygonLogo,
  SolanaLogo,
} from 'Utilities/images';
import { NavLink } from 'react-router-dom';

function TokenExplorer() {
  const [queryToken, setQueryToken] = useState('');
  const [chain, setChain] = useLocalStorage('chainId', 0);
  const [redirectOption, setRedirectOption] = useState('1');
  const [cgId, setCgId] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [isTokenSearch, setTokenSearch] = useState(false);
  let route;
  const ref = useRef();
  useOnClickOutside(ref, () => setTokenSearch(false));

  const dispatch = useDispatch();
  const processToken = (token) => {
    // to hold push result data
    let dataSet = [];
    token = token?.data;
    if (token && token.length > 0) {
      for (let key of token) {
        dataSet.push({
          name: key['f'][1]['v'],
          address: key['f'][2]['v'],
          cg_id: key['f'][7]['v'],
        });
      }
    }
    return dataSet;
  };
  const tokenSearchResultFormat = (tokenInfo) => {
    // to hold push result data
    let dataSet = [];
    // tokenInfo = tokenInfo?.data;
    if (tokenInfo && tokenInfo.length > 0) {
      for (let key of tokenInfo) {
        dataSet.push({
          contract_address: key['f'][0]['v'],
          symbol: key['f'][1]['v'],
          name: key['f'][2]['v'],
          price_usd: key['f'][3]['v'],
        });
      }
    }
    return dataSet;
  };

  const { token, tokenSearchInfo, searchLoading, theme } = useSelector(
    (s = initialState) => {
      return {
        token: processToken(s.dashboard.token),
        tokenSearchInfo: tokenSearchResultFormat(
          s.tokenSearchDetails.searchInfo
        ),
        searchLoading: s.tokenSearchDetails.loading,
        theme: s.appTheme.theme,
      };
    }
  );

  if (redirectOption === '1') {
    route = `/app/prebuilt/on-chain-analytics/token/details?contract_address=${queryToken.toLowerCase()}&tab=Overview`;
  } else {
    route = '/dashboard/wallet';
  }
  const getTokenInfo = (qToken) => {
    token.map((item) => {
      if (item.address === qToken) {
        setCgId(item.cg_id);
        setTokenName(item.name);
      }
    });
  };

  const tokenSearch = (search_input) => {
    setQueryToken(search_input);
    setTokenSearch(false);
    let search_type;
    search_input = search_input.toLowerCase().trim();
    if (search_input.length >= 3) {
      if (search_input.substring(0, 2).toLowerCase() == '0x') {
        search_type = 'contract_address';
      } else {
        search_type = 'symbol';
      }
      dispatch(getTokenSearch(search_input, search_type, chain));
      setTokenSearch(true);
    }
  };

  //FIXME: use more optimized way
  const chains = {
    0: 'ethereum',
    1: 'polygon',
    2: 'arbitrum',
    3: 'binance',
    4: 'solana',
  };
  const groupProps = {
    optionsObj: [
      { text: 'Ethereum', value: 0, 0: EthereumLogo },
      { text: 'Polygon', value: 1, 1: PolygonLogo },
      { text: 'Arbitrum', value: 2, 2: Arbitrum },
      { text: 'Binance', value: 3, 3: Binance },
      { text: 'Solana', value: 4, 4: SolanaLogo },
    ],
    state: chain,
    setState: setChain,
  };

  return (
    <div>
      <div className="relative px-4 md:px-6 lg:px-8 grid">
        <div className="flex flex-col items-center sm:items-start">
          <div className="flex flex-row items-center space-x-2">
            <img src={LogoMinified} className="w-8" />
            <h1 className="text-2xl lg:3xl dark: dark:text-white text-gray-800 font-medium">
              Token Explorer
            </h1>
          </div>
          <div className="my-4">
            <h1 className="text-gray-800 dark:text-dark-textPrimary text-sm font-medium text-center sm:text-left">
              Search by Token Address / Symbol / Name
            </h1>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center my-12">
        <div className="flex flex-col sm:flex-row space-x-3">
          <div className="">
            <div>
              <input
                type="input"
                className="block border dark:border-gray-700 p-2 h-10 rounded dark:text-dark-textPrimary text-xs w-80 sm:w-96  dark:bg-dark-themeBgDark focus:outline-none"
                placeholder="Search by Token Address/ Symbol/ Name"
                value={queryToken}
                onChange={(e) => tokenSearch(e.target.value)}
                //   onBlur={() => setTokenSearch(false)}
              />
            </div>
            {isTokenSearch ? (
              <div className="absolute z-40 mt-4" ref={ref}>
                <div
                  className={`bg-white dark:bg-dark-bgMutedDark border dark:border-gray-700 rounded-lg h-36 lg:w-96 overflow-y-auto ${
                    theme === 'dark'
                      ? 'metric-scroll-light'
                      : 'metric-scroll-dark'
                  }`}
                >
                  {searchLoading ? (
                    <div className="text-center m-auto w-80 lg:w-96 animate-pulse dark:text-dark-textPrimary">
                      Loading...
                    </div>
                  ) : tokenSearchInfo.length > 0 ? (
                    tokenSearchInfo.map((item, key) => (
                      <div
                        key={key}
                        className="p-3 border-b dark:border-gray-600 cursor-pointer w-80 lg:w-"
                        onClick={() => {
                          setQueryToken(item.contract_address);
                          setTokenSearch(false);
                          getTokenInfo(item.contract_address);
                        }}
                      >
                        <div className="flex flex-col">
                          <div className="flex">
                            <p className="mr-2 dark:text-dark-textPrimary text-xs">
                              <a
                                href={`/app/prebuilt/on-chain-analytics/token/details?chain=${
                                  chains[chain]
                                }&tab=Overview&contract_address=${item.contract_address.toLowerCase()}`}
                                onClick={() => {
                                  setQueryToken(item.contract_address);
                                  setTokenSearch(false);
                                  getTokenInfo(item.contract_address);
                                  localStorage.setItem('cgid', cgId);
                                  localStorage.setItem('cg_name', tokenName);
                                }}
                              >
                                <div className="flex space-x-2">
                                  <p>{item.name}</p>
                                  <p className="mr-2 dark:text-dark-textPrimary text-xs">
                                    ${item.price_usd}
                                  </p>
                                </div>
                                <div className="text-gray-300 text-sm">
                                  <p>{item.contract_address}</p>
                                </div>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center m-auto lg:w-96 my-12 text-gray-600 text-sm">
                      Ugh Oh! No data to show. Try with something else
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex justify-center items-center">
            <Select {...groupProps} />
          </div>
          <div className="flex items-center">
            <img
              src={groupProps.optionsObj[chain][chain]}
              // src={chain == 0 ? EthereumLogo : PolygonLogo}
              className="w-6 h-6"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center p-2">
        <NavLink
          to={`/app/prebuilt/on-chain-analytics/token/details?chain=${
            chains[chain]
          }&tab=Overview&contract_address=${queryToken.toLowerCase()}`}
          className={`${
            queryToken.length < 3 && 'pointer-events-none'
          } inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
        >
          Explore
        </NavLink>
      </div>
    </div>
  );
}

export default TokenExplorer;
