import { ARCSO, ARDO, ARRO, ARSO } from 'Helper/constants';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAlphaReqPreload } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { Select } from 'Utilities';
Filters.propTypes = {
  duration: PropTypes.string,
  setDuration: PropTypes.func,
  status: PropTypes.string,
  setStatus: PropTypes.func,
  reward: PropTypes.string,
  setReward: PropTypes.func,
  commentedStat: PropTypes.string,
  setCommentedStat: PropTypes.func,
  category: PropTypes.string,
  setCategory: PropTypes.func,
};

export default function Filters({
  duration,
  setDuration,
  status,
  setStatus,
  reward,
  setReward,
  commentedStat,
  setCommentedStat,
  category,
  setCategory,
}) {
  // const [categories, setCategories] = useState();

  const dispatch = useDispatch();
  const { categories } = useSelector((s = initialState) => {
    return {
      categories: s.alphaReqPreloadData.response,
    };
  });
  useEffect(() => {
    if (!categories || categories.length <= 0) {
      dispatch(getAlphaReqPreload());
    }
  }, []);

  return (
    <>
      <div className="relative flex flex-wrap">
        <div className="mr-1">
          <Select optionsObj={ARDO} state={duration} setState={setDuration} />
        </div>
        <div className="mr-1">
          <Select optionsObj={ARSO} state={status} setState={setStatus} />
        </div>
        {/* <div className="">
          <Select optionsObj={ARRO} state={reward} setState={setReward} />
        </div> */}
        <div className="mr-1">
          <Select
            optionsObj={ARCSO}
            state={commentedStat}
            setState={setCommentedStat}
          />
        </div>
        <div className="">
          <Select
            optionsObj={categories ? categories : []}
            state={category}
            setState={setCategory}
          />
        </div>
      </div>
    </>
  );
}
