import SentimentFng from 'Components/Home/SharedComponents/sentimentFng';
import { getFngData } from 'Helper/services';
import React, { useEffect, useState } from 'react';
import { Text } from 'Utilities';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@heroicons/react/outline';

export default function SentimentChild() {
  const [fng, setFng] = useState([]);
  const getFng = () => {
    getFngData()
      .then((res) => {
        setFng(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getFng();
  }, []);
  return (
    <div>
      <div className="flex">
        <Text
          size={'lg'}
          fontWeight={'semibold'}
          text={'Sentiment'}
          className={'dark:text-white'}
        />
        <a href="macro" target="_blank" rel="noopener noreferrer">
          <ExternalLinkIcon className="m-1 h-4 w-4 cursor-pointer dark:text-white" />
        </a>
      </div>

      <Text
        size={'xs'}
        fontWeight={'medium'}
        text={'General sentiment of the curretn bitcoin market'}
        className={'dark:text-dark-textPrimary mb-4'}
      />
      <SentimentFng fng={fng} />
    </div>
  );
}
