import { Table } from 'Utilities';
import React from 'react';
import PropTypes from 'prop-types';

function GroupNotableTraders({ walletAddresses, column, isloading }) {
  return (
    <div>
      <div>
        <h3 className="text-sm font-bold  dark:text-dark-textPrimary mb-2">
          Notable Traders
        </h3>
        <Table data={walletAddresses} col={column} isloading={isloading} />
      </div>
    </div>
  );
}

export default GroupNotableTraders;

GroupNotableTraders.propTypes = {
  walletAddresses: PropTypes.array,
  column: PropTypes.object,
  isloading: PropTypes.bool,
};
