/* eslint-disable react/prop-types */
import { ChevronLeftIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'Providers/appProvider';

function MySQLAWS({
  dbConfig,
  setDbConfig,
  cloudProvider,
  formError,
  catalogConf,
}) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (key, val) => {
    const catalogName = catalogConf?.catalog_name.replaceAll(/[_ \s]/g, '');
    const envKey = `${catalogName}${key}`;
    setDbConfig((prevState) => ({ ...prevState, [envKey]: val }));
  };

  const inputPlaceholderHandler = {
    1: {
      host: 'RDS Database host*',
      username: 'RDS master database username',
      password: 'RDS master database password',
    },
    2: {
      host: 'Database IP address*',
      username: 'Database username',
      password: 'Database password',
    },
    3: {
      host: 'DB server host*',
      username: 'DB server admin login name',
      password: 'DB server admin password',
    },
  };

  return (
    <div>
      <div className="my-4 sm:w-1/2">
        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-2">
            <input
              type="text"
              name="data-base-host"
              id="data-base-host"
              placeholder={inputPlaceholderHandler[cloudProvider].host}
              value={dbConfig.host}
              onChange={(e) => handleChange('host', e.target.value)}
              className="block w-full rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
            />
            {formError?.host && (
              <span className="text-xs text-red-500 mx-2 text-justify">
                {formError?.host}
              </span>
            )}
          </div>
          <div className="">
            <input
              type="text"
              name="port"
              id="port"
              value={dbConfig.port}
              placeholder="3306"
              onChange={(e) => handleChange('port', e.target.value)}
              className="block w-full rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
            />
            {formError?.port && (
              <span className="text-xs text-red-500 mx-2 text-justify">
                {formError?.port}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-3">
        <div>
          <input
            type="text"
            name="database-username"
            id="database-username"
            placeholder={inputPlaceholderHandler[cloudProvider].username}
            onChange={(e) => handleChange('username', e.target.value)}
            className="block w-full sm:w-1/2 rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
          />
          {formError?.username && (
            <span className="text-xs text-red-500 mx-2 text-justify">
              {formError?.username}
            </span>
          )}
        </div>
        <div className="w-full sm:w-1/2 flex items-center justify-between rounded-md border border-gray-600 dark:border-gray-500 px-2">
          <input
            type={showPassword ? 'text' : 'password'}
            name="database-password"
            id="database-password"
            placeholder={inputPlaceholderHandler[cloudProvider].password}
            onChange={(e) => handleChange('password', e.target.value)}
            className="w-full rounded-md p-2  sm:text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none"
          />
          {dbConfig?.password?.length > 0 &&
            (showPassword ? (
              <EyeOffIcon
                className="w-5 dark:text-gray-500 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <EyeIcon
                className="w-5 dark:text-gray-500 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              />
            ))}
        </div>
        {formError?.password && (
          <span className="text-xs text-red-500 mx-2 text-justify">
            {formError?.password}
          </span>
        )}
      </div>
      {/* <div className="my-3">
        <span className="text-red-600 text-xs">{formError && formError}</span>
      </div> */}
      <div className="my-2">
        <h1 className="dark:text-dark-textPrimary py-3 text-sm font-semibold">
          Test Configuration
        </h1>
        <p className="dark:text-dark-textPrimary pb-4 text-xs text-justify">
          Validate that the network configuration allows Starburst Galaxy to
          connect to the data source.
        </p>
        <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            // onClick={handleClick}
          >
            Test Connection
          </button>
        </div>
      </div>
    </div>
  );
}

export default MySQLAWS;
