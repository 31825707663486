import React from 'react';

function CatalogInfo() {
  return (
    <div>
      <div>
        <h1 className="dark:text-dark-textPrimary py-3 text-sm font-semibold">
          Name and description
        </h1>
        <p className="dark:text-dark-textPrimary pb-4 text-xs text-justify">
          Provide a unique name to identify the catalog in your SQL queries in
          the query editor and other client tools. The namespace for a table is
          typically <code>{'<catalog_name>.<schema_name>.<table_name>'}</code>
        </p>
        <div className="flex flex-col space-y-5">
          <div>
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              placeholder="Catalog name"
              className="block w-full sm:w-1/2 rounded-md p-2 text-xs  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500 placeholder"
            />
            <div className="text-xs text-gray-300 dark:text-gray-500 sm:w-1/2 px-3 py-1">
              Must start with a letter and only use lowercase letters (a-z),
              numbers (0-9), and underscores (_)
            </div>
          </div>
          <div>
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              placeholder="Description"
              className="block w-full sm:w-1/2 rounded-md p-2  text-sm  dark:bg-transparent bg-white dark:text-white focus:outline-none border border-gray-600 dark:border-gray-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatalogInfo;
