import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

type ModalProps = {
  disableBackdropClick: boolean;
  showPopup: boolean;
  setShowPopup: (show: boolean) => void;
  children: JSX.Element;
  width: string;
};

export default function Modal({
  disableBackdropClick,
  showPopup,
  setShowPopup,
  children,
  width,
}: ModalProps): JSX.Element {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div className="">
      <Transition.Root show={showPopup} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto sm:overflow-hidden"
          onClose={setShowPopup}
        >
          <div className="flex items-end justify-center my-40 sm:my-0  pt-4 px-4 pb-20 h-auto text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className={`fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity ${
                  disableBackdropClick && 'pointer-events-none'
                }`}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`inline-block align-bottom rounded-lg bg-white px-4 pt-10 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-${width} sm:h-max sm:p-3 dark:bg-dark-bgMutedDark ${
                  theme == 'dark' && 'bg-gray-800 text-white'
                }`}
              >
                {children}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
