import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [isLeaksSliderOver, setIsLeaksSliderOver] = useState(false);
  const [isWalletSliderOver, setIsWalletSliderOver] = useState(false);
  const [openSliderOver, setOpenSliderOver] = useState(false);
  const [uuid, setUuid] = useState(0);
  const user_id =
    JSON.parse(sessionStorage.getItem('user_info'))?.user_id || null;
  const [catalogConf, setCatalogConf] = useState({});
  return (
    <AppContext.Provider
      value={{
        isLeaksSliderOver,
        setIsLeaksSliderOver,
        isWalletSliderOver,
        setIsWalletSliderOver,
        openSliderOver,
        setOpenSliderOver,
        uuid,
        setUuid,
        catalogConf,
        setCatalogConf,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};
export { AppContext, AppProvider };
