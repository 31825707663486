import { FilterButton } from 'Utilities';
import React from 'react';
import { filterOptions } from '../constants';
import PropTypes from 'prop-types';

function SortOptions({ filter, setFilter }) {
  return (
    <div>
      <div className="flex flex-wrap mx-2 my-1">
        {filterOptions.map((item, key) => (
          <div key={key} className="group relative">
            <FilterButton
              value={item.val}
              label={item.valLabel}
              filter={filter}
              setFilter={setFilter}
              tooltip={item.tooltip}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SortOptions;

SortOptions.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func,
};
