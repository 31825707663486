/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import Etherscan from 'Utilities/Etherscan/etherscan';
import { SCAN_TX } from 'Helper/constants';
import formatValue from 'Helper/formatValue';
import moment from 'moment';

export default function useHeaders() {
  const [showValue, setShowValue] = useState(true);
  const [column, setColumn] = useState(getCol(showValue));

  const cols = getCol(showValue);
  useEffect(() => {
    setColumn(cols);
  }, [showValue]);
  return [column, setShowValue, showValue];
}

function getCol(showValue) {
  const column = [
    {
      Header: '#',
      id: 'row',
      accessor: '',
      Cell: (row) => {
        return <div>{Number(row.row.id) + Number(1)}</div>;
      },
      disableSortBy: true,
      disableFilters: true,
      Filter: '',
    },
    {
      Header: 'NFT',
      accessor: 'name',
      Cell: (cell) => (
        <div className="flex text-justify mt-3">
          <div className="flex-shrink-0 h-10 w-10">
            <Link
              to={{
                pathname: `https://opensea.io/assets/${`${
                  cell.data[cell.row.index]?.asset_contract?.address
                }`}/${`${cell.data[cell.row.index]?.token_id}`}`,
              }}
              target="_blank"
            >
              {cell.data[cell.row.index].image_url ? (
                <img
                  className="h-5 w-5 rounded-full"
                  src={`${cell.data[cell.row.index].image_url}`}
                  alt="logo"
                />
              ) : null}
            </Link>
          </div>
          <div className="">
            <div className="whitespace-nowrap text-sm text-blue-400 font-semibold">
              <Link
                to={{
                  pathname: `https://opensea.io/assets/${`${
                    cell.data[cell.row.index]?.asset_contract?.address
                  }`}/${`${cell.data[cell.row.index]?.token_id}`}`,
                }}
                target="_blank"
              >
                {cell.row.values.name && cell.row.values.name.length > 18 ? (
                  <Tippy content={cell.row.values.name} placement="top">
                    <div>{cell.row.values.name}</div>
                  </Tippy>
                ) : (
                  cell.row.values.name
                )}
              </Link>
            </div>
          </div>
          {/* <div>
            <Tippy content={'Open token details in a new tab'} placement="top">
              <Link
                to={`/app/prebuilt/on-chain-analytics/token/details?contract_address=${
                  cell.data[cell.row.index].address
                }`}
                target="_blank"
                query={'one'}
                className="inline-flex items-center px-4 py-1 cursor-pointer"
              >
                <ExternalLinkIcon className="ml-1 h-3 w-3" />
              </Link>
            </Tippy>
          </div> */}
        </div>
      ),
      Filter: '',
    },
    {
      Header: 'Collection',
      accessor: 'collection_name',
      Cell: (cell) => (
        <div className="flex text-justify mt-3">
          <div className="flex-shrink-0 h-10 w-10">
            <Link
              to={{
                pathname: `https://opensea.io/collection/${`${
                  cell.data[cell.row.index].collection?.slug
                }`}`,
              }}
              target="_blank"
            >
              {cell.data[cell.row.index].collection?.primary_asset_contracts[0]
                ?.image_url ? (
                <img
                  className="h-5 w-5 rounded-full"
                  src={`${
                    cell.data[cell.row.index].collection
                      ?.primary_asset_contracts[0]?.image_url
                  }`}
                  alt="logo"
                />
              ) : null}
            </Link>
          </div>
          <div className="">
            <div className="whitespace-nowrap text-sm text-blue-400 font-semibold">
              <Link
                to={{
                  pathname: `https://opensea.io/collection/${`${
                    cell.data[cell.row.index].collection?.slug
                  }`}`,
                }}
                target="_blank"
              >
                {cell.data[cell.row.index]?.collection
                  ?.primary_asset_contracts[0]?.name &&
                cell.data[cell.row.index]?.collection
                  ?.primary_asset_contracts[0]?.name > 18 ? (
                  <Tippy
                    content={
                      cell.data[cell.row.index]?.collection
                        ?.primary_asset_contracts[0]?.name
                    }
                    placement="top"
                  >
                    <div>
                      {
                        cell.data[cell.row.index]?.collection
                          ?.primary_asset_contracts[0]?.name
                      }
                    </div>
                  </Tippy>
                ) : (
                  cell.data[cell.row.index]?.collection
                    ?.primary_asset_contracts[0]?.name
                )}
              </Link>
            </div>
          </div>
          {/* <div>
            <Tippy content={'Open token details in a new tab'} placement="top">
              <Link
                to={`/app/prebuilt/on-chain-analytics/token/details?contract_address=${
                  cell.data[cell.row.index].address
                }`}
                target="_blank"
                query={'one'}
                className="inline-flex items-center px-4 py-1 cursor-pointer"
                onClick={() => {
                  localStorage.setItem('cgid', cell.data[cell.row.index].cg_id);
                  localStorage.setItem('cg_name', cell.row.values.name);
                }}
              >
                <ExternalLinkIcon className="ml-1 h-3 w-3" />
              </Link>
            </Tippy>
          </div> */}
        </div>
      ),
      Filter: '',
    },
    {
      Header: 'Last Price',
      accessor: 'last_price',
      Cell: (cell) => {
        let _totPrice = cell.data[cell.row.index].last_sale?.total_price;
        console.log(_totPrice);
        let _decimals =
          cell.data[cell.row.index].last_sale?.payment_token?.decimals;
        let _usdPrice =
          cell.data[cell.row.index].last_sale?.payment_token?.usd_price;
        let _lastPrice = (_totPrice / Math.pow(10, _decimals)) * _usdPrice;
        if (_totPrice && _decimals && _usdPrice) {
          let _op = `${Math.round(
            (_totPrice / Math.pow(10, _decimals)) * _usdPrice
          )}`;
          _lastPrice = `$ ${formatValue('format', _op)}`;
        } else {
          _lastPrice = '-';
        }

        return _lastPrice;
      },
      Filter: '',
    },
    // {
    //   Header: 'Highest Price(24H)',
    //   accessor: 'highest_price',
    // },
    // {
    //   Header: 'Sales(24hr)',
    //   accessor: 'sales',
    // },
    {
      Header: 'Last Deal',
      accessor: 'last_deal',
      Cell: (cell) => {
        let _txHash =
          cell.data[cell.row.index].last_sale?.transaction?.transaction_hash;
        let timestamp =
          cell.data[cell.row.index].last_sale?.transaction?.timestamp;
        let dateTime = moment
          .utc(cell.data[cell.row.index].last_sale?.transaction?.timestamp)
          .fromNow();
        if (_txHash) {
          return (
            <a
              className="mr-1 text-blue-500"
              href={`https://etherscan.io/tx/${_txHash}`}
              target="_blank"
              rel="noreferrer"
            >
              {dateTime}
            </a>
          );
        } else {
          return '-';
        }
      },
      Filter: '',
    },
  ];
  return column;
}
