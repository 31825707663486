import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, ShieldExclamationIcon } from '@heroicons/react/solid';
import Tippy from '@tippyjs/react';
import ActionMenu from './actionMenu';
import ConfigStatus from './configStatus';

export const column = [
  {
    Header: 'Cluster Status',
    accessor: 'cluster_status',
    Cell: (cell) => <ConfigStatus cell={cell} kind="cluster_status" />,
    Filter: '',
  },
  {
    Header: 'Config Status',
    accessor: 'config_status',
    Cell: (cell) => <ConfigStatus cell={cell} kind="config_status" />,
    Filter: '',
  },
  {
    Header: 'ID',
    accessor: 'name',
    Filter: '',
  },
  {
    Header: 'Nickname',
    accessor: 'nickname',
    Filter: '',
  },
  {
    Header: 'Plan',
    accessor: 'plan',
    Filter: '',
  },
  {
    Header: 'Allocation',
    accessor: 'allocation',
    Filter: '',
  },
  {
    Header: 'Memory',
    accessor: 'memory',
    Filter: '',
  },
  {
    Header: 'CPU',
    accessor: 'cpu',
    Filter: '',
  },
  {
    Header: 'Notifications',
    accessor: 'notifications',
    Filter: '',
  },
  {
    Header: 'Actions',
    width: 300,
    // eslint-disable-next-line react/prop-types
    Cell: (cell) => (
      // eslint-disable-next-line react/prop-types
      <ActionMenu
        user_clusters_id={cell.data[cell.row.index].user_clusters_id}
      />
      // <h1>dfdf</h1>
    ),
    openUtab: true,
    Filter: '',
  },
];
