import Tippy from '@tippyjs/react';
import { useLocalStorage } from 'Hooks';
import { FilterButton, MenuWrapper, Refresh, Select } from 'Utilities';
import Table from 'Utilities/Table/table';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getALphaleaksData } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import { filterOptions, rankOptions, sortOptions } from './Helper/constants';
import { column } from './header';
import SortOptions from './sortOptions';
import Filters from './filters';

export default function ViewAlphaLeads() {
  // state initialization start
  const [rank, setRank] = useLocalStorage('alphaleaks_rank', 'all');
  const [filter, setFilter] = useLocalStorage('alphaleaks_filter', 'all');
  const [sort, setSort] = useLocalStorage(
    'alphaleaks_sort',
    'latest_submissions'
  );
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();

  // listeing to redux store changes
  const { alphaleaks, loading } = useSelector((s = initialState) => {
    return {
      alphaleaks: s.alphaleaksData.alphaleaks,
      loading: s.alphaleaksData.loading,
    };
  });

  const filterProps = Object.freeze({
    rank: rank,
    setRank: setRank,
    filter: filter,
    setFilter: setFilter,
  });

  useEffect(() => {
    dispatch(getALphaleaksData(rank, filter, sort));
  }, [rank, filter, refresh, sort]);

  return (
    <>
      <div className="h-full z-10">
        <div className="dark:bg-dark-themePrimary bg-light-themeSecondary p-4 rounded-tr-md">
          <div className="hidden sm:block">
            <div className="flex justify-between my-1">
              <div className="flex gap-2">
                <Filters {...filterProps} />
                <SortOptions sort={sort} setSort={setSort} />
              </div>

              <div className="flex space-x-2 items-center">
                <div>
                  <Refresh
                    state={refresh}
                    setState={setRefresh}
                    loadingbutton={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* filter, sort options in mobile view */}
          <div className="sm:hidden block">
            <div className="flex flex-row-reverse">
              <div>
                <MenuWrapper>
                  <div className="py-1 px-2">
                    <div className="flex flex-col px-2 py-2">
                      <h2 className="text-black dark:text-gray-400 text-xs">
                        Filter by
                      </h2>
                      <div className="flex space-x-2 items-center mb-4">
                        <div className="">
                          <Select
                            optionsObj={rankOptions}
                            state={rank}
                            setState={setRank}
                          />
                        </div>
                        <div className="mr-2">
                          <Select
                            optionsObj={filterOptions}
                            state={filter}
                            setState={setFilter}
                          />
                        </div>
                      </div>
                      <div>
                        <h2 className="text-black dark:text-gray-400 text-xs">
                          Sort by
                        </h2>
                        <div className="flex flex-wrap">
                          {sortOptions.map((item, key) => (
                            <Tippy
                              key={key}
                              content={item.tooltip}
                              placement="top"
                            >
                              <div
                                key={key}
                                className="group cursor-pointer relative inline-block my-1 flex-shrink"
                              >
                                <FilterButton
                                  key={key}
                                  val={item.value}
                                  valLabel={item.text}
                                  filter={sort}
                                  setFilter={setSort}
                                />
                                {/* <Tooltip l1={item.tooltip} /> */}
                              </div>
                            </Tippy>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </MenuWrapper>
              </div>
              <div className="mx-2">
                <Refresh
                  state={refresh}
                  setState={setRefresh}
                  loadingbutton={loading}
                />
              </div>
            </div>
          </div>
          <div className="pt-3">
            <Table data={alphaleaks} col={column} isloading={loading} />
          </div>
        </div>
      </div>
    </>
  );
}
