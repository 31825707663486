import React from 'react';
import PropTypes from 'prop-types';
import { LogoMinified } from 'Utilities/images';

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
function PageTitle({ title, subtitle }) {
  return (
    <div className="px-4 md:px-6 lg:px-8">
      <div className="flex flex-col items-center sm:items-start">
        <div className="flex flex-row items-center space-x-2">
          <img src={LogoMinified} className="w-6" />
          <h1 className="text-md dark:text-white text-gray-800 font-semibold">
            {title}
          </h1>
        </div>
        <div className="my-2">
          <h1 className="text-gray-800 dark:text-dark-textPrimary text-xs font-medium text-center sm:text-left">
            {subtitle}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default PageTitle;
