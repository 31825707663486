/* eslint-disable react/prop-types */
import { ClockIcon } from '@heroicons/react/outline';
import React from 'react';
import Table from 'Utilities/Table/table';
import moment from 'moment';

function TableCard({ column, row, metric, last_updated, cols_type }) {
  let header = [];
  column.map((item, key) => {
    if (item === 'Coin') {
      header.push({
        Header: item,
        accessor: item,
        show: true,
        Cell: ({ cell }) => (
          <>
            <a
              href={cell.row.values.url}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center text-xs leading-4 font-semibold rounded-md text-blue-500"
            >
              {cell.row.values.Coin}
            </a>
          </>
        ),
        Filter: '',
      });
    } else if (cols_type[key] == 'timestamp') {
      header.push({
        Header: item,
        accessor: item,
        show: true,
        Cell: (cell) => {
          return moment
            .utc(cell.data[cell.row.index].Price_Last_Fetched)
            .fromNow(true);
        },
        Filter: '',
      });
    } else {
      header.push({
        Header: item,
        accessor: item,
        show: cols_type[key] == 'hidden' ? false : true,
        Filter: '',
      });
    }
  });

  return (
    <div className="">
      <div className="dark:border-dark-secondary rounded-md bg-white dark:bg-dark-themeBgDark">
        <div className="flex justify-between">
          <div className="mt-2 font-bold capitalize text-lg mb-2 dark:text-white">
            {metric}
          </div>
          <div className="mt-4 flex text-sm dark:text-dark-textPrimary">
            <ClockIcon className="w-4 h-4 mr-2 mt-0.5 dark:text-dark-textPrimary" />
            {moment.utc(last_updated).fromNow(true)} ago
          </div>
        </div>
        <div
          className={` overflow-y-scroll border dark:border-gray-700 rounded-md metric-scroll ${
            row?.length > 11 ? 'h-96' : 'h-auto'
          }`}
        >
          <Table data={row} col={header} />
        </div>
      </div>
    </div>
  );
}

export default TableCard;
