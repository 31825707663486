/* eslint-disable react/prop-types */
import { DefiIconDark, DefiIconLight } from 'Utilities/icons';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

function DefiIcon({ className }) {
  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div className="-ml-2">
      {theme === 'dark' ? (
        <img src={DefiIconDark} className="h-10 w-10" />
      ) : (
        <img src={DefiIconLight} className="h-10 w-10" />
      )}
    </div>
  );
}

export default DefiIcon;
