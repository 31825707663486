/* eslint-disable react/prop-types */
import React from 'react';

function ChartWrapper({ children, title, className }) {
  return (
    <div className="p-3 mb-4 border rounded-md dark:border-gray-700">
      <div className="p-0.5 mt-2 mb-4 text-center rounded-md">
        <h1 className="dark:text-white text-sm font-semibold">{title}</h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">{children}</div>
    </div>
  );
}

export default ChartWrapper;
