import React from 'react';

function ModelBuilding() {
  return (
    <div>
      <div className="dark:bg-dark-themeSecondary bg-light-themePrimary p-4 rounded-tr-md">
        <div className="p-2 dark:border-dark-secondary rounded-lg flex flex-col lg:h-102">
          <main className="sm:flex sm:mt-20">
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <div className="flex">
                  <h1 className="text-4xl font-medium text-gray-900 tracking-tight sm:text-5xl dark:text-dark-textPrimary">
                    Coming in 2023
                  </h1>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ModelBuilding;
