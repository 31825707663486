import { useAuth } from 'Authentication/authProvider';
import jwtDecode from 'jwt-decode';

function useGetCurrentUser() {
  const auth = useAuth();
  let user_address = null;
  const localStorageUser = auth.getLocalStorageUser();
  if (localStorageUser === null || localStorageUser === undefined) {
    //do nothing
  } else {
    if (localStorageUser.length === 0) {
      //do nothing
    } else {
      user_address = jwtDecode(localStorageUser['id_token'])[
        'preferred_username'
      ];
    }
  }

  return { user_address };
}

export default useGetCurrentUser;
