/* eslint-disable react/prop-types */
import { getUrlParam } from 'Helper/getUrlPram';
import { getTokenHistoricalPrice } from 'Helper/services';
import { useEffect, useState } from 'react';
import CandleCharts from './CandleCharts';
import { Spinner } from 'Utilities';
import BasicCharts from './BasicCharts';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TokenChart(props) {
  const token_contract = getUrlParam('contract_address');
  const network = getUrlParam('chain');
  const [ohlc, setOHLC] = useState([]);
  const [loading, setLoading] = useState(true);

  const networkMapping = {
    ethereum: 'eth',
    polygon: 'polygon_pos',
    arbitrum: 'arbitrum',
    bsc: 'bsc',
    solana: 'solana',
  };

  let token_slug = ``;
  let token_name = ``;
  const tokenInfo = props.tokenInfo[0];
  if (tokenInfo) {
    token_slug = tokenInfo?.cg_id ? tokenInfo?.cg_id : tokenInfo?.cmc_id;
    token_name = tokenInfo?.cg_name ? tokenInfo?.cg_name : tokenInfo?.cmc_slug;
  }
  let provider = '';
  if (tokenInfo?.cg_id) provider = 'cg';
  else if (tokenInfo?.cmc_id) provider = 'cmc';

  const getOHLC = () => {
    // setLoading(true);
    getTokenHistoricalPrice(
      token_slug,
      provider,
      token_contract,
      networkMapping[network]
    )
      .then((_r) => {
        if (_r.status) {
          if (provider === 'cg' || provider === 'cmc') {
            setOHLC(_r.message);
          } else {
            const reversed = [
              ..._r.message.data.attributes.ohlcv_list,
            ].reverse();
            setOHLC(reversed);
          }
        } else setOHLC([]);
      })
      .catch((err) => {
        console.log('OHLC', err.message);
        setOHLC([]);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      });
  };

  useEffect(() => {
    getOHLC();
  }, [token_slug]);

  const RenderNodata = () => {
    return (
      <main className="sm:flex py-20">
        <div className="sm:ml-6">
          <div className="sm:border-l sm:border-gray-200 sm:pl-6">
            <div className="flex">
              <h1 className="text-sm font-extrabold text-gray-900 tracking-tight dark:text-dark-textPrimary">
                No Market Data Yet
              </h1>
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Please Check Other Tabs
            </p>
          </div>
        </div>
      </main>
    );
  };

  const renderOHLCChart = () => {
    return (
      <CandleCharts
        symbol={token_slug}
        ohlc={ohlc}
        contract_address={token_contract}
        token={token_name}
      />
    );
  };

  const renderLoading = () => {
    return (
      <main className="flex items-center justify-center mt-20 p-6">
        <Spinner />
      </main>
    );
  };
  // return (
  //   <div className="">
  //     <div className="lg:mt-4">
  //       {loading
  //         ? renderLoading()
  //         : ohlc && ohlc.length > 0 && !loading
  //         ? renderOHLCChart()
  //         : null}
  //     </div>
  //   </div>
  // );

  if (loading) {
    return (
      <main className="flex items-center justify-center mt-20 p-6">
        <Spinner />
      </main>
    );
  }
  if (ohlc.length > 0) {
    return (
      <BasicCharts
        symbol={token_slug}
        ohlc={ohlc}
        contract_address={token_contract}
        token={token_name}
        provider={provider}
      />
    );
  } else {
    return <RenderNodata />;
  }
}
export default TokenChart;
