export const processData = (tokenInfo) => {
  // to hold push result data
  let dataSet = [];
  tokenInfo = tokenInfo?.data;
  if (tokenInfo && tokenInfo.length > 0) {
    for (let key of tokenInfo) {
      dataSet.push({
        symbol: key['f'][0]['v'],
        name: key['f'][1]['v'],
        cg_id: key['f'][2]['v'],
        cg_name: key['f'][3]['v'],
        logo_thumb_url: key['f'][4]['v'],
        homepage: key['f'][5]['v'],
        twitter_handle: key['f'][6]['v'],
        cmc_id: key['f'][7]['v'],
        cmc_slug: key['f'][8]['v'],
      });
    }
  }
  return dataSet;
};

export const tokenSearchResultFormat = (tokenInfo) => {
  // to hold push result data
  let dataSet = [];
  // tokenInfo = tokenInfo?.data;
  if (tokenInfo && tokenInfo.length > 0) {
    for (let key of tokenInfo) {
      dataSet.push({
        contract_address: key['f'][0]['v'],
        symbol: key['f'][1]['v'],
        name: key['f'][2]['v'],
        price_usd: key['f'][3]['v'],
      });
    }
  }
  return dataSet;
};
