import React from 'react';

type flexWrapperProps = {
  space?: string;
  direction?: string;
  justifyContent?: string;
  justifyItems?: string;
  className?: string;
  children?: JSX.Element;
};
export default function Flex({
  space,
  direction,
  justifyContent,
  justifyItems,
  className,
  children,
}: flexWrapperProps): JSX.Element {
  const flexJustifyContent = justifyContent && 'justify-' + justifyContent;
  const flexJustifyItems = justifyItems && 'justify-' + justifyItems;
  const flexStyle = `flex space-x-${space} flex-${direction} ${
    justifyContent && flexJustifyContent
  } ${justifyItems && flexJustifyItems} ${className}`;
  return (
    <>
      <div className={flexStyle}>{children}</div>
    </>
  );
}
