/* eslint-disable react/prop-types */
import {
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
  SelectorIcon,
} from '@heroicons/react/outline';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDoubleLeftIcon,
} from '@heroicons/react/solid';
import LatestTradesByTXHModal from 'Components/SharedComponents/LatestTradesBtTXHModal/latestTradesByTXHModal';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import Spinner from 'Utilities/Loaders/spinner';
import SlideOver from 'Utilities/SliderOvers/slideover';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Table(props) {
  const { data, col, isloading } = props;
  const columns = col;
  const [show, setShow] = useState(false);
  const [values, setValues] = useState('');
  const [component, setComponent] = useState('');
  const [txHash, setTxHash] = useState('');
  const [action, setAction] = useState('');
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.accessor),
        // sortBy: [{ id: 'trade_action' }],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const [showPopup, setShowPopup] = useState(false);

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  // for handling slider which trigger onclick of row of table
  function handleClick(id, isDrawer, dComponent) {
    setShow(isDrawer);
    setValues(id);
    setComponent(dComponent);
  }

  return (
    <div className="flow-root">
      <div
        className={`overflow-auto ${
          theme == 'dark'
            ? 'metric-scroll-light bg-dark-themePrimary'
            : 'metric-scroll-dark bg-white'
        }`}
      >
        <table
          className="min-w-full divide-y divide-gray-20"
          {...getTableProps()}
        >
          <thead className="dark:bg-dark-themePrimary sticky top-0">
            {headerGroups.map((headerGroup, key) => (
              <tr key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, key) => (
                  <th
                    key={key}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`px-2 py-1 text-center text-xs border-b  ${
                      theme === 'dark'
                        ? 'bg-dark-themePrimary border-dark-secondary text-white'
                        : 'bg-gray-100 text-black'
                    }`}
                  >
                    <div className="flex items-center">
                      <div className="inline-flex">
                        {column.render('Header')}
                      </div>
                      <div>
                        {column && column?.canFilter
                          ? column.render('Filter')
                          : null}
                      </div>

                      <div className="ml-1">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownIcon className="h-3 w-3 text-gray-350 dark:text-dark-themeMuted" />
                          ) : (
                            <ArrowUpIcon
                              className="h-3 w-3 text-gray-350 dark:text-dark-textPrimary"
                              aria-hidden="true"
                            />
                          )
                        ) : column.canSort ? (
                          <SelectorIcon className="w-5 h-5 text-dark-textPrimary" />
                        ) : null}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className={`${
              theme === 'dark'
                ? 'bg-dark-themePrimary divide-gray-700'
                : 'bg-light-themeSecondary divide-gray-200'
            }`}
            {...getTableBodyProps()}
          >
            {isloading ? (
              <tr>
                <td colSpan={12} className="h-96">
                  <div className="flex md:justify-center md:items-center ">
                    <Spinner />
                  </div>
                </td>
              </tr>
            ) : page.length > 0 ? (
              page.map((row, key) => {
                prepareRow(row);
                return (
                  <tr
                    key={key}
                    {...row.getRowProps()}
                    className="border-b dark:border-gray-700 text-justify"
                  >
                    {row.cells
                      ? row.cells.map((cell, key) => {
                          return (
                            <td
                              key={key}
                              {...cell.getCellProps()}
                              className={`px-2 py-1 whitespace-nowrap text-xs static ${
                                theme === 'dark' ? 'text-white' : 'text-black'
                              }`}
                              onClick={() => {
                                cell.column.isDrawer
                                  ? handleClick(
                                      cell.row.values.id,
                                      cell.column.isDrawer,
                                      cell.column.drawerComponent
                                    )
                                  : cell.column.openUtab
                                  ? null
                                  : null;
                              }}
                            >
                              {cell.column.isSearchClicked ? (
                                <div className="flex items-center space-x-2">
                                  <SearchIcon
                                    className="w-4 h-4 mt-0.5 cursor-pointer"
                                    onClick={() => {
                                      setShowPopup(true);
                                      setTxHash(
                                        cell.row.values.transaction_hash
                                      );
                                      setAction(cell.column.action);
                                    }}
                                  />
                                  <div className="">{cell.render('Cell')}</div>
                                </div>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          );
                        })
                      : 'No data'}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={12} className="">
                  <div className="flex justify-center items-center h-96">
                    <h1 className="font-semibold dark:text-gray-600 text-gray-400 my-auto italic font-sans">
                      No Data, Please try again with different filter
                    </h1>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!isloading && data.length > 50 ? (
          <div className="flex justify-center items-center flex-col sm:flex-row p-2 px-2 border-t dark:border-gray-700 dark:bg-dark-tabPillBgSelectedDark bg-light-tabPillBgSelectedLight rounded  bottom-0">
            <div className="flex mt-5 sm:mt-0 space-x-1">
              <button
                type="button"
                className="inline-flex items-center p-1 px-3 border border-transparent rounded-md shadow-sm dark:text-white text-gray-700 bg-gray-200 hover:bg-gray-300 dark:bg-gray-500  dark:hover:bg-gray-700"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <ChevronDoubleLeftIcon className="w-3 h-3" />
              </button>
              <button
                type="button"
                className="inline-flex items-center p-1 px-3 border border-transparent rounded-md shadow-sm dark:text-white text-gray-700 bg-gray-200 hover:bg-gray-300 dark:bg-gray-500 dark:hover:bg-gray-700"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <ChevronLeftIcon className="w-3 h-3" />
              </button>
              <span className="inline-flex items-center text-xs p-1 px-2 border border-transparent rounded-md shadow-sm dark:text-white text-gray-700 bg-gray-200 hover:bg-gray-300 dark:bg-gray-500 dark:hover:bg-gray-700">
                Page {pageIndex + 1} of {pageOptions.length}
              </span>
              <button
                type="button"
                className="inline-flex items-center p-1 px-3 border border-transparent rounded-md shadow-sm dark:text-white text-gray-700 bg-gray-200 hover:bg-gray-300 dark:bg-gray-500 dark:hover:bg-gray-700"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <ChevronRightIcon className="w-3 h-3" />
              </button>
              <button
                type="button"
                className="inline-flex items-center p-1 px-3 border border-transparent rounded-md shadow-sm dark:text-white text-gray-700 bg-gray-200 hover:bg-gray-300 dark:bg-gray-500 dark:hover:bg-gray-700"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <ChevronDoubleRightIcon className="w-3 h-3" />
              </button>
            </div>
          </div>
        ) : null}
        {show ? (
          <SlideOver
            show={show}
            setShow={setShow}
            value={values}
            component={component}
          />
        ) : null}
        {showPopup ? (
          <div>
            <LatestTradesByTXHModal
              setShowPopup={setShowPopup}
              showPopup={showPopup}
              txHash={txHash}
              action={action}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Table;
