import { ClipboardIcon, DuplicateIcon, XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';

LocalRunSetUp.propTypes = {
  setShowModal: PropTypes.func,
};
function LocalRunSetUp({ setShowModal }) {
  const [copied, setCopied] = useState(false);
  const resetCopy = useRef();

  const onCopyCode = () => {
    navigator.clipboard.writeText(children).then(() => setCopied(true));
  };

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  useEffect(() => {
    if (copied) {
      resetCopy.current = setTimeout(() => setCopied(false), 2500);
    }

    return () => {
      clearTimeout(resetCopy.current);
    };
  }, [copied]);
  return (
    <div className="p-4">
      <div className="flex justify-between">
        <h1 className="text-md sm:text-lg lg:text-xl my-2 font-semibold">
          Run Orchestration Locally
        </h1>
        <XIcon className="w-4" onClick={() => setShowModal(false)} />
      </div>
      <p className="py-2 text-sm">
        Alphastack uses Open-Source software Dagster for Orchestration.
      </p>
      <p className="py-2 mb-4 text-xs">
        A minimal skeleton{' '}
        <span
          className={`${
            theme === 'dark' ? 'bg-gray-500' : 'bg-gray-400'
          } rounded px-1`}
        >
          Dockerfile
        </span>{' '}
        that will run Dagit is shown below:
      </p>
      <div className="px-2">
        <div
          className={`${
            theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'
          }  rounded-lg w-auto my-4 max-w-2xl`}
        >
          <div className="flex justify-between items-baseline px-2 py-2">
            <div
              className={`flex flex-col w-full font-mono text-sm p-2 ${
                theme === 'dark' ? 'text-white' : 'text-black'
              }`}
            >
              <div className="mb-2">FROM python:3.7-slim</div>
              <div className="mb-2">
                RUN mkdir -p /opt/dagster/dagster_home /opt/dagster/app
              </div>
              <div className="mb-2">RUN pip install dagit dagster-postgres</div>
              <div className="mb-4">
                <div className="italic text-gray-300 text-xs">
                  # Copy your code and workspace to /opt/dagster/app
                </div>
                <div>COPY repo.py workspace.yaml /opt/dagster/app/</div>
              </div>
              <div className="mb-2">
                ENV DAGSTER_HOME=/opt/dagster/dagster_home/
              </div>
              <div className="mb-4">
                <div className="italic text-gray-300 text-xs">
                  # Copy dagster instance YAML to $DAGSTER_HOME
                </div>
                <div>COPY dagster.yaml /opt/dagster/dagster_home/</div>
              </div>
              <div className="mb-2">WORKDIR /opt/dagster/app</div>
              <div className="mb-2">EXPOSE 3000</div>
              <div>
                ENTRYPOINT [&apos;dagit&apos;, &apos;-h&apos;,
                &apos;0.0.0.0&apos;, &apos;-p&apos;, &apos;3000&apos;]
              </div>
            </div>
            <div>
              {copied ? (
                <ClipboardIcon
                  className={`text-black w-5 dark:text-gray-500 ${
                    theme === 'dark' ? 'text-white' : 'text-black'
                  }`}
                />
              ) : (
                <DuplicateIcon
                  className={`w-5 text-black dark:text-gray-500  ${
                    theme === 'dark' ? 'text-white' : 'text-black'
                  }`}
                  onClick={onCopyCode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <p className="mt-6 text-xs">
        You&apos;ll also need to include a{' '}
        <span className="font-semibold">workspace.yaml</span> file in the same
        directory as the Dockerfile to configure your workspace:
      </p>
      <p className="mt-2 text-xs mb-4">
        as well as a <span className="font-semibold">dagster.yaml</span> file to
        configure your Dagster instance:
      </p>
      <a
        className="text-blue-600"
        href="https://docs.dagster.io/deployment/guides/docker"
        target="_blank"
        rel="noreferrer"
      >
        https://docs.dagster.io/deployment/guides/docker
      </a>
    </div>
  );
}

export default LocalRunSetUp;
