import { Menu, Transition } from '@headlessui/react';
import { AdjustmentsIcon, FilterIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';

type menuProps = {
  children: JSX.Element;
};
export default function MenuWrapper({ children }: menuProps): JSX.Element {
  return (
    <>
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button className="rounded-md flex items-center px-1.5 py-1.5 bg-gray-100 dark:bg-gray-700  dark:text-dark-textPrimary">
            <div className="flex text-xs space-x-1 font-semibold">
              <AdjustmentsIcon className="w-4 text-gray-600 dark:text-gray-300" />
              {/* <span>More...</span> */}
            </div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-40 origin-top-right absolute right-4 mt-2 w-80 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
            {children}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
