import { getUrlParam } from 'Helper/getUrlPram';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getTopBalances } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import Table from 'Utilities/Table/table';
import { column } from './header';

function TopHolders() {
  // accesing url param
  const { search } = useLocation();
  const urlparams = new URLSearchParams(search);
  const contract_address = urlparams.get('contract_address');

  // useDispatch() instance
  const dispatch = useDispatch();

  const chainId = getUrlParam('chain') == 'ethereum' ? 0 : 1;
  const processTopBalances = (topBalance) => {
    let dataSet = [];
    if (topBalance) {
      for (let key of topBalance) {
        dataSet.push({
          name: key['f'][0]['v'],
          holder: key['f'][2]['v'],
          balance: key['f'][3]['v'],
          // change_7di: key['f'][4]['v'],
          // change_300: key['f'][5]['v'],
          // sent: key['f'][6]['v'],
          // day_since: key['f'][9]['v'],
          // first_recieved: key['f'][10]['v'],
        });
      }
    }
    return dataSet;
  };
  // listening to store changes
  const { topBalance, loading } = useSelector((s = initialState) => {
    return {
      topBalance: processTopBalances(s.topBalanceData.topBalance.data),
      loading: s.topBalanceData.loading,
    };
  });
  // useeffect hook start
  useEffect(() => {
    if (!(topBalance && topBalance.length > 0)) {
      dispatch(getTopBalances(contract_address, chainId));
    }
  }, []);
  // useeffect hook end
  return (
    <div className="">
      <div className="rounded-lg flex flex-col">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-y-scroll overflow-x-auto flex-1 h-full metric-scroll dark:border-dark-secondary">
          <Table data={topBalance} col={column} isloading={loading} />
        </div>
      </div>
    </div>
  );
}

export default TopHolders;
