/* eslint-disable react/prop-types */
import Spinner from 'Utilities/Loaders/spinner';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { initialState } from 'statemgmt/initialState';

function TableTokenDashboard(props) {
  const { data, col, isloading } = props;
  const columns = col;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const { theme } = useSelector((s = initialState) => {
    return {
      theme: s.appTheme.theme,
    };
  });
  return (
    <div className="flow-root">
      <div
        className={`overflow-x-auto h-screen ${
          theme == 'dark'
            ? 'metric-scroll-light bg-dark-themePrimary'
            : 'metric-scroll-dark bg-white'
        }`}
      >
        <table
          {...getTableProps()}
          className="min-w-full divide-y divide-gray-200 table-fixed"
        >
          <thead className="dark:bg-dark-themePrimary sticky top-0 text-left">
            {headerGroups.map((headerGroup, hKey) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={hKey}>
                {headerGroup.headers.map((column, colKey) => (
                  <th
                    {...column.getHeaderProps()}
                    key={colKey}
                    className={`px-2 py-1 text-xs text-cente border-b  ${
                      theme === 'dark'
                        ? 'bg-dark-themePrimary border-dark-secondary text-white'
                        : 'bg-gray-100 text-black'
                    }`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="">
            {isloading ? (
              <tr>
                <td colSpan={12} className="h-96">
                  <div className="flex justify-center items-center ">
                    <Spinner />
                  </div>
                </td>
              </tr>
            ) : (
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={i}
                    className="border-b dark:border-gray-700"
                  >
                    {row.cells.map((cell, j) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={j}
                          className={`px-2 py-3 whitespace-nowrap text-xs ${
                            theme === 'dark' ? 'text-white' : 'text-black'
                          }`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableTokenDashboard;
