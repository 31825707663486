/* eslint-disable react/prop-types */
import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';
import { CHAINS, chainDetailExternalLink } from 'Helper/constants';
import { useSelector } from 'react-redux';
import { initialState } from 'statemgmt/initialState';
import { getUrlParam } from 'Helper/getUrlPram';
import { chainProvider } from 'Helper/chainProvider';
import Tooltip from 'Utilities/Tooltip/tooltip';

function Etherscan(props) {
  // eslint-disable-next-line react/prop-types
  const { address, type, isSubString } = props;
  if (!address) return null;

  // const { block_chain } = useSelector((s = initialState) => {
  //   return {
  //     block_chain: s.currentBlockChain.selectedBlockChain,
  //   };
  // });
  const block_chain = CHAINS[getUrlParam('chain')];
  let type_path = type;
  if (block_chain == 4 && type === 'address') type_path = 'account';
  // const chain = getUrlParam('chain');

  return (
    <>
      <Tooltip
        content={
          type == 'address'
            ? `open address in ${new URL(
                chainProvider(block_chain)
              ).hostname.replace('www.', '')}`
            : type == 'token'
            ? `open token in ${new URL(
                chainProvider(block_chain)
              ).hostname.replace('www.', '')}`
            : `open transaction in ${new URL(
                chainProvider(block_chain)
              ).hostname.replace('www.', '')}`
        }
        placement="top"
      >
        <a
          className="mr-1 text-blue-500 text-xs font-semibold"
          href={`${chainProvider(block_chain)}/${type_path}/${address}`}
          target="_blank"
          rel="noreferrer"
        >
          {address
            ? isSubString
              ? address.substring(0, 6) +
                '...' +
                address.substring(address.length - 4)
              : address
            : ''}
        </a>
      </Tooltip>
    </>
  );
}

export default Etherscan;
