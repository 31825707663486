import ethereumLogo from '../../../../Assets/Images/ethereum_logo.png';
import solanaLogo from '../../../../Assets/Images/solana_logo.png';
import polygonLogo from '../../../../Assets/Images/polygon_logo.png';
export const nftCategories = [
  {
    Collectibles: 'purple',
    Game: 'green',
    Art: 'yellow',
    Avatar: 'red',
    Metaverse: 'blue',
    Utility: 'indigo',
    Social: 'yellow',
    DeFi: 'lime',
    Other: 'gray',
    DeFi: 'yellow',
  },
];

export const blockChainLogo = [
  {
    ethereum: ethereumLogo,
    solana: solanaLogo,
    polygon: polygonLogo,
  },
];

// export socialLink = [

// ]
