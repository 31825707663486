/* eslint-disable react/prop-types */
import { getUrlParam } from 'Helper/getUrlPram';
import { useIsMounted, useLocalStorage } from 'Hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticsData } from 'statemgmt/actions';

import { InformationCircleIcon } from '@heroicons/react/outline';
import { MenuWrapper, Refresh, Spinner } from 'Utilities';
import Filters from './filters';
import GroupTrades from './groupTrades';
import GroupWalletAddresses from './groupWalletAddresses';
import { column, traderAddressCols } from './helper/header';
import {
  processAnalayticsData,
  processTokenAddress,
} from './helper/processData';
import SearchToken from './searchToken';
import SortOptions from './sortOptions';
import BlockChainSelector from 'Components/Container/Atoms/blockChainSelector';

const RenderFeatureInfo = () => {
  return (
    <div className="flex mx-3 mt-3">
      <div
        className={`flex space-x-2 items-center px-2 py-1 bg-blue-50 dark:bg-blue-200 text-xs rounded-md`}
      >
        <div className="">
          <InformationCircleIcon
            className="h-4 w-4 text-blue-500"
            aria-hidden="true"
          />
        </div>

        <p className="text-xs text-blue-700 dark:text-blue-800">
          For the selected token what are the earliest receivers of that token
          doing currently
        </p>
      </div>
    </div>
  );
};

function EarlyHolders() {
  const [filter, setFilter] = useState('volume_usd');
  const [walletOption, setWalletOption] = useLocalStorage('walletOption', 50);
  const [operationOption, setOperationOption] = useLocalStorage(
    'operationOption',
    'buy'
  );
  const [refresh, setRefresh] = useState(false);
  const [queryToken, setQueryToken] = useState('');
  const [countOption, setCountOption] = useLocalStorage('countOption', 10);
  const [durationOption, setDurationOption] = useLocalStorage(
    'durationOption',
    '1hr'
  );

  const [chain, setChain] = useState(getUrlParam('chain'));
  const isMount = useIsMounted();
  const dispatch = useDispatch();

  const block_chain = getUrlParam('chain');

  const { analytics, tokenAddresses, loadingTable } = useSelector(
    (s = initialState) => {
      const earlyHolders = s.analyticsData.analytics?.data?.early_holders;
      const traderAddress = s.analyticsData?.analytics?.data?.trader_addresses;
      return {
        analytics: processAnalayticsData(earlyHolders),
        loadingTable: s.analyticsData.loading,
        tokenAddresses: processTokenAddress(traderAddress),
      };
    }
  );

  const getAnalytics = (contractAddress) => {
    let contract_address =
      contractAddress !== null ? contractAddress : queryToken;
    dispatch(
      getAnalyticsData(
        contract_address,
        operationOption,
        durationOption,
        filter,
        countOption,
        block_chain
      )
    );
  };

  const filterProps = Object.freeze({
    walletOption,
    setWalletOption,
    durationOption,
    setDurationOption,
    countOption,
    setCountOption,
    operationOption,
    setOperationOption,
  });
  const searchProps = Object.freeze({
    queryToken,
    setQueryToken,
    getAnalytics,
    block_chain,
  });

  useEffect(() => {
    if (isMount) return;
    getAnalytics(null);
  }, [durationOption, countOption, operationOption, filter, refresh, chain]);

  return (
    <div className="">
      <RenderFeatureInfo />
      <BlockChainSelector setState={setChain} />
      <SearchToken {...searchProps} />
      <div className="w-full">
        <div className="hidden lg:block border-b dark:border-gray-700 w-full">
          <div className="flex flex-wrap space-x-2 px-3 my-1 items-center">
            <Filters {...filterProps} />
            <SortOptions filter={filter} setFilter={setFilter} />
            {analytics && analytics.length > 0 ? (
              <Refresh
                state={refresh}
                setState={setRefresh}
                loadingbutton={loadingTable}
              />
            ) : null}
          </div>
        </div>
        {/* filters responsive view */}
        <div className="block lg:hidden py-4 px-2 lg:py-0 lg:px-0">
          <div className="flex justify-end items-center space-x-1">
            <Refresh
              state={refresh}
              setState={setRefresh}
              loadingbutton={loadingTable}
            />
            <MenuWrapper>
              <div className="m-2">
                <div>
                  <p className="text-xs text-gray-600 dark:text-gray-400 py-1">
                    Filter by
                  </p>
                  <Filters {...filterProps} />
                </div>
                <div className="py-2">
                  <p className="text-xs text-gray-600 dark:text-gray-400 py-1">
                    Sort by
                  </p>
                  <SortOptions filter={filter} setFilter={setFilter} />
                </div>
              </div>
            </MenuWrapper>
          </div>
        </div>

        <>
          {analytics?.length > 0 ? (
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 mx-4 my-3">
              <div className="xl:col-span-2">
                <GroupTrades
                  analytics={analytics}
                  column={column}
                  isloading={loadingTable}
                />
              </div>

              <div className="">
                <GroupWalletAddresses
                  walletAddresses={tokenAddresses}
                  column={traderAddressCols}
                  isloading={loadingTable}
                />
              </div>
            </div>
          ) : (
            <main className="flex items-center justify-center h-96 opacity-90">
              <div className="">
                <div className="text-center">
                  {loadingTable ? (
                    <Spinner />
                  ) : (
                    <>
                      <h1 className="mt-2 text-sm font-extrabold text-gray-200 dark:text-gray-400">
                        Early Holders
                      </h1>
                      <p className="mt-1 text-xs text-gray-200 dark:text-gray-500">
                        Enter the token address and hit Go
                      </p>
                    </>
                  )}
                </div>
              </div>
            </main>
          )}
        </>
      </div>
    </div>
  );
}

export default EarlyHolders;
