/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWalletSubmissionDetails } from 'statemgmt/actions';
import { initialState } from 'statemgmt/initialState';
import { AlphaStatus, Labels } from 'Utilities';

function SubmissionDetails({ value, component }) {
  const submission_id = value ? value : null;
  console.log(submission_id);
  const theme = localStorage.getItem('theme');
  // useDispatch() instance
  const dispatch = useDispatch();

  // listeing to redux store changes
  const { submissionDetails } = useSelector((s = initialState) => {
    return {
      submissionDetails: s.walletSubmissiondetailsData.submissionDetails.data,
    };
  });
  console.log(submissionDetails);
  // useeffect hook start
  useEffect(() => {
    dispatch(getWalletSubmissionDetails(submission_id));
  }, []);

  // useeffect hook end
  return (
    <div className="m-4 flex flex-col h-full">
      <div className="flex-1">
        <>
          <h1
            className={`mb-1 ${
              theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
            }`}
          >
            Result
          </h1>
        </>
        <div
          className={`bg-gray-100  rounded-sm ${
            theme === 'dark' ? 'bg-gray-800 border border-gray-500' : null
          }`}
        >
          <div className="flex flex-row justify-between">
            <div className="m-3">
              <dl className="">
                <dt
                  className={`text-sm font-medium  truncate mb-1 ${
                    theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                  }`}
                >
                  Status
                </dt>
                <dd className="text-white rounded-md text-sm">
                  {submissionDetails && submissionDetails[0].approval_status ? (
                    <AlphaStatus
                      status={submissionDetails[0].approval_status}
                    />
                  ) : (
                    '-'
                  )}
                </dd>
              </dl>
            </div>
            <div className="m-3">
              <dl className="">
                <dt
                  className={`text-sm font-medium  truncate mb-1 ${
                    theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                  }`}
                >
                  Earned Score
                </dt>
                <dd
                  className={`text-sm px-4 py-2 text-center font-semibold ${
                    theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                  }`}
                >
                  {submissionDetails ? submissionDetails[0].score : '-'}
                </dd>
              </dl>
            </div>
            {submissionDetails ? (
              submissionDetails[0].score_last_updated_timestamp_utc ? (
                <div className="m-3">
                  <dl className="">
                    <dt
                      className={`text-sm font-medium  truncate mb-1 ${
                        theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                      }`}
                    >
                      Reviewed on
                    </dt>
                    <dd
                      className={`text-sm py-2 font-semibold ${
                        theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
                      }`}
                    >
                      {submissionDetails
                        ? new Date(
                            submissionDetails[0].score_last_updated_timestamp_utc
                          ).toUTCString('en-US')
                        : '-'}
                    </dd>
                  </dl>
                </div>
              ) : null
            ) : null}
          </div>
          <div className="flex m-3">
            <dl className="">
              <dt
                className={`text-sm font-medium  truncate mb-1 ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
                }`}
              >
                Score Comments
              </dt>
              <dd className="text-white rounded-md text-sm">
                {submissionDetails ? submissionDetails[0].score_comments : '-'}
              </dd>
            </dl>
          </div>
        </div>
        <div className="m-3">
          <dl className="">
            <dt
              className={`text-sm font-medium  truncate mb-1 ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              Wallet Address
            </dt>
            <dd
              className={`text-sm py-2 font-semibold ${
                theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
              }`}
            >
              {submissionDetails ? submissionDetails[0].wallet_address : '-'}
            </dd>
          </dl>
        </div>
        <div className="my-6 m-3">
          <dl className="">
            <dt
              className={`text-sm font-medium  truncate mb-1 ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              Block Chain
            </dt>
            <dd className="text-sm font-semibold">
              {submissionDetails ? (
                <Labels
                  label={submissionDetails[0].blockchains_json[0].label}
                />
              ) : (
                '-'
              )}
            </dd>
          </dl>
        </div>
        <div className="my-6 m-3">
          <dl className="">
            <dt
              className={`text-sm font-medium  truncate mb-1 ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              Labels
            </dt>
            <dd className="text-sm font-semibold">
              {submissionDetails ? (
                <Labels label={submissionDetails[0].labels_json} />
              ) : (
                '-'
              )}
            </dd>
          </dl>
        </div>
        <div className="my-6 m-3">
          <dl className="">
            <dt
              className={`text-sm font-medium  truncate mb-1 ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              Research
            </dt>
            <dd
              className={`text-sm font-semibold break-words overflow-hidden ${
                theme === 'dark' ? 'text-gray-200' : 'text-gray-700'
              }`}
            >
              {submissionDetails ? submissionDetails[0].research : '-'}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default SubmissionDetails;
