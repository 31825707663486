import { ArrowLeftIcon } from '@heroicons/react/outline';
import { getUrlParam } from 'Helper/getUrlPram';
import { Tooltip } from 'Utilities';
import {
  Adls,
  AmazonRedShift,
  BigQuery,
  Gcs,
  MongoDb,
  MySql,
  PostgreSql,
  S3Bucket,
  SnowFlake,
  SqlServer,
  Synapse,
} from 'Utilities/images';
import { useNavigate } from 'react-router-dom';
import MysqlCatalog from './DataSources/MySQL/mysql';
import VaultUserAuth from './vaultUserAuth';
import DataSource from './DataSources';
const products = [
  {
    id: 1,
    name: 'MySQL',
    href: 'mysql',
    imageSrc: MySql,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'PostgreSQL',
    href: '#',
    imageSrc: PostgreSql,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Microsoft SQL Server',
    href: '#',
    imageSrc: SqlServer,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 4,
    name: 'Snow Flake',
    href: '#',
    imageSrc: SnowFlake,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 5,
    name: 'Google BigQuery',
    href: '#',
    imageSrc: BigQuery,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 6,
    name: 'MongoDB',
    href: '#',
    imageSrc: MongoDb,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 7,
    name: 'Amazon Redshift',
    href: '#',
    imageSrc: AmazonRedShift,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 8,
    name: 'Azure Synapse',
    href: '#',
    imageSrc: Synapse,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 9,
    name: 'Amazon S3',
    href: 'amazon_s3',
    imageSrc: S3Bucket,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 10,
    name: 'Google Cloud Storage',
    href: '#',
    imageSrc: Gcs,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
  {
    id: 11,
    name: 'Azure Data Lake Storage',
    href: '#',
    imageSrc: Adls,
    imageAlt: 'data source',
    price: '$35',
    color: 'Black',
  },
];
function NewDataSource() {
  const cluster_name = getUrlParam('name');
  const type = getUrlParam('type');
  const step = getUrlParam('step');
  const navigate = useNavigate();
  sessionStorage.setItem('catalog_type', type);

  const handleClick = (path) => {
    navigate(`?type=${path}&step=vault-confirm`);
    // navigate(`${path}?name=${cluster_name}`);
  };

  if (step === 'vault-confirm') {
    return (
      <div>
        <VaultUserAuth />
      </div>
    );
  }

  if (step === 'catalog-config') {
    return <DataSource source={type} />;
  }

  if (step === 'catalog-source') {
    return (
      <div className="bg-white dark:bg-dark-themeSecondary">
        <div className="border-b dark:border-gray-700 p-3">
          <ArrowLeftIcon
            className="cursor-pointer w-5 text-black dark:text-white mx-8 mb-4"
            onClick={() => navigate(-1)}
          />

          <div className="px-4 sm:px-6 lg:px-8">
            <h2 className="text-xl lg:2xl dark:text-white text-gray-800 font-medium">
              Configure a catalog
            </h2>
          </div>
        </div>
        <div className="mx-auto max-w-2xl py-12 px-4 sm:py-18 sm:px-6 lg:max-w-6xl lg:px-4">
          <h2 className="text-lg font-medium tracking-normal text-gray-900 dark:text-white text-center sm:text-left sm:ml-20">
            Select a data source
          </h2>

          <div className="mt-10 grid grid-cols-1 gap-y-10 gap-x-4 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {products.map((item, k) => (
              <>
                {item.id === 1 ? (
                  <button
                    key={k}
                    className={`cursor-pointer  relative flex flex-col items-center pointer-events-auto ${
                      item.id != 1 && 'opacity-40 cursor-not-allowed'
                    }`}
                    onClick={() => item.id == 1 && handleClick(item.href)}
                    // disabled={item.id != 1}
                  >
                    <div className="overflow-hidden rounded-md dark:bg-gray-200">
                      <div className="aspect-w-3 aspect-h-2">
                        <img
                          className="rounded-sm w-20"
                          src={item.imageSrc}
                          alt={item.imageAlt}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div>
                        <h3 className="text-md text-gray-700 dark:text-white text-center">
                          <div>
                            <span
                              aria-hidden="true"
                              className="absolute inset-0"
                            />
                            {item.name}
                          </div>
                        </h3>
                      </div>
                    </div>
                  </button>
                ) : (
                  <Tooltip key={k} content="currently disabled" placement="top">
                    <button
                      key={k}
                      className={`cursor-pointer  relative flex flex-col items-center pointer-events-auto ${
                        item.id != 1 && 'opacity-40 cursor-not-allowed'
                      }`}
                      onClick={() => item.id == 1 && handleClick(item.href)}
                      // disabled={item.id != 1}
                    >
                      <div className="overflow-hidden rounded-md dark:bg-gray-200">
                        <div className="aspect-w-3 aspect-h-2">
                          <img
                            className="rounded-sm w-20"
                            src={item.imageSrc}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <div>
                          <h3 className="text-md text-gray-700 dark:text-white text-center">
                            <div>
                              <span
                                aria-hidden="true"
                                className="absolute inset-0"
                              />
                              {item.name}
                            </div>
                          </h3>
                        </div>
                      </div>
                    </button>
                  </Tooltip>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default NewDataSource;
